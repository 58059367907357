<template>
  <div class="layout-sidebar">
    <div class="logo-block">
      <div class="logo-icon">
        <img src="/images/tekvel_logo-1.svg" loading="lazy" alt="" />
      </div>
    </div>
    <div class="main-nav">
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/nav-1-project.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/nav-2-wheel.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/nav-3-sercive.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/nav-4-documentation.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/nav-5-events.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/nav-6-analyse.svg" loading="lazy" alt="" />
        </div>
      </div>
    </div>
    <div class="user-nav">
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/userpic.png" loading="lazy" alt="" />
        </div>
      </div>
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/profile-1-settings.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="nav-elm">
        <div class="nav-elm__icon">
          <img src="/images/profile-2-exit.svg" loading="lazy" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'main-sidebar',
};
</script>

<style scoped></style>
