<template>
  <div id="about-app" v-loading="loading" style="position: relative">
    <div id="graph" style="width: calc(100% + 30px); height: 150px; margin-left: -30px"></div>
  </div>
</template>

<script>
import PreparedReports from '@/components/modals/PreparedReports.vue';
import Dygraph from 'dygraphs';
// import resize from 'vue-resize-directive';
import api from '@/services/api';
import DateNano from '../../utils/format.date.time';

export default {
  name: 'about-app',
  components: {},
  directives: {
    // resize,
  },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
    var myModalEl = document.getElementById('graph');
    // wait until element apears
    var checkExist = setInterval(() => {
      if (myModalEl.clientWidth > 0) {
        this.getData();
        clearInterval(checkExist);
      }
    }, 100); // check every 100ms
    //
  },
  methods: {
    getData() {
      this.loading = true;
      //db-freespace
      let dataUrl = '/db-freespace';
      api
        .get(dataUrl)
        .then((result) => {
          // JSON responses are automatically parsed.
          this.data = result.data;
          this.$nextTick(() => {
            this.show();
            this.loading = false;
          });
        })
        .catch((e) => {
          // this.errors.push(e);
          this.loading = false;
        });
    },
    show() {
      var data = [];
      // var t = new Date();
      // for (var i = 10; i >= 0; i--) {
      //   var x = new Date(t.getTime() - i * 1000);
      //   data.push([x, Math.random() * 500]);
      // }
      this.data.forEach((el) => {
        data.push([el.time, parseFloat((el.space / 1e9).toFixed(2))]);
      });

      dev.log('disk data', data);

      var g = new Dygraph(document.getElementById('graph'), data, {
        valueFormatter: (_val, opts, seriesName, dygraph, row, col) => {
          if (seriesName == 'Time') return new DateNano(_val).customFormat('#DD#.#MM#.#YYYY# #hhhh#:#mm#:#ss#');
          if (seriesName == 'Free space') return _val + ' Gb';
          return _val;
        },
        legend: 'follow',
        axes: {
          y: {
            drawAxis: true,
          },
          x: {
            drawAxis: false,
          },
        },
        // series: {
        //   'Time': {
        //     drawAxis: false,
        //     valueFormatter: function (x) {
        //       return new DateNano(x).customFormat('#hhhh#:#mm#:#ss#.#ms##us#');
        //     },
        //     ticker: Dygraph.dateTicker,
        //     axisLabelFormatter: function (date) {
        //       return new DateNano(date).customFormat('#DD#.#MM#.#YYYY#');
        //     },
        //   },
        //   'Free space': {
        //     valueFormatter: function (x) {
        //       return x + ' Gb';
        //     },
        //     ticker: Dygraph.dateTicker,
        //     axisLabelFormatter: function (date, granularity, opts) {
        //       return new DateNano(date).customFormat('#hhhh#:#mm#:#ss#.#ms##us#');
        //     },
        //   },
        // },
        drawPoints: true,
        // valueRange: [0.0, 550],
        labels: ['Time', 'Free space'],
      });
      // It sucks that these things aren't objects, and we need to store state in window.
      // window.intervalId = setInterval(function () {
      //   var x = new Date(); // current time
      //   var y = Math.random() * 500;
      //   data.push([x, y]);
      //   g.updateOptions({ file: data });
      // }, 1000);
    },
  },
  watch: {},
};
</script>

<style>
#events-app {
  width: 100%;
}
</style>

<style lang="scss">
#top-app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
}
.report-progress0 {
  position: absolute;
  background-color: darkgray;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.report-progress {
  position: absolute;
  background-color: lawngreen;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
<style lang="scss"></style>
