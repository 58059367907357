<template>
  <n-popover
    :show="unhandledEvents.popoverVisible"
    placement="bottom"
    trigger="manual"
    :overlap="false"
    @update:show="handleUpdateShow"
  >
    <template #trigger>
      <button
        class="btn btn-outline-secondary btn-sm unhandled-events-detector fal fa-angle-left"
        type="button"
        v-click-outside="clickOutside"
        @click="unhandledEvents.toggleVisible"
      ></button>
    </template>
    <div style="display: flex">
      <div class="unhandled-events-text">{{ $t('Unhandled events') }}: {{ unhandledEvents.eventsCounter || 0 }}</div>
      <button
        v-if="unhandledEvents.enabled"
        class="btn ml-3 fal fa-power-off"
        type="button"
        @click="unhandledEvents.turnOff"
      ></button>
    </div>
  </n-popover>
</template>

<script>
import { mapStores } from 'pinia';
import { useUnhandledEvents } from '@/stores/unhandledEvents';
import ClickOutside from 'v-click-outside';

export default {
  name: 'unhandled-events-popover',
  directives: {
    ClickOutside,
  },
  computed: {
    ...mapStores(useUnhandledEvents),
  },
  methods: {
    clickOutside(e) {
      dev.log('clickOutside', e);
      this.unhandledEvents.popoverVisible = false;
    },
    handleUpdateShow(show) {
      dev.log('handleUpdateShow', show);
      if (show) {
        this.unhandledEvents.turnOn();
      }
    },
  },
};
</script>

<style scoped>
.unhandled-events-text {
  margin: auto 0 auto;
  font-size: 14px;
  line-height: 14px;
}
</style>
