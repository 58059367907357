<template>
  <div class="timeline__date">
    <div class="timeline-dateblock">
      <div class="timeline-dateblock__date">{{ dateObj.date }}</div>
      <div class="timeline-dateblock__year">{{ dateObj.year }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'timeline-date',
  props: {
    dateObj: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
