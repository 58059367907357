import api from '@/services/api';
import { defineStore } from 'pinia';
import {responseCatch} from '@/utils/responseCatchHandler';
const rootProps = ['displayName', 'isLive'];

export const usePage = defineStore({
  id: 'page',
  persist: {
    paths: ['eventsPage', 'eventsOffTypes'],
    storage: sessionStorage,
  },
  state: () => {
    return {
      pageData: {},
      currentPage: undefined,
      eventsPage: 1,
      eventsOnTypes: [],
      eventsOffTypes: [],
    };
  },

  actions: {
    setProp({ page, data }) {
      this.pageData[page] = data;
    },
    setPageData({ page, data }) {
      this.pageData[page] = data;
    },
    getPageData(page) {
      if (!page?.meta?.noData) {
        const currentPage = page.name;
        const currentUrl = page.path;
        if (currentUrl) {
          api
            .get('' + currentUrl)
            .then((res) => {
              if (res.statusText == 'OK') {
                const data = res.data;
                Object.keys(data).forEach((el) => {
                  if (rootProps.indexOf(el) > -1) {
                    this.setProp({ name: el, val: data[el] });
                  }
                });
                this.currentPage = res.data;
                this.setPageData({ page: currentPage, data: res.data });
              }
            })
            .catch(responseCatch);
        }
      }
    },
  },
});
