import api from '@/services/api';
import { defineStore } from 'pinia';

import { useAuth } from '@/stores/auth';
import { useTpws } from '@/stores/tpws';
import { usePrefs } from '@/stores/prefs';
import { useVisual } from '@/stores/visual';

export const useShared = defineStore({
  id: 'shared',
  state: () => {
    return {
      currentProject: undefined,
      vertical: false,
      token: '',
      user: {},
    };
  },
  share: {
    // An array of fields that the plugin will ignore.
    // omit: ['foo'],
    // Override global config for this store.
    enable: true,
    initialize: true,
  },
  actions: {
    getUser() {
      const prefs = usePrefs();
      this.user = {
        vlanDisplayType: prefs.vlanDisplayType,
        ghostGooseCardTitleDisplay: prefs.ghostGooseCardTitleDisplay,
      };
    },
    getAccessToken() {
      const auth = useAuth();
      const token = localStorage.getItem('token');
      this.token = token;
    },
    getVertical() {
      const visual = useVisual();
      this.vertical = visual.vertical;
    },
    getCurrentProject() {
      const tpws = useTpws();
      this.currentProject = tpws.currentProject;
    },
  },
});
