export default {
  install: (app, options) => {
    // inject a globally available $translate() method
    app.config.globalProperties.$shadowStyle = (_this) => {
      let cur = _this;
      while (cur.$parent) {
        // console.log('cur', cur);
        cur = cur.$parent;
      }
      const shr = cur.$el.parentNode;
      // console.log('need scope', _this.$options);
      // console.log('$shadowStyle', _this, cur, _this.$options.name);
      let styles = [];
      if (_this.$options.__scopeId) {
        styles = [...styles, ...document.querySelectorAll("style[scope='" + _this.$options.__scopeId + "']")];
      }
      if (_this.$options.name) {
        styles = [...styles, ...document.querySelectorAll("style[component='" + _this.$options.name + "']")];
      }
      styles.forEach((style) => {
        let cloneId = style.getAttribute('clone-id');
        if (!cloneId) {
          const ci = Math.random();
          style.setAttribute('clone-id', ci);
          cloneId = ci;
        }

        if (shr && style) {
          const found = shr.querySelector("style[clone-id='" + cloneId + "']");
          // console.log('found', found);
          if (!found) {
            let styleClone = style.cloneNode(true);
            if (styleClone) {
              // console.log('add');
              shr.appendChild(styleClone, _this.$el);
            }
          }
        }
      });
    };
  },
};
