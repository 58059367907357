<template>
  <div class="timeline__item" :id="'cl-' + rowData._id">
    <div class="timeline__line"></div>
    <div class="timeline__dot"></div>
    <div class="timeline__item-block">
      <div class="cluster-block">
        <div class="cluster-block__body">
          <div class="row">
            <div class="col">
              <div class="cluster-name" @click="goClusterProfile(rowData.clusterDT)">
                <div class="cluster-name__title">
                  <template v-if="rowData['name']">{{ rowData['name'] }}</template>
                  <template v-else>
                    <template v-if="rowData['nameEv']"
                      ><span class="gen-cluster-name">{{ rowData['nameEv'] }}</span></template
                    >
                    <template v-else
                      ><span class="gen-cluster-name">{{
                        renderData(rowData, getField(fields, 'nameDT'))
                      }}</span></template
                    >
                  </template>
                </div>
                <div class="cluster-name__actions"><TIcon :size="16" icon="link"></TIcon></div>
                <span v-if="rowData['desc']">
                  &nbsp;
                  <popper
                    trigger="hover"
                    :append-to-body="true"
                    :options="{
                      placement: 'top',
                      modifiers: { offset: { offset: '0,10px' } },
                    }"
                  >
                    <template #content>
                      <div class="popper">
                        <div style="white-space: pre-line">{{ rowData['desc'] }}</div>
                      </div>
                    </template>

                    <span class="desc-info-icon"><i class="fas fa-info"></i></span>
                  </popper>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <labeled-block :title="$t('Start')">
                <span v-html="renderData(rowData, getField(fields, 'leftDT'))"></span>
              </labeled-block>
            </div>
            <div class="col-3">
              <labeled-block :title="$t('Events')">
                {{ renderData(rowData, getField(fields, 'evCount')) }}
              </labeled-block>
            </div>
            <div class="col-3">
              <labeled-block :title="$t('Duration')">
                {{ len }}
              </labeled-block>
            </div>
            <div class="col-3">
              <labeled-block title=""> </labeled-block>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <labeled-block
                type="list"
                :title="$t('Types')"
                :empty="renderData(rowData, getField(fields, 'evTypes')) === 0"
              >
                <limited-list type="iconed" :items="renderList(rowData, getField(fields, 'evTypes'))"></limited-list>
              </labeled-block>
            </div>
            <div class="col-8">
              <labeled-block
                type="list"
                :title="$t('Devices')"
                :count="renderData(rowData, getField(fields, 'devCount'))"
                :empty="renderData(rowData, getField(fields, 'devCount')) === 0"
              >
                <limited-list
                  type="tagged"
                  :items="renderList(rowData, getField(fields, 'evNames'))"
                  @showHidden="showHiddenDevices"
                ></limited-list>
                <hidden-counter
                  :max-right="maxRight['devices']"
                  :items-hidden="hiddenElements['devices']"
                ></hidden-counter>
              </labeled-block>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <labeled-block :title="$t('Protocols')"> </labeled-block>
            </div>
            <div class="col-8">
              <labeled-block :title="$t('Tags')"> </labeled-block>
            </div>
          </div>
          <div
            v-if="!rowData.showDetails"
            class="cluster-corner cluster-corner--closed"
            @click="execAction(getField(fields, 'evCount').action, $event, rowData)"
          >
            <span class="corner-label"><i class="el-icon-caret-bottom"></i>&nbsp;{{ $t('Show events') }}</span>
          </div>
          <div
            v-else
            class="cluster-corner cluster-corner--open"
            @click="execAction(getField(fields, 'evCount').action, $event, rowData)"
          >
            <span class="corner-label"><i class="el-icon-caret-top"></i>&nbsp;{{ $t('Hide events') }}</span>
          </div>
        </div>
        <div v-if="rowData.showDetails" class="cluster-block__details">
          <div class="cluster-block__details-before"></div>
          <div class="cluster-block__details-content">
            <slot></slot>
          </div>
        </div>
        <div
          v-if="rowData.showDetails"
          class="cluster-corner"
          @click="execAction(getField(fields, 'evCount').action, $event, rowData)"
        >
          <span class="corner-label"><i class="el-icon-caret-top"></i>&nbsp;{{ $t('Hide events') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LimitedList from '@/components/clusters/LimitedList.vue';
import LabeledBlock from '@/components/clusters/LabeledBlock.vue';
import HiddenCounter from '@/components/clusters/HiddenCounter.vue';
import TIcon from '@/components/ui/TIcon.vue';
import Popper from 'vue3-popper';
import '../../../assets/vue-popper-custom.css';

export default {
  name: 'timeline-element',
  components: {
    LimitedList,
    LabeledBlock,
    HiddenCounter,
    TIcon,
    popper: Popper,
  },
  props: {
    rowData: Object,
    fields: Array,
    className: Array,
    gap: String,
    len: String,
  },
  data: () => {
    return {
      hiddenElements: {
        types: [],
        devices: [],
      },
      maxRight: {
        types: 0,
        devices: 0,
      },
    };
  },
  methods: {
    goClusterProfile(c) {
      if (this.$config && this.$config.clusters && this.$config.clusters.eventsProfileMode) {
        let queryString;
        switch (this.$config.clusters.eventsProfileMode) {
          case 'old':
            queryString = this.$qs.stringify({
              cluster: c,
            });
            this.$router.push({ name: 'signals-profile-legacy', query: { cluster: c } });
            break;
          case 'mid':
            queryString = this.$qs.stringify({
              cluster: c,
            });
            window.location.href = '?p=cluster-events-profile' + (queryString ? '&' + queryString : '');
            break;
          default:
            queryString = this.$qs.stringify({
              cluster: c,
            });
            // window.location.href = '?p=cluster-events-profile' + (queryString ? '&' + queryString : '');
            this.$router.push({ name: 'clusters-profile', query: { cluster: c } });
            break;
        }
      } else {
        const queryString = this.$qs.stringify({
          cluster: c,
        });
        // window.location.href = '?p=cluster-events-profile' + (queryString ? '&' + queryString : '');
        this.$router.push({ name: 'clusters-profile', query: { cluster: c } });
      }
    },
    showHiddenTypes(e) {
      this.hiddenElements['devices'] = e;
    },
    showHiddenDevices(e, w) {
      this.hiddenElements['devices'] = e;
      this.maxRight['devices'] = w;
    },
    getField(fields, name) {
      return fields.find((el) => el.name === name);
    },
    execAction(a, e, d) {
      dev.log('execAction', a, e);
      if (a) {
        e.stopImmediatePropagation();
        this.$emit('colAction', a, e, d);
      }
    },
    renderData(row, f) {
      if (f.render && typeof f.render === 'function') {
        return f.render(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
    renderList(row, f) {
      if (f.renderList && typeof f.renderList === 'function') {
        return f.renderList(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
  },
};
</script>

<style>
.badge.bot {
  vertical-align: text-bottom;
}
</style>

<style scoped>
.data-table.stripe tbody tr.odd,
.data-table.display tbody tr.odd {
  background-color: #fbfbfb;
}
.data-table.display tbody td {
  border-top: 1px solid #ddd;
}
.data-table.display tbody tr:hover:not(.child) {
  background-color: #f6f6f6;
}
.data-table.display tbody tr.selected:hover:not(.child) {
  background-color: #aab7d1;
}
.data-table tbody td {
  padding: 8px 10px;
}
.data-table tbody .high-row td {
  padding: 22px 10px;
}
.data-table tbody td.show-gap {
  position: relative;
}
.data-table tbody td.show-gap::before {
  content: attr(data-gap);
  width: 130px;
  height: 3px;
  line-height: 3px;
  color: #007bff;
  text-align: center;
  font-size: 16px;
  background-color: white;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  margin-top: -2px;
  top: 0%;
}
.data-table tbody td.pointer {
  cursor: pointer;
}
a.pointer {
  cursor: pointer;
}
</style>
