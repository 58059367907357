import debouncePlugin from '@/utils/debouncePlugin';
import { createHead, Head } from '@vueuse/head';
import mitt from 'mitt';
import set from 'set-value';
import * as Vue from 'vue/dist/vue.esm-bundler';
import {
  createApp,
  defineAsyncComponent,
  defineComponent,
  defineCustomElement,
  h,
  markRaw,
} from 'vue/dist/vue.esm-bundler';

import { createPinia, mapStores, setMapStoreSuffix } from 'pinia';

import store from '@/stores';

import setupInterceptors from './services/axiosPrivate';
setupInterceptors();

import '@/../assets/json-tree.css';

import eventBus from '@/utils/eventBus';
import loadMod from '@/utils/loadModule';
import LoadScript from '@/utils/loadScript';
import vSvg from '@/utils/vSvg';
import { Icon } from '@vicons/utils';
import ElementPlus, { ElMessage, ElNotification } from 'element-plus';
import 'element-plus/dist/index.css';
import naive from 'naive-ui';
import VueLocalStorage from 'vue-ls';
import ScriptX from 'vue-scriptx';
import Sticky from 'vue-sticky-directive';
import { MqResponsive, Vue3Mq } from 'vue3-mq';
import naiveUiThemeOverrides from '../naive-ui-theme-overrides.json';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import chroma from 'chroma-js';
import Color from 'color';

import SecureLS from 'secure-ls';
var ls = new SecureLS({ isCompression: false });

import '../assets/cluster-common.scss';
import '../assets/cluster.scss';
import '../assets/signals.scss';

import RootApp from '@/components/common/RootApp.vue';

import ajax from '@/services/ajax';
import api from '@/services/api';

import PortalVue from 'portal-vue';
import VueSmoothScroll from 'vue3-smooth-scroll';
import resizeDirective from './utils/resizeDirectivePlugin';
import scrollLockPlugin from './utils/scrollLockPlugin';
import VueSticky from './utils/stickyDirectivePlugin';
import vIcon from './utils/vIcon';

import uiComponents from '@/components/ui';

// global Vue 3
window.Vue = Vue;

const parseDataset = (val) => {
  switch (val) {
    case 'true':
      return true;
    case 'false':
      return false;
    case 'undefined':
      return undefined;
    case 'null':
      return null;
    default:
      return val;
  }
};

const colors = [];
for (let i = 0; i <= 255; i += 8) {
  colors.push(Color.rgb(255, i, 0).hex());
}
for (let i = 255; i >= 0; i -= 8) {
  colors.push(Color.rgb(i, 255, 0).hex());
}
for (let i = 0; i <= 255; i += 8) {
  colors.push(Color.rgb(0, 255, i).hex());
}
for (let i = 255; i >= 0; i -= 8) {
  colors.push(Color.rgb(0, i, 255).hex());
}
for (let i = 0; i <= 255; i += 8) {
  colors.push(Color.rgb(i, 0, 255).hex());
}
for (let i = 255; i > 0; i -= 8) {
  colors.push(Color.rgb(255, 0, i).hex());
}

window['colorsUserProject'] = colors.map((item) => chroma(item).darken());

library.add(fas);
library.add(far);

import { formats, parse, stringify } from 'qs';
const queryString = {
  formats,
  parse,
  stringify,
};

import VueObserveVisibility from '@/plugins/vue-observe-visibility';
import FileSaver from 'file-saver';

// import { createI18n } from 'vue-i18n';
/*
 * The i18n resources in the path specified in the plugin `include` option can be read
 * as vue-i18n optimized locale messages using the import syntax
 */

import VueNativeSock from 'vue-native-websocket-vue3';

import enLang from 'element-plus/lib/locale/lang/en';
import ruLang from 'element-plus/lib/locale/lang/ru';

const elLangs = {
  ru: ruLang,
  en: enLang,
};

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
// import i18n from '@/utils/i18n';
import I18NextVue from 'i18next-vue';

const addTranslationNs = ['translation', 'events'];

i18next
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    cleanCode: true,
    supportedLngs: ['ru', 'en'],
    ns: addTranslationNs,
    // preload: true,
    preload: ['en', 'ru'],
    keySeparator: '^',
    saveMissingTo: 'current',
    saveMissing: true,
  });

const emitter = mitt();

import { useAuth } from '@/stores/auth';
import { useFaultProfile } from '@/stores/faultProfile';
import { useNotifications } from '@/stores/notifications';
import { useNotify } from '@/stores/notify';
import { usePage } from '@/stores/page';
import { usePrefs } from '@/stores/prefs';
import { useReports } from '@/stores/reports';
import { useTpui } from '@/stores/tpui';
import { useTpws } from '@/stores/tpws';
import { useVisual } from '@/stores/visual';
import { useWheelEventsCounter } from '@/stores/wheelEventsCounter';
import { useWheelSession } from '@/stores/wheelSession';
import { mapActions } from 'pinia';

import router from './router';

import CommonNotify from '@/components/common/CommonNotify.vue';
import TimeLineCard from '@/components/timeline/TimeLineCard.vue';
import en from 'element-plus/lib/locale/lang/en';
import ru from 'element-plus/lib/locale/lang/ru';
import { useLicense } from './stores/license';

const ItemComponent = defineComponent({
  template: `

      <TimeLineCard :item="item" />

    `,

  props: ['item'],
  methods: {
    testClick() {
      //console.log('test ClickS', this.item);
    },
  },
  components: {
    TimeLineCard,
  },
});

import { useSocketExt } from './stores/socket';
const socketStore = useSocketExt();

const wsProtocol = location.protocol !== 'https:' ? 'ws' : 'wss';
let wsUrl;
const wsPort = location.port;

if (wsPort) {
  wsUrl = `${wsProtocol}://${location.hostname}:${wsPort}`;
} else {
  wsUrl = `${wsProtocol}://${location.hostname}`;
}

import { ElConfigProvider } from 'element-plus';

import windowId from '@/utils/windowId';
import { NConfigProvider, NNotificationProvider } from 'naive-ui';

windowId();

const main = createApp({
  // topNavDataset: Object.assign({}, document.querySelector('#top-nav').dataset),
  template:
    '<common-notify></common-notify><el-config-provider :locale="locale"><n-config-provider :theme-overrides="themeOverrides"><n-notification-provider><root-block><Teleport v-for="{name, target, props, listeners},i in dynamicComponents" :key="i" :to="target"><component :is="name" v-bind="props" v-model="props.modelValue" v-on="listeners"/></Teleport></root-block></n-notification-provider></n-config-provider></el-config-provider>',
  name: 'root-app',
  components: {
    'root-block': RootApp,
    ItemComponent,
    CommonNotify,
    ElConfigProvider,
    NNotificationProvider,
    NConfigProvider,
  },

  setup() {
    const themeOverrides = naiveUiThemeOverrides;
    return {
      loc: en,
      themeOverrides,
    };
  },

  data: () => ({
    dynamicComponents: [],

    rootVar: true,
    loadedVersions: false,
    options: {
      moduleCache: {
        vue: Vue,
        api: api,
        'element-plus': ElementPlus,
      },

      getFile(pat) {
        return new Promise((resolve, reject) => {
          api
            .post('/scl-processors-report-component', {
              filePath: pat,
            })
            .then(function ({ data }) {
              resolve(data);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      },

      addStyle(styleStr, scope) {
        const style = document.createElement('style');
        style.textContent = styleStr;
        const regexpSize = /^\s+--component:\s'([A-Za-z-]+)';$/m;
        const match = styleStr.match(regexpSize);
        if (match && match[1]) {
          style.setAttribute('component', match[1]);
        }
        if (scope) {
          style.setAttribute('scope', scope);
        }
        style.setAttribute('type', 'text/css');
        const ref = document.head.getElementsByTagName('style')[0] || null;
        document.head.insertBefore(style, ref);
      },

      log(type, ...args) {
        dev.log(type, ...args);
      },
    },

    topNavDataset: Object.assign(
      {},
      document.querySelector('#lvb-table') && document.querySelector('#top-nav').dataset
    ),
    menuSideBarDataset: Object.assign(
      {},
      document.querySelector('#lvb-table') && document.querySelector('#menu-side-bar').dataset
    ),
    lvbTableDataset: Object.assign(
      {},
      document.querySelector('#lvb-table') && document.querySelector('#lvb-table').dataset
    ),
    controlUsersDataset: Object.assign(
      {},
      document.querySelector('#lvb-table') && document.querySelector('#control-users').dataset
    ),
  }),
  computed: {
    ...mapStores(useNotify),
    ...mapStores(useVisual),
    ...mapStores(useTpws),
    ...mapStores(usePage),
    ...mapStores(useTpui),
    ...mapStores(useAuth),
    ...mapStores(usePrefs),
    ...mapStores(useFaultProfile),
    ...mapStores(useLicense),
    // ...mapStores(useToken),
    lang() {
      return this.prefs.language;
    },
    locale() {
      return this.lang == 'ru' ? ru : en;
    },
  },
  mounted() {
    this.$router.isReady().then(() => {
      if (this.$route.params['projectId']) {
        this.loadProjectStatus(this.$route.params['projectId']);
      }
      this.initState();
      this.visual.resetModals();
      const devMode = dev.gtCookie('dev');
      dev.log('devMode', devMode);
      if (devMode === 'true') {
        this.visual.devMode = true;
      }
    });

    window.addEventListener('project-version-update', (e) => {
      this.reloadProjectStatus();
    });
    window.addEventListener('scl-result-update', (e) => {
      this.reloadProjectStatus();
    });
    window.addEventListener('save-text', (e) => {
      var file = new File([e.detail.text], e.detail.filename, { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(file);
    });
    window.addEventListener('save-hex', (e) => {
      var file = new File([e.detail.text], e.detail.filename, { type: 'application/vnd.tcpdump.pcap;' });
      FileSaver.saveAs(file);
    });
    eventBus.on('noAuth', () => {
      this.auth.toggleAuth(false);
    });
    eventBus.on('noRefreshToken', () => {
      this.auth.logout({name: 'auth-login'});
    });
    eventBus.on('login', () => {
      if (this.$route.name !== 'auth-reset' && this.$route.name !== 'auth-signup') {
        this.$router.push({ name: 'auth-login' });
      } else {
      }
    });
    eventBus.on('license-problem', (result) => {
      this.license.storeLicenseError(result);
      this.$router.isReady().then(() => {
        dev.log('listen license problem');

        if (!this.$route.meta.noLicense) {
          this.$router.push({ name: 'license-problem' });
        }
      });
    });
    eventBus.on('logout', () => {
      this.logout();
    });
    eventBus.on('project-forbidden', () => {
      this.$router.push({name: 'client-root'});
    });
    ru['el']['pagination']['pagesize'] = ' на стр.';
    eventBus.on('message', (options) => {
      ElMessage({ duration: 5000, ...options });
    });
    eventBus.on('notify', (options) => {
      dev.log('notify');
      ElNotification(options);
    });
  },
  beforeUnmount() {
    eventBus.remove('noAuth');
    eventBus.remove('license-problem');
    eventBus.remove('login');
    eventBus.remove('logout');
    eventBus.remove('message');
    eventBus.remove('notify');
  },
  methods: {
    ...mapActions(useNotify, { initNotify: 'init' }),
    ...mapActions(useTpws, ['reloadProjectStatus', 'loadProjectStatus']),
    ...mapActions(usePrefs, ['uploadSchema']),
    ...mapActions(useNotifications, ['reloadNotifications']),
    ...mapActions(useVisual, ['resetModals']),
    ...mapActions(useReports, ['loadPreparedReports']),
    ...mapActions(useWheelSession, ['initSession']),
    ...mapActions(useFaultProfile, ['initFaultProfile']),
    ...mapActions(useWheelEventsCounter, ['initWheelEventsCounter']),
    initState() {
      this.initNotify();
      const mainReadyEvent = new Event('mainReady');
      this.uploadSchema().then(() => {
        document.dispatchEvent(mainReadyEvent);
      });
      this.loadPreparedReports();
      this.initWheelEventsCounter();
      this.initSession();
      this.initFaultProfile();
    },
    handleCurrentProject(project) {
      dev.log('project changed', project);
      this.uploadSchema().then(() => {
        // document.dispatchEvent(mainReadyEvent);
        dev.log('user prefs init');
      });
      // this.page.eventsPage = 1;
      // this.page.eventsOffTypes = [];
    },
    handleActiveVersion(verId) {
      const lastVersion = localStorage.getItem('lastActiveVersion');
      if (lastVersion !== verId) {
        localStorage.setItem('lastActiveVersion', verId);
        dev.log('Version changed', verId);
        this.page.eventsPage = 1;
        this.page.eventsOffTypes = [];
      }
    },
    handleViewedVersion(project) {
      dev.log('Version changed');
      // this.page.eventsPage = 1;
      // this.page.eventsOffTypes = [];
    },
    t(msg) {
      //console.log('msg', msg);
      return this?.$i18n.t(msg) || '';
    },
    logout() {
      this.auth.logout({ name: 'auth-login' }).then((url) => {
        //console.log('ret', url);
        this.$router.push(url);
      });
    },
    mainMethod(rrr) {},
    switchLocale(locale) {
      this.$i18next.changeLanguage(locale);
      api.post('/set-lang', { lang: locale }).then((response) => {
        this.reloadNotifications();
      });
    },
    switchLang(lang) {
      // locale.use(elLangs[lang]);
    },
  },
  watch: {
    lang: {
      handler(newLang) {
        this.switchLocale(newLang);
      },
      // force eager callback execution
      immediate: false,
    },
    'auth.isAuthorized': {
      handler(newAuth, oldAuth) {
        if (newAuth !== oldAuth) {
          dev.log('watch auth.isAuthorized', this.auth.isAuthorized);
          this.uploadSchema();
          this.resetModals();
        }
      },
      // force eager callback execution
      immediate: false,
    },
    'tpws.currentProject': {
      handler(newProject, oldProject) {
        if (newProject !== oldProject) {
          this.handleCurrentProject(newProject);
          this.reloadNotifications();
        }
      },
      // force eager callback execution
      immediate: false,
    },
    'tpws.activeVersionId': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleActiveVersion(newVal);
        }
      },
      // force eager callback execution
      immediate: false,
    },
  },
});

main.config.globalProperties.$appendComponent = function (name, target, props, listeners = {}) {
  this.$root.dynamicComponents.push({
    name,
    target,
    props,
    listeners,
  });
};

main.config.silent = false;
main.config.disableNoTranslationWarning = true;
main.config.globalProperties.$colors = colors;

main.config.globalProperties.$ = window.$;
main.config.globalProperties.$config = {};
main.config.globalProperties.$i18next = i18next;
main.config.globalProperties.$addTranslationNs = addTranslationNs;
main.config.globalProperties.$sclProcessors = undefined;
main.config.globalProperties.$qs = queryString;
main.config.globalProperties.$parseDataset = parseDataset;
main.config.globalProperties.$ls = ls;
main.config.globalProperties.emitter = emitter;
main.config.globalProperties.$slf = main;
main.config.globalProperties.$h = h;
main.config.globalProperties.tt = main.t;
main.config.globalProperties.$rt = router;

store.$router = markRaw(router);

uiComponents.forEach((component) => {
  main.component(component.name, component);
});

main.component('Head', Head);
main.component('mq-responsive', MqResponsive);

main.component('font-awesome-icon', FontAwesomeIcon);

const head = createHead();

main
  .use(I18NextVue, { i18next })
  .use(store)
  .use(head)
  .use(vSvg)
  .use(vIcon)
  .use(Sticky)
  .use(debouncePlugin)
  .use(LoadScript)
  .use(loadMod)
  .use(ScriptX)
  .use(VueObserveVisibility)
  .use(PortalVue)
  .use(scrollLockPlugin)
  .use(VueSmoothScroll)
  .use(VueSticky)
  .use(resizeDirective)
  .use(VueNativeSock, wsUrl, {
    // connectManually: true,
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
    store: socketStore,
    format: 'json',
    // mutations: mutations,
  })
  .use(Vue3Mq, {
    // config options here
    breakpoints: {
      vertical: 0,
      horizontal: 1260,
    },
  })
  .use(VueLocalStorage, {
    namespace: 'vue.',
  })
  .use(router)
  .use(naive)
  .use(ElementPlus, {
    locale: enLang,
  });

main.i18next = {
  plug: I18NextVue,
  opt: { i18next },
};
main.$i18next = i18next;
main.scrollToTopVexContent = () => {
  const elemList = document.getElementsByClassName('vex-content');
  [...elemList].forEach((elem) => {
    elem.scrollTop = 0;
  });
};
main.ready = (cb) => document.addEventListener('mainReady', cb);
main.ready(() => {});

main.changePage = () => {};

main.modalShow = (header = this.$t('Header'), content = $('<div></div>'), footer) => {
  const modal = $('#main-modal');
  modal.find('#main-modal-header').text(header);
  modal.find('#main-modal-content').html(content);
  modal.find('#main-modal-footer div:not(:last)').detach();
  if (footer) {
    modal.find('#main-modal-footer').prepend(footer);
  }
  modal.fadeIn(500);
};

main.modalHide = () => $('#main-modal').fadeOut(300);

main.offlineIed = [];
main.setIedOffline = (cp, ied) => {
  ajax.setIedOffline(cp, ied, (data) => {
    if (data.success == 'ОК') {
      main.offlineIed.splice(main.offlineIed.indexOf(ied), 1);
      dev.log(data.success);
    }
  });
};

main.valid = /^[\wа-я\.\-_]+@[\wа-я\.\-_]+\.[\wа-я\.\-_]+$/i; // Регекс валидации e-mail

main.$ = {};
main.$.selectble = {};
main.$.select = (id, cb) => {
  $(id + ' tbody').on('mousedown', 'tr', (e) => {
    dev.log(e);
    // Реакция только на 0-ю кнопку мыши, то есть левую
    if (e.originalEvent.button) {
      return;
    }

    main.$.selectble[id] = e;
    let isSelected = false;
    $(e.currentTarget).toggleClass('selected');
    if ($(e.currentTarget).hasClass('selected')) {
      isSelected = true;
    }
    $(e.delegateTarget).on('mouseenter', 'tr', (event) => {
      $(event.delegateTarget).css({ 'user-select': 'none' });
      if (isSelected) {
        $(event.currentTarget).addClass('selected');
      } else {
        $(event.currentTarget).removeClass('selected');
      }
    });
  });

  $('body').on('mouseup', 'tr', () => {
    for (const _id in main.$.selectble) {
      $(_id + ' tbody').off('mouseenter', 'tr');
      $(_id + ' tbody').css({ 'user-select': 'initial' });
    }
    main.$.selectble = {};
    cb();
  });
};

Object.defineProperty(main, 'cookies', {
  get: () => {
    const cookie = document.cookie;
    const cookies = {};

    const cookiesArr = cookie.split('; ');

    for (const c of cookiesArr) {
      const keys = c.split('=')[0].split('.'),
        val = c.split('=')[1];

      const rec = (obj, key, value, i = 0) => {
        if (~key[i].indexOf('___array')) {
          key[i] = key[i].substring(0, key[i].indexOf('___array'));
        }
        if (key.length - 1 == i) {
          if (value === 'true') {
            value = true;
          }
          if (value === 'false') {
            value = false;
          }
          obj[key[i]] = value;
          return;
        }
        if (!obj[key[i]]) {
          obj[key[i]] = ~key[i + 1].indexOf('___array') ? [] : {};
        }
        rec(obj[key[i]], key, value, i + 1);
      };

      rec(cookies, keys, val);
    }

    return cookies;
  },
  set: (cookie) => {
    if (typeof cookie == 'object') {
      for (const cookieName in cookie) {
        if (typeof cookie[cookieName] == 'object') {
          main.cookies = `${cookieName}=${JSON.stringify(cookie[cookieName])};path=/`;
        } else {
          main.cookies = `${cookieName}=${cookie[cookieName]};path=/`;
        }
      }
      return;
    }
    const cookieObject = {
      name: cookie
        .split(';')
        .filter(
          (p) =>
            p.indexOf('path=') != 0 &&
            p.indexOf('domain=') != 0 &&
            p.indexOf('expires=') != 0 &&
            p.indexOf('secure') != 0
        )[0]
        .split('=')[0],
      value: cookie
        .split(';')
        .filter(
          (p) =>
            p.indexOf('path=') != 0 &&
            p.indexOf('domain=') != 0 &&
            p.indexOf('expires=') != 0 &&
            p.indexOf('secure') != 0
        )[0]
        .split('=')[1],
      path: ~cookie.indexOf('path')
        ? ';path=' +
          cookie
            .split(';')
            .filter((p) => p.indexOf('path=') == 0)[0]
            .split('=')[1]
        : '',
      domain: ~cookie.indexOf('domain')
        ? ';domain=' +
          cookie
            .split(';')
            .filter((p) => p.indexOf('domain=') == 0)[0]
            .split('=')[1]
        : '',
      expires: ~cookie.indexOf('expires')
        ? ';expires=' +
          cookie
            .split(';')
            .filter((p) => p.indexOf('expires=') == 0)[0]
            .split('=')[1]
        : '',
      secure: ~cookie.indexOf('secure') ? 'secure' : '',
    };

    const rec = (
      object,
      nameParent,
      path = cookieObject.path,
      domain = cookieObject.domain,
      expires = cookieObject.expires,
      secure = cookieObject.secure
    ) => {
      for (const prop in object) {
        if (typeof object[prop] == 'object') {
          rec(
            object[prop],
            `${nameParent}.${prop + (Array.isArray(object) ? '___array' : '')}`,
            path,
            domain,
            expires,
            secure
          );
          continue;
        }
        document.cookie = `${nameParent}.${prop + (Array.isArray(object) ? '___array' : '')}=${
          object[prop]
        }${path}${domain}${expires}${secure}`;
      }
    };

    // Удаляем старые куки
    // if (cookieObject.value != '')
    //     main.cookies = `${cookieObject.name}=${cookieObject.path}${cookieObject.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT${cookieObject.secure}`
    const oldCookies = main.cookies;
    if (cookieObject.name in oldCookies) {
      if (typeof oldCookies[cookieObject.name] == 'object') {
        rec(
          oldCookies[cookieObject.name],
          cookieObject.name,
          undefined,
          undefined,
          ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
        );
      } else {
        document.cookie = `${cookieObject.name}=${cookieObject.path}${cookieObject.domain};expires=Thu, 01 Jan 1970 00:00:01 GMT${cookieObject.secure}`;
      }
    }

    try {
      cookieObject.value = JSON.parse(cookieObject.value);
      if (typeof cookieObject.value != 'object') {
        throw 'not object';
      }

      rec(cookieObject.value, cookieObject.name);
    } catch (e) {
      document.cookie = `${cookieObject.name}=${cookieObject.value}${cookieObject.path}${cookieObject.domain}${cookieObject.expires}${cookieObject.secure}`;
    }

    return cookieObject.value;
  },
});

// TODO: перенести в файл diagnosis.js
main.diagnosis = {};
main.diagnosis.parseValidationErrors = (error) => {
  const regexArr = [
    /The attribute '([^']+)' is not allowed\./,
    /The attribute '([^']+)' is required but missing\./,
    /This element is not expected. Expected is \(([^\)]+)\)\./,
    /This element is not expected. Expected is one of \(([^\)]+)\)\./,
    /('[^']*') is not a valid value of the atomic type '([^']+)'\./,
    /('[^']*') is not a valid value of the local atomic type\./,
    /('[^']*') is not a valid value of the union type '\{([^}]+)\}([^']+)'\./,
    /\[facet '([^']+)'\] The value '([^']+)' has a length of ('[^']+'); this exceeds the allowed maximum length of ('[^']+')\./,
    /\[facet '([^']+)'\] The value ('[^']*') has a length of ('[^']+'); this underruns the allowed minimum length of ('[^']+')\./,
    /\[facet 'pattern'\] The value ('[^']*') is not accepted by the pattern '([^']+)'\./,
    /\[facet '([^']+)'\] The value ('[^']*') is not an element of the set {([^}]+)}\./,
    /No match found for key-sequence \['([^']+)'\] of keyref '([^']+)'\./,
    /Duplicate key-sequence \[('[^']*')\] in key identity-constraint ('[^']*')\./,
    /Duplicate key-sequence \[('[^']*')\] in unique identity-constraint ('[^']*')\./,
    /Not all fields of key identity-constraint '([^']+)' evaluate to a node\./,
    /Missing child element\(s\)\. Expected is \(([^\)]+)\)\./,
    /Missing child element\(s\)\. Expected is one of \(([^\)]+)\)\./,
    /The value ('[^']*') does not match the fixed value constraint ('[^']*')\./,
  ];

  for (const regex of regexArr) {
    const exec = regex.exec(error);
    if (exec) {
      for (let i = 1; i < exec.length; i++) {
        // замена на i-1 для того чтобы идентификатор ключа совпадал с тем что попадает в локализатор
        exec[0] = exec[0].replace(exec[i], `{{${i - 1}}}`);
      }
      // ключи подставляемых переменных преобразуются из array в json в формате {{0:"",1:""}}
      let varStr = exec[0];
      let varObject = Object.values(exec.slice(1))
      varObject.interpolation = { escapeValue: false };
      error = i18next.t(varStr, varObject);
    }
  }

  return error;
};

main.diagnosis.parseValidationElementReference = (elementRef) => {
  const regexArr = [
    /Element '\{([^}]+)\}([^']+)', attribute '([^']+)', line ([^']+)/,
  ];

  for (const regex of regexArr) {
    const exec = regex.exec(elementRef);
    if (exec) {
      for (let i = 1; i < exec.length; i++) {
        // замена на i-1 для того чтобы идентификатор ключа совпадал с тем что попадает в локализатор
        exec[0] = exec[0].replace(exec[i], `{{${i - 1}}}`);
      }
      // ключи подставляемых переменных преобразуются из array в json в формате {{0:"",1:""}}
      elementRef = i18next.t(exec[0], Object.values(exec.slice(1)));
    }
  }

  return elementRef;
}

// console.log('env', import.meta.env);

if (import.meta.env ? import.meta.env.VITE_APP_HOT === 'vite' : process.env.NODE_VUE === 'vite') {
  main.mount('#root-app');
} else {
  window.main = main;
}

window.trace = function stackTrace() {
  var err = new Error();
  return err.stack;
};

window.helper = {
  goToHashShadow(root, hash) {
    const targetEl = document.querySelector(root).shadowRoot.querySelector(hash);
    dev.log('goToHashShadow', root, hash, targetEl);
    targetEl.scrollIntoView({ behavior: 'smooth' });
  },
};

window.dev = {
  log(e, ...rst) {
    // console.log(e, ...rst);
    const logs = this.gtCookie('logs');
    // console.log('logs', logs, import.meta.env ? import.meta.env.mode === 'development' : process.env.mode === 'development', process.env);
    if (
      logs === 'on' ||
      (logs !== 'off' &&
        (import.meta.env ? import.meta.env.mode === 'development' : process.env.NODE_ENV === 'development'))
    ) {
      let line = trace();
      let lines = line.split('\n');
      console.info(e, ...rst, lines[3].substring(lines[3].indexOf('('), lines[3].lastIndexOf(')') + 1));
    }
  },
  gtCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  },
  stCookie(name, value) {
    document.cookie = name + '=' + value + '; Path=/;';
  },
  rmCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },
};

window.$store = store;

// export const $rt = router;
export const globalProperties = main.config.globalProperties;
// export const $socket = main.config.globalProperties.$socket;

export { colors };
export { router };
export { i18next };
export default main;
