import { i18next } from '@/root.app';
import api from '@/services/api';
import TokenService from '@/services/tokenService';
import { useNotify } from '@/stores/notify';
import eventBus from '@/utils/eventBus';
import { defineStore } from 'pinia';

export const useAuth = defineStore({
  id: 'auth',
  share: {
    // An array of fields that the plugin will ignore.
    omit: [],
    // Override global config for this store.
    enable: true,
    initialize: true,
  },
  state: () => {
    return {
      user: null,
      admin: null,
      authorized: false,
    };
  },
  getters: {
    isAdmin(state) {
      return state.admin;
    },
    isAuthorized(state) {
      return state.authorized;
    },
    accessToken(state) {
      return localStorage.getItem('token');
    },
    userId(state) {
      return state.user?._id;
    },
    currentUser(state) {
      return state.user;
    },
  },
  actions: {
    setAuthUser(user) {
      this.user = user;
    },
    setToken(accessToken) {
      localStorage.setItem('token', accessToken);
    },
    async login(email, password, returnUrl) {
      return new Promise((resolve, reject) => {
        api
          .post(`/login`, { email, password })
          .then((response) => {
            if (response.data.success) {
              const { user, accessToken, refreshToken } = response.data;
              TokenService.updateLocalAccessToken(accessToken);
              this.user = user;
              this.authorized = true;
              resolve(returnUrl || '/');
            } else {
            }
          })
          .catch((error) => {
            console.error('login error', error);
            if (error.response) {
              if (error.response.data == 'Unauthorized' && error.response.status === 401) {
                eventBus.dispatch('message', {
                  message: i18next.t('Login failed. Check your login and password'),
                  close: true,
                  type: 'error',
                });
              } else if (error.response.data.status == 'error' && error.response.data.messages) {
                error.response.data.messages.forEach((msg) => {
                  eventBus.dispatch('message', { message: i18next.t(msg || ''), close: true, type: 'error' });
                });
              } else if (error.response.status === 500) {
                eventBus.dispatch('message', {
                  message: i18next.t('Login failed. {{message}} ({{code}})', {message: error.message, code: error.code}),
                  close: true,
                  type: 'error',
                });
              } else {
                eventBus.dispatch('message', {
                  message: i18next.t('Login failed. {{message}} ({{code}})', {message: error.message, code: error.code}),
                  close: true,
                  type: 'error',
                });                
              }
            } else {
              eventBus.dispatch('message', {
                message: i18next.t('Login failed. {{message}} ({{code}})', {message: error.message, code: error.code}),
                close: true,
                type: 'error',
              });
              if (error.code == "ERR_NETWORK") {
                eventBus.dispatch('message', {
                  message: i18next.t('Check CORS settings.'),
                  close: true,
                  type: 'warning',
                });
              }
            }
            this.authorized = false;
            reject(error);
          });
      });
    },
    forgot(email, returnUrl) {
      const notify = useNotify();
      return new Promise((resolve, reject) => {
        api
          .post(`/forgot`, { email })
          .then((response) => {
            if (response.data.status == 'success') {
              eventBus.dispatch('message', {
                message: i18next.t(response.data.message || ''),
                close: true,
                type: 'info',
              });
              resolve(returnUrl || '/');
            } else if (response.data.status == 'error') {
              eventBus.dispatch('message', {
                message: i18next.t(response.data.message || ''),
                close: true,
                type: 'error',
              });
              resolve(response.data.redirect ? response.data.redirect : returnUrl || '/');
            } else {
              resolve(response.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    reset(token, password, password_confirm, returnUrl) {
      const notify = useNotify();
      return new Promise((resolve, reject) => {
        api
          .post(`/reset`, { token, password, confirm: password_confirm })
          .then((response) => {
            if (response.data.status == 'success') {
              eventBus.dispatch('message', {
                message: i18next.t(response.data.message || ''),
                close: true,
                type: 'info',
              });
              if (response.data.redirect) {
                resolve(response.data.redirect || '/');
              } else {
                resolve(returnUrl || '/');
              }
            } else if (response.data.status == 'error') {
              eventBus.dispatch('message', {
                message: i18next.t(response.data.message || ''),
                close: true,
                type: 'error',
              });
              resolve(response.data.redirect ? response.data.redirect : null);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    activate(token, returnUrl) {
      const notify = useNotify();
      return new Promise((resolve, reject) => {
        api
          .post(`/activation`, { token })
          .then((response) => {
            if (response.data.status == 'success') {
              eventBus.dispatch('message', {
                message: i18next.t(response.data.message || ''),
                close: true,
                type: 'success',
              });
              if (response.data.redirect) {
                resolve(response.data.redirect || '/');
              } else {
                resolve(returnUrl || '/');
              }
            } else {
              resolve(null);
            }
            if (response.data.status == 'fail') {
              eventBus.dispatch('message', {
                message: i18next.t(response.data.message || ''),
                close: true,
                type: 'error',
              });
              if (response.data.redirect) {
                setTimeout(() => {
                  resolve(response.data.redirect || '/');
                }, 2000);
              } else {
                resolve(returnUrl || '/');
              }
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async signup(registerData, returnUrl) {
      //console.log('register user', registerData);
      return new Promise((resolve, reject) => {
        api
          .post(`/signup`, registerData)
          .then((response) => {
            if (response.data.success) {
              eventBus.dispatch('message', {
                message: i18next.t(response.data.message || ''),
                close: true,
                type: 'success',
              });
              eventBus.dispatch('logout');
              resolve(returnUrl || '/');
            } else {
            }
          })
          .catch((error) => {
            console.error('register error', error);
            reject(error);
          });
      });
    },
    async init() {
      return this.isAuthorized;
    },
    async getMe() {
      const me = await this.me();
      dev.log('aw me', me);
      return me;
    },
    me() {
      dev.log('me');
      return new Promise((resolve, reject) => {
        api
          .get('/me')
          .then((response) => {
            dev.log('resp', response);
            const { data } = response;
            dev.log('currentUser resolve', data);
            if (data?.user) {
              this.user = data?.user;
              this.admin = data?.isAdmin;
              this.authorized = true;
            } else {
              this.user = undefined;
              this.admin = false;
              this.authorized = false;
            }
            resolve(data);
          })
          .catch((error) => {
            console.error('currentUser error', error);
            this.authorized = false;
            resolve(undefined);
          });
      });
    },
    toggleAuth(val) {
      this.authorized = val;
    },
    async logout(returnUrl) {
      const { success } = await api.get(`/logout`);
      this.user = null;
      this.authorized = false;
      TokenService.clearLocalAccessToken();
      return returnUrl || '/';
    },
  },
});
