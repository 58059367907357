<template>
  <div class="signal-view position-relative">
    <div class="data-table">
      <div class="data-table-buttons events-top-controllers">
        <div class="dt-buttons btn-group">
          <template v-for="b in buttons">
            <div class="btn-group" v-if="b.children">
              <button
                class="btn btn-primary dropdown-toggle"
                :class="'data-table-' + b.name"
                :id="'submenu-' + b.name"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t(b.label) }}
              </button>

              <div class="dropdown-menu" :aria-labelledby="'submenu-' + b.name">
                <div class="dt-button-collection-title"></div>
                <template v-for="cb in b.children">
                  <a class="dt-button dropdown-item buttons-csv buttons-html5" tabindex="0" href="#"
                    ><span>{{ $t(cb.label) }}</span></a
                  >
                </template>
              </div>
            </div>
            <button
              class="btn btn-primary"
              :class="['data-table-' + b.name, !enableView && b.name === 'view-selected' ? 'disabled' : '']"
              @click="execAction(b.action, $event)"
              v-else
            >
              {{ $t(b.label) }}
            </button>
          </template>
        </div>
        <div v-if="!noSearch" id="vue_events_table_filter" class="dataTables_filter">
          <label
            >Поиск:<input
              v-model="searchString"
              type="search"
              class="form-control form-control-sm"
              placeholder=""
              aria-controls="wheel_events_table"
          /></label>
        </div>
      </div>
      <div class="event-types-block">
        <slot name="filters"></slot>
      </div>
      <div class="data-table-loading-wrapper pb-5" v-loading="loading">
        <!--        <loading :active.sync="loading" :can-cancel="false" :is-full-page="false"> </loading>-->

        <table class="data-table display" :class="className">
          <thead>
            <tr role="row">
              <template v-for="f in fields">
                <th v-if="f.visible !== false" :key="f.name" :class="columnSortClass(f)" @click="f.sortable && changeSort(f)">
                  {{ $t(f.label) }}
                </th>
              </template>
            </tr>
          </thead>

          <tbody>
            <data-row
              v-for="(rowData, idx) in pageRowsData"
              :key="rowData.id"
              :row-data="rowData"
              :fields="fields"
              :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
              @select="selectRow(idx)"
            ></data-row>
          </tbody>
          <tfoot v-if="!hideBottom">
            <tr role="row">
              <template v-for="f in fields">
                <th v-if="f.visible !== false" :key="f.name">
                  {{ $t(f.label) }}
                </th>
              </template>
            </tr>
          </tfoot>
        </table>
      </div>
      <div
          class="floating-pager"
          style="width: auto"
          :style="{ left: visual.contentLeft + Math.floor(visual.contentWidth / 2) + 'px' }"
      >
        <div class="floating-pager__inner">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-model:current-page="currentPage"
              :default-current-page="1"
              :page-sizes="[4, 7, 17, 50, 100, 300]"
              v-model:page-size="pageLength"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordsTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div class="data-pages">
        <div class="dataTables_paginate paging_simple_numbers" id="wheel_events_table_paginate"></div>
      </div>
    </div>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue';
// import loading from 'vue-loading';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/css/index.css';
import { debounce } from '@/plugins/debounce';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';


import api from '@/services/api';
import {firstBy} from "thenby";

const sortAlias = {
  start: 'startSort',
  duration: 'durationSort',
}

export default {
  name: 'svr-meta',
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    // buttons: {
    //   type: Array,
    // },
    fields: {
      type: Array,
    },
    lengthMenu: {
      type: Array,
    },
    noSearch: {
      default: false,
    },
    hideBottom: {
      default: false,
    },
    offTypes: {
      type: Array,
    },
    query: {
      type: Object,
    },
  },
  components: { DataRow, Loading },
  // directives: { loading },
  data: () => {
    return {
      sortables: [],
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      pageStart: 0,
      pageLength: 7,
      searchString: '',
      // searchTypes: [],
      rowsData: [],
      recordsTotal: 0,
      recordsFiltered: 0,
      loading: false,
      draw: 0,
      errors: [],
      selectedRows: [],
      buttons: [
        {
          name: 'view-all',
          label: 'View all',
          action: 'openAll',
        },
        {
          name: 'view-selected',
          label: 'View selected',
          action: 'openSelected',
        },
      ],
    };
  },
  computed: {
    ...mapStores(useVisual),
    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    rowsDataSorted(){
      const durField = this.fields.find(el=>(el.name === 'duration'))
      const normalized = this.rowsData.map(el=>({...el, startSort: el.start.sec * 1e6 + Math.round(el.start.nano / 1e3), durationSort: durField.render(null, null, el)}))
      const sorted = normalized.sort(firstBy(this.currentSortFieldA, this.currentSortDir === 1 ? 'asc': 'desc'))
      return sorted;
    },
    pageRowsData() {
      let start = (this.currentPage - 1) * this.pageLength;
      let end = this.currentPage * this.pageLength;
      if (end > this.rowsDataSorted.length) {
        end = this.rowsDataSorted.length;
      }
      let rows = this.rowsDataSorted.slice(start, end);
      return rows;
    },
    currentSortFieldA(){
      return sortAlias[this.currentSortField] || this.currentSortField;
    },
    enableView() {
      return this.selectedRows.length > 0;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    offTypesArray() {
      return this.offTypes && this.offTypes.map((e) => '!' + e);
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      let searchObj = {
        draw: this.draw,
        order,
        search,
        // start: 0,
        // length: Infinity,
      };

      if (this.query) {
        return this.$qs.stringify({ ...searchObj, ...this.query, removeUnfiltered: true });
      } else {
        return this.$qs.stringify(searchObj);
      }
    },
  },
  mounted() {
    this.setDefaultSort();
    this.loadData();
    dev.log('r', this.$route);
  },
  methods: {
    handleSizeChange(e) {
      this.pageLength = e;
      dev.log('set size', e);
    },
    handleCurrentChange(e) {
      // this.currentPage = e;
      dev.log('set page', e);
    },

    execAction(a, e) {
      dev.log('execAction', a, e);
      if (a) {
        this[a](e);
      }
    },
    openAll(e) {
      this.selectAll();
      this.$nextTick(() => {
        this.$emit('open-selected', e);
      });
    },
    selectAll() {
      this.selectedRows = this.rowsData.map((el, i) => i);
    },

    openSelected(e) {
      this.$emit('open-selected', e);
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      dev.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    loadData: debounce(function () {
      return this.debLoadData();
    }, 500),
    async debLoadData() {
      this.draw++;
      dev.log('sq', this.queryString);
      let dataUrl = this.url + '?' + this.queryString;
      this.selectedRows = [];
      this.loading = true;
      api
        .get(dataUrl)
        .then((response) => {
          // JSON responses are automatically parsed.
          // console.log('sv resp', response);
          this.rowsData = response.data.desc;
          this.recordsTotal = response.data.recordsTotal;
          this.recordsFiltered = response.data.recordsTotal;

          this.$emit('loadedRows', this.rowsData.length);
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.sortable) {
        if (f.name === this.currentSortField) {
          if (this.currentSortDir === 1) {
            return 'sorting_asc';
          }
          if (this.currentSortDir === -1) {
            return 'sorting_desc';
          }
        } else {
          return 'sorting';
        }
      } else {
        return ''
      }
    },
    changeSort(f) {
      dev.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      // this.loadData();
    },
  },
  watch: {
    query: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
    // pageLength: function (val, old) {
    //   if (val !== old) {
    //     this.loadData();
    //   }
    // },
    // pageStart: function (val, old) {
    //   if (val !== old) {
    //     this.loadData();
    //   }
    // },
  },
};
</script>

<style scoped>
.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
