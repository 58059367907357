import api from '@/services/api';
import { defineStore } from 'pinia';

import { useTpws } from '@/stores/tpws';
import {responseCatch} from '@/utils/responseCatchHandler';

export const useNotifications = defineStore({
  id: 'notifications',
  state: () => {
    return {
      dropdownVisible: false,
      context: undefined,
    };
  },
  getters: {
    list(state) {
      return this.contextResolver?.list;
    },
    initNotifyList(state) {
      return this.contextResolver?.initNotifyList;
    },

    contextResolver(state) {
      return state.context || this.getContext();
    },
  },
  actions: {
    viewedItemList(typeList) {
      const tpws = useTpws();
      api
        .post(`/projects/${tpws.currentProject}/remove-prepared-notification`, { typeList })
        .then((response) => {
          if (response.status) {
            this.reloadNotifications();
          } else {
            console.error('Error ignored', typeList);
          }
        })
        .catch((err) => {
          console.error(err.statusText);
        });
    },
    removeByType(type, versionType = 'common') {
      const list = this.context.list;
      const findIndex = list.findIndex((i) => i.type === type && i.versionType === versionType);
      if (findIndex !== -1) {
        list.splice(findIndex, 1);

        this.context.list = list;
      }
    },
    ignoreItemList(typeList) {
      const tpws = useTpws();
      api
        .post(`/projects/${tpws.currentProject}/ignore-notification`, { typeList })
        .then(({ data: response }) => {
          if (response.status) {
            const newList = this.list;

            this.context.initNotifyList = response.initNotifyList;

            typeList.forEach(({ type, versionType = 'common', ignored }) => {
              const findAtList = newList.find((n) => n.type === type && n.versionType === versionType);
              const findAtListIndex = newList.findIndex((n) => n.type === type && n.versionType === versionType);
              if (findAtList) {
                newList[findAtListIndex] = { ...findAtList, ignored };
              }
            });
            this.context.list = newList;
          } else {
            console.error('Error ignored', typeList);
          }
        })
        .catch((err) => {
          console.error(err.statusText);
        });
    },
    getContext() {
      return new Promise((resolve) => {
        const tpws = useTpws();

        if (tpws.currentProject) {
          api
            .get(`/projects/${tpws.currentProject}/notifications`)
            .then(({ data }) => {
              this.context = data;
              resolve(this.context);
            })
            .catch(responseCatch);
        } else {
          resolve(undefined);
        }
      });
    },
    async reloadNotifications() {
      this.getContext();
    },
    unloadNotifications() {
      dev.log('unloadNotifications');
      this.context = undefined;
    },
    async loadNotifications() {
      const tmp = this.contextResolver;
    },
  },
});
