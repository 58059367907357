<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.eventsCounterModalVisible"
    v-model:visible="visual.eventsCounterModalVisible"
    ref="modEx"
    @closeModal="closeModal"
    @setNewEventsCountInterval="setNewEventsCountInterval"
  >
    <template #content>
      <p>
        {{ $t(modalText) }}
      </p>
      <div :class="modal.name + '-modal__timepicker'">
        <timepicker
          v-bind:init-time="initTime"
          v-bind:on-focus="hideAlert"
          v-bind:output-result="updateValue"
        ></timepicker>
      </div>
      <div class="alert alert-danger d-none" :class="modal.name + '-modal__error'">
        {{ $t('Please enter valid data') }}
      </div>
    </template>
    <template #buttons>
      <button
        class="btn btn-secondary"
        type="button"
        data-dismiss="modal"
        aria-label="Cancel"
        @click="$refs.modEx.$emit('closeModal')"
      >
        {{$t('Cancel')}}</button
      ><button
        class="btn btn-primary"
        :class="[modal.name + '__submit-btn']"
        :type="modal.typeSubmit ? 'submit' : 'button'"
        :aria-label="modal.submitBtn ? modal.submitBtn : 'Save'"
        @click="$refs.modEx.$emit(modal.onSubmit)"
        :form="modal.typeSubmit ? `${modal.name}-modal__form` : ''"
      >
        {{ $t(modal.submitBtn ? modal.submitBtn : 'Save') }}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import Timepicker from '@/components/ui/Timepicker.vue';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useWheelEventsCounter } from '@/stores/wheelEventsCounter';
import { useWheelSession } from '@/stores/wheelSession';

export default {
  name: 'events-counter-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog, Timepicker },
  data: () => {
    return {
      modal: {
        name: 'events-counter',
        title: 'Change interval',
        onSubmit: 'setNewEventsCountInterval',
        submitBtn: 'OK',
      },
      modalText: 'Show the number of events for the period',
      value: {},
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useWheelEventsCounter),
    ...mapStores(useWheelSession),
    hours: function () {
      return Math.floor(this.wheelEventsCounter.interval / 3600);
    },
    minutes: function () {
      return Math.floor((this.wheelEventsCounter.interval - this.hours * 3600) / 60);
    },
    seconds: function () {
      return this.wheelEventsCounter.interval - this.hours * 3600 - this.minutes * 60;
    },
    initTime: function () {
      return {
        hours: this.hours.toString().padStart(2, '00'),
        minutes: this.minutes.toString().padStart(2, '00'),
        seconds: this.seconds.toString().padStart(2, '00'),
      };
    },
  },
  created: function () {
    this.setInitEventsCountInterval();
  },

  mounted() {},
  methods: {
    // setNewEventsCountInterval() {
    //   console.log('setNewEventsCountInterval');
    //   this.$emit('setNewEventsCountInterval', { format: this.selectedFormat, sort: this.selectedSort });
    //   this.$emit('update:visibleModal', false);
    // },
    closeModal() {
      dev.log('closeModal');
      this.visual.eventsCounterModalVisible = false;
    },
    updateValue: function (newValue) {
      this.value = newValue;
    },
    hideAlert: function () {
      $('.events-counter-modal__error').addClass('d-none');
    },
    getTimeString: function () {
      const timeString = this.value.hours + ':' + this.value.min + ':' + this.value.sec;
      return timeString;
    },
    setInitEventsCountInterval: function () {
      const timeString = this.initTime.hours + ':' + this.initTime.minutes + ':' + this.initTime.seconds;
      $('#eventsCounterToggle').attr(
        'title',
        this.$t('Displays the number of events for each IED for the period') + ': ' + timeString
      );
      this.wheelEventsCounter.timeString = timeString;
    },
    setNewEventsCountInterval: function () {
      if (this.value === 'invalid') {
        $('.events-counter-modal__error').removeClass('d-none');
        return;
      }
      const time = 3600 * +this.value.hours + 60 * +this.value.min + +this.value.sec;
      this.wheelEventsCounter.interval = time;
      this.wheelSession.saveProp('eventsCounterPeriod', time);
      this.wheelEventsCounter.timeString = this.getTimeString();
      $('#eventsCounterToggle').attr(
        'title',
        this.$t('Displays the number of events for each IED for the period') + ': ' + this.getTimeString()
      );
      d3.selectAll('.eventsCountTitle').text(
        () => this.$t('Number of events for the period') + ': ' + this.getTimeString()
      );
      this.closeModal();
    },
  },
  watch: {
    visibleModal: function (val) {
      dev.log('visibleModal', val);
      if (!val) {
        this.$emit('update:visibleModal', val);
      }
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
