<template>
  <mq-responsive group>
    <template #vertical>
      <top-nav-black :atrs="dataset" />
    </template>
    <template #horizontal>
      <vertical-menu :atrs="dataset" />
    </template>
  </mq-responsive>
</template>

<script>
// import { CONTROL_POINT } from '@/constants/media-query';
// import { MatchMedia } from 'vue-component-media-queries';
import VerticalMenu from '@/components/layout/VerticalMenu.vue';
import TopNavBlack from '@/components/layout/TopNavBlack.vue';

// const queries = `(min-width: ${CONTROL_POINT}px)`;

export default {
  data() {
    return {};
  },
  props: ['dataset'],
  // queries,
  components: { VerticalMenu, TopNavBlack },
  name: 'nav-menu',
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
