<template>
  <div class="fl-row">
    <label :for="index" class="preferences-label">
      {{ $t(up__class.userPreferencesDescription[index]) }}
    </label>
    <div v-if="byProject" class="preferences-use-common">
      <!--      <label>-->
      <!--        <input v-model="chk" :class="'user-preferences__' + index + '-default'" type="checkbox" :checked="checked" />-->
      <!--        &nbsp;{{ $t('Use general') }}</label-->
      <!--      >-->
      <t-checkbox
        :checked="checked"
        :fieldId="'user-preferences__' + index + '-default'"
        :label="$t('Use general')"
        @update:checked="onChecked"
      ></t-checkbox>
    </div>
    <div class="preferences-control">
      <template v-if="typeOfProp == 'array'">
        <select
          v-if="isVal"
          :class="className"
          @change="onChange('select', $event)"
          class="user-preferences form-control desctype-input"
        >
          <option
            v-for="option in compUserPreferencesSchemaIndex"
            :value="option"
            :selected="option === val ? true : false"
          >
            <template v-if="option">
              {{ option ? $t(option.toString()) : '' }}
            </template>
          </option>
        </select>
        <select v-else :class="className" :readonly="checked" class="user-preferences form-control desctype-input">
          <option
            v-for="option in compUserPreferencesSchemaIndex"
            :value="option"
            :selected="option === genVal ? true : false"
          >
            <template v-if="option">
              {{ option ? $t(option.toString()) : '' }}
            </template>
          </option>
        </select>
      </template>
      <template v-if="typeOfProp == 'boolean'">
        <vue-togg v-if="isVal" :value="val" checkedText="On" uncheckedText="Off" @click="onChange('togg', !val)" />
        <vue-togg v-else :value="genVal" checkedText="On" uncheckedText="Off" :disabled="checked" />
      </template>
      <template v-if="typeOfProp == 'string'">
        <input
          v-if="isVal"
          :class="className"
          type="text"
          :value="val"
          @change="onChange('input:string', $event)"
          class="user-preferences form-control"
        />
        <input
          :class="className"
          type="text"
          :value="genVal"
          :readonly="checked"
          class="user-preferences form-control"
        />
      </template>
      <template v-if="typeOfProp == 'number'">
        <input
          v-if="isVal"
          :class="className"
          type="number"
          :value="val"
          @change="onChange('input:number', $event)"
          class="user-preferences form-control 11"
        />
        <input
          v-else
          :class="className"
          type="number"
          :value="genVal"
          :readonly="checked"
          class="user-preferences form-control 22"
        />
      </template>
    </div>
  </div>
  <div v-if="false" class="form-group row">
    <label :for="index" class="col-sm-5 col-form-label preferences-label">
      {{ $t(up__class.userPreferencesDescription[index]) }}
    </label>
    <div class="col-sm-4">
      <template v-if="typeOfProp == 'array'">
        <select
          :class="className"
          @change="onChange('select', $event)"
          class="user-preferences form-control desctype-input"
        >
          <option
            v-for="option in compUserPreferencesSchemaIndex"
            :value="option"
            :selected="option === val ? true : false"
          >
            <template v-if="option">
              {{ option ? $t(option.toString()) : '' }}
            </template>
          </option>
        </select>
      </template>
      <template v-if="typeOfProp == 'boolean'">
        <vue-togg :value="val" checkedText="On" uncheckedText="Off" :disabled="checked" @click="onChange('togg', !val)" />
      </template>
      <template v-if="typeOfProp == 'string'">
        <input
          :class="className"
          type="text"
          :value="val"
          @change="onChange('input:string', $event)"
          class="user-preferences form-control"
        />
      </template>
      <template v-if="typeOfProp == 'number'">
        <input
          :class="className"
          type="number"
          :value="val"
          @change="onChange('input:number', $event)"
          class="user-preferences form-control"
        />
      </template>
    </div>
    <div class="col-sm-3" v-if="byProject">
      <label>
        <input v-model="chk" :class="'user-preferences__' + index + '-default'" type="checkbox" :checked="checked" />
        &nbsp;{{ $t('Use general') }}</label
      >
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/ui/ToggleButton.vue';
import VueTogg from '@/components/ui/VueTogg.vue';
import TCheckbox from '@/components/ui/TCheckbox.vue';

export default {
  name: 'user-preferences-row',
  props: [
    'mod',
    'gen-val',
    'val',
    'name',
    'index',
    'up__user',
    'up__class',
    'byProject',
    'null-value',
    'project-value',
  ],
  emits: ['update'],
  components: { ToggleButton, VueTogg, TCheckbox },
  data: () => {
    return {
      chk: false,
    };
  },
  computed: {
    compUserPreferencesSchemaIndex() {
      return this.up__class.userPreferencesSchema[this.index];
    },
    typeOfProp() {
      return Array.isArray(this.up__class.userPreferencesSchema[this.index])
        ? 'array'
        : this.up__class.userPreferencesSchema[this.index];
    },
    className() {
      return `user-preferences__${this.index}-${this.byProject ? 'project' : 'general'}`;
    },
    checked() {
      return this.val === null;
    },
    isVal(){
      return this.val !== undefined && this.val !== null
    }
  },
  methods: {
    onChecked() {
      if (this.checked) {
        this.$emit('update', this.genVal);
        // this.up__user.prefByProject[this.name][this.index] = this.genVal;
        // this.val = this.genVal;
      } else {
        this.$emit('update', null);
        // this.up__user.prefByProject[this.name][this.index] = null;
        // this.val = null;
      }
    },
    onChange(el, e) {
      dev.log('prop:onChange', e, e?.target?.valueAsNumber);
      switch (el) {
        case 'togg':
          this.$emit('update', e);
          break;
        case 'select':
          let val = e.target.selectedOptions[0]._value;
          this.$emit('update', val);
          break;
        case 'input:number':
          this.$emit('update', e.target.valueAsNumber);
          break;
        case 'input:string':
          this.$emit('update', e.target.value);
          break;
      }
    },
    tr(el) {
      const st = el.toString();
      dev.log('el', st, typeof st);
      return st ? this.$t(st) : st;
    },
  },
};
</script>

<style lang="scss">
.fl-row {
  label.preferences-label {
    margin-bottom: 0;
  }
  .preferences-use-common {
    label {
      margin-bottom: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.fl-row:not(:last-child) {
  margin-bottom: 10px;
}
.fl-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 38px;

  label.preferences-label {
    margin-bottom: 0;
  }
  .preferences-use-common {
    label {
      margin-bottom: 0 !important;
    }
  }
}
.preferences-label {
  flex-basis: 370px;
}
.preferences-use-common {
}
.preferences-control {
  flex-basis: 260px;
  display: flex;
  justify-content: flex-end;
  select {
    flex: 1;
  }
}
</style>
