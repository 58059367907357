import store from '@/stores';
import { defineStore } from 'pinia';
// import main from '@/root.app';
import { globalProperties } from '@/root.app';
// import { $socket } from '@/root.app';
import { i18next } from '@/root.app';

import api from '@/services/api';
import * as mutation from './vars/mutation-types';

import { useEvents } from '@/stores/events';
import { useNotifications } from '@/stores/notifications';
import { useProjectsList } from '@/stores/projectsList';
import { useReports } from '@/stores/reports';
import { useServerTime } from '@/stores/serverTime';
import { useTpws } from '@/stores/tpws';
import { usePrefs } from '@/stores/prefs';
import eventBus from '@/utils/eventBus';

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const useSocket = defineStore({
  id: 'socket',
  state: () => ({
    clients: [],
    clientId: undefined,
    lastMsg: '',
    loading: false,
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },
    isConnected: false,
    // Message content
    message: '',
    // Reconnect error
    reconnectError: false,
    // Heartbeat message sending time
    heartBeatInterval: 50000,
    // Heartbeat timer
    heartBeatTimer: 0,
  }),
  getters: {
    clientList: (state) => state.clients,
    currentId: (state) => state.clientId,
    // clientId: (state) => state.clientId,
  },
  actions: {
    setClientId(value) {
      // console.info('Message system setClientId', value);
      if (value) {
        this.clientId = value;
      } else {
        this.fixClientId(this.clientId);
      }
    },
    serverTime(val) {
      const serverTime = useServerTime();
      serverTime.adjustingTime(val);
    },
    startPending(val) {
      const reports = useReports();
      reports.startPending(val);
    },
    progressPending(val) {
      const reports = useReports();
      reports.progressPending(val);
    },
    progressEventsCache(val) {
      const events = useEvents();
      events.progressEventsCache(val);
    },
    progressEventsCacheStore(val) {
      const events = useEvents();
      events.progressEventsCacheStore(val);
    },
    eventsCacheReady(val) {
      const events = useEvents();
      events.eventsCacheReady(val);
    },
    eventsCacheSilentReady(val) {
      const events = useEvents();
      events.eventsCacheSilentReady(val);
    },
    projectDetails(val) {
      // console.log('projectDetails', val);
      const projectsList = useProjectsList();
      projectsList.gettingProjectDetails(val);
    },
    finishPending(val) {
      const reports = useReports();
      reports.finishPending(val);
    },
    setCacheStatus(val) {
      const events = useEvents();
      events.setCacheStatus(val);
    },
    errorPending(val) {
      const reports = useReports();
      reports.errorPending(val);
    },
    doRedirect(content) {
      const tpws = useTpws();
      if (content.key === tpws.projectName) {
        location.assign(content.redirect);
      }
    },
    goProjectPage(content) {
      const tpws = useTpws();
      const notifications = useNotifications();
      tpws.reloadProjectStatus();
      notifications.reloadNotifications();
      // if (content.key === tpws.projectName && location.pathname === `/projects/${tpws.projectName}`) {
      //   location.reload(true);
      // }
    },
    projectActiveChange(content) {
      const tpws = useTpws();
      const prefs = usePrefs();
      const { projectName, versionId } = content;
      window.dispatchEvent(new CustomEvent('project-version-update', { projectName, versionId }));

      if (content.userId.toString() !== prefs.userId && content.projectName === tpws.currentProject) {
        if (window.location.pathname.includes('open/wheel')) {
          return location.reload();
        }
        eventBus.dispatch('message', {
          message: i18next.t('The active version has been changed. Reload the page'),
          duration: 0,
          showClose: true,
          type: 'error',
        });
      }
    },
    projectViewedChange(content) {
      const { projectName, versionId } = content;
      const notifications = useNotifications();
      notifications.reloadNotifications();
      window.dispatchEvent(new CustomEvent('project-version-update', { detail: { projectName, versionId } }));
    },
    sclUpdateResult(content) {
      const tpws = useTpws();
      const notifications = useNotifications();
      tpws.reloadProjectStatus();
      notifications.reloadNotifications();

      const { projectName, result } = content;
      // console.log('SCL_END', projectName, result);
      window.dispatchEvent(new CustomEvent('scl-result-update', { detail: { projectName, result } }));
      if (tpws.currentProject === projectName) {
        result.forEach((scl) => {
          dev.log('scl', scl);
        });
      }
    },
    setClusterBounds(content) {
      const events = useEvents();
      events.setClusterBounds(content);
    },
    messageProcessing(msg) {
      switch (msg.type) {
        case 'id':
          this.setClientId(msg.content);
          break;
        case 'server-time':
          this.serverTime(msg.content);
          break;
        case 'PROJECT_DETAILS':
          this.projectDetails(msg.content);
          break;
        case 'EXPORT_START':
          this.startPending(msg.content);
          break;
        case 'EXPORT_PROGRESS':
          this.progressPending(msg.content);
          break;
        case 'CACHE_PROGRESS':
          this.progressEventsCache(msg.content);
          break;
        case 'STORE_PROGRESS':
          this.progressEventsCacheStore(msg.content);
          break;
        case 'STORE_FINISH':
          this.eventsCacheReady(msg.content);
          break;
        case 'STORE_SILENT_FINISH':
          this.eventsCacheSilentReady(msg.content);
          break;
        case 'EXPORT_ERROR':
          this.errorPending(msg.content);
          break;
        case 'EXPORT_FINISH':
          this.finishPending(msg.content);
          break;
        case 'EVENTS_CACHE':
          this.setCacheStatus(msg.content);
          break;
        case 'REDIRECT':
          this.doRedirect(msg.content);
          break;
        case 'PROJECT_READY':
          this.goProjectPage(msg.content);
          break;
        case 'ACTIVE_CHANGE':
          this.projectActiveChange(msg.content);
          break;
        case 'VIEWED_CHANGE':
          this.projectViewedChange(msg.content);
          break;
        case 'SCL_END':
          this.sclUpdateResult(msg.content);
          break;
        case 'cluster-bounds':
          this.setClusterBounds(msg.content);
          break;
      }
    },
    async fixClientId(value) {
      globalProperties.$socket.send(JSON.stringify({ type: 'fix-id', content: value }));
    },
    // Connection open
    SOCKET_ONOPEN(event) {
      globalProperties.$socket = event.currentTarget;
      this.isConnected = true;
      // console.info('Message system connected...', this.clientId, this.currentId, event);
      eventBus.dispatch('socket.ready');
      // When the connection is successful, start sending heartbeat messages regularly to avoid being disconnected by the server
      // todo heartBeat
      // this.heartBeatTimer = window.setInterval(() => {
      //   const message = 'Heartbeat message';
      //   this.isConnected &&
      //     main.config.globalProperties.$socket.sendObj({
      //       code: 200,
      //       msg: message,
      //     });
      // }, this.heartBeatInterval);
    },
    // Connection closed
    SOCKET_ONCLOSE(event) {
      this.isConnected = false;
      this.clientId = undefined;
      // Stop the heartbeat message when the connection is closed
      // todo heartBeat
      // window.clearInterval(this.heartBeatTimer);
      // this.heartBeatTimer = 0;
      console.info('The line is disconnected: ' + new Date());
      dev.log(event);
    },
    // An error occurred
    SOCKET_ONERROR(event) {
      console.error(event);
    },
    // Receive the message sent by the server
    SOCKET_ONMESSAGE(message) {
      // console.log('SOCKET_ONMESSAGE message.data', message);
      let msg = undefined;
      if (message?.type) {
        msg = message;
        this.messageProcessing(msg);
      } else if (isJson(message)) {
        msg = JSON.parse(message);
        if (msg.type) {
          this.messageProcessing(msg);
        }
      } else {
        console.info('untyped msg', message);
      }
      this.socket.message = msg;
      this.message = msg;
    },
    // Auto reconnect
    SOCKET_RECONNECT(count) {
      console.info('Message system reconnecting...', count);
    },
    // Reconnect error
    SOCKET_RECONNECT_ERROR() {
      this.reconnectError = true;
    },
  },
});

export function useSocketExt() {
  return useSocket(store);
}
