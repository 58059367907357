<template>
  <!--  <el-dialog-->
  <!--    width="50%"-->
  <!--    :append-to-body="true"-->
  <!--    :modal="true"-->
  <!--    :title="$t('Prepared reports')"-->
  <!--    v-model="visible"-->
  <!--    @close="hidePreparedReports"-->
  <!--  >-->
  <modal-dialog
    :modal="modal"
    v-show="visual.preparedReportsModalVisible"
    v-model:visible="visual.preparedReportsModalVisible"
    ref="modEx"
    @closeModal="hidePreparedReports"
    @doExport="doExport"
  >
    <template #content>
      <el-table :data="reports.preparedReports" style="width: 100%">
        <el-table-column property="filename" :label="$t('Filename')"></el-table-column>
        <el-table-column :label="$t('Actions')" align="center" width="200">
          <template v-slot="scope">
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="handleDelete(scope.$index, scope.row)"
            ></el-button>
            <el-button size="small" type="success" @click="handleDownload(scope.$index, scope.row)">{{
              $t('Download')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </modal-dialog>
  <!--  </el-dialog>-->
</template>
<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { mapStores } from 'pinia';
import { useReports } from '@/stores/reports';
import { useVisual } from '@/stores/visual';
import { mapActions } from 'pinia/dist/pinia';

export default {
  name: 'prepared-reports',
  components: {
    ModalDialog,
  },

  data: () => {
    return {
      // modal: {
      //   name: 'prepared',
      //   title: this.$t('Prepared reports'),
      //   // onSubmit: 'doExport',
      //   submitBtn: 'OK',
      // },
    };
  },
  computed: {
    ...mapStores(useReports),
    ...mapStores(useVisual),
    modal() {
      return {
        name: 'prepared',
        title: this.$t('Prepared reports'),
        // onSubmit: 'doExport',
        size: 'lg',
        submitBtn: 'OK',
      };
    },
  },
  methods: {
    ...mapActions(useReports, ['showPreparedReports', 'removePreparedReport']),
    doExport(e) {
      dev.log('doExport', e);
    },
    handleDelete(i, r) {
      dev.log(i, r);
      this.removePreparedReport({ filename: r.downloadPath });
    },
    handleDownload(i, r) {
      dev.log(i, r);
      var link = document.createElement('a');
      link.href = r.downloadPath;
      link.download = r.filename;
      link.click();
    },
    hidePreparedReports() {
      this.visual.preparedReportsModalVisible = false;
    },
  },
  watch: {
    'visual.preparedReportsModalVisible': {
      handler(val) {
        if (val) {
          window.dispatchEvent(new Event('viewedPrepared'));
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
button:focus,
textarea:focus,
input:focus {
  outline: none;
}
</style>
