<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M3.2799 15.7656H7.3503C7.47664 15.7663 7.60189 15.7421 7.71885 15.6943C7.83581 15.6466 7.9422 15.5762 8.0319 15.4872L14.6751 8.83439L17.4015 6.16559C17.4915 6.07635 17.5629 5.97017 17.6116 5.85318C17.6604 5.7362 17.6855 5.61072 17.6855 5.48399C17.6855 5.35726 17.6604 5.23178 17.6116 5.1148C17.5629 4.99781 17.4915 4.89164 17.4015 4.80239L13.3311 0.683991C13.2419 0.594012 13.1357 0.522593 13.0187 0.473855C12.9017 0.425117 12.7762 0.400024 12.6495 0.400024C12.5228 0.400024 12.3973 0.425117 12.2803 0.473855C12.1633 0.522593 12.0571 0.594012 11.9679 0.683991L9.2607 3.40079L2.5983 10.0536C2.50933 10.1433 2.43894 10.2497 2.39116 10.3666C2.34339 10.4836 2.31917 10.6089 2.3199 10.7352V14.8056C2.3199 15.0602 2.42104 15.3044 2.60108 15.4844C2.78111 15.6645 3.02529 15.7656 3.2799 15.7656ZM12.6495 2.71919L15.3663 5.43599L14.0031 6.79919L11.2863 4.08239L12.6495 2.71919ZM4.2399 11.1288L9.9327 5.43599L12.6495 8.15279L6.9567 13.8456H4.2399V11.1288ZM18.6399 17.6856H1.3599C1.10529 17.6856 0.861115 17.7867 0.68108 17.9668C0.501045 18.1468 0.399902 18.391 0.399902 18.6456C0.399902 18.9002 0.501045 19.1444 0.68108 19.3244C0.861115 19.5044 1.10529 19.6056 1.3599 19.6056H18.6399C18.8945 19.6056 19.1387 19.5044 19.3187 19.3244C19.4988 19.1444 19.5999 18.9002 19.5999 18.6456C19.5999 18.391 19.4988 18.1468 19.3187 17.9668C19.1387 17.7867 18.8945 17.6856 18.6399 17.6856Z"
      fill="#1757BD"
    />
  </svg>
</template>

<script>
export default {
  name: 'edit-icon',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
