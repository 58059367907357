import ObserveVisibility from './directives/observe-visibility';

// Install the components
export function install(app) {
  app.directive('observe-visibility', ObserveVisibility);
  /* -- Add more components here -- */
}

export { ObserveVisibility };

/* -- Plugin definition & Auto-install -- */
/* You shouldn't have to modify the code below */

// Plugin
const plugin = {
  // eslint-disable-next-line no-undef
  // version: VERSION,
  install,
};

export default plugin;
