<template>
  <div class="items-list-hack">
    <div class="items-list" v-resize="onResize">
      <limited-item
        v-for="(item, idx) in itemsArray"
        :parent-size="parentSize"
        @inViewChange="inViewChange($event, item, idx)"
        :item="item"
        :key="item.title + idx + prefs.descriptionType"
        :type="type"
        :class="{
          'items-list__taged-item': type === 'tagged',
          'items-list__iconed-item': type === 'iconed',
          'not-fit': !item.inView,
        }"
      >
        <div class="items-list__tag">
          <div class="items-list__tag-title">{{ item.title }}</div>
        </div>
      </limited-item>
    </div>
  </div>
</template>

<script>
import LimitedItem from '@/components/clusters/LimitedItem.vue';
// import resize from 'vue-resize-directive';
import { mapStores } from 'pinia';
import { usePrefs } from '@/stores/prefs';

export default {
  name: 'limited-list',
  props: {
    items: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  directives: {
    // resize,
  },
  components: {
    LimitedItem,
  },
  data: () => {
    return {
      itemsArray: [],
      els: [],
      parentSize: new DOMRect(),
    };
  },
  created() {
    // this.genList();
  },
  mounted() {
    this.genList();
  },
  computed: {
    ...mapStores(usePrefs),
    itemsVisible() {
      return this.itemsArray.filter((el) => el.inView);
    },
    itemsHidden() {
      return this.itemsArray.filter((el) => !el.inView);
    },
    maxRight() {
      let maxR = 0;
      this.itemsVisible.forEach((el) => {
        if (maxR < el.right) {
          maxR = el.right;
        }
      });
      return maxR;
    },
  },
  methods: {
    genList() {
      this.itemsArray = [];
      this.items.forEach((el) => {
        this.itemsArray.push({ ...el, inView: true, right: 0 });
      });
    },
    onResize(e) {
      const par = this.$el.getBoundingClientRect();
      // console.log('p', JSON.stringify(par));
      if (JSON.stringify(this.parentSize) !== JSON.stringify(par)) {
        this.genList();
        this.$nextTick(() => {
          this.parentSize = par;
        });
      }

      // this.$nextTick(() => {});

      // this.genList();
      // console.log('parent res', this.parentSize);
    },
    inViewChange(e, i, x) {
      // console.log(e, i, x);
      const newEl = { ...i, inView: e.inView, right: e.right };
      // console.log('newEl', newEl);
      if (i.inView !== e.inView || i.right !== e.right) {
        this.itemsArray[x] = newEl;
      }
    },
  },
  watch: {
    items: function () {
      this.genList();
    },
    itemsHidden: function () {
      this.$emit('showHidden', this.itemsHidden, this.maxRight);
    },
  },
};
</script>

<style scoped></style>
