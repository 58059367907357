<template>
  <div id="signals-table" class="container1">
    <div class="content-block">
      <div class="page-block">
        <div class="page-block__header">
          <div class="page-actions">
            <div class="page-actions__buttons pointer-cursor" @click="goBackToClusters">
              <div class="ui-button">
                <div class="ui-button__icon">
                  <img src="/images/caret_left.svg" loading="lazy" alt="" />
                </div>
                <div class="ui-button__lable"><div>Назад</div></div>
              </div>
            </div>
            <div class="page-actions__editable-header">
              <div class="editable-header__label"><div>12 февраля 2021</div></div>
              <div class="editable-header__main-block">
                <div class="editable-header__title-block">
                  <div class="editable__text page-header__text">
                    <editable-span
                      :class="{ 'gen-cluster-name': !clusterNameReal }"
                      v-model="clusterName"
                      @change="saveClusterName"
                    ></editable-span>
                  </div>
                  <div class="editable__action d-none">
                    <img src="/images/edit.svg" loading="lazy" alt="" />
                  </div>
                </div>

                <el-popover placement="bottom-start" width="430" trigger="click">
                  <div class="flex-wide-top flex-wide-top--padding-24p">
                    <div class="popup-info__header">
                      <div class="popup-event-header__title"><div>Описание кластера</div></div>
                      <div class="popup-event-header__close-btn hide">
                        <img src="/images/remove.svg" loading="lazy" alt="" />
                      </div>
                    </div>
                    <div class="popup-info__body">
                      <div class="editable-multiline">
                        <div class="editable-multiline__text">
                          <editable-span
                            :class="{ 'gen-cluster-name': !clusterNameReal }"
                            v-model="clusterDesc"
                            multiline
                            @change="saveClusterDesc"
                          ></editable-span>
                        </div>
                        <div class="editable-multiline__edit-icon d-none">
                          <img src="/images/edit-btn.svg" loading="lazy" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="popup-info__actions"><div class="empty"></div></div>
                  </div>

                  <div slot="reference" class="editable-header__info-block">
                    <img src="/images/info.svg" loading="lazy" alt="" />
                  </div>
                </el-popover>
              </div>
            </div>
            <div class="spacer-grow"></div>
            <div class="page-actions__tags-block">
              <tags></tags>
            </div>
          </div>
        </div>
        <div class="page-block__body">
          <page-toolbar></page-toolbar>
          <div class="signals-block">
            <div class="signals-block__main">
              <div class="signals-view">
                <div class="signals-view__toolbar">
                  <div class="signals-top-action grow-02">
                    <div class="ui-text-field">
                      <div class="ui-select__text"><div>Период с 20:16:56.346000 по 20:16:57.645000</div></div>
                    </div>
                  </div>
                  <div class="signals-top-action">
                    <div class="ui-select border-left-2">
                      <div class="ui-select__text"><div>Вид по умолчанию</div></div>
                      <div class="ui-select__action">
                        <img src="/images/caret_down.svg" loading="lazy" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="signals-top-action">
                    <div class="ui-select border-left-2">
                      <div class="ui-select__text"><div>Метка времени процесс</div></div>
                      <div class="ui-select__action">
                        <img src="/images/caret_down.svg" loading="lazy" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="signals-view__body">
                  <signal-add :visibleDialog.sync="visibleSignalAdd">
                    <div class="modal-window__layout">
                      <div class="modal-window__sidebar">
                        <div class="side-filter">
                          <div class="side-filter__header"><div>ФИльтр</div></div>
                          <div class="side-filter__body">
                            <div class="filter-body__block">
                              <div class="ui-search">
                                <div class="ui-search__pre-icon">
                                  <img src="/images/search-blue.svg" loading="lazy" alt="" />
                                </div>
                                <div class="ui-search__placeholder"><div>Поиск</div></div>
                              </div>
                            </div>
                            <div class="filter-body__block">
                              <div class="filter-group">
                                <div class="filter-group__header">
                                  <div class="filter-group-header">
                                    <div class="filter-group-header__action">
                                      <img src="/images/caret_up.svg" loading="lazy" alt="" />
                                    </div>
                                    <div class="filter-group-header__title"><div>Устройства</div></div>
                                  </div>
                                </div>
                                <div class="filter-group__body">
                                  <div class="filter-list">
                                    <div class="filter-list__search">
                                      <div class="ui-search">
                                        <div class="ui-search__pre-icon">
                                          <img src="/images/search-blue.svg" loading="lazy" alt="" />
                                        </div>
                                        <div class="ui-search__placeholder"><div>Поиск</div></div>
                                      </div>
                                    </div>
                                    <div class="filter-list__body">
                                      <div class="list-checkbox">
                                        <div class="list-checkbox__icon">
                                          <img src="/images/checkbox-checked.svg" loading="lazy" alt="" />
                                        </div>
                                        <div class="list-checkbox__title"><div>Siemens</div></div>
                                      </div>
                                      <div class="list-checkbox">
                                        <div class="list-checkbox__icon">
                                          <img src="/images/checkbox.svg" loading="lazy" alt="" />
                                        </div>
                                        <div class="list-checkbox__title"><div>Siemens</div></div>
                                      </div>
                                      <div class="list-checkbox">
                                        <div class="list-checkbox__icon">
                                          <img src="/images/checkbox.svg" loading="lazy" alt="" />
                                        </div>
                                        <div class="list-checkbox__title"><div>Siemens</div></div>
                                      </div>
                                    </div>
                                    <div class="filter-list__action">
                                      <div class="filter-list-action">
                                        <div class="filter-list-action__show-all"><div>Показать всё</div></div>
                                        <div class="filter-list-action__reset"><div>Сбросить</div></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="filter-body__block">
                              <div class="filter-group">
                                <div class="filter-group__header">
                                  <div class="filter-group-header">
                                    <div class="filter-group-header__action">
                                      <img src="/images/caret_up.svg" loading="lazy" alt="" />
                                    </div>
                                    <div class="filter-group-header__title"><div>Протокол</div></div>
                                  </div>
                                </div>
                                <div class="filter-group__body">
                                  <div class="filter-list">
                                    <div class="filter-list__body">
                                      <div class="list-checkbox">
                                        <div class="list-checkbox__icon">
                                          <img src="/images/checkbox-checked.svg" loading="lazy" alt="" />
                                        </div>
                                        <div class="list-checkbox__title"><div>label</div></div>
                                      </div>
                                      <div class="list-checkbox">
                                        <div class="list-checkbox__icon">
                                          <img src="/images/checkbox.svg" loading="lazy" alt="" />
                                        </div>
                                        <div class="list-checkbox__title"><div>label</div></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="filter-body__block">
                              <div class="filter-group">
                                <div class="filter-group__header">
                                  <div class="filter-group-header">
                                    <div class="filter-group-header__action">
                                      <img src="/images/caret_down-1.svg" loading="lazy" alt="" />
                                    </div>
                                    <div class="filter-group-header__title filter-group-header__title--collapsed">
                                      <div>тип сигнала</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="side-filter__status">
                            <div class="filter-status-text"><div>Найдено 5 сигналов</div></div>
                          </div>
                          <div class="side-filter__actions">
                            <div class="ui-button ui-button--active m-b-8">
                              <div class="ui-button__lable ui-button__lable--active">
                                <div>Применить фильтр</div>
                              </div>
                            </div>
                            <div class="ui-button">
                              <div class="ui-button__lable"><div>Сбросить фильтр</div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-window__main">
                        <div class="modal-window__header modal-window__header--top-align">
                          <div class="modal-header__panel">
                            <div class="header-title">
                              <div class="header-title__text"><div>сигналы события</div></div>
                              <div class="header-title__buttons">
                                <div class="buttons-panel">
                                  <div class="ui-button">
                                    <div class="ui-button__icon--no-label">
                                      <img src="/images/plus.svg" loading="lazy" alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div @click="visibleSignalAdd = false" class="modal-header__close-btn">
                            <img src="/images/modal-close-crossline-black.svg" loading="lazy" alt="" />
                          </div>
                        </div>
                        <div class="modal-window__body">
                          <div class="signals-table">
                            <div class="signals-table__row signals-table__row--header">
                              <div class="signals-table__cell"><div>Сигнал</div></div>
                              <div class="signals-table__cell"><div>Описание сигнала</div></div>
                              <div class="signals-table__cell"><div>имя устройства</div></div>
                              <div class="signals-table__cell"><div>Описание устройства</div></div>
                              <div class="signals-table__cell"><div>Имя блока управления</div></div>
                              <div class="signals-table__cell"><div>Изменения</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                            <div class="signals-table__row">
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>PROT/PTOC6$Str</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Siemens</div></div>
                              <div class="signals-table__cell"><div>Block 1</div></div>
                              <div class="signals-table__cell"><div>+</div></div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-window__actions">
                          <div class="modal-actions">
                            <div class="modal-actions__col">
                              <div class="ui-button min-w-200">
                                <div class="ui-button__lable"><div>Отменить</div></div>
                              </div>
                            </div>
                            <div class="modal-actions__col">
                              <div class="ui-button ui-button--active">
                                <div class="ui-button__lable ui-button__lable--active">
                                  <div>Отобразить выделенное</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </signal-add>
                  <div class="signals-list">
                    <div class="signals-list__top-panel">
                      <div class="signals-align-sidebar">
                        <div class="signals-list__top-panel-content">
                          <div class="signals-list__name"><div>Сигналы</div></div>
                          <div class="spacer-grow"></div>
                          <div class="signals-list__actions">
                            <div class="buttons-panel">
                              <div class="ui-button" @click="showAdd">
                                <div class="ui-button__icon--no-label">
                                  <img src="/images/plus.svg" loading="lazy" alt="" />
                                </div>
                              </div>
                              <div class="ui-button m-l-4">
                                <div class="ui-button__icon--no-label">
                                  <img src="/images/cog-blue.svg" loading="lazy" alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="signals-align-main">
                        <div class="signals-list__toggle-view-panel">
                          <div class="signals-list__toggle-view">
                            <div class="toggle-view__switch">
                              <img src="/images/on.svg" loading="lazy" alt="" />
                            </div>
                            <div class="toggle-view__label"><div>Показать все</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="signals-list__body">
                      <signal-component
                        v-for="(signal, idx) in signals"
                        :class="{ 'blue-bg': idx % 2 }"
                        :key="idx + '-' + signal.signal_id"
                        :signal="signal"
                        :tstart="signals.tstart"
                        :tend="signals.tend"
                        :timeline.sync="signalsTimelinesArray[idx]"
                        @timelineUpdate="syncGraphs"
                      ></signal-component>
                    </div>
                  </div>
                  <div class="signals-list border-top-2">
                    <div class="signals-list__top-panel">
                      <div class="signals-align-sidebar">
                        <div class="signals-list__top-panel-content">
                          <div class="signals-list__name"><div>Осциллограммы</div></div>
                          <div class="spacer-grow"></div>
                          <div class="signals-list__actions">
                            <div class="buttons-panel">
                              <div class="ui-button">
                                <div class="ui-button__icon--no-label">
                                  <img src="/images/plus.svg" loading="lazy" alt="" />
                                </div>
                              </div>
                              <div class="ui-button m-l-4">
                                <div class="ui-button__icon--no-label">
                                  <img src="/images/cog-blue.svg" loading="lazy" alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="signals-align-main">
                        <div class="signals-list__toggle-view">
                          <div class="toggle-view__switch">
                            <img src="/images/on.svg" loading="lazy" alt="" />
                          </div>
                          <div class="toggle-view__label"><div>Разделить</div></div>
                        </div>
                      </div>
                    </div>
                    <div class="signals-list__body">
                      <!--                      <signal-component class="blue-bg">
                        <template v-slot:props>
                          <div class="signal-props__item color-yellow">
                            <div class="signal-vals__prop"><div class="text-block">Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                          <div class="signal-props__item color-green">
                            <div class="signal-vals__prop"><div>Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                          <div class="signal-props__item color-blue">
                            <div class="signal-vals__prop"><div>Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                        </template>
                      </signal-component>
                      <signal-component>
                        <template v-slot:props>
                          <div class="signal-props__item color-yellow">
                            <div class="signal-vals__prop"><div class="text-block">Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                          <div class="signal-props__item color-green">
                            <div class="signal-vals__prop"><div>Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                          <div class="signal-props__item color-blue">
                            <div class="signal-vals__prop"><div>Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                        </template>
                      </signal-component>
                      <signal-component class="blue-bg">
                        <template v-slot:props>
                          <div class="signal-props__item color-yellow">
                            <div class="signal-vals__prop"><div class="text-block">Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                          <div class="signal-props__item color-green">
                            <div class="signal-vals__prop"><div>Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                          <div class="signal-props__item color-blue">
                            <div class="signal-vals__prop"><div>Amp_1</div></div>
                            <div class="signal-vals__val"><div>7A</div></div>
                          </div>
                        </template>
                      </signal-component>-->
                    </div>
                  </div>
                  <div class="signals-list-events"></div>
                </div>
              </div>
            </div>
            <div class="signals-block__widgets-panel">
              <widget-ruler></widget-ruler>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';

import api from '@/services/api';
import EventsTable from '@/components/events/EventsTable.vue';
import ScrollTo from 'vue-scrollto';
import TagsList from '@/components/ui/TagsList.vue';
import EditableText from '@/components/ui/EditableText.vue';
import EditableSpan from '@/components/ui/EditableSpan.vue';
import MainSidebar from '@/components/unused/MainSidebar.vue';
import MainTopNav from '@/components/unused/MainTopNav.vue';
import Tags from '@/components/ui/Tags.vue';
import PageToolbar from '@/components/unused/PageToolbar.vue';
import SignalAdd from '@/components/modals/SignalAdd.vue';
import SignalComponent from '@/components/signals/SignalComponent.vue';
import WidgetRuler from '@/components/signals/WidgetRuler.vue';

import draggable from 'vue3-draggable';
// import Dygraph from 'dygraphs';
import Dygraph from '../../../../vendor/dygraph';
// import Dygraph from '../../../public/js/dygraph.min';
import DygraphTpui from '../../../../vendor/dygraph/dygraph-tpui';
import DygraphSync from '../../../../vendor/dygraph/dygraph-sync';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
// window.Dygraph = _DygraphRoot;

export default {
  name: 'clusters-events-page',
  components: {
    MainSidebar,
    MainTopNav,
    Tags,
    PageToolbar,
    SignalAdd,
    SignalComponent,
    WidgetRuler,
    EventsTable,
    TagsList,
    EditableText,
    EditableSpan,
    draggable,
  },
  props: {
    cluster: String,
    page: { type: Number, default: 1 },
    archive: Object,
    eventsSearchStr: String,
  },
  data: () => {
    return {
      signalsData: {},
      signalsArr: [1, 2, 3],
      visibleSignalAdd: false,
      scrollOptions: {
        container: 'body',
        easing: 'ease-in-out',
        offset: -60,
        force: false,
        cancelable: true,
        x: false,
        y: true,
      },
      tagsRef: [],
      prevRoute: null,
      clusterObj: {},
      clusterName: '',
      clusterNameReal: false,
      clusterDescReal: false,
      clusterNameEdit: '',
      clusterNameEv: '',
      clusterNameDT: '',
      clusterDesc: '',
      clusterDescEdit: '',
      clusterLeft: 0,
      clusterRight: 0,
      // clusterSize: 0,
      input3: '',
      select: undefined,
      editClusterName: false,
      editClusterDesc: false,
      quickSearch: '',
      onTypes: [],
      offTypes: [],
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      tpui: undefined,
      user: undefined,
      eventsCount: 0,
      signalsCount: 0,
      svrCount: 0,
      pcapsCount: 0,
      currentTab: 'events',
      dygraphVisible: false,
      buttonsVisible: false,
      errors: [],
      eventsButtons: [
        {
          name: 'print',
          label: 'Print',
        },
        {
          name: 'export-sub',
          label: 'Export',
          children: [
            {
              name: 'pdf',
              label: 'PDF',
              extAction: 'eventsExportPdf',
            },
            {
              name: 'csv',
              label: 'CSV',
              extAction: 'eventsExportCsv',
            },
            {
              name: 'xls',
              label: 'Excel',
              extAction: 'eventsExportXls',
            },
          ],
        },
        // {
        //   name: 'select-all',
        //   label: 'Select all',
        //   action: 'selectAll',
        // },
        // {
        //   name: 'view-selected',
        //   label: 'View selected',
        //   action: 'openSelected',
        // },
      ],
      sync: undefined,
      signalsTimelinesArray: [],
    };
  },
  computed: {
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
    signals() {
      return this.signalsData.signals || [];
    },
    clusterNameGen() {
      if (this.clusterName) {
        return this.clusterName;
      } else {
        if (this.clusterNameEv) {
          return this.clusterNameEv;
        } else {
          return this.clusterNameDT;
        }
      }
    },
    clusterDescGen() {
      if (this.clusterDesc) {
        return this.clusterDesc;
      } else {
        if (this.clusterNameEv) {
          return this.clusterNameEv;
        }
      }
    },
    offset() {
      return this.user && this.user.preferences && this.user.preferences.timezoneOffset;
    },
    dateNow() {
      try {
        return new DateNano(this.$route.query.time).setTimezoneOffset(this.offset).customFormat();
      } catch (e) {}
    },
    dateBefore() {
      try {
        return new DateNano(this.$route.query.time - this.$route.query.timeBefore)
          .setTimezoneOffset(this.offset)
          .customFormat();
      } catch (e) {}
    },
    dateAfter() {
      try {
        return new DateNano(Number(this.$route.query.time) + Number(this.$route.query.timeAfter))
          .setTimezoneOffset(this.offset)
          .customFormat();
      } catch (e) {}
    },
    eventsUrl() {
      return 'events2-exp';
    },
    signalsUrl() {
      return 'getsignalsmeta';
    },
    pcapsUrl() {
      return 'getpcapsmeta';
    },
    oscsUrl() {
      return 'oscs';
    },
    signalsTimeMiddle() {
      return this.$route.name === 'clusters-profile'
        ? Math.ceil((this.clusterLeft + this.clusterRight) / 2)
        : Number(this.$route.query.time);
    },
    signalsTimeBefore() {
      const timeBefore =
        this.$route.name === 'clusters-profile'
          ? this.signalsTimeMiddle - this.clusterLeft
          : Number(this.$route.query.timeBefore);
      return this.$tpui && timeBefore + this.$tpui.faultprofile.tbefore * 1e6;
    },
    signalsTimeAfter() {
      const timeAfter =
        this.$route.name === 'clusters-profile'
          ? this.clusterRight - this.signalsTimeMiddle
          : Number(this.$route.query.timeAfter);
      return this.$tpui && timeAfter + this.$tpui.faultprofile.tafter * 1e6;
    },
    eventsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    signalsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    oscsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    pcapsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$t('Goose') },
        { name: 'SV', label: this.$t('SV') },
        { name: 'SRV', label: this.$t('Service events') },
        { name: 'ARP', label: this.$t('ARP') },
        { name: 'MMS', label: this.$t('MMS') },
        { name: 'USER', label: this.$t('User events') },
      ];
    },
    _eq() {
      return {
        GO: this.$t('Goose'),
        SV: this.$t('SV'),
        SRV: this.$t('Service events'),
        ARP: this.$t('ARP'),
        MMS: this.$t('MMS'),
        USER: this.$t('User events'),
      };
    },

    __eq() {
      let eq = {};
      for (let t in this._eq) {
        eq[this._eq[t]] = t;
      }
      return eq;
    },
    headerVisible() {
      return this.archive && !isNaN(this.archive.tstart) && !isNaN(this.archive.tend);
    },

    descriptionType() {
      return this.user && this.user.preferences && this.user.preferences.descriptionType;
    },

    eventsFields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
    fields() {
      return [
        {
          label: this.$t('signals-columns^IED Name'),
          name: 'iedName',
          data: 'iedName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: false,
        },
        {
          label: this.$t('signals-columns^IED description'),
          name: 'iedDesc',
          data: 'iedDesc',
          visible: this.descriptionType !== 'onlyName',
          sortable: false,
        },
        {
          label: this.$t('signals-columns^Signal'),
          name: 'signalName',
          data: 'signalName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: false,
        },
        {
          label: this.$t('signals-columns^Signal description'),
          name: 'desc',
          data: 'desc',
          visible: this.descriptionType !== 'onlyName',
          render: (d, t, row) => `<span data-name="${row.signalName}">${d.replace(/\|+(.*)/g, '$1')}</span>`,

          sortable: false,
        },
        {
          label: this.$t('signals-columns^Control Block Name'),
          name: 'gooseCB',
          data: 'gooseCB',
          sortable: false,
        },
        {
          label: this.$t('signals-columns^Simulation'),
          name: 'simulation',
          data: 'simulation',
          render: (_) =>
            `<b class="fa-${!!_ ? 'exclamation-' : ''}circle fal text-${!!_ ? 'warning' : 'success'}"></b>`,

          sortable: false,
        },
      ];
    },
    svrFields() {
      return [
        {
          label: this.$t('svr-columns.LVB'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: false,
        },
        {
          label: this.$t('svr-columns.SVID'),
          name: 'svId',
          data: 'svId',
          sortable: false,
        },
        {
          label: this.$t('svr-columns.Destination MAC'),
          name: 'dstMac',
          data: 'dstMac',
          sortable: false,
        },
        {
          label: this.$t('svr-columns.APPID'),
          name: 'appId',
          data: 'appId',
          sortable: false,
        },
        {
          label: this.$t('svr-columns.Simulation'),
          name: 'simulation',
          data: 'simulation',
          render: (_) =>
            `<b class="fa-${!!_ ? ' exclamation-' : ''}circle fal text-${!!_ ? 'warning' : 'success'}"></b>`,
          sortable: false,
        },
        {
          label: this.$t('svr-columns.Record Start'),
          name: 'start',
          data: 'start',
          render: (d) => {
            dev.log('d', d);
            let ts = new DateNano(d.sec * 1e6 + Math.round(d.nano / 1e3));

            return ts.customFormat('#hh#:#mm#:#ss# #DD#.#MM#.#YYYY#');
          },
          sortable: false,
        },
        {
          label: this.$t('svr-columns.Record Duration'),
          name: 'duration',
          render: (d, t, row) => {
            let recordDuration = 0;
            if (row.stop.nano > row.start.nano)
              recordDuration =
                `${row.stop.sec - row.start.sec}.` + `${row.stop.nano - row.start.nano}`.padStart(9, '0');
            else
              recordDuration =
                `${row.stop.sec - row.start.sec - 1}.` + `${row.stop.nano - row.start.nano + 1e9}`.padStart(9, '0');
            return recordDuration;
          },
          sortable: false,
        },
      ];
    },
    pcapsFields() {
      return [
        {
          label: this.$t('pcaps-columns.LVB name'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: false,
        },
        {
          label: this.$t('pcaps-columns.pcap frames'),
          name: 'count',
          data: 'count',
          sortable: false,
        },
        {
          label: this.$t('pcaps-columns.download'),
          name: 'link',
          data: 'count',
          render: (d, t, row) => {
            const time = this.signalsTimeMiddle / 1e6;
            const timebefore = this.signalsTimeBefore / 1e6;
            const timeafter = this.signalsTimeAfter / 1e6;
            dev.log(time, timebefore, timeafter, row);
            if (parseInt(row.count) > 5e5) {
              return `<a
                                    title="${this.$t('No more than 500000 frames')}"
                                    class="btn btn-outline-dark btn-sm fa-ban fal"
                                    href="#"
                                </a>`;
            } else {
              return `<a download class="btn btn-outline-dark btn-sm fa-download fal" href="getpcap?t=${parseInt(
                time
              )}&timebefore=${parseInt(timebefore)}&timeafter=${parseInt(timeafter)}&lvb=${row._id}" >
                                </a>`;
            }
          },

          sortable: false,
        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.archive.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.archive.tend).customFormat();
    },
  },

  mounted() {
    this.getClusterData();
    this.input3 = ' ';
    dev.log('$main', JSON.stringify(this.$main));
    // console.log('$tpws', this.$tpws);
    dev.log('main', window.main);
    // // console.log('main', main);
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    window.addEventListener('user', () => {
      dev.log('ev:user');
      this.user = this.$main.user;
    });
    // document.onreadystatechange = () => {
    //   console.log('document.readyState', document.readyState);
    //   if (document.readyState === 'complete') {
    //     this.user = this.$main.user;
    //     setTimeout(function() {
    //       console.log('timer');
    //       this.user = this.$main.user;
    //     }, 1000);
    //   }
    // };

    this.onTypes = this.eventTypes.map((e) => e.name);
    this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
    this.$nextTick(() => {
      ScrollTo.scrollTo(`#tpws_menu`, 300, this.scrollOptions);
      this.getSignalsTest();
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    showAdd() {
      this.visibleSignalAdd = !this.visibleSignalAdd;
    },
    syncGraphs() {
      if (this.signalsTimelinesArray.length > 1) {
        this.$nextTick(() => {
          // console.log('sync', this.signalsTimelinesArray);
          this.sync = DygraphSync.synchronize(this.signalsTimelinesArray, {
            selection: false,
            zoom: true,
            range: true,
          });
        });
      }
    },

    editCluster() {
      this.editClusterName = true;
      this.clusterNameEdit = this.clusterNameGen;
    },
    editClusterDescr() {
      this.editClusterDesc = true;
      this.clusterDescEdit = this.clusterDescGen;
    },
    goBackToClusters() {
      const lastRoute = localStorage.getItem('lastRoute');
      dev.log('this.$router', this.$router);
      if (lastRoute === 'clusters-index') {
        this.$router.push({ name: 'clusters-index', params: { cluster: this.$route.query.cluster } });
      } else {
        this.$router.push({ name: 'clusters-index' });
      }
    },
    getClusterData(e) {
      dev.log('save', e, this.$route.query.cluster);
      this.editClusterName = false;
      this.clusterNameReal = false;
      this.clusterDescReal = false;
      api
        .post('get-cluster-data', { _id: this.$route.query.cluster })
        .then((response) => {
          // JSON responses are automatically parsed.
          dev.log('res', response.data.data.result);
          this.clusterObj = response.data.data.result;
          if (response.data.data.result.name) {
            this.clusterName = response.data.data.result.name;
            this.clusterNameReal = true;
          } else {
            if (response.data.data.result.nameEv) {
              this.clusterName = response.data.data.result.nameEv;
            } else {
              this.clusterName = response.data.data.result.nameDT;
            }
          }
          if (response.data.data.result.desc) {
            this.clusterDesc = response.data.data.result.desc;
            this.clusterDescReal = true;
          } else {
          }

          // this.clusterNameEv = response.data.data.result.nameEv;
          // this.clusterNameDT = response.data.data.result.nameDT;
          this.clusterLeft = response.data.data.result.left;
          this.clusterRight = response.data.data.result.right;
          this.clusterSize = response.data.data.result.size;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    saveClusterName(e) {
      dev.log('save', e, this.$route.query.cluster);
      this.editClusterName = false;
      api
        .post('set-cluster-name', { _id: this.$route.query.cluster, name: e })
        .then((response) => {
          // JSON responses are automatically parsed.

          this.loading = false;
          this.getClusterData();
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    saveClusterDesc(e) {
      dev.log('save', e, this.$route.query.cluster);
      this.editClusterDesc = false;
      api
        .post('set-cluster-desc', { _id: this.$route.query.cluster, desc: e })
        .then((response) => {
          // JSON responses are automatically parsed.

          this.loading = false;
          this.getClusterData();
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    extAction(a, e) {
      if (a) {
        this[a](e);
      }
    },
    eventsExportXls(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl =
        `/projects/${this.currentProject}/cluster-events2-xls` +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProject} ` + this.$t('analysis report') + `.xlsx`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    eventsExportPdf(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl =
        `/projects/${this.currentProject}/cluster-events2-pdf` +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProject} ` + this.$t('analysis report') + `.pdf`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    eventsExportCsv(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl =
        `/projects/${this.currentProject}/cluster-events2-csv` +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.tpws.displayName} ` + this.$t('analysis report') + `.csv`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },

    swSvr(e) {
      dev.log('swSvr');
      this.showSvr(e);
    },
    showSvr() {
      this.buttonsVisible = true;
      this.dygraphVisible = true;
      dev.log('showSvr');
      this.getSvr();
    },
    swDygraph(e) {
      this.showDygraph(e);
    },
    showDygraph() {
      this.buttonsVisible = true;
      this.dygraphVisible = true;
      this.getSignals();
    },
    showTabs() {
      this.buttonsVisible = true;
      this.dygraphVisible = false;
    },
    dygraphData() {
      return this.$refs.signalsMeta.rowsDataSelected.map((e) => e.id);
    },
    getSignalsTest() {
      let dataUrl =
        'getfakesignals' +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      api
        .get(dataUrl)
        .then((result) => {
          // JSON responses are automatically parsed.
          // let res = result.data.desc;
          // console.log(res); //
          // result.data.desc.signals.shift();
          // result.data.desc.signals.push(result.data.desc.signals[0]);
          // let lastSignal = result.data.desc.signals[0];
          // result.data.desc.signals.forEach((el)=> {
          //   el.points.forEach((p)=> {
          //     // console.log(p.t.nsa);
          //     p.t.nsa = parseInt(p.t.nsa)/1e3;
          //     p.ts.nsa = parseInt(p.ts.nsa)/1e3;
          //     // console.log(p.data.t.nsa);
          //     p.data.t.nsa = parseInt(p.data.t.nsa)/1e3;
          //   })
          // })
          // result.data.desc.signals = result.data.desc.signals.filter(el =>(el.cdc === "SPS"));
          // result.data.desc.signals = result.data.desc.signals.filter(el =>(el.cdc === "ACT"));
          // result.data.desc.signals = result.data.desc.signals.filter(el =>(el.cdc === "DPS"));
          // result.data.desc.signals = result.data.desc.signals.filter(el =>(el.cdc !== "DPC" && el.cdc !== "DPS"));
          // result.data.desc.signals = result.data.desc.signals.filter(el =>(el.cdc == "DPC"));
          // result.data.desc.signals = result.data.desc.signals.filter(el =>(el.cdc !== "DPC"));
          // result.data.desc.signals = result.data.desc.signals.filter(el =>(el.cdc === "SPS" || el.cdc === "DPC"));
          dev.log('result.data.desc', result.data.desc);
          this.signalsData = result.data.desc;
          this.signalsData.tstart = Number(this.$route.query.time) - Number(this.$route.query.timeBefore);
          this.signalsData.tend = Number(this.$route.query.time) + Number(this.$route.query.timeAfter);
          // window._displayDygraph1(this.signalsData);
          // this.$emit('loadedRows', this.rowsData.length);
          // this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    getSignals() {
      let dataUrl =
        'getfakesignals' +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      api
        .get(dataUrl)
        .then((result) => {
          // JSON responses are automatically parsed.
          let res = result.data.desc;
          dev.log(res); //result.data.desc;
          res.tstart = Number(this.$route.query.time) - Number(this.$route.query.timeBefore);
          res.tend = Number(this.$route.query.time) + Number(this.$route.query.timeAfter);
          // window._displayDygraph1(res);
          // this.$emit('loadedRows', this.rowsData.length);
          // this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    getSvr() {
      let lines = this.$refs.svrMeta.rowsDataSelected;
      lines.forEach((l) => {
        let dataUrl =
          'oscfiles' +
          '?' +
          this.$qs.stringify({
            // t: parseInt(this.$route.query.time),
            // prev: parseInt(this.signalsTimeBefore),
            // next: parseInt(this.signalsTimeAfter),
            cluster: this.$route.query.cluster,

            lvb: l.lvb_id,
            svId: l.svId,
            dstMac: l.dstMac,
            appId: l.appId,
            simulation: l.simulation,
          });
        api
          .get(dataUrl)
          .then((result) => {
            let res = result.data.desc;
            dev.log('ans', result.data);
            svrDraw(
              result.data,
              'signals-dygraph-canvas',
              Number(parseInt(result.data.time.t)),
              Number(parseInt(result.data.time.timeBefore)),
              Number(parseInt(result.data.time.timeAfter))
            );
          })
          .catch((e) => {
            this.errors.push(e);
            this.loading = false;
          });
      });
      // let dataUrl =
      //   'oscfiles' +
      //   '?' +
      //   this.$qs.stringify({
      //     t: parseInt(this.$route.query.time),
      //     prev: parseInt(this.signalsTimeBefore),
      //     next: parseInt(this.signalsTimeAfter),
      //
      //     cluster: this.$route.query.cluster,
      //     common: commonPart,
      //     signals: signals.map((s) => s.substring(commonPart.length)).join(','),
      //   });
      // api
      //   .get(dataUrl)
      //   .then((result) => {
      //     // JSON responses are automatically parsed.
      //     let res = result.data.desc;
      //     console.log(res); //result.data.desc;
      //     res.tstart = Number(this.$route.query.time) - Number(this.$route.query.timeBefore);
      //     res.tend = Number(this.$route.query.time) + Number(this.$route.query.timeAfter);
      //     window._displayDygraph1(res);
      //     // this.$emit('loadedRows', this.rowsData.length);
      //     // this.loading = false;
      //   })
      //   .catch((e) => {
      //     this.errors.push(e);
      //     this.loading = false;
      //   });
    },
    switchTab(tab) {
      switch (tab) {
        case 'events':
          if (this.eventsCount > 0) {
            this.currentTab = 'events';
          }
          break;
        case 'signals':
          if (this.signalsCount > 0) {
            this.currentTab = 'signals';
          }
          break;
        case 'svr':
          if (this.svrCount > 0) {
            this.currentTab = 'svr';
          }
          break;
        case 'pcaps':
          if (this.pcapsCount > 0) {
            this.currentTab = 'pcaps';
          }
          break;
      }
    },
    loadedEvents(rows) {
      this.eventsCount = rows;
    },
    loadedSignals(rows) {
      this.signalsCount = rows;
    },
    loadedSvr(rows) {
      this.svrCount = rows;
    },
    loadedPcaps(rows) {
      this.pcapsCount = rows;
    },
    switchType(type) {
      dev.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
    },
  },
  watch: {
    // signalsTimelinesArray: {
    //   handler: function (val, oldVal) {
    //     console.log('upd: timeline', JSON.stringify(val));
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
