<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.projectAddModalVisible"
    v-model:visible="visual.projectAddModalVisible"
    ref="modEx"
    @closeModal="closeModal"
    @close="closeModal"
    @onSubmit="onSubmit"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #content> <slot></slot> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';

import { useVisual } from '@/stores/visual';
import { mapStores } from 'pinia';
export default {
  name: 'project-add',
  props: {
    visibleDialog: {
      default: false,
    },
  },
  emits: ['create', 'close'],
  components: {
    ModalDialog,
  },
  data: () => {
    return {
      modal: {
        name: 'project-add',
        title: 'Create project',
        onSubmit: 'Create',
        noSubmit: false,
        submitBtn: 'OK',
        size: 'lg',
        noActions: false,
      },
    };
  },
  computed: {
    ...mapStores(useVisual),
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.visual.projectAddModalVisible = false;
    },
    onSubmit() {
      this.$emit('create');
      this.visual.projectAddModalVisible = false;
    },
  },
};
</script>

<style scoped></style>
