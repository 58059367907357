<template>
  <div class="wheel-breadcrumbs">
    <wheel-breadcrumbs-item
      v-for="(el, idx) in list"
      :key="idx"
      :idx="idx"
      :item="el"
      @onSelect="onSelect"
    ></wheel-breadcrumbs-item>
  </div>
</template>

<script>
import WheelBreadcrumbsItem from '@/components/wheel/WheelBreadcrumbsItem.vue';

export default {
  name: 'wheel-breadcrumbs',
  props: {
    list: {
      type: Array,
    },
  },
  emits: ['onSelect'],
  components: {
    WheelBreadcrumbsItem,
  },
  methods: {
    onSelect(e) {
      dev.log('selected', e);
      this.$emit('onSelect', e);
    },
  },
};
</script>

<style scoped>
.wheel-breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
