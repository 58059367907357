<template>
<!--      <simplebar-vue :disabled="true">-->
<!--  <n-scrollbar trigger="none" >-->
  <div v-resize:debounce="ntRecalculate">
    <div v-if="componentName" style="height: auto"><component :is="componentName"></component></div>
  </div>
<!--  </n-scrollbar>-->
<!--      </simplebar-vue>-->
</template>

<script>
import { mapStores } from 'pinia';
import { useSclProcessors } from '../../stores/sclProcessors';
import { useShared } from '../../stores/shared';
import { getCurrentInstance } from 'vue/dist/vue.esm-bundler';
import SimplebarVue from '@/components/common/SimplebarVue.vue';
import eventBus from '@/utils/eventBus';

export default {
  name: 'report',
  setup() {
    const uid = getCurrentInstance().uid;
    // ...
    return { uid };
  },
  components: {
    SimplebarVue,
  },
  data: () => {
    return {
      libName: undefined,
      id: undefined,
    };
  },
  computed: {
    ...mapStores(useSclProcessors),
    ...mapStores(useShared),
    componentName() {
      if (this.$route.params.reportCode) {
        return this.$route.params?.reportCode + '-report';
      }
      return undefined;
    },
    currentScl() {
      return this.sclProcessors.sclProcessors.find(
        (el) => el.manifest.reportView.route == this.$route.params.reportCode
      );
    },
  },
  mounted() {
    // this.$loadScript('/js/scd-new.js');
    this.id = this._uid;
  },
  beforeUnmount() {
    // this.$unloadModule(`/api/scl-libs/${this.libName}`);
    dev.log('$unloadModule', this.libName);
  },
  unmounted() {},
  methods: {
    ntRecalculate(e){
      dev.log('scl resize', e)
      eventBus.dispatch('reportResize');
    },
    onUnload(e) {
      dev.log('onUnload', e);
    },
  },
  watch: {
    libName: {
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.shared.getUser();
          this.shared.getAccessToken();
          this.shared.getVertical();
          this.shared.getCurrentProject();
          // this.$loadModule(`/api/scl-libs/${newValue}`);
          // console.log('$loadModule', newValue, oldValue);
        }
      },
      // deep: true,
    },
    currentScl: {
      handler(newValue, oldValue) {
        dev.log('watch currentScl', newValue);
        if (newValue && newValue.manifest.lib !== oldValue?.manifest?.lib) {
          this.libName = newValue.manifest.lib;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
