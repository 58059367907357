<template>
  <div class="sidebar-top" id="sidebar-top">
    <el-menu
      mode="horizontal"
      :default-active="activeIndex"
      menu-trigger="hover"
      background-color="#2a2e32"
      text-color="#949698"
      active-text-color="#ffffff"
      :ellipsis="false"
      :unique-opened="true"
      @select="handleSubmenuSelection"
      style="display: flex"
    >
      <el-menu-item
        v-show="currentProject"
        index="/users/1/projects"
        class="el-submenu_horizontal"
        @click="goToLink('/projects/' + currentProject)"
      >
        <font-awesome-icon class="fal-menu" icon="book-open" />
        <span>{{ $t('Project') }}</span>
      </el-menu-item>

      <el-sub-menu
        v-show="versionNumber && versionNumber !== '0'"
        index="2"
        :popper-offset="0"
        popper-class="el-submenu__menu "
        class="el-submenu_horizontal"
      >
        <template #title>
          <font-awesome-icon class="fal-menu" icon="eye" />
          <span>{{ $t('Viewing and analysis') }}</span>
        </template>
        <el-menu-item
          class="el-menu-item-nested"
          :class="{ 'is-active': currentProject === item.link }"
          v-for="item in menuItems.viewingAndAnalyzing"
          :key="item.link"
          :index="item.link"
          @click="goToLink(item.link)"
        >
          <template #title>
            <font-awesome-icon class="fal-menu" :icon="item.icon" />
            <span>{{ $t(item.title) }}</span>
          </template>
        </el-menu-item>
      </el-sub-menu>
      <el-sub-menu
        v-show="menuItems.monitoring && versionNumber !== '0' && isLive"
        popper-class="el-submenu__menu"
        index="3"
        :popper-offset="0"
        ref="subMenu3"
        class="el-submenu_horizontal"
      >
        <template #title>
          <font-awesome-icon class="fal-menu" icon="heartbeat" />
          <span>{{ $t('Monitoring') }}</span>
        </template>
        <el-menu-item
          class="el-menu-item-nested"
          v-for="item in menuItems.monitoring"
          :key="item.link"
          :index="item.link"
          @click="goToLink(item.link)"
        >
          <template #title>
            <font-awesome-icon class="fal-menu" :icon="item.icon" />
            <span>{{ $t(item.title) }}</span>
          </template>
        </el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import { menu } from '@/mixins/menu';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { useAuth } from '@/stores/auth';
import { useTpui } from '@/stores/tpui';
// import { ElMenu, ElSubMenu, ElMenuItem } from 'element-plus';
export default {
  name: 'horizontal-menu',
  mixins: [menu],
  components: {
    // ElMenu,
    // ElSubMenu,
    // ElMenuItem,
  },

  data() {
    return {
      // widthSideBar: '220px',
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useAuth),
    ...mapStores(useTpui),
    currentProject() {
      return this.tpws.currentProject;
    },
    isAdmin() {
      return this.auth.isAdmin;
    },
    // sidebarCollapsed() {
    //   return this.$store.state.controlMenu.sidebarCollapsed;
    // },
  },
  watch: {
    // sidebarCollapsed(newVal) {
    //   this.widthSideBar = newVal ? '64px' : '220px';
    // },
  },
  methods: {
    handleOpen(e) {
      dev.log('e', e);
    },
    handleSubmenuSelection(i, p, e) {
      // e && e.$parent._name === '<ElSubmenu>' && e.$parent.handleMouseleave();
      // this.$store.commit('setSidebarCollapsed', true);
    },
  },
};
</script>

<style>
.el-submenu_horizontal {
  flex: 1;
  text-align: center;
  border: none !important;
}
.el-menu--popup-bottom-start {
  margin: -2px !important;
  background-color: #35383c !important;
}
</style>
<style lang="scss">
.el-menu-item-nested {
  background-color: #35383c !important;
}
.el-submenu__menu {
  min-width: 30%;
}
.el-submenu__menu .el-menu--popup {
  width: 100%;
}
//.el-submenu__menu .el-menu-item {
//  width: 100%;
//}
.is-opened {
  .el-submenu__title {
    background-color: #35383c !important;
  }
}
</style>
