/*************************************************************************

 TEKVEL CONFIDENTIAL
 __________________
 2016 - 2017 TEKVEL R&D Ltd.
 All Rights Reserved.
 NOTICE: All information contained herein is, and remains
 the property of TEKVEL R&D Ltd. and its suppliers,
 if any. The intellectual and technical concepts contained
 herein are proprietary to TEKVEL R&D Ltd.
 and its suppliers and may be covered by Russian and Foreign Patents,
 patents in process, and are protected by trade secret or copyright law.
 Dissemination of this information or reproduction of this material
 is strictly forbidden unless prior written permission is obtained
 from TEKVEL R&D Ltd.

 *************************************************************************/

import Dygraph from '../../vendor/dygraph';
import {v4 as uuid} from 'uuid';

let DygraphTpui;

(function () {
  'use strict';

  //console.log(Dygraph.Plotters.linePlotter.prototype);
  //console.log(Dygraph.prototype)

  if (typeof Dygraph === 'undefined') {
    throw 'dygraph not found!';
  }

  DygraphTpui = {};

  // Plotter styling constants;

  let PRIMARY_SIM_HEIGHT = 20;
  let PRIMARY_STATE_HEIGHT = 20;
  let PRIMARY_STEP_HEIGHT = 8;
  let PRIMARY_BOOLEAN_HEIGHT_FALSE = 4;
  let PRIMARY_BOOLEAN_HEIGHT_TRUE = 8;
  let SECONDARY_BOOLEAN_HEIGHT_FALSE = 4;
  let SECONDARY_BOOLEAN_HEIGHT_TRUE = 8;
  const QUALITY_MASK = 0x3; // 0b0000000000000011
  const GOOD_STATE = 0,
    TTL_STATE = 1,
    TIMEOUT_STATE = 2,
    TEST_STATE = 3,
    DSTR_ERR_STATE = 4,
    DISCONNECT_STATE = 5,
    MAINTENANCE_STATE = 6,
    GHOST_STATE = 7,
    UNKNOWN_STATE = 15;
  const Q_GOOD_COLOR = '#51EA5B',
    Q_QUESTIONABLE_COLOR = '#F7C002',
    Q_BAD_COLOR = '#F70F1C',
    Q_RESERVED_COLOR = 'gray',
    Q_UNKNOWN_COLOR = '#C8CDD4',
    Q_TEST_BACKGROUND = 'rgba(30,144,255,0.3)',
    Q_SIM_BACKGROUND = 'rgba(249, 239, 20, 0.75)';

  const pattertCache = [];
  const realTag = {
    true: 'real.',
    false: 'sim.',
  };

  function getColorByValidity(q) {
    let quality = q.isValid
      ? Q_GOOD_COLOR
      : q.isInvalid
      ? Q_BAD_COLOR
      : q.isQuestionable
      ? Q_QUESTIONABLE_COLOR
      : Q_RESERVED_COLOR;
    console.log('quality color', quality);
    return quality;
  }

  function reverseString(str) {
    return str.split('').reverse().join('');
  }

  function getTestPattern(color, height, patShift = 0) {
    console.log('pat', height, patShift);
    const shiftY = patShift % height;
    const found = pattertCache.find((pat) => pat.height === height && pat.shift === shiftY && pat.type === 'test');
    if (found) {
      console.log('pattern found');
      return found.pattern;
    }

    var canvasPattern = document.createElement('canvas');
    var canvasPatternShift = document.createElement('canvas');

    const halfHeight = height / 2;
    canvasPattern.width = height * 2;
    canvasPattern.height = height;
    var ctxPattern = canvasPattern.getContext('2d');
    ctxPattern.fillStyle = '#000';
    // ctxPattern.fillRect(0, 0, canvasPattern.width, canvasPattern.height);
    ctxPattern.fillRect(height, 0, height, halfHeight);
    ctxPattern.fillRect(0, halfHeight, height, halfHeight);

    ctxPattern.fillStyle = color;
    ctxPattern.fillRect(0, 0, height, halfHeight);
    ctxPattern.fillRect(height, halfHeight, height, halfHeight);

    canvasPatternShift.width = height * 2;
    canvasPatternShift.height = height;
    var ctxPatternShift = canvasPatternShift.getContext('2d');

    ctxPatternShift.translate(0, shiftY);

    console.log('pat trans', height, patShift, shiftY);

    const pattern = ctxPatternShift.createPattern(canvasPattern, 'repeat');

    ctxPatternShift.fillStyle = pattern;
    ctxPatternShift.fillRect(0, -shiftY, canvasPatternShift.width, canvasPatternShift.height);

    var img1 = canvasPattern.toDataURL('image/png');
    var img2 = canvasPatternShift.toDataURL('image/png');
    console.log('pat img1', img1);
    console.log('pat img2', img2);

    pattertCache.push({
      height: height,
      shift: shiftY,
      type: 'test',
      pattern: canvasPatternShift,
    });
    console.log('pattern stored', pattertCache);
    return canvasPatternShift;
  }

  function getSimPattern(height, patShift = 0, opts = {}) {
    const shiftY = patShift % height;
    const found = pattertCache.find((pat) => pat.height === height && pat.shift === shiftY && pat.type === 'sim');
    if (found) {
      console.log('pattern found');
      return found.pattern;
    }

    var canvasPattern = document.createElement('canvas');
    var canvasPatternShift = document.createElement('canvas');
    const halfHeight = height / 2;
    canvasPattern.width = height * 2;
    canvasPattern.height = opts.v2 ? height * 2 : height;
    var ctxPattern = canvasPattern.getContext('2d');
    ctxPattern.fillStyle = Q_SIM_BACKGROUND;

    if (opts.v2) {
      ctxPattern.beginPath();
      ctxPattern.moveTo(0, 0);
      ctxPattern.lineTo(height * 2, height * 2);
      ctxPattern.lineTo(height * 2, height);
      ctxPattern.lineTo(height, 0);
      ctxPattern.lineTo(0, 0);
      // ctxPattern.closePath();
      // ctxPattern.beginPath();
      ctxPattern.moveTo(0, height);
      ctxPattern.lineTo(height, height * 2);
      ctxPattern.lineTo(0, height * 2);
      ctxPattern.lineTo(0, height);
      ctxPattern.closePath();
    } else {
      ctxPattern.beginPath();
      ctxPattern.moveTo(0, 0);
      ctxPattern.lineTo(height, height);
      ctxPattern.lineTo(height * 2, height);
      ctxPattern.lineTo(height, 0);
      ctxPattern.lineTo(0, 0);
      ctxPattern.closePath();
    }

    ctxPattern.fill();

    canvasPatternShift.width = height * 2;
    canvasPatternShift.height = opts.v2 ? height * 2 : height;
    var ctxPatternShift = canvasPatternShift.getContext('2d');

    ctxPatternShift.translate(0, shiftY);
    console.log('pat trans sim', height, patShift, shiftY);

    const pattern = ctxPatternShift.createPattern(canvasPattern, 'repeat');

    ctxPatternShift.fillStyle = pattern;
    ctxPatternShift.fillRect(0, -shiftY, canvasPatternShift.width, canvasPatternShift.height);

    // var img1    = canvasPattern.toDataURL("image/png");
    // var img2    = canvasPatternShift.toDataURL("image/png");
    // console.log('img1', img1);
    // console.log('img2', img2);

    console.log('pattern stored');
    pattertCache.push({
      height: height,
      shift: shiftY,
      type: 'sim',
      pattern: canvasPatternShift,
    });

    return canvasPatternShift;
  }

  function rect(ctx, e, x1, y1, x2, y2, opts = { test: false, stroke: false }) {
    const a = e.plotArea;
    const w = Math.abs(x2 - x1);
    const h = Math.abs(y2 - y1);
    console.log('pat h', h, y1, y2);
    if (opts.test) {
      ctx.fillStyle = ctx.createPattern(getTestPattern(ctx.fillStyle, Math.ceil(h), Math.floor(y1)), 'repeat');
      // console.log('test', ctx.fillStyle);
    }
    if (opts.state) {
      console.log('draw state', h, y1, y2, opts.state);
      var gradient;
      switch (opts.state) {
        case 0:
          return;
          break;
        case 1:
          gradient = ctx.createLinearGradient(x1, y1, x2 + 1, y1);
          gradient.addColorStop(0, 'rgba(251, 255, 54, 0.1)');
          gradient.addColorStop(1, '#FBFF36');
          ctx.fillStyle = gradient;
          break;
        case 2:
          ctx.fillStyle = '#FBFF36';

          break;
        case 3:
          gradient = ctx.createLinearGradient(x1, y1, x2 + 1, y1);
          gradient.addColorStop(0, 'rgba(255, 119, 118, 0.1)');
          gradient.addColorStop(1, '#FF7676');
          ctx.fillStyle = gradient;
          break;
        case 4:
          ctx.fillStyle = '#FF7676';
          break;

        default:
          console.log('unnkown state');
      }
      console.log('draw rect', x1, y1, x2, y2, ctx.fillStyle);
    }

    // console.log('a', a, ctx);
    if (x1 <= a.w && x2 >= 0) {
      if (x1 < 0 && x2 >= 0) {
        x1 = Math.max(x1, 0);
      }
      if (x2 > a.w && x1 <= a.w) {
        x2 = Math.min(x2, a.w);
      }
      ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
      if (opts.stroke) {
        ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
      }
      // console.log('r', x1, y1, x2, y2, ctx.fillStyle);
    }
  }

  function pr(number) {
    return parseFloat(parseFloat(number).toPrecision(12));
  }

  class Quality {
    constructor(string = '1000000000000') {
      this._qString = reverseString(string);
      this._VALID = 0;
      this._INVALID = 2;
      this._QUESTIONABLE = 3;
      this._RESERVED = 1;
      this._SOURCE_PROCESS = 0;
      this._SOURCE_SUBSTITUTED = 1;
    }

    get qu() {
      // console.log(
      //   this._qString.substring(this._qString.length - 2),
      //   parseInt(this._qString.substring(this._qString.length - 2), 2)
      // );
      return parseInt(this._qString.substring(this._qString.length - 2), 2);
    }

    get isValid() {
      return parseInt(this._qString.substring(this._qString.length - 2), 2) == this._VALID;
    }

    get isInvalid() {
      return parseInt(this._qString.substring(this._qString.length - 2), 2) == this._INVALID;
    }

    get isQuestionable() {
      return parseInt(this._qString.substring(this._qString.length - 2), 2) == this._QUESTIONABLE;
    }

    get isTest() {
      return parseInt(this._qString[this._qString.length - 12], 2) == 1;
    }

    get isSubstituted() {
      return parseInt(this._qString[this._qString.length - 11], 2) == this._SOURCE_SUBSTITUTED;
    }
  }

  DygraphTpui.metaConverter = function metaConverter(m) {
    // // Quality is BIG Endian: "0100000000000". Bits: 0,1,2, .. 12. Total: 13
    m.q = parseInt(m.q, 2) >> 11;
  };

  DygraphTpui.valueConverter = function Dygraph_tpui_valueConverter(value) {
    let val;
    //console.log(value)
    value = '' + value;
    switch (value) {
      // BOOLEANS
      case true:
      case 'true':
        val = 1;
        break;

      case false:
      case 'false':
        val = 0;
        break;

      // DOUBLE POINTS
      case '00':
        val = 0;
        break;
      case '01':
        val = 1;
        break;
      case '10':
        val = 2;
        break;
      case '11':
        val = 3;
        break;

      // INTEGERS
      case '0':
        val = 0;
        break;
      case '1':
        val = 1;
        break;
      case '2':
        val = 2;
        break;
      case '3':
        val = 3;
        break;
      default:
        val = null;
        break;
    }
    return val;
  };

  DygraphTpui.splitDataV2 = function (data, cdc, joined = true) {
    function pointsSort(a, b) {
      // Compare the 2 dates
      if (a.Time < b.Time) return -1;
      if (a.Time > b.Time) return 1;
      return 0;
    }

    let parsed = [];
    let currentLevels = {
      SPS: 7,
      DPS: 7,
      ACT: 7,
      ACD: 7,
      SPC: 7,
      DPC: 7,
    };
    const sizes = {
      SPS: { stVal: 25, subEna: 25, subVal: 25, blkEna: 25 },
      DPS: { stVal: 45, subEna: 25, subVal: 25, blkEna: 25 },
      ACT: { general: 25, phsA: 25, phsB: 25, phsC: 25, neut: 25 },
      ACD: {
        general: 25,
        dirGeneral: 25,
        phsA: 25,
        dirPhsA: 25,
        phsB: 25,
        dirPhsB: 25,
        phsC: 25,
        dirPhsC: 25,
        neut: 25,
        dirNeut: 25,
      },
      SPC: { stVal: 25, stSeld: 25, opRcvd: 25, opOk: 25, subEna: 25, subVal: 25, blkEna: 25 },
      DPC: { stVal: 45, stSeld: 25, opRcvd: 25, opOk: 25, subEna: 25, subVal: 25, blkEna: 25 },
    };
    let levels = {};
    const pdPad = 0.7;
    const topPad = 1.3;
    const bottomPad = 0.7;
    const gapSize = 0.3;
    const signalHeight = 27;
    let passes = ['real', 'sim'];
    let viewVars = [];
    let viewLabels = [];
    let usedVars = [];
    let pd = {};
    let parts = ['tickers', 'labels', 'arr', 'meta', 'quality', 'series', 'states', 'valueRange'];
    const vals = {
      SPS: ['stVal', 'subEna', 'subVal', 'blkEna'],
      DPS: ['stVal', 'subEna', 'subVal', 'blkEna'],
      ACT: ['general', 'phsA', 'phsB', 'phsC', 'neut'],
      ACD: ['general', 'dirGeneral', 'phsA', 'dirPhsA', 'phsB', 'dirPhsB', 'phsC', 'dirPhsC', 'neut', 'dirNeut'],
      SPC: ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'],
      DPC: ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'],
    };

    const valsDp = {
      DPS: { stVal: ['Invalid', 'On', 'Off', 'Intermediate'] },
      DPC: { stVal: ['Invalid', 'On', 'Off', 'Intermediate'] },
    };

    const valsDpState = {
      DPS: { stVal: { 3: 'Invalid', 2: 'On', 1: 'Off', 0: 'Intermediate' } },
      DPC: { stVal: { 3: 'Invalid', 2: 'On', 1: 'Off', 0: 'Intermediate' } },
    };

    let ord = {};
    Object.keys(vals).forEach((c) => {
      // console.log('vals', c);
      vals[c].forEach((v) => {
        passes.forEach((ps) => {
          if (ord[c] === undefined) {
            ord[c] = [];
          }
          if (valsDp[c] && valsDp[c][v]) {
            // console.log('valsDp[c][v]', c, v, valsDp[c][v]);
            ord[c].push(ps + '.' + v);
            valsDp[c][v].forEach((d) => {
              ord[c].push(ps + '.' + d);
            });
          } else {
            ord[c].push(ps + '.' + v);
          }
        });
      });
    });

    // console.log('ord', ord);

    const tickers = {
      true: {
        SPS: ['stVal'],
        DPS: ['stVal'],
        ACT: ['general'],
        ACD: ['general'],
        SPC: ['stVal'],
        DPC: ['stVal'],
      },
      false: {
        SPS: ['stVal', 'subEna', 'subVal', 'blkEna'],
        DPS: ['stVal', 'subEna', 'subVal', 'blkEna'],
        ACT: ['general', 'phsA', 'phsB', 'phsC', 'neut'],
        ACD: ['general', 'phsA', 'phsB', 'phsC', 'neut'],
        SPC: ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'],
        DPC: ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'],
      },
    };
    tickers[joined][cdc].forEach((t) => {
      // console.log('tickers', t);
      passes.forEach((pt) => {
        const pos = ord[cdc].indexOf(pt + '.' + t);
        viewVars[pos] = pt + '.' + t;
      });
    });
    viewVars = viewVars.filter((e) => e !== undefined);

    pd.real = DygraphTpui.splitDataV2pass(data, cdc, joined, true);
    let merged = { ...pd.real };
    pd.sim = DygraphTpui.splitDataV2pass(data, cdc, joined, false);
    console.log('cdc', cdc);
    console.log('real', pd.real);
    console.log('sim', pd.sim);
    // console.log('labels vars', viewLabels, viewVars);
    parts.forEach((p) => {
      switch (p) {
        case 'tickers':
          passes.forEach((ps) => {
            pd[ps].arr.forEach((a) => {
              Object.keys(a).forEach((k) => {
                if (k !== 'Time' && usedVars.indexOf(k) < 0) {
                  const pos = ord[cdc].indexOf(k);
                  usedVars[pos] = k;
                }
              });
            });
          });
          usedVars = usedVars.filter((e) => e !== undefined);
          // console.log('usedVars', usedVars);
          tickers[joined][cdc].forEach((t) => {
            const ta = valsDp[cdc] && valsDp[cdc][t] ? valsDp[cdc][t] : [t];
            // console.log('tickers', t);
            passes.forEach((pt) => {
              if (['DPS', 'DPC'].indexOf(cdc) > -1) {
                currentLevels[cdc] = pr(currentLevels[cdc] - pdPad);
              }
              ta.forEach((tt) => {
                // console.log('currentLevels[cdc]', p, pt, t, tt, currentLevels[cdc]);
                merged.tickers.push({ v: currentLevels[cdc], label: tt, sim: pt === 'sim' });
                if (levels[cdc] === undefined) levels[cdc] = {};
                levels[cdc][pt + '.' + tt] = currentLevels[cdc];
                // currentLevels[cdc]--;
                currentLevels[cdc] = pr(currentLevels[cdc] - 1);
              });
              currentLevels[cdc] = pr(currentLevels[cdc] - gapSize);
            });
          });
          // console.log('levels', levels, currentLevels);
          break;
        case 'labels':
          merged.labels = ['Time', ...viewVars];
          // console.log('merged.labels', merged.labels);
          // merged.labels.splice(0, 0, 'Time', 'uid');
          break;
        case 'arr':
          let lbls = ['Time', ...viewVars];
          // console.log('labels', lbls);
          // lbls.splice(0, 0, 'Time');

          merged.arr = [];
          pd.real.arr.sort(pointsSort);
          pd.real.arr.forEach((p) => {
            let el = [];
            lbls.forEach((k) => {
              let vl;
              const k_ = k.replace('real.', '').replace('sim.', '');
              if (typeof p[k] === 'object') {
                if (valsDpState[cdc] && valsDpState[cdc][k_]) {
                  const st = pd.real.states.find((el) => el.uid == p[k].uid)[k];
                  vl = p[k].val === true ? [levels[cdc]['real.' + valsDpState[cdc][k_][st]], p[k].uid] : null;
                } else {
                  vl = p[k].val === true ? [levels[cdc][k], p[k].uid] : null;
                }
              } else {
                vl = p[k];
              }
              el[lbls.indexOf(k)] = vl !== undefined ? vl : null;
            });
            merged.arr.push(el);
          });
          pd.sim.arr.sort(pointsSort);
          pd.sim.arr.forEach((p) => {
            let el = [];
            lbls.forEach((k) => {
              let vl;
              const k_ = k.replace('real.', '').replace('sim.', '');
              if (typeof p[k] === 'object') {
                if (valsDpState[cdc] && valsDpState[cdc][k_]) {
                  const st = pd.sim.states.find((el) => el.uid == p[k].uid)[k];
                  vl = p[k].val === true ? [levels[cdc]['sim.' + valsDpState[cdc][k_][st]], p[k].uid] : null;
                } else {
                  vl = p[k].val === true ? [levels[cdc][k], p[k].uid] : null;
                }
              } else {
                vl = p[k];
              }
              el[lbls.indexOf(k)] = vl !== undefined ? vl : null;
            });
            merged.arr.push(el);
          });
          break;
        case 'meta':
          merged.meta = [...pd.real.meta, ...pd.sim.meta];
          break;
        case 'quality':
          merged.quality = {
            q: [...pd.real.quality.q, ...pd.sim.quality.q],
            subQ: [...pd.real.quality.subQ, ...pd.sim.quality.subQ],
          };
          break;
        case 'series':
          merged.series = { ...pd.real.series, ...pd.sim.series };
          break;
        case 'states':
          merged.states = [...pd.real.states, ...pd.sim.states];
          break;
        case 'valueRange':
          let tMin = 999;
          let tMax = -999;

          merged.tickers.forEach((t) => {
            tMin = Math.min(tMin, t.v);
            tMax = Math.max(tMax, t.v);
          });
          tMin = pr(tMin - bottomPad);
          tMax = pr(tMax + topPad);
          if (['DPS', 'DPC'].indexOf(cdc) > -1) {
            tMax = pr(tMax + pdPad);
          }

          merged.valueRange = [tMin, tMax];
          break;
      }
    });
    merged['size'] = Math.floor(signalHeight * pr(Math.max(...merged.valueRange) - Math.min(...merged.valueRange)));
    // console.log('viewVars', viewVars);
    // viewVars.forEach((v) => {
    //   const s = v.replace('real.', '').replace('sim.', '');
    //   merged['size'] += sizes[cdc][s];
    //   // console.log('height', s, sizes[cdc][s]);
    // });
    merged.yticker = function (min, max, pixels, opts, dygraph, vals) {
      return merged.tickers;
    };
    console.log('merged', merged);
    return merged;
  };
  DygraphTpui.splitDataV2pass = function (pointsData, cdc, joined = true, real = true) {
    var dots_arr = [],
      state_arr = [],
      meta = [],
      plotter = [],
      labels = [],
      series = {},
      valueRange = [],
      yticker = [],
      quality = {};
    let q_arr = [];
    let subQ_arr = [];
    let data;
    if (real) {
      data = pointsData.real;
    } else {
      data = pointsData.simulated;
    }

    const plotters = {
      SPS: {
        stVal: Dygraph.Plotters.lineBoolPlotter,
        subEna: Dygraph.Plotters.lineBoolPlotter,
        subVal: Dygraph.Plotters.lineBoolPlotter,
        blkEna: Dygraph.Plotters.lineBoolPlotter,
      },
      DPS: {
        stVal: Dygraph.Plotters.lineStepPlotter,
        subEna: Dygraph.Plotters.lineBoolPlotter,
        subVal: Dygraph.Plotters.lineBoolPlotter,
        blkEna: Dygraph.Plotters.lineBoolPlotter,
      },
      ACT: {
        general: Dygraph.Plotters.lineBoolPlotter,
        phsA: Dygraph.Plotters.lineBoolPlotter,
        phsB: Dygraph.Plotters.lineBoolPlotter,
        phsC: Dygraph.Plotters.lineBoolPlotter,
        neut: Dygraph.Plotters.lineBoolPlotter,
      },
      ACD: {
        general: Dygraph.Plotters.lineBoolPlotter,
        dirGeneral: Dygraph.Plotters.dirPlotter,
        phsA: Dygraph.Plotters.lineBoolPlotter,
        dirPhsA: Dygraph.Plotters.dirPlotter,
        phsB: Dygraph.Plotters.lineBoolPlotter,
        dirPhsB: Dygraph.Plotters.dirPlotter,
        phsC: Dygraph.Plotters.lineBoolPlotter,
        dirPhsC: Dygraph.Plotters.dirPlotter,
        neut: Dygraph.Plotters.lineBoolPlotter,
        dirNeut: Dygraph.Plotters.dirPlotter,
      },
      SPC: {
        stVal: Dygraph.Plotters.lineBoolPlotter,
        stSeld: Dygraph.Plotters.lineBoolPlotter,
        opRcvd: Dygraph.Plotters.lineBoolPlotter,
        opOk: Dygraph.Plotters.lineBoolPlotter,
        subEna: Dygraph.Plotters.lineBoolPlotter,
        subVal: Dygraph.Plotters.lineBoolPlotter,
        blkEna: Dygraph.Plotters.lineBoolPlotter,
      },
      DPC: {
        stVal: Dygraph.Plotters.lineStepPlotter,
        stSeld: Dygraph.Plotters.lineStepPlotter,
        opRcvd: Dygraph.Plotters.lineStepPlotter,
        opOk: Dygraph.Plotters.lineStepPlotter,
        subEna: Dygraph.Plotters.lineStepPlotter,
        subVal: Dygraph.Plotters.lineStepPlotter,
        blkEna: Dygraph.Plotters.lineStepPlotter,
      },
    };

    const vals = {
      SPS: ['stVal', 'subEna', 'subVal', 'blkEna'],
      DPS: ['stVal', 'subEna', 'subVal', 'blkEna'],
      ACT: ['general', 'phsA', 'phsB', 'phsC', 'neut'],
      ACD: ['general', 'dirGeneral', 'phsA', 'dirPhsA', 'phsB', 'dirPhsB', 'phsC', 'dirPhsC', 'neut', 'dirNeut'],
      SPC: ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'],
      DPC: ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'],
    };

    //console.log(data);

    for (var i = 0; i < data.length; i++) {
      let val = { real: {}, sim: {} };
      let temp_val = 0;

      let dot_vals = [];
      let state_vals = [];
      let uid = uuid();
      labels = [];

      switch (cdc) {
        case 'SPS':
          /**
                    * This class shall contain the following mandatory data attributes:
                    ** stVal [BOOLEAN]
                    ** q [Quality]
                    ** t [timestamp]
                    And the following optional attributes:
                    ** subEna [BOOLEAN]
                    ** subVal [BOOLEAN]
                    ** subQ [Quality]
                    ** subID [VISIBLE STRING64]
                    ** blkEna [BOOLEAN]
                    ** d [VISIBLE STRING255]
                    ** dU [UNICODE STRING255]
                    ** cdcNs [VISIBLE STRING255]
                    ** cdcName [VISIBLE STRING255]
                    ** dataNs [VISIBLE STRING255]
                    */

          val.stVal =
            data[i].data.stVal !== undefined
              ? DygraphTpui.valueConverter(data[i].data.stVal)
              : DygraphTpui.valueConverter(data[i].data.v); // "v" is for compatibility with older data
          if (val.stVal === null) val.stVal = -1;
          val.q = data[i].data.q ? new Quality(data[i].data.q) : new Quality(data[i].meta.q); // "meta.q" is for compatibility with older data
          val.subEna = DygraphTpui.valueConverter(data[i].data.subEna);
          val.subVal = DygraphTpui.valueConverter(data[i].data.subVal);
          val.subQ = new Quality(data[i].data.subQ);
          val.blkEna = DygraphTpui.valueConverter(data[i].data.blkEna);

          // console.log('meta', data[i].meta.transport.goose[0].agents[0].lvb_goose_sniffer);

          state_vals = {
            ts: data[i].ts.nsa,
            sim: !real,
            uid,
          };
          vals[cdc].forEach((v) => {
            if (val[v] != null) {
              state_vals[realTag[real] + v] = val[v];
              dot_vals[realTag[real] + v] = { val: true, uid };
            }
          });

          // This is for testing purposes
          temp_val = dot_vals;

          break;

        case 'DPS':
          /**
                    * This class shall contain the following mandatory data attributes:
                    ** stVal [CODED ENUM: intermediate-state | off | on | bad-state]
                    ** q [Quality]
                    ** t [timestamp]
                    And the following optional attributes:
                    ** subEna [BOOLEAN]
                    ** subVal [BOOLEAN]
                    ** subQ [Quality]
                    ** subID [VISIBLE STRING64]
                    ** blkEna [BOOLEAN]
                    ** d [VISIBLE STRING255]
                    ** dU [UNICODE STRING255]
                    ** cdcNs [VISIBLE STRING255]
                    ** cdcName [VISIBLE STRING255]
                    ** dataNs [VISIBLE STRING255]
                    */

          val.stVal =
            data[i].data.stVal !== undefined
              ? DygraphTpui.valueConverter(data[i].data.stVal)
              : DygraphTpui.valueConverter(data[i].data.v); // "v" is for compatibility with older data
          if (val.stVal === null) val.stVal = -1;
          val.q = data[i].data.q ? new Quality(data[i].data.q) : new Quality(data[i].meta.q); // "meta.q" is for compatibility with older data
          val.subEna = DygraphTpui.valueConverter(data[i].data.subEna);
          val.subVal = DygraphTpui.valueConverter(data[i].data.subVal);
          val.subQ = new Quality(data[i].data.subQ);
          val.blkEna = DygraphTpui.valueConverter(data[i].data.blkEna);

          // console.log('meta', data[i].meta.transport.goose[0].agents[0].lvb_goose_sniffer);

          state_vals = {
            ts: data[i].ts.nsa,
            sim: !real,
            uid,
          };
          vals[cdc].forEach((v) => {
            if (val[v] != null) {
              state_vals[realTag[real] + v] = val[v];
              dot_vals[realTag[real] + v] = { val: true, uid };
            }
          });

          // if (data[i].meta.transport.goose[0].agents[0].lvb_goose_sniffer.length > 1) {
          //   val.sniffer_int = {
          //     t1: data[i].meta.transport.goose[0].agents[0].lvb_goose_sniffer[0].ts.nsa,
          //     t2: data[i].meta.transport.goose[0].agents[0].lvb_goose_sniffer[1].ts.nsa
          //   }
          // }

          // This is for testing purposes
          temp_val = dot_vals;

          break;

        case 'ACT':
          /**
                    * This class shall contain the following mandatory data attributes:
                    ** general [BOOLEAN]
                    ** q [Quality]
                    ** t [timestamp]
                    And the following optional attributes:
                    ** phsA [BOOLEAN]
                    ** phsB [BOOLEAN]
                    ** phsC [BOOLEAN]
                    ** neut [BOOLEAN]
                    ** originSrc [Originator]
                    ** operTmPhsA [TimeStamp]
                    ** operTmPhsB [TimeStamp]
                    ** operTmPhsB [TimeStamp]
                    ** d [VISIBLE STRING255]
                    ** dU [UNICODE STRING255]
                    ** cdcNs [VISIBLE STRING255]
                    ** cdcName [VISIBLE STRING255]
                    ** dataNs [VISIBLE STRING255]
                    */

          val.general = data[i].data.general
            ? DygraphTpui.valueConverter(data[i].data.general)
            : DygraphTpui.valueConverter(data[i].data.v); // "v" is for compatibility with older data
          val.q = data[i].data.q ? new Quality(data[i].data.q) : new Quality(data[i].meta.q); // "meta.q" is for compatibility with older data
          val.phsA = DygraphTpui.valueConverter(data[i].data.phsA);
          val.phsB = DygraphTpui.valueConverter(data[i].data.phsB);
          val.phsC = DygraphTpui.valueConverter(data[i].data.phsC);
          val.neut = DygraphTpui.valueConverter(data[i].data.neut);

          state_vals = {
            ts: data[i].ts.nsa,
            sim: !real,
            uid,
          };
          vals[cdc].forEach((v) => {
            if (val[v] != null) {
              state_vals[realTag[real] + v] = val[v];
              dot_vals[realTag[real] + v] = { val: true, uid };
            }
          });

          temp_val = dot_vals;

          break;

        case 'ACD':
          /**
                    * This class shall contain the following mandatory data attributes:
                    ** general [BOOLEAN]
                    ** dirGeneral [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
                    ** q [Quality]
                    ** t [timestamp]
                    And the following optional attributes:
                    ** phsA [BOOLEAN]
                    ** dirPhsA [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
                    ** phsB [BOOLEAN]
                    ** dirPhsB [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
                    ** phsC [BOOLEAN]
                    ** dirPhsC [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
                    ** neut [BOOLEAN]
                    ** dirNeut[ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
                    ** d [VISIBLE STRING255]
                    ** dU [UNICODE STRING255]
                    ** cdcNs [VISIBLE STRING255]
                    ** cdcName [VISIBLE STRING255]
                    ** dataNs [VISIBLE STRING255]
                    */

          val.general = DygraphTpui.valueConverter(data[i].data.general);
          val.q = new Quality(data[i].data.q);

          val.dirGeneral = DygraphTpui.valueConverter(data[i].data.dirGeneral);

          val.phsA = DygraphTpui.valueConverter(data[i].data.phsA);
          val.dirPhsA = DygraphTpui.valueConverter(data[i].data.dirPhsA);

          val.phsB = DygraphTpui.valueConverter(data[i].data.phsB);
          val.dirPhsB = DygraphTpui.valueConverter(data[i].data.dirPhsB);

          val.phsC = DygraphTpui.valueConverter(data[i].data.phsC);
          val.dirPhsC = DygraphTpui.valueConverter(data[i].data.dirPhsC);

          val.neut = DygraphTpui.valueConverter(data[i].data.neut);
          val.dirNeut = DygraphTpui.valueConverter(data[i].data.dirNeut);

          state_vals = {
            ts: data[i].ts.nsa,
            sim: !real,
            uid,
          };
          vals[cdc].forEach((v) => {
            if (val[v] != null) {
              state_vals[realTag[real] + v] = val[v];
              dot_vals[realTag[real] + v] = { val: true, uid };
            }
          });

          temp_val = dot_vals;
          break;

        case 'SPC':
          /**
                    * This class shall contain the following mandatory data attributes:
                    ** stVal [BOOLEAN]
                    ** q [Quality]
                    ** t [timestamp]
                    And the following optional attributes:
                    ** origin [Originator]
                    ** ctlNum [INT8U]
                    ** stSeld [BOOLEAN]
                    ** opRcvd [BOOLEAN]
                    ** opOk [BOOLEAN]
                    ** tOpOk [Timestamp]
                    ** subEna [BOOLEAN]
                    ** subVal [BOOLEAN]
                    ** subQ [Quality]
                    ** subID [VISIBLE STRING64]
                    ** blkEna [BOOLEAN]
                    ** pulseConfig [PulseConfig]
                    ** ctlModel [CtlModels]
                    ** sboTimeout [INT32U]
                    ** sboClass [SboClasses]
                    ** operTimeout [INT32U]
                    ** d [VISIBLE STRING255]
                    ** dU [UNICODE STRING255]
                    ** cdcNs [VISIBLE STRING255]
                    ** cdcName [VISIBLE STRING255]
                    ** dataNs [VISIBLE STRING255]
                    */
          val.stVal =
            data[i].data.stVal !== undefined
              ? DygraphTpui.valueConverter(data[i].data.stVal)
              : DygraphTpui.valueConverter(data[i].data.v); // "v" is for compatibility with older data
          val.q = data[i].data.q ? new Quality(data[i].data.q) : new Quality(data[i].meta.q); // "meta.q" is for compatibility with older data

          val.origin = data[i].data.origin;

          // Control
          val.stSeld = DygraphTpui.valueConverter(data[i].data.stSeld);
          val.opRcvd = DygraphTpui.valueConverter(data[i].data.opRcvd);
          val.opOk = DygraphTpui.valueConverter(data[i].data.opOk);

          // Substition
          val.subEna = DygraphTpui.valueConverter(data[i].data.subEna);
          val.subVal = DygraphTpui.valueConverter(data[i].data.subVal);
          val.subQ = new Quality(data[i].data.subQ);

          // Blocked
          val.blkEna = DygraphTpui.valueConverter(data[i].data.blkEna);

          state_vals = {
            ts: data[i].ts.nsa,
            sim: !real,
            uid,
          };
          vals[cdc].forEach((v) => {
            if (val[v] != null) {
              state_vals[realTag[real] + v] = val[v];
              dot_vals[realTag[real] + v] = { val: true, uid };
            }
          });

          temp_val = dot_vals;

          break;

        case 'DPC':
          /**
                    * This class shall contain the following mandatory data attributes:
                    ** stVal [CODED ENUM: intermediate-state | off | on | bad-state]
                    ** q [Quality]
                    ** t [timestamp]
                    And the following optional attributes:
                    ** origin [Originator]
                    ** ctlNum [INT8U]
                    ** stSeld [BOOLEAN]
                    ** opRcvd [BOOLEAN]
                    ** opOk [BOOLEAN]
                    ** tOpOk [Timestamp]
                    ** subEna [BOOLEAN]
                    ** subVal [BOOLEAN]
                    ** subQ [Quality]
                    ** subID [VISIBLE STRING64]
                    ** blkEna [BOOLEAN]
                    ** pulseConfig [PulseConfig]
                    ** ctlModel [CtlModels]
                    ** sboTimeout [INT32U]
                    ** sboClass [SboClasses]
                    ** operTimeout [INT32U]
                    ** d [VISIBLE STRING255]
                    ** dU [UNICODE STRING255]
                    ** cdcNs [VISIBLE STRING255]
                    ** cdcName [VISIBLE STRING255]
                    ** dataNs [VISIBLE STRING255]
                    */

          val.stVal =
            data[i].data.stVal !== undefined
              ? DygraphTpui.valueConverter(data[i].data.stVal)
              : DygraphTpui.valueConverter(data[i].data.v); // "v" is for compatibility with older data
          console.log('DPC', data[i].data.stVal, DygraphTpui.valueConverter(data[i].data.stVal));
          val.q = data[i].data.q ? new Quality(data[i].data.q) : new Quality(data[i].meta.q); // "meta.q" is for compatibility with older data

          val.origin = data[i].data.origin;

          // Control
          val.stSeld = DygraphTpui.valueConverter(data[i].data.stSeld);
          val.opRcvd = DygraphTpui.valueConverter(data[i].data.opRcvd);
          val.opOk = DygraphTpui.valueConverter(data[i].data.opOk);

          // Substition
          val.subEna = DygraphTpui.valueConverter(data[i].data.subEna);
          val.subVal = DygraphTpui.valueConverter(data[i].data.subVal);
          val.subQ = new Quality(data[i].data.subQ);

          // Blocked
          val.blkEna = DygraphTpui.valueConverter(data[i].data.blkEna);

          state_vals = {
            ts: data[i].ts.nsa,
            sim: !real,
            uid,
          };
          vals[cdc].forEach((v) => {
            if (val[v] != null) {
              state_vals[realTag[real] + v] = val[v];
              dot_vals[realTag[real] + v] = { val: true, uid };
            }
          });

          temp_val = dot_vals;

          break;

        default:
          console.warn('Common data class ' + cdc + ' is not supported in this version of Tekvel Park');
          break;
      }

      // temp_val.splice(0, 0, data[i].ts.nsa);
      temp_val['Time'] = data[i].ts.nsa;

      //dot_vals.splice(0, 0, data[i].ts);
      dots_arr.push(temp_val);
      state_arr.push(state_vals);
      meta.push({ real: real, uid, ts: data[i].ts.nsa, meta: data[i].meta });
      q_arr.push({ real: real, uid, ts: data[i].ts.nsa, q: val.q });
      subQ_arr.push({ real: real, uid, ts: data[i].ts.nsa, subQ: val.subQ });
    }

    quality = { q: q_arr, subQ: subQ_arr };

    // Choose plotters based on CDC:
    switch (cdc) {
      case 'SPS':
        vals[cdc].forEach((v) => {
          labels.push(realTag[real] + v);
          series[realTag[real] + v] = { plotter: plotters[cdc][v] };
        });

        break;

      case 'DPS':
        vals[cdc].forEach((v) => {
          labels.push(realTag[real] + v);
          series[realTag[real] + v] = { plotter: plotters[cdc][v] };
        });

        break;

      case 'ACT':
        // Protection activation information
        // The plotters are as follows: [val.general, val.phsA, val.phsB, val.phsC, val.neut];

        vals[cdc].forEach((v) => {
          labels.push(realTag[real] + v);
          series[realTag[real] + v] = { plotter: plotters[cdc][v] };
        });

        break;

      case 'ACD':
        // The plotters are as follows: [val.general, val.dirGeneral, val.phsA, val.dirPhsA, val.phsB, val.dirPhsB, val.phsC, val.dirPhsC, val.neut, val.dirNeut];

        vals[cdc].forEach((v) => {
          labels.push(realTag[real] + v);
          series[realTag[real] + v] = { plotter: plotters[cdc][v] };
        });

        break;

      case 'SPC':
        // The plotters are as follows: [val.stVal, val.stSeld, val.opRcvd, val.opOk, val.subEna, val.subVal, val.subQ, val.blkEna];

        vals[cdc].forEach((v) => {
          labels.push(realTag[real] + v);
          series[realTag[real] + v] = { plotter: plotters[cdc][v] };
        });

        break;

      case 'DPC':
        // The plotters are as follows: [val.stVal, val.stSeld, val.opRcvd, val.opOk, val.subEna, val.subVal, val.subQ, val.blkEna];

        vals[cdc].forEach((v) => {
          labels.push(realTag[real] + v);
          series[realTag[real] + v] = { plotter: plotters[cdc][v] };
        });

        break;

      default:
        // for all other cases simply try to map all available data to line plotters
        plotter = Dygraph.Plotters.linePlotter;
    }

    return {
      arr: dots_arr,
      states: state_arr,
      type: cdc,
      labels: labels,
      quality: quality,
      meta: meta,
      series: series,
      valueRange: [],
      tickers: [],
    };
  };

  // DygraphTpui.metaToStyle = function (arr, cdc, meta) {
  // /*
  // * Style parameters will be stored in point[0] (meta[0]) for each segment
  // * Style parameters are:
  // * stroke-width
  // * stroke-color
  // * fill-color (or none: without fill)
  // */
  function convertQualityToStrokeStyle(q) {
    var strokeStyle;

    switch (q & QUALITY_MASK) {
      case 0:
        strokeStyle = Q_GOOD_COLOR;
        break;
      case 1:
        strokeStyle = Q_BAD_COLOR;
        break;
      case 3:
        strokeStyle = Q_QUESTIONABLE_COLOR;
        break;
      case 2:
      default:
        strokeStyle = Q_RESERVED_COLOR;
        break;
    }

    return strokeStyle;
  }
  // function convertStateToStopColor(state) {
  // var stopColor

  DygraphTpui._NO_GRANULARITIE = -1;
  DygraphTpui.NUM_GRANULARITIES = 35;

  DygraphTpui.MICROSECONDLY_5 = 0;
  DygraphTpui.MICROSECONDLY_10 = 1;
  DygraphTpui.MICROSECONDLY_50 = 2;
  DygraphTpui.MICROSECONDLY_100 = 3;
  DygraphTpui.MICROSECONDLY_250 = 4;
  DygraphTpui.MICROSECONDLY_500 = 5;
  DygraphTpui.MILLISECONDLY_1 = 6;
  DygraphTpui.MILLISECONDLY_5 = 7;
  DygraphTpui.MILLISECONDLY_10 = 8;
  DygraphTpui.MILLISECONDLY_50 = 9;
  DygraphTpui.MILLISECONDLY_100 = 10;
  DygraphTpui.MILLISECONDLY_250 = 11;
  DygraphTpui.MILLISECONDLY_500 = 12;
  DygraphTpui.SECONDLY = 13;
  DygraphTpui.TWO_SECONDLY = 14;
  DygraphTpui.FIVE_SECONDLY = 15;
  DygraphTpui.TEN_SECONDLY = 16;
  DygraphTpui.THIRTY_SECONDLY = 17;
  DygraphTpui.MINUTELY = 18;
  DygraphTpui.TWO_MINUTELY = 19;
  DygraphTpui.FIVE_MINUTELY = 20;
  DygraphTpui.TEN_MINUTELY = 21;
  DygraphTpui.THIRTY_MINUTELY = 22;
  DygraphTpui.HOURLY = 23;
  DygraphTpui.TWO_HOURLY = 24;
  DygraphTpui.SIX_HOURLY = 25;
  DygraphTpui.DAILY = 26;
  DygraphTpui.TWO_DAILY = 27;
  DygraphTpui.WEEKLY = 28;
  DygraphTpui.MONTHLY = 29;
  DygraphTpui.QUARTERLY = 30;
  DygraphTpui.BIANNUAL = 31;
  DygraphTpui.ANNUAL = 32;
  DygraphTpui.DECADAL = 33;
  DygraphTpui.CENTENNIAL = 34;

  DygraphTpui.NUM_DATEFIELDS = 8;

  DygraphTpui.DATEFIELD_Y = 0;
  DygraphTpui.DATEFIELD_M = 1;
  DygraphTpui.DATEFIELD_D = 2;
  DygraphTpui.DATEFIELD_HH = 3;
  DygraphTpui.DATEFIELD_MM = 4;
  DygraphTpui.DATEFIELD_SS = 5;
  DygraphTpui.DATEFIELD_MS = 6;
  DygraphTpui.DATEFIELD_US = 7;

  DygraphTpui.TICK_PLACEMENT = [];
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MICROSECONDLY_5] = {
    datefield: DygraphTpui.DATEFIELD_US,
    step: 5,
    spacing: 5,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MICROSECONDLY_10] = {
    datefield: DygraphTpui.DATEFIELD_US,
    step: 10,
    spacing: 10,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MICROSECONDLY_50] = {
    datefield: DygraphTpui.DATEFIELD_US,
    step: 50,
    spacing: 50,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MICROSECONDLY_100] = {
    datefield: DygraphTpui.DATEFIELD_US,
    step: 100,
    spacing: 100,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MICROSECONDLY_250] = {
    datefield: DygraphTpui.DATEFIELD_US,
    step: 200,
    spacing: 200,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MICROSECONDLY_500] = {
    datefield: DygraphTpui.DATEFIELD_US,
    step: 500,
    spacing: 500,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MILLISECONDLY_1] = {
    datefield: DygraphTpui.DATEFIELD_MS,
    step: 1,
    spacing: 1 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MILLISECONDLY_5] = {
    datefield: DygraphTpui.DATEFIELD_MS,
    step: 5,
    spacing: 5 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MILLISECONDLY_10] = {
    datefield: DygraphTpui.DATEFIELD_MS,
    step: 10,
    spacing: 10 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MILLISECONDLY_50] = {
    datefield: DygraphTpui.DATEFIELD_MS,
    step: 50,
    spacing: 50 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MILLISECONDLY_100] = {
    datefield: DygraphTpui.DATEFIELD_MS,
    step: 100,
    spacing: 100 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MILLISECONDLY_250] = {
    datefield: DygraphTpui.DATEFIELD_MS,
    step: 250,
    spacing: 250 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MILLISECONDLY_500] = {
    datefield: DygraphTpui.DATEFIELD_MS,
    step: 500,
    spacing: 500 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.SECONDLY] = {
    datefield: DygraphTpui.DATEFIELD_SS,
    step: 1,
    spacing: 1000 * 1 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.TWO_SECONDLY] = {
    datefield: DygraphTpui.DATEFIELD_SS,
    step: 2,
    spacing: 1000 * 2 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.FIVE_SECONDLY] = {
    datefield: DygraphTpui.DATEFIELD_SS,
    step: 5,
    spacing: 1000 * 5 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.TEN_SECONDLY] = {
    datefield: DygraphTpui.DATEFIELD_SS,
    step: 10,
    spacing: 1000 * 10 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.THIRTY_SECONDLY] = {
    datefield: DygraphTpui.DATEFIELD_SS,
    step: 30,
    spacing: 1000 * 30 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MINUTELY] = {
    datefield: DygraphTpui.DATEFIELD_MM,
    step: 1,
    spacing: 1000 * 60 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.TWO_MINUTELY] = {
    datefield: DygraphTpui.DATEFIELD_MM,
    step: 2,
    spacing: 1000 * 60 * 2 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.FIVE_MINUTELY] = {
    datefield: DygraphTpui.DATEFIELD_MM,
    step: 5,
    spacing: 1000 * 60 * 5 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.TEN_MINUTELY] = {
    datefield: DygraphTpui.DATEFIELD_MM,
    step: 10,
    spacing: 1000 * 60 * 10 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.THIRTY_MINUTELY] = {
    datefield: DygraphTpui.DATEFIELD_MM,
    step: 30,
    spacing: 1000 * 60 * 30 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.HOURLY] = {
    datefield: DygraphTpui.DATEFIELD_HH,
    step: 1,
    spacing: 1000 * 3600 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.TWO_HOURLY] = {
    datefield: DygraphTpui.DATEFIELD_HH,
    step: 2,
    spacing: 1000 * 3600 * 2 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.SIX_HOURLY] = {
    datefield: DygraphTpui.DATEFIELD_HH,
    step: 6,
    spacing: 1000 * 3600 * 6 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.DAILY] = {
    datefield: DygraphTpui.DATEFIELD_D,
    step: 1,
    spacing: 1000 * 86400 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.TWO_DAILY] = {
    datefield: DygraphTpui.DATEFIELD_D,
    step: 2,
    spacing: 1000 * 86400 * 2 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.WEEKLY] = {
    datefield: DygraphTpui.DATEFIELD_D,
    step: 7,
    spacing: 1000 * 604800 * 1e3,
  };
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.MONTHLY] = {
    datefield: DygraphTpui.DATEFIELD_M,
    step: 1,
    spacing: 1000 * 7200 * 365.2524 * 1e3,
  }; // 1e3 * 60 * 60 * 24 * 365.2524 / 12
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.QUARTERLY] = {
    datefield: DygraphTpui.DATEFIELD_M,
    step: 3,
    spacing: 1000 * 21600 * 365.2524 * 1e3,
  }; // 1e3 * 60 * 60 * 24 * 365.2524 / 4
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.BIANNUAL] = {
    datefield: DygraphTpui.DATEFIELD_M,
    step: 6,
    spacing: 1000 * 43200 * 365.2524 * 1e3,
  }; // 1e3 * 60 * 60 * 24 * 365.2524 / 2
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.ANNUAL] = {
    datefield: DygraphTpui.DATEFIELD_Y,
    step: 1,
    spacing: 1000 * 86400 * 365.2524 * 1e3,
  }; // 1e3 * 60 * 60 * 24 * 365.2524 * 1
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.DECADAL] = {
    datefield: DygraphTpui.DATEFIELD_Y,
    step: 10,
    spacing: 1000 * 864000 * 365.2524 * 1e3,
  }; // 1e3 * 60 * 60 * 24 * 365.2524 * 10
  DygraphTpui.TICK_PLACEMENT[DygraphTpui.CENTENNIAL] = {
    datefield: DygraphTpui.DATEFIELD_Y,
    step: 100,
    spacing: 1000 * 8640000 * 365.2524 * 1e3,
  }; // 1e3 * 60 * 60 * 24 * 365.2524 * 100

  DygraphTpui.dateTicker = function (a, b, pixels, opts, dygraph, vals) {
    var chosen = DygraphTpui.pickDateTickGranularity(a, b, pixels, opts);
    // console.log('chosen', chosen);
    if (chosen > DygraphTpui._NO_GRANULARITIE) {
      //console.log("Good pick", chosen)
      return DygraphTpui.getDateAxis(a, b, chosen, opts, dygraph);
    } else {
      // console.log("Bad pick")
      //this can happen if self.width_ is zero.
      return [];
    }
  };

  DygraphTpui.pickDateTickGranularity = function (a, b, pixels, opts) {
    var pixels_per_tick = /** @type{number} */ (opts('pixelsPerLabel'));
    for (var i = 0; i < DygraphTpui.NUM_GRANULARITIES; i++) {
      var num_ticks = DygraphTpui.numDateTicks(a, b, i);
      if (pixels / num_ticks >= pixels_per_tick) {
        return i;
      }
    }
    return Dygraph._NO_GRANULARITIE;
  };

  DygraphTpui.numDateTicks = function (start_time, end_time, granularity) {
    var spacing = DygraphTpui.TICK_PLACEMENT[granularity].spacing;
    return Math.round((1.0 * (end_time - start_time)) / spacing);
  };

  DygraphTpui.Date = function (y, m, d, hh, mm, ss, ms, us) {
    var isCreateFromRawUs = arguments.length === 1;

    if (isCreateFromRawUs) {
      us = y % 1e3;
      ms = Math.floor(y / 1000);
    }
    (this.date = isCreateFromRawUs ? new Date(ms) : new Date(y, m, d, hh, mm, ss, ms)), (this.us = us);
  };

  DygraphTpui.Date.prototype.getTime = function () {
    return this.date.getTime() * 1e3 + this.us;
  };

  DygraphTpui.DateAccessorsUTC = {
    getFullYear: function (d) {
      return d.date.getUTCFullYear();
    },
    getMonth: function (d) {
      return d.date.getUTCMonth();
    },
    getDate: function (d) {
      return d.date.getUTCDate();
    },
    getHours: function (d) {
      return d.date.getUTCHours();
    },
    getMinutes: function (d) {
      return d.date.getUTCMinutes();
    },
    getSeconds: function (d) {
      return d.date.getUTCSeconds();
    },
    getMilliseconds: function (d) {
      return d.date.getUTCMilliseconds();
    },
    getMicroseconds: function (d) {
      return d.us;
    },
    getDay: function (d) {
      return d.date.getUTCDay();
    },
    makeDate: function (y, m, d, hh, mm, ss, ms, us) {
      return new DygraphTpui.Date(Date.UTC(y, m, d, hh, mm, ss, ms) * 1e3 + us);
    },
  };

  DygraphTpui.DateAccessorsLocal = {
    getFullYear: function (d) {
      return d.date.getFullYear();
    },
    getMonth: function (d) {
      return d.date.getMonth();
    },
    getDate: function (d) {
      return d.date.getDate();
    },
    getHours: function (d) {
      return d.date.getHours();
    },
    getMinutes: function (d) {
      return d.date.getMinutes();
    },
    getSeconds: function (d) {
      return d.date.getSeconds();
    },
    getMilliseconds: function (d) {
      return d.date.getMilliseconds();
    },
    getMicroseconds: function (d) {
      return d.us;
    },
    getDay: function (d) {
      return d.date.getDay();
    },
    makeDate: function (y, m, d, hh, mm, ss, ms, us) {
      return new DygraphTpui.Date(y, m, d, hh, mm, ss, ms, us);
    },
  };

  DygraphTpui.getDateAxis = function (start_time, end_time, granularity, opts, dg) {
    var formatter = /** @type{AxisLabelFormatter} */ (opts('axisLabelFormatter'));
    var utc = opts('labelsUTC');
    var accessors = utc ? DygraphTpui.DateAccessorsUTC : DygraphTpui.DateAccessorsLocal;

    var datefield = DygraphTpui.TICK_PLACEMENT[granularity].datefield;
    var step = DygraphTpui.TICK_PLACEMENT[granularity].step;
    var spacing = DygraphTpui.TICK_PLACEMENT[granularity].spacing;

    /**
            Choose a nice tick position before the initial instant.
                Currently, this code deals properly with the existent daily granularities:
            DAILY(with step of 1) and WEEKLY (with step of 7 but specially handled).
            Other daily granularities (say TWO_DAILY) should also be handled specially
            by setting the start_date_offset to 0.
        */
    var start_date = new DygraphTpui.Date(start_time);
    var date_array = [];
    date_array[DygraphTpui.DATEFIELD_Y] = accessors.getFullYear(start_date);
    date_array[DygraphTpui.DATEFIELD_M] = accessors.getMonth(start_date);
    date_array[DygraphTpui.DATEFIELD_D] = accessors.getDate(start_date);
    date_array[DygraphTpui.DATEFIELD_HH] = accessors.getHours(start_date);
    date_array[DygraphTpui.DATEFIELD_MM] = accessors.getMinutes(start_date);
    date_array[DygraphTpui.DATEFIELD_SS] = accessors.getSeconds(start_date);
    date_array[DygraphTpui.DATEFIELD_MS] = accessors.getMilliseconds(start_date);
    date_array[DygraphTpui.DATEFIELD_US] = accessors.getMicroseconds(start_date);

    var start_date_offset = date_array[datefield] % step;
    if (granularity == DygraphTpui.WEEKLY) {
      //This will put the ticks on Sundays.
      start_date_offset = accessors.getDay(start_date);
    }

    date_array[datefield] -= start_date_offset;
    for (var df = datefield + 1; df < DygraphTpui.NUM_DATEFIELDS; df++) {
      //The minimum value is 1 for the day of month, and 0 for all other fields.
      date_array[df] = df === DygraphTpui.DATEFIELD_D ? 1 : 0;
    }

    /**
        Generate the ticks.
        For granularities not coarser than HOURLY we use the fact that:
        the number of milliseconds between ticks is constant
        and equal to the defined spacing.
        Otherwise we rely on the 'roll over' property of the Date functions:
        when some date field is set to a value outside of its logical range,
            the excess 'rolls over' the next (more significant) field.
                However, when using local time with DST transitions,
                    there are dates that do not represent any time value at all
                        (those in the hour skipped at the 'spring forward'),
                        and the JavaScript engines usually return an equivalent value.
        Hence we have to check that the date is properly increased at each step,
            returning a date at a nice tick position.
        */
    var ticks = [];
    var tick_date = accessors.makeDate.apply(null, date_array);
    var tick_time = tick_date.getTime();
    if (granularity <= DygraphTpui.HOURLY) {
      if (tick_time < start_time) {
        tick_time += spacing;
        tick_date = new DygraphTpui.Date(tick_time);
      }
      while (tick_time <= end_time) {
        ticks.push({
          v: tick_time,
          label: formatter.call(dg, tick_date, granularity, opts, dg),
        });
        tick_time += spacing;
        tick_date = new DygraphTpui.Date(tick_time);
      }
    } else {
      if (tick_time < start_time) {
        date_array[datefield] += step;
        tick_date = accessors.makeDate.apply(null, date_array);
        tick_time = tick_date.getTime();
      }
      while (tick_time <= end_time) {
        if (granularity >= DygraphTpui.DAILY || accessors.getHours(tick_date) % step === 0) {
          ticks.push({
            v: tick_time,
            label: formatter.call(dg, tick_date, granularity, opts, dg),
          });
        }
        date_array[datefield] += step;
        tick_date = accessors.makeDate.apply(null, date_array);
        tick_time = tick_date.getTime();
      }
    }
    // console.log(ticks)
    return ticks;
  };

  Dygraph.hmsString_ = function hmsString_(hours, mins, secs) {
    let hoursString = hours >= 10 ? hours : '0' + hours;
    let minsString = mins >= 10 ? mins : '0' + mins;
    let secsString = secs >= 10 ? secs : '0' + secs;

    return hoursString + ':' + minsString + ':' + secsString;
  };

  DygraphTpui.dateAxisLabelFormatter = function (date, granularity, opts) {
    date.date = date.date.setTimezoneOffset(-120 /*main.user.timezoneOffset*/);
    var utc = opts('labelsUTC');
    var accessors = utc ? DygraphTpui.DateAccessorsUTC : DygraphTpui.DateAccessorsLocal;

    var year = accessors.getFullYear(date),
      month = accessors.getMonth(date),
      day = accessors.getDate(date),
      hours = accessors.getHours(date),
      mins = accessors.getMinutes(date),
      secs = accessors.getSeconds(date),
      millis = accessors.getMilliseconds(date),
      micros = accessors.getMicroseconds(date);

    if (granularity >= DygraphTpui.DECADAL) {
      return '' + year;
    } else if (granularity >= DygraphTpui.MONTHLY) {
      return month < 10 ? `0${month}` : month /*Dygraph.SHORT_MONTH_NAMES_[month]*/ + '&#160;' + year;
    } else if (
      granularity === DygraphTpui.MILLISECONDLY_500 ||
      granularity === DygraphTpui.MILLISECONDLY_250 ||
      granularity === DygraphTpui.MILLISECONDLY_100 ||
      granularity === DygraphTpui.MILLISECONDLY_50 ||
      granularity === DygraphTpui.MILLISECONDLY_10 ||
      granularity === DygraphTpui.MILLISECONDLY_5 ||
      granularity === DygraphTpui.MILLISECONDLY_1
    ) {
      return secs + '.' + millis.toString().padStart(3, '000');
    } else if (
      granularity === DygraphTpui.MICROSECONDLY_500 ||
      granularity === DygraphTpui.MICROSECONDLY_250 ||
      granularity === DygraphTpui.MICROSECONDLY_100 ||
      granularity === DygraphTpui.MICROSECONDLY_50 ||
      granularity === DygraphTpui.MICROSECONDLY_10 ||
      granularity === DygraphTpui.MICROSECONDLY_5
    ) {
      return millis + ':' + micros.toString().padStart(3, '000');
    } else {
      var frac = hours * 3600 + mins * 60 + secs + 1e-3 * millis;
      if (frac === 0 || granularity >= DygraphTpui.DAILY) {
        //e.g. '21 Jan' (%d % b)
        // console.log(day);
        return day < 10 ? `0${day}` : day + '&#160;' + month < 10 ? `0${month}` : month; // Dygraph.SHORT_MONTH_NAMES_[month];
      } else {
        return Dygraph.hmsString_(hours, mins, secs);
      }
    }
  };

  /*
   *
   * @param {type} e {dygraph, canvas}
   * @returns {undefined}
   */
  DygraphTpui.drawHostXAxis = function (e) {
    var g = e.dygraph;

    if (
      !g.getOptionForAxis('drawAxis', 'x') &&
      !g.getOptionForAxis('drawAxis', 'y') &&
      !g.getOptionForAxis('drawAxis', 'y2')
    ) {
      return;
    }

    //Round pixels to half- integer boundaries for crisper drawing.
    function halfUp(x) {
      return Math.round(x) + 0.5;
    }
    function halfDown(y) {
      return Math.round(y) - 0.5;
    }

    var context = e.canvas.getContext('2d'); //e.drawingContext;
    var containerDiv = e.canvas.parentNode;
    var ticksContainer = document.getElementById('x_axis_ticks');
    var canvasWidth = e.canvas.style.width; //g.width_;  // e.canvas.width is affected by pixel ratio.
    var canvasHeight = e.canvas.style.height; //g.height_;

    var label, x, y, tick, i;

    var makeLabelStyle = function (axis) {
      return {
        position: 'absolute',
        fontSize: g.getOptionForAxis('axisLabelFontSize', axis) + 'px',
        zIndex: 10,
        color: g.getOptionForAxis('axisLabelColor', axis),
        width: g.getOptionForAxis('axisLabelWidth', axis) + 'px',
        height: g.getOptionForAxis('axisLabelFontSize', 'x') + 2 + 'px',
        lineHeight: 'normal', // Something other than "normal" line-height screws up label positioning.
        overflow: 'hidden',
      };
    };

    var labelStyles = {
      x: makeLabelStyle('x'),
      y: makeLabelStyle('y'),
      y2: makeLabelStyle('y2'),
    };

    var makeDiv = function (txt, axis, prec_axis) {
      /*
       * This seems to be called with the following three sets of axis/prec_axis:
       * x: undefined
       * y: y1
       * y: y2
       */
      var div = document.createElement('div');
      var labelStyle = labelStyles[prec_axis == 'y2' ? 'y2' : axis];
      for (var name in labelStyle) {
        if (labelStyle.hasOwnProperty(name)) {
          div.style[name] = labelStyle[name];
        }
      }
      var inner_div = document.createElement('div');
      inner_div.className =
        'dygraph-axis-label' + ' dygraph-axis-label-' + axis + (prec_axis ? ' dygraph-axis-label-' + prec_axis : '');
      inner_div.innerHTML = txt;
      // div.appendChild(inner_div);
      return div;
    };

    //axis lines
    while (ticksContainer.firstChild) {
      ticksContainer.removeChild(ticksContainer.firstChild);
    }
    context.save();

    var layout = g.layout_;
    var area = e.dygraph.plotter_.area;

    //Helper for repeated axis- option accesses.
    var makeOptionGetter = function (axis) {
      return function (option) {
        return g.getOptionForAxis(option, axis);
      };
    };

    if (g.getOptionForAxis('drawAxis', 'x')) {
      if (layout.xticks) {
        var getAxisOption = makeOptionGetter('x');
        for (i = 0; i < layout.xticks.length; i++) {
          tick = layout.xticks[i];
          x = area.x + tick[0] * area.w;
          y = area.y + area.h;

          /* Tick marks are currently clipped, so don't bother drawing them.
                    context.beginPath();
                    context.moveTo(halfUp(x), halfDown(y));
                    context.lineTo(halfUp(x), halfDown(y + this.attr_('axisTickSize')));
                    context.closePath();
                    context.stroke();
                    */

          label = makeDiv(tick[1], 'x');
          label.style.textAlign = 'center';
          label.style.top = y + getAxisOption('axisTickSize') + 'px';

          var left = x - getAxisOption('axisLabelWidth') / 2;
          if (left + getAxisOption('axisLabelWidth') > canvasWidth) {
            left = canvasWidth - getAxisOption('axisLabelWidth');
            label.style.textAlign = 'right';
          }
          if (left < 0) {
            left = 0;
            label.style.textAlign = 'left';
          }

          label.style.left = left + 'px';
          label.style.width = getAxisOption('axisLabelWidth') + 'px';
          ticksContainer.appendChild(label);
          this.xlabels_.push(label);
        }
      }

      context.strokeStyle = g.getOptionForAxis('axisLineColor', 'x');
      context.lineWidth = g.getOptionForAxis('axisLineWidth', 'x');
      context.beginPath();
      var axisY;
      if (g.getOption('drawAxesAtZero')) {
        var r = g.toPercentYCoord(0, 0);
        if (r > 1 || r < 0) r = 1;
        axisY = halfDown(area.y + r * area.h);
      } else {
        axisY = halfDown(area.y + area.h);
      }
      context.moveTo(halfUp(area.x), axisY);
      context.lineTo(halfUp(area.x + area.w), axisY);
      context.closePath();
      context.stroke();
    }

    context.restore();
  };

  /**
   * Plotter which draws SPS class data: [stVal, subEna, subVal, blkEna]
   * @private
   */
  DygraphTpui._spsPlotter = function spsPlotter(e) {
    //console.log(e);
    var g = e.dygraph;
    var setName = e.setName;
    var strokeWidth = e.strokeWidth;

    // TODO(danvk): Check if there's any performance impact of just calling
    // getOption() inside of _drawStyledLine. Passing in so many parameters makes
    // this code a bit nasty.
    var borderWidth = g.getNumericOption('strokeBorderWidth', setName);
    var drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
    var strokePattern = g.getOption('strokePattern', setName);
    var drawPoints = g.getBooleanOption('drawPoints', setName);
    var pointSize = g.getNumericOption('pointSize', setName);

    var dataObjects = [];
    var dataObject;

    var sets = e.allSeriesPoints;

    for (var p = 0; p < sets[0].length; p++) {
      dataObject = {
        stVal: sets[0][p].yval,
        subEna: sets[1][p].yval,
        subVal: sets[2][p].yval,
        blkEna: sets[3][p].yval,
      };
      dataObjects.push(dataObject);
    }

    var area = e.plotArea;
    var ctx = e.drawingContext;
    ctx.strokeStyle = '#202020';
    ctx.lineWidth = 0.6;

    for (p = 1; p < dataObjects.length; p++) {
      ctx.beginPath();

      let this_dataObject = dataObjects[p];
      let previous_dataObject = dataObjects[p - 1];

      var previous_stVal = previous_dataObject.stVal;
      let centerY = area.h / 2;
      var previous_X = area.x + sets[0][p - 1].x * area.w;
      var this_X = area.x + sets[0][p].x * area.w;

      e.points[p].y = 0;

      if (previous_stVal == 1) {
        ctx.fillStyle = '#f4d442';
        ctx.fillRect(previous_X, centerY - 3, this_X, area.h - centerY + 2);
      } else if (previous_stVal == 0) {
        ctx.fillStyle = '#40edc4';
        ctx.fillRect(previous_X, centerY - 1, this_X, area.h - centerY + 1);
      }

      //ctx.moveTo(previous_StValX, previous_stValY);
      //ctx.lineTo(this_StValX, previous_stValY);
      //ctx.closePath();
      //ctx.stroke();
    }

    //if (borderWidth && strokeWidth) {
    //    DygraphTpui._drawStyledLine(e,
    //        g.getOption("strokeBorderColor", setName),
    //        strokeWidth + 2 * borderWidth,
    //        strokePattern,
    //        drawPoints,
    //        drawPointCallback,
    //        pointSize
    //    );
    //}

    //DygraphTpui._drawStyledLine(e,
    //    e.color,
    //    strokeWidth,
    //    strokePattern,
    //    drawPoints,
    //    drawPointCallback,
    //    pointSize
    //);
  };

  /**
   * Plotter for BOOLEAN type data
   */

  DygraphTpui._lineBoolPlotter = function tpui_lineBoolPlotter(e) {
    // console.log('_lineBoolPlotter', e);
    let g = e.dygraph;
    let ctx = e.drawingContext;

    let setName = e.setName;
    let strokeWidth = e.strokeWidth;

    console.log('setName', setName);

    let borderWidth = g.getNumericOption('strokeBorderWidth', setName);
    let drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
    let strokePattern = g.getOption('strokePattern', setName);
    let drawPoints = g.getBooleanOption('drawPoints', setName);
    let pointSize = g.getNumericOption('pointSize', setName);
    console.log('opts', borderWidth, drawPointCallback, strokePattern, drawPoints, pointSize);

    let area = e.plotArea;

    let plotWidth = area.w;
    let plotHeight = area.h;
    let plotLeftBoundary = area.x;
    let xmax = e.axis.extremeRange[1];
    let plotMidHeight = plotHeight / 2;
    let yscale = e.axis.yscale;
    let yrange = e.axis.yrange;
    let ymin = e.axis.minyval;
    let ymax = e.axis.maxyval;
    let size = e.dygraph.user_attrs_._size;
    let qualities = e.dygraph.attributes_.user_.quality;

    console.log('yscale', yscale, ymin, plotHeight, e.axis, area);

    let pointsAll = e.points;
    let states = e.dygraph.user_attrs_._states;
    let meta = e.dygraph.user_attrs_.meta;

    let yShift;
    let booleanHeightTrue;
    let booleanHeightFalse;
    let showInterval = false;
    let applyQ = false,
      applySubQ = false;
    let yex = e.dygraph.yAxisExtremes();
    console.log('points yex', yex, e);

    // points.sort(function (a, b) {
    //   // Compare the 2 dates
    //   if (a.x < b.x) return -1;
    //   if (a.x > b.x) return 1;
    //   return 0;
    // });

    function convertTs(ev, ts) {
      const a = ev.plotArea;
      let exTs = ev.dygraph.dateWindow_;
      if (!exTs) {
        exTs = ev.dygraph.xAxisExtremes();
      }
      const minTs = exTs[0];
      const maxTs = exTs[1];
      const wTs = maxTs - minTs;
      const x = ((ts - minTs) / wTs) * a.w;
      // console.log('x_ts:', x);
      return x;
    }

    //console.log(e);

    // Choose setName-specific parameters (eg. shifts for non-primary data etc.):

    // console.log('e.setName', e.setName, points);

    switch (e.setName) {
      case 'real.stVal':
      case 'sim.stVal':
        //ctx.fillStyle = "#f9b2ff";
        booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
        showInterval = true;
        applyQ = true;
        break;
      case 'real.general':
      case 'sim.general':
        //yShift = PRIMARY_Y_SHIFT;
        //ctx.fillStyle = "#f9b2ff";
        booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
        showInterval = true;
        applyQ = true;
        break;
      case 'real.phsA':
      case 'sim.phsA':
        //yShift = SECONDARY_Y_SHIFT + 0 * SECONDARY_D_SHIFT;
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        applyQ = true;
        //ctx.fillStyle = "#ffeb87";
        break;
      case 'real.phsB':
      case 'sim.phsB':
        //yShift = SECONDARY_Y_SHIFT + 1 * SECONDARY_D_SHIFT;
        //ctx.fillStyle = "#87ffab";
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        applyQ = true;
        break;
      case 'real.phsC':
      case 'sim.phsC':
        //yShift = SECONDARY_Y_SHIFT + 2 * SECONDARY_D_SHIFT;
        //ctx.fillStyle = "#ff8799";
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        applyQ = true;
        break;
      case 'real.neut':
      case 'sim.neut':
        //yShift = SECONDARY_Y_SHIFT + 3 * SECONDARY_D_SHIFT;
        //ctx.fillStyle = "#e8e8e8";
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        applyQ = true;
        break;
      case 'real.subVal':
      case 'sim.subVal':
        booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
        // applySubQ = true;
        applyQ = true;
        break;

      default:
        //yShift = 0;
        booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
        break;
    }

    ctx.globalCompositeOperation = 'source-over';

    // Shift middle line by yShift (applies for secondary lines)
    let boolLineMidY = plotMidHeight + yShift;

    let sniffer_ints = [];
    // console.log('meta', meta);

    // console.log('meta', sniffer_ints);

    // if (showInterval) {
    //   sniffer_ints.forEach(function (sn_int) {
    //     let value = 0.5;
    //     let hShift = (-booleanHeightTrue * yscale) / 2;
    //     // ctx.fillStyle = '#FF7F27';
    //     ctx.fillStyle = 'rgba(255,125,38,1)';
    //     ctx.strokeStyle = '#FF7F27';
    //     rect(
    //       ctx,
    //       e,
    //       plotLeftBoundary + sn_int.t1.x,
    //       value * plotHeight + hShift,
    //       plotLeftBoundary + sn_int.t2.x,
    //       value * plotHeight + hShift + booleanHeightTrue * yscale
    //     );
    //   });
    // }

    // points.sort(function(a, b) {
    //   // Compare the 2 dates
    //   if (a.xval < b.xval) return -1;
    //   if (a.xval > b.xval) return 1;
    //   return 0;
    // });
    console.log('points all', pointsAll);
    let points = pointsAll.filter((el) => Array.isArray(el.yval));
    console.log('points meta', points, meta);
    let metaStatesAll = meta.map((e) => {
      return { uid: e.uid, state: e.meta.transport.goose.map((g) => g.goose_alive_state) };
    });
    let pointsUids = points.map((e) => e.yval[1]);
    let metaStates = metaStatesAll.filter((e) => pointsUids.indexOf(e.uid) > -1);

    metaStates.forEach((e) => {
      let hasNull = false;
      let hasHalf = false;
      let hasFull = false;
      let st = -1;
      if (e.state.indexOf(1) > -1) {
        hasNull = true;
      }
      if (e.state.indexOf(97) > -1 || e.state.indexOf(98) > -1 || e.state.indexOf(99) > -1) {
        hasHalf = true;
      }
      if (e.state.indexOf(2) > -1) {
        hasFull = true;
      }
      if (hasNull && !hasHalf && !hasFull) {
        st = 0;
      }
      if (hasNull && hasHalf && !hasFull) {
        st = 1;
      }
      if (hasNull && !hasHalf && hasFull) {
        st = 2;
      }
      if ((!hasNull && hasHalf && !hasFull) || (!hasNull && hasHalf && hasFull)) {
        st = 3;
      }
      if (!hasNull && !hasHalf && hasFull) {
        st = 4;
      }
      e.st = st;
    });

    console.log('points str', JSON.stringify(points), e.seriesIndex, pointsUids, metaStates);

    let simTick = e.axis.ticks[e.seriesIndex];
    if (simTick.sim) {
      let hSimShift = PRIMARY_SIM_HEIGHT / 2;
      let yposSim = plotHeight - pr((pr(simTick.v - ymin) / pr(ymax - ymin)) * plotHeight);
      const patrShift = yposSim - hSimShift;
      ctx.fillStyle = ctx.createPattern(getSimPattern(Math.ceil(PRIMARY_SIM_HEIGHT), Math.floor(patrShift)), 'repeat');
      // ctx.strokeStyle = ctx.fillStyle;
      rect(
        ctx,
        e,
        plotLeftBoundary,
        Math.floor(yposSim - hSimShift),
        plotLeftBoundary + plotWidth,
        Math.floor(yposSim + hSimShift)
      );
    }

    for (var p = 0; p < points.length; p++) {
      console.log('points[p]', points[p].yval[0], points[p], states, e.setName);
      let uid = points[p].yval[1];

      let value = points[p].yval[0];
      // state = states[p][e.setName],
      let stateEl = states.find((s) => s.uid == uid);
      let metaEl = metaStates.find((s) => s.uid == uid);
      let ypos = plotHeight - pr((pr(value - ymin) / pr(ymax - ymin)) * plotHeight);
      console.log('yscale yval', ypos, ymin, value, yscale, points[p].yval);

      // if (stateEl === undefined) {
      //   continue;
      // }
      console.log('points stateEl', stateEl);
      let state = stateEl[e.setName],
        isSim = stateEl.sim,
        currentPointX = (points[p + 1] ? points[p + 1].x : 1) * plotWidth,
        previousPointX = points[p].x * plotWidth;
      if (previousPointX > currentPointX) {
        let sw = currentPointX;
        currentPointX = previousPointX;
        previousPointX = sw;
      }
      console.log('previousPointX', previousPointX, 'currentPointX', currentPointX, 'state', state);
      if (metaEl.st > 0) {
        ctx.globalCompositeOperation = 'destination-over';

        let hStateShift = PRIMARY_STATE_HEIGHT / 2;
        rect(
          ctx,
          e,
          previousPointX + plotLeftBoundary,
          Math.floor(ypos - hStateShift),
          currentPointX + plotLeftBoundary,
          Math.floor(ypos + hStateShift),
          { state: metaEl.st }
        );
        ctx.globalCompositeOperation = 'source-over';
      }

      // console.log('state sim', state, isSim);
      let regionWidth = currentPointX - previousPointX,
        quality,
        dataFillColor,
        testColor = null,
        isTest = false;

      // console.log(
      //   points[p + 1] ? points[p + 1].x : plotLeftBoundary + plotWidth,
      //   'x1:',
      //   plotLeftBoundary + previousPointX,
      //   'x2:',
      //   plotLeftBoundary + currentPointX,
      //   'w:',
      //   regionWidth,
      //   plotWidth
      // );

      if (applyQ) {
        // console.log('find', e.dygraph.attributes_.user_.quality.q.find((el) => el.t == points[p].xval));
        let qualityEl = qualities.q.find((el) => el.uid == uid);
        console.log('quality', isSim, uid, points[p].xval, qualityEl, e.dygraph.attributes_.user_.quality.q);
        quality = qualityEl.q;
        dataFillColor = getColorByValidity(quality);
        testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        isTest = quality.isTest;
      } else if (applySubQ) {
        let qualityEl = qualities.subQ.find((el) => el.t == points[p].xval && el.real !== isSim);
        console.log('sub quality', isSim, points[p].xval, qualityEl, e.dygraph.attributes_.user_.quality.subQ);
        quality = qualityEl.subQ;
        dataFillColor = getColorByValidity(quality);
        testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        isTest = quality.isTest;
      } else {
        dataFillColor = Q_UNKNOWN_COLOR;
      }

      ctx.fillStyle = dataFillColor;
      // console.log('dataFillColor', dataFillColor, quality, 'sim', isSim, points[p]);
      points[p]._color = dataFillColor;

      if (state == 1) {
        let hShift = Math.ceil(-booleanHeightTrue / 2);
        let hGrow = Math.ceil(booleanHeightTrue);

        // ctx.fillStyle = `green`
        console.log('r1', value, (plotHeight / size) * value, plotHeight, hShift, booleanHeightFalse, yscale);
        console.log(
          'rect1',
          previousPointX + plotLeftBoundary,
          value * plotHeight + hShift,
          regionWidth,
          booleanHeightFalse * yscale,
          dataFillColor,
          value
        );

        // ctx.fillRect(
        //   previousPointX + plotLeftBoundary,
        //   value * plotHeight + hShift,
        //   regionWidth,
        //   booleanHeightTrue * yscale
        // );
        rect(
          ctx,
          e,
          previousPointX + plotLeftBoundary,
          Math.floor(ypos - hShift),
          currentPointX + plotLeftBoundary,
          Math.floor(ypos + hShift),
          {
            test: isTest,
          }
        );

        ctx.strokeStyle = ctx.fillStyle;
        ctx.strokeRect(
          previousPointX + plotLeftBoundary,
          value * plotHeight + hShift,
          regionWidth,
          booleanHeightTrue * yscale
        );
      } else if (state == 0) {
        console.log(`yscale`, yscale, value);
        let hShift = Math.ceil(-booleanHeightFalse / 2);
        let hGrow = Math.ceil(booleanHeightFalse);
        // ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
        ctx.fillStyle = dataFillColor;
        ctx.strokeStyle = ctx.fillStyle;
        console.log('r0', value, plotHeight, hShift, booleanHeightFalse, yscale);
        console.log(
          'rect0',
          previousPointX + plotLeftBoundary,
          value * plotHeight + hShift,
          regionWidth,
          booleanHeightFalse * yscale,
          dataFillColor,
          value
        );

        rect(
          ctx,
          e,
          previousPointX + plotLeftBoundary,
          Math.floor(ypos - hShift),
          currentPointX + plotLeftBoundary,
          Math.floor(ypos + hShift),
          { test: isTest }
        );
        ctx.fillStyle = dataFillColor;
        ctx.strokeStyle = ctx.fillStyle;
      } else if (state === -1) {
        // let prevState = p >= 1 ? states[p - 1][e.setName] : null,
        console.log(
          'rect-1'
          // previousPointX + plotLeftBoundary,
          // value * plotHeight + hShift,
          // regionWidth,
          // booleanHeightFalse * yscale,
          // dataFillColor
        );

        let prevState = p >= 1 ? states[states.findIndex((s) => s.ts == points[p].xval) - 1][e.setName] : null,
          prevEventPosX = p >= 1 ? (points[p].x - points[p - 1].x) * plotWidth : 0;

        let gradient = ctx.createLinearGradient(
          previousPointX + plotLeftBoundary - prevEventPosX,
          value * plotHeight - booleanHeightTrue * yscale,
          previousPointX + plotLeftBoundary,
          value * plotHeight - booleanHeightTrue * yscale
        );
        // gradient.addColorStop(0, 'rgba(255,255,255,0.2)');
        // gradient.addColorStop(1, '#a52a2a');
        //
        // ctx.fillStyle = gradient;
        // ctx.fillRect(
        //   previousPointX + plotLeftBoundary - prevEventPosX + 1,
        //   value * plotHeight - booleanHeightTrue * yscale,
        //   prevEventPosX + 1,
        //   booleanHeightTrue * yscale * 2
        // );

        // ctx.fillStyle = '#a52a2a';
        // ctx.fillRect(
        //   previousPointX + plotLeftBoundary,
        //   value * plotHeight - booleanHeightTrue * yscale,
        //   regionWidth,
        //   booleanHeightTrue * yscale * 2
        // );

        ctx.strokeStyle = p >= 1 ? points[p - 1]._color : Q_UNKNOWN_COLOR;
        ctx.lineWidth = (prevState === 1 ? booleanHeightTrue : booleanHeightFalse) * yscale;

        ctx.beginPath();
        ctx.moveTo(previousPointX + plotLeftBoundary - prevEventPosX - 1, value * plotHeight);
        ctx.lineTo(previousPointX + plotLeftBoundary, value * plotHeight);
        ctx.stroke();

        // Рисуем пунктирную линию
        ctx.setLineDash([0, prevState === 1 ? 20 : 15, prevState === 1 ? 12 : 8]);
        // ctx.setLineDash([5,3,2,5,6,2,3,5,4,6,5,2,4]);
        ctx.beginPath();
        ctx.moveTo(previousPointX + plotLeftBoundary, value * plotHeight);
        ctx.lineTo(previousPointX + plotLeftBoundary + regionWidth, value * plotHeight);
        ctx.stroke();
        // ctx.scale(0, 0);
      }

      // Update Y value for point to draw points in correct places;
    }

    console.log('patterns', pattertCache);
  };

  DygraphTpui._dirPlotter = function tpui_dirPlotter(e) {
    let g = e.dygraph;
    let ctx = e.drawingContext;

    let setName = e.setName;
    let strokeWidth = e.strokeWidth;

    let borderWidth = g.getNumericOption('strokeBorderWidth', setName);
    let drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
    let strokePattern = g.getOption('strokePattern', setName);
    let drawPoints = g.getBooleanOption('drawPoints', setName);
    let pointSize = g.getNumericOption('pointSize', setName);

    let area = e.plotArea;

    let plotWidth = area.w;
    let plotHeight = area.h;
    let plotLeftBoundary = area.x;
    let plotMidHeight = plotHeight / 2;
    let yscale = e.axis.yscale;

    let points = e.points;
    let states = e.dygraph.user_attrs_._states;

    let below = false; // if true the direction shall be written below the timeline
    let booleanHeightTrue;
    let booleanHeightFalse;
    let pkpValueName;

    // Choose setName-specific parameters (eg. shifts for non-primary data etc.):

    switch (e.setName) {
      case 'dirGeneral':
        below = true;
        ctx.fillStyle = '#f9b2ff';
        booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
        pkpValueName = 'general';
        break;
      case 'dirPhsA':
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        ctx.fillStyle = '#ffeb87';
        pkpValueName = 'phsA';
        break;
      case 'dirPhsB':
        ctx.fillStyle = '#87ffab';
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        pkpValueName = 'phsB';
        break;
      case 'dirPhsC':
        ctx.fillStyle = '#ff8799';
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        pkpValueName = 'phsC';
        break;
      case 'dirNeut':
        ctx.fillStyle = '#e8e8e8';
        booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
        pkpValueName = 'neut';
        break;
      default:
        booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
        booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
        break;
    }

    let previousState;

    for (var p = 0; p < points.length; p++) {
      let currentValue = points[p].y;
      let currentState = states[p][e.setName];
      let currentPointX = points[p].x * plotWidth;
      let addXShift = p == 0 ? 5 : p == points.length - 1 ? -5 : 2; // Add shift if the point is most left or most right;

      let dirText;
      let drawText = true;
      if (currentState != previousState) {
        switch (currentState) {
          //[ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
          case 0: // UNKNOWN
            dirText = 'Unknown';
            break;
          case 1: // FORWARD
            dirText = 'Forward';
            break;
          case 2: // BACKWARD
            dirText = 'Backward';
            break;
          case 3: // BOTH
            dirText = 'Both';
            break;

          default:
            // UNDEFINED
            dirText = 'Undefined';
            drawText = false; // Do not draw direction data if it is not transmitted with GOOSE;
            break;
        }
        if (drawText) {
          if (p == points.length - 1) {
            // This is the last point of the plot, text must be righ-aligned to fit
            ctx.textAlign = 'right';
          } else {
            ctx.textAlign = 'left';
          }
          ctx.fillStyle = 'gray';
          ctx.font = '10px Arial';

          let belowMultiplyer = below ? -1 : 1;

          ctx.fillText(
            dirText,
            currentPointX + plotLeftBoundary + addXShift,
            currentValue * plotHeight - belowMultiplyer * ((booleanHeightTrue * yscale) / 2 + (below ? 12 : 2))
          ); //Math.max([booleanHeightTrue, booleanHeightFalse])
        }
      }

      previousState = currentState;
    }
  };

  DygraphTpui._horizontalPattern = function (color) {
    var canvasPattern = document.createElement('canvas');
    canvasPattern.width = 8;
    canvasPattern.height = 8;
    var ctxPattern = canvasPattern.getContext('2d');
    ctxPattern.fillStyle = '#80808080';
    ctxPattern.fillRect(0, 0, 8, 8);
    // ctxPattern.fill();
    ctxPattern.fillStyle = color;
    ctxPattern.fillRect(0, 2, 8, 4);
    // ctxPattern.rect(2, 2, 2, 2);
    // ctxPattern.fill();
    return canvasPattern;
  };

  DygraphTpui._VerticalDashPattern = function (color) {
    var canvasPattern = document.createElement('canvas');
    canvasPattern.width = 4;
    canvasPattern.height = 4;
    var ctxPattern = canvasPattern.getContext('2d');
    // ctxPattern.fillStyle = '#80808080';
    // ctxPattern.fillRect(0, 0, 8, 8);
    // // ctxPattern.fill();
    ctxPattern.fillStyle = color;
    ctxPattern.fillRect(0, 0, 4, 2);
    // ctxPattern.rect(2, 2, 2, 2);
    // ctxPattern.fill();
    return canvasPattern;
  };

  DygraphTpui._lineStepPlotter = function tpui_lineStepPlotter(e) {
    //console.log('_lineStepPlottere);
    let g = e.dygraph;
    let ctx = e.drawingContext;
    let applyQ = false,
      applySubQ = false;

    let setName = e.setName;
    let strokeWidth = e.strokeWidth;

    let borderWidth = g.getNumericOption('strokeBorderWidth', setName);
    let drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
    let strokePattern = g.getOption('strokePattern', setName);
    let drawPoints = g.getBooleanOption('drawPoints', setName);
    let pointSize = g.getNumericOption('pointSize', setName);

    let area = e.plotArea;

    let plotWidth = area.w;
    let plotHeight = area.h;
    let plotLeftBoundary = area.x;
    let plotMidHeight = plotHeight / 2;
    let yscale = e.axis.yscale;
    let ymin = e.axis.minyval;
    let ymax = e.axis.maxyval;
    let qualities = e.dygraph.attributes_.user_.quality;

    let pointsAll = e.points;
    let states = e.dygraph.user_attrs_._states;
    let meta = e.dygraph.user_attrs_.meta;

    let booleanHeightFalse;

    let lineWidth = PRIMARY_STEP_HEIGHT;

    switch (e.setName) {
      case 'stVal':
        applyQ = true;
        break;
      case 'subVal':
        // applySubQ = true;
        applyQ = true;

        break;
      default:
        applyQ = true;
        break;
    }

    let points = pointsAll.filter((el) => Array.isArray(el.yval));
    console.log('points 1', points);
    let metaStatesAll = meta.map((e) => {
      return { uid: e.uid, state: e.meta.transport.goose.map((g) => g.goose_alive_state) };
    });
    let pointsUids = points.map((e) => e.yval[1]);
    let metaStates = metaStatesAll.filter((e) => pointsUids.indexOf(e.uid) > -1);

    metaStates.forEach((e) => {
      let hasNull = false;
      let hasHalf = false;
      let hasFull = false;
      let st = -1;
      if (e.state.indexOf(1) > -1) {
        hasNull = true;
      }
      if (e.state.indexOf(97) > -1 || e.state.indexOf(98) > -1 || e.state.indexOf(99) > -1) {
        hasHalf = true;
      }
      if (e.state.indexOf(2) > -1) {
        hasFull = true;
      }
      if (hasNull && !hasHalf && !hasFull) {
        st = 0;
      }
      if (hasNull && hasHalf && !hasFull) {
        st = 1;
      }
      if (hasNull && !hasHalf && hasFull) {
        st = 2;
      }
      if ((!hasNull && hasHalf && !hasFull) || (!hasNull && hasHalf && hasFull)) {
        st = 3;
      }
      if (!hasNull && !hasHalf && hasFull) {
        st = 4;
      }
      e.st = st;
    });

    [false, true].forEach((p) => {
      let pasTicks = e.axis.ticks.filter((t) => t.sim === p);
      let serieName = e.setName.replace('real.', '').replace('sim.', '');
      if (serieName == 'stVal') {
        let ticksRange = pasTicks.map((e) => e.v);
        let ticksMax = Math.max(...ticksRange);
        let yposTicks = plotHeight - pr((pr(ticksMax - ymin) / pr(ymax - ymin)) * plotHeight);

        ctx.font =
          '11.9px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
        ctx.fillStyle = '#1d5bbe';
        ctx.fillText('(' + serieName + ')', 7, yposTicks - 25);
      }
    });

    let simTick = e.axis.ticks[e.seriesIndex];
    let isSimSerie = e.setName.includes('sim.stVal');

    let simTicks = e.axis.ticks.filter((t) => t.sim === isSimSerie);
    switch (3) {
      case 1:
        {
          // joined
          let ticksRange = simTicks.map((e) => e.v);
          let simMin = Math.min(...ticksRange);
          let simMax = Math.max(...ticksRange);
          let simMid = pr((simMax + simMin) / 2);
          let simRange = (pr(simMax - simMin) / pr(ymax - ymin)) * plotHeight;
          console.log('ticksRange', ticksRange, simMin, simMax, simMid);
          if (isSimSerie) {
            let hSimShift = pr((PRIMARY_SIM_HEIGHT + simRange) / 2);
            let yposSim = plotHeight - pr((pr(simMid - ymin) / pr(ymax - ymin)) * plotHeight);
            const patrShift = yposSim - hSimShift;
            console.log(
              'ticksRange size',
              hSimShift,
              yposSim,
              patrShift,
              Math.ceil(PRIMARY_SIM_HEIGHT + simRange),
              Math.floor(patrShift)
            );
            ctx.fillStyle = ctx.createPattern(
              getSimPattern(Math.ceil(PRIMARY_SIM_HEIGHT + simRange), Math.floor(patrShift)),
              'repeat'
            );
            // ctx.strokeStyle = ctx.fillStyle;
            rect(
              ctx,
              e,
              plotLeftBoundary,
              Math.floor(yposSim - hSimShift),
              plotLeftBoundary + plotWidth,
              Math.floor(yposSim + hSimShift)
            );
          }
        }
        break;
      case 2:
        {
          // separate
          simTicks.forEach((t) => {
            if (t.sim) {
              let hSimShift = PRIMARY_SIM_HEIGHT / 2;
              let yposSim = plotHeight - pr((pr(t.v - ymin) / pr(ymax - ymin)) * plotHeight);
              const patrShift = yposSim - hSimShift;
              ctx.fillStyle = ctx.createPattern(
                getSimPattern(Math.ceil(PRIMARY_SIM_HEIGHT), Math.floor(patrShift)),
                'repeat'
              );
              // ctx.strokeStyle = ctx.fillStyle;
              rect(
                ctx,
                e,
                plotLeftBoundary,
                Math.floor(yposSim - hSimShift),
                plotLeftBoundary + plotWidth,
                Math.floor(yposSim + hSimShift)
              );
            }
          });
        }

        break;
      case 3:
        {
          // joined 2
          let ticksRange = simTicks.map((e) => e.v);
          let simMin = Math.min(...ticksRange);
          let simMax = Math.max(...ticksRange);
          let simMid = pr((simMax + simMin) / 2);
          let simRange = (pr(simMax - simMin) / pr(ymax - ymin)) * plotHeight;
          console.log('ticksRange', ticksRange, simMin, simMax, simMid);
          if (isSimSerie) {
            let hSimShift = pr((PRIMARY_SIM_HEIGHT + simRange) / 2);
            let yposSim = plotHeight - pr((pr(simMid - ymin) / pr(ymax - ymin)) * plotHeight);
            const patrShift = yposSim - hSimShift;
            console.log(
              'ticksRange size',
              hSimShift,
              yposSim,
              patrShift,
              Math.ceil(PRIMARY_SIM_HEIGHT + simRange),
              Math.floor(patrShift)
            );
            ctx.fillStyle = ctx.createPattern(
              getSimPattern(Math.ceil(PRIMARY_SIM_HEIGHT), Math.floor(patrShift), { v2: true }),
              'repeat'
            );
            // ctx.strokeStyle = ctx.fillStyle;
            rect(
              ctx,
              e,
              plotLeftBoundary,
              Math.floor(yposSim - hSimShift),
              plotLeftBoundary + plotWidth,
              Math.floor(yposSim + hSimShift)
            );
          }
        }

        break;
    }
    console.log('simTick', simTick, e, e.setName, simTicks);

    //ctx.fillStyle = "#777"
    for (var p = 1; p < points.length; p++) {
      let uid = points[p].yval[1];

      let value = points[p - 1].yval[0];

      let currentValue = points[p].yval[0];
      // let state = states[p - 1][e.setName];
      let stateEl = states.find((s) => s.uid == uid);
      let metaEl = metaStates.find((s) => s.uid == uid);
      let ypos = plotHeight - pr((pr(value - ymin) / pr(ymax - ymin)) * plotHeight);

      // if (stateEl === undefined) {
      //   continue;
      // }
      // console.log('stateEl', stateEl);
      let state = stateEl[e.setName],
        isSim = stateEl.sim;

      let currentPointX = points[p].x * plotWidth;
      let previousPointX = points[p - 1].x * plotWidth;
      let regionWidth = currentPointX - previousPointX;

      let hShift = Math.ceil(-lineWidth / 2);

      let quality,
        dataFillColor,
        testColor = null,
        isTest = false;

      if (applyQ) {
        // quality = e.dygraph.attributes_.user_.quality.q[p - 1];
        let qualityEl = qualities.q.find((el) => el.uid == uid);
        console.log('quality', isSim, points[p].xval, qualityEl, qualities.q);
        quality = qualityEl.q;

        dataFillColor = getColorByValidity(quality);
        testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        isTest = quality.isTest;
      } else if (applySubQ) {
        // quality = e.dygraph.attributes_.user_.quality.subQ[p - 1];
        let qualityEl = qualities.subQ.find((el) => el.uid == uid);
        console.log('sub quality', isSim, points[p].xval, qualityEl, qualities.subQ);
        quality = qualityEl.subQ;

        dataFillColor = getColorByValidity(quality);
        testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        isTest = quality.isTest;
      } else {
        dataFillColor = Q_UNKNOWN_COLOR;
      }

      //console.log(e.setName, ctx.fillStyle, value)
      ctx.fillStyle = dataFillColor;
      rect(
        ctx,
        e,
        plotLeftBoundary + previousPointX,
        Math.floor(ypos - hShift),
        plotLeftBoundary + currentPointX,
        Math.floor(ypos + hShift),
        {
          test: isTest,
        }
      );

      // ctx.fillRect(
      //   previousPointX + plotLeftBoundary, value * plotHeight + hShift,
      //   regionWidth, lineWidth * yscale);
      // if (currentValue != value) {
      // Draw additional part of line from previous to current:
      //if (testColor) {
      //    ctx.fillStyle = testColor;
      //    let testWidth = 4 * lineWidth;
      //    let testFillXShift = testWidth * yscale / 2;
      //    ctx.fillRect(currentPointX + plotLeftBoundary - testFillShift, value * plotHeight - shiftSighn * testFillShift, testWidth * yscale, testWidth + shiftSighn * 2 *testFillShift);
      //}
      // ctx.fillStyle = 'red';//dataFillColor;

      var canvasPattern = DygraphTpui._VerticalDashPattern(dataFillColor);
      var pattern = ctx.createPattern(canvasPattern, 'repeat');
      ctx.fillStyle = pattern;

      let xShift = (lineWidth * yscale) / 2;
      let valuePrev = points[p].yval[0];

      let yposPrev = plotHeight - pr((pr(valuePrev - ymin) / pr(ymax - ymin)) * plotHeight);

      let shiftSign = valuePrev - value < 0 ? 1 : -1;
      let connectShift = hShift;

      if (valuePrev != value) {
        rect(
          ctx,
          e,
          plotLeftBoundary + currentPointX,
          Math.floor(ypos + shiftSign * connectShift),
          plotLeftBoundary + currentPointX + 1,
          Math.floor(yposPrev + shiftSign * connectShift)
        );
      }
      // }

      // Update Y value for point to draw points in correct places;
    }
  };

  Dygraph.Plotters.lineBoolPlotter = DygraphTpui._lineBoolPlotter;
  Dygraph.Plotters.lineStepPlotter = DygraphTpui._lineStepPlotter;
  Dygraph.Plotters.spsPlotter = DygraphTpui._spsPlotter;
  Dygraph.Plotters.dirPlotter = DygraphTpui._dirPlotter;

  Dygraph._supportedCdcs = ['ACT', 'ACD', 'SPS', 'SPC', 'DPC', 'DPS'];

  /**
   * https://github.com/danvk/dygraphs/blob/master/src/extras/smooth-plotter.js
   * Given three sequential points, p0, p1 and p2, find the left and right
   * control points for p1.
   *
   * The three points are expected to have x and y properties.
   *
   * The alpha parameter controls the amount of smoothing.
   * If α=0, then both control points will be the same as p1 (i.e. no smoothing).
   *
   * Returns [l1x, l1y, r1x, r1y]
   *
   * It's guaranteed that the line from (l1x, l1y)-(r1x, r1y) passes through p1.
   * Unless allowFalseExtrema is set, then it's also guaranteed that:
   *   l1y ∈ [p0.y, p1.y]
   *   r1y ∈ [p1.y, p2.y]
   *
   * The basic algorithm is:
   * 1. Put the control points l1 and r1 α of the way down (p0, p1) and (p1, p2).
   * 2. Shift l1 and r2 so that the line l1–r1 passes through p1
   * 3. Adjust to prevent false extrema while keeping p1 on the l1–r1 line.
   *
   * This is loosely based on the HighCharts algorithm.
   */
  function getControlPoints(p0, p1, p2, opt_alpha, opt_allowFalseExtrema) {
    var alpha = opt_alpha !== undefined ? opt_alpha : 1 / 3; // 0=no smoothing, 1=crazy smoothing
    var allowFalseExtrema = opt_allowFalseExtrema || false;

    if (!p2) {
      return [p1.x, p1.y, null, null];
    }

    // Step 1: Position the control points along each line segment.
    var l1x = (1 - alpha) * p1.x + alpha * p0.x,
      l1y = (1 - alpha) * p1.y + alpha * p0.y,
      r1x = (1 - alpha) * p1.x + alpha * p2.x,
      r1y = (1 - alpha) * p1.y + alpha * p2.y;

    // Step 2: shift the points up so that p1 is on the l1–r1 line.
    if (l1x != r1x) {
      // This can be derived w/ some basic algebra.
      var deltaY = p1.y - r1y - ((p1.x - r1x) * (l1y - r1y)) / (l1x - r1x);
      l1y += deltaY;
      r1y += deltaY;
    }

    // Step 3: correct to avoid false extrema.
    if (!allowFalseExtrema) {
      if (l1y > p0.y && l1y > p1.y) {
        l1y = Math.max(p0.y, p1.y);
        r1y = 2 * p1.y - l1y;
      } else if (l1y < p0.y && l1y < p1.y) {
        l1y = Math.min(p0.y, p1.y);
        r1y = 2 * p1.y - l1y;
      }

      if (r1y > p1.y && r1y > p2.y) {
        r1y = Math.max(p1.y, p2.y);
        l1y = 2 * p1.y - r1y;
      } else if (r1y < p1.y && r1y < p2.y) {
        r1y = Math.min(p1.y, p2.y);
        l1y = 2 * p1.y - r1y;
      }
    }

    return [l1x, l1y, r1x, r1y];
  }

  // i.e. is none of (null, undefined, NaN)
  function isOK(x) {
    return !!x && !isNaN(x);
  }

  // A plotter which uses splines to create a smooth curve.
  // See tests/plotters.html for a demo.
  // Can be controlled via smoothPlotter.smoothing
  function smoothPlotter(e) {
    var ctx = e.drawingContext,
      points = e.points;

    points.sort(function (a, b) {
      if (a.xval > b.xval) {
        return -1;
      }
      if (a.xval < b.xval) {
        return 1;
      }
      return 0;
    });

    ctx.beginPath();
    ctx.moveTo(points[0].canvasx, points[0].canvasy);

    // right control point for previous point
    var lastRightX = points[0].canvasx,
      lastRightY = points[0].canvasy;

    for (var i = 1; i < points.length; i++) {
      var p0 = points[i - 1],
        p1 = points[i],
        p2 = points[i + 1];
      p0 = p0 && isOK(p0.canvasy) ? p0 : null;
      p1 = p1 && isOK(p1.canvasy) ? p1 : null;
      p2 = p2 && isOK(p2.canvasy) ? p2 : null;
      if (p0 && p1) {
        var controls = getControlPoints(
          { x: p0.canvasx, y: p0.canvasy },
          { x: p1.canvasx, y: p1.canvasy },
          p2 && { x: p2.canvasx, y: p2.canvasy },
          smoothPlotter.smoothing
        );
        // Uncomment to show the control points:
        // ctx.lineTo(lastRightX, lastRightY);
        // ctx.lineTo(controls[0], controls[1]);
        // ctx.lineTo(p1.canvasx, p1.canvasy);
        lastRightX = lastRightX !== null ? lastRightX : p0.canvasx;
        lastRightY = lastRightY !== null ? lastRightY : p0.canvasy;
        ctx.bezierCurveTo(lastRightX, lastRightY, controls[0], controls[1], p1.canvasx, p1.canvasy);
        lastRightX = controls[2];
        lastRightY = controls[3];
      } else if (p1) {
        // We're starting again after a missing point.
        ctx.moveTo(p1.canvasx, p1.canvasy);
        lastRightX = p1.canvasx;
        lastRightY = p1.canvasy;
      } else {
        lastRightX = lastRightY = null;
      }
    }

    ctx.stroke();
  }
  smoothPlotter.smoothing = 1 / 3;
  smoothPlotter._getControlPoints = getControlPoints; // for testing

  // older versions exported a global.
  // This will be removed in the future.
  // The preferred way to access smoothPlotter is via Dygraph.smoothPlotter.
  DygraphTpui.smoothPlotter = smoothPlotter;
})();

export default DygraphTpui;
