<template>
  <div
    class="flex flex-col items-start justify-center w-64 border-2 border-gray-400 p-8 rounded-lg"
  >
    <t-checkbox
      v-for="option in options"
      :checked="value.includes(option.id)"
      @update:checked="check(option.id, $event)"
      :fieldId="option.name"
      :label="option.name"
      :key="option"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import TCheckbox from "@/components/ui/TCheckbox.vue";

export default {
  name: 't-checkbox-multi',
  emits: ["update:value"],
  props: {
    value: {
      type: Array,
      required: true,
    },
    disabled:{
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        const hasNameKey = value.every((option) =>
          Object.keys(option).includes("name")
        );
        const hasIdKey = value.every((option) =>
          Object.keys(option).includes("id")
        );
        return hasNameKey && hasIdKey;
      },
    },
  },
  setup(props, context) {
    const check = (optionId, checked) => {
      let updatedValue = [...props.value];
      if (checked) {
        updatedValue.push(optionId);
      } else {
        updatedValue.splice(updatedValue.indexOf(optionId), 1);
      }
      context.emit("update:value", updatedValue);
    };

    return {
      check,
    };
  },
  components: {
    "t-checkbox": TCheckbox,
  },
};
</script>

<style scoped>
.flex{display:flex;}
.flex-col{flex-direction:column;}
.items-start{align-items:flex-start;}
.justify-center{justify-content:center;}

</style>
