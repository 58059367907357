<template>
  <div class="page-toolbar m-b-12">
    <div class="page-toolbar__title"><div>Профиль события</div></div>
    <div class="spacer-grow"></div>
    <div class="page-toolbar__actions">
      <div class="buttons-panel">
        <div class="ui-button ui-button--active">
          <div class="ui-button__icon--no-label">
            <img src="/images/ruler-white.svg" loading="lazy" alt="" />
          </div>
        </div>

        <el-popover placement="bottom" width="480" trigger="click">
          <div class="flex-wide-top flex-wide-top--padding-24p">
            <div class="popup-dialog__header">
              <div class="popup-dialog__title"><div>внешний вид профиля события</div></div>
              <div class="popup-dialog__close-btn hide">
                <img src="/images/remove.svg" loading="lazy" alt="" />
              </div>
            </div>
            <div class="popup-dialog__body">
              <div class="cluster-view-settings">
                <div class="ui-label"><div>Наименование</div></div>
                <div class="ui-input opacity-50"><div>Введите наименование</div></div>
                <div class="ui-checkbox">
                  <div class="ui-checkbox__control">
                    <div class="ui-checkbox__checkbox">
                      <img src="/images/checkbox-checked.svg" loading="lazy" alt="" />
                    </div>
                    <div class="ui-checkbox__text"><div>Сделать глобальным</div></div>
                  </div>
                  <div class="ui-checkbox__desc"><div>Некое описание для глобального присета</div></div>
                </div>
              </div>
            </div>
            <div class="popup-dialog__actions">
              <div class="modal-actions">
                <div class="modal-actions__col">
                  <div class="ui-button min-w-120">
                    <div class="ui-button__lable"><div>Отменить</div></div>
                  </div>
                </div>
                <div class="modal-actions__col">
                  <div class="ui-button ui-button--active min-w-120">
                    <div class="ui-button__lable ui-button__lable--active"><div>Сохранить</div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #reference>
            <div class="ui-button m-l-4">
              <div class="ui-button__icon--no-label">
                <img src="/images/plus.svg" loading="lazy" alt="" />
              </div>
            </div>
          </template>
        </el-popover>

        <div class="ui-button m-l-4">
          <div class="ui-button__icon--no-label">
            <img src="/images/save.svg" loading="lazy" alt="" />
          </div>
        </div>
        <div class="ui-button m-l-4">
          <div class="ui-button__icon--no-label">
            <img src="/images/refresh.svg" loading="lazy" alt="" />
          </div>
        </div>
        <div class="ui-button m-l-4">
          <div class="ui-button__icon">
            <img src="/images/events.svg" loading="lazy" alt="" />
          </div>
          <div class="ui-button__lable"><div>Все события</div></div>
        </div>
        <div class="ui-button m-l-4">
          <div class="ui-button__icon">
            <img src="/images/inport.svg" loading="lazy" alt="" />
          </div>
          <div class="ui-button__lable"><div>Загрузить PCAPs</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-toolbar',
};
</script>

<style scoped></style>
