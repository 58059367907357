export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showDialog() {
      this.$emit('update:visible', true);
    },
    hideDialog() {
      this.$emit('update:visible', false);
    },
  },
  mounted() {
    dev.log('mixin mounted');
  },
  // ...
};
