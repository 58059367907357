<script setup>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { useTranslation } from 'i18next-vue';
import { useVisual } from '@/stores/visual';
import { useUsers } from '@/stores/users';
import api from '@/services/api';
import { ref, watch, computed } from 'vue';
import eventBus from '@/utils/eventBus';

const props = defineProps({
  visibleModal: {
    type: String,
    default: () => {
      return false;
    },
  },
  rowUser: {
    type: Object,
    // default: () => {
    //   return {}
    // },
  },
});

const { i18next, t } = useTranslation();
const visual = useVisual();
const users = useUsers();

const formRef = ref(null);
const formValue = ref({
  id: '',
  password: '',
  reenteredPassword: '',
});

const modal = ref({
  closeBtn: 'Close',
  noSubmit: true,
  size: '600',
  noTitle: false,
  submitBtn: 'Create',
  typeSubmit: true,
  name: 'change-password',
  title: 'Change user password',
});

const savePassword = () => {
  formRef.value
    ?.validate(async (errors) => {
      if (!errors) {
        const resp = await api.post(`/users/admin-edit`, {
          id: formValue.value.id,
          password: formValue.value.password,
          password2: formValue.value.reenteredPassword,
        });
        dev.log('ch pass', resp);
        if (resp.status) {
          eventBus.dispatch('message', {
            message: t('User data was successfully changed'),
            close: true,
            type: 'success',
          });

          users.loadUsers();
          users.hideChangePasswordModal();
        } else {
          eventBus.dispatch('message', {
            message: t('Unable to change user data'),
            close: true,
            type: 'warning',
          });
        }
      } else {
        dev.log('form not valid', errors);
      }
    })
    .catch((err) => {});
};

const closeModal = () => {
  dev.log('closeModal');
  users.hideChangePasswordModal();
};

watch(
  () => props.rowUser,
  (val) => {
    if (val) {
      formValue.value = {
        id: props.rowUser._id,
        password: '',
        reenteredPassword: '',
      };
    }
  },
  { deep: true }
);

const validatePasswordStartWith = (rule, value) => {
  return (
    !!formValue.value.password &&
    formValue.value.password.startsWith(value) &&
    formValue.value.password.length >= value.length
  );
};
const validatePasswordSame = (rule, value) => {
  return value === formValue.value.password;
};

const rules = computed(() => {
  return {
    password: [
      {
        required: true,
        message: t('Password is required'),
      },
    ],
    reenteredPassword: [
      {
        required: true,
        message: t('Re-entered password is required'),
        trigger: ['input', 'blur'],
      },
      {
        validator: validatePasswordStartWith,
        message: t('Password is not same as re-entered password!'),
        trigger: 'input',
      },
      {
        validator: validatePasswordSame,
        message: t('Password is not same as re-entered password!'),
        trigger: ['blur', 'password-input'],
      },
    ],
  };
});
</script>

<template>
  <modal-dialog
    :modal="modal"
    v-show="users.visibleChangePasswordModal"
    v-model:visible="users.visibleChangePasswordModal"
    ref="modEx"
    @closeModal="closeModal"
  >
    <!--    <template #title>-->
    <!--      <span class="lvb-description-modal__title"></span>-->
    <!--    </template>-->
    <template #content>
      <n-form
        ref="formRef"
        :model="formValue"
        :rules="rules"
        label-placement="top"
        id="signin_form"
        require-mark-placement="right-hanging"
        label-width="auto"
      >
        <input v-model="token" name="token" type="token" hidden :readonly="true" id="token" class="form-control" />

        <n-form-item :label="$t('Password')" path="password">
          <n-input
            name="password"
            type="password"
            v-model:value="formValue.password"
            show-password-on="click"
            :placeholder="$t('Password')"
          />
        </n-form-item>
        <n-form-item :label="$t('Confirm password')" path="reenteredPassword">
          <n-input
            name="reenteredPassword"
            type="password"
            v-model:value="formValue.reenteredPassword"
            :disabled="!formValue.password"
            show-password-on="click"
            :placeholder="$t('Confirm password')"
          />
        </n-form-item>
      </n-form>
    </template>
    <template #buttons>
      <button class="btn btn-sec" type="button" @click="closeModal">
        {{ $t('Cancel') }}
      </button>
      <button class="btn btn-pri" type="button" @click="savePassword">
        {{ $t('Save') }}
      </button>
    </template>
  </modal-dialog>
</template>

<style lang="scss"></style>
<style lang="scss"></style>
