<template>
  <div class="time-line-card">
    <div v-if="item.version.length > 1">
      <h5 class="time-line-card__title">
        Версия {{ item.version[0] }} - Версия {{ item.version[item.version.length - 1] }}
      </h5>
      <div :class="{ test: item.version.length > 7 }" v-if="item.version" @wheel.stop>
        <ul style="max-height: 150px; width: 185px">
          <li v-for="version in item.version" :key="version" @click="testLi(version)" class="version">
            <div class="version__head">
              <div class="version__circules"></div>
              <div class="version__line"></div>
            </div>
            <div class="version__text">Версия {{ version }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="one-version" v-else>
      <h5 class="time-line-card__title" :class="{ 'time-line-card__title_select': true }">
        <span style="margin-right: 5px">
          <v-svg src="viewed" fill="white"></v-svg>
        </span>
        <span style="margin-right: 5px">
          <v-svg src="active" fill="white"></v-svg>
        </span>
        Версия {{ item.version[0] }}
      </h5>
      <div class="version__circules"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  components: {},
  mounted() {
    dev.log('card mounted', this.item);
  },
  methods: {
    testLi(version) {
      dev.log('testName', version);
    },
  },
};
</script>

<style lang="scss">
.version {
  position: relative;
  display: flex;
  align-items: baseline;
  margin-left: calc(50% - 6px);
  margin-bottom: 0;
  &__circules {
    width: 12px;
    height: 12px;
    background-color: #c5d5ef;
    border-radius: 100%;
    position: relative;
  }
  &__line {
    height: 16px;
    width: 2px;
    background-color: #c5d5ef;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__text {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-left: 7px;
  }
}
.one-version {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 185px;
}
.vis-item.vis-box.transparent.vis-selected.vis-readonly {
  .version__circules {
    background-color: #1757bd;
  }
  .version__line {
    background-color: #1757bd;
  }
}
</style>

<style scoped>
.time-line-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.time-line-card__title {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #1757bd;
  opacity: 0.8;
}

.time-line-card__title_select {
  background-color: #009688;
  border-radius: 2px;
  color: #fff;
  padding: 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test {
  position: relative;
}

ul {
  padding: 0;
  margin: 0;
  overflow: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
}

li {
  list-style-type: none;
}

.test:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  width: 100%;
  height: 20px;
}

.test::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  width: 100%;
  height: 20px;
}

.test > ul:hover {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
}
.test > ul::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.test > ul::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
