// defineCustomElementWithStyles.js
import {
  defineCustomElement as VueDefineCustomElement,
  h,
  createApp,
  getCurrentInstance,
} from 'vue/dist/vue.esm-bundler';

import api from '@/services/api';
import { useShared } from '@/stores/shared';
import { useDygraphTpui } from '@/stores/dygraphTpui';
import Dygraph from '@/../../vendor/dygraph';
import JsonTreeCe from '@/components/common/JsonTree.ce.vue';
import i18next from 'i18next';
import main from '../root.app';
import { useLicense } from '@/stores/license';
import { mapStores } from 'pinia';
import { Zone, DateTime } from 'luxon';

const nearestElement = (el) => {
  while (el?.nodeType !== 1 /* ELEMENT */) el = el.parentElement;
  // console.log('nearestElement', el);
  return el;
};

export const defineCustomElement = (component, { globalComponents = {}, plugins = [], options = [] }) =>
  VueDefineCustomElement({
    render: () => h(component),
    setup() {
      const app = createApp();
      const shared = useShared();

      // install plugins
      plugins.forEach((plug) => {
        // console.log('plug', plug);
        if (plug.plug) {
          dev.log('plug', plug.plug, plug.opt);
          app.use(plug.plug, plug.opt);
        } else {
          app.use(plug);
        }
      });

      const inst = getCurrentInstance();

      app.mixin({
        mounted() {
          this.$router.isReady().then(() => {
            this.$nextTick(() => {
              // console.log('this m', this);
              this.$shadowStyle(this);
            });
          });
          const insertStyles = (styles) => {
            if (styles?.length) {
              this.__style = document.createElement('style');
              this.__style.innerText = styles.join().replace(/\n/g, '');
              // console.log('this.__style', styles, this.__style);
              nearestElement(this.$el).prepend(this.__style);
            }
          };

          insertStyles(this.$?.type.styles);
          if (this.$options.components) {
            for (const comp of Object.values(this.$options.components)) {
              insertStyles(comp.styles);
            }
          }
        },
        unmounted() {
          this.__style?.remove();
        },
      });

      Object.assign(inst.appContext, app._context);
      Object.assign(inst.provides, app._context.provides);
      let context = { shared, api, inst };
      if (options.includes('useLicense')){
        context = { ...context, useLicense: useLicense };
      }
      if (options.includes('mapStores')){
        context = { ...context, mapStores: mapStores };
      }
      if (options.includes('DateTime')){
        context = { ...context, DateTime: DateTime };
      }
      if (options.includes('Zone')){
        context = { ...context, Zone: Zone };
      }
      if (options.includes('needDygraph')) {
        context = { ...context, Dygraph: Dygraph };
      }
      if (options.includes('needDygraphTpui')) {
        const dygraphTpui = useDygraphTpui();
        context = { ...context, dygraphTpui: dygraphTpui };
      }
      if (options.includes('needJsonTree')) {
        app.component('json-tree', JsonTreeCe);
        dev.log('JsonTreeCe', JsonTreeCe.styles);
      }
      if (options.includes('needI18next')){
        Object.defineProperty(VueDefineCustomElement.prototype, '$i18next', { value: i18next })
      }

      dev.log('context', context);
      return context;
    },
    // setup() {
    //   const app = createApp();
    //
    //   Object.entries(globalComponents).forEach(([name, comp]) => app.component(name, comp));
    //
    //   app.mixin({
    //     mounted() {
    //       const insertStyles = (styles) => {
    //         if (styles?.length) {
    //           this.__style = document.createElement('style');
    //           this.__style.innerText = styles.join().replace(/\n/g, '');
    //           console.log('this.__style', styles, this.__style);
    //           nearestElement(this.$el).prepend(this.__style);
    //         }
    //       };
    //
    //       insertStyles(this.$?.type.styles);
    //       if (this.$options.components) {
    //         for (const comp of Object.values(this.$options.components)) {
    //           insertStyles(comp.styles);
    //         }
    //       }
    //     },
    //     unmounted() {
    //       this.__style?.remove();
    //     },
    //   });
    //
    //   plugins.forEach(app.use);
    //
    //   const inst = getCurrentInstance();
    //   Object.assign(inst.appContext, app._context);
    //   return () => h(component);
    // },
  });
