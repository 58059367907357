<template>
  <div class="data-table" ref="pageRoot">
    <teleport to="#modals-target">
      <archive-events-modal v-model:visibleModal="visibleArchiveDialog"></archive-events-modal>
      <!--    <export-events-modal></export-events-modal>-->
      <export-modal
        v-model:visibleModal="visibleExportDialog"
        @update:visibleModal="updateVisibleExportDialog"
        @doExport="doExport"
        object="events"
        :sorting="true"
      ></export-modal>
    </teleport>

    <div style="display: none" ref="print-block">
      <table class="data-table display" :class="className" ref="print-tab">
        <thead>
          <tr role="row">
            <th v-for="f in fields" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
              {{ $t(f.label) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in printRowsData"
            :row-data="rowData"
            :fields="fields"
            :key="rowData._id"
            :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
            @select="selectRow(idx)"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ $t(f.label) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="data-table-buttons events-top-controllers mt-4">
      <n-space>
        <n-button-group>
          <template v-for="b in buttons">
            <n-button
              strong
              secondary
              type="primary"
              :class="[
                'data-table-' + b.name,
                {
                  disabled:
                    (b.name === 'events-export' && exportDisabled) || (!enableView && b.name === 'view-selected'),
                },
              ]"
              @click="execAction(b, $event)"
              :disabled="(b.name === 'events-export' && exportDisabled) || (!enableView && b.name === 'view-selected')"
            >
              {{ $t(b.label) }}
            </n-button>
          </template>
        </n-button-group>
      </n-space>
      <div v-if="false" class="dt-buttons btn-group">
        <template v-for="b in buttons">
          <div class="btn-group" v-if="b.children">
            <button
              class="btn btn-secondary dropdown-toggle"
              :class="'data-table-' + b.name"
              :id="'submenu-' + b.name"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="b.name === 'events-export' && exportDisabled"
            >
              {{ $t(b.label) }}
            </button>

            <div class="dropdown-menu" :aria-labelledby="'submenu-' + b.name">
              <div class="dt-button-collection-title"></div>
              <template v-for="cb in b.children">
                <a
                  class="dt-button dropdown-item buttons-pdf buttons-html5"
                  tabindex="0"
                  @click="execAction(cb, $event)"
                  ><span>{{ $t(cb.label) }}</span></a
                >
              </template>
            </div>
          </div>
          <button
            class="btn btn-secondary"
            :class="[
              'data-table-' + b.name,
              {
                disabled: (b.name === 'events-export' && exportDisabled) || (!enableView && b.name === 'view-selected'),
              },
            ]"
            @click="execAction(b, $event)"
            :disabled="(b.name === 'events-export' && exportDisabled) || (!enableView && b.name === 'view-selected')"
            v-else
          >
            {{ $t(b.label) }}
          </button>
        </template>
      </div>
      <div class="events-status-block">
        <div v-if="false && hasNotCachedEvents" class="events-status">
          <n-popconfirm
            :negative-text="$t('Close')"
            :positive-text="$t('Refresh')"
            @positive-click="handleEventsStatusRefresh('hasNewNotCachedEvents')"
          >
            <template #trigger>
              <div class="events-status__short" style="color:#f2711c;">
                <v-icon size="24" class="ml-1 cursor-pointer">
                  <v-svg fill="#f2711c" width="18" height="18" src="info"></v-svg> </v-icon
                >&nbsp; {{ $t('New events') }}
              </div>
            </template>
            <div class="events-status__text">
              {{ $t('There are new events not displayed in the list.') }}
            </div>
          </n-popconfirm>
        </div>
        <div v-if="visual.devMode" style="display: flex">
          <n-input v-model:value="resetId" type="text" style="max-width: 120px"/>
          <n-button @click="resetTpls">reset</n-button>
          <n-button @click="outRangeTpls">out</n-button>
        </div>
        <transition name="fade">
          <div v-if="cacheSilentUpdate" class="events-status">
            <div class="events-status__short" style="color:#f2711c; padding: 4px 10px; border: 1px solid; border-radius: var(--el-border-radius-base);">
              <v-icon size="24" class="ml-1"> <v-svg fill="#f2711c" width="18" height="18" src="info"></v-svg> </v-icon
              >&nbsp; {{ $t('New events') }}&nbsp;<span class="reload-lnk" @click="handleEventsUpdate">{{
                $t('Reload?')
              }}</span>
            </div>
          </div>
        </transition>
        <div class="events-cache-progress-holder" v-if="showEventsCacheProgress || showEventsCacheStoreProgress">
        <transition name="fade">
          <div v-if="showEventsCacheProgress" class="events-cache-progress" key="cache-progress">
            <span>{{ $t('Events cache procecssing...') }}</span>
            <div class="events-cache-progress-bar">
              <n-progress type="line" :percentage="events.cacheProgress" />
            </div>
          </div>
          <div v-else-if="showEventsCacheStoreProgress" class="events-cache-progress" key="store-progress">
            <span>{{ $t('Events cache saving...') }}</span>
            <div class="events-cache-progress-bar">
              <n-progress type="line" :percentage="events.storeProgress" color="#18a058" />
            </div>
          </div>
        </transition>
        </div>

        <div v-if="false" class="events-status">
          <n-popconfirm
            :negative-text="$t('Close')"
            :positive-text="$t('Refresh')"
            @positive-click="handleEventsStatusRefresh('hasNotCachedEvents')"
          >
            <template #trigger>
              <div class="events-status__short">
                <v-icon size="24" class="ml-1 cursor-pointer">
                  <v-svg fill="#1757bd" width="18" height="18" src="info"></v-svg> </v-icon
                >&nbsp; {{ $t('New events') }}
              </div>
            </template>
            <div class="events-status__text">
              {{ $t('There are updated events not displayed in the list.') }}
            </div>
          </n-popconfirm>
        </div>
      </div>
      <div v-if="false && !noSearch" id="vue_events_table_filter" class="dataTables_filter">
        <label
          >Поиск:<input
            v-model="searchString"
            type="search"
            class="form-control form-control-sm"
            placeholder=""
            aria-controls="wheel_events_table"
        /></label>
      </div>
      <div>
        <n-button
          type="primary"
          :ghost="!visual.eventsSearchFiltersVisible"
          :quaternary="!visual.eventsSearchFiltersVisible"
          :secondary="visual.eventsSearchFiltersVisible"
          @click="visual.toggleEventsFilter"
        >
          <template #icon>
            <v-svg src="search" fill="#1757BD"></v-svg>
          </template>
        </n-button>
        <!--        <button id="filter_btn" class="btn ml-2" type="button" @click="visual.toggleEventsFilter">-->
        <!--                <span class="filter-btn-text">-->
        <!--                  <v-svg src="search" fill="#1757BD"></v-svg>-->
        <!--                </span>-->
        <!--        </button>-->
      </div>
    </div>
    <div class="event-types-block">
      <span v-for="t in eventTypes" class="text-secondary mr-md-4 btn event-type" @click="switchType(t)" :key="t.name">
        <span :class="'fa-' + (onTypes.indexOf(t.name) < 0 ? '' : 'check-') + 'square fal'"></span
        ><span class="type-label">&nbsp;{{ $t('type^' + t.name) }}</span>
      </span>
    </div>
    <div v-if="visual.eventsSearchFiltersVisible" class="events-filters-block">
      <div class="filter-field-wrapper" :style="{ width: widths['timeDT'] + 'px' }" ref="timeDTwrapper">
        <n-input v-model:value="searchFilters.timeDT" type="text" :placeholder="$t('Time')" />
      </div>
      <div class="filter-field-wrapper" :style="{ width: widths['typeDT'] + 'px' }" ref="iedNameDTwrapper"></div>
      <div class="filter-field-wrapper" :style="{ width: widths['iedNameDT'] + 'px' }" ref="iedNameDTwrapper">
        <n-input v-model:value="searchFilters.iedNameDT" type="text" :placeholder="$t('IED')" />
      </div>
      <div class="filter-field-wrapper" :style="{ width: widths['controlBlockDT'] + 'px' }" ref="controlBlockDTwrapper">
        <n-input v-model:value="searchFilters.controlBlockDT" type="text" :placeholder="$t('Control block')" />
      </div>
      <div class="filter-field-wrapper" :style="{ width: widths['descriptionsDT'] + 'px' }" ref="descriptionsDTwrapper">
        <n-input v-model:value="searchFilters.descriptionsDT" type="text" :placeholder="$t('Description')" />
      </div>
    </div>
    <div class="data-table-loading-wrapper pb-5" v-loading="loading" id="tttab">
      <table class="data-table display" :class="className" ref="print-tab">
        <!--        <colgroup>-->
        <col v-for="f in fields" :key="f.name" :style="f.style" />
        <!--        </colgroup>-->

        <thead>
          <tr role="row">
            <th
              v-for="f in fields"
              :key="f.name"
              :class="columnSortClass(f)"
              @click="changeSort(f)"
              :data-field="f.name"
              v-resize:debounce="onColResize"
            >
              {{ $t(f.label) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in rowsData"
            :row-data="rowData"
            :fields="fields"
            :key="rowData._id"
            :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
            @select="selectRow(idx)"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ $t(f.label) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      class="floating-pager"
      style="width: auto"
      :style="{ left: visual.contentLeft + Math.floor(visual.contentWidth / 2) + 'px' }"
    >
      <div class="floating-pager__inner">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="page.eventsPage"
          :default-current-page="1"
          :page-sizes="[7, 17, 50, 100, 300]"
          v-model:page-size="pageLength"
          layout="total, sizes, prev, pager, next, jumper"
          :total="recordsFiltered"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="false" class="data-pages">
      <div class="dataTables_paginate paging_simple_numbers" id="wheel_events_table_paginate"></div>
    </div>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import ArchiveEventsModal from '@/components/modals/ArchiveEventsModal.vue';
import ExportModal from '@/components/modals/ExportModal.vue';

import api from '@/services/api';
import { Zone, DateTime } from 'luxon';
import Printd from 'printd';
import tinyPrint from 'tiny-print';
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useReports } from '@/stores/reports';
import { useEventsArchive } from '@/stores/eventsArchive';
import { useTpws } from '@/stores/tpws';
import { useEvents } from '@/stores/events';
import { usePage } from '@/stores/page';
import { usePrefs } from '@/stores/prefs';
import { useSocket } from "@/stores/socket";
import { useUrls } from '@/stores/urls';
import { useVisual } from '@/stores/visual';
import { useLicense } from '@/stores/license';

import eventBus from '@/utils/eventBus';
import { debounce } from '@/plugins/debounce';
import VIcon from '../../utils/VIcon.vue';

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    // buttons: {
    //   type: Array,
    // },
    fields: {
      type: Array,
    },
    lengthMenu: {
      type: Array,
    },
    noSearch: {
      defauld: false,
    },
    extButtons: {
      type: Array,
    },
    query: {
      type: Object,
    },
  },
  components: { VIcon, ArchiveEventsModal, ExportModal, DataRow, Loading },
  // directives: { loading },
  name: 'events-table',
  emits: ['page-update'],
  data: () => ({
    sortables: [],
    currentSortField: '',
    currentSortDir: -1,
    // currentPage: 1,
    pageStart: 0,
    pageLength: 17,
    searchString: '',
    // searchTypes: [],
    rowsData: [],
    printRowsData: [],
    recordsTotal: 0,
    recordsFiltered: 0,
    loading: false,
    requested: false,
    draw: 0,
    errors: [],
    selectedRows: [],
    visibleArchiveDialog: false,
    visibleExportDialog: false,
    eventsStatusInterval: undefined,
    updTsInterval: undefined,
    widths: {
      timeDT: 0,
      typeDT: 0,
      iedNameDT: 0,
      controlBlockDT: 0,
      descriptionsDT: 0,
    },
    searchFilters: {
      timeDT: '',
      iedNameDT: '',
      controlBlockDT: '',
      descriptionsDT: '',
    },
    nowTs: 100000,
    cacheSilentUpdate: false,
    resetId: ''
  }),
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useEvents),
    ...mapStores(usePage),
    ...mapStores(useUrls),
    ...mapStores(useVisual),
    ...mapStores(usePrefs),
    ...mapStores(useSocket),
    ...mapStores(useLicense),
    ...mapStores(useReports),
    ...mapStores(useEventsArchive),
    currentProject() {
      return this.tpws.currentProject;
    },
    displayName() {
      return this.tpws.displayName;
    },
    currentPage() {
      return this.page.eventsPage;
    },
    showEventsCacheProgress() {
      const delta = Math.abs(this.nowTs - this.events.cacheProgressTs);
      return delta < 4000;
    },
    showEventsCacheStoreProgress() {
      const delta = Math.abs(this.nowTs - this.events.storeProgressTs);
      return delta < 4000;
    },
    buttons() {
      let buttons = [
        {
          name: 'print',
          label: 'Print',
          action: 'printTable',
        },
        {
          name: 'events-export',
          label: 'Export',
          action: 'exportDialog',
        },
        {
          name: 'archive',
          label: 'Archive',
          action: 'switchArchive',
        },
        {
          name: 'view-selected',
          label: 'View selected',
          action: 'openSelected',
        },
        // {
        //   name: 'gen-fake',
        //   label: 'Generate fake',
        //   action: 'genFake',
        // },
        // {
        //   name: 'reset-tpls',
        //   label: 'Reset TPLS',
        //   action: 'resetTpls',
        // },
      ];

      let found;

      if (this.eventsArchive.eventsArchive) {
        found = buttons.find((el) => el.name === 'archive');
        found.label = 'Recent';
        found.action = 'switchRecent';
      } else {
        found = buttons.find((el) => el.name === 'archive');
        found.label = 'Archive';
        found.action = 'switchArchive';
      }

      return buttons;
    },
    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    enableView() {
      return this.rowsDataSelected.filter((e) => e.hasRef).length > 1;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    onTypes() {
      return this.eventTypes.filter((e) => this.offTypes.indexOf(e.name) < 0).map((e) => e.name);
    },
    offTypes() {
      return this?.page?.eventsOffTypes || [];
    },
    order() {
      return {
        column: Math.max(this.fields.map((e) => e.name).indexOf(this.currentSortField), 0),
        dir: this.currentSortDir === 1 ? 'asc' : 'desc',
      };
    },
    pagesList() {
      let list = [];
      if (this.pages?.length) {
        for (let i = 0; i < this.pages.length; i++) {
          list.push(this.pages[i]);
          if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
            list.push(null);
          }
        }
      }
      return list;
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$t('Goose') },
        { name: 'SV', label: this.$t('SV') },
        { name: 'SRV', label: this.$t('Service events') },
        { name: 'ARP', label: this.$t('ARP') },
        { name: 'MMS', label: this.$t('MMS') },
        { name: 'USER', label: this.$t('User events') },
      ];
    },

    offTypesArray() {
      return this.offTypes && this.offTypes.map((e) => '!' + e);
    },
    search() {
      // let searchArray = this.offTypesArray;
      // if (this.searchString !== '') {
      //   searchArray = [...this.offTypesArray, this.searchString];
      // }
      return {
        offTypes: this.offTypesArray.join(','),
        time: this.searchFilters.timeDT,
        iedName: this.searchFilters.iedNameDT,
        controlBlock: this.searchFilters.controlBlockDT,
        descriptions: this.searchFilters.descriptionsDT,
        regex: false,
      };
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      let searchObj = {
        draw: this.draw,
        dest: 'events',
        wsId: this.socket.currentId,
        order,
        search,
        start: this.pageStart,
        length: this.pageLength,
      };

      // console.log('this.$qs', this.$qs);

      if (this.query) {
        return this.$qs.stringify({ ...this.query, ...searchObj, removeUnfiltered: true });
      } else {
        return this.$qs.stringify(searchObj);
      }
    },
    printQueryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      let searchObj = {
        draw: this.draw,
        order,
        search,
        start: 0,
        length: this.recordsFiltered,
      };

      // console.log('this.$qs', this.$qs);

      if (this.query) {
        return this.$qs.stringify({ ...this.query, ...searchObj, removeUnfiltered: true });
      } else {
        return this.$qs.stringify(searchObj);
      }
    },
    exportDisabled() {
      return this.requested || this.reports.hasWaitingReports || this.reports.hasPendingReports || this.loading;
    },
    hasNotCachedEvents() {
      return this.events.hasNewNotCachedEvents || this.events.hasNotCachedEvents;
    },
  },
  beforeMount() {},
  mounted() {
    this.$router.isReady().then(() => {
      const lastRoute = localStorage.getItem('lastRoute');
      this.loading = false;
      this.draw = 0;
      this.rowsData = [];
      this.setDefaultSort();

      this.$nextTick(() => {
        this.loadData();
      });
    });

    dev.log('r', this.$route);

    this.$nextTick(() => {});
    this.updTsInterval = setInterval(this.updTs, 500);
    eventBus.on('eventsReload', () => {
      this.loadData();
    });
    eventBus.on('eventsSilentUpdate', () => {
      this.cacheSilentUpdate = true;
    });
  },
  beforeUnmount() {
    if (this.eventsStatusInterval) {
      clearInterval(this.eventsStatusInterval);
    }
    if (this.updTsInterval) {
      clearInterval(this.updTsInterval);
    }
    eventBus.remove('eventsReload');
    eventBus.remove('eventsSilentUpdate');
  },
  methods: {
    ...mapActions(useEventsArchive, ['setEventsArchive']),
    updTs() {
      this.nowTs = new Date().getTime();
    },
    onColResize(e, f) {
      // dev.log('onColResize', e, f.dataset.field);
      switch (f.dataset.field) {
        case 'timeDT':
        case 'typeDT':
        case 'iedNameDT':
        case 'controlBlockDT':
        case 'descriptionsDT':
          // this.$refs[f.dataset.field + 'wrapper'].style.width = e.width+'px';
          this.widths[f.dataset.field] = e.width;
          break;
      }
    },
    resetActivityRefresh() {
      clearInterval(this.eventsStatusInterval);
      this.$nextTick(() => {
        this.eventsStatusInterval = setInterval(() => {
          this.sendActivityRefresh();
          // 180000 - 3 min
        }, 180000);
      });
    },
    sendActivityRefresh() {
      if (this.currentProject && this.prefs.language) {
        this.$socket.send(
          JSON.stringify({
            type: 'user-activity-checkin',
            content: {
              projectName: this.currentProject,
              language: this.prefs.language,
            },
          })
        );
      }
    },
    updateVisibleExportDialog(e) {
      this.visibleExportDialog = e;
    },
    handleEventsStatusRefresh(statusType) {
      dev.log('handleEventsStatusRefresh', statusType);
      if (statusType === 'hasNewNotCachedEvents') {
        this.loadData(true);
      }
      if (statusType === 'hasNotCachedEvents') {
        this.loadData(true);
      }
    },
    handleEventsCacheExtension() {
      dev.log('handleEventsCacheExtension');
      this.loadData(true, true);
    },
    handleEventsUpdate() {
      dev.log('handleEventsUpdate');
      this.cacheSilentUpdate = false;
      this.loadData();
    },
    debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
    updateTypes() {
      try {
        // if (!this.page.eventsOnTypes) {
        //   this.page.eventsOnTypes = this.eventTypes.map((e) => e.name);
        // }
        // let ss = this.$route.query;
        // let ssArr = this.$qs.parse(ss);
        // let types = ssArr['search']
        //   .split(',')
        //   .filter((e) => e.indexOf('!') > -1)
        //   .map((e) => e.substring(1));
        // dev.log('ss', ssArr, this.page.eventsOnTypes);
        // this.page.eventsOnTypes.forEach((el) => {
        //   if (types.indexOf(el) < 0) {
        //     this.page.eventsOnTypes.push(el);
        //   }
        // });
        // this.offTypes = this.eventTypes.filter((e) => this.page.eventsOnTypes.indexOf(e.name) < 0).map((e) => e.name);
        this.$nextTick(() => {
          this.visibleTable = true;
        });
      } catch (e) {
        this.page.eventsOffTypes = this.eventTypes.map((e) => e.name);
        this.visibleTable = true;
      }
      if (this.extButtons) {
        this.buttons = this.extButtons;
      }
    },
    printTable() {
      // const d = new Printd()
      // d.print(this.$refs['print-block'], '.color { color: blue; }')
      this.loadPrintData().then(() => {
        dev.log('pr th');
        this.$nextTick(() => {
          tinyPrint(this.$refs['print-block'], {
            scanStyles: true,
            scanHTML: true,
            cssStyle: `a { text-decoration: none!important; }`,
          });
          this.$nextTick(() => {
            this.printRowsData = [];
          });
        });
      });
    },
    handleSizeChange(e) {
      this.pageLength = e;
      dev.log('set size', e);
    },
    handleCurrentChange(e) {
      // this.currentPage = e;
      dev.log('set page', e);
    },
    doExport(e) {
      dev.log('doExport11', e);
      if (!e?.format) {
        return;
      }

      let exportFormat = e.format;
      let exportSort = e.sort;
      let fileDateTime = DateTime.local().toFormat('yyyyLLdd');
      let exportFilename =
        `Теквел Парк - проект ${this.displayName} - список событий - ${fileDateTime}.` + exportFormat;

      const archive = this.eventsArchive.eventsArchive
        ? {
            tstart: this.eventsArchive.eventsArchiveTS.start,
            tend: this.eventsArchive.eventsArchiveTS.end,
            archive: this.eventsArchive.eventsArchive,
            type: exportFormat,
          }
        : { archive: this.eventsArchive.eventsArchive };

      const build = {
        version: this.license.license.license_data.version.tpwsVersionString,
        date: this.license.license.license_data.version.tpwsVersionDateString,
      };

      this.requested = true;

      this.reports.startWaitingReport(exportFilename);

      api
        .post(`/projects/${this.currentProject}/events-file-export`, {
          search: this.search,
          draw: 1,
          exportFilename: exportFilename,
          exportFormat: exportFormat,
          exportSort: exportSort,
          build,
          ...archive,
        })
        .then((response) => {
          dev.log('resp', response);
          // eventBus.dispatch('message', {
          //   message: this.$t('New report prepared'),
          //   close: true,
          //   type: 'info',
          // });

          // // auto download start
          // var link = document.createElement('a');
          // link.href = response.data.downloadPath;
          // link.download = response.data.filename;
          // link.click();
        })
        .catch((e) => {
          dev.log('err', e);
        })
        .finally(() => {
          this.requested = false;
          // loader.hide();
        });

      /*
      this.$.ajax({
        url: `/projects/${this.currentProject}/events-file-export`,
        type: 'post',
        dataType: 'json',

        data: {
          ...table.ajax.params(),
          draw: 1,
          exportFilename: exportFilename,
          exportFormat: exportFormat,
          exportSort: exportSort,
        },
        error: function (err) {

        },
        success: function (response) {
        },
        complete: function () {

        },
      });
*/
    },
    switchType(type) {
      dev.log('t', type);
      if (this.page.eventsOffTypes.indexOf(type.name) < 0) {
        this.page.eventsOffTypes.push(type.name);
      } else {
        this.page.eventsOffTypes = this.page.eventsOffTypes.filter((e) => e != type.name);
      }
      this.$nextTick(() => {
        // this.pageChange();
        this.$emit('pageUpdate');
      });
    },

    execAction(b, e) {
      dev.log('b, e', b, e);
      if (b.extAction) {
        this.$emit('ext-action', b.extAction, e);
      }
      if (b.action) {
        this[b.action](e);
      }
    },
    selectAll() {
      for (let i = 0; i < this.pageLength - 2; i++) {
        const rowInx = this.pageStart + i;
        if (this.selectedRows.indexOf(rowInx) < 0) {
          this.selectedRows.push(rowInx);
        } else {
          // this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
        }
      }
    },
    switchArchive(e) {
      this.visibleArchiveDialog = true;
    },
    switchRecent(e) {
      this.setEventsArchive(false);
    },
    exportDialog(e) {
      this.visibleExportDialog = true;
    },
    async genFake(e) {
      const result = await api.get('/projects/qdmfun/events-fake-tpls' + '?' + this.$qs.stringify({ count: 2500 }));
      dev.log('genFake', result);
    },
    async resetTpls(e) {
      const result1 = await api.post(
        this.urls.currentProjectUrl + '/events-reset-tpls', { id: this.resetId }
      );
      // const result2 = await api.get(
      //   '/projects/qdmfun/events-reset-tpls' +
      //     '?' +
      //     this.$qs.stringify({ range: { tstart: 1689753734, tend: 1689753734 } })
      // );
      dev.log('genFake', result1);
    },
    async outRangeTpls(e) {
      const result1 = await api.post(
        this.urls.currentProjectUrl + '/events-out-range-event', { id: this.resetId }
      );
      // const result2 = await api.get(
      //   '/projects/qdmfun/events-reset-tpls' +
      //     '?' +
      //     this.$qs.stringify({ range: { tstart: 1689753734, tend: 1689753734 } })
      // );
      dev.log('genFake', result1);
    },
    openSelected(e) {
      dev.log('openSelected', e);
      const ts = this.rowsDataSelectedTime;
      if (e.ctrlKey || e.shiftKey || e.altKey) {
        this.$router.push({
          name: 'signals-profile',
          params: { blank: true },
          query: { time: ts.timestamp, timeBefore: ts.timeBefore, timeAfter: ts.timeAfter },
        });
        // window.open(`?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`, '_blank');
      } else {
        this.$router.push({
          name: 'signals-profile',
          params: { blank: false },
          query: { time: ts.timestamp, timeBefore: ts.timeBefore, timeAfter: ts.timeAfter },
        });

        // location.href = `?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`;
      }
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      dev.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.page.eventsPage = p;
      }
    },
    loadData: debounce(function (upd, silent) {
      // console.trace();
      return this.debLoadData(upd, silent);
    }, 500),
    async debLoadData(upd, silent) {
      dev.log('--loadData events', upd, silent);
      if (this.$route.name !== 'events') {
        return;
      }
      this.draw++;
      if (!silent) {
        this.rowsData = [];
      }
      let dataUrl =
        this.url +
        (this.url.indexOf('?') > -1 ? '&' : '?') +
        this.queryString +
        (upd ? '&addNoCached=true' : '') +
        (silent ? '&silent=true' : '');
      if (!silent) {
        this.selectedRows = [];
        this.loading = true;

        this.$emit('pageUpdate');
      }

      dev.log('rowsData preload', this.rowsData);

      const result = await api.get(dataUrl);

      if (result?.data?.state) {
        dev.log('get state', result?.data?.state);
        return;
      }

      dev.log('result', result);
      this.resetActivityRefresh();

      const {
        data: { data, recordsTotal, recordsFiltered },
      } = result;
      if (!silent) {
        this.rowsData = [];
        data.forEach((e) => {
          this.rowsData.push(e);
        });
        // this.rowsData = data;
        dev.log('rowsData load', this.rowsData);
        this.recordsTotal = recordsTotal;
        this.recordsFiltered = recordsFiltered;
      }

      // this.$emit('loadedRows', this.rowsData.length);
      this.loading = false;
    },
    loadPrintData() {
      return new Promise((resolve, reject) => {
        this.draw++;
        dev.log('psq', this.printQueryString);
        let dataUrl = this.url + (this.url.indexOf('?') > -1 ? '&' : '?') + this.printQueryString;
        // this.selectedRows = [];
        this.loading = true;

        api
          .get(dataUrl)
          .then((response) => {
            // JSON responses are automatically parsed.
            this.printRowsData = response.data.data;
            this.$nextTick(() => {
              resolve('Success!');
              this.loading = false;
            });
          })
          .catch((e) => {
            reject(e);
            this.loading = false;
          });
      });
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          return 'sorting_asc';
        }
        if (this.currentSortDir === -1) {
          return 'sorting_desc';
        }
      } else {
        return f.sortable ? 'sorting' : '';
      }
    },
    changeSort(f) {
      dev.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.loadData();
      this.$emit('pageUpdate');
    },
  },
  watch: {
    hasNotCachedEvents: {
      handler(val, old) {
        if (val) {
          dev.log('hasNotCachedEvents change', val, old);
          this.handleEventsCacheExtension();
        }
      },
      // deep: true,
    },
    rowsData: {
      handler(val, old) {
        dev.log('rowsData change', val, this.rowsData);
      },
      deep: true,
    },
    onTypes: {
      handler(val, old) {
        dev.log('watch onTypes', old, val);

        this.loadData();
      },
      deep: true,
    },

    // searchString: {
    //   handler(val, old) {
    //     dev.log('watch searchString');
    //     if (val !== old) {
    //       this.loadData();
    //     }
    //   },
    //   immediate: false,
    // },
    'searchFilters.timeDT': {
      handler(val, old) {
        dev.log('watch searchFilters.timeDT');
        if (val !== old) {
          this.loadData();
        }
      },
      immediate: false,
    },
    'searchFilters.iedNameDT': {
      handler(val, old) {
        dev.log('watch searchFilters.iedNameDT');
        if (val !== old) {
          this.loadData();
        }
      },
      immediate: false,
    },
    'searchFilters.controlBlockDT': {
      handler(val, old) {
        dev.log('watch searchFilters.controlBlockDT');
        if (val !== old) {
          this.loadData();
        }
      },
      immediate: false,
    },
    'searchFilters.descriptionsDT': {
      handler(val, old) {
        dev.log('watch searchFilters.descriptionsDT');
        if (val !== old) {
          this.loadData();
        }
      },
      immediate: false,
    },
    'prefs.language': {
      handler(val, old) {
        if (val !== old) {
          this.draw = 0;
          this.loadData();
        }
      },
      // deep: true,
    },

    url: {
      handler(val, old) {
        dev.log('watch url');
        if (val !== old) {
          this.loadData();
        }
      },
      immediate: false,
    },
    currentPage: {
      handler(val, old) {
        dev.log('watch currentPage');
        if (val !== old) {
          this.pageStart = (this.currentPage - 1) * this.pageLength;
        }
      },
      immediate: true,
    },
    eventTypes: {
      handler(val, old) {
        // dev.log('watch eventTypes', val);
        this.updateTypes();
      },
      immediate: false,
    },
    pageLength: {
      handler(val, old) {
        dev.log('watch pageLength');
        if (val !== old) {
          this.pageStart = (this.currentPage - 1) * this.pageLength;
          this.loadData();
        }
      },
      immediate: true,
    },
    pageStart: {
      handler(val, old) {
        dev.log('watch pageStart');
        if (val !== old) {
          this.loadData();
        }
      },
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
