<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.devStatusModalVisible"
    v-model:visible="visual.devStatusModalVisible"
    ref="modEx"
    @closeModal="closeModal"
  >
    <!--    <template #title>-->
    <!--      <span class="lvb-description-modal__title"></span>-->
    <!--    </template>-->
    <template #content>
      <div class="dev__header-wrapper">
        <ul id="dev-console-nav-tabs" class="nav dev__header" role="tablist">
          <li class="header__item">
            <a
              href="#dev-objects"
              id="dev-objects-tab"
              class="header-item__label active"
              data-toggle="tab"
              role="tab"
              aria-controls="dev-objects"
              aria-selected="true"
              >{{ $t('TPWS Objects') }}</a
            >
          </li>

          <li class="header__item">
            <a
              href="#dev-live-objects"
              id="dev-live-objects-tab"
              class="header-item__label"
              data-toggle="tab"
              role="tab"
              aria-controls="dev-live-objects"
              aria-selected="false"
              >{{ $t('Live Objects JSON') }}</a
            >
          </li>
          <li class="header__item">
            <a
              href="#dev-live-objects-table"
              id="dev-live-objects-table-tab"
              class="header-item__label"
              data-toggle="tab"
              role="tab"
              aria-controls="dev-live-objects-table"
              aria-selected="false"
              >{{ $t('Live Objects Table') }}</a
            >
          </li>
          <li class="header__item">
            <a
              href="#dev-unhandled-events"
              id="dev-unhandled-events-tab"
              class="header-item__label"
              data-toggle="tab"
              role="tab"
              aria-controls="dev-unhandled-events"
              aria-selected="false"
              >{{ $t('Unhandled events') }}</a
            >
          </li>
        </ul>
      </div>

      <div id="myTabContent" class="tab-content">
        <div id="dev-objects" class="tab-pane fade show active" role="tabpanel" aria-labelledby="dev-objects-tab">
          <div id="dev-objects-json-view">
            <n-scrollbar style="max-height: 65vh">
              <json-tree :level="1" :data="ghosts.devObjectsJson"></json-tree>
            </n-scrollbar>
          </div>
        </div>
        <div id="dev-live-objects" class="tab-pane fade" role="tabpanel" aria-labelledby="dev-live-objects-tab">
          <div id="dev-live-objects-json-view">
            <n-scrollbar style="max-height: 65vh">
              <json-tree :level="1" :data="ghosts.devLiveObjectsJson"></json-tree>
            </n-scrollbar>
          </div>
        </div>
        <div
          id="dev-live-objects-table"
          class="tab-pane fade"
          role="tabpanel"
          aria-labelledby="dev-live-objects-table-tab"
        >
          <div id="dev-live-objects-table-view">
            <h1>{{ $t('Live Goose Messages') }}</h1>
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t('ID') }}</th>
                  <th>{{ $t('Paramaters') }}</th>
                  <th>{{ $t('Reporting LVBs') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  is="vue:live-dev-object"
                  v-for="(item, idx) in ghosts.objects"
                  v-bind:obj="item"
                  v-bind:idx="idx + 1"
                ></tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="dev-unhandled-events" class="tab-pane fade" role="tabpanel" aria-labelledby="dev-unhandled-events-tab">
          <div id="dev-fault-profile"></div>
          <div id="control-buttons">
            <button id="dev-view-records" class="btn btn-primary" type="button">
              {{ $t('View All') }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #buttons> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import LiveDevObject from '@/components/wheel/LiveDevObject.vue';
import { useVisual } from '@/stores/visual';
import { useUnhandledEvents } from '@/stores/unhandledEvents';
import { useGhosts } from '@/stores/ghosts';
import { mapStores } from 'pinia';
import JsonTree from '@/components/common/JsonTree.vue';

export default {
  name: 'dev-status-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog, LiveDevObject, JsonTree },
  data: () => {
    return {
      modal: {
        name: 'dev-status',
        title: 'Tekvel Park Developer Console',
        closeBtn: 'Close',
        noSubmit: true,
        size: 'xl',
        noTitle: true,
      },
      dateTime: {},
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useGhosts),
  },
  mounted() {
    window.addEventListener('keyup', this.keyboardEvent);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.keyboardEvent);
  },
  methods: {
    keyboardEvent(e) {
      if (e.ctrlKey && e.shiftKey && e.keyCode == 75) {
        // This is a sample JS for project renaming testing
        // Ctrl + Shift + L
        // $('#dev-status-modal').modal('show');
        dev.log('keyboardEvent dev-status-modal', e);
        this.visual.devStatusModalVisible = !this.visual.devStatusModalVisible;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 186) {
        // This is a sample JS for project renaming testing
        this.ghosts.devConsoleLiveUpdateOn = !this.ghosts.devConsoleLiveUpdateOn;
        dev.log(this.ghosts.devConsoleLiveUpdateOn);
        const onOff = this.ghosts.devConsoleLiveUpdateOn ? 'on' : 'off';
        notifyKas.show({ text: this.$t('Developer console update ' + onOff), close: true });
      }
    },
    closeModal() {
      dev.log('closeModal');
      this.visual.devStatusModalVisible = false;
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
