<template>
  <modal-dialog
    :modal="modal"
    v-if="visibleModal"
    :visible="visibleModal"
    @update:visible="updateVisibleModal"
    ref="mod"
    @closeModal="closeModal"
    @showArchive="showArchive"
  >
    <template #content>
      <form>
        <p>
          {{ $t(modalText) }}
        </p>
        <radio-button-group
          :data.sync="timePresets"
          :current="eventsArchive?.currentTimePreset"
          :parent-elem="modal.name + '-modal'"
          group-name="time-preset"
          @selected="selectPeriod"
        ></radio-button-group>

        <div v-show="eventsArchive?.currentTimePreset === 'custom-period'" class="datetime-period-picker">
          <p>
            <label class="" style="width: 200px" :id="$t('From')">
              {{ $t('From') }}
            </label>
            <el-date-picker
              :clearable="false"
              v-model="dateTime.start"
              type="datetime"
              :placeholder="$t('Select date and time')"
              @change="setTimePeriodCustom"
              @blur="setTimePeriodCustom"
            >
            </el-date-picker>
          </p>
          <p>
            <label class="" style="width: 200px" :id="$t('To')">
              {{ $t('To') }}
            </label>
            <el-date-picker
              :clearable="false"
              v-model="dateTime.end"
              type="datetime"
              :placeholder="$t('Select date and time')"
              @change="setTimePeriodCustom"
              @blur="setTimePeriodCustom"
            >
            </el-date-picker>
          </p>
        </div>
        <div v-if="isCounted">{{$t('Events number in interval is')}} {{ countNum }}</div>
        <div v-else>{{$t('Events number counting in process')}}...</div>

        <!--      <datetime-period-picker
        v-if="currentTimePreset === 'custom-period'"
        :parent-elem="modal.name + '-modal'"
        :datetime="dateTime"
        @selected="setTimePeriodCustom"
      ></datetime-period-picker>
        -->
        <div class="alert alert-danger d-none" :class="modal.name + '-modal__error'"></div>
      </form>
    </template>
    <template #buttons>
      <button
        class="btn btn-sec"
        type="button"
        data-dismiss="modal"
        :aria-label="$t('Cancel')"
        @click="$refs.mod.$emit('closeModal')"
      >
        {{$t('Cancel')}}</button
      ><button
        class="btn btn-pri"
        :class="[modal.name + '__submit-btn']"
        :type="modal.typeSubmit ? 'submit' : 'button'"
        :aria-label="modal.submitBtn ? modal.submitBtn : 'Save'"
        :disabled="isDisabled"
        @click="$refs.mod.$emit(modal.onSubmit)"
        :form="modal.typeSubmit ? `${modal.name}-modal__form` : ''"
      >
        {{ $t(modal.submitBtn ? modal.submitBtn : 'Save') }}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import RadioButtonGroup from '@/components/ui/RadioButtonGroup.vue';
import DatetimePeriodPicker from '@/components/ui/DatetimePeriodPicker.vue';

import { mapActions } from 'pinia';
import { useEventsArchive } from '@/stores/eventsArchive';
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { useVisual } from '@/stores/visual';

export default {
  name: 'archive-events-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['showArchive'],
  components: { ModalDialog, RadioButtonGroup, DatetimePeriodPicker },
  data: () => {
    return {
      isCounted: false,
      countNum: 0,
      maxEventsNum: 200000,
      isDisabled: false,
      modal: {
        name: 'archive-events-vue',
        title: 'Archive',
        onSubmit: 'showArchive',
        submitBtn: 'OK',
      },
      dateTime: {},
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useVisual),
    ...mapStores(useEventsArchive),
    currentProject() {
      return this.tpws.currentProject;
    },
    timePresets() {
      return [
        { value: 'five-min', label: this.$t('Last 5 minutes') },
        { value: 'today', label: this.$t('Today') },
        { value: 'this-week', label: this.$t('This week') },
        { value: 'last-week', label: this.$t('Last week') },
        { value: 'this-month', label: this.$t('This month') },
        { value: 'last-month', label: this.$t('Last month') },
        { value: 'custom-period', label: this.$t('Set custom period') },
      ];
    },
    modalText() {
      return this.$t('Show events log for selected period');
    },
  },
  mounted() {},
  methods: {
    ...mapActions(useEventsArchive, ['setEventsArchive', 'setEventsArchiveTS']),
    updateVisibleModal(e){
      this.$emit('update:visibleModal', e);
    },
    showArchive() {
      dev.log('showArchive');
      this.setEventsArchive(true);
      this.$emit('update:visible-modal', false);
      // this.$emit('update:archive', true);
    },
    closeModal() {
      dev.log('closeModal');
      this.$emit('update:visibleModal', false);
    },
    selectPeriod(p) {
      dev.log(p);
      this.isCounted = false;
      this.isDisabled = true;

      dev.log('selectPeriod', p);
      this.eventsArchive.currentTimePreset = p.value;
      dev.log('selectPeriod currentTimePreset', this.eventsArchive.currentTimePreset);
      this.setTimePeriod(p.value);
    },
    setTimePeriod(value) {
      let now = new Date();
      let start = new Date();
      let end = new Date();
      switch (value) {
        case 'five-min':
          start.setMinutes(now.getMinutes() - 5);
          break;
        case 'today':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          break;
        case 'this-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'last-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 6);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'this-month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case 'last-month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        default:
          break;
      }

      this.dateTime = {
        start: start,
        end: end,
      };
      this.$nextTick(() => {
        this.setTimePeriodCustom();
      });
      this.countEvents(start, end);
    },
    setTimePeriodCustom() {
      this.isCounted = false;
      // this.isDisabled = true;
      this.setEventsArchiveTS({
        tstart: this.dateTime.start.getTime() * 1e3,
        tend: this.dateTime.end.getTime() * 1e3,
      });
      this.countEvents(this.dateTime.start, this.dateTime.end);
    },
    countEvents(start, end) {
      dev.log('countEvents', start, end);
      api
        .get(
          `/projects/${this.currentProject}/archive-count?tstart=${start.getTime() * 1e3}&tend=${end.getTime() * 1e3}`
        )
        .then((response) => {
          if (response.data.count == this.maxEventsNum) {
            this.countNum = `Current interval contains over ${this.maxEventsNum} events. Please, choose more specific interval`;
          } else {
            this.countNum = response.data.count;
            this.isDisabled = false;
          }
          this.isCounted = true;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
  },
  watch: {
    visibleModal: function (val) {
      dev.log('visibleModal', val);
      if (!val) {
        this.$emit('update:visibleModal', val);
      }
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
