<template>
  <div>
    <input type="text" />
  </div>
</template>

<script>
export default {
  name: 't-input',
};
</script>

<style scoped></style>
