import { i18next } from '@/root.app';
import api from '@/services/api';
import { useTpws } from '@/stores/tpws';
import eventBus from '@/utils/eventBus';
import { defineStore } from 'pinia';

export const useUsers = defineStore({
  name: 'users',
  id: 'users',
  state: () => {
    return {
      users: undefined,
      visibleNewUserModal: false,
      visibleEditUserModal: false,
      visibleChangePasswordModal: false,
      profileContext: undefined,
    };
  },
  getters: {
    contextResolver(state) {
      return state.profileContext || this.getCurrentProfileFields();
    },
    userFields(state) {
      return this.contextResolver;
    },
  },
  actions: {
    showNewUserModal() {
      this.visibleNewUserModal = true;
    },
    hideNewUserModal() {
      this.visibleNewUserModal = false;
    },
    showEditUserModal() {
      this.visibleEditUserModal = true;
    },
    hideEditUserModal() {
      this.visibleEditUserModal = false;
    },
    showChangePasswordModal() {
      this.visibleChangePasswordModal = true;
    },
    hideChangePasswordModal() {
      this.visibleChangePasswordModal = false;
    },
    invite(email) {
      return new Promise((resolve) => {
        const tpws = useTpws();
        api
          .get(`/projects/${tpws.currentProject}/invite2`, { params: { email } })
          .then((response) => {
            //console.log('forgot user response', response);
            if (response.data.res == 'success') {
              eventBus.dispatch('message', {
                message: `${i18next.t('Successfully sent invitation to')} ` + email + '',
                close: true,
                type: 'success',
              });
              resolve(null);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.error(error);
            resolve(null);
          });
      });
    },
    getCurrentProfileFields() {
      return new Promise((resolve) => {
        api
          .get(`/users/current-profile-fields`)
          .then(({ data }) => {
            //console.log('current-profile-fields', data);
            this.profileContext = data;
            resolve(this.profileContext);
          })
          .catch((error) => {
            console.error('currentProject error', error);
            resolve(error);
          })
          .finally(() => {
            // this.projectStatusLoading = false;
          });
      });
    },
    setCurrentProfileFields(formValues) {
      return new Promise((resolve) => {
        api
          .post(`/users/edit-profile`, formValues)
          .then((response) => {
            //console.log('edit-profile', response);
            // this.profileContext = data;
            resolve(response);
          })
          .catch((error) => {
            console.error('currentProject error', error);
            resolve(error);
          })
          .finally(() => {
            // this.projectStatusLoading = false;
          });
      });
    },
    async loadUsers() {
      //console.log('pin loadUsers');
      if (this.licenseLoaded) return;
      const {
        data: {
          data: { users },
        },
      } = await api.post(`/users/manage-data`);
      //console.log('users', users);
      if (users) {
        this.users = users;
      }
    },
    unloadUsers() {
      this.users = [];
    },
  },
});
