<template>
  <mq-responsive group>
    <template #horizontal>
      <top-nav-white :atrs="dataset" />
    </template>
    <template #vertical>
      <div>
        <horizontal-menu v-if="true || $route.path !== '/open/wheel'" :atrs="dataset" />
      </div>
    </template>
  </mq-responsive>
</template>

<script>
import { CONTROL_POINT } from '@/constants/media-query';
// import { MatchMedia } from 'vue-component-media-queries';
// import { MqResponsive, updateBreakpoints } from 'vue3-mq';
import TopNavWhite from '@/components/layout/TopNavWhite.vue';
import HorizontalMenu from '@/components/layout/HorizontalMenu.vue';

const queries = `(min-width: ${CONTROL_POINT}px)`;

export default {
  queries,
  name: 'top-nav',
  components: { TopNavWhite, HorizontalMenu },
  props: ['dataset'],
  inject: ['mq'],
  data() {
    return {};
  },
  computed: {
    displayText() {
      return this.$mq === 'horizontal' ? 'I am horizontal' : 'I am vertical';
    },
    mqq() {
      return this.mq;
    },
  },
  mounted() {
  },
  methods: {
    debounce(func, wait, immediate) {
      var timeout;
      return () => {
        const context = this,
          args = arguments;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },

    checkWidth(media) {
      dev.log('media', media);
      // if (media.matches) {
      //   this.$store.commit('openFilterVisible');
      //   this.$store.dispatch('setVertical', false);
      // } else {
      //   this.$store.commit('closeFilterVisible');
      //   this.$store.dispatch('setVertical', true);
      // }
    },
  },
  watch: {
    mq(vv) {
      dev.log('mq', vv);
    },
    mqq(vv) {
      dev.log('mqq', vv);
    },
  },
};
</script>

<style scoped></style>
