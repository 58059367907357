import api from '@/services/api';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import set from 'set-value';

import { colors } from '@/root.app';
import { useAuth } from '@/stores/auth';
import { useUrls } from '@/stores/urls';
import { usePrefs } from '@/stores/prefs';
import chroma from 'chroma-js';

const getUserColor = (name) => {
  let nameSum = 0;
  for (let i = 0; i < name.length; i++) {
    nameSum += name.charCodeAt(i) + i;
  }
  return colors[nameSum % 192];
};

export const useProjectsList = defineStore({
  id: 'projectsList',
  state: () => ({
    projectsByUser: {},
    eventsByProjectCache: {},
    versionsByProjectCache: {},
    usersByProjectCache: {},
    usersCache: {},
    allUsersCache: [],
  }),
  getters: {
    userProjects: (state) => {
      const auth = useAuth();
      //console.log('userProjects auth.userId', auth.userId);
      return state.projectsByUser[auth.userId]?.projects || [];
    },
    userCanAddProjects: (state) => {
      const auth = useAuth();
      return state.projectsByUser[auth.userId]?.plus || false;
    },
    projectUsers: (state) => {
      return (projectId) => {
        const pUsers = state.usersByProjectCache[projectId];
        if (!this.usersCache[projectId]) {
          this.usersCache[projectId] = [];
        }
        let roles = pUsers;
        if (roles) {
          roles.forEach((role) => {
            role.users &&
              role.users.forEach((us) => {
                const found = this.usersCache[projectId].find((el) => el.id === us.id);
                if (!found) {
                  this.usersCache[projectId].push(us);
                }
                // console.log('user', us);
              });
          });
          // this.fillUsers();
        }
        return this.usersCache[projectId];
      };
    },
    allUsers: (state) => {
      let users = state.allUsersCache || [];
      users.forEach((user) => {
        user.letters = (user.firstname ? user.firstname[0] : '_') + (user.lastname ? user.lastname[0] : '_');
        user.fullName = (user.firstname ? user.firstname : '_') + ' ' + (user.lastname ? user.lastname : '_');
        user.color = chroma(getUserColor(user.fullName)).darken();
      });
      return users;
    },
    projectLastEvent: (state) => {
      return (projectId) => {
        const ts = state.eventsByProjectCache[projectId];
        if (ts) {
          const prefs = usePrefs();
          return DateTime.fromMillis(ts / 1e3)
            .setLocale(prefs.language ? prefs.language : 'en')
            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
        } else {
          return '---';
        }
      };
    },
    projectLastVersion: (state) => {
      return (projectId) => {
        const ts = state.versionsByProjectCache[projectId];
        if (ts) {
          const prefs = usePrefs();
          return DateTime.fromMillis(ts / 1e3)
            .setLocale(prefs.language ? prefs.language : 'en')
            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
        } else {
          return '---';
        }
      };
    },
  },
  actions: {
    getProjects() {
      const urls = useUrls();
      const auth = useAuth();
      const userId = auth.userId;
      dev.log('getProjects userId', userId);
      let dataUrl = '/projects-index/list';
      try {
        api
          .get(dataUrl)
          .then((result) => {
            dev.log('result', result);
            // JSON responses are automatically parsed.
            dev.log('projects-list result', result);
            // result.data.users = [...new Set(result.data.users)];
            this.projectsByUser[userId] = result.data;
            // this.projectsByUser[userId].users = [...new Set(this.projectsByUser[userId].users)];
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (er) {
        console.error(er);
      }
    },
    gettingProjectDetails(data) {
      if (data.dType) {
        switch (data.dType) {
          case 'lastEventByProject':
            if (data?.data?.project_id && data?.data?.lastEvent) {
              // console.log('eventsByProjectCache set', data.data);
              this.eventsByProjectCache[data.data.project_id] = data.data.lastEvent;
            }

            break;
          case 'lastVersionByProject':
            if (data?.data?.project_id && data?.data?.lastVersion) {
              this.versionsByProjectCache[data.data.project_id] = data.data.lastVersion;
            }
            break;
          case 'usersByProject':
            if (data?.data?.project_id && data?.data?.users) {
              let roles = data?.data?.users;
              if (!this.allUsersCache) {
                this.allUsersCache = [];
              }
              if (roles) {
                roles.forEach((role) => {
                  role.users &&
                    role.users.forEach((us) => {
                      const found = this.allUsersCache.find((el) => el.id === us.id);
                      if (!found) {
                        this.allUsersCache.push(us);
                      }
                    });
                });
              }
              this.usersByProjectCache[data.data.project_id] = data.data.users;
            }
            break;
        }
      }
    },
    requestProjectDetails() {
      const urls = useUrls();
      let dataUrl = '/projects-index/details';
      api
        .get(dataUrl)
        .then((data) => {
          dev.log('resp start', data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    requestProjectsUsers(projects) {
      const urls = useUrls();
      let dataUrl = '/projects-index/users';
      projects.length > 0 &&
        api
          .post(dataUrl, { projects: projects })
          .then((data) => {
          })
          .catch((e) => {
            console.error(e);
          });
    },
  },
});
