<template>
  <tr class="table-row">
    <td class="table-cell table-cell-col-1">{{ gi.mac[0] }} <span v-if="gi.macVendor">({{ gi.macVendor[0] }})</span></td>
    <td class="table-cell table-cell-col-2">{{ gi.ip[0] }}</td>
    <td class="table-cell table-cell-col-3">
      <a id="set-offline-btn" class="btn btn-sm fal fa-times-circle" v-on:click="setOffline(gi.ied)"></a>
    </td>
  </tr>
</template>

<script>
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useWheel } from '@/stores/wheel';
import { useGhosts } from '@/stores/ghosts';

import { useTpws } from '@/stores/tpws';
export default {
  name: 'ghost-ied',
  props: ['gi', 'idx'],
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useWheel),
    ...mapStores(useGhosts),
    currentProject() {
      return this.tpws.currentProject;
    },
    ghostIeds() {
      return this.ghosts.ghostIeds;
    },
  },
  methods: {
    ...mapActions(useGhosts, ['setGhostIeds']),

    setOffline: function (ied) {
      this.$slf.offlineIed.push(this.gi.ied);
      this.setGhostIeds(this.ghostIeds.filter((i) => i.ied !== this.gi.ied));
      this.$slf.setIedOffline(this.currentProject, ied);
    },
  },
};
</script>

<style scoped></style>
