<template>
  <div class="container login-form-container">
    <div class="tpws-centered-form">
      <p class="text-center">{{ $t('Activation') }}</p>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useAuth } from '@/stores/auth';
import { usePage } from '@/stores/page';
import { mapActions } from 'pinia';
import eventBus from '@/utils/eventBus';

export default {
  name: 'reset-page',
  data: () => {
    return {
      text: '',
      password: '',
      password_confirm: '',
    };
  },
  computed: {
    ...mapStores(usePage),
    onPremis() {
      return this.page.pageData['auth-login']?.onPremis;
    },
  },
  mounted() {
    this.$router.isReady().then(() => {
      this.doActivate();
    });
  },
  unmounted() {},
  methods: {
    ...mapActions(useAuth, ['activate']),
    doActivate() {
      const token = this.$route.query.t;
      if (!token) {
        eventBus.dispatch('message', {
          message: this.$t('Incorrect token. Check the link in your mailbox.'),
          close: true,
          type: 'error',
        });
        return;
      }
      eventBus.dispatch('message', {
        message: this.$t('Sending activation request.'),
        close: true,
        type: 'info',
      });

      this.activate(token, this.password, this.password_confirm, { name: 'auth-login' })
        .then((url) => {
          dev.log('ret', url);
          if (url) {
            this.$router.push(url);
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss"></style>
