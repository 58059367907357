<template>
  <div class="container login-form-container">
    <div class="tpws-centered-form">
      <div class="tps-error-message">
        <p v-if="message" class="alert alert-danger tpws-roundborder-block">message</p>
      </div>
      <div>
        <n-form ref="formRef" :model="formValue" :rules="rules" label-placement="top"  id="signin_form"
                require-mark-placement="right-hanging"
                label-width="auto">
          <input v-model="token" name="token" type="token" hidden :readonly="true" id="token" class="form-control" />

          <n-form-item :label="$t('Email')" path="username">
            <n-input name="email"
                     type="text" v-model:value="formValue.username" :placeholder="$t('Email')" />
          </n-form-item>
        </n-form>
        <div v-if="false" class="form-group">
          <label for="email">{{ $t('Email') }}</label>
          <input
            v-model="username"
            name="email"
            type="text"
            id="username"
            class="form-control"
            @keyup.enter="onSubmit"
          />
        </div>
        <button @click="onSubmit" class="btn btn-pri">{{ $t('Reset Password') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useAuth } from '@/stores/auth';
import { usePage } from '@/stores/page';
import { mapActions } from 'pinia';
import {ref} from "vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: 'forgot-page',
  setup() {
    const formRef = ref(null);
    return { v$: useVuelidate(),
      formRef,
      formValue: ref({
        username: '',
      }),};
  },

  data: () => {
    return {
      username: '',
    };
  },
  computed: {
    ...mapStores(usePage),
    onPremis() {
      return this.page.pageData['auth-login']?.onPremis;
    },
    rules() {
      return {
        username: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
      };
    },

  },
  mounted() {
    $('div#copyright p').text(function () {
      return $(this).text().replace('currentyear', new Date().getFullYear());
    });
  },
  unmounted() {
    // this.$unloadScript('/js/wheel-session.js');
  },
  methods: {
    // ...mapActions(['getPageData']),
    ...mapActions(useAuth, ['forgot']),
    onSubmit() {
      this.formRef
          ?.validate((errors) => {
            if (!errors) {
              this.forgot(this.formValue.username, { name: 'auth-login' }).then((url) => {
                dev.log('ret', url);
                if (url) {
                  this.$router.push(url);
                }
              });
            } else {
              dev.log('form not valid', errors);
            }
          })
          .catch((err) => {});



      // api
      //   .post('/login', { email: this.username, password: this.password })
      //   .then((res) => {
      //     if (res.status == 200) {
      //       this.$router.push({ name: 'client-root' });
      //     }
      //   })
      //   .catch(() => {});
    },
  },
};
</script>

<style lang="scss"></style>
