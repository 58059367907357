<template>
  <div id="project-page">
    <Head v-if="displayName">
      <title>{{ $t('Tekvel Park') + ' — ' + $t('Project') + ' ' + displayName }}</title>
    </Head>
    <div class="container-fluid project-page-container pt-5  mb-3">
      <template v-if="current?.substation">
        <div class="project-title-bar d-flex justify-content-between align-items-center">
          <h1 class="project-title-bar__title">{{ current.substation.name }}</h1>
          <div class="project-title-bar__actions">
            <div class="ui-button pointer-cursor" @click="visual.editProjectModalVisible = true">
              <icon size="20" class="mr-1">
                <edit-icon></edit-icon>
              </icon>

              <div class="ui-button__lable">{{ $t('Edit') }}</div>
            </div>
          </div>
        </div>
        <div class="project-title-bar__desc">{{
          current.substation.desc === 'No substation description' ||
          current.substation.desc === 'Нет описания подстанции'
            ? ''
            : current.substation.desc
        }}</div>
      </template>
      <div v-else class="mb-3">
        <div class="project-title-bar d-flex justify-content-between align-items-center">
          <h1 class="project-title-bar__title">{{ displayName }}</h1>
          <div class="project-title-bar__actions">
            <div class="ui-button pointer-cursor" @click="visual.editProjectModalVisible = true">
              <icon size="20" class="mr-1">
                <edit-icon></edit-icon>
              </icon>

              <div class="ui-button__lable">{{ $t('Edit') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-block__header">
        <div class="page-actions__tabs page-actions__tabs_project">
          <ul class="nav nav-pills mb-3 page-tabs" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link page-tabs__elm pointer-cursor"
                :class="{ active: $route.name == 'project-timeline' }"
                tabindex="1"
                @click="$router.push({ name: 'project-timeline', params: { projectId: currentProject } })"
              >
                {{ $t('Versions history') }}
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link page-tabs__elm pointer-cursor"
                :class="{ active: $route.name == 'project-users' }"
                tabindex="1"
                @click="$router.push({ name: 'project-users', params: { projectId: currentProject } })"
              >
                {{ this.$t('Users') }}
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link page-tabs__elm pointer-cursor"
                :class="{ active: $route.name == 'project-lvb' }"
                tabindex="1"
                @click="$router.push({ name: 'project-lvb', params: { projectId: currentProject } })"
              >
                {{ this.$t('LVBs list') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <teleport to="#modals-target">
        <edit-project-modal></edit-project-modal>
      </teleport>
      <div class="tab-content" id="pills-tabContent" @load="onContentLoaded">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePage } from '@/stores/page';
import { useVisual } from '@/stores/visual';
import EditProjectModal from '@/components/modals/EditProjectModal.vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import { Icon } from '@vicons/utils';
export default {
  name: 'project-page',
  components: { Icon, EditIcon, EditProjectModal },
  props: {},
  data: () => {
    return {
      // scripts: ['/js/timeline.js', '/js/project_users.js', '/js/tpui.project.timeline.js', '/js/widget.scl.js'],
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(usePage),
    ...mapStores(useVisual),
    currentProject() {
      return this.tpws.currentProject;
    },
    displayName() {
      return this.tpws.displayName;
    },
    current() {
      return this.page.currentPage?.current;
    },
    enableSoftwareLVB() {
      return this.page.currentPage?.enableSoftwareLVB;
    },
    adm() {
      return this.page.currentPage?.adm;
    },
    isLive() {
      return this.page.currentPage?.isLive;
    },
    projectusers() {
      return this.page.currentPage?.projectusers;
    },
    permission() {
      return this.page.currentPage?.permission;
    },
    commissioning() {
      return this.page.currentPage?.commissioning;
    },

    url() {
      return this.eventsArchive
        ? `/projects/${this.currentProject}/archive?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.currentProject}/events2`;
    },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },

    fields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
  },
  created() {},
  mounted() {},
  methods: {
    onContentLoaded(e) {
      dev.log('page load', e);
      window.dispatchEvent(new Event('page.ready'));
    },

  },
};
</script>

<style lang="scss">
.nav-item:first-child .nav-link {
  padding-left: 0;
}
.project-title-bar {
  display: flex;
  justify-content: space-between;
  &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  &__desc {
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
  }
  &__actions {
  }
}
</style>
<style lang="scss"></style>
