<template></template>

<script>
import { ElNotification } from 'element-plus';
import { ElMessage } from 'element-plus';
import eventBus from '@/utils/eventBus';

export default {
  name: 'common-notify',
  mounted() {
  },
  methods: {},
};
</script>

<style scoped></style>
