<template>
  <div class="sidebar-left" id="sidebar" :class="{ 'sidebar-left--collapsed': visual.sidebarCollapsed }">
    <div class="logo-block">
      <div class="logo-icon" v-if="visual.sidebarCollapsed">
        <v-svg src="goose-logo" fill="white" @click="goTo({ name: 'client-root' })" cc="pointer-cursor"></v-svg>
      </div>
      <div v-else>
        <v-svg src="tekvel-logo" fill="white" @click="goTo({ name: 'client-root' })" cc="pointer-cursor"></v-svg>
      </div>
    </div>
    <simplebar-vue data-simplebar ref="menuSb" class="dark" id="dark">
      <el-menu
        v-if="!visual.sidebarCollapsed"
        :default-active="activeIndex"
        class="sidebar-nav-menu"
        background-color="#2a2e32"
        text-color="#949698"
        :collapse="false"
        :unique-opened="true"
        :collapse-transition="false"
        @select="handleSubmenuSelection"
      >
        <el-menu-item
          v-show="currentProject"
          index="/users/1/projects"
          @click="goToItem({ action: { name: 'current-project', params: { projectId: currentProject } } })"
          :disabled="page === '/open/wheel'"
        >
          <v-svg src="book-open" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
          <span>{{ $t('Project') }}</span>
        </el-menu-item>
        <el-sub-menu
          v-if="menuItems.viewingAndAnalyzing"
          index="2"
          ref="subMenu2"
          :show-timeout="300"
          :hide-timeout="50"
          :popper-offset="0"
          v-show="currentProject && page !== '/open/wheel'"
          v-resize="doRecalc"
        >
          <template #title>
            <v-svg src="eye" fill="#949698" cc="fal-menu"></v-svg>
            <span>{{ $t('Viewing and analysis') }}</span>
          </template>
          <el-menu-item
            v-for="item in menuItems.viewingAndAnalyzing"
            :class="{ 'active-item': activeIndex === item.link }"
            :key="item.link"
            :index="item.link"
            @click="goToItem(item)"
          >
            <template #title>
              <font-awesome-icon class="fal-menu" :icon="item.icon" />
              <span>{{ $t(item.title) }}</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu
          v-if="menuItems.monitoring && versionNumber !== '0' && isLive"
          index="3"
          ref="subMenu3"
          :show-timeout="300"
          :hide-timeout="50"
          :popper-offset="0"
          v-show="currentProject && page !== '/open/wheel'"
          v-resize="doRecalc"
        >
          <template #title>
            <v-svg src="heartbeat" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
            <span>{{ $t('Monitoring') }}</span>
          </template>
          <el-menu-item
            v-for="item in menuItems.monitoring"
            :key="item.link"
            :class="{ 'active-item': activeIndex === item.link }"
            :index="item.link"
            @click="goToItem(item)"
          >
            <template #title>
              <font-awesome-icon class="fal-menu" :icon="item.icon" />
              <span>{{ $t(item.title) }}</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <div class="menu-spacer"></div>
        <el-menu-item v-if="auth.authorized" :disabled="page === '/open/wheel'" index="4" @click="showUserProfile">
          <v-svg src="user" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
          <span>{{ $t('Profile') }}</span>
        </el-menu-item>
        <el-sub-menu
          v-if="auth.authorized"
          :show-timeout="300"
          :hide-timeout="50"
          :popper-offset="0"
          index="5"
          ref="subMenu5"
          :disabled="page === '/open/wheel'"
          v-resize="doRecalc"
        >
          <template #title>
            <v-svg src="cog" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>

            <span>{{ $t('Other') }}</span>
          </template>
          <el-menu-item index="5-1-1" @click="showUserSettings">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="cogs" />
              <span>{{ $t('Preferences') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item v-if="commissioning" index="5-1-2" @click="srcMacNull">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="network-wired" />
              <span>{{ $t('Set srcMac default') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item v-if="currentProject" index="5-2" @click="showUserInvite">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="user-circle" />
              <span>{{ $t('Invite Users') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item index="5-3" v-if="reports.preparedReportsCount" @click="showPreparedReports">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="envelope" />
              <span>{{ $t('View prepared reports') }} ({{ reports.preparedReportsCount }})</span>
            </template>
          </el-menu-item>
          <el-menu-item v-if="isAdmin" index="/users/manage" @click="goTo({ name: 'users-manage' })">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="address-book" />
              <span>{{ $t('User management') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item v-if="isAdmin" index="/portainer/" @click="goOut({ path: '/portainer/' })">
            <template #title>
              <v-svg src="portainer" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
              <span>{{ $t('Portainer') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item v-if="isAdmin" index="/grafana/" @click="goOut({ path: '/grafana/' })">
            <template #title>
              <v-svg src="grafana" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
              <span>{{ $t('Grafana') }}</span>
            </template>
          </el-menu-item>

          <el-menu-item index="5-5" @click="visual.visibleAboutCompanyModal = true">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="info-circle" />
              <span>{{ $t('About Tekvel') }}</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item v-if="auth.authorized" index="6" @click="doLogout">
          <v-svg src="sign-out-alt" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>

          <span>{{ $t('Logout') }}</span>
        </el-menu-item>
        <el-menu-item v-else-if="page !== '/login'" index="7" @click="goToLink('/login')">
          <v-svg src="sign-in-alt" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>

          <span>{{ $t('Login') }}</span>
        </el-menu-item>
      </el-menu>
      <div v-else class="collapsed-menu">
        <v-icon
          v-if="currentProject"
          size="56"
          class="cursor-pointer"
          @click="goToItem({ action: { name: 'current-project', params: { projectId: currentProject } } })"
        >
          <v-svg src="book-open" width="20" height="14" fill="#949698"></v-svg>
        </v-icon>
        <n-config-provider v-if="menuItems.viewingAndAnalyzing && showSettingsMenu" :theme="theme">
          <n-popover
            trigger="hover"
            placement="right-start"
            :overlap="false"
            class="nav-top-black-popup"
            :show-arrow="false"
            raw
            ref="menuViewingAndAnalyzing"
          >
            <template #trigger>
              <v-icon size="56" class="cursor-pointer">
                <v-svg src="eye" width="20" height="14" fill="#949698"></v-svg>
              </v-icon>
            </template>
            <div class="pop-body">
              <el-menu
                v-if="menuItems.viewingAndAnalyzing"
                index="2"
                :show-timeout="300"
                :hide-timeout="50"
                :popper-offset="0"
                v-show="currentProject && page !== '/open/wheel'"
                v-resize="doRecalc"
                @select="menuViewingAndAnalyzingTriggered"
              >
                <template #title>
                  <v-svg src="eye" fill="#949698" cc="fal-menu"></v-svg>
                  <span>{{ $t('Viewing and analysis') }}</span>
                </template>
                <el-menu-item
                  v-for="item in menuItems.viewingAndAnalyzing"
                  class="el-menu-item-nested"
                  :class="{ 'active-item': activeIndex === item.link }"
                  :key="item.link"
                  :index="item.link"
                  @click="goToItem(item)"
                >
                  <template #title>
                    <font-awesome-icon class="fal-menu" :icon="item.icon" />
                    <span>{{ $t(item.title) }}</span>
                  </template>
                </el-menu-item>
              </el-menu>
            </div>
          </n-popover>
        </n-config-provider>
        <n-config-provider v-if="menuItems.monitoring && versionNumber !== '0' && isLive" :theme="theme">
          <n-popover
            trigger="hover"
            placement="right-start"
            :overlap="false"
            class="nav-top-black-popup"
            :show-arrow="false"
            raw
            ref="menuMonitoring"
          >
            <template #trigger>
              <v-icon size="56" class="cursor-pointer">
                <v-svg src="heartbeat" width="20" height="14" fill="#949698"></v-svg>
              </v-icon>
            </template>
            <div class="pop-body">
              <el-menu
                v-if="menuItems.monitoring && versionNumber !== '0' && isLive"
                index="3"
                :show-timeout="300"
                :hide-timeout="50"
                :popper-offset="0"
                v-show="currentProject && page !== '/open/wheel'"
                v-resize="doRecalc"
                @select="menuMonitoringTriggered"
              >
                <el-menu-item
                  v-for="item in menuItems.monitoring"
                  :key="item.link"
                  class="el-menu-item-nested"
                  :class="{ 'active-item': activeIndex === item.link }"
                  :index="item.link"
                  @click="goToItem(item)"
                >
                  <template #title>
                    <font-awesome-icon class="fal-menu" :icon="item.icon" />
                    <span>{{ $t(item.title) }}</span>
                  </template>
                </el-menu-item>
              </el-menu>
            </div>
          </n-popover>
        </n-config-provider>
        <div class="menu-spacer"></div>
        <v-icon v-if="auth.authorized" size="56" class="cursor-pointer" @click="showUserProfile">
          <v-svg src="user" width="20" height="14" fill="#949698"></v-svg>
        </v-icon>
        <n-config-provider v-if="showSettingsMenu" :theme="theme">
          <n-popover
            trigger="hover"
            placement="right-start"
            :overlap="false"
            class="nav-top-black-popup"
            :show-arrow="false"
            raw
            ref="menuSettings"
          >
            <template #trigger>
              <v-icon size="56" class="cursor-pointer">
                <v-svg src="cog" width="20" height="14" fill="#949698"></v-svg>
              </v-icon>
            </template>
            <div class="pop-body">
              <el-menu index="1" v-if="page !== '/open/wheel'" @select="menuSettingsTriggered">
                <el-menu-item class="el-menu-item-nested" index="1-1" @click="showUserSettings">
                  <template #title>
                    <font-awesome-icon class="fal-menu" icon="cogs" />
                    <span>{{ $t('Preferences') }}</span>
                  </template>
                </el-menu-item>
                <el-menu-item v-if="commissioning"  class="el-menu-item-nested" index="1-1-1" @click="srcMacNull">
                  <template #title>
                    <font-awesome-icon class="fal-menu" icon="network-wired" />
                    <span>{{ $t('Set srcMac default') }}</span>
                  </template>
                </el-menu-item>

                <el-menu-item v-if="currentProject" class="el-menu-item-nested" index="1-2" @click="showUserInvite">
                  <template #title>
                    <font-awesome-icon class="fal-menu" icon="user-circle" />
                    <span>{{ $t('Invite Users') }}</span>
                  </template>
                </el-menu-item>
                <el-menu-item
                  class="el-menu-item-nested"
                  index="1-3"
                  v-if="reports.preparedReportsCount"
                  @click="showPreparedReports"
                >
                  <template #title>
                    <font-awesome-icon class="fal-menu" icon="envelope" />
                    <span>{{ $t('View prepared reports') }} {{ reports.preparedReportsCount }}</span>
                  </template>
                </el-menu-item>
                <el-menu-item
                  v-if="isAdmin"
                  class="el-menu-item-nested"
                  index="/users/manage"
                  @click="goTo({ name: 'users-manage' })"
                >
                  <template #title>
                    <font-awesome-icon class="fal-menu" icon="address-book" />
                    <span> {{ $t('User management') }} </span>
                  </template>
                </el-menu-item>
                <el-menu-item v-if="isAdmin" class="el-menu-item-nested" index="/portainer/" @click="goOut({ path: '/portainer/' })">
                  <template #title>
                    <v-svg src="portainer" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
                    <span>{{ $t('Portainer') }}</span>
                  </template>
                </el-menu-item>
                <el-menu-item v-if="isAdmin" class="el-menu-item-nested" index="/grafana/" @click="goOut({ path: '/grafana/' })">
                  <template #title>
                    <v-svg src="grafana" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
                    <span>{{ $t('Grafana') }}</span>
                  </template>
                </el-menu-item>

                <el-menu-item @click="visual.visibleAboutCompanyModal = true" class="el-menu-item-nested" index="1-5">
                  <template #title>
                    <font-awesome-icon class="fal-menu" icon="info-circle" />
                    <span> {{ $t('About Tekvel') }} </span>
                  </template>
                </el-menu-item>
              </el-menu>
            </div>
          </n-popover>
        </n-config-provider>
        <v-icon v-if="auth.authorized" size="56" class="cursor-pointer" @click="doLogout">
          <v-svg src="sign-out-alt" width="20" height="14" fill="#949698"></v-svg>
        </v-icon>
        <v-icon v-else size="56" class="cursor-pointer" @click="goToLink('/login')">
          <v-svg src="sign-in-alt" width="20" height="14" fill="#949698"></v-svg>
        </v-icon>
      </div>
    </simplebar-vue>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useTpws } from '@/stores/tpws';
import { useTpui } from '@/stores/tpui';
import { useReports } from '@/stores/reports';
import { useAuth } from '@/stores/auth';

import { menu } from '@/mixins/menu';
import SimplebarVue from '@/components/common/SimplebarVue.vue';
import { mapActions } from 'pinia/dist/pinia';
import { darkTheme } from 'naive-ui';
// import resize from 'vue-resize-directive';
// import { ElMenu, ElSubMenu, ElMenuItem } from 'element-plus';

export default {
  name: 'vertical-menu',
  mixins: [menu],
  components: {
    SimplebarVue,
    // ElMenu,
    // ElSubMenu,
    // ElMenuItem,
  },
  setup() {
    // const visualStore = useVisualStore()
    // return { visual: visualStore }
  },
  directives: {
    // resize,
  },
  data() {
    return {
      widthSideBar: '220px',
      theme: darkTheme,
      overlap: false,
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useTpws),
    ...mapStores(useTpui),
    ...mapStores(useReports),
    ...mapStores(useAuth),
    currentProject() {
      return this.tpws.currentProject;
    },
    isAdmin() {
      return this.auth.isAdmin;
    },
    sidebarCollapsed() {
      return this.visual.sidebarCollapsed;
    },
    showSettingsMenu() {
      return this.page !== '/open/wheel' && this.auth.user;
    },
  },
  watch: {
    'visual.sidebarCollapsed'(newVal) {
      dev.log('visual.sidebarCollapsed', newVal);
      this.widthSideBar = newVal ? '64px' : '220px';
    },
  },
  mounted() {
    if (this.page === '/open/wheel') {
      // this.$store.commit('closeSidebarCollapse');
      this.visual.closeCollapseSidebar();
    }
  },
  methods: {
    // ...mapActions(useReports, ['showPreparedReports']),
    goTo(route) {
      this.$router.push(route).catch((err) => {
        dev.log('r err', err);
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
    menuViewingAndAnalyzingTriggered(e) {
      dev.log('menuTriggered', e);
      this.$refs.menuViewingAndAnalyzing.setShow(false);
    },
    menuMonitoringTriggered(e) {
      dev.log('menuTriggered', e);
      this.$refs.menuMonitoring.setShow(false);
    },
    menuSettingsTriggered(e) {
      dev.log('menuTriggered', e);
      this.$refs.menuSettings.setShow(false);
    },
    handleOpen(e) {
      dev.log('e', e);
    },
    doRecalc() {
      const simpleBar = this.$refs.menuSb.simpleBar;
      simpleBar.recalculate();
    },
    handleSubmenuSelection(i, p, e) {
      dev.log('handleSubmenuSelection', i, p, e);
      // e && e.$parent._name === '<ElSubmenu>' && e.$parent.handleMouseleave();
      // this.$store.commit('setSidebarCollapsed', true);
    },
  },
};
</script>

<style lang="scss">
.sidebar-left {
  .collapsed-menu {
    height: 100%;
    flex: 1;
    overflow-y: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .menu-spacer {
      flex: 1;
    }
  }
}

.dark .simplebar-scrollbar::before {
  background-color: rgba(0, 0, 0, 0.77);
}
.sidebar-left:not(.sidebar-left--collapsed) {
  & .dark .simplebar-content-wrapper,
  & .dark .simplebar-content {
    display: flex;
    min-width: 310px;
  }
  .menu-wrapper {
    display: flex;
    min-width: 310px;
    height: 100%;
    /*flex: 1;*/
    overflow-y: visible;
  }
}
.dark .simplebar-content {
  height: 100%;
  flex: 1;
  overflow-y: visible;
}
.sidebar-nav-menu {
  height: 100%;
  flex: 1;
  overflow-y: visible;
}
.sidebar-left .sidebar-nav-menu {
  height: 100%;
  flex: 1;
  overflow-y: visible;
}
.dark {
  height: calc(100vh - 56px);
  /*padding-right: 16px;*/
}
</style>
