import api from '@/services/api';
import { defineStore } from 'pinia';

import Dygraph from '../../../vendor/dygraph';

import { usePrefs } from '@/stores/prefs';
import DateTpui from '@/utils/tpui.date.time';

let PRIMARY_BOOLEAN_HEIGHT_FALSE = 20;
let PRIMARY_BOOLEAN_HEIGHT_TRUE = 100;
let SECONDARY_BOOLEAN_HEIGHT_FALSE = 20;
let SECONDARY_BOOLEAN_HEIGHT_TRUE = 80;
const QUALITY_MASK = 0x3; // 0b0000000000000011
const GOOD_STATE = 0,
  TTL_STATE = 1,
  TIMEOUT_STATE = 2,
  TEST_STATE = 3,
  DSTR_ERR_STATE = 4,
  DISCONNECT_STATE = 5,
  MAINTENANCE_STATE = 6,
  GHOST_STATE = 7,
  UNKNOWN_STATE = 15;
const Q_GOOD_COLOR = '#00ad4b',
  Q_QUESTIONABLE_COLOR = '#fff151',
  Q_BAD_COLOR = '#ff4e45',
  Q_RESERVED_COLOR = 'gray',
  Q_UNKNOWN_COLOR = '#777',
  Q_TEST_BACKGROUND = 'rgba(244, 209, 66, 0.3)';

const getColorByValidity = (q) => {
  return q.isValid
    ? Q_GOOD_COLOR
    : q.isInvalid
    ? Q_BAD_COLOR
    : q.isQuestionable
    ? Q_QUESTIONABLE_COLOR
    : Q_RESERVED_COLOR;
};

const reverseString = (str) => {
  return str.split('').reverse().join('');
};

class Quality {
  constructor(string = '1000000000000') {
    this._qString = reverseString(string);
    this._VALID = 0;
    this._INVALID = 2;
    this._QUESTIONABLE = 3;
    this._RESERVED = 1;
    this._SOURCE_PROCESS = 0;
    this._SOURCE_SUBSTITUTED = 1;
  }

  get isValid() {
    return parseInt(this._qString.substring(this._qString.length - 2), 2) == this._VALID;
  }

  get isInvalid() {
    return parseInt(this._qString.substring(this._qString.length - 2), 2) == this._INVALID;
  }

  get isQuestionable() {
    return parseInt(this._qString.substring(this._qString.length - 2), 2) == this._QUESTIONABLE;
  }

  get isTest() {
    return parseInt(this._qString[this._qString.length - 12], 2) == 1;
  }

  get isSubstituted() {
    return parseInt(this._qString[this._qString.length - 11], 2) == this._SOURCE_SUBSTITUTED;
  }
}

const getQuality = (q1, q2) => {
  return q1 ? new Quality(q1) : q2 ? new Quality(q2) : new Quality(undefined);
};

const convertQualityToStrokeStyle = (q) => {
  var strokeStyle;

  switch (q & QUALITY_MASK) {
    case 0:
      strokeStyle = Q_GOOD_COLOR;
      break;
    case 1:
      strokeStyle = Q_BAD_COLOR;
      break;
    case 3:
      strokeStyle = Q_QUESTIONABLE_COLOR;
      break;
    case 2:
    default:
      strokeStyle = Q_RESERVED_COLOR;
      break;
  }

  return strokeStyle;
};

const getControlPoints = (p0, p1, p2, opt_alpha, opt_allowFalseExtrema) => {
  var alpha = opt_alpha !== undefined ? opt_alpha : 1 / 3; // 0=no smoothing, 1=crazy smoothing
  var allowFalseExtrema = opt_allowFalseExtrema || false;

  if (!p2) {
    return [p1.x, p1.y, null, null];
  }

  // Step 1: Position the control points along each line segment.
  var l1x = (1 - alpha) * p1.x + alpha * p0.x,
    l1y = (1 - alpha) * p1.y + alpha * p0.y,
    r1x = (1 - alpha) * p1.x + alpha * p2.x,
    r1y = (1 - alpha) * p1.y + alpha * p2.y;

  // Step 2: shift the points up so that p1 is on the l1–r1 line.
  if (l1x != r1x) {
    // This can be derived w/ some basic algebra.
    var deltaY = p1.y - r1y - ((p1.x - r1x) * (l1y - r1y)) / (l1x - r1x);
    l1y += deltaY;
    r1y += deltaY;
  }

  // Step 3: correct to avoid false extrema.
  if (!allowFalseExtrema) {
    if (l1y > p0.y && l1y > p1.y) {
      l1y = Math.max(p0.y, p1.y);
      r1y = 2 * p1.y - l1y;
    } else if (l1y < p0.y && l1y < p1.y) {
      l1y = Math.min(p0.y, p1.y);
      r1y = 2 * p1.y - l1y;
    }

    if (r1y > p1.y && r1y > p2.y) {
      r1y = Math.max(p1.y, p2.y);
      l1y = 2 * p1.y - r1y;
    } else if (r1y < p1.y && r1y < p2.y) {
      r1y = Math.min(p1.y, p2.y);
      l1y = 2 * p1.y - r1y;
    }
  }

  return [l1x, l1y, r1x, r1y];
};

const isOK = (x) => {
  return !!x && !isNaN(x);
};

const smoothPlotter = (e) => {
  var ctx = e.drawingContext,
    points = e.points;
  points.sort(function (a, b) {
    return a.xval - b.xval;
  });

  ctx.beginPath();
  ctx.moveTo(points[0].canvasx, points[0].canvasy);

  // right control point for previous point
  var lastRightX = points[0].canvasx,
    lastRightY = points[0].canvasy;

  // console.log('points.length', points.length)
  for (var i = 1; i < points.length; i++) {
    var p0 = points[i - 1],
      p1 = points[i],
      p2 = points[i + 1];
    p0 = p0 && isOK(p0.canvasy) ? p0 : null;
    p1 = p1 && isOK(p1.canvasy) ? p1 : null;
    p2 = p2 && isOK(p2.canvasy) ? p2 : null;
    if (p0 && p1) {
      var controls = getControlPoints(
        { x: p0.canvasx, y: p0.canvasy },
        { x: p1.canvasx, y: p1.canvasy },
        p2 && { x: p2.canvasx, y: p2.canvasy },
        smoothPlotter.smoothing
      );
      // Uncomment to show the control points:
      // ctx.lineTo(lastRightX, lastRightY);
      // ctx.lineTo(controls[0], controls[1]);
      // ctx.lineTo(p1.canvasx, p1.canvasy);
      lastRightX = lastRightX !== null ? lastRightX : p0.canvasx;
      lastRightY = lastRightY !== null ? lastRightY : p0.canvasy;
      ctx.bezierCurveTo(lastRightX, lastRightY, controls[0], controls[1], p1.canvasx, p1.canvasy);
      lastRightX = controls[2];
      lastRightY = controls[3];
    } else if (p1) {
      // We're starting again after a missing point.
      ctx.moveTo(p1.canvasx, p1.canvasy);
      lastRightX = p1.canvasx;
      lastRightY = p1.canvasy;
    } else {
      lastRightX = lastRightY = null;
    }
  }

  ctx.stroke();
};

smoothPlotter.smoothing = 1 / 3;
smoothPlotter._getControlPoints = getControlPoints;

const arraysAreEqual = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  var i = a.length;
  if (i !== b.length) return false;
  while (i--) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
const attachZoomHandlers = (gs, syncOpts, prevCallbacks) => {
  dev.log('attachZoomHandlers', gs, syncOpts, prevCallbacks);
  var block = false;
  for (var i = 0; i < gs.length; i++) {
    var g = gs[i];
    try {
      g.updateOptions(
        {
          drawCallback: function (me, initial) {
            if (block || initial) return;
            block = true;
            var opts = {
              dateWindow: me.xAxisRange(),
            };
            if (syncOpts.range) opts.valueRange = me.yAxisRange();

            for (var j = 0; j < gs.length; j++) {
              if (gs[j] == me) {
                if (prevCallbacks[j] && prevCallbacks[j].drawCallback) {
                  prevCallbacks[j].drawCallback.apply(this, arguments);
                }
                continue;
              }

              // Only redraw if there are new options
              if (
                arraysAreEqual(opts.dateWindow, gs[j].getOption('dateWindow')) &&
                arraysAreEqual(opts.valueRange, gs[j].getOption('valueRange'))
              ) {
                continue;
              }

              gs[j].updateOptions(opts);
            }
            block = false;
          },
        },
        true /* no need to redraw */
      );
    } catch (e) {
      dev.log('zoom err', e);
    }
  }
};
const attachSelectionHandlers = (gs, prevCallbacks) => {
  var block = false;
  for (var i = 0; i < gs.length; i++) {
    var g = gs[i];

    g.updateOptions(
      {
        highlightCallback: function (event, x, points, row, seriesName) {
          if (block) return;
          block = true;
          var me = this;
          for (var i = 0; i < gs.length; i++) {
            if (me == gs[i]) {
              if (prevCallbacks[i] && prevCallbacks[i].highlightCallback) {
                prevCallbacks[i].highlightCallback.apply(this, arguments);
              }
              continue;
            }
            var idx = gs[i].getRowForX(x);
            if (idx !== null) {
              gs[i].setSelection(idx, seriesName);
            }
          }
          block = false;
        },
        unhighlightCallback: function (event) {
          if (block) return;
          block = true;
          var me = this;
          for (var i = 0; i < gs.length; i++) {
            if (me == gs[i]) {
              if (prevCallbacks[i] && prevCallbacks[i].unhighlightCallback) {
                prevCallbacks[i].unhighlightCallback.apply(this, arguments);
              }
              continue;
            }
            gs[i].clearSelection();
          }
          block = false;
        },
      },
      true /* no need to redraw */
    );
  }
};
const dygraphsBinarySearch = (g, xVal) => {
  var low = 0,
    high = g.numRows() - 1;

  while (low <= high) {
    var idx = (high + low) >> 1;
    var x = g.getValue(idx, 0);
    if (x < xVal) {
      low = idx + 1;
    } else if (x > xVal) {
      high = idx - 1;
    } else {
      return idx;
    }
  }

  // TODO: give an option to find the closest point, i.e. not demand an exact match.
  return null;
};

export const useDygraphTpui = defineStore({
  id: 'dygraphTpui',
  state: () => {
    return {
      _NO_GRANULARITIE: -1,
      NUM_GRANULARITIES: 35,
      MICROSECONDLY_5: 0,
      MICROSECONDLY_10: 1,
      MICROSECONDLY_50: 2,
      MICROSECONDLY_100: 3,
      MICROSECONDLY_250: 4,
      MICROSECONDLY_500: 5,
      MILLISECONDLY_1: 6,
      MILLISECONDLY_5: 7,
      MILLISECONDLY_10: 8,
      MILLISECONDLY_50: 9,
      MILLISECONDLY_100: 10,
      MILLISECONDLY_250: 11,
      MILLISECONDLY_500: 12,
      SECONDLY: 13,
      TWO_SECONDLY: 14,
      FIVE_SECONDLY: 15,
      TEN_SECONDLY: 16,
      THIRTY_SECONDLY: 17,
      MINUTELY: 18,
      TWO_MINUTELY: 19,
      FIVE_MINUTELY: 20,
      TEN_MINUTELY: 21,
      THIRTY_MINUTELY: 22,
      HOURLY: 23,
      TWO_HOURLY: 24,
      SIX_HOURLY: 25,
      DAILY: 26,
      TWO_DAILY: 27,
      WEEKLY: 28,
      MONTHLY: 29,
      QUARTERLY: 30,
      BIANNUAL: 31,
      ANNUAL: 32,
      DECADAL: 33,
      CENTENNIAL: 34,
      NUM_DATEFIELDS: 8,
      DATEFIELD_Y: 0,
      DATEFIELD_M: 1,
      DATEFIELD_D: 2,
      DATEFIELD_HH: 3,
      DATEFIELD_MM: 4,
      DATEFIELD_SS: 5,
      DATEFIELD_MS: 6,
      DATEFIELD_US: 7,
      TICK_PLACEMENT: [],
      _supportedCdcs: ['ACT', 'ACD', 'SPS', 'SPC', 'DPC'],
    };
  },
  getters: {
    DateAccessorsUTC: (state) => ({
      getFullYear: function (d) {
        dev.log('d', d, d.getTime());
        return d.date.getUTCFullYear();
      },
      getMonth: function (d) {
        return d.date.getUTCMonth();
      },
      getDate: function (d) {
        return d.date.getUTCDate();
      },
      getHours: function (d) {
        return d.date.getUTCHours();
      },
      getMinutes: function (d) {
        return d.date.getUTCMinutes();
      },
      getSeconds: function (d) {
        return d.date.getUTCSeconds();
      },
      getMilliseconds: function (d) {
        return d.date.getUTCMilliseconds();
      },
      getMicroseconds: function (d) {
        return d.us;
      },
      getDay: function (d) {
        return d.date.getUTCDay();
      },
      makeDate: function (y, m, d, hh, mm, ss, ms, us) {
        return new DateTpui(Date.UTC(y, m, d, hh, mm, ss, ms) * 1e3 + us);
      },
    }),
    DateAccessorsLocal: (state) => ({
      getFullYear: function (d) {
        return d.date.getFullYear();
      },
      getMonth: function (d) {
        return d.date.getMonth();
      },
      getDate: function (d) {
        return d.date.getDate();
      },
      getHours: function (d) {
        return d.date.getHours();
      },
      getMinutes: function (d) {
        return d.date.getMinutes();
      },
      getSeconds: function (d) {
        return d.date.getSeconds();
      },
      getMilliseconds: function (d) {
        return d.date.getMilliseconds();
      },
      getMicroseconds: function (d) {
        return d.us;
      },
      getDay: function (d) {
        return d.date.getDay();
      },
      makeDate: function (y, m, d, hh, mm, ss, ms, us) {
        return new DateTpui(y, m, d, hh, mm, ss, ms, us);
      },
    }),
  },
  actions: {
    initDygraphTpui() {
      this.TICK_PLACEMENT[this.MICROSECONDLY_5] = {
        datefield: this.DATEFIELD_US,
        step: 5,
        spacing: 5,
      };
      this.TICK_PLACEMENT[this.MICROSECONDLY_10] = {
        datefield: this.DATEFIELD_US,
        step: 10,
        spacing: 10,
      };
      this.TICK_PLACEMENT[this.MICROSECONDLY_50] = {
        datefield: this.DATEFIELD_US,
        step: 50,
        spacing: 50,
      };
      this.TICK_PLACEMENT[this.MICROSECONDLY_100] = {
        datefield: this.DATEFIELD_US,
        step: 100,
        spacing: 100,
      };
      this.TICK_PLACEMENT[this.MICROSECONDLY_250] = {
        datefield: this.DATEFIELD_US,
        step: 200,
        spacing: 200,
      };
      this.TICK_PLACEMENT[this.MICROSECONDLY_500] = {
        datefield: this.DATEFIELD_US,
        step: 500,
        spacing: 500,
      };
      this.TICK_PLACEMENT[this.MILLISECONDLY_1] = {
        datefield: this.DATEFIELD_MS,
        step: 1,
        spacing: 1 * 1e3,
      };
      this.TICK_PLACEMENT[this.MILLISECONDLY_5] = {
        datefield: this.DATEFIELD_MS,
        step: 5,
        spacing: 5 * 1e3,
      };
      this.TICK_PLACEMENT[this.MILLISECONDLY_10] = {
        datefield: this.DATEFIELD_MS,
        step: 10,
        spacing: 10 * 1e3,
      };
      this.TICK_PLACEMENT[this.MILLISECONDLY_50] = {
        datefield: this.DATEFIELD_MS,
        step: 50,
        spacing: 50 * 1e3,
      };
      this.TICK_PLACEMENT[this.MILLISECONDLY_100] = {
        datefield: this.DATEFIELD_MS,
        step: 100,
        spacing: 100 * 1e3,
      };
      this.TICK_PLACEMENT[this.MILLISECONDLY_250] = {
        datefield: this.DATEFIELD_MS,
        step: 250,
        spacing: 250 * 1e3,
      };
      this.TICK_PLACEMENT[this.MILLISECONDLY_500] = {
        datefield: this.DATEFIELD_MS,
        step: 500,
        spacing: 500 * 1e3,
      };
      this.TICK_PLACEMENT[this.SECONDLY] = {
        datefield: this.DATEFIELD_SS,
        step: 1,
        spacing: 1000 * 1 * 1e3,
      };
      this.TICK_PLACEMENT[this.TWO_SECONDLY] = {
        datefield: this.DATEFIELD_SS,
        step: 2,
        spacing: 1000 * 2 * 1e3,
      };
      this.TICK_PLACEMENT[this.FIVE_SECONDLY] = {
        datefield: this.DATEFIELD_SS,
        step: 5,
        spacing: 1000 * 5 * 1e3,
      };
      this.TICK_PLACEMENT[this.TEN_SECONDLY] = {
        datefield: this.DATEFIELD_SS,
        step: 10,
        spacing: 1000 * 10 * 1e3,
      };
      this.TICK_PLACEMENT[this.THIRTY_SECONDLY] = {
        datefield: this.DATEFIELD_SS,
        step: 30,
        spacing: 1000 * 30 * 1e3,
      };
      this.TICK_PLACEMENT[this.MINUTELY] = {
        datefield: this.DATEFIELD_MM,
        step: 1,
        spacing: 1000 * 60 * 1e3,
      };
      this.TICK_PLACEMENT[this.TWO_MINUTELY] = {
        datefield: this.DATEFIELD_MM,
        step: 2,
        spacing: 1000 * 60 * 2 * 1e3,
      };
      this.TICK_PLACEMENT[this.FIVE_MINUTELY] = {
        datefield: this.DATEFIELD_MM,
        step: 5,
        spacing: 1000 * 60 * 5 * 1e3,
      };
      this.TICK_PLACEMENT[this.TEN_MINUTELY] = {
        datefield: this.DATEFIELD_MM,
        step: 10,
        spacing: 1000 * 60 * 10 * 1e3,
      };
      this.TICK_PLACEMENT[this.THIRTY_MINUTELY] = {
        datefield: this.DATEFIELD_MM,
        step: 30,
        spacing: 1000 * 60 * 30 * 1e3,
      };
      this.TICK_PLACEMENT[this.HOURLY] = {
        datefield: this.DATEFIELD_HH,
        step: 1,
        spacing: 1000 * 3600 * 1e3,
      };
      this.TICK_PLACEMENT[this.TWO_HOURLY] = {
        datefield: this.DATEFIELD_HH,
        step: 2,
        spacing: 1000 * 3600 * 2 * 1e3,
      };
      this.TICK_PLACEMENT[this.SIX_HOURLY] = {
        datefield: this.DATEFIELD_HH,
        step: 6,
        spacing: 1000 * 3600 * 6 * 1e3,
      };
      this.TICK_PLACEMENT[this.DAILY] = {
        datefield: this.DATEFIELD_D,
        step: 1,
        spacing: 1000 * 86400 * 1e3,
      };
      this.TICK_PLACEMENT[this.TWO_DAILY] = {
        datefield: this.DATEFIELD_D,
        step: 2,
        spacing: 1000 * 86400 * 2 * 1e3,
      };
      this.TICK_PLACEMENT[this.WEEKLY] = {
        datefield: this.DATEFIELD_D,
        step: 7,
        spacing: 1000 * 604800 * 1e3,
      };
      this.TICK_PLACEMENT[this.MONTHLY] = {
        datefield: this.DATEFIELD_M,
        step: 1,
        spacing: 1000 * 7200 * 365.2524 * 1e3,
      }; // 1e3 * 60 * 60 * 24 * 365.2524 / 12
      this.TICK_PLACEMENT[this.QUARTERLY] = {
        datefield: this.DATEFIELD_M,
        step: 3,
        spacing: 1000 * 21600 * 365.2524 * 1e3,
      }; // 1e3 * 60 * 60 * 24 * 365.2524 / 4
      this.TICK_PLACEMENT[this.BIANNUAL] = {
        datefield: this.DATEFIELD_M,
        step: 6,
        spacing: 1000 * 43200 * 365.2524 * 1e3,
      }; // 1e3 * 60 * 60 * 24 * 365.2524 / 2
      this.TICK_PLACEMENT[this.ANNUAL] = {
        datefield: this.DATEFIELD_Y,
        step: 1,
        spacing: 1000 * 86400 * 365.2524 * 1e3,
      }; // 1e3 * 60 * 60 * 24 * 365.2524 * 1
      this.TICK_PLACEMENT[this.DECADAL] = {
        datefield: this.DATEFIELD_Y,
        step: 10,
        spacing: 1000 * 864000 * 365.2524 * 1e3,
      }; // 1e3 * 60 * 60 * 24 * 365.2524 * 10
      this.TICK_PLACEMENT[this.CENTENNIAL] = {
        datefield: this.DATEFIELD_Y,
        step: 100,
        spacing: 1000 * 8640000 * 365.2524 * 1e3,
      }; // 1e3 * 60 * 60 * 24 * 365.2524 * 100
    },
    synchronize(/* dygraphs..., opts */) {
      dev.log('arguments', arguments);
      if (arguments.length === 0) {
        throw 'Invalid invocation of Dygraph.synchronize(). Need >= 1 argument.';
      }

      var OPTIONS = ['selection', 'zoom', 'range'];
      var opts = {
        selection: true,
        zoom: true,
        range: true,
      };
      var dygraphs = [];
      var prevCallbacks = [];

      dev.log('OPTIONS', OPTIONS);

      var parseOpts = function (obj) {
        if (!(obj instanceof Object)) {
          throw 'Last argument must be either Dygraph or Object.';
        } else {
          for (var i = 0; i < OPTIONS.length; i++) {
            var optName = OPTIONS[i];
            if (obj.hasOwnProperty(optName)) opts[optName] = obj[optName];
          }
        }
      };

      if (arguments[0] instanceof Dygraph) {
        // Arguments are Dygraph objects.
        for (var i = 0; i < arguments.length; i++) {
          if (arguments[i] instanceof Dygraph) {
            dygraphs.push(arguments[i]);
          } else {
            break;
          }
        }
        if (i < arguments.length - 1) {
          throw 'Invalid invocation of Dygraph.synchronize(). ' + 'All but the last argument must be Dygraph objects.';
        } else if (i == arguments.length - 1) {
          parseOpts(arguments[arguments.length - 1]);
        }
      } else if (arguments[0].length) {
        // Invoked w/ list of dygraphs, options
        for (var i = 0; i < arguments[0].length; i++) {
          dygraphs.push(arguments[0][i]);
        }
        if (arguments.length == 2) {
          parseOpts(arguments[1]);
        } else if (arguments.length > 2) {
          throw (
            'Invalid invocation of Dygraph.synchronize(). ' +
            'Expected two arguments: array and optional options argument.'
          );
        } // otherwise arguments.length == 1, which is fine.
      } else {
        throw (
          'Invalid invocation of Dygraph.synchronize(). ' +
          'First parameter must be either Dygraph or list of Dygraphs.'
        );
      }

      if (dygraphs.length < 2) {
        throw 'Invalid invocation of Dygraph.synchronize(). ' + 'Need two or more dygraphs to synchronize.';
      }

      dev.log('dygraphs', dygraphs);

      var readycount = dygraphs.length;
      for (var i = 0; i < dygraphs.length; i++) {
        var g = dygraphs[i];
        g.ready(function () {
          if (--readycount == 0) {
            // store original callbacks
            var callBackTypes = ['drawCallback', 'highlightCallback', 'unhighlightCallback'];
            for (var j = 0; j < dygraphs.length; j++) {
              if (!prevCallbacks[j]) {
                prevCallbacks[j] = {};
              }
              for (var k = callBackTypes.length - 1; k >= 0; k--) {
                prevCallbacks[j][callBackTypes[k]] = dygraphs[j].getFunctionOption(callBackTypes[k]);
              }
            }

            // Listen for draw, highlight, unhighlight callbacks.
            if (opts.zoom) {
              attachZoomHandlers(dygraphs, opts, prevCallbacks);
            }

            if (opts.selection) {
              attachSelectionHandlers(dygraphs, prevCallbacks);
            }
          }
        });
      }

      return {
        detach: function () {
          for (var i = 0; i < dygraphs.length; i++) {
            var g = dygraphs[i];
            if (opts.zoom) {
              g.updateOptions({ drawCallback: prevCallbacks[i].drawCallback });
            }
            if (opts.selection) {
              g.updateOptions({
                highlightCallback: prevCallbacks[i].highlightCallback,
                unhighlightCallback: prevCallbacks[i].unhighlightCallback,
              });
            }
          }
          // release references & make subsequent calls throw.
          dygraphs = null;
          opts = null;
          prevCallbacks = null;
        },
      };
    },
    metaConverter(m) {
      // // Quality is BIG Endian: "0100000000000". Bits: 0,1,2, .. 12. Total: 13
      m.q = parseInt(m.q, 2) >> 11;
    },
    valueConverter(value) {
      let val;
      //console.log(value)
      switch (value) {
        // BOOLEANS
        case 'true':
          val = 1;
          break;
        case 'false':
          val = 0;
          break;

        // DOUBLE POINTS
        case '00':
          val = 0;
          break;
        case '01':
          val = 1;
          break;
        case '10':
          val = 2;
          break;
        case '11':
          val = 3;
          break;

        // INTEGERS
        case '0':
          val = 0;
          break;
        case '1':
          val = 1;
          break;
        case '2':
          val = 2;
          break;
        case '3':
          val = 3;
          break;
        default:
          val = null;
          break;
      }
      return val;
    },
    splitData(data, cdc) {
      var dots_arr = [],
        state_arr = [],
        meta = [],
        plotter = [],
        labels = [],
        series = {},
        valueRange = [],
        yticker,
        quality = {};
      let q_arr = [];
      let subQ_arr = [];

      data.sort((a, b) => {
        return a.ts - b.ts;
      });

      for (var i = 0; i < data.length; i++) {
        let val = {};
        let temp_val = 0;

        let dot_vals = [];
        let sate_vals = [];

        switch (cdc) {
          case 'SPS':
            /**
             * This class shall contain the following mandatory data attributes:
             ** stVal [BOOLEAN]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** subEna [BOOLEAN]
             ** subVal [BOOLEAN]
             ** subQ [Quality]
             ** subID [VISIBLE STRING64]
             ** blkEna [BOOLEAN]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.stVal = data[i].stVal ? this.valueConverter(data[i].stVal) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            if (val.stVal === null) val.stVal = -1;
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data
            val.subEna = this.valueConverter(data[i].subEna);
            val.subVal = this.valueConverter(data[i].subVal);
            val.subQ = new Quality(data[i].subQ);
            val.blkEna = this.valueConverter(data[i].blkEna);

            sate_vals = {
              stVal: val.stVal,
              subEna: val.subEna,
              subVal: val.subVal,
              blkEna: val.blkEna,
              ts: data[i].ts,
            };

            dot_vals = [
              val.stVal != null ? 7 : null,
              val.subEna != null ? 4 : null,
              val.subVal != null ? 3 : null,
              val.blkEna != null ? 2 : null,
            ];

            // This is for testing purposes
            temp_val = dot_vals;

            break;

          case 'ACT':
            /**
             * This class shall contain the following mandatory data attributes:
             ** general [BOOLEAN]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** phsA [BOOLEAN]
             ** phsB [BOOLEAN]
             ** phsC [BOOLEAN]
             ** neut [BOOLEAN]
             ** originSrc [Originator]
             ** operTmPhsA [TimeStamp]
             ** operTmPhsB [TimeStamp]
             ** operTmPhsB [TimeStamp]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.general = data[i].general ? this.valueConverter(data[i].general) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.phsA = this.valueConverter(data[i].phsA);

            val.phsB = this.valueConverter(data[i].phsB);

            val.phsC = this.valueConverter(data[i].phsC);

            val.neut = this.valueConverter(data[i].neut);

            sate_vals = {
              general: val.general,
              phsA: val.phsA,
              phsB: val.phsB,
              phsC: val.phsC,
              neut: val.neut,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.general != null ? 7 : null,
              val.phsA != null ? 4 : null,
              val.phsB != null ? 3 : null,
              val.phsC != null ? 2 : null,
              val.neut != null ? 1 : null,
            ];

            temp_val = dot_vals;

            break;

          case 'ACD':
            /**
             * This class shall contain the following mandatory data attributes:
             ** general [BOOLEAN]
             ** dirGeneral [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** phsA [BOOLEAN]
             ** dirPhsA [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** phsB [BOOLEAN]
             ** dirPhsB [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** phsC [BOOLEAN]
             ** dirPhsC [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** neut [BOOLEAN]
             ** dirNeut[ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.general = this.valueConverter(data[i].general);
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.dirGeneral = this.valueConverter(data[i].dirGeneral);

            val.phsA = this.valueConverter(data[i].phsA);
            val.dirPhsA = this.valueConverter(data[i].dirPhsA);

            val.phsB = this.valueConverter(data[i].phsB);
            val.dirPhsB = this.valueConverter(data[i].dirPhsB);

            val.phsC = this.valueConverter(data[i].phsC);
            val.dirPhsC = this.valueConverter(data[i].dirPhsC);

            val.neut = this.valueConverter(data[i].neut);
            val.dirNeut = this.valueConverter(data[i].dirNeut);

            sate_vals = {
              general: val.general,
              dirGeneral: val.dirGeneral,
              phsA: val.phsA,
              dirPhsA: val.dirPhsA,
              phsB: val.phsB,
              dirPhsB: val.dirPhsB,
              phsC: val.phsC,
              dirPhsC: val.dirPhsC,
              neut: val.neut,
              dirNeut: val.dirNeut,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.general != null ? 7 : null,
              val.dirGeneral != null ? 7 : null,
              val.phsA != null ? 4 : null,
              val.dirPhsA != null ? 4 : null,
              val.phsB != null ? 3 : null,
              val.dirPhsB != null ? 3 : null,
              val.phsC != null ? 2 : null,
              val.dirPhsC != null ? 2 : null,
              val.neut != null ? 1 : null,
              val.dirNeut != null ? 1 : null,
            ];

            temp_val = dot_vals;
            break;

          case 'SPC':
            /**
             * This class shall contain the following mandatory data attributes:
             ** stVal [BOOLEAN]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** origin [Originator]
             ** ctlNum [INT8U]
             ** stSeld [BOOLEAN]
             ** opRcvd [BOOLEAN]
             ** opOk [BOOLEAN]
             ** tOpOk [Timestamp]
             ** subEna [BOOLEAN]
             ** subVal [BOOLEAN]
             ** subQ [Quality]
             ** subID [VISIBLE STRING64]
             ** blkEna [BOOLEAN]
             ** pulseConfig [PulseConfig]
             ** ctlModel [CtlModels]
             ** sboTimeout [INT32U]
             ** sboClass [SboClasses]
             ** operTimeout [INT32U]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */
            val.stVal = data[i].stVal ? this.valueConverter(data[i].stVal) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.origin = data[i].origin;

            // Control
            val.stSeld = this.valueConverter(data[i].stSeld);
            val.opRcvd = this.valueConverter(data[i].opRcvd);
            val.opOk = this.valueConverter(data[i].opOk);

            // Substition
            val.subEna = this.valueConverter(data[i].subEna);
            val.subVal = this.valueConverter(data[i].subVal);
            val.subQ = new Quality(data[i].subQ);

            // Blocked
            val.blkEna = this.valueConverter(data[i].blkEna);

            sate_vals = {
              stVal: val.stVal,
              stSeld: val.stSeld,
              opRcvd: val.opRcvd,
              opOk: val.opOk,
              subEna: val.subEna,
              subVal: val.subVal,
              blkEna: val.blkEna,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.stVal != null ? 7 : null,
              val.stSeld != null ? 4 : null,
              val.opRcvd != null ? 3 : null,
              val.opOk != null ? 2 : null,
              val.subEna != null ? 1 : null,
              val.subVal != null ? 0 : null,
              val.blkEna != null ? -1 : null,
            ];

            temp_val = dot_vals;

            break;

          case 'DPC':
            /**
             * This class shall contain the following mandatory data attributes:
             ** stVal [CODED ENUM: intermediate-state | off | on | bad-state]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** origin [Originator]
             ** ctlNum [INT8U]
             ** stSeld [BOOLEAN]
             ** opRcvd [BOOLEAN]
             ** opOk [BOOLEAN]
             ** tOpOk [Timestamp]
             ** subEna [BOOLEAN]
             ** subVal [BOOLEAN]
             ** subQ [Quality]
             ** subID [VISIBLE STRING64]
             ** blkEna [BOOLEAN]
             ** pulseConfig [PulseConfig]
             ** ctlModel [CtlModels]
             ** sboTimeout [INT32U]
             ** sboClass [SboClasses]
             ** operTimeout [INT32U]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.stVal = data[i].stVal ? this.valueConverter(data[i].stVal) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.origin = data[i].origin;

            // Control
            val.stSeld = this.valueConverter(data[i].stSeld);
            val.opRcvd = this.valueConverter(data[i].opRcvd);
            val.opOk = this.valueConverter(data[i].opOk);

            // Substition
            val.subEna = this.valueConverter(data[i].subEna);
            val.subVal = this.valueConverter(data[i].subVal);
            val.subQ = new Quality(data[i].subQ);

            // Blocked
            val.blkEna = this.valueConverter(data[i].blkEna);

            sate_vals = {
              stVal: val.stVal,
              stSeld: val.stSeld,
              opRcvd: val.opRcvd,
              opOk: val.opOk,
              subEna: val.subEna,
              subVal: val.subVal,
              blkEna: val.blkEna,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.stVal == null ? null : val.stVal == 0 ? 6 : val.stVal == 1 ? 5 : 5 + val.stVal,
              val.stSeld != null ? 4 : null,
              val.opRcvd != null ? 3 : null,
              val.opOk != null ? 2 : null,
              val.subEna != null ? 1 : null,
              val.subVal != null ? 0 : null,
              val.blkEna != null ? -1 : null,
            ];

            temp_val = dot_vals;

            break;

          default:
            console.warn('Common data class ' + cdc + ' is not supported in this version of Tekvel Park');
            break;
        }

        temp_val.splice(0, 0, data[i].ts);
        //dot_vals.splice(0, 0, data[i].ts);
        dots_arr.push(temp_val);
        state_arr.push(sate_vals);
        meta.push(data[i].meta);
        //console.log(val.q);
        q_arr.push(val.q);
        subQ_arr.push(val.subQ);
        //this.metaConverter(meta[meta.length - 1])
      }

      quality = { q: q_arr, subQ: subQ_arr };

      // Choose plotters based on CDC:
      switch (cdc) {
        case 'SPS':
          // The plotters are as follows: [stVal, subEna, subVal, blkEna]
          //plotters = [
          //    Dygraph.Plotters.stValBooleanPrimary_plotter,
          //    Dygraph.Plotters.subEnaBooleanSecondary_plotter,
          //    Dygraph.Plotters.subValBooleanSecondary_plotter,
          //    Dygraph.Plotters.blkEnaBooleanSecondary_plotter
          //];
          //plotter = Dygraph.Plotters.spsPlotter // [stVal, subEna, subVal, blkEna]

          labels = ['stVal', 'subEna', 'subVal', 'blkEna'];

          series = {
            stVal: {
              plotter: this.lineBoolPlotter,
            },
            subEna: {
              plotter: this.lineBoolPlotter,
            },
            subVal: {
              plotter: this.lineBoolPlotter,
            },
            blkEna: {
              plotter: this.lineBoolPlotter,
              // plotter: Dygraph.Plotters.linePlotter,
            },
          };
          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 7, label: 'stVal' },
              { v: 4, label: 'subEna' },
              { v: 3, label: 'subVal' },
              { v: 2, label: 'blkEna' },
            ];
          };

          break;

        case 'ACT':
          // Protection activation information
          // The plotters are as follows: [val.general, val.phsA, val.phsB, val.phsC, val.neut];

          //plotter = Dygraph.Plotters.linePlotter;
          labels = ['general', 'phsA', 'phsB', 'phsC', 'neut'];
          series = {
            general: {
              plotter: this.lineBoolPlotter,
            },
            phsA: {
              plotter: this.lineBoolPlotter,
            },
            phsB: {
              plotter: this.lineBoolPlotter,
            },
            phsC: {
              plotter: this.lineBoolPlotter,
            },
            neut: {
              plotter: this.lineBoolPlotter,
            },
          };
          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 1, label: 'neut' },
              { v: 2, label: 'phsC' },
              { v: 3, label: 'phsB' },
              { v: 4, label: 'phsA' },
              { v: 7, label: 'general' },
            ];
          };
          break;

        case 'ACD':
          // The plotters are as follows: [val.general, val.dirGeneral, val.phsA, val.dirPhsA, val.phsB, val.dirPhsB, val.phsC, val.dirPhsC, val.neut, val.dirNeut];

          //plotter = Dygraph.Plotters.linePlotter;
          labels = [
            'general',
            'dirGeneral',
            'phsA',
            'dirPhsA',
            'phsB',
            'dirPhsB',
            'phsC',
            'dirPhsC',
            'neut',
            'dirNeut',
          ];
          series = {
            general: {
              plotter: this.lineBoolPlotter,
            },
            dirGeneral: {
              plotter: this.dirPlotter,
            },
            phsA: {
              plotter: this.lineBoolPlotter,
            },
            dirPhsA: {
              plotter: this.dirPlotter,
            },
            phsB: {
              plotter: this.lineBoolPlotter,
            },
            dirPhsB: {
              plotter: this.dirPlotter,
            },
            phsC: {
              plotter: this.lineBoolPlotter,
            },
            dirPhsC: {
              plotter: this.dirPlotter,
            },
            neut: {
              plotter: this.lineBoolPlotter,
            },
            dirNeut: {
              plotter: this.dirPlotter,
            },
          };
          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 1, label: 'neut' },
              { v: 2, label: 'phsC' },
              { v: 3, label: 'phsB' },
              { v: 4, label: 'phsA' },
              { v: 7, label: 'general' },
            ];
          };
          break;

        case 'SPC':
          // The plotters are as follows: [val.stVal, val.stSeld, val.opRcvd, val.opOk, val.subEna, val.subVal, val.subQ, val.blkEna];

          //plotter = Dygraph.Plotters.linePlotter; // [stVal, stSeld, opRcvd, opOk, subEna, subVal, subQ, blkEna]
          labels = ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'];

          series = {
            stVal: {
              plotter: this.lineBoolPlotter,
            },
            stSeld: {
              plotter: this.lineBoolPlotter,
            },
            opRcvd: {
              plotter: this.lineBoolPlotter,
            },
            opOk: {
              plotter: this.lineBoolPlotter,
            },
            subEna: {
              plotter: this.lineBoolPlotter,
            },
            subVal: {
              plotter: this.lineBoolPlotter,
            },
            blkEna: {
              plotter: this.lineBoolPlotter,
            },
          };

          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 7, label: 'stVal' },
              { v: 4, label: 'stSeld' },
              { v: 3, label: 'opRcvd' },
              { v: 2, label: 'opOk' },
              { v: 2, label: 'opOk' },
              { v: 0, label: 'subVal' },
              { v: -1, label: 'blkEna' },
            ];
          };

          break;

        case 'DPC':
          // The plotters are as follows: [val.stVal, val.stSeld, val.opRcvd, val.opOk, val.subEna, val.subVal, val.subQ, val.blkEna];

          //plotter = Dygraph.Plotters.linePlotter; // [stVal, stSeld, opRcvd, opOk, subEna, subVal, subQ, blkEna]
          labels = ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'];

          series = {
            stVal: {
              plotter: this.lineStepPlotter,
            },
            stSeld: {
              plotter: this.lineBoolPlotter,
            },
            opRcvd: {
              plotter: this.lineBoolPlotter,
            },
            opOk: {
              plotter: this.lineBoolPlotter,
            },
            subEna: {
              plotter: this.lineBoolPlotter,
            },
            subVal: {
              plotter: this.lineBoolPlotter,
            },
            blkEna: {
              plotter: this.lineBoolPlotter,
            },
          };

          valueRange = [4, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 6, label: 'Intermediate' },
              { v: 5, label: 'Off' },
              { v: 7, label: 'On' },
              { v: 8, label: 'Invalid' },
              // {v: 4, label: "stSeld"},
              // {v: 3, label: "opRcvd"},
              // {v: 2, label: "opOk"},
              // {v: 2, label: "opOk"},
              // {v: 0, label: "subVal"},
              // {v: -1, label: "blkEna"}
            ];
          };

          break;

        default:
          // for all other cases simply try to map all available data to line plotters
          plotter = Dygraph.Plotters.linePlotter;
      }

      labels.splice(0, 0, 'Time');

      return {
        arr: dots_arr,
        states: state_arr,
        type: cdc,
        //plotter: plotter,//this.getPlotters(cdc),
        labels: labels,
        quality: quality,
        series: series,
        valueRange: valueRange,
        yticker: yticker,
        //style: this.metaToStyle(arr, cdc, meta)
      };
    },
    splitDataTest(data, cdc) {
      var dots_arr = [],
        state_arr = [],
        meta = [],
        plotter = [],
        labels = [],
        series = {},
        valueRange = [],
        yticker,
        quality = {};
      let q_arr = [];
      let subQ_arr = [];

      //console.log(data);

      for (var i = 0; i < data.length; i++) {
        let val = {};
        let temp_val = 0;

        let dot_vals = [];
        let sate_vals = [];

        switch (cdc) {
          case 'SPS':
            /**
             * This class shall contain the following mandatory data attributes:
             ** stVal [BOOLEAN]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** subEna [BOOLEAN]
             ** subVal [BOOLEAN]
             ** subQ [Quality]
             ** subID [VISIBLE STRING64]
             ** blkEna [BOOLEAN]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.stVal = data[i].stVal ? this.valueConverter(data[i].stVal) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            if (val.stVal === null) val.stVal = -1;
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data
            val.subEna = this.valueConverter(data[i].subEna);
            val.subVal = this.valueConverter(data[i].subVal);
            val.subQ = new Quality(data[i].subQ);
            val.blkEna = this.valueConverter(data[i].blkEna);

            sate_vals = {
              stVal: val.stVal,
              subEna: val.subEna,
              subVal: val.subVal,
              blkEna: val.blkEna,
              ts: data[i].ts,
            };

            dot_vals = [
              val.stVal != null ? 7 : null,
              val.subEna != null ? 4 : null,
              val.subVal != null ? 3 : null,
              val.blkEna != null ? 2 : null,
            ];

            // This is for testing purposes
            temp_val = dot_vals;

            break;

          case 'ACT':
            /**
             * This class shall contain the following mandatory data attributes:
             ** general [BOOLEAN]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** phsA [BOOLEAN]
             ** phsB [BOOLEAN]
             ** phsC [BOOLEAN]
             ** neut [BOOLEAN]
             ** originSrc [Originator]
             ** operTmPhsA [TimeStamp]
             ** operTmPhsB [TimeStamp]
             ** operTmPhsB [TimeStamp]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.general = data[i].general ? this.valueConverter(data[i].general) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.phsA = this.valueConverter(data[i].phsA);

            val.phsB = this.valueConverter(data[i].phsB);

            val.phsC = this.valueConverter(data[i].phsC);

            val.neut = this.valueConverter(data[i].neut);

            sate_vals = {
              general: val.general,
              phsA: val.phsA,
              phsB: val.phsB,
              phsC: val.phsC,
              neut: val.neut,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.general != null ? 7 : null,
              val.phsA != null ? 4 : null,
              val.phsB != null ? 3 : null,
              val.phsC != null ? 2 : null,
              val.neut != null ? 1 : null,
            ];

            temp_val = dot_vals;

            break;

          case 'ACD':
            /**
             * This class shall contain the following mandatory data attributes:
             ** general [BOOLEAN]
             ** dirGeneral [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** phsA [BOOLEAN]
             ** dirPhsA [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** phsB [BOOLEAN]
             ** dirPhsB [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** phsC [BOOLEAN]
             ** dirPhsC [ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** neut [BOOLEAN]
             ** dirNeut[ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.general = this.valueConverter(data[i].general);
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.dirGeneral = this.valueConverter(data[i].dirGeneral);

            val.phsA = this.valueConverter(data[i].phsA);
            val.dirPhsA = this.valueConverter(data[i].dirPhsA);

            val.phsB = this.valueConverter(data[i].phsB);
            val.dirPhsB = this.valueConverter(data[i].dirPhsB);

            val.phsC = this.valueConverter(data[i].phsC);
            val.dirPhsC = this.valueConverter(data[i].dirPhsC);

            val.neut = this.valueConverter(data[i].neut);
            val.dirNeut = this.valueConverter(data[i].dirNeut);

            sate_vals = {
              general: val.general,
              dirGeneral: val.dirGeneral,
              phsA: val.phsA,
              dirPhsA: val.dirPhsA,
              phsB: val.phsB,
              dirPhsB: val.dirPhsB,
              phsC: val.phsC,
              dirPhsC: val.dirPhsC,
              neut: val.neut,
              dirNeut: val.dirNeut,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.general != null ? 7 : null,
              val.dirGeneral != null ? 7 : null,
              val.phsA != null ? 4 : null,
              val.dirPhsA != null ? 4 : null,
              val.phsB != null ? 3 : null,
              val.dirPhsB != null ? 3 : null,
              val.phsC != null ? 2 : null,
              val.dirPhsC != null ? 2 : null,
              val.neut != null ? 1 : null,
              val.dirNeut != null ? 1 : null,
            ];

            temp_val = dot_vals;
            break;

          case 'SPC':
            /**
             * This class shall contain the following mandatory data attributes:
             ** stVal [BOOLEAN]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** origin [Originator]
             ** ctlNum [INT8U]
             ** stSeld [BOOLEAN]
             ** opRcvd [BOOLEAN]
             ** opOk [BOOLEAN]
             ** tOpOk [Timestamp]
             ** subEna [BOOLEAN]
             ** subVal [BOOLEAN]
             ** subQ [Quality]
             ** subID [VISIBLE STRING64]
             ** blkEna [BOOLEAN]
             ** pulseConfig [PulseConfig]
             ** ctlModel [CtlModels]
             ** sboTimeout [INT32U]
             ** sboClass [SboClasses]
             ** operTimeout [INT32U]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */
            val.stVal = data[i].stVal ? this.valueConverter(data[i].stVal) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.origin = data[i].origin;

            // Control
            val.stSeld = this.valueConverter(data[i].stSeld);
            val.opRcvd = this.valueConverter(data[i].opRcvd);
            val.opOk = this.valueConverter(data[i].opOk);

            // Substition
            val.subEna = this.valueConverter(data[i].subEna);
            val.subVal = this.valueConverter(data[i].subVal);
            val.subQ = new Quality(data[i].subQ);

            // Blocked
            val.blkEna = this.valueConverter(data[i].blkEna);

            sate_vals = {
              stVal: val.stVal,
              stSeld: val.stSeld,
              opRcvd: val.opRcvd,
              opOk: val.opOk,
              subEna: val.subEna,
              subVal: val.subVal,
              blkEna: val.blkEna,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.stVal != null ? 7 : null,
              val.stSeld != null ? 4 : null,
              val.opRcvd != null ? 3 : null,
              val.opOk != null ? 2 : null,
              val.subEna != null ? 1 : null,
              val.subVal != null ? 0 : null,
              val.blkEna != null ? -1 : null,
            ];

            temp_val = dot_vals;

            break;

          case 'DPC':
            /**
             * This class shall contain the following mandatory data attributes:
             ** stVal [CODED ENUM: intermediate-state | off | on | bad-state]
             ** q [Quality]
             ** t [timestamp]
             And the following optional attributes:
             ** origin [Originator]
             ** ctlNum [INT8U]
             ** stSeld [BOOLEAN]
             ** opRcvd [BOOLEAN]
             ** opOk [BOOLEAN]
             ** tOpOk [Timestamp]
             ** subEna [BOOLEAN]
             ** subVal [BOOLEAN]
             ** subQ [Quality]
             ** subID [VISIBLE STRING64]
             ** blkEna [BOOLEAN]
             ** pulseConfig [PulseConfig]
             ** ctlModel [CtlModels]
             ** sboTimeout [INT32U]
             ** sboClass [SboClasses]
             ** operTimeout [INT32U]
             ** d [VISIBLE STRING255]
             ** dU [UNICODE STRING255]
             ** cdcNs [VISIBLE STRING255]
             ** cdcName [VISIBLE STRING255]
             ** dataNs [VISIBLE STRING255]
             */

            val.stVal = data[i].stVal ? this.valueConverter(data[i].stVal) : this.valueConverter(data[i].v); // "v" is for compatibility with older data
            val.q = getQuality(data[i].q, data[i].meta.q); // "meta.q" is for compatibility with older data

            val.origin = data[i].origin;

            // Control
            val.stSeld = this.valueConverter(data[i].stSeld);
            val.opRcvd = this.valueConverter(data[i].opRcvd);
            val.opOk = this.valueConverter(data[i].opOk);

            // Substition
            val.subEna = this.valueConverter(data[i].subEna);
            val.subVal = this.valueConverter(data[i].subVal);
            val.subQ = new Quality(data[i].subQ);

            // Blocked
            val.blkEna = this.valueConverter(data[i].blkEna);

            sate_vals = {
              stVal: val.stVal,
              stSeld: val.stSeld,
              opRcvd: val.opRcvd,
              opOk: val.opOk,
              subEna: val.subEna,
              subVal: val.subVal,
              blkEna: val.blkEna,
              ts: data[i].ts,
            };

            // Dot vals are used to draw dots on the Timeline in proper places
            dot_vals = [
              val.stVal == null ? null : val.stVal == 0 ? 6 : val.stVal == 1 ? 5 : 5 + val.stVal,
              val.stSeld != null ? 4 : null,
              val.opRcvd != null ? 3 : null,
              val.opOk != null ? 2 : null,
              val.subEna != null ? 1 : null,
              val.subVal != null ? 0 : null,
              val.blkEna != null ? -1 : null,
            ];

            temp_val = dot_vals;

            break;

          default:
            console.warn('Common data class ' + cdc + ' is not supported in this version of Tekvel Park');
            break;
        }

        temp_val.splice(0, 0, data[i].ts);
        //dot_vals.splice(0, 0, data[i].ts);
        dots_arr.push(temp_val);
        state_arr.push(sate_vals);
        meta.push(data[i].meta);
        //console.log(val.q);
        q_arr.push(val.q);
        subQ_arr.push(val.subQ);
        //this.metaConverter(meta[meta.length - 1])
      }

      quality = { q: q_arr, subQ: subQ_arr };

      // Choose plotters based on CDC:
      switch (cdc) {
        case 'SPS':
          // The plotters are as follows: [stVal, subEna, subVal, blkEna]
          //plotters = [
          //    Dygraph.Plotters.stValBooleanPrimary_plotter,
          //    Dygraph.Plotters.subEnaBooleanSecondary_plotter,
          //    Dygraph.Plotters.subValBooleanSecondary_plotter,
          //    Dygraph.Plotters.blkEnaBooleanSecondary_plotter
          //];
          //plotter = Dygraph.Plotters.spsPlotter // [stVal, subEna, subVal, blkEna]

          labels = ['stVal', 'subEna', 'subVal', 'blkEna'];

          series = {
            stVal: {
              plotter: this.lineBoolPlotter,
            },
            subEna: {
              plotter: this.lineBoolPlotter,
            },
            subVal: {
              plotter: this.lineBoolPlotter,
            },
            blkEna: {
              plotter: this.lineBoolPlotter,
            },
          };
          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 7, label: 'stVal' },
              { v: 4, label: 'subEna' },
              { v: 3, label: 'subVal' },
              { v: 2, label: 'blkEna' },
            ];
          };

          break;

        case 'ACT':
          // Protection activation information
          // The plotters are as follows: [val.general, val.phsA, val.phsB, val.phsC, val.neut];

          //plotter = Dygraph.Plotters.linePlotter;
          labels = ['general', 'phsA', 'phsB', 'phsC', 'neut'];
          series = {
            general: {
              plotter: this.lineBoolPlotter,
            },
            phsA: {
              plotter: this.lineBoolPlotter,
            },
            phsB: {
              plotter: this.lineBoolPlotter,
            },
            phsC: {
              plotter: this.lineBoolPlotter,
            },
            neut: {
              plotter: this.lineBoolPlotter,
            },
          };
          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 1, label: 'neut' },
              { v: 2, label: 'phsC' },
              { v: 3, label: 'phsB' },
              { v: 4, label: 'phsA' },
              { v: 7, label: 'general' },
            ];
          };
          break;

        case 'ACD':
          // The plotters are as follows: [val.general, val.dirGeneral, val.phsA, val.dirPhsA, val.phsB, val.dirPhsB, val.phsC, val.dirPhsC, val.neut, val.dirNeut];

          //plotter = Dygraph.Plotters.linePlotter;
          labels = [
            'general',
            'dirGeneral',
            'phsA',
            'dirPhsA',
            'phsB',
            'dirPhsB',
            'phsC',
            'dirPhsC',
            'neut',
            'dirNeut',
          ];
          series = {
            general: {
              plotter: this.lineBoolPlotter,
            },
            dirGeneral: {
              plotter: this.dirPlotter,
            },
            phsA: {
              plotter: this.lineBoolPlotter,
            },
            dirPhsA: {
              plotter: this.dirPlotter,
            },
            phsB: {
              plotter: this.lineBoolPlotter,
            },
            dirPhsB: {
              plotter: this.dirPlotter,
            },
            phsC: {
              plotter: this.lineBoolPlotter,
            },
            dirPhsC: {
              plotter: this.dirPlotter,
            },
            neut: {
              plotter: this.lineBoolPlotter,
            },
            dirNeut: {
              plotter: this.dirPlotter,
            },
          };
          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 1, label: 'neut' },
              { v: 2, label: 'phsC' },
              { v: 3, label: 'phsB' },
              { v: 4, label: 'phsA' },
              { v: 7, label: 'general' },
            ];
          };
          break;

        case 'SPC':
          // The plotters are as follows: [val.stVal, val.stSeld, val.opRcvd, val.opOk, val.subEna, val.subVal, val.subQ, val.blkEna];

          //plotter = Dygraph.Plotters.linePlotter; // [stVal, stSeld, opRcvd, opOk, subEna, subVal, subQ, blkEna]
          labels = ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'];

          series = {
            stVal: {
              plotter: this.lineBoolPlotter,
            },
            stSeld: {
              plotter: this.lineBoolPlotter,
            },
            opRcvd: {
              plotter: this.lineBoolPlotter,
            },
            opOk: {
              plotter: this.lineBoolPlotter,
            },
            subEna: {
              plotter: this.lineBoolPlotter,
            },
            subVal: {
              plotter: this.lineBoolPlotter,
            },
            blkEna: {
              plotter: this.lineBoolPlotter,
            },
          };

          valueRange = [5, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 7, label: 'stVal' },
              { v: 4, label: 'stSeld' },
              { v: 3, label: 'opRcvd' },
              { v: 2, label: 'opOk' },
              { v: 2, label: 'opOk' },
              { v: 0, label: 'subVal' },
              { v: -1, label: 'blkEna' },
            ];
          };

          break;

        case 'DPC':
          // The plotters are as follows: [val.stVal, val.stSeld, val.opRcvd, val.opOk, val.subEna, val.subVal, val.subQ, val.blkEna];

          //plotter = Dygraph.Plotters.linePlotter; // [stVal, stSeld, opRcvd, opOk, subEna, subVal, subQ, blkEna]
          labels = ['stVal', 'stSeld', 'opRcvd', 'opOk', 'subEna', 'subVal', 'blkEna'];

          series = {
            stVal: {
              plotter: this.lineStepPlotter,
            },
            stSeld: {
              plotter: this.lineBoolPlotter,
            },
            opRcvd: {
              plotter: this.lineBoolPlotter,
            },
            opOk: {
              plotter: this.lineBoolPlotter,
            },
            subEna: {
              plotter: this.lineBoolPlotter,
            },
            subVal: {
              plotter: this.lineBoolPlotter,
            },
            blkEna: {
              plotter: this.lineBoolPlotter,
            },
          };

          valueRange = [4, 9];
          yticker = function (min, max, pixels, opts, dygraph, vals) {
            return [
              { v: 6, label: 'Intermediate' },
              { v: 5, label: 'Off' },
              { v: 7, label: 'On' },
              { v: 8, label: 'Invalid' },
              // {v: 4, label: "stSeld"},
              // {v: 3, label: "opRcvd"},
              // {v: 2, label: "opOk"},
              // {v: 2, label: "opOk"},
              // {v: 0, label: "subVal"},
              // {v: -1, label: "blkEna"}
            ];
          };

          break;

        default:
          // for all other cases simply try to map all available data to line plotters
          plotter = Dygraph.Plotters.linePlotter;
      }

      labels.splice(0, 0, 'Time');

      return {
        arr: dots_arr,
        states: state_arr,
        type: cdc,
        //plotter: plotter,//this.getPlotters(cdc),
        labels: labels,
        quality: quality,
        series: series,
        valueRange: valueRange,
        yticker: yticker,
        //style: this.metaToStyle(arr, cdc, meta)
      };
    },
    dateTicker(a, b, pixels, opts, dygraph, vals) {
      var chosen = this.pickDateTickGranularity(a, b, pixels, opts);
      if (chosen > this._NO_GRANULARITIE) {
        //console.log("Good pick", chosen)
        return this.getDateAxis(a, b, chosen, opts, dygraph);
      } else {
        // console.log("Bad pick")
        //this can happen if self.width_ is zero.
        return [];
      }
    },
    pickDateTickGranularity(a, b, pixels, opts) {
      var pixels_per_tick = /** @type{number} */ (opts('pixelsPerLabel'));
      for (var i = 0; i < this.NUM_GRANULARITIES; i++) {
        var num_ticks = this.numDateTicks(a, b, i);
        if (pixels / num_ticks >= pixels_per_tick) {
          return i;
        }
      }
      return Dygraph._NO_GRANULARITIE;
    },
    numDateTicks(start_time, end_time, granularity) {
      var spacing = this.TICK_PLACEMENT[granularity].spacing;
      return Math.round((1.0 * (end_time - start_time)) / spacing);
    },
    getDateAxis(start_time, end_time, granularity, opts, dg) {
      var formatter = /** @type{AxisLabelFormatter} */ (opts('axisLabelFormatter'));
      var utc = opts('labelsUTC');
      var accessors = utc ? this.DateAccessorsUTC : this.DateAccessorsLocal;

      var datefield = this.TICK_PLACEMENT[granularity].datefield;
      var step = this.TICK_PLACEMENT[granularity].step;
      var spacing = this.TICK_PLACEMENT[granularity].spacing;

      /**
       Choose a nice tick position before the initial instant.
       Currently, this code deals properly with the existent daily granularities:
       DAILY(with step of 1) and WEEKLY (with step of 7 but specially handled).
       Other daily granularities (say TWO_DAILY) should also be handled specially
       by setting the start_date_offset to 0.
       */
      var start_date = new DateTpui(start_time);
      var date_array = [];
      date_array[this.DATEFIELD_Y] = accessors.getFullYear(start_date);
      date_array[this.DATEFIELD_M] = accessors.getMonth(start_date);
      date_array[this.DATEFIELD_D] = accessors.getDate(start_date);
      date_array[this.DATEFIELD_HH] = accessors.getHours(start_date);
      date_array[this.DATEFIELD_MM] = accessors.getMinutes(start_date);
      date_array[this.DATEFIELD_SS] = accessors.getSeconds(start_date);
      date_array[this.DATEFIELD_MS] = accessors.getMilliseconds(start_date);
      date_array[this.DATEFIELD_US] = accessors.getMicroseconds(start_date);

      var start_date_offset = date_array[datefield] % step;
      if (granularity == this.WEEKLY) {
        //This will put the ticks on Sundays.
        start_date_offset = accessors.getDay(start_date);
      }

      date_array[datefield] -= start_date_offset;
      for (var df = datefield + 1; df < this.NUM_DATEFIELDS; df++) {
        //The minimum value is 1 for the day of month, and 0 for all other fields.
        date_array[df] = df === this.DATEFIELD_D ? 1 : 0;
      }

      /**
       Generate the ticks.
       For granularities not coarser than HOURLY we use the fact that:
       the number of milliseconds between ticks is constant
       and equal to the defined spacing.
       Otherwise we rely on the 'roll over' property of the Date functions:
       when some date field is set to a value outside of its logical range,
       the excess 'rolls over' the next (more significant) field.
       However, when using local time with DST transitions,
       there are dates that do not represent any time value at all
       (those in the hour skipped at the 'spring forward'),
       and the JavaScript engines usually return an equivalent value.
       Hence we have to check that the date is properly increased at each step,
       returning a date at a nice tick position.
       */
      var ticks = [];
      var tick_date = accessors.makeDate.apply(null, date_array);
      var tick_time = tick_date.getTime();
      if (granularity <= this.HOURLY) {
        if (tick_time < start_time) {
          tick_time += spacing;
          tick_date = new DateTpui(tick_time);
        }
        while (tick_time <= end_time) {
          ticks.push({
            v: tick_time,
            label: formatter.call(dg, tick_date, granularity, opts, dg),
          });
          tick_time += spacing;
          tick_date = new DateTpui(tick_time);
        }
      } else {
        if (tick_time < start_time) {
          date_array[datefield] += step;
          tick_date = accessors.makeDate.apply(null, date_array);
          tick_time = tick_date.getTime();
        }
        while (tick_time <= end_time) {
          if (granularity >= this.DAILY || accessors.getHours(tick_date) % step === 0) {
            ticks.push({
              v: tick_time,
              label: formatter.call(dg, tick_date, granularity, opts, dg),
            });
          }
          date_array[datefield] += step;
          tick_date = accessors.makeDate.apply(null, date_array);
          tick_time = tick_date.getTime();
        }
      }
      //console.log(ticks)
      return ticks;
    },
    hmsString_(hours, mins, secs) {
      let hoursString = hours >= 10 ? hours : '0' + hours;
      let minsString = mins >= 10 ? mins : '0' + mins;
      let secsString = secs >= 10 ? secs : '0' + secs;

      return hoursString + ':' + minsString + ':' + secsString;
    },
    dateAxisLabelFormatter(date, granularity, opts) {
      const prefs = usePrefs();
      date.date = date.date.setTimezoneOffset(prefs.timezoneOffset);
      var utc = opts('labelsUTC');
      var accessors = utc ? this.DateAccessorsUTC : this.DateAccessorsLocal;

      var year = accessors.getFullYear(date),
        month = accessors.getMonth(date),
        day = accessors.getDate(date),
        hours = accessors.getHours(date),
        mins = accessors.getMinutes(date),
        secs = accessors.getSeconds(date),
        millis = accessors.getMilliseconds(date),
        micros = accessors.getMicroseconds(date);

      if (granularity >= this.DECADAL) {
        return '' + year;
      } else if (granularity >= this.MONTHLY) {
        return month < 10 ? `0${month}` : month /*Dygraph.SHORT_MONTH_NAMES_[month]*/ + '&#160;' + year;
      } else if (
        granularity === this.MILLISECONDLY_500 ||
        granularity === this.MILLISECONDLY_250 ||
        granularity === this.MILLISECONDLY_100 ||
        granularity === this.MILLISECONDLY_50 ||
        granularity === this.MILLISECONDLY_10 ||
        granularity === this.MILLISECONDLY_5 ||
        granularity === this.MILLISECONDLY_1
      ) {
        return secs + '.' + millis.toString().padStart(3, '000');
      } else if (
        granularity === this.MICROSECONDLY_500 ||
        granularity === this.MICROSECONDLY_250 ||
        granularity === this.MICROSECONDLY_100 ||
        granularity === this.MICROSECONDLY_50 ||
        granularity === this.MICROSECONDLY_10 ||
        granularity === this.MICROSECONDLY_5
      ) {
        return millis + ':' + micros.toString().padStart(3, '000');
      } else {
        var frac = hours * 3600 + mins * 60 + secs + 1e-3 * millis;
        if (frac === 0 || granularity >= this.DAILY) {
          //e.g. '21 Jan' (%d % b)
          // console.log(day);
          return day < 10 ? `0${day}` : day + '&#160;' + month < 10 ? `0${month}` : month; // Dygraph.SHORT_MONTH_NAMES_[month];
        } else {
          return this.hmsString_(hours, mins, secs);
        }
      }
    },
    drawHostXAxis(e) {
      var g = e.dygraph;

      if (
        !g.getOptionForAxis('drawAxis', 'x') &&
        !g.getOptionForAxis('drawAxis', 'y') &&
        !g.getOptionForAxis('drawAxis', 'y2')
      ) {
        return;
      }

      //Round pixels to half- integer boundaries for crisper drawing.
      function halfUp(x) {
        return Math.round(x) + 0.5;
      }
      function halfDown(y) {
        return Math.round(y) - 0.5;
      }

      var context = e.canvas.getContext('2d'); //e.drawingContext;
      var containerDiv = e.canvas.parentNode;
      var ticksContainer = document.getElementById('x_axis_ticks');
      var canvasWidth = e.canvas.style.width; //g.width_;  // e.canvas.width is affected by pixel ratio.
      var canvasHeight = e.canvas.style.height; //g.height_;

      var label, x, y, tick, i;

      var makeLabelStyle = function (axis) {
        return {
          position: 'absolute',
          fontSize: g.getOptionForAxis('axisLabelFontSize', axis) + 'px',
          zIndex: 10,
          color: g.getOptionForAxis('axisLabelColor', axis),
          width: g.getOptionForAxis('axisLabelWidth', axis) + 'px',
          height: g.getOptionForAxis('axisLabelFontSize', 'x') + 2 + 'px',
          lineHeight: 'normal', // Something other than "normal" line-height screws up label positioning.
          overflow: 'hidden',
        };
      };

      var labelStyles = {
        x: makeLabelStyle('x'),
        y: makeLabelStyle('y'),
        y2: makeLabelStyle('y2'),
      };

      var makeDiv = function (txt, axis, prec_axis) {
        /*
         * This seems to be called with the following three sets of axis/prec_axis:
         * x: undefined
         * y: y1
         * y: y2
         */
        var div = document.createElement('div');
        var labelStyle = labelStyles[prec_axis == 'y2' ? 'y2' : axis];
        for (var name in labelStyle) {
          if (labelStyle.hasOwnProperty(name)) {
            div.style[name] = labelStyle[name];
          }
        }
        var inner_div = document.createElement('div');
        inner_div.className =
          'dygraph-axis-label' + ' dygraph-axis-label-' + axis + (prec_axis ? ' dygraph-axis-label-' + prec_axis : '');
        inner_div.innerHTML = txt;
        div.appendChild(inner_div);
        return div;
      };

      //axis lines
      while (ticksContainer.firstChild) {
        ticksContainer.removeChild(ticksContainer.firstChild);
      }
      context.save();

      var layout = g.layout_;
      var area = e.dygraph.plotter_.area;

      //Helper for repeated axis- option accesses.
      var makeOptionGetter = function (axis) {
        return function (option) {
          return g.getOptionForAxis(option, axis);
        };
      };

      if (g.getOptionForAxis('drawAxis', 'x')) {
        if (layout.xticks) {
          var getAxisOption = makeOptionGetter('x');
          for (i = 0; i < layout.xticks.length; i++) {
            tick = layout.xticks[i];
            x = area.x + tick[0] * area.w;
            y = area.y + area.h;

            /* Tick marks are currently clipped, so don't bother drawing them.
                      context.beginPath();
                      context.moveTo(halfUp(x), halfDown(y));
                      context.lineTo(halfUp(x), halfDown(y + this.attr_('axisTickSize')));
                      context.closePath();
                      context.stroke();
                      */

            label = makeDiv(tick[1], 'x');
            label.style.textAlign = 'center';
            label.style.top = y + getAxisOption('axisTickSize') + 'px';

            var left = x - getAxisOption('axisLabelWidth') / 2;
            if (left + getAxisOption('axisLabelWidth') > canvasWidth) {
              left = canvasWidth - getAxisOption('axisLabelWidth');
              label.style.textAlign = 'right';
            }
            if (left < 0) {
              left = 0;
              label.style.textAlign = 'left';
            }

            label.style.left = left + 'px';
            label.style.width = getAxisOption('axisLabelWidth') + 'px';
            ticksContainer.appendChild(label);
            this.xlabels_.push(label);
          }
        }

        context.strokeStyle = g.getOptionForAxis('axisLineColor', 'x');
        context.lineWidth = g.getOptionForAxis('axisLineWidth', 'x');
        context.beginPath();
        var axisY;
        if (g.getOption('drawAxesAtZero')) {
          var r = g.toPercentYCoord(0, 0);
          if (r > 1 || r < 0) r = 1;
          axisY = halfDown(area.y + r * area.h);
        } else {
          axisY = halfDown(area.y + area.h);
        }
        context.moveTo(halfUp(area.x), axisY);
        context.lineTo(halfUp(area.x + area.w), axisY);
        context.closePath();
        context.stroke();
      }

      context.restore();
    },
    spsPlotter(e) {
      dev.log(e);
      var g = e.dygraph;
      var setName = e.setName;
      var strokeWidth = e.strokeWidth;

      // TODO(danvk): Check if there's any performance impact of just calling
      // getOption() inside of _drawStyledLine. Passing in so many parameters makes
      // this code a bit nasty.
      var borderWidth = g.getNumericOption('strokeBorderWidth', setName);
      var drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
      var strokePattern = g.getOption('strokePattern', setName);
      var drawPoints = g.getBooleanOption('drawPoints', setName);
      var pointSize = g.getNumericOption('pointSize', setName);

      var dataObjects = [];
      var dataObject;

      var sets = e.allSeriesPoints;

      for (var p = 0; p < sets[0].length; p++) {
        dataObject = {
          stVal: sets[0][p].yval,
          subEna: sets[1][p].yval,
          subVal: sets[2][p].yval,
          blkEna: sets[3][p].yval,
        };
        dataObjects.push(dataObject);
      }

      var area = e.plotArea;
      var ctx = e.drawingContext;
      ctx.strokeStyle = '#202020';
      ctx.lineWidth = 0.6;

      for (p = 1; p < dataObjects.length; p++) {
        ctx.beginPath();

        let this_dataObject = dataObjects[p];
        let previous_dataObject = dataObjects[p - 1];

        var previous_stVal = previous_dataObject.stVal;
        let centerY = area.h / 2;
        var previous_X = area.x + sets[0][p - 1].x * area.w;
        var this_X = area.x + sets[0][p].x * area.w;

        e.points[p].y = 0;

        if (previous_stVal == 1) {
          ctx.fillStyle = '#f4d442';
          ctx.fillRect(previous_X, centerY - 3, this_X, area.h - centerY + 2);
        } else if (previous_stVal == 0) {
          ctx.fillStyle = '#40edc4';
          ctx.fillRect(previous_X, centerY - 1, this_X, area.h - centerY + 1);
        }

        //ctx.moveTo(previous_StValX, previous_stValY);
        //ctx.lineTo(this_StValX, previous_stValY);
        //ctx.closePath();
        //ctx.stroke();
      }

      //if (borderWidth && strokeWidth) {
      //    this._drawStyledLine(e,
      //        g.getOption("strokeBorderColor", setName),
      //        strokeWidth + 2 * borderWidth,
      //        strokePattern,
      //        drawPoints,
      //        drawPointCallback,
      //        pointSize
      //    );
      //}

      //this._drawStyledLine(e,
      //    e.color,
      //    strokeWidth,
      //    strokePattern,
      //    drawPoints,
      //    drawPointCallback,
      //    pointSize
      //);
    },
    lineBoolPlotter(e) {
      // console.log('_lineBoolPlotter', e);
      let g = e.dygraph;
      let ctx = e.drawingContext;

      let setName = e.setName;
      let strokeWidth = e.strokeWidth;

      let borderWidth = g.getNumericOption('strokeBorderWidth', setName);
      let drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
      let strokePattern = g.getOption('strokePattern', setName);
      let drawPoints = g.getBooleanOption('drawPoints', setName);
      let pointSize = g.getNumericOption('pointSize', setName);

      let area = e.plotArea;

      let plotWidth = area.w;
      let plotHeight = area.h;
      let plotLeftBoundary = area.x;
      let plotMidHeight = plotHeight / 2;
      let yscale = e.axis.yscale;

      let points = e.points;
      let states = e.dygraph.user_attrs_._states;

      let yShift;
      let booleanHeightTrue;
      let booleanHeightFalse;
      let applyQ = false,
        applySubQ = false;

      //console.log(e);

      // Choose setName-specific parameters (eg. shifts for non-primary data etc.):

      switch (e.setName) {
        case 'stVal':
          //ctx.fillStyle = "#f9b2ff";
          booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
          applyQ = true;
          break;
        case 'general':
          //yShift = PRIMARY_Y_SHIFT;
          //ctx.fillStyle = "#f9b2ff";
          booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
          applyQ = true;
          break;
        case 'phsA':
          //yShift = SECONDARY_Y_SHIFT + 0 * SECONDARY_D_SHIFT;
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          applyQ = true;
          //ctx.fillStyle = "#ffeb87";
          break;
        case 'phsB':
          //yShift = SECONDARY_Y_SHIFT + 1 * SECONDARY_D_SHIFT;
          //ctx.fillStyle = "#87ffab";
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          applyQ = true;
          break;
        case 'phsC':
          //yShift = SECONDARY_Y_SHIFT + 2 * SECONDARY_D_SHIFT;
          //ctx.fillStyle = "#ff8799";
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          applyQ = true;
          break;
        case 'neut':
          //yShift = SECONDARY_Y_SHIFT + 3 * SECONDARY_D_SHIFT;
          //ctx.fillStyle = "#e8e8e8";
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          applyQ = true;
          break;
        case 'subVal':
          booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
          applySubQ = true;
          break;

        default:
          //yShift = 0;
          booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
          break;
      }

      // Shift middle line by yShift (applies for secondary lines)
      let boolLineMidY = plotMidHeight + yShift;

      for (var p = 0; p < points.length; p++) {
        let value = points[p].y,
          // state = states[p][e.setName],
          state = states.find((s) => s.ts == points[p].xval)[e.setName],
          currentPointX = (points[p + 1] ? points[p + 1].x : 1.1) * plotWidth,
          previousPointX = points[p].x * plotWidth,
          regionWidth = currentPointX - previousPointX,
          quality,
          dataFillColor,
          testColor = null;

        if (applyQ) {
          quality = e.dygraph.attributes_.user_.quality.q[p];
          dataFillColor = getColorByValidity(quality);
          testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        } else if (applySubQ) {
          quality = e.dygraph.attributes_.user_.quality.subQ[p];
          dataFillColor = getColorByValidity(quality);
          testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        } else {
          dataFillColor = Q_UNKNOWN_COLOR;
        }

        //console.log(e.setName, ctx.fillStyle, value)

        if (testColor) {
          ctx.fillStyle = testColor;
          let testHeight = 2 * booleanHeightTrue;
          let testFillShift = (-testHeight * yscale) / 2;
          //ctx.fillStyle = '#f4d442'
          ctx.fillRect(
            previousPointX + plotLeftBoundary,
            value * plotHeight + testFillShift,
            regionWidth,
            testHeight * yscale
          );
        }

        ctx.fillStyle = dataFillColor;
        points[p]._color = dataFillColor;

        if (state == 1) {
          let hShift = (-booleanHeightTrue * yscale) / 2;
          // ctx.fillStyle = `green`
          ctx.fillRect(
            previousPointX + plotLeftBoundary,
            value * plotHeight + hShift,
            regionWidth,
            booleanHeightTrue * yscale
          );
          ctx.strokeStyle = ctx.fillStyle;
          ctx.strokeRect(
            previousPointX + plotLeftBoundary,
            value * plotHeight + hShift,
            regionWidth,
            booleanHeightTrue * yscale
          );
        } else if (state == 0) {
          let hShift = (-booleanHeightFalse * yscale) / 2;
          // ctx.fillStyle = `yellow`
          ctx.fillRect(
            previousPointX + plotLeftBoundary,
            value * plotHeight + hShift,
            regionWidth,
            booleanHeightFalse * yscale
          );
          ctx.strokeStyle = ctx.fillStyle;
          ctx.strokeRect(
            previousPointX + plotLeftBoundary,
            value * plotHeight + hShift,
            regionWidth,
            booleanHeightFalse * yscale
          );
        } else if (state === -1) {
          // let prevState = p >= 1 ? states[p - 1][e.setName] : null,
          let prevState = p >= 1 ? states[states.findIndex((s) => s.ts == points[p].xval) - 1][e.setName] : null,
            prevEventPosX = p >= 1 ? (points[p].x - points[p - 1].x) * plotWidth : 0;

          let gradient = ctx.createLinearGradient(
            previousPointX + plotLeftBoundary - prevEventPosX,
            value * plotHeight - booleanHeightTrue * yscale,
            previousPointX + plotLeftBoundary,
            value * plotHeight - booleanHeightTrue * yscale
          );
          gradient.addColorStop(0, 'rgba(255,255,255,0.2)');
          gradient.addColorStop(1, '#a52a2a');

          ctx.fillStyle = gradient;
          ctx.fillRect(
            previousPointX + plotLeftBoundary - prevEventPosX + 1,
            value * plotHeight - booleanHeightTrue * yscale,
            prevEventPosX + 1,
            booleanHeightTrue * yscale * 2
          );

          ctx.fillStyle = '#a52a2a';
          ctx.fillRect(
            previousPointX + plotLeftBoundary,
            value * plotHeight - booleanHeightTrue * yscale,
            regionWidth,
            booleanHeightTrue * yscale * 2
          );

          ctx.strokeStyle = p >= 1 ? points[p - 1]._color : Q_UNKNOWN_COLOR;
          ctx.lineWidth = (prevState === 1 ? booleanHeightTrue : booleanHeightFalse) * yscale;

          ctx.beginPath();
          ctx.moveTo(previousPointX + plotLeftBoundary - prevEventPosX - 1, value * plotHeight);
          ctx.lineTo(previousPointX + plotLeftBoundary, value * plotHeight);
          ctx.stroke();

          // Рисуем пунктирную линию
          ctx.setLineDash([0, prevState === 1 ? 20 : 15, prevState === 1 ? 12 : 8]);
          // ctx.setLineDash([5,3,2,5,6,2,3,5,4,6,5,2,4]);
          ctx.beginPath();
          ctx.moveTo(previousPointX + plotLeftBoundary, value * plotHeight);
          ctx.lineTo(previousPointX + plotLeftBoundary + regionWidth, value * plotHeight);
          ctx.stroke();
          // ctx.scale(0, 0);
        }

        // Update Y value for point to draw points in correct places;
      }
    },
    dirPlotter(e) {
      let g = e.dygraph;
      let ctx = e.drawingContext;

      let setName = e.setName;
      let strokeWidth = e.strokeWidth;

      let borderWidth = g.getNumericOption('strokeBorderWidth', setName);
      let drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
      let strokePattern = g.getOption('strokePattern', setName);
      let drawPoints = g.getBooleanOption('drawPoints', setName);
      let pointSize = g.getNumericOption('pointSize', setName);

      let area = e.plotArea;

      let plotWidth = area.w;
      let plotHeight = area.h;
      let plotLeftBoundary = area.x;
      let plotMidHeight = plotHeight / 2;
      let yscale = e.axis.yscale;

      let points = e.points;
      let states = e.dygraph.user_attrs_._states;

      let below = false; // if true the direction shall be written below the timeline
      let booleanHeightTrue;
      let booleanHeightFalse;
      let pkpValueName;

      // Choose setName-specific parameters (eg. shifts for non-primary data etc.):

      switch (e.setName) {
        case 'dirGeneral':
          below = true;
          ctx.fillStyle = '#f9b2ff';
          booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
          pkpValueName = 'general';
          break;
        case 'dirPhsA':
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          ctx.fillStyle = '#ffeb87';
          pkpValueName = 'phsA';
          break;
        case 'dirPhsB':
          ctx.fillStyle = '#87ffab';
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          pkpValueName = 'phsB';
          break;
        case 'dirPhsC':
          ctx.fillStyle = '#ff8799';
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          pkpValueName = 'phsC';
          break;
        case 'dirNeut':
          ctx.fillStyle = '#e8e8e8';
          booleanHeightTrue = SECONDARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = SECONDARY_BOOLEAN_HEIGHT_FALSE;
          pkpValueName = 'neut';
          break;
        default:
          booleanHeightTrue = PRIMARY_BOOLEAN_HEIGHT_TRUE;
          booleanHeightFalse = PRIMARY_BOOLEAN_HEIGHT_FALSE;
          break;
      }

      let previousState;

      for (var p = 0; p < points.length; p++) {
        let currentValue = points[p].y;
        let currentState = states[p][e.setName];
        let currentPointX = points[p].x * plotWidth;
        let addXShift = p == 0 ? 5 : p == points.length - 1 ? -5 : 2; // Add shift if the point is most left or most right;

        let dirText;
        let drawText = true;
        if (currentState != previousState) {
          switch (currentState) {
            //[ENUMERATED: UNKOWN | FORWARD | BACKWARD | BOTH]
            case 0: // UNKNOWN
              dirText = 'Unknown';
              break;
            case 1: // FORWARD
              dirText = 'Forward';
              break;
            case 2: // BACKWARD
              dirText = 'Backward';
              break;
            case 3: // BOTH
              dirText = 'Both';
              break;

            default: // Do not draw direction data if it is not transmitted with GOOSE;
              // UNDEFINED
              dirText = 'Undefined';
              drawText = false;
              break;
          }
          if (drawText) {
            if (p == points.length - 1) {
              // This is the last point of the plot, text must be righ-aligned to fit
              ctx.textAlign = 'right';
            } else {
              ctx.textAlign = 'left';
            }
            ctx.fillStyle = 'gray';
            ctx.font = '10px Arial';

            let belowMultiplyer = below ? -1 : 1;

            ctx.fillText(
              dirText,
              currentPointX + plotLeftBoundary + addXShift,
              currentValue * plotHeight - belowMultiplyer * ((booleanHeightTrue * yscale) / 2 + (below ? 12 : 2))
            ); //Math.max([booleanHeightTrue, booleanHeightFalse])
          }
        }

        previousState = currentState;
      }
    },
    lineStepPlotter(e) {
      //console.log('_lineStepPlotter', e);
      let g = e.dygraph;
      let ctx = e.drawingContext;
      let applyQ = false,
        applySubQ = false;

      let setName = e.setName;
      let strokeWidth = e.strokeWidth;

      let borderWidth = g.getNumericOption('strokeBorderWidth', setName);
      let drawPointCallback = g.getOption('drawPointCallback', setName); //|| utils.Circles.DEFAULT;
      let strokePattern = g.getOption('strokePattern', setName);
      let drawPoints = g.getBooleanOption('drawPoints', setName);
      let pointSize = g.getNumericOption('pointSize', setName);

      let area = e.plotArea;

      let plotWidth = area.w;
      let plotHeight = area.h;
      let plotLeftBoundary = area.x;
      let plotMidHeight = plotHeight / 2;
      let yscale = e.axis.yscale;

      let points = e.points;
      let states = e.dygraph.user_attrs_._states;

      let lineWidth = PRIMARY_BOOLEAN_HEIGHT_FALSE;

      switch (e.setName) {
        case 'stVal':
          applyQ = true;
          break;
        case 'subVal':
          applySubQ = true;
          break;
        default:
          applyQ = true;
          break;
      }

      //ctx.fillStyle = "#777"
      for (var p = 1; p < points.length; p++) {
        let value = points[p - 1].y;
        let currentValue = points[p].y;
        let state = states[p - 1][e.setName];
        let currentPointX = points[p].x * plotWidth;
        let previousPointX = points[p - 1].x * plotWidth;
        let regionWidth = currentPointX - previousPointX;

        let hShift = (-lineWidth * yscale) / 2;

        let quality,
          dataFillColor,
          testColor = null;

        if (applyQ) {
          quality = e.dygraph.attributes_.user_.quality.q[p - 1];
          dataFillColor = getColorByValidity(quality);
          testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        } else if (applySubQ) {
          quality = e.dygraph.attributes_.user_.quality.subQ[p - 1];
          dataFillColor = getColorByValidity(quality);
          testColor = quality.isTest ? Q_TEST_BACKGROUND : null;
        } else {
          dataFillColor = Q_UNKNOWN_COLOR;
        }

        //console.log(e.setName, ctx.fillStyle, value)

        if (testColor) {
          ctx.fillStyle = testColor;
          let testWidth = 4 * lineWidth;
          let testFillHShift = (-testWidth * yscale) / 2;
          ctx.fillRect(
            previousPointX + plotLeftBoundary,
            value * plotHeight + testFillHShift,
            regionWidth,
            testWidth * yscale
          );
        }

        //console.log(e.setName, ctx.fillStyle, value)
        ctx.fillStyle = dataFillColor;
        ctx.fillRect(previousPointX + plotLeftBoundary, value * plotHeight + hShift, regionWidth, lineWidth * yscale);
        if (currentValue != value) {
          // Draw additional part of line from previous to current:
          let shiftSighn = currentValue - value < 0 ? 1 : -1;
          let lineHeight = (currentValue - value) * plotHeight;
          //if (testColor) {
          //    ctx.fillStyle = testColor;
          //    let testWidth = 4 * lineWidth;
          //    let testFillXShift = testWidth * yscale / 2;
          //    ctx.fillRect(currentPointX + plotLeftBoundary - testFillShift, value * plotHeight - shiftSighn * testFillShift, testWidth * yscale, testWidth + shiftSighn * 2 *testFillShift);
          //}
          ctx.fillStyle = dataFillColor;

          let xShift = (lineWidth * yscale) / 2;
          ctx.fillRect(
            currentPointX + plotLeftBoundary - xShift,
            value * plotHeight - shiftSighn * hShift,
            lineWidth * yscale,
            lineHeight + shiftSighn * 2 * hShift
          );
        }

        // Update Y value for point to draw points in correct places;
      }
    },
    smoothPlotter: smoothPlotter,
  },
});
