<template>
  <div class="">
    <template v-if="isLive">
      <div class="container-fluid p-0 mb-4">
        <div class="mt-2" id="lvb-accordion">
          <div id="lvb-table" :data-enable="enableSoftwareLVB" :data-adm="adm" :data-commissioning="commissioning">
            <lvb-table :dataset="lvbTableDataset"></lvb-table>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LvbTable from '@/components/timeline/LvbTable.vue';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePage } from '@/stores/page';

export default {
  name: 'project-lvb',
  components: {
    LvbTable,
  },
  props: {},
  data: () => {
    return {
      // scripts: ['/js/timeline.js', '/js/project_users.js', '/js/tpui.project.timeline.js', '/js/widget.scl.js'],
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(usePage),
    currentProject() {
      return this.tpws.currentProject;
    },
    projectPageCurrent() {
      return this.projectPage && this.projectPage.current;
    },
    displayName() {
      return this.$store.getters.displayName;
    },
    current() {
      return this.page.currentPage?.current;
    },
    enableSoftwareLVB() {
      return this.page.currentPage?.enableSoftwareLVB;
    },
    adm() {
      return this.page.currentPage?.adm;
    },
    isLive() {
      return this.page.currentPage?.isLive;
    },
    projectusers() {
      return this.page.currentPage?.projectusers;
    },
    permission() {
      return this.page.currentPage?.permission;
    },
    commissioning() {
      return this.page.currentPage?.commissioning;
    },

    lvbTableDataset() {
      return this.$root.$parseDataset(this.$root.lvbTableDataset);
    },

    url() {
      return this.eventsArchive
        ? `/projects/${this.tpws.projectName}/archive?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.tpws.projectName}/events2`;
    },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },

    fields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
    nanoTstart() {
      return new DateNano(this.eventsArchiveTS.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.eventsArchiveTS.tend).customFormat();
    },
  },
  created() {},
  mounted() {},
  methods: {
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
