<template>
  <div class="top-nav-black__container">
    <div class="top-nav-black__left-items">
      <div>
        <div>
          <v-svg src="tekvel-logo" fill="white" cc="pointer-cursor" @click="goToLink('/')"></v-svg>
        </div>
      </div>
      <div class="top-nav-black__left-items_second">
        <p style="font-size: 18px; font-weight: bold" class="top-nav-black__text">{{ displayName }}</p>
      </div>
    </div>
    <div class="top-nav-black__right-items pr-3">
      <div>
        <top-app style="margin-right: 50px; color: #fff"></top-app>
      </div>
      <div v-show="currentProject" class="top-nav-elm__popup" style="z-index: 999">
        <popup-top-nav theme="dark" :name="currentProject"> </popup-top-nav>
      </div>
      <n-config-provider v-if="showSettingsMenu" :theme="theme">
        <n-popover trigger="hover" class="nav-top-black-popup" raw ref="menuSettings">
          <template #trigger>
            <v-icon size="24" class="ml-3 cursor-pointer">
              <v-svg fill="#949698" width="20" height="14" src="cog"></v-svg>
            </v-icon>
          </template>
          <div class="pop-body">
            <el-menu index="1" v-if="page !== '/open/wheel'" @select="menuSettingsTriggered">
              <el-menu-item class="el-menu-item-nested" index="1-1" @click="showUserSettings">
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="cogs" />
                  <span>{{ $t('Preferences') }}</span>
                </template>
              </el-menu-item>
              <el-menu-item v-if="commissioning"  class="el-menu-item-nested" index="1-1-1" @click="srcMacNull">
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="network-wired" />
                  <span>{{ $t('Set srcMac default') }}</span>
                </template>
              </el-menu-item>
              <el-menu-item v-if="currentProject" class="el-menu-item-nested" index="1-2" @click="showUserInvite">
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="user-circle" />
                  <span>{{ $t('Invite Users') }}</span>
                </template>
              </el-menu-item>
              <el-menu-item
                class="el-menu-item-nested"
                index="1-3"
                v-if="reports.preparedReportsCount"
                @click="showPreparedReports"
              >
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="envelope" />
                  <span>{{ $t('View prepared reports') }} {{ reports.preparedReportsCount }}</span>
                </template>
              </el-menu-item>
              <el-menu-item
                v-if="isAdmin"
                class="el-menu-item-nested"
                index="/users/manage"
                @click="goToLink('/users/manage')"
              >
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="address-book" />
                  <span> {{ $t('User management') }} </span>
                </template>
              </el-menu-item>
              <el-menu-item v-if="isAdmin" class="el-menu-item-nested" index="/portainer/" @click="goOut({ path: '/portainer/' })">
                <template #title>
                  <v-svg src="portainer" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
                  <span>{{ $t('Portainer') }}</span>
                </template>
              </el-menu-item>
              <el-menu-item v-if="isAdmin" class="el-menu-item-nested" index="/grafana/" @click="goOut({ path: '/grafana/' })">
                <template #title>
                  <v-svg src="grafana" width="20" height="14" fill="#949698" cc="fal-menu"></v-svg>
                  <span>{{ $t('Grafana') }}</span>
                </template>
              </el-menu-item>

              <el-menu-item @click="visual.visibleAboutCompanyModal = true" class="el-menu-item-nested" index="1-5">
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="info-circle" />
                  <span> {{ $t('About Tekvel') }} </span>
                </template>
              </el-menu-item>
            </el-menu>
          </div>
        </n-popover>
      </n-config-provider>
      <n-config-provider v-if="showUserMenu" :theme="theme">
        <n-popover trigger="click" class="nav-top-black-popup" raw ref="menuUser">
          <template #trigger>
            <v-icon size="24" class="ml-3 cursor-pointer">
              <v-svg fill="#949698" width="20" height="14" src="user"></v-svg>
            </v-icon>
          </template>
          <div class="pop-body">
            <el-menu index="2" v-if="page !== '/open/wheel'" @select="menuUserTriggered">
              <template #title>
                <font-awesome-icon class="fal-menu" icon="user" />
              </template>

              <el-menu-item
                :disabled="page === '/open/wheel'"
                class="el-menu-item-nested"
                index="2-2"
                @click="showUserProfile"
              >
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="address-book" />
                  <span>{{ $t('Profile') }}</span>
                </template>
              </el-menu-item>
              <el-menu-item class="el-menu-item-nested" index="2-3" @click="doLogout">
                <template #title>
                  <font-awesome-icon class="fal-menu" icon="sign-out-alt" />
                  <span>{{ $t('Logout') }}</span>
                </template>
              </el-menu-item>
            </el-menu>
          </div>
        </n-popover>
      </n-config-provider>
      <v-icon size="24" class="cursor-pointer" v-else @click="goToLink('/login')">
        <v-svg width="20" height="14" src="sign-in-alt" fill="#949698"></v-svg>
      </v-icon>
      <el-menu
        v-if="false"
        mode="horizontal"
        :default-active="activeIndex"
        menu-trigger="hover"
        background-color="#2a2e32"
        text-color="#949698"
        :ellipsis="false"
        active-text-color="#949698"
        :unique-opened="true"
        @select="handleSubmenuSelection"
        style="border: none"
      >
        <el-sub-menu index="1" v-if="page !== '/open/wheel'">
          <template #title>
            <font-awesome-icon class="fal-menu" icon="cog" />
          </template>
          <el-menu-item class="el-menu-item-nested" index="1-1" @click="showUserSettings">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="cogs" />
              <span>{{ $t('Preferences') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item v-if="commissioning"  class="el-menu-item-nested" index="1-1-1" @click="srcMacNull">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="network-wired" />
              <span>{{ $t('Set srcMac default') }}</span>
            </template>
          </el-menu-item>

          <el-menu-item v-if="currentProject" class="el-menu-item-nested" index="1-2" @click="openModal('#invite-user-modal')">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="user-circle" />
              <span>{{ $t('Invite Users') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item
            class="el-menu-item-nested"
            index="1-3"
            v-if="reports.preparedReportsCount"
            @click="showPreparedReports"
          >
            <template #title>
              <font-awesome-icon class="fal-menu" icon="envelope" />
              <span>{{ $t('View prepared reports') }} {{ reports.preparedReportsCount }}</span>
            </template>
          </el-menu-item>
          <el-menu-item
            v-if="isAdmin"
            class="el-menu-item-nested"
            index="/users/manage"
            @click="goToLink('/users/manage')"
          >
            <template #title>
              <font-awesome-icon class="fal-menu" icon="address-book" />
              <span> {{ $t('User management') }} </span>
            </template>
          </el-menu-item>
          <el-menu-item @click="visual.visibleAboutCompanyModal = true" class="el-menu-item-nested" index="1-5">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="info-circle" />
              <span> {{ $t('About Tekvel') }} </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="2" v-if="page !== '/open/wheel'">
          <template #title>
            <font-awesome-icon class="fal-menu" icon="user" />
          </template>

          <el-menu-item :disabled="page === '/open/wheel'" class="el-menu-item-nested" index="2-2" @click="showProfile">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="address-book" />
              <span>{{ $t('Profile') }}</span>
            </template>
          </el-menu-item>
          <el-menu-item class="el-menu-item-nested" index="2-3" @click="doLogout">
            <template #title>
              <font-awesome-icon class="fal-menu" icon="sign-out-alt" />
              <span>{{ $t('Logout') }}</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item v-else index="7" @click="goToLink('/login')">
          <template #title>
            <font-awesome-icon class="fal-menu" icon="sign-out-in" />
            <span>{{ $t('Login') }}</span>
          </template>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import TopApp from '@/components/layout/TopApp.vue';
import { menu } from '@/mixins/menu';
import PopupTopNav from '@/components/layout/PopupTopNav.vue';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useTpws } from '@/stores/tpws';
import { useTpui } from '@/stores/tpui';
import { useReports } from '@/stores/reports';
import { useAuth } from '@/stores/auth';
import { darkTheme } from 'naive-ui';

export default {
  components: { PopupTopNav, TopApp },
  name: 'top-nav-black',
  props: ['dataset'],
  mixins: [menu],
  data() {
    return {
      showPopup: false,
      theme: darkTheme,
      isMenuVisible: false,
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useTpws),
    ...mapStores(useTpui),
    ...mapStores(useReports),
    ...mapStores(useAuth),
    currentProject() {
      return this.tpws.currentProject;
    },
    isAdmin() {
      return this.auth.isAdmin;
    },
    showSettingsMenu() {
      return this.page !== '/open/wheel' && this.auth.user;
    },
    showUserMenu() {
      return this.page !== '/open/wheel' && this.auth.user;
    },
  },
  watch: {
    // "visual.sidebarCollapsed"(newVal) {
    //   this.widthSideBar = newVal ? '64px' : '220px';
    // },
  },
  methods: {
    menuSettingsTriggered(e) {
      dev.log('menuTriggered', e);
      this.$refs.menuSettings.setShow(false);
    },
    menuUserTriggered(e) {
      dev.log('menuTriggered', e);
      this.$refs.menuUser.setShow(false);
    },
    clickPopup() {
      this.showPopup = !this.showPopup;
    },
    handleOpen(e) {
      dev.log('e', e);
    },
    handleSubmenuSelection(i, p, e) {
      // e && e.$parent._name === '<ElSubmenu>' && e.$parent.handleMouseleave();
      // this.$store.commit('setSidebarCollapsed', true);
    },
  },
};
</script>

<style lang="scss">
.top-nav-black {
  &__container {
    display: flex;
    background-color: #2a2e32;
    justify-content: space-between;
  }
  &__left-items {
    display: flex;
    align-items: center;
    margin-left: 33px;
    &_second {
      margin-left: 23px;
    }
  }
  &__text {
    color: #ffffff;
    opacity: 0.5;
    margin: 0;
  }
  &__right-items {
    position: relative;
    display: flex;
    align-items: center;
  }
}
.nav-top-black-popup {
  .el-menu {
    border-right: none;
    background-color: unset;
  }
  .el-menu-item-nested {
    background-color: #2a2e32 !important;
  }
  .el-menu-item {
    color: #949698;
  }
  .el-menu-item:hover {
    color: white;
  }
}
.pop-body {
  padding: 10px;
  background-color: #2a2e32;
  border-radius: 3px;
  border: 0.5px solid #48484e;
}

.top-nav-elm {
  &_varning {
    display: flex;
    position: relative;
  }
}
</style>
