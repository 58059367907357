<template>
  <div class="">
    <div class="container-fluid p-0">
      <div class="mt-2">
        <div id="control-users" :data-permission="permission" :data-users="JSON.stringify(projectusers)">
          <users-control :dataset="controlUsersDataset"></users-control>
          <!--                <scriptx src="/js/project_users.js"></scriptx>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersControl from '@/components/timeline/UsersControl.vue';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePage } from '@/stores/page';

export default {
  name: 'project-users',
  components: {
    UsersControl,
  },
  props: {},
  data: () => {
    return {
      // scripts: ['/js/timeline.js', '/js/project_users.js', '/js/tpui.project.timeline.js', '/js/widget.scl.js'],
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(usePage),
    currentProject() {
      return this.tpws.currentProject;
    },
    projectPageCurrent() {
      return this.projectPage && this.projectPage.current;
    },
    displayName() {
      return this.tpws.displayName;
    },
    current() {
      return this.page.currentPage?.current;
    },
    enableSoftwareLVB() {
      return this.page.currentPage?.enableSoftwareLVB;
    },
    adm() {
      return this.page.currentPage?.adm;
    },
    isLive() {
      return this.page.currentPage?.isLive;
    },
    projectusers() {
      return this.page.currentPage?.projectusers;
    },
    permission() {
      return this.page.currentPage?.permission;
    },
    commissioning() {
      return this.page.currentPage?.commissioning;
    },
    controlUsersDataset() {
      return this.$root.$parseDataset(this.$root.controlUsersDataset);
    },

    url() {
      return this.eventsArchive
        ? `/projects/${this.tpws.projectName}/archive?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.tpws.projectName}/events2`;
    },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },

    fields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
  },
  created() {},
  mounted() {},
  methods: {
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
