<template>
  <div>
    <div
      v-if="currentProject"
      id="notification-wrapper"
      class="notification"
      :class="{
        notification_white: theme === 'white',
        notification_dark: theme === 'dark',
        'd-none': !filteredList?.length && !ignoredList?.length,
      }"
      v-click-outside="closeInfo"
    >
      <div
        @click="switchInfo"
        id="notification-btn"
        class="d-flex align-items-center flex-row text-white pl-3 pr-3 pt-2 pb-2 rounded"
        :class="[{ 'justify-content-end': true, 'text-white': theme === 'dark' }, bgClassColor]"
        style="cursor: pointer; width: 60px"
      >
        <i id="notification-icon" class="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>
        <span id="notification-count" class="font-weight-bold">
          <template v-if="filteredList?.length || ignoredList?.length">{{
            filteredList?.length + ignoredList?.length
          }}</template>
        </span>
      </div>
      <div
        v-show="notifications.dropdownVisible"
        id="notification-dropdown"
        class="notification__dropdown"
        :class="{ notification__dropdown_white: theme === 'white', notification__dropdown_dark: theme === 'dark' }"
      >
        <!--        <simplebar-vue data-simplebar ref="sb" class='pop-scroll'>-->
        <n-scrollbar :style="{ maxHeight: 'calc(100vh - 56px)' }">
          <template v-if="isShowedActual">
            <notification-list type="actual">
              <notification-item
                v-for="(item, index) in filteredList"
                :item="item"
                :isLast="index === filteredList?.length - 1"
                @ignore="onIgnore"
                @event="onEvent"
                @maintenance="onMaintenance"
              ></notification-item>
            </notification-list>
            <div
              v-if="ignoredList?.length && filteredList?.length"
              id="showIgnore"
              class="notification__button notification__button-bottom text-primary"
              @click="onShowIgnored"
            >
              {{ $t('View hidden notifications') }}
            </div>
          </template>
          <template v-else>
            <notification-list type="ignored">
              <div
                v-if="filteredList?.length"
                id="hiddenNotifications"
                class="notification__back text-primary"
                @click="onHideIgnored"
              >
                <i class="fas fa-long-arrow-alt-left"></i>
                {{ $t('Back') }}
              </div>
              <notification-item
                v-for="(item, index) in ignoredList"
                :item="item"
                :isLast="index === ignoredList?.length - 1"
                :isIgnored="true"
                @ignore="onIgnore"
                @event="onEvent"
                @maintenance="onMaintenance"
              ></notification-item>
            </notification-list>
          </template>
        </n-scrollbar>
        <!--        </simplebar-vue>-->
      </div>
    </div>
  </div>
</template>

<script>
// import ClickOutside from 'vue-click-outside';
import { ClickOutside } from 'element-plus';
import SimplebarVue from '@/components/common/SimplebarVue.vue';
import NotificationList from '@/components/notifications/NotificationList.vue';
import NotificationItem from '@/components/notifications/NotificationItem.vue';
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { useNotifications } from '@/stores/notifications';
import { useVisual } from '@/stores/visual';

export default {
  name: 'popup-top-nav',
  props: {
    name: {
      type: String,
    },
    theme: {
      type: String,
      default: 'white',
    },
  },
  components: {
    NotificationList,
    NotificationItem,
  },
  data() {
    return {
      listInternal: [],
      filteredListInternal: [],
      // currentProject: undefined,
      isShowedActual: true,
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useVisual),
    ...mapStores(useNotifications),
    currentProject() {
      return this.tpws.currentProject;
    },
    list() {
      return this.notifications.list;
    },
    initNotifyList() {
      return this.notifications.initNotifyList;
    },
    someIgnorable() {
      return this.initNotifyList?.some((n) => n.ignorable);
    },
    ignoredList() {
      return this.initNotifyList?.filter((n) => n.ignored);
    },
    filteredList() {
      const lst = this.list?.filter((n) => !n.ignored);
      if (!this.tpws.isLive) {
        return lst?.filter((n) => !n.type.toLowerCase().includes('active'));
      } else {
        return lst;
      }
    },
    hasDanger() {
      return this.filteredList?.some((n) => n.severity === 'danger');
    },
    hasWarning() {
      return this.filteredList?.some((n) => n.severity === 'warning');
    },
    showIcon() {
      return (!this.filteredList?.length && this.ignoredList?.length) || this.filteredList?.length;
    },
    bgClassColor() {
      let bgClass = '';
      if (!this.filteredList?.length && this.ignoredList?.length) {
        bgClass = 'bg-secondary';
      } else if (this.filteredList?.length) {
        bgClass = 'bg-info';
        if (this.hasDanger) {
          bgClass = 'bg-danger';
        } else if (this.hasWarning) {
          bgClass = 'bg-warning';
        }
      }
      return bgClass;
    },
    iconBgclass() {
      let iconClass = '';
      if (!this.filteredList?.length && this.ignoredList?.length) {
        iconClass = 'icon-secondary';
      } else if (this.filteredList?.length) {
        iconClass = 'icon-info';
        if (this.hasDanger) {
          iconClass = 'icon-danger';
        } else if (this.hasWarning) {
          iconClass = 'icon-warning';
        }
      }
      return iconClass;
    },
  },
  directives: {
    ClickOutside,
  },
  methods: {
    // ...mapActions(useReports, ['showPreparedReports']),
    onIgnore(ev) {
      dev.log('onIgnore', ev);
      const infoAtr = ev.target.getAttribute('data-ignoreItemList').split(',');
      const list = [
        {
          type: infoAtr[0],
          versionType: infoAtr[1],
          ignored: infoAtr[2] === 'false' ? false : true,
        },
      ];
      this.notifications.ignoreItemList(list);
      return false;
    },
    onEvent(ev) {
      dev.log('onEvent', ev);
      this.emitEvent(ev.target.getAttribute('data-event'));
    },
    onMaintenance(ev) {
      this.tpws.disableMaintenance((res) => {
        if (res.ok) {
          this.notifications.removeByType('maintenance');
        } else {
          console.error(res.err);
        }
      });
    },
    emitEvent(ev) {
      switch (ev) {
        case 'showPrepared':
          this.visual.preparedReportsModalVisible = true;
          this.notifications.dropdownVisible = false;
          break;
      }
      dev.log('emitEvent', ev);
    },
    closeInfo() {
      this.notifications.dropdownVisible = false;
    },
    switchInfo() {
      if (this.currentProject) {
        this.notifications.dropdownVisible = !this.notifications.dropdownVisible;
      }
    },
    addToEnd(item) {
      const list = this.list;
      list.push(item);
      this.list = list;
    },
    addToStart(item) {
      const list = this.list;
      list.unshift(item);
      this.list = list;
    },
    addByIndex(item, index) {
      const list = this.list;
      list.splice(index, 0, item);
      this.list = list;
    },

    injectStyles(id, rule = null) {
      if (rule) {
        $('<div />', {
          html: '<style>' + rule + '</style>',
        })
          .attr('id', id)
          .appendTo('body');
      } else {
        $(`#${id}`).remove();
      }
    },
    onShowIgnored() {
      const actualWrapper = $('#notification-content-actual');
      const ignoredWrapper = $('#notification-content-ignored');

      this.renderIgnoredList();

      const actualHeight = actualWrapper.height();
      const ignoredHeight = ignoredWrapper.height();

      ignoredWrapper.addClass('notification-content-ignored');
      if (actualHeight > ignoredHeight) {
        this.injectStyles(
          'notification-style',
          `.notification-content-ignored::after { min-height: ${Math.abs(actualHeight - ignoredHeight) + 20}px; }`
        );
      }

      actualWrapper.fadeOut(0);
      ignoredWrapper.fadeIn(200);

      this.isShowedActual = false;
    },
    onHideIgnored() {
      const actualWrapper = $('#notification-content-actual');
      const ignoredWrapper = $('#notification-content-ignored');

      ignoredWrapper.removeClass('notification-content-ignored');
      this.injectStyles('notification-style');

      ignoredWrapper.fadeOut(0);
      actualWrapper.fadeIn(200);

      ignoredWrapper.removeClass('notification-content-ignored');

      this.isShowedActual = true;
    },
    renderIgnoredList() {
      if (this.ignoredList?.length <= 0) {
        return this.onHideIgnored();
      }
    },

    init(name = '') {
      this.notifications.loadNotifications();

      $('#notification-dropdown').mouseleave(() => {
        if (!this.isShowedActual) {
          this.onHideIgnored();
        }
      });

      $('#notification-btn').mouseenter(() => {
        if (!this.filteredList?.length && this.ignoredList?.length) {
          this.onShowIgnored();
        }
      });
    },
  },
  mounted() {
    this.init(this.currentProject);
    window.addEventListener('reportPrepared', () => {
      // console.log('ev:notify', this.name);
      this.notifications.reloadNotifications();
    });
    window.addEventListener('reloadNotify', () => {
      // console.log('ev:notify', this.name);
      this.notifications.reloadNotifications();
    });
    window.addEventListener('viewedPrepared', () => {
      // console.log('ev:notify readed');
      this.notifications.viewedItemList([{ type: 'reportPrepared', versionType: 'common' }]);
    });
    // this.getList(this.currentProject);
  },
  watch: {
    filteredList: {
      handler(current) {
        if (!this.filteredList?.length && this.ignoredList?.length) {
          this.onShowIgnored();
        }
      },
      deep: true,
    },
    currentProject: {
      handler(val, old) {
        dev.log('watch this.notifications.reloadNotifications()', val, old);
        if (val && val !== old) {
          dev.log('this.notifications.reloadNotifications()');
          this.notifications.reloadNotifications();
        } else {
          dev.log('this.notifications.unloadNotifications()');
          this.notifications.unloadNotifications();
        }
      },
      // deep: true
    },
  },
};
</script>

<style lang="scss">
.notification__dropdown_white {
  max-height: calc(100vh - 56px);
}
.pop-scroll,
.pop-scroll .simplebar-placeholder {
  max-height: calc(90vh - 56px);
}

.pop-scroll .simplebar-content {
  //height: 100%;
  flex: 1;
  overflow-y: visible;
}

.notification {
  position: relative;

  &_white {
    color: #6c757d;
  }
  &_dark {
    color: #fff;
  }
  &__dropdown {
    z-index: 9999;
    max-width: 550px;
    min-width: 420px;
    padding: 0;
    margin-top: 4px;
    position: absolute;
    right: 0;
    &_white {
      background-color: #fff;
      box-shadow: 0 1px 3px;
    }
    &_dark {
      background-color: #2a2e32;
    }
  }
}

.icon-danger {
  fill: #dc3545;
}
.icon-info {
  fill: #17a2b8;
}
.icon-warning {
  fill: #ffc107;
}
.icon-secondary {
  fill: #6c757d;
}
.popup-top-nav {
  position: relative;
  &__container-btn {
    margin: 15px;
  }
  &__close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 24px;
    &_grey {
      color: #ffffff;
      opacity: 0.5;
      margin: 0;
    }
  }
}
</style>
