import api from '@/services/api';
import { defineStore } from 'pinia';
import set from 'set-value';
import { i18next } from '@/root.app';

// import rootApp from '../root.app';

import { useAuth } from '@/stores/auth';
import { useTpws } from '@/stores/tpws';
import { usePrefs } from '@/stores/prefs';

export const useTpui = defineStore({
  id: 'tpui',
  state: () => {
    return {
      project: {},
      voice: {
        initialized: false,
        settings: {},
      },
      voiceAvailable: false,
      voiceMessageQueue: 0,
      // voiceSynth: window.speechSynthesis,
      voices: [],
      selectedVoice: undefined,
      context: undefined,
      projectContext: undefined,
    };
  },
  getters: {
    voiceSynth: (state) => {
      return window.speechSynthesis;
    },

    contextResolver(state) {
      return state.context || this.getContext();
    },
    projectContextResolver(state) {
      return state.projectContext || this.getProjectContext();
    },
    language(state) {
      const prefs = usePrefs();
      return prefs.language;
    },
  },
  actions: {
    initTpui() {
      this.voice.settings.defaultLang = 'ru-RU';
      this.voice.settings.moreMessagesFollowTxt = i18next.t('and more messages follow...');
    },
    // getVoices() {
    //   this.voices = this.voiceSynth.getVoices();
    // },
    getVoicesList: () => {
      this.voices = this.voiceSynth.getVoices();
      for (let i = 0; i < this.voices.length; i++) {
        var voiceDescriptionStr;
        voiceDescriptionStr = this.voices[i].name + ' (' + this.voices[i].lang + ')';

        if (this.voices[i].localService) {
          voiceDescriptionStr += ' LOCAL';
        }

        if (this.voices[i].default) {
          voiceDescriptionStr += ' -- DEFAULT';
          this.selectedVoice = this.voices[i];
        }

        dev.log(voiceDescriptionStr);
      }
    },
    selectDefaultVoice() {
      for (let i = 0; i < this.voices.length; i++) {
        if (this.voices[i].localService && this.voices[i].lang.substr(0, 2) == this.language) {
          this.selectedVoice = this.voices[i];
          this.voiceAvailable = true;
          this.voice.initialized = true;
        }
      }

      return selectedVoice;
    },
    speakMessage(message) {
      const prefs = usePrefs();
      if (!prefs.voiceNotifications) return;

      if (!this.voice.initialized) {
        this.selectDefaultVoice();
      }
      try {
        if (this.voiceAvailable) {
          let alreadySpeaking = this.voiceSynth.speaking;
          if (!alreadySpeaking) {
            this.voiceMessageQueue = 0;
            let utterThis = new SpeechSynthesisUtterance(message);
            utterThis.voice = this.selectedVoice;
            this.voiceSynth.speak(utterThis);
          } else {
            this.voiceMessageQueue++;
            if (this.voiceMessageQueue < 2) {
              let utterThis = new SpeechSynthesisUtterance(message);
              utterThis.voice = this.selectedVoice;
              this.voiceSynth.speak(utterThis);
            } else if (this.voiceMessageQueue == 2) {
              let utterThis = new SpeechSynthesisUtterance(tpui.voice.settings.moreMessagesFollowTxt);
              utterThis.voice = this.selectedVoice;
              this.voiceSynth.speak(utterThis);
            } else {
              // Do nothing, too many notifications
            }
          }
        } else {
          throw 'Voice is not available';
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getContext() {
      // const auth = useAuth();
      // console.log('tpui gc');
      // await auth.me();
      // console.log('auth', auth, auth.user);
      // this.context = auth.get;
      dev.log('tpui getContext');
      this.me()
        .then((data) => {
          dev.log('tpui getContext', data);
          this.context = data;
        })
        .catch((err) => {
          dev.log('err', err);
        });
    },
    async me() {
      dev.log('tpui me');
      return new Promise((resolve) => {
        api
          .get('/me')
          .then((response) => {
            const { data } = response;
            resolve(data);
          })
          .catch((error) => {
            console.error('currentUser error', error);
            this.authorized = false;
            // resolve(error);
          });
        // .finally(() => {
        //   resolve();
        // });
      });
    },

    getProjectContext() {
      return new Promise((resolve) => {
        const tpws = useTpws();
        if (tpws.currentProject) {
          api
            .post('/project-context', { project: tpws.currentProject })
            .then(({ data }) => {
              dev.log('getProjectContext resolve', data);
              this.projectContext = data;
              resolve(this.projectContext);
            })
            .catch((error) => {
              console.error('getProjectContext error', error);
              resolve(undefined);
            });
        } else {
          resolve(undefined);
        }
      });
    },
    setProject(state, item) {
      set(state, item.path, item.payload);
    },
  },
});
