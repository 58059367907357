<template>
  <modal-dialog
    :modal="modal"
    v-show="wheel.visibleLastEventsModal"
    v-model:visible="wheel.visibleLastEventsModal"
    ref="modEx"
    @closeModal="closeModal"
    @doExport="doExport"
  >
    <template #title> </template>
    <template #content>
      <last-events-block :data="lastEvents"></last-events-block>
    </template>
    <template #buttons>
      <button class="btn btn-link" id="recentEventsShowAll" @click="goToAll">{{ $t('View all') }}</button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import LastEventsBlock from '@/components/events/LastEventsBlock.vue';
import { mapActions } from 'pinia';
import { useWheel } from '@/stores/wheel';
import { mapStores } from 'pinia';
import { usePrefs } from '@/stores/prefs';

export default {
  name: 'last-events-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    lastEvents: {
      type: Object,
    },
  },
  components: { ModalDialog, LastEventsBlock },
  data: () => {
    return {
      modal: {
        name: 'recent-events',
        title: 'Recent events',
        onSubmit: 'doExport',
        submitBtn: 'OK',
        noActions: false,
        centerActions: true,
      },
      exportFormats: [
        { value: 'pdf', label: 'PDF', active: true },
        { value: 'xlsx', label: 'Excel' },
        { value: 'csv', label: 'CSV' },
      ],
      exportSort: [
        { value: 'asc', label: 'from new to old', active: true },
        { value: 'desc', label: 'from old to new' },
      ],
      selectedFormat: 'pdf',
      selectedSort: 'asc',
      dateTime: {},
    };
  },
  computed: {
    ...mapStores(useWheel),
    ...mapStores(usePrefs),
  },
  mounted() {},
  methods: {
    ...mapActions(useWheel, ['hideLastEventsModal']),
    goToAll() {
      this.hideLastEventsModal();
      this.$nextTick(() => {
        this.$router.push({
          name: 'events',
          query: {
            search: `${
              this.prefs.descriptionType == 'onlyDesc' && this.lastEvents.desc
                ? this.lastEvents.desc
                : this.lastEvents.name
            }`,
          },
        });
      });
    },
    doExport() {
      dev.log('doExport');
      this.$emit('doExport', { format: this.selectedFormat, sort: this.selectedSort });
      this.$emit('update:visibleModal', false);
    },
    closeModal() {
      dev.log('closeModal');
      this.$emit('update:visibleModal', false);
    },
    selectPeriod(p) {
      dev.log(p);
      this.timePresets
        .filter((e) => e.active)
        .forEach((el) => {
          let ell = el;
          let idx = this.timePresets.indexOf(el);
          delete ell['active'];
          // this.timePresets.$set(idx, ell);
          this.timePresets[idx] = ell;
        });
      dev.log(this.timePresets.filter((e) => e.value === p.value));
      let el = this.timePresets.find((e) => e.value === p.value);
      let idx = this.timePresets.indexOf(el);

      el.active = true;
      // this.timePresets.$set(idx, el);
      this.timePresets[idx] = el;
      this.setTimePeriod(p.value);
    },
    setTimePeriod(value) {
      let now = new Date();
      let start = new Date();
      let end = new Date();
      switch (value) {
        case 'five-min':
          start.setMinutes(now.getMinutes() - 5);
          break;
        case 'today':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          break;
        case 'this-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'last-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 6);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'this-month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case 'last-month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        default:
          break;
      }
      this.dateTime = {
        start: {
          date: start.customFormat('#YYYY#-#MM#-#DD#'),
          time: start.customFormat('#hhhh#:#mm#:#ss#'),
        },
        end: {
          date: end.customFormat('#YYYY#-#MM#-#DD#'),
          time: end.customFormat('#hhhh#:#mm#:#ss#'),
        },
      };
    },
  },
  watch: {
    visibleModal: function (val) {
      dev.log('visibleModal', val);
      if (!val) {
        this.$emit('update:visibleModal', val);
      }
    },
  },
};
</script>

<style lang="scss">
#recent-events-modal .modal-dialog {
  max-width: 80%;
}
</style>
<style lang="scss"></style>
