<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.91666 9.99998L12.0833 14.1666V5.83331L7.91666 9.99998Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'caret-down',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
