import api from '@/services/api';
import { defineStore } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePrefs } from '@/stores/prefs';
import { useSocket } from '@/stores/socket';
import eventBus from '@/utils/eventBus';

export const useEvents = defineStore({
  id: 'events',
  state: () => {
    return {
      list: false,
      filterTypes: [],
      eventsArchiveTEnd: 0,
      visibleArchiveDialog: false,
      clustersCache: {},
      hasNotCachedEvents: false,
      hasNewNotCachedEvents: false,
      cacheComplete: false,
      cacheProgress: 0,
      cacheProgressTs: 0,
      storeProgress: 0,
      storeProgressTs: 0,
    };
  },
  getters: {
    clusters: (state) => {
      return state.clustersCache;
    },
    eventsArchiveTS: (state) => {
      return { start: state.eventsArchiveTSrart, end: state.eventsArchiveTEnd };
    },
  },
  actions: {
    setClusterBounds(data) {
      dev.log('setClusterBounds', data);
      if (data) {
        this.clustersCache[data.cluster] = {
          time: data.t,
          prev: data.prev,
          next: data.next,
        };
      }
    },
    progressEventsCache(msg) {
      // dev.log('setCacheStatus', msg);
      const tpws = useTpws();
      const prefs = usePrefs();
      const socket = useSocket();
      // dev.log('ch', tpws.currentProject, msg.projectName, tpws.currentProject === msg.projectName)
      if ((!msg.peronal || socket.currentId === msg.peronal) && tpws.currentProject === msg.project && prefs.language === msg.lang) {
        this.cacheProgress = msg.progress;
        this.cacheProgressTs = new Date().getTime();
      }

    },
    progressEventsCacheStore(msg) {
      // dev.log('setCacheStatus', msg);
      const tpws = useTpws();
      const prefs = usePrefs();
      const socket = useSocket();
      // dev.log('ch', tpws.currentProject, msg.projectName, tpws.currentProject === msg.projectName)
      if ((!msg.peronal || socket.currentId === msg.peronal) && tpws.currentProject === msg.project && prefs.language === msg.lang) {
        this.storeProgress = msg.progress;
        this.storeProgressTs = new Date().getTime();
      }

    },
    eventsCacheReady(msg) {
      // dev.log('setCacheStatus', msg);
      const tpws = useTpws();
      const prefs = usePrefs();
      const socket = useSocket();
      // dev.log('ch', tpws.currentProject, msg.projectName, tpws.currentProject === msg.projectName)
      if ((!msg.peronal || socket.currentId === msg.peronal) && tpws.currentProject === msg.project && prefs.language === msg.lang) {
        eventBus.dispatch('eventsReload');
      }
    },
    eventsCacheSilentReady(msg) {
      // dev.log('setCacheStatus', msg);
      const tpws = useTpws();
      const prefs = usePrefs();
      // dev.log('ch', tpws.currentProject, msg.projectName, tpws.currentProject === msg.projectName)
      if (tpws.currentProject === msg.project && prefs.language === msg.lang) {
        eventBus.dispatch('eventsSilentUpdate');
      }
    },
    setCacheStatus(msg) {
      // dev.log('setCacheStatus', msg);
      const tpws = useTpws();
      const prefs = usePrefs();
      // dev.log('ch', tpws.currentProject, msg.projectName, tpws.currentProject === msg.projectName)
      if (tpws.currentProject === msg.project && prefs.language === msg.lang) {
        this.hasNotCachedEvents = msg.hasNotCached;
        this.hasNewNotCachedEvents = msg.hasNewNotCached;
      }
    },
    setEventsArchive(value) {
      dev.log('setEventsArchive', value);
      this.eventsArchive = value;
    },
    setEventsArchiveTS(value) {
      dev.log('setEventsArchiveTS', value);
      this.eventsArchiveTSrart = value.tstart;
      this.eventsArchiveTEnd = value.tend;
    },
  },
});
