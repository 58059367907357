<template>
  <li
    class="list-group-item d-flex justify-content-between align-items-center live_lvb_item"
    @click="getLvbDescription"
  >
    {{ $t('LVB') }} {{ lvb.description }}
    <span class="text-success" v-if="lvb.state">
      <i class="fal fa-check"></i>
    </span>
    <span class="text-danger" v-else>
      <i class="fal fa-exclamation-triangle"></i>
    </span>
  </li>
</template>

<script>
import { useWheel } from '@/stores/wheel';
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useTpws } from '@/stores/tpws';
import api from '@/services/api';

export default {
  name: 'live-lvb',
  props: ['lvb'],
  computed: {
    ...mapStores(useWheel),
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
  },
  methods: {
    ...mapActions(useWheel, ['showLvbDescriptionModal']),
    showLvbDescription(data) {
      var lvbs = data.data.desc;
      for (let obj of lvbs) {
        if (obj['lvb_id_str'] != this.lvb.lvb_id_str) continue;
        $('.lvb-description-modal__table').DataTable({
          destroy: true,
          data: [obj],
          paging: false,
          ordering: false,
          info: false,
          searching: false,
          columns: [{ data: 'description' }, { data: 'type' }, { data: 'rack' }, { data: 'location' }, { data: 'ip' }],
          columnDefs: [
            {
              targets: 0,
              defaultContent: 'description',
            },
            {
              targets: 1,
              defaultContent: 'GOOSE/SV',
              render: function (data) {
                return data == 1 ? 'GOOSE/SV' : 'MMS';
              },
            },
            {
              targets: 2,
              defaultContent: 'rack',
            },
            {
              targets: 3,
              defaultContent: 'location',
            },
            {
              targets: 4,
              defaultContent: '0.0.0.0',
            },
          ],
        });
      }
    },
    getLvbDescription() {
      const isOpenWheel = window.location.pathname.includes('open/wheel');
      let lvd_id = this.lvb.lvb_id_str;
      if (!lvd_id) return;
      this.showLvbDescriptionModal();
      $('.lvb-description-modal__title').html(lvd_id);
      api
        .get(isOpenWheel ? '/open/get-lvb-list' : `/projects/${this.currentProject}/get-lvb-list`)
        .then(this.showLvbDescription)
        .catch((error) => {
          console.error(error.statusText);
        });
    },
  },
};
</script>

<style scoped></style>
