<template>
  <div ref="element">
    <div class="simplebar-wrapper">
      <div class="simplebar-height-auto-observer-wrapper">
        <div class="simplebar-height-auto-observer" />
      </div>
      <div class="simplebar-mask">
        <div class="simplebar-offset">
          <div
            class="simplebar-content-wrapper"
            ref="scrollElement"
            v-on="{
              ...($attrs.onScroll && {
                scroll: $attrs.onScroll,
              }),
            }"
          >
            <div
              class="simplebar-content"
              v-resize:debounce="ntRecalculate"
              ref="contentElement"
            >
                <slot></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="simplebar-placeholder" />
    </div>
    <div class="simplebar-track simplebar-horizontal">
      <div class="simplebar-scrollbar" />
    </div>
    <div class="simplebar-track simplebar-vertical">
      <div class="simplebar-scrollbar" />
    </div>
  </div>
</template>

<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';
import eventBus from '@/utils/eventBus';

export default {
  name: 'simplebar-vue',
  props: {
    nsClass: {
      // type: String,
      default: '',
    },
  },
  setup() {
    const uid = Vue.getCurrentInstance().uid;
    return {
      uid,
    };
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      scrollElement: () => {
        return this.$refs.scrollElement.getBoundingClientRect();
      },
      // scrollElement: this.$refs.scrollElement.$el.getBoundingClientRect(),
      scrollTo: (offset) => {
        const scrollElement = this.simpleBar.getScrollElement();
        scrollElement.scrollTo(0, offset);
      },
    };
  },
  data: () => {
    return {
      simpleBar: undefined,
    };
  },
  mounted() {
    if (!this.disabled) {
      const options = SimpleBar.getOptions(this.$refs.element.attributes);
      this.simpleBar = new SimpleBar(this.$refs.element, {
        ...options,
        scrollbarMinSize: '50',
        'overflow-x': 'hidden',
      });
      this.simpleBar.contentWrapperEl.removeAttribute('tabindex');
    }

    eventBus.on('reportResize', (options) => {
      this.ntRecalculate();
    });

    // }
  },
  computed: {
    ...mapStores(useVisual),
    scrollElement() {
      return this.$refs.scrollElement;
    },
    contentElement() {
      return this.$refs.contentElement;
    },
  },
  methods: {
    ntRecalculate() {
      this.$nextTick(() => {
        this.recalculate();
      });
    },
    recalculate() {
      this.simpleBar.recalculate();
    },
    // on() {
    //   console.log('simplebar-on');
    //   const options = SimpleBar.getOptions(this.$refs.element.attributes);
    //   this.simpleBar = new SimpleBar(this.$refs.element, options);
    // },
    // off() {
    //   console.log('simplebar-off');
    //   if (this.simpleBar) {
    //     this.simpleBar.unMount();
    //   }
    // },
  },
  watch: {
    // 'visual.sidebarCollapsed': {
    //   handler(val, oldVal) {
    //     if (val !== oldVal) {
    //       this.$nextTick(() => {
    //         this.recalculate();
    //       });
    //     }
    //   },
    //   // immediate: true,
    // },
    // disabled: {
    //   handler(val) {
    //     if (val) {
    //       this.off();
    //     } else {
    //       this.on();
    //     }
    //   },
    //   // immediate: true,
    // },
  },
};
</script>
<style>
.simplebar-scrollbar::before {
  background-color: rgba(0, 0, 0, 0.33);
}

.native-scroll {
  /*overflow: auto;*/
}
</style>
