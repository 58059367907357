import api from '@/services/api';
import { defineStore } from 'pinia';
import set from 'set-value';

import { useAuth } from '@/stores/auth';
import { useTpws } from '@/stores/tpws';
import { usePrefs } from '@/stores/prefs';

export const useUrls = defineStore({
  id: 'urls',
  state: () => ({}),
  getters: {
    currentUserUrl: () => {
      const auth = useAuth();
      return `/users/${auth.userId}`;
    },
    currentProjectUrl: () => {
      const tpws = useTpws();
      return `/projects/${tpws.currentProject}`;
    },
  },
  actions: {},
});
