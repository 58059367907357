<template>
  <div class="data-table" ref="pageRoot">
    <export-modal v-model:visible-modal="visibleExportDialog" @doExport="doExport" object="users"></export-modal>

    <div class="data-table-buttons events-top-controllers">
      <div class="dt-buttons btn-group">
        <template v-for="b in buttons">
          <div class="btn-group" v-if="b.children">
            <button
              class="btn btn-secondary dropdown-toggle"
              :class="'data-table-' + b.name"
              :id="'submenu-' + b.name"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="b.name === 'events-export' && reports.hasPendingReports"
            >
              {{ $t(b.label) }}
            </button>

            <div class="dropdown-menu" :aria-labelledby="'submenu-' + b.name">
              <div class="dt-button-collection-title"></div>
              <template v-for="cb in b.children">
                <a
                  class="dt-button dropdown-item buttons-pdf buttons-html5"
                  tabindex="0"
                  @click="execAction(cb, $event)"
                  ><span>{{ $t(cb.label) }}</span></a
                >
              </template>
            </div>
          </div>
          <button
            class="btn btn-secondary"
            :class="[
              'data-table-' + b.name,
              {
                disabled:
                  (b.name === 'events-export' && reports.hasPendingReports) ||
                  (!enableView && b.name === 'view-selected'),
              },
            ]"
            @click="execAction(b, $event)"
            :disabled="
              (b.name === 'events-export' && reports.hasPendingReports) || (!enableView && b.name === 'view-selected')
            "
            v-else
          >
            {{ $t(b.label) }}
          </button>
        </template>
      </div>
    </div>
    <div style="display: none" ref="print-block">
      <table class="data-table display" :class="className" ref="print-tab">
        <thead>
          <tr role="row">
            <th v-for="f in fields" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
              {{ $t(f.label) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in filteredPrintRowsData"
            :row-data="rowData"
            :fields="printFields"
            :key="rowData._id"
            :print="true"
            :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1 }]"
            @select="selectRow(idx)"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ $t(f.label) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="data-table-loading-wrapper pb-5" v-loading="loading">
      <table class="data-table display" :class="className">
        <col v-for="f in fields" :key="f.name" :style="f.style" />

        <thead>
          <tr role="row">
            <template v-for="f in fields" :key="f.name">
              <th
                v-if="f.visible !== false"
                :data-gap="f.name === 'timeDT' && gap"
                :class="columnSortClass(f)"
                @click="changeSort(f)"
                v-html="renderLabel(rowData, f)"
              ></th>
            </template>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in pageRowsData"
            :row-data="rowData"
            :fields="fields"
            :key="rowData._id"
            :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
            class="analysis-cell"
            @select="selectRow(idx)"
            @colAction="colAction"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ $t(f.label) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="floating-pager" style="width: auto" :style="{ left: visual.contentLeft + Math.floor(visual.contentWidth / 2) + 'px' }">
      <div class="floating-pager__inner">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="currentPage"
          :page-sizes="[4, 5, 7, 17, 50, 100, 300]"
          :page-size="pageLength"
          layout="total, sizes, prev, pager, next, jumper"
          :total="recordsFiltered"
        >
        </el-pagination>
      </div>
    </div>
    <teleport to="#modals-target">
      <new-user-modal></new-user-modal>
      <edit-user-modal :rowUser="rowUser"></edit-user-modal>
      <change-password-modal :rowUser="rowUser"></change-password-modal>
    </teleport>
  </div>
</template>

<script>
import DataRow from '../common/DataRow.vue';
// import loading from 'vue-loading';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/css/index.css';

import ExportModal from '@/components/modals/ExportModal.vue';
import NewUserModal from '@/components/auth/NewUserModal.vue';
import EditUserModal from '@/components/modals/EditUserModal.vue';
import ChangePasswordModal from '@/components/modals/ChangePasswordModal.vue';
// import resize from 'vue-resize-directive';

import api from '@/services/api';
import { Zone, DateTime } from 'luxon';
import VueSticky from 'vue-sticky';
import { mapActions } from 'pinia';
import { useUsers } from '@/stores/users';
import { useTpws } from '@/stores/tpws';
import { useVisual } from '@/stores/visual';
import { mapStores } from 'pinia';
import { useReports } from '@/stores/reports';
import { useLicense } from '@/stores/license';
import { h } from 'vue/dist/vue.esm-bundler';
import { vSvg } from '@/utils/vSvg';
import tinyPrint from 'tiny-print';
import {firstBy} from "thenby";

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    lengthMenu: {
      type: Array,
    },
    noSearch: {
      defauld: false,
    },
    extButtons: {
      type: Array,
    },
    query: {
      type: Object,
    },
    atrs: Object,
  },
  directives: {
    // resize,
  },

  components: {
    ExportModal,
    DataRow,
    Loading,
    NewUserModal,
    EditUserModal,
    ChangePasswordModal,
    vSvg,
  },
  // directives: { loading },
  name: 'user-manage-table',
  data: () => {
    return {
      sortables: [],
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      pageStart: 0,
      pageLength: 17,
      searchString: '',
      // searchTypes: [],
      // rowsData: [],
      recordsTotal: 0,
      // recordsFiltered: 0,
      loading: false,
      firstLoad: true,
      draw: 0,
      errors: [],
      onTypes: [],
      selectedRows: [],
      printRowsData: [],
      visibleArchiveDialog: false,
      visibleExportDialog: false,
      thisEvTable: undefined,
      rowUser: undefined,
    };
  },
  computed: {
    ...mapStores(useUsers, useTpws),
    ...mapStores(useVisual),
    ...mapStores(useReports),
    ...mapStores(useLicense),

    rowsData() {
      return this.users?.users || [];
    },

    currentProject() {
      return this.tpws.currentProject;
    },
    displayName() {
      return this.tpws.displayName;
    },
    currentProjectName() {
      return this.displayName;
    },
    recordsFiltered() {
      return this.filteredRowsData.length;
    },
    fields() {
      return [
        {
          label: this.$t('Id'),
          name: 'id',
          data: '_id',
          style: { width: 64 + 'px' },
          sortable: true,
        },
        {
          label: this.$t('Email'),
          name: 'email',
          data: 'email',
          style: { width: 20 + '%' },
          sortable: true,
        },
        {
          label: this.$t('Firstname'),
          name: 'firstname',
          data: 'firstname',
          style: { width: 15 + '%' },
          sortable: true,
        },
        {
          label: this.$t('Lastname'),
          name: 'lastname',
          data: 'lastname',
          style: { width: 15 + '%' },
          sortable: true,
        },
        {
          label: this.$t('Company'),
          name: 'company',
          data: 'company',
          style: { width: 12 + '%' },
          sortable: true,
        },
        {
          label: this.$t('Position'),
          name: 'position',
          data: 'position',
          style: { width: 12 + '%' },
          sortable: true,
        },
        {
          label: this.$t('Phone'),
          name: 'phone',
          data: 'phone',
          style: { width: 13 + '%' },
          sortable: true,
        },
        {
          label: this.$t('Activation'),
          name: 'activation',
          data: 'activation',
          style: { width: 10 + '%' },
          action: 'activateUser',
          render: (d, r) => {
            dev.log('r', r);
            return `<span class="${
              r.activation === null ? 'fa-check-square' : 'fa-square'
            } fal" style="display:block; text-align:center;"></span>`;
          },
          sortable: true,
        },
        {
          label: this.$t('Coins'),
          name: 'coins',
          data: 'coins',
          style: { width: 10 + '%' },
          sortable: true,
        },
        {
          label: '',
          name: 'chpwd',
          data: 'chpwd',
          style: { width: 64 + 'px' },
          action: 'chPassword',
          render: (d, r) => {
            return `<button class="btn btn-block"><span class="fal fa-key" /></button>`;
          },
          sortable: false,
        },
        {
          label: '',
          name: 'actions',
          data: 'actions',
          style: { width: 64 + 'px' },
          action: 'editUser',
          render: (d, r) => {
            return `<button class="btn btn-block btn-success">
                        <img src="/icons/pencil.svg" alt="edit">
                    </button>`;
          },
          sortable: false,
        },
      ];
    },
    printFields() {
      return this.fields.filter((f) => f.name !== 'actions' && f.name !== 'chpwd');
    },
    filteredPrintRowsData() {
      return this.printRowsData.map((el) => ({ ...el, chpwd: undefined, actions: undefined }));
    },

    filteredRowsData() {
      return this.rowsData;
    },
    rowsDataSorted(){
      const normalized = this.filteredRowsData.map(el=>({...el, id: el._id, activation: el.activation === null ? null : true}));
      const sorted = normalized.sort(firstBy(this.currentSortField, this.currentSortDir === 1 ? 'asc': 'desc'))
      return sorted;
    },
    pageRowsData() {
      let start = (this.currentPage - 1) * this.pageLength;
      let end = this.currentPage * this.pageLength;
      if (end > this.rowsDataSorted.length) {
        end = this.rowsDataSorted.length;
      }
      return this.rowsDataSorted.slice(start, end);
    },
    buttons() {
      let buttons = [
        {
          name: 'print',
          label: 'Print',
          action: 'printTable',
        },
        {
          name: 'new-user',
          label: 'New user',
          action: 'addNewUser',
        },
        {
          name: 'users-export',
          label: 'Export',
          action: 'exportDialog',
        },
      ];

      let found;

      return buttons;
    },
    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    enableView() {
      return this.rowsDataSelected.filter((e) => e.hasRef).length > 1;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    order() {
      return {
        column: this.fields.map((e) => e.name).indexOf(this.currentSortField),
        dir: this.currentSortDir === 1 ? 'asc' : 'desc',
      };
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$t('Goose') },
        { name: 'SV', label: this.$t('SV') },
        { name: 'SRV', label: this.$t('Service events') },
        { name: 'ARP', label: this.$t('ARP') },
        { name: 'MMS', label: this.$t('MMS') },
        { name: 'USER', label: this.$t('User events') },
      ];
    },

    search() {
      let searchArray = [];
      if (this.searchString !== '') {
        searchArray = [this.searchString];
      }
      return {
        value: searchArray.join(','),
        regex: false,
      };
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      let searchObj = {
        draw: this.draw,
        order,
        search,
        start: this.pageStart,
        length: this.pageLength,
      };

      if (this.query) {
        return this.$qs.stringify({ ...this.query, ...searchObj, removeUnfiltered: true });
      } else {
        return this.$qs.stringify(searchObj);
      }
    },
  },
  beforeMount() {
    try {
      let onTypes = this.eventTypes.map((e) => e.name);
      let ss = this.$route.query;
      let ssArr = this.$qs.parse(ss);
      let types = ssArr['search']
        .split(',')
        .filter((e) => e.indexOf('!') > -1)
        .map((e) => e.substring(1));
      dev.log('ss', ssArr, onTypes);
      onTypes.forEach((el) => {
        if (types.indexOf(el) < 0) {
          this.onTypes.push(el);
        }
      });
      this.$nextTick(() => {
        this.visibleTable = true;
      });
    } catch (e) {
      this.visibleTable = true;
    }
    if (this.extButtons) {
      this.buttons = this.extButtons;
    }
  },
  mounted() {
    this.loadUsers();
  },
  beforeUnmount() {
    this.unloadUsers();
  },
  methods: {
    ...mapActions(useUsers, [
      'loadUsers',
      'unloadUsers',
      'showNewUserModal',
      'showEditUserModal',
      'showChangePasswordModal',
    ]),
    printTable() {
      // const d = new Printd()
      // d.print(this.$refs['print-block'], '.color { color: blue; }')
      this.loadPrintData().then(() => {
        dev.log('pr th');
        this.$nextTick(() => {
          tinyPrint(this.$refs['print-block'], {
            scanStyles: true,
            scanHTML: true,
            cssStyle: `a { text-decoration: none!important; }`,
          });
          this.$nextTick(() => {
            this.printRowsData = [];
          });
        });
      });
    },
    loadPrintData() {
      return new Promise((resolve, reject) => {
        this.printRowsData = this.filteredRowsData;
        this.$nextTick(() => {
          resolve('Success!');
          this.loading = false;
        });
      });
    },
    renderLabel(row, f) {
      if (f.label && typeof f.label === 'function') {
        return f.label();
      } else {
        return this.$t(f.label);
      }
    },

    addNewUser() {
      this.showNewUserModal();
    },
    colAction(a, e, r) {
      dev.log('colAction', a, e, r);
      if (this[a]) {
        this[a](e, r);
      } else {
        dev.log('No action: ', a);
      }
    },
    editUser(e, r) {
      this.rowUser = r;
      dev.log('editUser', e, r);
      this.showEditUserModal();
    },
    async activateUser(e, r) {
      dev.log('activateUser', e, r);
      if (!r.activation) {
        dev.log('activated', e, r);
        return;
      }
      const resp = await api.post(`/users/_activation`, { id: r._id });
      dev.log('new user', resp);
      if (resp.status) {
        notifyKas.show({ text: this.$t('User successfully activated'), close: true, type: 'success' });
        this.loadUsers();
        // this.hideNewUserModal();
      } else {
        notifyKas.show({ text: this.$t('User activation problem'), close: true, type: 'warning' });
      }
    },
    chPassword(e, r) {
      this.rowUser = r;
      dev.log('chPassword', e, r);
      this.showChangePasswordModal();
    },
    handleSizeChange(e) {
      this.pageLength = e;
      dev.log('set size', e);
    },
    handleCurrentChange(e) {
      // this.currentPage = e;
      dev.log('set page', e);
    },
    doExport(e) {
      dev.log('doExport', e);

      let exportFormat = e.format;
      let exportSort = e.sort;
      let fileDateTime = DateTime.local().toFormat('yyyyLLdd');
      let exportFilename =
        `${this.$t('Users list')} - ${fileDateTime}.` + exportFormat;

      // this.$('#export2-events-modal').modal('hide');

      // const loader = this.$('#loading_file');
      // loader.show();
      this.$socket.send(
        JSON.stringify({
          type: 'users-file-export',
          content: {
            data: this.filteredRowsData,
            header: {
            },
            license: this.license.license.license_data.version,
            exportFilename: exportFilename,
            exportFormat: exportFormat,
          },
        })
      );

      // api
      //   .post(`/users/users-file-export`, {
      //     search: this.search,
      //     draw: 1,
      //     exportFilename: exportFilename,
      //     exportFormat: exportFormat,
      //     exportSort: exportSort,
      //   })
      //   .then((response) => {})
      //   .catch((e) => {
      //     console.log('err', e);
      //   })
      //   .then(function () {
      //     // loader.hide();
      //   });

      /*
      this.$.ajax({
        url: `/projects/${this.currentProject}/events-file-export`,
        type: 'post',
        dataType: 'json',

        data: {
          ...table.ajax.params(),
          draw: 1,
          exportFilename: exportFilename,
          exportFormat: exportFormat,
          exportSort: exportSort,
        },
        error: function (err) {

        },
        success: function (response) {
        },
        complete: function () {

        },
      });
*/
    },
    switchType(type) {
      dev.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.$nextTick(() => {
        // this.pageChange();
        this.$emit('pageUpdate');
      });
    },

    execAction(b, e, r) {
      if (b.extAction) {
        this.$emit('ext-action', b.extAction, e, r);
      }
      if (b.action) {
        this[b.action](e, r);
      }
    },
    selectAll() {
      for (let i = 0; i < this.pageLength - 2; i++) {
        const rowInx = this.pageStart + i;
        if (this.selectedRows.indexOf(rowInx) < 0) {
          this.selectedRows.push(rowInx);
        } else {
          // this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
        }
      }
    },
    exportXls(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.eventsArchive
        ? `/projects/${this.currentProject}/archive-xls?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.currentProject}/events2-xls`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProjectName} ` + this.$t('analysis report') + `.xlsx`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportPdf(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.eventsArchive
        ? `/projects/${this.currentProject}/archive-pdf?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.currentProject}/events2-pdf`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProjectName} ` + this.$t('analysis report') + `.pdf`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportCsv(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.eventsArchive
        ? `/projects/${this.currentProject}/archive-csv?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.currentProject}/events2-csv`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProjectName} ` + this.$t('analysis report') + `.csv`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportDialog(e) {
      this.visibleExportDialog = true;
    },
    viewCritical(e) {
      dev.log('openSelected', e);
      const ts = this.rowsDataSelectedTime;
      if (e.ctrlKey || e.shiftKey || e.altKey)
        window.open(`?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`, '_blank');
      else location.href = `?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`;
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      dev.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          return 'sorting_asc';
        }
        if (this.currentSortDir === -1) {
          return 'sorting_desc';
        }
      } else {
        return f.sortable ? 'sorting' : '';
      }
    },
    changeSort(f) {
      dev.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.$emit('pageUpdate');
    },
  },
  watch: {
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
  },
};
</script>

<style scoped>
.floating-pager {
  position: fixed;
  bottom: 0;
  text-align: center;
}

.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .analysis-cell {
  word-break: break-all;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
  *cursor: hand;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
