import { createRouter, createWebHistory } from 'vue-router';
import { compile, match, parse, pathToRegexp } from 'path-to-regexp/dist/index';

import qs from '@/utils/qs';

// Vue.use(Router);
import ProjectsListApp from '@/components/projects/ProjectsListApp.vue';
import ProjectPage from '@/components/timeline-old/ProjectPage.vue';
import ProjectTimeline from '@/components/timeline-old/ProjectTimeline.vue';
import ProjectUsers from '@/components/timeline/ProjectUsers.vue';
import ProjectLvb from '@/components/timeline/ProjectLvb.vue';

import WheelPage from '@/components/wheel/WheelPage.vue';
import WheelNew from '@/components/wheel/WheelNew.vue';
import EventsPage from '@/components/events/EventsPage.vue';
import SignalsView from '@/components/signals/SignalsView.vue';
import AnalysisPage from '@/components/analysis/AnalysisPage.vue';
import ClustersPage from '@/components/clusters/ClustersPage.vue';
import ClustersEventsPage from '@/components/clusters/ClustersEventsPage.vue';
import Report from '@/components/reports/Report.vue';
import SigninPage from '@/components/auth/SigninPage.vue';
import LicenseProblem from '@/components/pages/LicenseProblem.vue';
import SignupPage from '@/components/auth/SignupPage.vue';
import ForgotPage from '@/components/auth/ForgotPage.vue';
import ResetPage from '@/components/auth/ResetPage.vue';
import ActivationPage from '@/components/auth/ActivationPage.vue';
import UsersManagement from '@/components/users/UsersManagement.vue';
import TosPage from '@/components/pages/TosPage.vue';

import { beforeEach, afterEach } from './guards';

export const router = createRouter({
  history: import.meta.env ? createWebHistory(import.meta.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'client-root',
      components: { default: { name: 'none' } },
      redirect: { name: 'projects', params: { userId: 0 } },
    },
    {
      path: '/license-problem',
      name: 'license-problem',
      component: LicenseProblem,
      meta: {
        noLicense: true,
        noAuth: true,
        layout: 'app-layout-error',
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      meta: {
        noLicense: true,
        noAuth: true,
        authRedirect: true,
      },
      component: SigninPage,
    },
    {
      path: '/signup',
      name: 'auth-signup',
      meta: {
        noLicense: true,
        noAuth: true,
      },
      component: SignupPage,
    },
    {
      path: '/forgot',
      name: 'auth-forgot',
      meta: {
        noLicense: true,
        noAuth: true,
      },
      component: ForgotPage,
    },
    {
      path: '/reset',
      name: 'auth-reset',
      meta: {
        noLicense: true,
        noAuth: true,
      },
      component: ResetPage,
    },
    {
      path: '/activation',
      name: 'auth-activate',
      meta: {
        noLicense: true,
        noAuth: true,
      },
      component: ActivationPage,
    },
    {
      path: '/tos',
      name: 'tos-page',
      meta: {
        noLicense: true,
        noAuth: true,
      },
      component: TosPage,
    },
    {
      path: '/users/:userId/projects',
      props: {
        cluster: '',
      },
      meta: {
        noData: true,
      },
      name: 'projects',
      component: ProjectsListApp,
    },
    {
      path: '/projects/:projectId/prepared-reports',
      props: {
        cluster: '',
      },
      name: 'reports-page',
      components: { default: { name: 'none' } },
      meta: { inProject: true },
    },
    {
      path: '/open/wheel',
      props: {
        cluster: '',
      },
      name: 'open-wheel-page',
      meta: {
        noAuth: true,
        authRedirect: true,
        noData: true,
      },
      component: WheelPage,
    },
    {
      path: '/projects/:projectId/wheel',
      name: 'wheel-page',
      component: WheelPage,
      meta: { inProject: true, noData: true },
    },
    {
      path: '/projects/:projectId/wh',
      name: 'wheel-page-new',
      component: WheelNew,
      meta: { inProject: true },
    },
    {
      path: '/projects/:projectId/wheel-live',
      name: 'wheel-live-page',
      component: WheelPage,
      meta: { inProject: true },
    },
    {
      path: '/projects/:projectId/clusters-index',
      props: {
        cluster: '',
      },
      name: 'clusters-index',
      component: ClustersPage,
      meta: { inProject: true },
    },
    {
      path: '/projects/:projectId/events',
      props: {
        cluster: '',
      },
      name: 'events',
      component: EventsPage,
      meta: { inProject: true },
    },
    {
      path: '/projects/:projectId/analysis',
      props: {
        cluster: '',
      },
      name: 'analysis',
      component: AnalysisPage,
      meta: { inProject: true },
    },
    {
      path: '/projects/:projectId/analysis-live',
      props: {
        cluster: '',
      },
      name: 'analysis-live',
      component: AnalysisPage,
      meta: { inProject: true },
    },
    {
      path: '/users/manage',
      props: {
        cluster: '',
      },
      meta: { adminsOnly: true },
      name: 'users-manage',
      component: UsersManagement,
    },

    {
      path: '/projects/:projectId/signals-profile',
      props: {
        blank: false,
      },
      name: 'signals-profile',
      component: SignalsView,
      meta: { inProject: true },
    },
    {
      path: '/projects/:projectId/signals-profile-legacy',
      // props: {
      //   cluster: '',
      // },
      name: 'signals-profile-legacy',
      component: SignalsView,
      // component: ClustersEventsPage,
      // components: { default: { name: 'none' } },
      meta: { inProject: true },
    },
    {
      path: '/projects/:projectId/clusters-profile',
      name: 'clusters-profile',
      components: { default: { name: 'none' } },
      meta: { inProject: true },
    },
    // {
    //   path: '/projects/:projectId/space?signals',
    //   name: 'signals-profile-old',
    //   components: { default: { name: 'none' } },
    // },
    {
      path: '/projects/:projectId/reports/:reportCode',
      name: 'report',
      meta: { noData: true, inProject: true },
      component: Report,
    },
    // {
    //   path: '/projects/:projectId/space',
    //   name: 'project',
    //   components: { default: { name: 'none' } },
    // },
    {
      path: '/projects/:projectId',
      name: 'current-project',
      component: ProjectPage,
      redirect: { name: 'project-timeline' },

      children: [
        { path: '', name: 'project-timeline', component: ProjectTimeline, meta: { inProject: true } },
        { path: 'project-users', name: 'project-users', component: ProjectUsers, meta: { inProject: true } },
        { path: 'project-lvb', name: 'project-lvb', component: ProjectLvb, meta: { inProject: true } },
      ],
    },
    // {
    //   path: '/projects/:projectId/scd/new',
    //   name: 'scd-new-page',
    //   component: ScdNewPage,
    // },
  ],
});

router.beforeEach(beforeEach);

router.afterEach(afterEach);

// router.beforeEach((to, from, next) => {
//   // console.log('beforeEach', to);
//   // console.log('cp', store.state.currentProject, to);
//   let projectName;
//   // const keys = [];
//   // const regexp = pathToRegexp("/projects/:projectId/(.*)", keys);
//   //     const res = regexp.exec(to.path);
//
//   // console.log('cp res', params);
//   if (!store.state.currentProject) {
//     if (to.params.hasOwnProperty('projectId')) {
//       projectName = to.params['projectId'];
//     } else {
//       const pattern = '/projects/:projectId/(.*)';
//       const path = to.path;
//       const converter = match(pattern, { decode: decodeURIComponent });
//       const params = converter(path)?.params || {};
//       if (params.hasOwnProperty('projectId')) {
//         projectName = params['projectId'];
//       }
//     }
//     if (projectName) {
//       store.dispatch('loadCurrentProject', projectName);
//     }
//   }
//
//   const nearestWithTitle = to.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.title);
//   const nearestWithMeta = to.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.metaTags);
//   const previousNearestWithMeta = from.matched
//     .slice()
//     .reverse()
//     .find((r) => r.meta && r.meta.metaTags);
//
//   if (nearestWithTitle) {
//     document.title = `${$.i18n('Tekvel Park')} — ${$.i18n(nearestWithTitle.meta.title)}`;
//   } else if (previousNearestWithMeta) {
//     document.title = `${$.i18n('Tekvel Park')} — ${$.i18n(previousNearestWithMeta.meta.title)}`;
//   }
//
//   localStorage.setItem('lastRoute', from.name);
//   const lastRouteName = localStorage.getItem('lastRoute');
//
//   const shouldRedirect = Boolean(to.name === 'home' && lastRouteName);
//
//   if (shouldRedirect) next({ name: lastRouteName });
//   else next();
// });
//
// router.afterEach((from) => {});

export default router;
