<template>
  <div class="btn-group-toggle" :class="parentElem + '__' + groupName + '-container'" data-toggle="buttons">
    <label
      class="btn btn-light"
      v-for="elem in data"
      :class="[{ active: elem.value === current }, parentElem + '__' + groupName + '-btn', 'btn-group-toggle__btn']"
      :id="$t(elem.label)"
      @click="$emit('selected', elem)"
      >{{ $t(elem.label) }}
      <input
        :class="[
          parentElem + '__' + groupName + '-input',
          parentElem + '__' + elem.value + '-input',
          'btn-group-toggle__input',
        ]"
        :value="elem.value"
        type="radio"
        :name="groupName"
        autocomplete="off"
        :checked="elem.value === current"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'radio-button-group',
  props: ['data', 'current', 'parentElem', 'groupName'],
  components: {},
  data: () => {
    return {};
  },
  mounted() {
    if (this.data.length > 0 && !this.current) {
      this.$emit('selected', this.data[0]);
    }
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
