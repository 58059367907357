<template>
  <div class="data-table">
    <archive-events-modal :visible-modal.sync="visibleArchiveDialog"></archive-events-modal>
    <export-events-modal></export-events-modal>

    <div class="data-table-buttons events-top-controllers">
      <div class="dt-buttons btn-group">
        <template v-for="b in buttons">
          <div class="btn-group" v-if="b.children">
            <button
              class="btn btn-secondary dropdown-toggle"
              :class="'data-table-' + b.name"
              :id="'submenu-' + b.name"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ $t(b.label) }}
            </button>

            <div class="dropdown-menu" :aria-labelledby="'submenu-' + b.name">
              <div class="dt-button-collection-title"></div>
              <template v-for="cb in b.children">
                <a
                  class="dt-button dropdown-item buttons-pdf buttons-html5"
                  tabindex="0"
                  @click="execAction(cb.action, $event)"
                  ><span>{{ $t(cb.label) }}</span></a
                >
              </template>
            </div>
          </div>
          <button
            class="btn btn-secondary"
            :class="['data-table-' + b.name, !enableView && b.name === 'view-selected' ? 'disabled' : '']"
            @click="execAction(b.action, $event)"
            v-else
          >
            {{ $t(b.label) }}
          </button>
        </template>
      </div>
      <div id="vue_events_table_filter" class="dataTables_filter">
        <label
          >Поиск:<input
            v-model="searchString"
            type="search"
            class="form-control form-control-sm"
            placeholder=""
            aria-controls="wheel_events_table"
        /></label>
      </div>
    </div>
    <div class="event-types-block">
      <span v-for="t in eventTypes" class="text-secondary mr-md-4 btn event-type" @click="switchType(t)" :key="t.name">
        <span :class="'fa-' + (onTypes.indexOf(t.name) < 0 ? '' : 'check-') + 'square fal'"></span
        ><span class="type-label">&nbsp;{{ $t('type.' + t.name) }}</span>
      </span>
    </div>
    <div class="data-table-loading-wrapper" v-loading="loading">
      <table class="data-table display" :class="className">
        <thead>
          <tr role="row">
            <template v-for="f in fields">
              <th v-if="f.visible !== false" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
                {{ $t(f.label) }}
              </th>
            </template>
          </tr>
        </thead>

        <tbody>
          <template v-for="(rowData, idx) in rowsData" :key="'tpl-' + idx">
            <data-row
              :row-data="rowData"
              :fields="fields"
              :class-name="['high-row', { even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
              @select="clickRow(idx)"
              @colAction="colAction"
              :gap="rowData.timeGapDT"
            ></data-row>
            <detail-row v-if="rowData.showDetails" :fields="fields">
              <cluster-events-table :rows-data="rowData.children" :fields="eventsFields"> </cluster-events-table>
            </detail-row>
          </template>
        </tbody>
        <tfoot>
          <tr role="row">
            <template v-for="f in fields">
              <th v-if="f.visible !== false" :key="f.name">
                {{ $t(f.label) }}
              </th>
            </template>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="data-info">
      <div class="events-page-info">
        <div class="dataTables_length" id="wheel_events_table_length">
          <label
            >Показать
            <select
              name="wheel_events_table_length"
              aria-controls="wheel_events_table"
              class="custom-select custom-select-sm form-control form-control-sm"
              v-model="pageLength"
            >
              <option v-for="pl in lengthMenu" :value="pl">{{ pl }}</option>
            </select>
            записей</label
          >
        </div>
        <div class="dataTables_info" id="wheel_events_table_info" role="status" aria-live="polite">
          Записи с {{ recordLeft }} до {{ recordRight }} из {{ recordsTotal }} записей
        </div>
      </div>
    </div>
    <div class="data-pages">
      <div class="dataTables_paginate paging_simple_numbers" id="wheel_events_table_paginate">
        <ul class="pagination">
          <li
            v-if="currentPage > minPage"
            @click="currentPage = currentPage - 1"
            class="paginate_button page-item previous"
            id="wheel_events_table_previous"
          >
            <a aria-controls="wheel_events_table" data-dt-idx="0" tabindex="0" class="page-link">Предыдущая</a>
          </li>
          <li v-else class="paginate_button page-item previous disabled" id="wheel_events_table_previous">
            <a aria-controls="wheel_events_table" data-dt-idx="0" tabindex="0" class="page-link">Предыдущая</a>
          </li>
          <li
            v-for="(p, i) in pagesList"
            class="paginate_button page-item"
            :class="{ disabled: p === null, active: p === currentPage }"
            :key="i + '-' + p"
            @click="setCurrentPage(p)"
          >
            <a aria-controls="wheel_events_table" :data-dt-idx="i + 1" tabindex="0" class="page-link">{{
              p !== null ? p : '...'
            }}</a>
          </li>
          <li
            v-if="currentPage < maxPage"
            @click="currentPage = currentPage + 1"
            class="paginate_button page-item next"
            id="wheel_events_table_next"
          >
            <a aria-controls="wheel_events_table" :data-dt-idx="pagesList.length + 2" tabindex="0" class="page-link"
              >Следующая</a
            >
          </li>
          <li v-else class="paginate_button page-item next disabled" id="wheel_events_table_next">
            <a aria-controls="wheel_events_table" :data-dt-idx="pagesList.length + 2" tabindex="0" class="page-link"
              >Следующая</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue';
import DetailRow from '@/components/common/DetailRow.vue';
import ClusterEventsTable from '@/components/clusters/ClusterEventsTable.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import ArchiveEventsModal from '@/components/modals/ArchiveEventsModal.vue';

import api from '@/services/api';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    fields: {
      type: Array,
    },
    lengthMenu: {
      type: Array,
    },
    archive: Boolean,
  },
  components: { ArchiveEventsModal, DataRow, DetailRow, ClusterEventsTable, Loading },
  name: 'clusters-table',
  data: () => {
    return {
      sortables: [],
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      pageStart: 0,
      pageLength: 17,
      searchString: '',
      // searchTypes: [],
      rowsData: [],
      recordsTotal: 0,
      loading: false,
      draw: 0,
      errors: [],
      onTypes: [],
      offTypes: [],

      selectedRows: [],
      visibleArchiveDialog: false,
      buttons: [
        {
          name: 'print',
          label: 'Print',
        },
        {
          name: 'export-sub',
          label: 'Export',
          children: [
            {
              name: 'pdf',
              label: 'PDF',
              action: 'exportPdf',
            },
            {
              name: 'csv',
              label: 'CSV',
              action: 'exportCsv',
            },
            {
              name: 'xls',
              label: 'Excel',
              action: 'exportXls',
            },
          ],
        },
        {
          name: 'archive',
          label: 'Archive',
          action: 'switchArchive',
        },
        {
          name: 'view-selected',
          label: 'View selected',
          action: 'openSelected',
        },
      ],
    };
  },
  computed: {
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
    eventsFields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$t('Goose') },
        { name: 'SV', label: this.$t('SV') },
        { name: 'SRV', label: this.$t('Service events') },
        { name: 'ARP', label: this.$t('ARP') },
        { name: 'MMS', label: this.$t('MMS') },
        { name: 'USER', label: this.$t('User events') },
      ];
    },

    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    enableView() {
      return this.rowsDataSelected.filter((e) => e.hasRef).length > 1;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    order() {
      return {
        column: this.fields.map((e) => e.name).indexOf(this.currentSortField),
        dir: this.currentSortDir === 1 ? 'asc' : 'desc',
      };
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    offTypesArray() {
      return this.offTypes && this.offTypes.map((e) => '!' + e);
    },
    search() {
      let searchArray = this.offTypesArray;
      if (this.searchString !== '') {
        searchArray = [...this.offTypesArray, this.searchString];
      }
      return {
        value: searchArray.join(','),
        regex: false,
      };
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      return this.$qs.stringify({
        draw: this.draw,
        order,
        search,
        start: this.pageStart,
        length: this.pageLength,
      });
    },
  },
  beforeMount() {
    try {
      let onTypes = this.eventTypes.map((e) => e.name);
      let ss = this.$route.query;
      let ssArr = this.$qs.parse(ss);
      let types = ssArr['search']
        .split(',')
        .filter((e) => e.indexOf('!') > -1)
        .map((e) => e.substring(1));
      dev.log('ss', ssArr, onTypes);
      onTypes.forEach((el) => {
        if (types.indexOf(el) < 0) {
          this.onTypes.push(el);
        }
      });
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.$nextTick(() => {
        this.visibleTable = true;
      });
    } catch (e) {
      this.onTypes = this.eventTypes.map((e) => e.name);
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.visibleTable = true;
    }
    if (this.extButtons) {
      this.buttons = this.extButtons;
    }
  },
  mounted() {
    this.setDefaultSort();
    this.loadData();
    dev.log('r', this.$route);
  },
  methods: {
    switchType(type) {
      dev.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.$nextTick(() => {
        // this.pageChange();
        this.$emit('pageUpdate');
      });
    },
    execAction(a, e, d) {
      dev.log('execAction', a, e, d);
      if (a) {
        this[a](e, d);
      }
    },
    exportXls(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.currentProject}/archive-xls?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.currentProject}/events2-xls`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProject} ` + this.$t('analysis report') + `.xlsx`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportPdf(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.currentProject}/archive-pdf?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.currentProject}/events2-pdf`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.tpws.displayName} ` + this.$t('analysis report') + `.pdf`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportCsv(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.currentProject}/archive-csv?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.currentProject}/events2-csv`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.tpws.displayName} ` + this.$t('analysis report') + `.csv`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    switchArchive(e) {
      this.visibleArchiveDialog = true;
    },
    openSelected(e) {
      dev.log('openSelected', e);
      const ts = this.rowsDataSelectedTime;
      if (e.ctrlKey || e.shiftKey || e.altKey)
        window.open(`?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`, '_blank');
      else location.href = `?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`;
    },
    colAction(a, e, d) {
      if (a) {
        e.stopImmediatePropagation();
        this[a](e, d);
      }
    },
    showProfile(e, d) {
      dev.log(e);
      // this.$emit('showProfile', e);
      const queryString = this.$qs.stringify({
        cluster: d.clusterDT,
      });
      const url = `?p=cluster-events-profile` + (queryString ? '&' + queryString : '');
      if (e.ctrlKey || e.shiftKey || e.altKey) window.open(url, '_blank');
      else location.href = url;
    },
    showEvents(e, d) {
      dev.log(d);
      let el = this.rowsData.find((e) => e._id === d._id);
      let idx = this.rowsData.indexOf(el);

      el.showDetails = !el.showDetails;
      // this.timePresets.$set(idx, el);
      this.rowsData[idx] = el;
      // this.$emit('showEvents', e);
      if (el.showDetails) {
        this.loadDetails(d);
      }
    },
    loadDetails(d) {
      let search = this.search;
      const queryString = this.$qs.stringify({
        cluster: d.clusterDT,
        search,
      });
      let dataUrl = `/projects/${this.currentProject}/events2-exp?` + queryString;
      // this.selectedRows = [];
      this.loading = true;

      // this.$emit('pageUpdate');

      api
        .get(dataUrl)
        .then((response) => {
          dev.log(response);
          let el = this.rowsData.find((e) => e._id === d._id);
          let idx = this.rowsData.indexOf(el);

          el.children = response.data.data;
          this.rowsData[idx] = el;

          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    clickRow(i) {
      const rowInx = this.pageStart + i;
      dev.log('clickRow', this.selectedRows.indexOf(rowInx), rowInx);
    },
    clickCol(e) {
      dev.log('clickCol', e);
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      dev.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    loadData() {
      this.draw++;
      dev.log('sq', this.queryString);
      let dataUrl = this.url + '?' + this.queryString;
      this.selectedRows = [];
      this.loading = true;

      this.$emit('pageUpdate');

      api
        .get(dataUrl)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.rowsData = response.data.data;
          this.recordsTotal = response.data.recordsTotal;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          return 'sorting_asc';
        }
        if (this.currentSortDir === -1) {
          return 'sorting_desc';
        }
      } else {
        return f.sortable ? 'sorting' : '';
      }
    },
    changeSort(f) {
      dev.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.loadData();
      this.$emit('pageUpdate');
    },
  },
  watch: {
    offTypes: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    searchString: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
    pageLength: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    pageStart: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
  },
};
</script>

<style scoped>
.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
