<template>
  <div>
    <timeline
      ref="timeline"
      :groups="groups"
      :items="items"
      :options="options"
      :events="['select', 'rangechanged', 'zoomIn']"
      @select="mySelectCallback"
      @rangechanged="myRangechangedCallbac"
    />
  </div>
</template>

<script>
import Timeline from '@/components/timeline/Timeline.vue';

export default {
  name: 'versions-timeline-vis',
  components: {
    Timeline,
  },
  setup() {},
  data() {
    return {
      groups: [
        {
          id: 0,
          content: '',
        },
      ],
      items: [
        {
          id: 1,
          group: 0,
          start: new Date('2021-06-17T14:22:04.017Z'),
          version: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1001],
          className: 'transparent',
        },
        // {
        //   id: 2,
        //   group: 0,
        //   start: new Date(2021, 8, 11),
        //   version: [11],
        //   className: 'transparent',
        // },
        // {
        //   id: 32,
        //   group: 0,
        //   start: new Date(2021, 8, 5),
        //   version: [12, 13],
        //   className: 'transparent',
        // },
        {
          id: 4,
          group: 0,
          start: new Date(2021, 8, 17),
          version: [14],
          className: 'transparent',
        },
        // {
        //   id: 5,
        //   group: 0,
        //   start: new Date(2021, 8, 17),
        //   version: [15],
        //   className: 'transparent',
        // },
        // {
        //   id: 6,
        //   group: 0,
        //   start: new Date(2021, 8, 17),
        //   version: [16],
        //   className: 'transparent',
        // },
        // {
        //   id: 7,
        //   group: 0,
        //   start: new Date(2021, 8, 17),
        //   version: [17],
        //   className: 'transparent',
        // },
        {
          id: 8,
          group: 0,
          start: new Date(2021, 8, 19),
          version: [18],
          className: 'transparent',
        },
        {
          id: 9,
          group: 0,
          start: new Date(2021, 8, 25),
          version: [20],
          className: 'transparent',
        },
      ],
      options: {
        editable: false,
        horizontalScroll: true,
        height: '340px',
        showCurrentTime: false,
        template: async (item) => {
          // const parInst = createApp({
          //   template: '<tl-list :item="item"/>',
          //   props: ['item'],
          //   propsData: {
          //     item,
          //   },
          // });
          // const el = document.createElement('span');
          // const mnt = parInst.mount(el);
          // console.log('templ', this, ItemComponent, parInst);
          let span = document.createElement('span');
          // document.getElementById('main').appendChild(span);
          await this.$nextTick();
          this.$appendComponent(
            'ItemComponent',
            span,
            {
              item: item,
            },
            {
              click: () => {
                alert('Clicked');
              },
            }
          );

          return span;
        },
      },
      loading: false,
    };
  },
  mounted() {
    // const custElement = defineCustomElement({
    //   template: `
    //
    //   <TimeLineCard :item="item" />
    //
    // `,
    //
    //   props: ['item'],
    //   methods: {
    //     testClick() {
    //       console.log('test ClickS', this.item);
    //     },
    //   },
    //   components: {
    //     TimeLineCard,
    //   },
    // });
    //
    // console.log('custElement', custElement);
    // customElements.define('tl-list', custElement);
  },
  methods: {
    mySelectCallback(items, e) {
      dev.log('mySelectCallback', 'e:', e, 'item:', items);
      dev.log('mySelectCallback', this.$refs.timeline.getVisibleItems());
    },
    myRangechangedCallbac(event) {
      dev.log('myRangechangedCallbac', 'start:', event);
      dev.log('getItemRange()', this.$refs.timeline.getVisibleItems());
      this.intervalTime(event.start, event.end);
      // console.log('getItemRange()', this.$refs.timeline.getItemRange());
    },
    intervalTime(startPeriod, endPeriod) {
      const period = new Date(endPeriod).getTime() - new Date(startPeriod).getTime();
      // console.log('period', period);
      const showItems = [];
      this.items.forEach((item) => {
        const time = item.start.getTime();
        if (time > startPeriod && time < endPeriod) {
          showItems.push(item);
        }
      });
      // 172800000
      showItems.forEach((item, i, arr) => {
        if (i > 0) {
          const firstTime = new Date(item.start).getTime();
          const secondTime = new Date(arr[i - 1].start).getTime();
          if (firstTime - secondTime < period / 5) {
            dev.log('объединяем', 'periodItem', firstTime - secondTime);
          } else dev.log('не объеденеям');
        }
      });
      dev.log('showItems', showItems);
      if (showItems.length === 2) {
        dev.log('интервал', showItems[1].start.getTime() - showItems[0].start.getTime());
      }
    },
  },
};
</script>

<style>
.vis-item.vis-box.transparent.vis-readonly {
  background: transparent;
  border: none;
}
.vis-item.vis-dot.transparent.vis-readonly {
  display: none;
}
.vis-item.vis-line.transparent.vis-readonly {
  border-left-width: 2px;
  border-color: #c5d5ef;
  /* background-color: #c5d5ef; */
}

.vis-item .vis-item-content {
  padding: 0 !important;
}

.vis-item.vis-selected.vis-line {
  background-color: #1757bd !important;
  border-color: #1757bd !important;
}
</style>
