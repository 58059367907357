<template>
  <div class="">
    <template v-if="current">
      <template v-if="timelineExperimental">
<!--        <versions-timeline-vis></versions-timeline-vis>-->
        <versions-timeline-vis-js></versions-timeline-vis-js>
      </template>
      <template v-else>
        <versions-timeline></versions-timeline>
        <!--            <scriptx  src="/js/tpui.project.timeline.js"></scriptx>-->
        <div v-loading="isLoading" class="container-fluid p-0" id="description-container">
          <div class="mt-2" id="description-accordion">
            <div class="card-project">
              <div id="description-header">
                <h5 class="project-page__card-sub-title">
                  <button
                    class="btn btn-link card-project__btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#description-collapsed"
                    aria-expanded="true"
                    aria-controls="description-collapsed"
                  >
                    {{ $t('Information') }}
                  </button>
                </h5>
              </div>
              <span class="comma" id="description-container-user" style="display: none">
                <a href="">
                  <span class="users-container-current"></span>
                </a>
              </span>
              <div
                v-show="!isLoading"
                class="collapse show"
                id="description-collapsed"
                aria-labelledby="#description-header"
                data-parent="#description-accordion"
              >
                <div class="card-body" style="padding: 0 40px 100px">
                  <div id="description-wrapper"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <scl-widgets></scl-widgets>
      </template>
    </template>
    <template v-else>
      <version-upload></version-upload>
    </template>
  </div>
</template>

<script>
import VersionsTimelineVis from '@/components/timeline/VersionsTimelineVis.vue';
import VersionsTimelineVisJs from '@/components/timeline/VersionsTimelineVisJs.vue';
import VersionsTimeline from '@/components/timeline/VersionsTimeline.vue';
import VersionUpload from '@/components/timeline/VersionUpload.vue';
import SclWidgets from '@/components/timeline/SclWidgets.vue';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePrefs } from '@/stores/prefs';
import { usePage } from '@/stores/page';
export default {
  name: 'project-timeline',
  components: {
    VersionsTimelineVis,
    VersionsTimelineVisJs,
    VersionsTimeline,
    VersionUpload,
    SclWidgets,
  },
  props: {},
  data: () => {
    return {
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(usePrefs),
    ...mapStores(usePage),
    currentProject() {
      return this.tpws.currentProject;
    },
    displayName() {
      return this.tpws.displayName;
    },
    current() {
      return this.page.pageData['project-timeline']?.current;
    },
    enableSoftwareLVB() {
      return this.page.pageData['project-timeline']?.enableSoftwareLVB;
    },
    adm() {
      return this.page.pageData['project-timeline']?.adm;
    },
    isLoading() {
      return this.tpws?.projectStatusLoading;
    },
    isLive() {
      return this.page.pageData['project-timeline']?.isLive;
    },
    projectusers() {
      return this.page.pageData['project-timeline']?.projectusers;
    },
    permission() {
      return this.page.pageData['project-timeline']?.permission;
    },
    commissioning() {
      return this.page.pageData['project-timeline']?.commissioning;
    },
    timelineExperimental() {
      return !!this.prefs?.timelineExperimental;
    },

    lvbTableDataset() {
      return this.$root.$parseDataset(this.$root.lvbTableDataset);
    },
    controlUsersDataset() {
      return this.$root.$parseDataset(this.$root.controlUsersDataset);
    },

    url() {
      return this.eventsArchive
        ? `/projects/${this.tpws.projectName}/archive?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.tpws.projectName}/events2`;
    },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },

    fields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
  },
  created() {},
  mounted() {},
  methods: {
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
