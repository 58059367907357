<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.editProjectModalVisible"
    v-model:visible="visual.editProjectModalVisible"
    ref="modEx"
    @closeModal="closeModal"
    @onSubmit="onSubmit"
  >
    <template #title> </template>
    <template #content>
      <n-form ref="formRef" :model="formValue" :rules="rules" :label-width="80">
        <n-form-item :label="$t('Project name')" path="newName">
          <n-input v-model:value="formValue.newName" :placeholder="$t('Enter project name')" />
        </n-form-item>
        <n-form-item :label="$t('Language')" path="newLanguage">
          <n-select v-model:value="formValue.newLanguage" :options="options"  :placeholder="$t('Choose language')" />
        </n-form-item>
      </n-form>
    </template>
    <template #buttons> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useTpws } from '@/stores/tpws';
import { mapStores } from 'pinia';
import {ref} from "vue";

export default {
  name: 'edit-project-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    },
    selectedSubnet: {
      type: String,
    },
  },
  components: { ModalDialog },
  setup() {
    const formRef = ref(null);
    // const message = useMessage()
    return {
      formRef,
      formValue: ref({
        newName: '',
        newLanguage: undefined,
      }),
      options: [
        {
          label: "English",
          value: 'en',
          disabled: false
        },
        {
          label: "Русский",
          value: 'ru',
          disabled: false
        },
      ]
    };
  },

  data: () => {
    return {
      modal: {
        name: 'project-settings',
        title: 'Project settings',
        closeBtn: 'Close',
        onSubmit: 'Save',
        noSubmit: false,
        size: '800',
        noTitle: false,
        noCross: false,
      },
      newName: '',
      newLanguage: undefined,
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useTpws),
    rules() {
      return {
        newName: {
          required: true,
          message: this.$t('Value is required'),
          trigger: 'blur',
        },
        newLanguage: {
          required: true,
          message: this.$t('Value is required'),
          trigger: 'blur',
        },
      };
    },
  },
  mounted() {
    dev.log('this.formValue', this.formValue.newName)
    dev.log('this.formValue', this.formValue.newLanguage)
    // this.formValue.newName = this.tpws.displayName;
    // this.formValue.newLanguage = this.tpws.currentLang;
  },
  methods: {
    closeModal() {
      dev.log('closeModal');
      this.visual.editProjectModalVisible = false;
    },
    onSubmit() {
      dev.log('onSubmit');
      // this.$emit('select', this.selected);

      this.formRef
          ?.validate((errors) => {
            if (!errors) {
              dev.log('success');
              this.tpws.renameProject({
                newName: this.formValue.newName,
                newLanguage: this.formValue.newLanguage
              }, (res) => {
                dev.log('rename res', res);
                this.visual.editProjectModalVisible = false;
              });
            } else {
              // console.log(errors);
            }
          })
          .catch((err) => {});

    },
  },
  watch: {
    'visual.editProjectModalVisible': {
      handler(val) {
        if (val) {
          this.formValue.newName = this.tpws.displayName;
          this.formValue.newLanguage = this.tpws.currentLang;
        };
      },
    },
    'tpws.displayName': {
      handler(val) {
        this.newName = val;
      },
    },
    'tpws.currentLang': {
      handler(val) {
        this.newLanguage = val;
      },
    },
  },
};
</script>

<style lang="scss">
h5 .subnet-select-modal__title {
  font-size: 1.1em;
  line-height: 1.5em;
}
</style>
<style lang="scss"></style>
