<template>
  <div class="card card-default">
    <div class="card-header">
      <a v-bind:href="'#ghost_goose_' + idx" data-toggle="collapse">
        {{ ghostGooseCardTitleDisplay == "GoID"
            ? gg.goId
            : ghostGooseCardTitleDisplay == "GoCBRef"
              ? gg.goCbRef
              : $t('Ghost GOOSE')+" "+idx }}
        <span class="badge badge-pill badge-warning ml-1 mr-1" v-if="gg.simulation == 1">{{ $t('Simulated') }}</span>
        <span class="badge badge-pill badge-danger ml-1 mr-1" v-if="gg.ndsCom">{{ $t('Needs Commissioning') }}</span>
      </a>
    </div>
    <div class="card-collapse collapse" v-bind:id="'ghost_goose_' + idx">
      <div class="card-body">
        <ul>
          <li v-if="gg.ndsCom"><span class="text-danger">{{ $t('This GOOSE message needs commissioning (ndsCom = True).') }}</span></li>
          <li v-if="gg.simulation == 1" class="mb-2"><span class="text-warning">{{ $t('This message is being sent by a test equipment (simulation = True).') }}</span></li>
          <li><span class="tip-label">{{ $t('Source MAC') }}:</span> {{ gg.srcMac }} <span v-if="gg.macVendor">({{ gg.macVendor }})</span></li>
          <li><span class="tip-label">{{ $t('Destination MAC') }}:</span> {{ gg.dstMac }}</li>
          <li v-if="gg.vlan && 'id' in gg.vlan">
            <span class="tip-label">{{ $t('VLAN-ID') }}:</span>
            {{
              vlanDisplayType == 'HEX'
                ? gg.vlan.id.toString().padStart(5, '0x000')
                : parseInt(gg.vlan.id, 16).toString().padStart(4, '0000')
            }}
          </li>
          <li><span class="tip-label">{{ $t('APP ID') }}:</span> {{ gg.appId.toUpperCase().padStart(6, '0x0000') }}</li>
          <li><span class="tip-label">{{ $t('GOOSE Control Reference') }}:</span> {{ gg.goCbRef }}</li>
          <li><span class="tip-label">{{ $t('GOOSE ID') }}:</span> {{ gg.goId }}</li>
          <li><span class="tip-label">{{ $t('Configuration Revision') }}:</span> {{ gg.confRev }}</li>
          <li><span class="tip-label">{{ $t('Number of dataset entries') }}:</span> {{ gg.numberOfDatSetEntries }}</li>
          <li class="pt-2">
            <span class="tip-label">{{ $t('Captured by') }}:</span>&nbsp;

            <span v-for="(lvb, index) in capturedByLvbList">
              {{lvb.lvb_id}}<span v-if="index != (capturedByLvbList.length - 1)">,</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { usePrefs } from '@/stores/prefs';

export default {
  name: 'ghost-goose',
  props: ['gg', 'idx', 'lvbList'],
  data: function () {
    return {};
  },
  computed: {
    ...mapStores(usePrefs),
    vlanDisplayType() {
      return this.prefs.vlanDisplayType;
    },
    ghostGooseCardTitleDisplay() {
      return this.prefs.ghostGooseCardTitleDisplay;
    },
    capturedByLvbList() {
      return this.lvbList.filter(lvb => this.gg.status.aliveLVB.includes(lvb._id))
    }
  },
};
</script>

<style scoped></style>
