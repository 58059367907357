import api from '@/services/api';
import { defineStore } from 'pinia';

import { useTpws } from '@/stores/tpws';

export const useUnhandledEvents = defineStore({
  id: 'unhandledEvents',
  state: () => {
    return {
      enabled: false,
      period: 7500,
      periodMin: 500,
      periodMax: 10000,
      eventsCounter: 0,
      loopRef: undefined,
      popoverVisible: false,
    };
  },
  // watch: {
  //   enabled: {
  //     col
  //   }
  // },
  actions: {
    toggleVisible() {
      if (!this.enabled) {
        this.turnOn();
      }
      this.popoverVisible = !this.popoverVisible;
    },
    turnOn() {
      // todo интервал после выполнения
      if (!this.loopRef) {
        this.loopRef = setTimeout(this.track, 500);
        this.enabled = true;
      }
    },
    turnOff() {
      if (this.loopRef) {
        clearTimeout(this.loopRef);
        this.loopRef = undefined;
        this.enabled = false;
        this.popoverVisible = false;
      }
    },
    track() {
      const tpws = useTpws();
      api.get(`/projects/${tpws.currentProject}/count-unhandled-events`).then(({ data }) => {
        if (data.res == 'fail') return;

        if (data.desc == 0) {
          if (this.enabled) {
            this.popoverVisible = true;
          }
          if (this.period <= this.periodMax) this.period += this.periodMin;
        } else {
          this.period = this.periodMin;
          this.popoverVisible = true;
        }

        this.eventsCounter = data.desc;
        if (this.enabled) {
          this.loopRef = setTimeout(this.track, this.period);
        }
      });
    },
  },
});
