<template>
  <div class="range-filter">
    <!--    <n-space vertical>-->
    <!--      <n-slider v-model:value="value" range :step="1" :min="min" :max="max" />-->
    <!--      <n-space>-->
    <!--        <n-input-number v-model:value="value[0]" size="small" :show-button="false" />-->
    <!--        <n-input-number v-model:value="value[1]" size="small" :show-button="false" />-->
    <!--      </n-space>-->
    <!--    </n-space>-->
    <div class="range-filter__slider">
      <el-slider
        class="r1"
        v-if="range && value"
        v-model="value"
        :range="true"
        :min="min"
        :max="max"
        ref="sli"
        @input="updateInputs"
        @change="onChangeValue"
      >
      </el-slider>
    </div>
    <div class="range-filter__values">
      <template v-if="isArray">
        <el-input
          class="range-filter__value"
          style="margin-right: 8px"
          type="text"
          v-model="minValueTmp"
          :value="minValue"
          @change="updateMinValue"
        ></el-input>
        <el-input
          class="range-filter__value"
          style=""
          type="text"
          v-model="maxValueTmp"
          :value="maxValue"
          @change="updateMaxValue"
        ></el-input>
      </template>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useFilters } from '@/stores/filters';

export default {
  name: 'range-filter',
  props: {
    range: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data: () => {
    return {
      minValueTmp: 0,
      maxValueTmp: 0,
    };
  },
  computed: {
    ...mapStores(useFilters),

    minValue: {
      get() {
        return this.filters.filterValues.clusterSize?.min;
      },
      set(e) {
        this.setClusterSize({ min: e, max: this.maxValue });
      },
    },
    maxValue: {
      get() {
        return this.filters.filterValues.clusterSize?.max;
      },
      set(e) {
        this.setClusterSize({ min: this.minValue, max: e });
      },
    },
    value: {
      get() {
        return [this.filters.filterValues.clusterSize?.min, this.filters.filterValues.clusterSize?.max];
      },
      set(e) {
        e = e.sort(function (a, b) {
          return a - b;
        });
        // console.log('-clusterSize value set', e);

        this.setClusterSize({ min: e[0], max: e[1] });
      },
    },
    min() {
      return this.filters.filters.clusterSizeBounds?.min;
    },
    max() {
      return this.filters.filters.clusterSizeBounds?.max;
    },
    isArray() {
      return this.value && Array.isArray(this.value);
    },
  },
  mounted() {
    this.minValueTmp = this.minValue;
    this.maxValueTmp = this.maxValue;
    //todo watch
    // this.$store.watch(
    //   (state) => state.clusterFilterValues.clusterSize && state.clusterFilterValues.clusterSize.min,
    //   (newValue, oldValue) => {
    //     this.minValueTmp = newValue;
    //   }
    // );
    // this.$store.watch(
    //   (state) => state.clusterFilterValues.clusterSize && state.clusterFilterValues.clusterSize.max,
    //   (newValue, oldValue) => {
    //     this.maxValueTmp = newValue;
    //   }
    // );
  },
  methods: {
    ...mapActions(useFilters, ['setClusterSize']),

    updateInputs(e) {
      this.value = e;
    },
    onChangeValue(e) {
      this.$nextTick(() => {
        this.$emit('change', { min: e[0], max: e[1] });
      });
    },
    updateMinValue(e) {
      this.minValue = Number(e);
      this.value[0] = Number(e);
      this.$nextTick(() => {
        this.$emit('change', { min: this.minValue, max: this.maxValue });
      });
    },
    updateMaxValue(e) {
      this.maxValue = Number(e);
      this.value[1] = Number(e);
      this.$nextTick(() => {
        this.$emit('change', { min: this.minValue, max: this.maxValue });
      });
    },
    onChange(e) {
      this.v = e;
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.range-filter {
  &__slider {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 220px;
  }
  &__values {
    display: flex;
    max-width: 220px;
  }
  &__value {
    text-align: center;
    flex: 1;
    & input {
      text-align: center;
    }
  }
}
</style>
