import axios from 'axios';

let baseURL = `/api`;
if (import.meta.env) {
  if (import.meta.env.NODE_ENV === 'development') {
    baseURL = import.meta.env.VITE_APP_DEV_HOST
      ? `http://${import.meta.env.VITE_APP_DEV_HOST}:3000/api`
      : 'http://localhost:3000/api';
  }
} else {
  // console.log('process.env', process.env);
  if (process.env.NODE_ENV === 'development') {
    baseURL = process.env.VUE_APP_DEV_HOST
      ? `http://${process.env.VUE_APP_DEV_HOST}:3000/api`
      : 'http://localhost:3000/api';
  }
}

const axiosPublic = axios.create({
  withCredentials: true,
  crossDomain: true,
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
  },
});

export default axiosPublic;
