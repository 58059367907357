//*** This code is copyright 2002-2016 by Gavin Kistner, !@phrogz.net
//*** It is covered under the license viewable at http://phrogz.net/JS/_ReuseLicense.txt
//*** Reuse or modification is free provided you abide by the terms of that license.
//*** (Including the first two lines above in your source code satisfies the conditions.)

// Include this code (with notice above ;) in your library; read below for how to use it.
// Код расширен возможностью использовать милисекунды, микросекунды и наносекунды

class DateTpui extends Date {
  constructor(y, m, d, hh, mm, ss, ms, us) {
    super();
    var isCreateFromRawUs = arguments.length === 1;

    if (isCreateFromRawUs) {
      us = y % 1e3;
      ms = Math.floor(y / 1000);
    }
    let date;
    if (isCreateFromRawUs) {
      date = new Date(ms);
    } else {
      date = new Date(y, m, d, hh, mm, ss, ms);
    }
    // this.us = us;
    Object.defineProperty(this, 'date', { value: date, enumerable: false, writable: true });
    Object.defineProperty(this, 'us', { value: us, enumerable: false, writable: true });

    return this;
  }
}

DateTpui.prototype.getTime = function () {
  return this.date.getTime() * 1e3 + this.us;
};

export default DateTpui;
