<template>
  <div class="tp-row doc-contents mt-4">
    <div class="header-h1">{{ $t('Configuration Analisys') }} ({{ $t(data.isActive ? 'Active version' : 'Previewed version') }})</div>
  </div>
  <div v-loading="loading" style="min-height: 96px">
    <div v-if="data.versionInfo?.text" id="versionInfo" class="mb-2" v-html="data.versionInfo.text"></div>
    <div v-else class="mb-2"><skeleton-span height="17px" width="47vw" /></div>
    <template v-if="data.withErrors">
      <div class="tp-row doc-contents">
        <p v-if="data.noticesStatHtml" v-html="data.noticesStatHtml"></p>
      </div>
    </template>
    <template v-else>
      <div class="tp-row doc-contents">
        <p style="display: block">
          <skeleton-span height="17px" width="50vw" /><br /><skeleton-span height="17px" width="30vw" />
        </p>
      </div>
    </template>
    <template v-if="data.inProcessing">
      <div class="col-lg-6 container text-center">
        <div class="d-flex flex-column alert alert-warning">
          <span>${$t('The new version of the project is in processing...')}</span>
          <span>${$t('Information may be incomplete')}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SkeletonSpan from '@/components/common/SkeletonSpan.vue';

export default {
  name: 'analysis-header',
  components: { SkeletonSpan },
  props: {
    data: Object,
  },
  data: () => ({
    loading: false,
  }),
  watch: {
    // data: {
    //   handler(val, old) {
    //     if (val) {
    //       this.loading = true;
    //       setTimeout(() => {
    //         this.loading = false;
    //       }, 300);
    //     }
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style scoped></style>
