import { mapActions, mapStores } from 'pinia';
import { useAuth } from '@/stores/auth';
import { useTpws } from '@/stores/tpws';
import { useSclProcessors } from '@/stores/sclProcessors';
// import { useRoot } from '@/stores/root';
import { useVisual } from '@/stores/visual';
import { useReports } from '@/stores/reports';
import eventBus from '@/utils/eventBus';

export const menu = {
  props: ['atrs'],
  computed: {
    // ...mapGetters(['preparedReportsCount']),
    ...mapStores(useTpws, useSclProcessors, useVisual, useReports),
    currentProject() {
      return this.tpws.currentProject;
    },
    displayName() {
      return this.tpws.displayName;
    },
    sclProcessorsElements() {
      return this.sclProcessors.sclProcessorsElements;
    },
    isLive() {
      return this.tpws.isLive;
    },
    commissioning() {
      return this.tpws.commissioning;
    },
    versionNumber() {
      return this.tpws.versionNumber;
    },
    activeVersionId() {
      return this.tpws?.activeVersion?._id;
    },
    viewedVersionId() {
      return this.tpws?.viewedVersion?._id;
    },
    viewedVersionHasElly() {
      return this.tpws?.viewedVersionHasElly;
    },
    activeVersionHasElly() {
      return this.tpws?.activeVersionHasElly;
    },

    menuItems() {
      const items = {};
      // items.viewingAndAnalyzing.push({
      //   title: 'Wheel (old)',
      //   link: '/projects/' + this.currentProject + '/space?p=wheel',
      //   icon: 'asterisk',
      // });
      if (this.viewedVersionId) {
        items.viewingAndAnalyzing = [];
        if (this.viewedVersionHasElly) {
          items.viewingAndAnalyzing.push({
            title: 'Wheel',
            link: '/projects/' + this.currentProject + '/wheel',
            action: { name: 'wheel-page', params: { projectId: this.currentProject } },
            icon: 'asterisk',
          });
        }

        // console.log('this.sclProcessorsElements', this.sclProcessorsElements);
        this.sclProcessorsElements.viewingAndAnalyzing &&
          this.sclProcessorsElements.viewingAndAnalyzing.forEach((el) => {
            // todo pinia getters
            // console.log('el.condition', el.condition, this.tpws[el.condition]);
            if (this.tpws[el.condition]) {
              items.viewingAndAnalyzing.push({
                title: el.title,
                link: '/projects/' + this.currentProject + '/reports/' + el.route,
                action: {
                  name: 'report',
                  params: {
                    projectId: this.currentProject,
                    reportCode: el.route,
                  },
                },
                icon: el.icon,
              });
            }
          });
        items.viewingAndAnalyzing.push({
          title: 'Analysis',
          link: '/projects/' + this.currentProject + '/analysis',
          action: { name: 'analysis', params: { projectId: this.currentProject } },
          icon: 'check',
        });
      }
      if (this.tpws.projectHasActiveVersion) {
        items['monitoring'] = [];
        if (this.isLive && this.activeVersionHasElly) {
          items.monitoring.push({
            title: 'Wheel LIVE',
            link: '/projects/' + this.currentProject + '/wheel-live',
            action: { name: 'wheel-live-page', params: { projectId: this.currentProject } },
            icon: 'play',
          });
        }
        // if (this.commissioning) {
        //   items.monitoring.push({
        //     title: 'Set srcMac default',
        //     link: 'srcMacNull',
        //     method: 'srcMacNull',
        //     icon: 'play',
        //   });
        // }
        if (this.activeVersionId) {
          items.monitoring.push({
            title: 'Event clusters',
            link: '/projects/' + this.currentProject + '/clusters-index',
            action: { name: 'clusters-index', params: { projectId: this.currentProject } },
            icon: 'object-group',
          });
          items.monitoring.push({
            title: 'Events',
            link: '/projects/' + this.currentProject + '/events',
            action: { name: 'events', params: { projectId: this.currentProject } },
            icon: 'flag',
          });
        }
        if (this.versionNumber) {
          items.monitoring.push({
            title: 'Analysis',
            link: '/projects/' + this.currentProject + '/analysis-live',
            action: { name: 'analysis-live', params: { projectId: this.currentProject } },
            icon: 'check',
          });
        }
      }

      return items;
    },
    activeIndex() {
      return this.$route.fullPath;
    },
    page() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions(useAuth, ['logout']),
    goOut(route) {
      dev.log('loc', window.location, window.location.protocol + '//'+window.location.host + route.path)
      window.open(
          window.location.protocol + '//'+window.location.host + route.path,"_self");
      // this.$router.push(route).catch((err) => {
      //   dev.log('r err', err);
      //   throw new Error(`Problem handling something: ${err}.`);
      // });
    },
    doLogout() {
      this.logout({ name: 'auth-login' }).then((url) => {
        dev.log('ret', url);
        this.$router.push(url).catch((err) => {
          dev.log('r err doLogout', err);
          throw new Error(`Problem handling something: ${err}.`);
        });;
      });
    },
    goToItem(item) {
      if (item.method) {
        this[item.method]();
      } else if (item.action) {
        this.$router.push(item.action);
      } else {
        document.location.href = item.link;
      }
    },
    goToLink(link) {
      document.location.href = link;
    },
    showPreparedReports() {
      dev.log('mx showPreparedReports');
      this.visual.preparedReportsModalVisible = true;
    },
    showUserProfile() {
      this.visual.showUserProfile(true);
    },
    showUserSettings() {
      this.visual.showUserSettings(true);
    },
    showUserInvite() {
      dev.log('showUserInvite');
      this.visual.showUserInvite(true);
    },
    openModal(modalName) {
      $(modalName).modal('show');
    },
    srcMacNull() {
      this.tpws.srcMacNull(this.currentProject).then((res)=>{
        eventBus.dispatch('notification', {
          message: this.$t('Success'),
          type: 'success',
        });
      });
    },
    showProfile() {
      const FORM_FIELDS = JSON.parse(decodeURI(currentProfileFields))
        .map((field) => field.name)
        .filter((fieldName) => fieldName !== 'id');

      const hideErrorField = (fieldName) => {
        $(`#edit-profile-${fieldName}-error`).hide();
        $(`#edit-profile-${fieldName}`).removeClass('is-invalid');
      };

      const hideEditProfileErrors = () => FORM_FIELDS.forEach((field) => hideErrorField(field));
      hideEditProfileErrors();

      const hideEditProfileErrorsOnChange = () =>
        FORM_FIELDS.forEach((field) => $(`#edit-profile-${field}`).on('input', () => hideErrorField(field)));
      hideEditProfileErrorsOnChange();

      const profileFields = JSON.parse(decodeURI(currentProfileFields));
      profileFields.forEach((field) => $(`.edit-user-profile-modal #edit-profile-${field.name}`).val(field.value));

      $('.edit-user-profile-modal').modal();
    },
  },
  mounted() {
    // menuScript();
  },
};
