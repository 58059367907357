<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.userSettingsVisible"
    v-model:visible="visual.userSettingsVisible"
    ref="modEx"
    @closeModal="closeModal"
    @onSubmit="onSubmit"
  >
    <!--    <template #title>-->
    <!--      <span class="lvb-description-modal__title"></span>-->
    <!--    </template>-->
    <template #content>
      <div class="about__header-wrapper">
        <ul id="myTab" class="nav about__header" role="tablist">
          <li class="header__item">
            <a
              href=""
              id="general-settings-tab"
              class="header-item__label"
              :class="{ active: 'general' === activeTab }"
              @click.prevent="setActiveTab('general')"
              >{{ $t('General preferences') }}</a
            >
          </li>

          <li v-if="inProject" class="header__item">
            <a
              href=""
              id="by-project-settings-tab"
              class="header-item__label"
              @click.prevent="setActiveTab('project')"
              :class="{ active: 'project' === activeTab }"
              >{{ $t('Project preferences') }}</a
            >
          </li>
        </ul>
      </div>

      <div id="myTabContent" class="tab-content">
        <div
          v-show="activeTab === 'general'"
          id="general-settings"
          class="tab-pane show"
          :class="{ active: 'general' === activeTab }"
        >
          <template v-if="prefs.formGeneralPreferences">
            <template v-for="(val, index) in prefs.formGeneralPreferences">
              <user-preferences-row
                v-if="isAdmin || !prefs.userPreferencesPreventToChange[index]"
                :key="'general-' + index"
                :val="prefs.formGeneralPreferences[index]"
                @update="onUpdGeneral(index, $event)"
                :index="index"
                :up__user="prefs"
                :up__class="prefs"
                :by-project="false"
              ></user-preferences-row>
            </template>
          </template>
        </div>

        <div
          v-if="inProject"
          v-show="activeTab === 'project'"
          id="by-project-settings"
          class="tab-pane show"
          :class="{ active: 'project' === activeTab }"
        >
          <template v-if="currentProject && prefs.formPreferences">
            <template v-for="(val, index) in prefs.formPreferences">
              <user-preferences-row
                v-if="
                  (isAdmin ||
                    (!prefs.userPreferencesPreventToChange[index] &&
                      prefs.userPreferencesByProject &&
                      prefs.userPreferencesByProject[index])) &&
                  index !== 'language'
                "
                :key="'project-' + index + '-' + currentProject"
                :gen-val="prefs.formGeneralPreferences[index]"
                :val="prefs.formPreferences[index]"
                @update="onUpdProject(currentProject, index, $event)"
                :index="index"
                :null-value="prefs?.formPreferences[index] === null"
                :project-value="prefs?.formPreferences[index]"
                :up__user.sync="prefs"
                :up__class="prefs"
                :name="currentProject"
                :by-project="true"
              ></user-preferences-row>
            </template>
          </template>
        </div>
      </div>
    </template>
    <template #buttons> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import UserPreferencesRow from '@/components/settings/UserPreferencesRow.vue';

import { mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { usePrefs } from '@/stores/prefs';
import { useTpws } from '@/stores/tpws';
import { useTpui } from '@/stores/tpui';
import { useAuth } from '@/stores/auth';
import { mapStores } from 'pinia';

export default {
  name: 'user-settings-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog, UserPreferencesRow },
  data: () => {
    return {
      modal: {
        name: 'user-settings',
        title: 'User settings',
        closeBtn: 'Cancel',
        onSubmit: 'Save',
        submitBtn: 'OK',
        size: 'w1000',
        noTitle: true,
      },
      dateTime: {},
      activeTab: 'project',
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useTpui),
    ...mapStores(useAuth),
    ...mapStores(useTpws),
    ...mapStores(usePrefs),
    currentProject() {
      return this.tpws.currentProject;
    },
    isAdmin() {
      return this.auth.isAdmin;
    },
    inProject() {
      return this.$route?.meta?.inProject || false;
    },
  },
  mounted() {
    this.$router.isReady().then(() => {});
  },
  methods: {
    ...mapActions(useVisual, ['hideUserSettingsModal']),
    ...mapActions(usePrefs, ['saveFormPrefs']),
    onUpdGeneral(idx, e) {
      dev.log('onUpdGeneral',idx, e);
      this.prefs.formGeneralPreferences[idx] = e;
    },
    onUpdProject(curProject, idx, e) {
      dev.log('onUpdProject', curProject, idx, e);
      if (this.prefs.prefByProject[curProject] === undefined) {
        this.prefs.prefByProject[curProject] = {};
      }
      this.prefs.prefByProject[curProject][idx] = e;
      this.prefs.formPreferences[idx] = e;
    },
    setActiveTab(name) {
      dev.log('setActiveTab', name);
      this.activeTab = name;
    },
    onShow() {
      this.prefs.resetForms();
      if (this.inProject) {
        this.setActiveTab('project');
      } else {
        this.setActiveTab('general');
      }
    },
    unUpd(e) {
      dev.log('onUpd', e);
    },
    closeModal() {
      dev.log('closeModal');
      this.hideUserSettingsModal();
    },
    onSubmit() {
      dev.log('onSubmit');
      this.saveFormPrefs().then(() => {
        this.hideUserSettingsModal();
      });
    },
  },
  watch: {
    'visual.userSettingsVisible': {
      handler(val) {
        dev.log('visibleModal', val);
        if (val) {
          this.onShow();
        }
      },
      // deep: true
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
