<template>
  <div id="last-events-table">
    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <simplebar-vue-naive :height="500">
        <events-table-lite
          :fields="fields"
          :query="{ search }"
          :url="url"
          :length-menu="lengthMenu"
          ref="eventsTableLite"
        >
          <template #filters> </template>
        </events-table-lite>
      </simplebar-vue-naive>
    </div>
  </div>
</template>

<script>
import EventsTableLite from '@/components/events/EventsTableLite.vue';
import SimplebarVueNaive from '@/components/common/SimplebarVueNaive.vue';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { RouterLink } from 'vue-router/dist/vue-router';
export default {
  name: 'last-events-block',
  props: {
    data: { type: Object },
  },
  components: { EventsTableLite, SimplebarVueNaive },

  data: () => {
    return {
      reportDialogVisible: false,
      visibleTable: false,
      ts: undefined,
      // search: {},
      lengthMenu: [5, 10],
    };
  },
  computed: {
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
    url() {
      return `/projects/${this.currentProject}/events2`;
    },
    search() {
      return {
        value: this.data.name,
        regex: false,
      };
    },
    fields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
          renderTemplate: true,
          render: (d) => {
            if (d.params || d.query) {
              return this.$h(RouterLink, {
                class: 'btn-link',
                to: { name: 'signals-profile', params: d.params, query: d.query },
                title: d.title,
                innerHTML: d.name,
              });
            } else {
              return this.$h('span', { title: d.title, innerHTML: d.name });
            }
          },
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
          render: (d) => {
            return `<span title="${d.name}" class="${d.class}" aria-hidden="true"></span>&nbsp;${d.name}`;
          },
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
          renderTemplate: true,
          render: (d) => {
            if (d.params || d.query) {
              return this.$h(RouterLink, {
                class: d.class,
                to: { name: 'wheel-page', params: d.params, query: d.query },
                title: d.title,
                innerHTML: d.name,
              });
            } else {
              return this.$h('span', { class: d.class, title: d.title, innerHTML: d.name });
            }
          },
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
          renderTemplate: true,
          render: (d) => {
            if (d.params || d.query) {
              return this.$h(RouterLink, {
                class: d.class,
                to: { name: 'wheel-page', params: d.params, query: d.query },
                title: d.title,
                innerHTML: d.name,
              });
            } else {
              return this.$h('span', { class: d.class, title: d.title, innerHTML: d.name });
            }
          },
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
  },

  mounted() {
    // window.addEventListener('loadRecent', (e) => {
    //   this.search = {
    //     value: e.detail.name,
    //     regex: false,
    //   };
    //   this.$nextTick(() => {
    //     this.visibleTable = true;
    //     this.$nextTick(() => {
    //       this.ts = e.detail.ts;
    //     });
    //   });
    //
    //   console.log('ev:loadRecent', e, this.search);
    // });
  },
  methods: {},
  watch: {
    data: {
      handler(newVal, old) {
        if (newVal !== old) {
          this.$refs.eventsTableLite.loadData();
        }
      },
      deep: true,
    },
    ts(newVal, old) {
      if (newVal !== old) {
        this.$refs.eventsTableLite.loadData();
      }
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
<style lang="scss"></style>
