import { useAuth } from '@/stores/auth';
import { useConfig } from '@/stores/config';
import { useLicense } from '@/stores/license';
import { usePage } from '@/stores/page';
import { usePrefs } from '@/stores/prefs';
import { useTpui } from '@/stores/tpui';
import { useTpws } from '@/stores/tpws';

export const beforeEach = async (to, from) => {
  const auth = useAuth();
  const page = usePage();
  const tpws = useTpws();
  await auth.getMe();

  let userId = auth.userId;

  //dev.log('be', userId, to?.params?.userId)

  let curLicense;

  if (!to?.meta?.noLicense) {
    const license = useLicense();
    await license.getLicense();
    curLicense = !!license.license && license?.licenseStatus?.status !== 'ERROR'
    if (!curLicense) {
      return { name: 'license-problem', params: {}, query: {} };
    }
  }

  if (to?.name == 'projects' && to?.params?.userId != userId) {
    dev.log('route projects', userId, to);
    if (userId) {
      return { name: 'projects', params: {userId: userId}, query: {} };
    } else {
      return { name: 'auth-login', params: {}, query: {} };
    }
  }

  to.meta['from'] = from;
  dev.log('to', to.name, to?.meta, JSON.stringify(auth.isAuthorized), !to?.meta?.noAuth, auth.user === undefined);
  if (to?.meta?.authRedirect && auth.isAuthorized) {
    return { name: 'client-root', params: {}, query: {} };
  }
  if (!to?.meta?.noAuth && !auth.isAuthorized) {
    if (!curLicense) {
      return { name: 'license-problem', params: {}, query: {} };
    } else {
      return { name: 'open-wheel-page', params: {}, query: {} };
    }
  }
  if (to?.meta?.adminsOnly && !auth.isAdmin) {
    return { name: 'client-root', params: {}, query: {} };
  }
  if (to.query.p) {
    const q = { ...to.query };
    delete q['p'];
    switch (to.query.p) {
      case 'wheel':
        return { name: 'wheel-page', params: { projectId: to.params.projectId }, query: q };
        break;
      case 'events':
        return { name: 'events', params: { projectId: to.params.projectId }, query: q };
        break;
      case 'signals':
        return { name: 'signals-profile', params: { projectId: to.params.projectId }, query: q };
        break;
    }
  }

  dev.log('to.params?.projectId', to.params?.projectId);
  if (to.params?.projectId) {
    tpws.setRouteProject(to.params?.projectId);
  }
  if (to?.name) {
    //todo page store
    page.getPageData(to);
  }
  localStorage.setItem('lastRoute', from.name);
  dev.log('beforeEach out', from?.name, from, to?.name, to);

};

export const afterEach = (to, from) => {
  const tpws = useTpws();
  //console.log('aft to.params', to);
  if (!to.params?.projectId) {
    dev.log('aft prj');
    tpws.unloadProject();
  }

  // console.log('afterEach', from?.name, from, to?.name, to);
};
