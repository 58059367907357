<template>
  <modal-dialog
    :modal="modal"
    v-show="users.visibleEditUserModal"
    v-model:visible="users.visibleEditUserModal"
    ref="modEx"
    @closeModal="closeModal"
  >
    <!--    <template #title>-->
    <!--      <span class="lvb-description-modal__title"></span>-->
    <!--    </template>-->
    <template #content>
      <n-form ref="formRef" :model="formValue" :rules="rules" :label-width="110" id="signup_form" class="form-horizontal"     label-placement="left"
              require-mark-placement="right-hanging"
              label-width="auto">
        <input v-model="token" name="token" type="token" hidden :readonly="true" id="token" class="form-control" />

        <n-form-item :label="$t('Email')" path="email">
          <n-input name="email"
                   type="text" v-model:value="formValue.email" :placeholder="$t('Email')" />
        </n-form-item>
        <n-form-item :label="$t('Firstname')" path="firstname">
          <n-input type="text"
                   id="firstname"
                   name="firstname" v-model:value="formValue.firstname" :placeholder="$t('Firstname')" />
        </n-form-item>
        <n-form-item :label="$t('Lastname')" path="lastname">
          <n-input type="text"
                   id="lastname"
                   name="lastname" v-model:value="formValue.lastname" :placeholder="$t('Lastname')" />
        </n-form-item>
        <n-form-item :label="$t('Company')" path="company">
          <n-input type="text"
                   id="company"
                   name="company" v-model:value="formValue.company" :placeholder="$t('Company')" />
        </n-form-item>
        <n-form-item :label="$t('Position')" path="position">
          <n-input type="text"
                   id="position"
                   name="position" v-model:value="formValue.position" :placeholder="$t('Position')" />
        </n-form-item>
        <n-form-item :label="$t('Phone')" path="phone">
          <n-input type="text"
                   id="phone"
                   name="phone" v-model:value="formValue.phone" :placeholder="$t('Phone')" />
        </n-form-item>
      </n-form>

    </template>
    <template #buttons>
      <button class="btn btn-sec" type="button" @click="closeModal">
        {{ $t('Cancel') }}
      </button>
      <button class="btn btn-pri" type="button" @click="saveUser">
        {{ $t('Save') }}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useUsers } from '@/stores/users';
import { mapStores } from 'pinia';
import api from '@/services/api';
import {ref} from "vue";

export default {
  name: 'edit-user-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    rowUser: {
      type: Object,
    },
  },
  setup() {
    const formRef = ref(null);
    return {
      formRef,
      formValue: ref({
        id: '',
        email: '',
        password: '',
        firstname: '',
        lastname: '',
        company: '',
        position: '',
        phone: '',
      }),};
  },

  components: { ModalDialog },
  data: () => {
    return {
      modal: {
        closeBtn: 'Close',
        noSubmit: true,
        size: '600',
        noTitle: false,
        name: 'edit-user',
        title: 'Edit user profile',
        submitBtn: 'Create',
        typeSubmit: true,
      },
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useUsers),
    rules() {
      return {
        email: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        firstname: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        lastname: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        company: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        position: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        phone: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
      };
    },

  },
  mounted() {},
  methods: {
    ...mapActions(useUsers, ['hideEditUserModal', 'loadUsers']),
    async saveUser() {
      this.formRef
          ?.validate(async (errors) => {
            if (!errors) {
              const resp = await api.post(`/users/edit-profile`, this.formValue);
              dev.log('new user', resp);
              if (resp.status) {
                this.loadUsers();
                this.hideEditUserModal();
              }
            } else {
              dev.log('form not valid', errors);
            }
          })
          .catch((err) => {});

    },
    closeModal() {
      dev.log('closeModal');
      this.hideEditUserModal();
    },
  },
  watch: {
    rowUser: {
      handler(val) {
        if (val) {
          this.formValue = {
            id: this.rowUser._id,
            email: this.rowUser.email,
            firstname: this.rowUser.firstname,
            lastname: this.rowUser.lastname,
            company: this.rowUser.company,
            position: this.rowUser.position,
            phone: this.rowUser.phone,
          };
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
