<template>
  <div class="wheel-node-icon mr-1">
    <v-icon size="24">
      <v-svg v-if="icon" width="16" :src="icon" fill="#2a2e32"></v-svg>
    </v-icon>
  </div>
</template>
<script>
export default {
  name: 'wheel-breadcrumbs-icon',
  props: {
    nodeType: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  computed: {
    icon() {
      let icon;
      switch (this.nodeType) {
        case 'subnet':
          icon = 'wheel/subnet-2';
          break;
        case 'IED':
          icon = 'wheel/ied-2';
          break;
        case 'IED_LABEL':
          icon = 'wheel/label';
          break;
        case 'GOOSE_CTRL':
          icon = 'wheel/binary-2';
          break;
        case 'SV_CTRL':
          icon = 'wheel/binary-2';
          break;
        case 'GOOSE_FCDA':
          icon = 'wheel/osc-2';
          break;
        case 'SV_FCDA':
          icon = 'wheel/osc-2';
          break;
        case 'INPUTS':
          icon = 'wheel/input';
          break;
        case 'INPUTS_LABEL':
          icon = 'wheel/input-label';
          break;
        case 'INPUTS_DATA':
          icon = 'wheel/input-data';
          break;
        default:
          dev.log('unhandled icon', this.nodeType);
          break;
      }
      return icon;
    },
  },
};
</script>
