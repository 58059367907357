<template>
  <div class="card card-default">
    <div class="card-header">
      <a v-bind:href="'#ghost_sv_' + idx" data-toggle="collapse">
        {{ ghostSvCardTitleDisplay == "SVID"
            ? gs.svId
            : $t('Ghost SV')+" "+idx }}
        <span class="badge badge-pill badge-warning ml-1 mr-1" v-if="gs.simulation == 1">{{ $t('Simulated') }}</span>
        <span class="badge badge-pill badge-danger ml-1 mr-1" v-if="gs.smpSynch == 0">{{ $t('Not synchronized') }}</span>
      </a>
      
    </div>
    <div class="card-collapse collapse in" v-bind:id="'ghost_sv_' + idx">
      <div class="card-body">
        <ul>
          <li v-if="gs.simulation == 1" class="mb-2"><span class="text-warning">{{ $t('This message is being sent by a test equipment (simulation = True).') }}</span></li>
          <li><span class="tip-label">{{ $t('Source MAC') }}:</span> {{ gs.srcMac }} <span v-if="gs.macVendor">({{ gs.macVendor }})</span></li>
          <li><span class="tip-label">{{ $t('Destination MAC') }}:</span> {{ gs.dstMac }}</li>
          <li v-if="gs.vlan && 'id' in gs.vlan">
            <span class="tip-label">{{ $t('VLAN-ID') }}:</span>
            {{
              vlanDisplayType == 'HEX'
                ? gs.vlan.id.toString().padStart(5, '0x000')
                : parseInt(gs.vlan.id, 16).toString().padStart(4, '0000')
            }}
          </li>
          <li><span class="tip-label">{{ $t('APP ID') }}:</span> {{ gs.appId.toUpperCase().padStart(6, '0x0000') }}</li>
          <li><span class="tip-label">{{ $t('SV ID') }}:</span> {{ gs.svId }}</li>
          <li><span class="tip-label">{{ $t('Configuration Revision') }}:</span> {{ gs.confRev }}</li>
          <li><span class="tip-label">{{ $t('Number of ASDU') }}:</span> {{ gs.noASDU }}</li>
          <li><span class="tip-label">{{ $t('Synchronization') }}:</span> 
            {{ gs.smpSynch == "2"
              ? $t('Sync-Global')
              : gs.smpSynch == "1"
                ? $t('Sync-Local')
                : $t('Sync-None')
            }}
          </li>
          <li v-if="gs.maxSmpCnt"><span class="tip-label">{{ $t('Maximum sample counter') }}:</span> {{ gs.maxSmpCnt }}</li>
          <li class="pt-2">
            <span class="tip-label">{{ $t('Captured by') }}:</span>&nbsp;

            <span v-for="(lvb, index) in capturedByLvbList">
              {{lvb.lvb_id}}<span v-if="index != (capturedByLvbList.length - 1)">,</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { usePrefs } from '@/stores/prefs';
import { mapStores } from 'pinia';

export default {
  name: 'ghost-sv',
  props: ['gs', 'idx', 'lvbList'],
  data: function () {
    return {};
  },
  computed: {
    ...mapStores(usePrefs),
    vlanDisplayType() {
      return this.prefs.vlanDisplayType;
    },
    ghostSvCardTitleDisplay() {
      return this.prefs.ghostSvCardTitleDisplay;
    },
    capturedByLvbList() {
      return this.lvbList.filter(lvb => this.gs.status.aliveLVB.includes(lvb._id))
    }
  },
};
</script>

<style scoped></style>
