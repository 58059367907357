<template>
  <div class="container-fluid p-0">
    <div v-loading="isLoading" class="accordion mt-2" id="scl-accordion">
      <div class="card-project">
        <div id="scl-header">
          <h5 class="project-page__card-sub-title">
            <button
              class="btn btn-link card-project__btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#scl-collapsed"
              aria-expanded="true"
              aria-controls="scl-collapsed"
            >
              {{ $t('SCL-processors') }}
            </button>
          </h5>
        </div>
        <span class="comma" id="scl-container-user" style="display: none">
          <a href="">
            <span class="users-container-current"></span>
          </a>
        </span>
        <div class="collapse show" id="scl-collapsed" aria-labelledby="#scl-header" data-parent="#scl-accordion">
          <div class="card-body" style="padding: 10px 40px 100px">
            <div class="d-flex flex-row flex-wrap justify-content-between" ref="sclWrapper" id="scl-wrapper">
              <template v-for="el in elementsList">
                <div :id="'scl-progress-' + el.elemId" class="scl-item pb-3">
                  <div class="">
                    <div class="">
                      <h6 class="scl-item__header">{{ $t(el.title) }}</h6>
                    </div>
                    <div class="d-flex flex-row">
                      <template v-if="el.status == 'success'">
                        <div class="scl-item_success">
                          <span class="mr-1">{{ $t('Success') }}</span>
                          <i class="" aria-hidden="true"></i>
                        </div>
                      </template>
                      <template v-else-if="el.status == 'fail'">
                        <div class="scl-item_fail">
                          <span class="mr-1">{{ $t('Fail') }}</span>
                          <i class="fa fa-times text-danger" aria-hidden="true"></i>
                        </div>
                      </template>
                      <template v-else>
                        <div v-if="tpws.inProcessing" class="scl-item_">
                          <span class="mr-1">{{ $t('Processing...') }}</span>
                          <i class="fal fa-pulse fa-spinner" aria-hidden="true"></i>
                        </div>
                        <div v-else class="scl-item_">
                          <span class="mr-1">{{ $t('No status') }}</span>
                        </div>
                      </template>
                      <div v-if="el.hasError" @click="onShowModalSclDetail(el.elemId)" class="pointer-cursor">
                        <v-svg src="info" fill="#1757BD" width="20" height="20"></v-svg>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getSize from 'get-size';
import { debounce } from '@/plugins/debounce';
import { mapStores } from 'pinia';
import { usePage } from '@/stores/page';
import { useTpws } from '@/stores/tpws';
import { mapActions } from 'pinia/dist/pinia';

export default {
  name: 'scl-widgets',
  data: () => {
    return {
      elements: [],
    };
  },
  computed: {
    ...mapStores(usePage),
    ...mapStores(useTpws),
    history() {
      return this.tpws.historyList;
    },
    sortedList() {},
    historyList() {
      return this.tpws?.historyList;
    },
    isLoading() {
      return this.tpws?.projectStatusLoading;
    },
    inProcessing() {
      return this.tpws?.inProcessing;
    },
    currentVersionIndex() {
      return this.tpws?.currentVersionIndex;
    },
    elementsList() {
      if (this.currentVersionIndex === undefined) {return []}
      if (!this.history) {return []}
      const list =  this.history[this.currentVersionIndex]?.sclProcessors || [];
      let initList = this.history[this.currentVersionIndex]?.sclProcessorsInit?.map((scl) => {
        const found = list.find(el=>el.processor_id === scl.processorId);
        const status = found ? (found.processing_result ? found.processing_result.toLowerCase() : 'fail') : 'inProcess';
        const original = found || scl;
        return {
          title: scl.label  || scl.processorId,
          status,
          elemId: scl.processorId,
          originScl: original,
          hasError: original.errorLog && Object.values(original.errorLog).some((s) => s) || false,
        }
      });
      return initList || [];
    },
  },
  mounted() {
    this.$router.isReady().then(() => {
      this.changeIndex(this.currentVersionIndex);
    });
  },
  methods: {
    ...mapActions(useTpws, ['setCurrentVersionIndex']),
    changeIndex(index) {
      this.debouncedUpdateSclResult(index);
    },
    updateSclResult: debounce(function (index) {
      //console.log('debounce UpdateSclResult');
      this.debouncedUpdateSclResult(index);
    }, 50),

    debouncedUpdateSclResult(index) {
      // dev.log('updateSclResult', index);
      this.elements = [];

      const self = this;

      if (this?.history?.length > 0 && this?.history[index]) {
        const sortedList = this.history[index]?.sclProcessors
          ? this.sortByKey(this.history[index]?.sclProcessors, 'processor_id')
          : [];

        // если просматриваемая версия в процессе обработки и она последняя
        if (this.tpws.inProcessing && index === this.history.length - 1) {
          const sortedSclProcessorsInit = this.history[index]?.sclProcessorsInit
            ? this.sortByKey(this.history[index].sclProcessorsInit, 'processorId')
            : [];

          sortedSclProcessorsInit.forEach((scl) => {
            self.setAllData(scl.label || scl.processorId, 'inProcess', scl.processorId, scl);
          });

          sortedList.forEach((scl) => {
            const sclResult = scl.processing_result ? scl.processing_result.toLowerCase() : 'fail';

            // self.showMenuItemByScl(scl.processor_id, sclResult === 'success');
            self.setDataById(scl.label || scl.processor_id, sclResult, scl.processor_id, scl);
          });
        } else {
          // console.log('sortedList', sortedList);
          sortedList.forEach((scl) => {
            const sclResult = scl.processing_result ? scl.processing_result.toLowerCase() : 'fail';
            self.setAllData(scl.label || scl.processor_id, sclResult, scl.processor_id, scl);
          });
        }
      }
    },

    setDataById(title, status, elemId, originScl) {
      if (!this.elements) {
        this.elements = [];
      }
      let found = this.elements.find((el) => el.elemId == elemId);
      if (!found) {
        this.elements.push({ elemId: elemId });
        found = this.elements.find((el) => el.elemId == elemId);
      }
      found['title'] = title;
      found['status'] = status;
      found['originScl'] = originScl;
      found['hasError'] = originScl.errorLog && Object.values(originScl.errorLog).some((s) => s);
    },

    setAllData(title, status, elemId, originScl) {
      // console.log('originScl', originScl);
      const hasError = originScl.errorLog && Object.values(originScl.errorLog).some((s) => s);
      this.elements.push({
        title,
        status,
        elemId,
        originScl,
        hasError,
      });
    },
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        const x = a[key],
          y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    // showMenuItemByScl(type, isSuccess) {
    //   const showElem = (idList) => {
    //     idList.forEach((id) => $(`#${id}`).removeClass('d-none'));
    //   };
    //
    //   if (isSuccess) {
    //     if (type === 'ELLY') {
    //       showElem(['menu-nav-wheel', 'menu-nav-docs', 'doc_btn', 'menu-nav-events']);
    //     }
    //
    //     if (type === 'SIGNAL_TABLE') {
    //       showElem(['menu-nav-docs', 'signal-table']);
    //     }
    //
    //     if (type === 'SSD_EXPLORER') {
    //       showElem(['menu-nav-ssd']);
    //     }
    //   }
    // },
    onShowModalSclDetail(sclType) {
      // console.log('onShowModalSclDetail', sclType);
      const logItemTemplate = ([title, text], parentItem, needDivider) => {
        const divWrapper = document.createElement('div');
        divWrapper.className = 'col-md-12 mb-2';

        const titleElem = document.createElement('h4');
        titleElem.innerText = this.$t(title);

        const btnElem = document.createElement('a');
        btnElem.className = 'btn-save';
        btnElem.innerText = this.$t('Save to file');
        btnElem.onclick = function () {
          window.dispatchEvent(new CustomEvent('save-text', { detail: { filename: title + '.txt', text: text } }));
        };
        const textElem = document.createElement('div');
        textElem.innerText = text;

        divWrapper.appendChild(titleElem);
        divWrapper.appendChild(btnElem);
        divWrapper.appendChild(textElem);

        if (needDivider) {
          const dividerElem = document.createElement('hr');
          divWrapper.appendChild(dividerElem);
        }

        parentItem.appendChild(divWrapper);
      };

      const currentVersion = this.history[this.currentVersionIndex];
      const { errorLog } = currentVersion.sclProcessors.find(
        (scl) => scl.processor_id.toLowerCase() === sclType.toLowerCase()
      );
      if (errorLog) {
        const modalElem = $('.detail-scl-processor-modal');

        const [contentElem] = modalElem.find('.content');
        contentElem.innerHTML = '';

        Object.entries(errorLog)
          .filter(([, text]) => text)
          .forEach((item, index, originArray) => {
            logItemTemplate(item, contentElem, originArray.length && index !== originArray.length - 1);
          });

        modalElem.modal();
      } else {
        console.info('no error data');
      }
    },
  },
  watch: {
    history: {
      handler(val) {
        if (val?.length) {
          this.setCurrentVersionIndex(val.length - 1);
          this.changeIndex(val.length - 1);
        }
      },
      // deep: true,
    },
    currentVersionIndex: {
      handler(val) {
        this.setCurrentVersionIndex(val);
        this.changeIndex(val);
      },
      // deep: true,
    },
  },
};
</script>

<style scoped></style>
