<template>
  <div>
    <teleport to="#modals-target">
      <scd-upload :submitActive="selectedSclProcessors.length > 0 && !submitted" :cancelActive="true" @upload="formSubmit" @cancel="cancel">
        <div class="modal-window__layout">
          <div class="modal-window__main">
            <div class="modal-window__body">
              <div class="tpws-centered-form container mt-5">
                <div v-if="false" class="d-none mt-3" id="scd-new__error-container">
                  <div class="upload-error-buttons text-center">
                    <a :href="'/projects/' + name" class="mr-3">{{ $t('Return to the project') }}</a>
                    <a :href="'/projects/' + name + '/scd/new'">{{ $t('Upload again') }}</a>
                  </div>
                  <div class="alert alert-danger mt-3" id="scd-new__error">
                    <h2 class="ml-0">{{ $t('File failed to process') }}</h2>
                    <p>{{ $t('Validation errors detected are listed below') }}</p>
                  </div>
                </div>
                <div v-if="false" class="d-none mt-3" id="scd-new__validation-error">
                  <div class="upload-error-buttons text-center">
                    <a :href="'/projects/' + name" class="mr-3">{{ $t('Return to the project') }}</a>
                    <a :href="'/projects/' + name + '/scd/new'">{{ $t('Upload again') }}</a>
                  </div>
                  <div class="alert alert-danger mt-3" id="scd-new__validation-error-content">
                    <h2 class="ml-0">{{ $t('File failed to process') }}</h2>
                    <p>{{ $t('Validation errors detected are listed below') }}</p>
                  </div>
                </div>
                <form
                  method="post"
                  enctype="application/x-www-form-urlencoded;charset=utf-8"
                  class="mt-4"
                  id="scd-new__form"
                  ref="uploadForm"
                >
                  <div class="form-group">
                    <label>{{ $t('.SCD file') }}</label>
                    <label for="scdfile" class="block cursor-pointer">
                      <div
                        class="dropzone-block"
                        @click="newFile"
                        @dragover="dragoverFile"
                        @dragleave="dragleaveFile"
                        @drop="dropFile"
                        :disabled="submitted"
                      >
                        <input
                          type="file"
                          class="hidden_input"
                          @change="onChangeFile"
                          id="scdfile"
                          ref="scdfile"
                          name="scdfile"
                          accept=".scd,.cid,.sed,.ssd,.icd"
                          :disabled="submitted"
                        />

                        <div :class="{
                          'text-gray-500': submitted,
                          'text-gray-800': !submitted
                         }">
                          {{ $t('You can drop file in here or click') }}
                        </div>

                        <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                          <li v-for="file in filelist" :disabled="submitted" :class="{
                          'flex items-center p-1 text-sm text-gray-500': submitted,
                          'flex items-center p-1 text-sm text-gray-800': !submitted
                         }">
                            <n-ellipsis style="max-width: 270px">
                              {{ file.name }}
                            </n-ellipsis>
                            <span
                              class="ml-2"
                              @click.stop.prevent="removeFile(filelist.indexOf(file))"
                              title="Remove file"
                              ><img src="/images/crossline-blue.svg" loading="lazy" alt=""
                            /></span>
                          </li>
                        </ul>
                      </div>
                    </label>
                  </div>
                  <div v-if="sclProcessorList?.length" class="form-group">
                    <label for="" id="label_scl" class="mr-2">
                      {{ $t('SCL-processors') }}
                    </label>
                    <div class="p-6">
                      <div class="card-body" id="scl_body">
                        <!--                      <n-config-provider :theme-overrides="themeOverrides">-->
                        <!--                        <n-checkbox-group v-model:value="selectedSclProcessors" @update:value="handleUpdateValue">-->
                        <div class="scl-processors-list">
                          <t-checkbox-multi v-model:value="selectedSclProcessors" :options="sclProcessorListOpts" :disabled="submitted"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="comment">{{ $t('Comment') }}</label>
                    <textarea name="comment" id="comment" cols="30" rows="5" class="form-control" :disabled="submitted"></textarea>
                  </div>
                  <template v-if="hasErrors">
                    <div v-for="error in formErrors" class="alert alert-danger mt-3" id="scd-new__error">
                      <h2 class="ml-0">{{ $t('File failed to process') }}</h2>
                      <p>{{ error }}</p>
                    </div>
                  </template>
                  <template v-if="submitted">
                    <span>{{ $t('Uploading...') }}</span>
                    <div class="upload-progress-bar">
                      <n-progress type="line" :percentage="uploadProgress" color="#18a058" />
                    </div>
                  </template>
                </form>
              </div>
              <div class="error-message-container container"></div>
            </div>
          </div>
        </div>
      </scd-upload>
    </teleport>
  </div>
</template>

<script>
import api from '@/services/api';
import ScdUpload from '@/components/timeline/ScdUpload.vue';
import TCheckboxMulti from '@/components/ui/TCheckboxMulti.vue';
import { mapStores } from 'pinia';
import { useLicense } from '@/stores/license';
import { useTpws } from '@/stores/tpws';
import { usePage } from '@/stores/page';
import { useVisual } from '@/stores/visual';
import eventBus from '@/utils/eventBus';
// import ScriptX from '@/plugins/vue-scriptx';

export default {
  name: 'scd-upload-page',
  model: {
    prop: 'visibleScdUpload',
    event: 'change',
  },
  props: {
    visibleScdUpload: {
      type: Boolean,
    },
    visibleScdUploadProgress: {
      type: Boolean,
    }
  },
  // emits: ['update:visibleScdUpload'],
  components: {
    ScdUpload,
    TCheckboxMulti,
    // 'scriptx': ScriptX
  },
  data: () => {
    return {
      formSclProcessorList: [],
      selectedSclProcessors: [],
      submitted: false,
      uploadedFiles: [],
      filelist: [],
      formErrors: [],
      uploadProgress: 0,
      abortController: new AbortController(),
    };
  },
  computed: {
    filenames() {},
    ...mapStores(useTpws),
    ...mapStores(usePage),
    ...mapStores(useVisual),
    ...mapStores(useLicense),
    hasErrors() {
      return this.formErrors.length > 0;
    },
    currentProject() {
      return this.tpws.currentProject;
    },
    name() {
      return this.page.pageData['scd-new']?.name;
    },
    licenseSclProcessors() {
      return this.license.sclProcessors;
    },
    sclProcessorListOpts() {
      let list = this.tpws.sclProcessorList;
      let licenseProcs = this.licenseSclProcessors;
      let listFull = list?.map((el) => {
        // dev.log('el', el);
        const av = licenseProcs?.find((e) => e.typeName === el?.value)?.available;
        // dev.log('av', av);
        return { ...el, name: el.label, id: el.value, disabled: !av };
      });
      return listFull;
    },
    sclProcessorList() {
      let list = this.tpws.sclProcessorList;
      let licenseProcs = this.licenseSclProcessors;
      let listFull = list?.map((el) => {
        // dev.log('el', el);
        const av = licenseProcs?.find((e) => e.typeName === el?.value)?.available;
        // dev.log('av', av);
        return { ...el, available: av };
      });
      return listFull || [];
    },
  },
  mounted() {},
  unmounted() {},

  methods: {
    newFile() {
      dev.log('newFile');
      // this.$refs.scdfile.value = null
      // this.filelist = [];
      this.formErrors = [];
    },
    onChangeFile() {
      dev.log('onChangeFile');
      this.filelist = [...this.$refs.scdfile.files];
      // this.formErrors = [];
    },
    dragoverFile(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleaveFile(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    dropFile(event) {
      event.preventDefault();
      // dev.log('drop', event.dataTransfer.files);
      const dt = new DataTransfer();
      dt.items.add(event.dataTransfer.files[0]);
      this.$refs.scdfile.files = dt.files;
      this.onChangeFile(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    removeFile(i) {
      if (!submitted) {
        this.filelist.splice(i, 1);
        this.$refs.scdfile.value = null;
      }
    },
    visible() {
      //dev.log('upload show');
      this.page.getPageData({
        name: 'scd-new',
        path: '/projects/' + this.currentProject + '/scd/new',
      });

      // document.querySelectorAll('[id^="scl_"]').forEach((sclElem) => {
      //   sclElem.setAttribute('checked', 'checked');
      // });

      this.checkAvailable();
    },
    checkAvailable() {
      this.selectedSclProcessors = [];
      this.$nextTick(() => {
        try {
          this.sclProcessorList?.forEach((el) => {
            if (el.available) {
              this.selectedSclProcessors.push(el.value);
            }
          });
        } catch (e) {
          console.error(e);
        }
      });
    },
    toggleLoading(isLoading = true) {
      this.submitted = isLoading;
      // $('#scdfile-send-btn').attr('disabled', isLoading);
      // $('#comment').attr('readonly', isLoading);

      // $('#scdfile').attr('disabled', isLoading);

      if (isLoading) {
        $('#scd-new__form #spinner').show();
      } else {
        $('#scd-new__form #spinner').hide();
      }

      // document.querySelectorAll('[id*="scl_"]').forEach((sclElem) => {
      //   if (isLoading) {
      //     sclElem.setAttribute('disabled', 'disabled');
      //   } else {
      //     sclElem.removeAttribute('disabled');
      //   }
      // });

      if (isLoading) {
        $('#scl_body').css('background-color', '#e9ecef');
      } else {
        $('#scl_body').css('background-color', 'unset');
      }
    },
    fileSelect(event, numFiles, label) {
      dev.log('fileSelect', event);
      const input = $(event.target).parents('.input-group').find(':text'),
        log = numFiles > 1 ? numFiles + ' files selected' : label;

      if (input.length) input.val(log);
      else if (log) dev.log(log);
    },
    formSubmit(e) {
      e?.preventDefault();
      this.submitted = true;
      this.formErrors = [];

      const formData = new FormData(this.$refs.uploadForm);

      dev.log('selected', this.selectedSclProcessors);

      formData.append('sclProcessorList', JSON.stringify(this.selectedSclProcessors));
      if (formData.get('scdfile').size) {
        const scdfile = formData.get('scdfile');

        const reader = new FileReader();

        reader.readAsText(scdfile);

        this.toggleLoading();

        reader.onload = (evt) => {
          const xmlstr = evt.target.result;

          const xmldoc = new DOMParser().parseFromString(xmlstr, 'application/xml');

          if (xmldoc.querySelector('parsererror')) {
            // XML is invalid
            // const currentPage = location.pathname;
            // location.replace(currentPage + '?error=File has xml syntax error or empty');
            eventBus.dispatch('message', {
              message: this.$t('File has xml syntax error or empty'),
              close: true,
              type: 'error',
            });
            this.formErrors.push(this.$t('File has xml syntax error or empty'));
            this.toggleLoading(false);

            this.visual.sclUploadModalVisible = true;
          } else {
            this.uploadProgress = 0;
            api
              .post('/projects/' + this.currentProject + '/scd/new', formData, {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                },
                onUploadProgress: progressEvent => {
                  this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                },
                signal: this.abortController.signal,
              })
              .then((data, textStatus, jqXHR) => {
                dev.log('upload', data, textStatus, jqXHR);
                this.visual.sclUploadModalVisible = false;
                this.tpws.reloadProjectStatus();
                this.tpws.setCurrentVersionIndex(this.tpws.historyList.length - 1);
                if (data.result === 'redirect') {
                  window.location = data.url;
                } else {
                  this.closeUpdate();
                }
              })
              .catch((e) => {
                // console.error(e);
                if (e.response) {
                  this.toggleLoading(false);
                  eventBus.dispatch('message', {
                    message: this.$t('SCD Upload Failed'),
                    close: true,
                    type: 'error',
                  });

                  this.formErrors.push(this.$t(e.response.data.data));
                  this.$nextTick(() => {
                    this.visual.sclUploadModalVisible = true;
                  });
                } else if (e.name == "CanceledError") {
                  eventBus.dispatch('message', {
                    message: this.$t('SCD Upload Canceled'),
                    close: true,
                    type: 'error',
                  });
                  this.closeUpdate();
                } else if (e.request) {
                  this.toggleLoading(false);
                  eventBus.dispatch('message', {
                    message: this.$t('SCD Upload Failed'),
                    close: true,
                    type: 'error',
                  });

                  this.formErrors.push(this.$t('Can\'t send the request. Check your internet connection'));
                  this.$nextTick(() => {
                    this.visual.sclUploadModalVisible = true;
                  });
                } else {
                  this.toggleLoading(false);
                  eventBus.dispatch('message', {
                    message: this.$t('SCD Upload Failed'),
                    close: true,
                    type: 'error',
                  });

                  this.formErrors.push(this.$t('Unknown error occured'));
                  this.$nextTick(() => {
                    this.visual.sclUploadModalVisible = true;
                  });
                }

              });
          }
        };
      } else {
        eventBus.dispatch('message', {
          message: this.$t('Format check error: File is empty'),
          close: true,
          type: 'error',
        });

        this.formErrors.push(this.$t('Format check error: File is empty'));
        this.$nextTick(() => {
          this.visual.sclUploadModalVisible = true;
        });
      }
    },
    cancel(e){
      if (this.submitted) {
        this.abortController.abort()
        this.abortController = new AbortController()
      }
      this.closeUpdate()
    },
    close() {
      this.$emit('update:visibleScdUpload', false);
    },
    closeUpdate() {
      this.toggleLoading(false);
      this.filelist = [];
      this.selectedSclProcessors = [];
      this.$refs.uploadForm.reset();
      this.$emit('update:visibleScdUpload', false);
      this.$emit('versionUploaded', false);
    },
    uploadProgressHandler(data) {
      console.log(data);
    },
  },
  watch: {
    filelist: {
      handler(newValue) {
        // dev.log('watch filelist', newValue);
        if (newValue) {
          this.formErrors = [];
        }
      },
      deep: true,
      // immediate: true,
    },
    sclProcessorList: {
      handler(newValue) {
        dev.log('watch sclProcessorList', newValue);
        if (newValue) {
          this.checkAvailable();
        }
      },
      // deep: true,
      immediate: true,
    },
    visibleScdUpload(val) {
      if (val) {
        this.visible();
      }
    },
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.text-gray-800 {
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}
.text-gray-500{
  --text-opacity:1;
  color:#a0aec0;
  color:rgba(160,174,192, var(--text-opacity));
}
.scl-processors-list {
  display: flex;
}
.form-check {
  padding-bottom: 0.3rem;
}
.form-check-input {
  position: absolute;
  /*margin-top: 0.3rem;*/
  margin-left: -1.25rem;
}
.form-check-label {
  margin-bottom: 0;
}
</style>

<style scoped>
/*! CSS Used from: Embedded */
*,
::after,
::before {
  box-sizing: border-box;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
}
/*! CSS Used from: Embedded */
*,
::after,
::before {
  box-sizing: border-box;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
}
* {
  margin: 0;
  padding: 0;
}
/*! CSS Used from: Embedded */
input:disabled {
  background-color: transparent;
  cursor: not-allowed;
}
input::-webkit-input-placeholder {
  color: #c0c4cc;
}
/*! CSS Used from: Embedded */
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}
::before,
::after {
  --tw-content: '';
}
input {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}
:-moz-focusring {
  outline: auto;
}
input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}
input::placeholder {
  opacity: 1;
  color: #9ca3af;
}
:disabled {
  cursor: default;
}
*,
::before,
::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-scroll-snap-strictness: proximity;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
}
.absolute {
  position: absolute;
}
.block {
  display: block;
}
.h-px {
  height: 1px;
}
.w-px {
  width: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-hidden {
  overflow: hidden;
}
.border {
  border-width: 1px;
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
.p-12 {
  padding: 3rem;
}
.opacity-0 {
  opacity: 0;
}
/*! CSS Used from: http://alpha-tekvel.local:3000/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
input {
  overflow: visible;
}
.border {
  border: 1px solid #dee2e6 !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
}
/*! CSS Used from: http://alpha-tekvel.local:3000/css/wheel.main.css */
* {
  margin: 0;
  padding: 0;
}
</style>
