<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 12.0833L14.1667 7.91665L5.83334 7.91665L10 12.0833Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'caret-down',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
