import api from './api';
import axiosPublic from './api';

const ajax = {};

ajax.getLVBList = (success, error) => ajax.main(`/projects/${tpws.projectName}/get-lvb-list`, success, error);

ajax.getuserbyemail = (email, success, error) =>
  ajax.get(`${tpws.projectName}/getuserbyemail?email=${email}`, success, error);

ajax.removeuserrole = (email, role, success, error) =>
  ajax.get(`${tpws.projectName}/removeuserrole?email=${email}&role=${role}`, success, error);

ajax.adduserrole = (email, role, success, error) =>
  ajax.get(`${tpws.projectName}/adduserrole?email=${email}&role=${role}`, success, error);

ajax.addusertoproject = (email, success, error) =>
  ajax.get(`${tpws.projectName}/adduser?email=${email}`, success, error);

ajax.inviteuserbyemail = (email, role, success, error) =>
  ajax.get(`${tpws.projectName}/invite2?email=${email}&role=${role}`, success, error);

ajax.setIedOffline = (currentProject, ied, success, error) =>
  ajax.get(`/projects/${currentProject}/setiedoffline?ied=${ied}`, success, error);

ajax.getoscs = (timestamp, prev, next, success, error) =>
  ajax.get(`oscs?t=${timestamp}&prev=${prev}&next=${next}`, success, error);

ajax._getsignals = (timestamp, prev, next, success, error) =>
  ajax.get(`draw_data?t=${timestamp}&prev=${prev}&next=${next}`, success, error);

ajax.getsignals = (timestamp, prev, next, commonPart, signals, success, error) =>
  ajax.get(
    `getsignals?t=${timestamp}&prev=${prev}&next=${next}&common=${commonPart}&signals=${signals}`,
    success,
    error
  );

ajax.getsignalsmeta = (timestamp, prev, next, success, error) =>
  ajax.get(`getsignalsmeta?t=${timestamp}&prev=${prev}&next=${next}`, success, error);

ajax.getsignalsmetaByProject = (timestamp, prev, next, project, success, error) =>
  ajax.get(`getsignalsmeta?t=${timestamp}&prev=${prev}&next=${next}&project=${project}`, success, error);

ajax.getpcapsmeta = (timestamp, timeBefore, timeAfter, success, error) =>
  ajax.get(`getpcapsmeta?t=${timestamp}&timebefore=${timeBefore}&timeafter=${timeAfter}`, success, error);

ajax.activate = (id, success, error) => ajax.get(`${id}/_activation`, success, error);

ajax.srcmacnull = (id, success, error) => ajax.get(`/projects/${id}/srcmacnull`, success, error);

ajax.changeLocale = (locale, success, error) => ajax.get(`/changelocale?locale=${locale}`, success, error);

ajax.editDone = (id, password, success, error) =>
  ajax.get(`/users/admin-edit?id=${id}&password=${password}`, success, error);

ajax.countUnhandledEvents = (success, error) => ajax.get('count-unhandled-events', success, error);

ajax.getPropertiesSchema = (success, error) =>
  ajax.get(`/users/get-properties-schema?projectName=${tpws.projectName}`, success, error);

ajax.setPropertiesSchema = (data, success, error) => ajax.post('/users/set-properties', data, success, error);

ajax.editProfile = (data, success, error) => ajax.post('/users/edit-profile', data, success, error);

ajax.get = (url, success, error) => {
  api.get(url).then(success).catch(error);
};

ajax.post = (url, data, success, error) => {
  api.post(url, data).then(success).catch(error);
};

export default ajax;
