<template>
  <div class="row justify-content-center">
    <div class="col-sm-offset-3 col-sm-7">
      <h1 class="text-center">{{ $t('Sign up for Tekvel Park') }}</h1>
      <br />
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-sm-offset-3 col-sm-4 tps-error-message">
      <p v-if="message" class="bg-danger tpws-roundborder-block">{{ message }}</p>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-sm-offset-3 col-sm-4">
      <n-form ref="formRef" :model="formValue" :rules="rules" :label-width="110" id="signup_form" class="form-horizontal"     label-placement="left"
              require-mark-placement="right-hanging"
              label-width="auto">
        <input v-model="token" name="token" type="token" hidden :readonly="true" id="token" class="form-control" />

        <n-form-item :label="$t('Email')" path="email">
          <n-input name="email" :readonly="hasEmailQuery"
                   type="text" v-model:value="formValue.email" :placeholder="$t('Email')" />
        </n-form-item>
        <n-form-item :label="$t('Password')" path="password">
          <n-input name="password" type="password" v-model:value="formValue.password" show-password-on="click" :placeholder="$t('Password')" />
        </n-form-item>
        <n-form-item :label="$t('Firstname')" path="firstname">
          <n-input type="text"
                   id="firstname"
                   name="firstname" v-model:value="formValue.firstname" :placeholder="$t('Firstname')" />
        </n-form-item>
        <n-form-item :label="$t('Lastname')" path="lastname">
          <n-input type="text"
                   id="lastname"
                   name="lastname" v-model:value="formValue.lastname" :placeholder="$t('Lastname')" />
        </n-form-item>
        <n-form-item :label="$t('Company')" path="company">
          <n-input type="text"
                   id="company"
                   name="company" v-model:value="formValue.company" :placeholder="$t('Company')" />
        </n-form-item>
        <n-form-item :label="$t('Position')" path="position">
          <n-input type="text"
                   id="position"
                   name="position" v-model:value="formValue.position" :placeholder="$t('Position')" />
        </n-form-item>
        <n-form-item :label="$t('Phone')" path="phone">
          <n-input type="text"
                   id="phone"
                   name="phone" v-model:value="formValue.phone" :placeholder="$t('Phone')" />
        </n-form-item>
        <n-form-item label="&nbsp;" :show-require-mark="false" path="agreetoc">
          <n-switch id="agreetoc"
                    name="agree" v-model:value="formValue.agreetoc" />&nbsp;{{ $t('I agree with') }}&nbsp;<a href="/tos" target="_blank">{{ $t('Terms of Service') }}</a>
        </n-form-item>

        <n-form-item label="&nbsp;">
          <div class="btn-wrap">
            <button
                v-if="useReCaptcha"
                id="btn_submit"
                class="btn btn-pri g-recaptcha"
                :data-sitekey="sitekey"
                data-callback="onSubmit"
                @click.prevent="onSubmit"
            >
              {{ $t('Sign up') }}
            </button>
            <button v-else class="btn btn-pri"
                    type="button"
                    :aria-label="$t('Sign up')"
                    @click="onSubmit"
            >
              {{ $t('Sign up') }}
            </button>
            <div v-if="sitekey">
              <vue-recaptcha
                  @verify="onVerify"
                  @expired="onExpired"
                  size="invisible"
                  ref="recaptcha"
                  :sitekey="sitekey"
              />
              <!--            <vue-recaptcha ref="recaptcha" :sitekey="sitekey" />-->
            </div>
          </div>

        </n-form-item>

      </n-form>

    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import api from '@/services/api';
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs } from '@vuelidate/validators';
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useAuth } from '@/stores/auth';
import { usePage } from '@/stores/page';
import {ref} from "vue";

export default {
  name: 'signup-page',
  setup() {
    const formRef = ref(null);
    return { v$: useVuelidate(),
      formRef,
      formValue: ref({
        emailRaw: '',
        password: '',
        firstname: '',
        lastname: '',
        company: '',
        position: '',
        phone: '',
        agreetoc: '',
      }),};
  },
  components: { VueRecaptcha },
  data: () => {
    return {
      test: {dot:{value:1}},
      emailRaw: '',
      password: '',
      firstname: '',
      lastname: '',
      company: '',
      position: '',
      phone: '',
      agreetoc: '',
    };
  },
  validations() {
    return {
      email: { required, email },
      password: { required },
      firstname: { required },
      lastname: { required },
      company: { required },
      position: { required },
      phone: { required },
      agreetoc: {
        required,
        checked: (val) => {
          return val;
        },
      },
    };
  },
  computed: {
    ...mapStores(usePage),
    token() {
      return this.$route.query['token'] ? this.$route.query['token'] : this.page.pageData['auth-signup']?.token;
    },
    rules() {
      return {
        email: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        password: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        firstname: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        lastname: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        company: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        position: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        phone: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        agreetoc: {
          required: true,
          validator(rule, value) {
            dev.log('agree val', rule, value)
            if (!value) {
              return new Error("Check is required");
            }
            return value;
          },
          // type: "array",
          message: this.$t('Value is required'),
          trigger: ['change'],
        },
      };
    },

    email: {
      get() {
        return this.$route.query['email'] ? this.$route.query['email'] : this.emailRaw;
      },
      set(newValue) {
        this.emailRaw = newValue;
      },
    },
    hasEmailQuery() {
      return !!this.$route.query['email'];
    },
    sitekey() {
      return this.page.pageData['auth-signup']?.sitekey;
    },
    useReCaptcha() {
      return this.page.pageData['auth-signup']?.useReCaptcha;
    },
  },

  mounted() {
    if (this.$route.query['email']) {
      this.formValue.email = this.$route.query['email'];
    }
  },
  methods: {
    ...mapActions(useAuth, ['signup']),
    serializeForm(form) {
      var object = {};
      var formData = new FormData(form);
      dev.log('formData', formData)
      formData.forEach((value, key) => (object[key] = value));
      return object;
      // var obj = {};
      // var formData = new FormData(form);
      // for (var key of formData.keys()) {
      //   obj[key] = formData.get(key);
      // }
      // return obj;
    },
    onVerify: function (response) {
      dev.log('Verify: ' + response);
      dev.log('this.formRef', this.formRef)
      const myData = this.formValue;
      if (this.token) {
        myData['token'] = this.token;
      }
      if (this.formValue.email) {
        myData['emailRaw'] = this.formValue.email;
      }
      dev.log('myData', myData);
      this.signup({ ...myData }, { name: 'client-root' }).then((url) => {
        dev.log('ret', url);
        this.$router.push(url);
      });
    },
    onExpired: function () {
      dev.log('Expired');
    },
    async onSubmit() {
      // const isFormCorrect = await this.v$.$validate();
      // // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      // if (!isFormCorrect) {
      //   dev.log('form not valid');
      //   return;
      // } else {
      //   this.doSubmit();
      // }
      this.formRef
          ?.validate((errors) => {
            if (!errors) {
              this.doSubmit();
            } else {
              dev.log('form not valid', errors);
            }
          })
          .catch((err) => {});
    },
    doSubmit() {
      this.$refs.recaptcha.execute();
    },
  },
};
</script>

<style scoped lang="scss">
.btn-wrap {
  flex: auto;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="scss"></style>
