export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function throttle(fn, wait){
  let throttled = false;
  return function(...args){
    if(!throttled){
      fn.apply(this,args);
      throttled = true;
      setTimeout(()=>{
        throttled = false;
      }, wait);
    }
  }
}

export function debounce1(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export default {
  methods: {
    vueDebounce(func, delay) {
      let debounceTimer;
      return function () {
        dev.log('debouncing call..');
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
        dev.log('..done');
      };
    },
  },
};
