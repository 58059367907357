import { defineStore } from 'pinia';

export const useVisual = defineStore({
  id: 'visual',
  persist: {
    paths: ['sidebarCollapsed'],
    storage: localStorage,
  },
  state: () => {
    return {
      editProjectModalVisible: false,
      visibleAboutCompanyModal: false,
      userProfileModalVisible: false,
      preparedReportsModalVisible: false,
      archiveEventsModalVisible: false,
      projectAddModalVisible: false,
      sclUploadModalVisible: false,
      userSettingsVisible: false,
      userInviteModalVisible: false,
      eventsCounterModalVisible: false,
      devStatusModalVisible: false,
      eventsSearchFiltersVisible: false,
      wheelOrder: false,
      wheelCollapsed: false,
      vertical: false,
      contentLeft: 0,
      modalVisible: false,
      contentWidth: 1920,
      sidebarCollapsed: true,
      isTouch: false,
      devMode: false,
    };
  },

  actions: {
    resetModals() {
      this.editProjectModalVisible = false;
      this.visibleAboutCompanyModal = false;
      this.userProfileModalVisible = false;
      this.preparedReportsModalVisible = false;
      this.projectAddModalVisible = false;
      this.sclUploadModalVisible = false;
      this.userSettingsVisible = false;
      this.userInviteModalVisible = false;
      this.eventsCounterModalVisible = false;
      this.archiveEventsModalVisible = false;
      this.devStatusModalVisible = false;
    },
    closeCollapseSidebar() {
      this.sidebarCollapsed = true;
    },
    setCollapseSidebar(val) {
      this.sidebarCollapsed = val;
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleEventsFilter() {
      this.eventsSearchFiltersVisible = !this.eventsSearchFiltersVisible;
    },
    showProjectAddModal(value) {
      this.projectAddModalVisible = value;
    },
    showSclUploadModal(value) {
      this.sclUploadModalVisible = value;
    },
    showEventsCounterModal(value) {
      this.eventsCounterModalVisible = value;
    },
    showAboutCompanyModal() {
      this.visibleAboutCompanyModal = true;
    },
    hideAboutCompanyModal() {
      this.visibleAboutCompanyModal = false;
    },
    hideUserSettingsModal() {
      this.userSettingsVisible = false;
    },
    setVertical(val) {
      this.vertical = val;
    },
    setTouch(val) {
      this.isTouch = val;
    },
    toggleWheelCollapsed(val) {
      this.wheelCollapsed = !this.wheelCollapsed;
    },
    showUserProfile(value) {
      this.userProfileModalVisible = value;
    },
    showUserSettings(value) {
      this.userSettingsVisible = value;
    },
    showUserInvite(value) {
      dev.log('showUserInvite', value);
      this.userInviteModalVisible = value;
    },
  },
});
