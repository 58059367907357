<template>
  <div
    class="d-flex align-items-baseline"
    style="padding: 1.25rem 1.5rem; clear: both; white-space: normal; border-bottom: 1px solid #e4dcdc; margin-top: 0"
    :style="baseLineStyle"
  >
    <i class="fas mr-2" :class="iconClass" aria-hidden="true"></i>
    <div class="d-flex flex-column align-items-baseline" style="width: 100%">
      <span v-if="propsVersionType" class="text-secondary">{{ $t(propsVersionType.text) }}</span>
      <span>
        <template v-if="item.properties">{{ $t(item.title, item.properties) }}</template>
        <template v-else>{{ $t(item.title) }}</template>
      </span>

      <span v-if="item.description" style="font-size: 13px; color: #8c8c8c">
        <template v-if="item.properties">{{ $t(item.description, item.properties) }}</template>
        <template v-else>{{ $t(item.description) }}</template>
      </span>

      <div
        v-if="item.ignorable || item.link || item.button"
        class="d-flex align-self-end justify-content-between"
        style="width: 100%; margin-top: 1rem"
      >
        <div class="d-flex flex-row align-items-center">
          <template v-if="item.link">
            <button
              v-if="item.link.isEvent"
              class="btn btn-primary"
              role="button"
              :data-event="item.link.eventName"
              @click="$emit('event', $event)"
            >
              {{ $t('notifBtn^'+item.link.title) }}
            </button>
            <a v-else :href="href" class="btn btn-primary" role="button">{{ $t('notifBtn^'+item.link.title) }}</a>
          </template>

          <button
            v-if="item.button && item.button.show"
            :id="'notification-' + item.type + '-btn'"
            type="button"
            class="btn btn-secondary"
            @click="onTypeButton(item.type, $event)"
          >
            {{ $t('notifBtn^'+item.button.text) }}
          </button>
        </div>

        <button
          v-if="item.ignorable"
          :data-ignoreItemList="[item.type, item.versionType || 'common', !isIgnored].join(',')"
          type="button"
          class="btn btn-secondary"
          @click="$emit('ignore', $event)"
        >
          {{ $t(isIgnored ? 'notifBtn^DoNotIgnore' : 'notifBtn^Ignore') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';

export default {
  name: 'notification-item',
  props: {
    item: {
      type: Object,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isIgnored: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useTpws),
    propsVersionType() {
      if (this.item.versionType === 'active') {
        return {
          iconHtml: '<i class="fas fa-play mr-2"></i>',
          text: 'Active version',
          linkAdd: '-live',
        };
      } else if (this.item.versionType === 'viewed') {
        return {
          iconHtml: '<i class="fas fa-eye mr-2"></i>',
          text: 'Previewed version',
          linkAdd: '',
        };
      }
    },
    iconClass() {
      return `fa-exclamation-triangle text-${this.item.severity}`;
    },
    baseLineStyle() {
      return !this.isLast ? 'margin-bottom: 8px;' : '';
    },
    href() {
      return this.item.link.forProject
        ? `/projects/${this.tpws.currentProject}${
            this.item.link.path == '/space?p=analysis' ? '/analysis' : this.item.link.path
          }${this.propsVersionType && this.propsVersionType.linkAdd ? this.propsVersionType.linkAdd : ''}`
        : this.item.link.path;
    },
  },
  methods: {
    onTypeButton(typ, ev) {
      if (typ == 'maintenance') {
        this.$emit('maintenance', ev);
      }
    },
  },
};
</script>

<style scoped></style>
