<template>
  <div id="diagnosis-page" style="margin-top: 15px">
    <Head v-if="displayName">
      <title>{{ $t('Tekvel Park') + ' — ' + $t('Project') + ' ' + displayName }}</title>
    </Head>
    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <analysis-table
        v-if="visibleTable"
        :atrs="atrs"
        :fields="fields"
        :url="url"
        :length-menu="lengthMenu"
        ref="eventsTable"
        @pageUpdate="pageChange"
      >
        <template slot="filters"> </template>
      </analysis-table>
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';
import AnalysisTable from '@/components/analysis/AnalysisTable.vue';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { RouterLink } from 'vue-router';

export default {
  name: 'analysis-page',
  components: { AnalysisTable },
  props: {
    page: { type: Number, default: 1 },
    eventsSearchStr: String,
    atrs: Object,
  },
  data: () => {
    return {
      quickSearch: '',
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      visibleTable: true,
    };
  },
  computed: {
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
    displayName() {
      return this.tpws.displayName;
    },

    url() {
      return `/projects/${this.currentProject}/data.json`;
    },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },

    fields() {
      return [
        {
          label: this.$t('Element'),
          name: 'elementDT',
          data: 'routeData',
          search: 'elementDTsearch',
          sortable: true,
          classes: ['ts-column'],
          style: { width: 35 + '%' },
          renderTemplate: true,
          print: (d, r) => {
            return r.elementDTsearch;
          },
          render: (d, r) => {
            // dev.log('d', d?.title, d, r);
            if (d?.query) {
              return this.$h(RouterLink, {
                class: 'btn-link',
                to: { name: d.name, query: d.query },
                title: d?.title || '',
                innerHTML: d?.title || '',
              });
            } else {
              return this.$h('span', { title: d?.title || '', innerHTML: d?.title || '' });
            }
          },
        },
        {
          label: this.$t('Element Type'),
          name: 'elementTypeDT',
          data: 'elementTypeDT',
          style: { width: 15 + '%' },
          sortable: true,
        },
        {
          label: this.$t('Warn level'),
          name: 'warnLevelDT',
          data: 'warnLevelDT',
          style: { width: 15 + '%' },
          print: (d, r) => {
            return r.warnLevelText;
          },

          sortable: true,
        },
        {
          label: this.$t('Description'),
          name: 'descriptionDT',
          data: 'descriptionDT',
          style: { width: 35 + '%' },
          sortable: true,
        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.eventsArchiveTS.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.eventsArchiveTS.tend).customFormat();
    },
  },

  mounted() {
  },
  methods: {
    onTouchStart() {
      document.querySelector('body').style.overflow = 'hidden';
    },
    onTouchEnd() {
      document.querySelector('body').style.overflow = 'auto';
    },
    pageChange() {
    },
  },
};
</script>

<style lang="scss">
#diagnosis-page {
  padding: 0 7%;
}
</style>
<style lang="scss"></style>
