<template>
  <div class="tags-component">
    <div class="tags-block__label m-b-2">
      <div class="label-block">
        <div class="label-block__title"><div>Теги</div></div>
        <div class="label-block__value"><div>6</div></div>
      </div>
    </div>
    <div class="tags-block__list">
      <div class="tags-list">
        <div class="tags-list__body">
          <div class="tag-element"><div>Tag 1</div></div>
          <div class="tag-element"><div>Tag 2</div></div>
          <div class="tag-element"><div>Tag 3</div></div>
          <div class="tag-element"><div>Tag 4</div></div>
          <div class="tag-element"><div>Tag 5</div></div>
          <div class="tag-element"><div>Tag 6</div></div>
        </div>
        <div class="tags-list__more">
          <div class="tag-element tag-element__btn"><div>...</div></div>
        </div>
        <div class="tags-list__add">
          <div class="tag-element tag-element__btn"><div>+</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tags',
};
</script>

<style scoped></style>
