import eventBus from '@/utils/eventBus';

export const responsePromiseCatch = (error) => {
  console.warn('promise error handler', error);
  return Promise.reject(error);
};
export const responseCatch = (error) => {
  console.warn('error handler', error);
  if (error?.response?.status === 555 || error.data.licenseStatus.status === 'ERROR') {
    eventBus.dispatch('license-problem', error.data);
  }
};
