import mem from 'mem';
import api from './api';
import TokenService from './tokenService';

const refreshTokenFn = async () => {
  // const session = JSON.parse(localStorage.getItem('session'));

  try {
    const response = await api.post(
      '/refreshToken',
      {
        // refreshToken: TokenService.getLocalRefreshToken(),
      },
      { withCredentials: true }
    );


    const { accessToken, refreshToken, status } = response.data;
    // console.log('refreshToken', accessToken, refreshToken, status);

    if (!accessToken) {
      TokenService.clearLocalAccessToken();
    } else {
      TokenService.updateLocalAccessToken(accessToken);
    }

    return { accessToken, refreshToken };
  } catch (error) {
    console.error('refresh error', error);
    TokenService.clearLocalAccessToken();
    // TokenService.clearLocalRefreshToken();
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
