<template>
  <slot name="reference"></slot>
  <div style="position: relative; width: 0; height: 0">
    <div v-if="visible" ref="vpTarget" class="my-popover__content" v-click-outside="hideDialog">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import visibleMixin from '@/mixins/visibleMixin';
import inViewport from '@/mixins/inViewport';
import { ClickOutside } from 'element-plus';
export default {
  name: 't-popover',
  mixins: [visibleMixin, inViewport],
  directives: {
    ClickOutside,
  },
  mounted() {
    dev.log('dialog mounted');
  },
  watch: {
    visible(v) {
      if (v) {
        this.viewportCheckerInit();
      } else {
        this.viewportCheckerDestroy();
      }
    },
  },
};
</script>

<style scoped>
.dialog {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
}

.my-popover__content {
  position: absolute;

  left: 5px;
  /* margin: auto; */
  margin-top: -85px;
  background: white;
  border-radius: 12px;
  min-height: 50px;
  min-width: 300px;
  padding: 0 20px;
  box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
