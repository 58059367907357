<template>
  <div
    class="main-container"
    v-resize:debounce="onResize"
    @resize="onResize"
    :class="{ 'main-container--full-height': visual.vertical }"
  >
    <nav-menu></nav-menu>
    <div class="main-section" :class="{ 'main-section--full-height': visual.vertical }">
      <top-nav></top-nav>

      <div
        class="content-view"
        v-resize:debounce="onContentViewResize"
        ref="contentView"
        :class="{ 'content-view--vertical': visual.vertical }"
      >
        <simplebar-vue
          data-simplebar
          class="content-view-height"
          :nsClass="['native-scroll--full-height', { 'native-scroll--full-height--vertical': visual.vertical }]"
          :class="{
            'content-view-height--vertical': visual.vertical,
            'content-view-height--vertical--no-menu': $route.name == 'projects',
          }"
        >
          <div
            tabindex="-1"
            role="dialog"
            aria-labelledby="recentEventsModalLabel"
            aria-hidden="true"
            class="modal fade"
            id="recentEventsModal"
          >
            <div role="document" class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="recentEventsModalLabel">
                    {{ $t('Recent events') }}
                    <span></span>
                  </h5>
                  <button type="button" data-dismiss="modal" aria-label="Close" class="close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div id="recentEventsVue"></div>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-link" id="recentEventsShowAll">
                    {{ $t('View all') }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <dev-status-modal></dev-status-modal>
          <slot />
        </simplebar-vue>
      </div>
    </div>

    <user-profile-modal></user-profile-modal>
    <user-settings-modal></user-settings-modal>
    <user-invite-modal></user-invite-modal>
    <about-company-modal></about-company-modal>
    <div id="modals-target"></div>
    <div style="display: none">
      <!--    <teleport v-if="hasEventsApp" to="#events-block">-->
      <!--      <events-app></events-app>-->
      <!--    </teleport>-->
      <!--    <teleport v-if="hasHistoryTimeline" to="#tpws_history_timeline-test">-->
      <!--      <tpws-history-timeline />-->
      <!--    </teleport>-->
      <!--    <teleport v-if="hasUsersControl" to="#control-users">-->
      <!--      <users-control :dataset="controlUsersDataset"></users-control>-->
      <!--    </teleport>-->
      <teleport v-if="hasMenuReportsApp" to="#reports-block">
        <menu-reports-app></menu-reports-app>
      </teleport>
      <teleport v-if="hasLastEventsBlock" to="#recentEventsVue">
        <last-events-block></last-events-block>
      </teleport>
    </div>
  </div>
</template>
<script>
import TopNav from '@/components/layout/TopNav.vue';
import NavMenu from '@/components/layout/NavMenu.vue';
import SimplebarVue from '@/components/common/SimplebarVue.vue';
import DevStatusModal from '@/components/modals/DevStatusModal.vue';
import UserProfileModal from '@/components/modals/UserProfileModal.vue';
import UserSettingsModal from '@/components/modals/UserSettingsModal.vue';
import UserInviteModal from '@/components/modals/UserInviteModal.vue';
import AboutCompanyModal from '@/components/modals/AboutCompanyModal.vue';
import MenuReportsApp from '@/components/layout/MenuReportsApp.vue';
import LastEventsBlock from '@/components/events/LastEventsBlock.vue';
import { isMobile } from 'detect-touch-device';
import { mapActions } from 'pinia';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';

export default {
  name: 'app-layout-default',
  components: {
    TopNav,
    NavMenu,

    SimplebarVue,
    DevStatusModal,
    UserProfileModal,
    UserSettingsModal,
    UserInviteModal,
    AboutCompanyModal,

    MenuReportsApp,
    LastEventsBlock,
  },
  inject: ['mq'],
  computed: {
    ...mapStores(useVisual),
    hasMenuReportsApp() {
      return !!document.querySelector('#reports-block');
    },
    hasLastEventsBlock() {
      return !!document.querySelector('#recentEventsVue');
    },

    noGlobalScroll() {
      return (
        this.$route.name == 'wheel-page' ||
        this.$route.name == 'wheel-live-page' ||
        this.$route.name == 'open-wheel-page'
      );
    },
  },
  mounted() {
    if (isMobile) {
      this.setTouch(true);
    } else {
      this.setTouch(false);
    }
  },
  methods: {
    ...mapActions(useVisual, ['setVertical', 'setTouch']),
    onResize() {
      setTimeout(() => {
        this.checkTouch();
      }, 50);
      this.$nextTick(() => {});
    },
    onContentViewResize(e) {
      const box = this.$refs.contentView && this.$refs.contentView.getBoundingClientRect();
      dev.log('onContentViewResize', e, box);
      if (box) {
        this.visual.contentLeft = box.left;
        this.visual.contentWidth = box.width;
      }
    },
    checkTouch() {
      if (navigator.maxTouchPoints) {
        this.setTouch(true);
      } else {
        this.setTouch(false);
      }
    },
  },
  watch: {
    'mq.vertical': {
      handler(vertical) {
        this.setVertical(vertical);
      },
      // force eager callback execution
      immediate: true,
      // deep: true,
    },
  },
};
</script>
<style lang="scss">
.content-view-height {
  height: calc(100vh - 56px);
  &--vertical {
    height: calc(100vh - 112px);
    &--no-menu {
      height: calc(100vh - 56px) !important;
    }
  }
  & .simplebar-content {
    height: 100%;
    flex: 1;
    overflow-y: visible;
    &--disabled {
      //display: flex;
    }
  }
}

//.wrrr .simplebar-scrollbar::before {
//  background-color: rgba(0, 0, 0, 0.33);
//}
.sidebar-left:not(.sidebar-left--collapsed) {
  & .wrrr .simplebar-content-wrapper,
  & .wrrr .simplebar-content {
    display: flex;
    min-width: 310px;
  }
  .menu-wrapper {
    display: flex;
    min-width: 310px;
    height: 100%;
    /*flex: 1;*/
    overflow-y: visible;
  }
}
</style>
<style>
#root-app {
  min-height: 100vh;
}
</style>
<style lang="scss" scoped>
.main-container {
  &--full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.main-section {
  &--full-height {
    flex: auto;
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.content-view {
  overflow: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  &--full-height {
    flex: auto;
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
    //justify-content: flex-start;
  }
}
.root-section {
  flex: 1 1 auto;
}
</style>
