<template>
  <signals-page></signals-page>
</template>

<script>
import SignalsPage from '@/components/signals/SignalsPage.vue';

import { mapStores } from 'pinia';
import { useConfig } from '@/stores/config';

export default {
  name: 'signals-view',
  components: { SignalsPage },
  props: {},
  data: () => {
    return {};
  },
  computed: {
    ...mapStores(useConfig),
    isSignalsProfile() {
      return false;
    },
    isSignalsProfileOld() {
      return false;
    },
    isSignalsPageLegacy() {
      return this.$route.name === 'signals-profile-legacy';
    },
    isSignalsPage() {
      return this.$route.name === 'signals-profile';
    },
  },

  mounted() {},
  methods: {},
  watch: {},
};
</script>
