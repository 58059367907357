<template>
  <div class="container-fluid p-0">
    <!--    <scriptx src="/js/timeline.js"></scriptx>-->
    <div class="accordion mt-2" id="history-accordion">
      <div v-loading="isLoading" class="card-project">
        <div id="history-header">
          <h5 class="project-page__card-title">
            <div v-if="false" class="page-actions__search">
              <div class="ui-search" style="margin: 10px 20px">
                <div class="ui-search__pre-icon">
                  <img src="/images/search.svg" loading="lazy" alt="" />
                </div>
                <div class="ui-search__placeholder">
                  {{ $t('Search') }}
                </div>
              </div>
            </div>
            <div class="spacer-grow"></div>
            <div class="d-flex align-items-center">
              <scd-upload-page @versionUploaded="versionUploaded"></scd-upload-page>
              <template v-if="permission?.indexOf('scdmanagement') > -1">
                <a
                  @click="showUpload"
                  role="button"
                  :class="{ disabled: inProcessing }"
                  class="ui-button ui-button_blue pointer-cursor"
                >
                  <span class="ui-button__icon">
                    <v-svg src="add" fill="white"></v-svg>
                  </span>
                  <span class="ui-button__lable">
                    {{ $t('Upload new version') }}
                  </span>
                </a>
              </template>
              <template v-if="false && permission?.indexOf('scdmanagement') > -1">
                <button
                  id="project-props-modal-btn"
                  class="btn btn-link fal fa-pen project-page__controls"
                  href="#"
                  type="button"
                  :title="$t('Change project settings')"
                  @click="visual.editProjectModalVisible = true"
                ></button>
              </template>
            </div>
          </h5>
        </div>
        <div
          class="show collapse"
          id="history-collapsed"
          aria-labelledby="#history-header"
          data-parent="#history-accordion"
        >
          <div class="card-body" id="project_timeline_card">
            <div id="tpws_history_timeline"></div>
            <div v-if="tlVisible" class="slides-content">
              <template v-for="el in timelineVersionsList">
                <Teleport :to="'#slide-place-' + el._id" :disabled="false">
                  <div
                    v-if="permission && permission?.indexOf('scdmanagement') !== -1"
                    id="wrapper-button"
                    class="d-flex mb-4"
                  >
                    <a
                      class="btn btn-primary btn-sm mr-2 pl-3 pr-3 text-white"
                      @click="downloadSCD('/projects/' + tpws.currentProject + '/getscd?v=' + el.version, el.origin)"
                    >
                      <i class="fa fa-download" style="font-style: normal" aria-hidden="true"></i>
                      {{ $t('Download SCD') }}
                    </a>
                    <!-- Clone button has been removed from the interface as it is no more needed.
                    This code here has been left just in case for further retuning the clonde button back -->
                    <!-- <button
                      v-if="!el.isLast"
                      @click="onCloneVersionModal(el.version, el.filename)"
                      type="button"
                      class="btn btn-sm btn-primary mr-2 pl-3 pr-3"
                      :disabled="el.inProcessing"
                    >
                      <i
                        :id="'clone-btn-icon-' + el.version"
                        class="fa fa-clone"
                        style="font-style: normal"
                        aria-hidden="true"
                      ></i>
                      {{ $t('Clone') }}
                    </button> -->
                    <button
                      v-if="
                        isLive &&
                        el.isEllySuccess &&
                        !el.isActive &&
                        history?.length &&
                        permission?.indexOf('changeActiveVersion') !== -1
                      "
                      :id="'active-btn-' + el._id"
                      @click="setActiveVersion(el._id, activeVersionId)"
                      type="button"
                      class="btn btn-sm btn-outline-success mr-2 pl-3 pr-3"
                      :disabled="inProcessing || el.isActive"
                    >
                      <i
                        :id="'active-btn-icon-' + el._id"
                        class="fa fa-play"
                        style="font-style: normal"
                        aria-hidden="true"
                      ></i>
                      {{ $t('Set LIVE') }}
                    </button>
                    <button
                      v-if="!el.isViewedVersion"
                      :id="'viewed-btn-' + el._id"
                      @click="setViewedVersion(el._id)"
                      type="button"
                      class="btn btn-sm btn-outline-primary pl-3 pr-3"
                      :disabled="inProcessing"
                    >
                      <i
                        :id="'viewed-btn-icon-' + el._id"
                        class="fas fa-eye"
                        style="font-style: normal"
                        aria-hidden="true"
                      ></i>
                      {{ $t('View this versions') }}
                    </button>
                  </div>
                  <div v-html="el.description"></div>
                </Teleport>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container text-center">
    <div id="tpws_history_hint" class="tpws-history-hint"></div>
  </div>
</template>

<script>
import ScdUploadPage from '@/components/timeline/ScdUploadPage.vue';

import api from '@/services/api';
import FileSaver from 'file-saver';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { useTpui } from '@/stores/tpui';
import { usePage } from '@/stores/page';
// import { useRoot } from '@/stores/root';
import { usePrefs } from '@/stores/prefs';
import { useVisual } from '@/stores/visual';
import { mapActions } from 'pinia';
import { Timeline } from '@/utils/TimelineJS3/js';
import eventBus from '@/utils/eventBus';
import { debounce, throttle } from '@/plugins/debounce';

// import '../../../public/css/timeline.css';
export default {
  name: 'versions-timeline',
  components: {
    ScdUploadPage,
    // 'scriptx': ScriptX
  },
  data: () => {
    return {
      visibleScdUpload: false,
      containerId: 'tpws_history_timeline',
      PREFIX_ID_FOR_TL_SLIDE: 'version',
      timelineVersionsList: [],
      tlVisible: false,
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useTpui),
    ...mapStores(usePrefs),
    ...mapStores(usePage),
    // ...mapStores(useRoot),
    ...mapStores(useVisual),
    currentProject() {
      return this.tpws.currentProject;
    },
    language() {
      return this.prefs.language;
    },
    inProcessing() {
      return this.tpws?.inProcessing;
    },
    isLoading() {
      return this.tpws?.projectStatusLoading;
    },
    name() {
      return this.page.pageData['project-timeline']?.name;
    },
    history() {
      return this.tpws.historyList || [];
    },
    projectData() {
      return {
        activeVersionId: this.activeVersionId,
        viewedVersionId: this.viewedVersionId,
      };
    },
    activeVersionId() {
      return this.tpws.activeVersionId;
    },
    viewedVersionId() {
      return this.tpws.viewedVersionId;
    },
    permission() {
      return this.tpws.permission || [];
    },
    isLive() {
      return this.page.pageData['project-timeline']?.isLive;
    },
  },
  mounted() {
    // this.$loadScript('/js/timeline.js');
    // if (this.history && this.projectData) {
    //   this.buildTimeline();
    // }
    this.$router.isReady().then((e, r, t) => {
      //console.log('ready', this.$route, this.projectData);
      if (this.projectData?.activeVersionId || this.projectData?.viewedVersionId) {
        this.buildTimeline();
      }
    });
  },
  unmounted() {
    // this.$unloadScript('/js/timeline.js');
  },
  methods: {
    ...mapActions(useTpws, ['setCurrentVersionIndex']),

    async downloadSCD(url, filename) {
      const { data: cont } = await api.get(url);
      var file = new File([cont], filename, { type: 'application/xml' });
      FileSaver.saveAs(file);
    },
    setDescriptionOutSide: debounce(function (element) {
      dev.log('debounce setDescriptionOutSide')
      this.debSetDescriptionOutSide(element);
    }, 150),

    debSetDescriptionOutSide(element) {
      // console.log('setDescriptionOutSide', JSON.stringify(element));
      let descriptionOutSide = '';

      if (element?.additional) {
        descriptionOutSide += `
          <div class="d-flex flex-row flex-wrap justify-content-between">
            <div class="scl-item">
              <h6 class="scl-item__header">${this.$t('IEDs')}</h6>
              <div class="scl-item__text">
                ${element.additional.summary.ieds}
              </div>
            </div>
            <div class="scl-item">
              <h6 class="scl-item__header">${this.$t('GOOSE messages')}</h6>
              <div class="scl-item__text">
                ${element.additional.summary.gooses}
              </div>
            </div>
            <div class="scl-item">
              <h6 class="scl-item__header">${this.$t('SV streams')}</h6>
              <div class="scl-item__text">
                ${element.additional.summary.svs}
              </div>
            </div>
          </div>
      `;
      }

      if (element?.changes != null) {
        descriptionOutSide += `
        <h5 class="project-page__card-sub-title card-project__btn-link" style="margin: 0; padding-top: 35px; padding-bottom: 8px;">
          ${this.$t('Compared to previous version')}
        </h5>
        <div class="d-flex flex-column flex-wrap justify-content-between">
          <div class="scl-item">
            <h6 class="scl-item__header">${this.$t('Added IEDs')}</h6>
            <div class="scl-item__text">
              ${element.changes.ied.added != '' ? element.changes.ied.added.join(', ') : this.$t('none')}
            </div>
          </div>
          <div class="scl-item">
            <h6 class="scl-item__header">${this.$t('Changed IEDs')}</h6>
            <div class="scl-item__text">
              ${element.changes.ied.changed != '' ? element.changes.ied.changed.join(', ') : this.$t('none')}
            </div>
          </div>
          <div class="scl-item">
            <h6 class="scl-item__header">${this.$t('Removed IEDs')}</h6>
            <div class="scl-item__text">
              ${element.changes.ied.removed != '' ? element.changes.ied.removed.join(', ') : this.$t('none')}
            </div>
          </div>
        </div>
       `;
      }

      if (descriptionOutSide.replace(/\s/g, '').length) {
        $('#description-container').fadeOut('fast');
        // $('#description-wrapper').hide().html(descriptionOutSide).show('slow');
        $('#description-wrapper').html(descriptionOutSide);
        $('#description-container').fadeIn('fast');
      } else {
        $('#description-container').fadeOut('fast');
      }
    },
    spinnerHandler(type, id, iconClass) {
      const runSpinner = () => $(`#${type}-btn-icon-${id}`).removeClass(iconClass).addClass('fal fa-pulse fa-spinner');
      const stopSpinner = () => $(`#${type}-btn-icon-${id}`).removeClass('fal fa-pulse fa-spinner').addClass(iconClass);
      return { runSpinner, stopSpinner };
    },
    switchDisableBtn(status, type = '', id = '') {
      $('#wrapper-button .btn').prop('disabled', status);
      if (type && id && !status) {
        $(`#${type}-btn-${id}`).prop('disabled', true);
      }
    },
    cloneVersionsRequest() {
      // FIXME: This part of code shall be removed if Clone button has been proven to be not needed.
      const modalElem = $('.clone-scl-processors-modal');

      const version = modalElem.find('[name="version"]').val();
      const filename = modalElem.find('[name="filename"]').val();

      const submitBtn = modalElem.find('[type="submit"]');
      const submitText = submitBtn.text();
      const spinnerIcon = '<i class="fal fa-pulse fa-spinner" aria-hidden=true></i>';
      submitBtn.html(`${spinnerIcon} ${submitText}`);

      const sclCheckboxList = modalElem.find('input[type="checkbox"]');

      sclCheckboxList.attr('disabled', true);
      submitBtn.prop('disabled', true);

      const sclProcessorList = [...sclCheckboxList]
        .filter((scl) => $(scl).prop('checked'))
        .map((scl) => ({
          processorId: $(scl).attr('name'),
          label: $(scl).attr('data-label'),
        }));

      const self = this;
      const { runSpinner, stopSpinner } = this.spinnerHandler('clone', version, 'fa fa-clone');

      this.switchDisableBtn(true);
      runSpinner();

      api
        .post(`/projects/${this.currentProject}/scd/new`, {
          version,
          filename,
          sclProcessorList,
        })
        .then((response) => {
          if (response.status) {
            location.reload();
          }
        })
        .catch((err) => {
          console.error('Error: ', err.statusText);
          self.switchDisableBtn(false);
          stopSpinner();
        });
    },
    buildTimeline() {
      this.tlVisible = false;
      //console.log('buildTimeline');

      if (!this.history || !this.projectData) return;
      const indexActiveVersion = this.history.findIndex((i) => i._id === this.activeVersionId);

      const foundViewedVersionIndex = this.history.findIndex((i) => i._id === this.viewedVersionId)
      const indexViewedVersion = this.viewedVersionId
        ? foundViewedVersionIndex
        : this.history.length - 1;

      this.timelineVersionsList = [];
      const eventTimelineList = this.history.map((element, idx) => {
        const el = { ...element };

        const sclElly = element.sclProcessors ? element.sclProcessors.find((scl) => scl.processor_id === 'ELLY') : null;
        const isEllySuccess =
          sclElly && sclElly.processing_result ? sclElly.processing_result.toLowerCase() === 'success' : false;

        const isViewedVersion = idx === indexViewedVersion;
        const isLast = idx === this.history.length - 1;
        const isActive = element._id === this.activeVersionId;

        el['isEllySuccess'] = isEllySuccess;
        el['isViewedVersion'] = isViewedVersion;
        el['isLast'] = isLast;
        el['isActive'] = isActive;

        // debugger
        let description = `<div id='slide-place-${element._id}'></div>`;

        const { errorsCount } = element;
        const errorsHtml = errorsCount
          ? `${this.$t('Critical errors')}: <b>${errorsCount.critical}</b>. ${this.$t('Minors')}: <b>${
              errorsCount.minor
            }</b>.`
          : `${this.$t('Validation errors')}: <b>0</b>.`;

        let syntaxIsValidHtml = '';

        if (element.status == 'ok') {
          syntaxIsValidHtml = this.$t('valid');
        } else {
          syntaxIsValidHtml = `<span class="badge badge-danger">${this.$t('invalid')}</span>`;
        }

        const sclStandartEdition =
          element.edition && element.edition.label
            ? `${this.$t('SCL Syntax Version')}: <b>${element.edition.label}</b><br/>`
            : '';

        const versionCommentText = element.comment
          ? `${this.$t('Comment')}: <b>${element.comment}</b><br/>`
          : '';

        el['description'] = `${this.$t('This configuration file has')} ${syntaxIsValidHtml} ${this.$t(
          'SCL syntax'
        )}.<br/>
                ${errorsHtml}<br/>
                ${this.$t('Uploaded by')} <b>${element.user}</b><br/>
                ${versionCommentText}
                ${this.$t('Original filename')}: <b>${element.origin}</b><br/>
                ${sclStandartEdition}`;

        const eventDate = new Date(element.date);
        const yearStr = eventDate.getFullYear();
        const monthArr = [
          this.$t('January'),
          this.$t('February'),
          this.$t('March'),
          this.$t('April'),
          this.$t('May'),
          this.$t('June'),
          this.$t('July'),
          this.$t('August'),
          this.$t('September'),
          this.$t('October'),
          this.$t('November'),
          this.$t('December'),
        ];
        const monthStr = monthArr[eventDate.getMonth()];
        const dateStr = eventDate.getDate();
        const hourStr = eventDate.getHours() < 10 ? '0' + eventDate.getHours() : eventDate.getHours();
        const minuteStr = eventDate.getMinutes() < 10 ? '0' + eventDate.getMinutes() : eventDate.getMinutes();
        const secondStr = eventDate.getSeconds() < 10 ? '0' + eventDate.getSeconds() : eventDate.getSeconds();
        const eventDateString =
          monthStr + ' ' + dateStr + ', ' + yearStr + ' ' + hourStr + ':' + minuteStr + ':' + secondStr;

        const headlineText = `
          <div class="d-flex flex-row align-items-center mr-1">
            <span class="mr-1">${this.$t('Version')} ${element.version} </span>
            ${isViewedVersion ? this.badgeHtml('Previewed', 'primary') : ''}
            ${this.isLive && isActive ? this.badgeHtml('Active', 'success') : ''}
          </div>
        `;
        const headtimenavText = `
        <div class="d-flex flex-row align-items-baseline mr-1" style="width: 100%;">
          ${isViewedVersion ? '<i class="fas fa-eye mr-1" style="font-style: normal;"></i>' : ''}
          ${this.isLive && isActive ? '<i class="fa fa-play mr-1" style="font-style: normal;"></i>' : ''}
          <div>
            <span class="mr-1">${this.$t('Version')} ${element.version}</span>
          </div>
        </div>
      `;

        const event = {
          start_date: eventDate,
          text: {
            headline: headlineText,
            text: description,
            headtimenav: headtimenavText,
            slideBtnText: headtimenavText,
          },
          unique_id: this.PREFIX_ID_FOR_TL_SLIDE,
          display_date: eventDateString,
        };

        this.timelineVersionsList.push(el);

        return event;
      });

      const timeline_data = {
        events: eventTimelineList,
      };

      const additionalOptions = {
        timenav_height: 20,
        duration: 500,
        scale_factor: 0.5,
        lang: this.language || 'en',
      };
      //console.log('TL init', additionalOptions);
      const elm = document.getElementById(this.containerId);
      const timeline = new Timeline(elm, timeline_data, additionalOptions);
      $('.tl-knightlab-logo').closest('a').css('display', 'none');

      this.setDescriptionOutSide(this.history[this.history.length - 1]);

      const changeHandler = (index) => {
        // console.log('changeHandler', index, JSON.stringify(this.history[index]));
        this.setDescriptionOutSide(this.history[index]);
        this.setCurrentVersionIndex(index);
      };
      const initIndex = this.inProcessing ? this.history.length - 1 : indexViewedVersion;

      timeline.goTo(initIndex);
      // timeline.setFocus(initIndex);
      changeHandler(initIndex);

      timeline.on('change', (event) => {
        const [, index] = event.unique_id.split('-');
        const versionIndex = Number(index || 1) - 1;
        // console.log('TL change', event, event.unique_id, index, versionIndex);

        changeHandler(versionIndex);
      });
      setTimeout(() => {
        this.tlVisible = true;
      }, 300);
    },
    setActiveVersion(versionId, activeVersionId, force = false) {
      const self = this;
      const { runSpinner, stopSpinner } = this.spinnerHandler('active', versionId, 'fa fa-play');

      this.switchDisableBtn(true, 'active', activeVersionId);
      runSpinner();
      api
        .post(`/projects/${this.currentProject}/set-active-version`, {
          versionId,
          force,
        })
        .then(({ status, err }) => {
          stopSpinner();
          if (!status) {
            dev.log('Error: ', err);
            if (this.tpws.permissionList.indexOf('changeActiveVersion') === -1) {
              // notifyKas.show({ text: this.$t('Permission denied'), close: true, type: 'danger' });
              eventBus.dispatch('message', {
                message: this.$t('Permission denied'),
                type: 'error',
              });
            } else if (err == 'Error: Version has critical errors') {
              const modalElem = $('.confirm-set-active-modal');

              modalElem.find('[name="versionId"]').val(versionId);
              modalElem.find('[name="activeVersionId"]').val(activeVersionId);

              modalElem.modal();
            } else {
              // notifyKas.show({ text: this.$t('Unexpected error'), close: true, type: 'danger' });
              eventBus.dispatch('message', {
                message: this.$t('Unexpected error'),
                type: 'error',
              });
            }
            self.switchDisableBtn(false, 'active', activeVersionId);
            return;
          }

          // notifyKas.show({
          //   text: this.$t('Active version has been updated successfully'),
          //   close: true,
          //   type: 'success',
          // });
          eventBus.dispatch('message', {
            message: this.$t('Active version has been updated successfully'),
            type: 'success',
          });

          setTimeout(function () {
            location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.error('Error: ', err.statusText);
          stopSpinner();
          self.switchDisableBtn(false, 'active', activeVersionId);
        });
    },
    confirmedSetActiveVersion() {
      const modalElem = $('.confirm-set-active-modal');

      const versionId = modalElem.find('[name="versionId"]').val();
      const activeVersionId = modalElem.find('[name="activeVersionId"]').val();
      // console.log('versionId, activeVersionId', versionId, activeVersionId);
      modalElem.modal('hide');
      this.setActiveVersion(versionId, activeVersionId, true);
    },
    setViewedVersion(versionId) {
      this.switchDisableBtn(true, 'viewed', versionId);

      api
        .post(`/projects/${this.currentProject}/change-viewed-version`, {
          versionId,
        })
        .then(({ status, err }) => {
          if (!status) {
            //console.log('Error: ', err);
            this.switchDisableBtn(false, 'viewed', versionId);
            return;
          }

          location.reload();
        })
        .catch((err) => {
          console.error('Error: ', err.statusText);
          this.switchDisableBtn(false, 'viewed', versionId);
        });
    },
    onCloneVersionModal(version, filename) {
      const modalElem = $('.clone-scl-processors-modal');

      modalElem.find('[name="version"]').val(version);
      modalElem.find('[name="filename"]').val(filename);

      modalElem.modal();
    },
    async retreiveProjectHistory(projectId) {
      this.tpws.reloadProjectStatus();
      // console.log('retreiveProjectHistory', projectId);
      //
      // const { data: projectData } = await api.post('/projects/' + this.currentProject + '/data.json');
      // const { data: history } = await api.get('/projects/' + this.currentProject + '/history');
      // console.log('projectData', history, projectData);
      //
      // this.buildTimeline(history, projectData);
    },
    badgeHtml(text, type = 'secondary') {
      return `<span style="font-size: .875rem;" class="badge badge-${type} mr-1 text-capitalize">${this.$t(
        text
      )}</span>`;
    },
    showUpload() {
      if (!this.inProcessing) {
        this.visual.showSclUploadModal(true);
      }
    },
    versionUploaded() {
      this.page.getPageData({
        name: 'current-project',
        path: '/projects/' + this.currentProject,
      });

      // window.dispatchEvent(new Event('project.ready'));
    },
  },
  watch: {
    'prefs.language': {
      handler(val, old) {
        if (val !== old) {
          this.tpws.reloadProjectStatus();
        }
      },
    },
    'history.length': {
      handler(val) {
        //console.log('watch projectData', val);
        if (val > 0) {
          this.buildTimeline();
        }
      },
      deep: false,
    },
    viewedVersionId: {
      handler(val) {
        //console.log('watch projectData', val);
        if (val) {
          this.buildTimeline();
        }
      },
    },
    projectData: {
      handler(val) {
        //console.log('watch projectData', val);
        if (val?.activeVersionId || val?.viewedVersionId) {
          this.buildTimeline();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.slides-content {
  display: none;
}
.tl-timemarker-content-small .tl-headline {
  font-size: 12px !important;
}
.tl-headline {
  font-family: unset !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  text-transform: unset !important;
}
//@import '../../../public/css/timeline.css';
</style>
