<template>
  <div id="events-table" class="container-fluid manage-user__container mt-4">
    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <user-manage-table
        v-if="visibleTable"
        :atrs="atrs"
        :url="url"
        :length-menu="lengthMenu"
        ref="userManageTable"
        @pageUpdate="pageChange"
      >
        <template slot="filters"> </template>
      </user-manage-table>
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';
import UserManageTable from '@/components/users/UserManageTable.vue';

export default {
  name: 'users-management',
  components: { UserManageTable },
  props: {
    page: { type: Number, default: 1 },
    eventsSearchStr: String,
    atrs: Object,
  },
  data: () => {
    return {
      quickSearch: '',
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      visibleTable: true,
    };
  },
  computed: {
    url() {
      return '/users/manage-data';
    },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },

    nanoTstart() {
      return new DateNano(this.eventsArchiveTS.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.eventsArchiveTS.tend).customFormat();
    },
  },

  mounted() {},
  methods: {
    onTouchStart() {
      document.querySelector('body').style.overflow = 'hidden';
    },
    onTouchEnd() {
      document.querySelector('body').style.overflow = 'auto';
    },
    pageChange() {
      let page = this.$refs.userManageTable.currentPage,
        order = this.$refs.userManageTable.order,
        search = this.$refs.userManageTable.search.value;
      // history.pushState(
      //   {
      //     page: page,
      //     order: order,
      //     search: this.$qs.stringify(search),
      //   },
      //   `page ${page}`,
      //   `?p=${this.eventsArchive ? 'archive' : 'events'}&page=${page}&search=${search}&column=${order['column']}&dir=${
      //     order['dir']
      //   }`
      // );
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
