<template>
  <div class="sidebar" v-sticky="{ zIndex: 10, stickyTop: 0 }">
    <div class="sidebar__header">
      <div class="sidebar__header-icon">
        <div class="tk-icon">
          <img src="/images/filter.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="sidebar__header-title">{{ $t('Filter') }}</div>
      <div class="sidebar__header-action">
        <div class="tk-icon tk-icon--big" @click="$emit('update:filterVisible')">
          <img src="/images/crossline.svg" loading="lazy" alt="" />
        </div>
      </div>
    </div>
    <div class="sidebar__body">
      <simplebar-vue class="wrr">
        <slot name="text-search"></slot>
        <filter-group v-if="filters.filters?.clusterSizeBounds" :name="$t('Cluster size')">
          <!--          <el-slider :value="v" range :min="10" :max="20"> </el-slider>-->
          <range-filter
            v-if="clusterSize"
            :range="range"
            :value="clusterSize"
            @change="clusterSizeChange"
          ></range-filter>
        </filter-group>
        <filter-group
          v-if="filters.filters.clusterDateBounds['min'] && filters.filters.clusterDateBounds['max']"
          :name="$t('Period')"
        >
          <period-picker
            v-if="clusterDate"
            :bounds="filters.filters.clusterDateBounds"
            :value.sync="clusterDate"
            @change="changeDateBounds"
          ></period-picker>
        </filter-group>
        <filter-group v-if="filters.filters.protocols" :name="$t('Protocols')">
          <filter-list
            :items="filters.filters.protocols"
            :active-items="filters.filterValues.protocols"
            @checked="chEl('protocols', $event)"
          ></filter-list>
        </filter-group>
        <filter-group v-if="filters.filters.types" :name="$t('Types')">
          <filter-list
            :items="filters.filters.types"
            :active-items="filters.filterValues.types"
            @checked="chEl('types', $event)"
          ></filter-list>
        </filter-group>
        <filter-group v-if="filters.filters.devices" :name="$t('Devices')">
          <filter-list
            :searchable="true"
            :items="filters.filters.devices"
            :active-items="filters.filterValues['devices']"
            @checked="chEl('devices', $event)"
          ></filter-list>
        </filter-group>
      </simplebar-vue>
    </div>
  </div>
</template>

<script>
import SimplebarVue from '@/components/common/SimplebarVue.vue';
import FilterGroup from '@/components/clusters/FilterGroup.vue';
import FilterList from '@/components/clusters/FilterList.vue';
import RangeFilter from '@/components/clusters/RangeFilter.vue';
import PeriodPicker from '@/components/clusters/PeriodPicker.vue';
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useFilters } from '@/stores/filters';

export default {
  name: 'clusters-filter',
  components: {
    FilterGroup,
    FilterList,
    RangeFilter,
    PeriodPicker,
    SimplebarVue,
  },
  props: {
    filterVisible: {
      type: Boolean,
    },
    activeFilters: {
      type: Object,
    },
  },
  data: () => {
    return {
      range: true,
    };
  },
  computed: {
    ...mapStores(useFilters),
    clusterSize: {
      get() {
        if (this.filters.filterValues) {
          return this.filters.filterValues.clusterSize;
        } else {
          return { min: 0, max: 0 };
        }
      },
      set(e) {
        dev.log('clusterSize set', e);
        this.setClusterSize(e);
      },
    },
    clusterDate: {
      get() {
        if (this.filters.filterValues) {
          return this.filters.filterValues.clusterDate;
        } else {
          return { min: 0, max: 0 };
        }
      },
      set(e) {
        dev.log('clusterDate set', e);
        this.setClusterDate(e);
      },
    },
    clusterSizeBounds() {
      return this.filters.clusterSizeBounds;
    },
    clusterDateBounds() {
      return this.filters.clusterDateBounds;
    },
  },
  mounted() {},
  methods: {
    ...mapActions(useFilters, ['pushItemValue', 'shiftItemValue', 'setClusterDate', 'setClusterSize']),
    changeDateBounds(v) {
      dev.log('changeDateBounds', v);
      dev.log(
        'changeDateBounds',
        v.min,
        v.max,
        this.filters.filters.clusterDateBounds.min,
        v.min !== this.filters.filters.clusterDateBounds.min,
        v.max !== this.filters.filters.clusterDateBounds.max
      );
      if (!!v.min&& !!v.max && v.min > v.max) {
        const l = v.max;
        v.max = v.min;
        v.min = l;
      }
      this.clusterDate = v;
      if (
        v.min !== this.filters.filters.clusterDateBounds.min ||
        v.max !== this.filters.filters.clusterDateBounds.max
      ) {
        this['activeFilters']['clusterDates'] = [v.min, v.max];
      } else {
        this['activeFilters']['clusterDates'] = [
          this.filters.filters.clusterDateBounds.min,
          this.filters.filters.clusterDateBounds.max,
        ];
      }
      this.$nextTick(() => {
        this.$emit('filter-changed', this.activeFilters);
      });
    },
    clusterSizeChange(e) {
      this.clusterSize = e;
      this['activeFilters']['clusterSize'] = e;
      this.$nextTick(() => {
        this.$emit('filter-changed', this.activeFilters);
      });
    },
    chEl(type, e) {
      const action = e['action'];
      const value = e['value'];
      if (action === 'add') {
        this.pushItemValue({ type, value });
      } else {
        this.shiftItemValue({ type, value });
      }
      this.$emit('filter-changed', this.activeFilters);
    },
  },
};
</script>

<style scoped></style>
