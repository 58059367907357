<template>
  <div class="t-drawer-divider" :class="{ 't-drawer-divider__vertical': visual.vertical }" style="display: flex">
    <div>
      <div class="divider-btn" @click="$emit('divider-click')">
        <icon size="24">
          <template v-if="visual.vertical">
            <template v-if="direction">
              <caret-down :color="color" />
            </template>
            <template v-else>
              <caret-up :color="color" />
            </template>
          </template>
          <template v-else>
            <template v-if="direction"><caret-right :color="color" /></template>
            <template v-else><caret-left :color="color" /></template>
          </template>
        </icon>
      </div>
    </div>
  </div>
</template>

<script>
// import { AngleDown, AngleLeft, AngleRight, AngleUp } from '@vicons/fa';
import CaretUp from '@/components/icons/CaretUp.vue';
import CaretDown from '@/components/icons/CaretDown.vue';
import CaretLeft from '@/components/icons/CaretLeft.vue';
import CaretRight from '@/components/icons/CaretRight.vue';
import { Icon } from '@vicons/utils';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';
export default {
  name: 't-drawer-divider',
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CaretUp,
    CaretDown,
    CaretLeft,
    CaretRight,
    // AngleDown,
    // AngleLeft,
    // AngleRight,
    // AngleUp,
    Icon,
  },
  data: () => {
    return {
      color: '#1757BD',
    };
  },
  computed: {
    ...mapStores(useVisual),
    direction() {
      return this.visual.wheelCollapsed ^ this.reverse;
    },
  },
};
</script>

<style lang="scss" scoped>
.divider-btn {
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgb(232 238 248 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgb(232 238 248 / 100%);
  }
}
.t-drawer-divider {
  position: relative;
  height: unset;
  width: 3px;
  flex-grow: 0;
  background-color: #e8eef8;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  z-index: 99;
  &__vertical {
    height: 3px;
    width: 100%;
  }
}
</style>
