<template>
  <div class="native-scroll" :class="nsClass">
    <portal :disabled="disabled" :to="'simplebar-on-' + uid">
      <slot></slot>
    </portal>
  </div>

  <n-scrollbar :style="{ height: height + 'px' }">
    <portal-target :name="'simplebar-on-' + uid" />
  </n-scrollbar>
</template>

<script>
export default {
  name: 'simplebar-vue-naive',
  props: {
    height: {
      type: Number,
      default: 0,
    },
    nsClass: {
      // type: Array,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uid = Vue.getCurrentInstance().uid;
    return {
      uid,
    };
  },
  data: () => {
    return {
      // simpleBar: undefined,
    };
  },
  mounted() {
    // if (!this.disabled) {
    //   const options = SimpleBar.getOptions(this.$refs.element.attributes);
    //   this.simpleBar = new SimpleBar(this.$refs.element, options);
    // }
    // if (!this.disabled) {
    //   this.$nextTick(() => {
    //     this.on();
    //   });
    // }
  },
  computed: {
    scrollElement() {
      return this.$refs.scrollElement;
    },
    contentElement() {
      return this.$refs.contentElement;
    },
  },
  methods: {
    // recalculate(){
    //   this.simpleBar.recalculate();
    // },
    // on() {
    //   console.log('simplebar-on');
    //   const options = SimpleBar.getOptions(this.$refs.element.attributes);
    //   this.simpleBar = new SimpleBar(this.$refs.element, options);
    // },
    // off() {
    //   console.log('simplebar-off');
    //   if (this.simpleBar) {
    //     this.simpleBar.unMount();
    //   }
    // },
  },
  watch: {
    // disabled: {
    //   handler(val) {
    //     if (val) {
    //       this.off();
    //     } else {
    //       this.on();
    //     }
    //   },
    //   // immediate: true,
    // },
  },
};
</script>
<style>
.native-scroll {
  /*overflow: auto;*/
}
</style>
