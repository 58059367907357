<template>
  <app-layout>
    <router-view :key="$route.fullPath"></router-view>
  </app-layout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout.vue';

import api from '@/services/api';
import { loadModule } from 'vue3-sfc-loader/dist/vue3-sfc-loader.esm';
import router from '../../router';
import { defineCustomElement } from '../../utils/defineCustomElementWithStyles';
import shadowStyleInjector from '@/utils/shadowStyleInjector';
import rootApp from '../../root.app';
import VueDOMInjector from 'vue-dom-injector';
import loadingDirectivePlugin from '../../utils/loadingDirectivePlugin';

import VueApexCharts from 'vue3-apexcharts';

import { createPinia, mapStores, mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useConfig } from '@/stores/config';
import { useSclProcessors } from '@/stores/sclProcessors';
import { markRaw, shallowRef, ref } from 'vue/dist/vue.esm-bundler';
import main from '../../root.app';
import eventBus from '@/utils/eventBus';

import { useNotification, NNotificationProvider } from 'naive-ui';
import en from 'element-plus/lib/locale/lang/en';

export default {
  name: 'root-block',
  components: {
    AppLayout,
  },
  props: {},
  setup() {
    const notification = useNotification();
    return {
      notification,
    };
  },

  data: () => ({}),
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useConfig),
    topNavDataset() {
      return this.$root.$parseDataset(this.$root.topNavDataset);
    },
    menuSideBarDataset() {
      return this.$root.$parseDataset(this.$root.menuSideBarDataset);
    },
    isWheelLivePage() {
      return this.$route.name === 'wheel-live-page';
    },
  },
  mounted() {
    //console.log('rootApp component mounted');
    this.getConfigFromFile().then(() => {
      this.clearSclProcessorElements();

      this.getSclProcessorsConfigFromFile().then((err, resp) => {
        this.getSclProcessors();
        this.addTranslationNss();
      });
    });
    eventBus.on('notification', (options) => {
      dev.log('notify');
      this.notification[options.type]({ content: options.message, duration: 5000, keepAliveOnHover: true });
    });
  },
  methods: {
    ...mapActions(useSclProcessors, ['clearSclProcessorElements', 'addSclProcessor']),
    addTranslationNss() {
      // console.log('addTranslationNss');
      const scls = rootApp.config.globalProperties.$sclProcessors;
      // console.log('conf', scls);
      scls?.sclProcessors?.execList.forEach((scl) => {
        if (scl.addTranslationNs) {
          main.config.globalProperties.$addTranslationNs.push(scl.addTranslationNs);
        }
      });
      main.$i18next.reloadResources(null, main.config.globalProperties.$addTranslationNs);
      // console.log('main.i18next', main.config.globalProperties.$addTranslationNs, main.$i18next);
    },
    getConfigFromFile() {
      return new Promise((resolve, reject) => {
        api
          .get('/config')
          .then(function ({ data }) {
            rootApp.config.globalProperties.$config = data;
            resolve(null, data);
          })
          .catch(function (error) {
            resolve(error);
          });
      });
    },
    getSclProcessors() {
      const processors = rootApp.config.globalProperties.$sclProcessors;
      //console.log('processors', processors);
      if (processors && processors.sclProcessors && processors.sclProcessors.execList) {
        processors.sclProcessors.execList.forEach((processor) => {
          this.getProcessorManifest(processor);
        });
      }
    },
    getSclProcessorsConfigFromFile() {
      return new Promise((resolve, reject) => {
        api
          .get('/scl-processors-config')
          .then(function ({ data }) {
            rootApp.config.globalProperties.$sclProcessors = data;
            resolve(null, data);
          })
          .catch(function (error) {
            resolve(error);
          });
      });
    },
    getProcessorManifest(processor) {
      api
        .post('/scl-processors-manifest', { manifestPath: processor.pathToManifest })
        .then(({ data }) => {
          // console.log('data', data);
          if (data.manifest) {
            const proc = { ...processor, manifest: data.manifest };
            this.addSclProcessor(proc);
            this.registerSclProcessorsViews(processor, data.manifest);
          }
        })
        .catch(() => {});
    },
    registerSclProcessorsViews(processor, manifest) {
      if (manifest && manifest.reportView && manifest.reportView.components) {
        const componentsList = manifest.reportView.components.split(',');
        componentsList.forEach((cmp, idx) => {
          const pat = processor.rootPath + 'reportView/' + cmp;
          window.curComp = cmp;
          loadModule(pat, this.$root.options)
            .then((component) => {
              // console.log('register component', component);
              component.rootComponent = true;
              const options = ['needI18next'];
              const plugins = [router, VueDOMInjector, shadowStyleInjector, loadingDirectivePlugin, main.i18next];
              if (component.name == 'ip-table-report') {
                options.push('DateTime');
                options.push('needTinyPrint');
              }
              if (component.name == 'scl-stats-report') {
                options.push('needDygraph');
                options.push('needDygraphTpui');
                options.push('needApexCharts');
                options.push('needJsonTree');
                plugins.push(VueApexCharts);
              }
              const custElement = defineCustomElement(markRaw(component), {
                plugins: idx === 0 ? plugins : [],
                options,
              });

              // console.log(component.name, component);
              customElements.define(component.name, custElement);

              if (manifest.reportView.commonRoute && idx === 0) {
                // this.$router.addRoute({
                //   path: `/projects/:projectId/reports/${manifest.reportView.route}`,
                //   name: `${manifest.reportView.route}-report`,
                //   meta: {
                //     title: `${this.$t(manifest.shortDescription)}`,
                //   },
                // });
              }
            })
            .catch((err) => {
              console.log('error loading scl', err, pat)
            });
        });
      }
    },
  },
  watch: {
    'window.navigator': {
      handler(nav) {
        //console.log('ua', nav);
      },
      // force eager callback execution
      // immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.content-view-height {
  height: calc(100vh - 56px);
  &--vertical {
    height: calc(100vh - 112px);
  }
  & .simplebar-content {
    height: 100%;
    flex: 1;
    overflow-y: visible;
    &--disabled {
      //display: flex;
    }
  }
}

.sidebar-left:not(.sidebar-left--collapsed) {
  & .wrrr .simplebar-content-wrapper,
  & .wrrr .simplebar-content {
    display: flex;
    min-width: 310px;
  }
  .menu-wrapper {
    display: flex;
    min-width: 310px;
    height: 100%;
    /*flex: 1;*/
    overflow-y: visible;
  }
}
</style>
<style>
#root-app {
  min-height: 100vh;
}
</style>
<style lang="scss" scoped>
.main-container {
  &--full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.main-section {
  &--full-height {
    flex: auto;
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.content-view {
  overflow: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  &--full-height {
    flex: auto;
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
    //justify-content: flex-start;
  }
}
.root-section {
  flex: 1 1 auto;
}
</style>
