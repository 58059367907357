<template>
  <div :class="[parentElem + '__datetime-period-picker', 'datetime-period-picker']">
    <div v-for="row in rows" class="form-inline" :key="row.name">
      <div class="form-group datetime-period-picker__row">
        <label class="datetime-period-picker__col" :id="$t(row.label)">
          {{ $t(row.label) }}
        </label>
        <el-date-picker v-model="datetime.start" type="datetime" placeholder="Select date and time"> </el-date-picker>
        <el-date-picker v-model="datetime.end" type="datetime" placeholder="Select date and time"> </el-date-picker>
        <div class="datetime-period-picker__col">
          <input
            type="date"
            class="form-control datetime-period-picker__input"
            :class="parentElem + '__' + row.name + '-date-input'"
            :value="datetime[row.name]['date']"
            @change="timeChange"
            required
          />
        </div>
        <div class="datetime-period-picker__col">
          <!--TODO change to ISO lang-->
          <input
            type="time"
            lang="ru-RU"
            class="form-control datetime-period-picker__input"
            :class="parentElem + '__' + row.name + '-time-input'"
            step="1"
            :value="datetime[row.name]['time']"
            @change="timeChange"
            required
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'datetime-period-picker',
  props: ['parentElem', 'datetime'],
  components: {},
  data: () => {
    return {
      rows: [
        { label: 'From', name: 'start' },
        { label: 'To', name: 'end' },
      ],
    };
  },
  computed: {
    fullName: {
      // getter
      get: function () {
        return this.firstName + ' ' + this.lastName;
      },
      // setter
      set: function (newValue) {
        var names = newValue.split(' ');
        this.firstName = names[0];
        this.lastName = names[names.length - 1];
      },
    },
  },
  mounted() {},
  methods: {
    timeChange(e) {
      dev.log(e);
      this.$emit('selected', e);
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
