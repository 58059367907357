<template>
  <div id="top-app" class="mr-2">
    <div class="top-app-inner">
      <template v-for="rep in waitingReports">
        <div class="report-icon mr-2">
          <i class="fa fa-spinner fa-pulse fa-fw" :title="rep"></i>
        </div>
        <!--<span>{{ rep.progress }}%</span>-->
      </template>
      <template v-for="rep in pendingReports">
        <div class="report-icon mr-2">
          <i class="fa fa-spinner fa-pulse fa-fw" :title="rep.filename"></i>
          <div class="report-progress0"></div>
          <div class="report-progress" :style="{ width: rep.progress + '%' }"></div>
        </div>
        <!--<span>{{ rep.progress }}%</span>-->
      </template>
    </div>
    <div class="top-app-inner">
      <span v-if="timeColor !== 'black'" class="time-description">
        <popper :hover="true" x-placement="bottom" :append-to-body="true" :options="popperOptions">
          <template #content>
            <div class="popper p-2" x-placement="bottom">
              <div>{{ timeObject.timeDescription }}</div>
            </div>
          </template>
          <span>
            <i :style="{ color: timeColor }" class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
            <span>{{ timeObject.localTime }}</span>
          </span>
        </popper>
        <!--      <div v-if="timeDiff" class="t-icon t-icon__20px t-icon__blink t-icon__ts-alert-icon" ></div>-->
      </span>
      <span v-else>{{ timeObject.localTime }}</span>
    </div>

    <!--    <div ref="place" id="compPlace"></div>
    <div ref="place" id="compPlace1"><test-el></test-el></div>
    <button @click="loadComps">Load</button>
    -->
    <prepared-reports></prepared-reports>
    <!--    <user-settings v-model:visible="visual.userSettingsVisible"></user-settings>-->
  </div>
</template>

<script>
import PreparedReports from '@/components/modals/PreparedReports.vue';
import { Zone, DateTime } from 'luxon';
import DateNano from '../../utils/format.date.time';
import * as Vue from 'vue/dist/vue.esm-bundler';

// import store from '../store';
// import router from '../router';

import Popper from 'vue3-popper';
// import './../../assets/vue-popper-custom.css';
import { clusterInterval } from '../../utils/datetimeHumanize';
// import UserSettings from './UserSettings.vue';

import { mapStores } from 'pinia';
import { useReports } from '@/stores/reports';
import { useTpui } from '@/stores/tpui';
import { usePrefs } from '@/stores/prefs';
import { useServerTime } from '@/stores/serverTime';
import { useVisual } from '@/stores/visual';

export default {
  name: 'top-app',
  components: { PreparedReports, popper: Popper },
  props: ['dataset'],
  data() {
    return {
      // eslint-disable-next-line no-undef
      now: Date.now(),
      popperOptions: {
        // placement: 'bottom',
        // modifiers: { offset: [0, 10] },
      },
      options: {
        moduleCache: {
          vue: Vue,
        },

        getFile(url) {
          return fetch(url).then((response) => {
            const dt = response.text();
            dev.log('dt', dt);
            return response.ok ? dt : Promise.reject(response);
          });
        },

        addStyle(styleStr) {
          const style = document.createElement('style');
          style.textContent = styleStr;
          const ref = document.head.getElementsByTagName('style')[0] || null;
          document.head.insertBefore(style, ref);
        },

        log(type, ...args) {
          dev.log(type, ...args);
        },
      },
    };
  },
  computed: {
    ...mapStores(useReports, useTpui, usePrefs, useServerTime),
    ...mapStores(useVisual),
    pendingReports() {
      return this.reports.pendingReports;
    },
    waitingReports() {
      return this.reports.waitPendingReports;
    },
    serverTimeOffset() {
      return this.serverTime.serverTimeOffset;
    },
    getLang() {
      return this.prefs?.language || 'en';
    },
    getOffset() {
      return this.prefs?.timezoneOffset || 0;
    },

    timeObject() {
      const serverTime = new DateNano(this.now + this.serverTimeOffset)
        .setTimezoneOffset(this.getOffset)
        .customFormat('#DD#.#MM#.#YYYY# #hhhh#:#mm#:#ss#');
      return {
        localTime: new DateNano(this.now).setTimezoneOffset(this.getOffset).customFormat('#hhhh#:#mm#:#ss#'),
        time: DateTime.fromMillis(this.now + this.serverTimeOffset).toLocaleString(DateTime.TIME_WITH_SECONDS),
        timeDescription:
          this.$t('Server time differs by') +
          ' ' +
          clusterInterval(this.serverTimeOffset, this.getLang).text +
          ' (' +
          serverTime +
          ')',
        dateTime: DateTime.fromMillis(
          new DateNano(this.now + this.serverTimeOffset).setTimezoneOffset(this.getOffset).getTime()
        ).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
      };
    },
    timeDiff() {
      if (Math.abs(this.serverTimeOffset) > 15 * 1e3) {
        return true;
      }
      return false;
    },
    timeColor() {
      if (Math.abs(this.serverTimeOffset) > 10000) {
        return 'red';
      } else if (Math.abs(this.serverTimeOffset <= 10000) && Math.abs(this.serverTimeOffset) > 1000) {
        return 'orange';
      } else {
        return 'black';
      }
    },
  },
  created() {
    var self = this;
    setInterval(function () {
      self.now = Date.now();
    }, 500);
    // setInterval(function () {
    //   self.$store.dispatch('syncTime');
    // }, 5000);
  },
  mounted() {
    // this.$store.commit('increment')
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    window.addEventListener('showPrepared', () => {
      // console.log('ev:user');
      this.visual.preparedReportsModalVisible = true;
    });
  },
  methods: {
  },
  watch: {},
};
</script>

<style>
#events-app {
  width: 100%;
}
</style>

<style lang="scss">
#top-app {
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
}
.report-progress0 {
  position: absolute;
  background-color: darkgray;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.report-progress {
  position: absolute;
  background-color: lawngreen;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
<style>
:root {
  --popper-theme-background-color: #2a2e32;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: white;
  --popper-theme-border-width: 0px;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 4px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
