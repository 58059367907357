<template>
  <div
    id="right_sidebar"
    class="nav-side-menu wheel-desc-block"
    :class="{ 'wheel-desc-block--vertical': visual.vertical }"
  >
    <div class="menu-list">
      <ul id="menu-content" class="menu-content in m-3">
        <n-grid x-gap="10" cols="2" item-responsive>
          <n-grid-item span="2 800:1">
            <div class="menu-content-left">
              <li class="wh-tip">
                <div class="card card_wh-tip">
                  <div id="currentSubnet" class="card-header card-header_wh-tip">&nbsp;</div>
                  <div class="card-body">
                    <ul id="view-info" class="sub-menu collapse show in">
                      <li id="header" class="wh-tip no-collapsable"></li>
                      <li id="desc_left" class="wh-tip no-collapsable">
                        <div id="desc_left_head" class="wh-tip-head">
                          <wh-bulls @showDesc="showDesc" :bulls="leftBulls"></wh-bulls>
                        </div>
                        <div id="desc_left_body" class="wh-tip-body">
                          <wh-desc :descs="leftDesc" @show-last-events="showLastEvents"></wh-desc>
                        </div>
                      </li>
                      <li id="wheelRecentEvents"></li>
                      <li id="desc_right" class="wh-tip">
                        <div id="desc_right_head" class="wh-tip-head">
                          <wh-bulls @showDesc="showDesc" :bulls="rightBulls"></wh-bulls>
                        </div>
                        <div id="desc_right_body" class="wh-tip-body">
                          <wh-desc :descs="rightDesc" @show-last-events="showLastEvents"></wh-desc>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </div>
          </n-grid-item>
          <n-grid-item span="2 800:1">
            <div class="menu-content-right">
              <li ref="eventsNotificationsContainer" id="eventsNotificationsContainer"></li>
              <li id="right_sidebar_buttons"></li>
              <li v-if="wheel.isOpenWheel">
                <ul id="view-info" class="sub-menu collapse show in">
                  <li id="header" class="wh-tip no-collapsable"></li>
                  <li id="desc_left" class="wh-tip no-collapsable">
                    <div id="desc_left_head" class="wh-tip-head"></div>
                    <div id="desc_left_body" class="wh-tip-body"></div>
                  </li>
                  <li id="wheelRecentEvents"></li>
                  <li id="desc_right" class="wh-tip">
                    <div id="desc_right_head" class="wh-tip-head"></div>
                    <div id="desc_right_body" class="wh-tip-body"></div>
                  </li>
                </ul>
              </li>
              <li class="wh-tip">
                <div
                  v-if="wheel.liveModeOn && ghosts.ghostGooses?.length > 0"
                  class="card card_wh-tip"
                  id="ghost_goose_info"
                >
                  <div class="card-header card-header_wh-tip">
                    <a href="#ghost_gooses_panel" data-toggle="collapse" class="collapsed">
                      {{ $t('Ghost GOOSE messages') }}
                      <span class="badge badge-danger badge_wh-tip">{{ ghosts.ghostGooses?.length }}</span>
                      <i style="margin-top: 5px" class="fa fa-caret-down float-right"></i>
                      <i style="margin-top: 5px" class="fa fa-caret-up float-right"></i>
                    </a>
                  </div>
                  <div class="collapse" id="ghost_gooses_panel">
                    <div class="card-body">
                      <ghost-goose
                        v-for="(item, idx) in ghosts.ghostGooses"
                        v-bind:gg="item"
                        v-bind:idx="idx + 1"
                        v-bind:lvbList="ghosts.lvbs"
                      ></ghost-goose>
                    </div>
                  </div>
                </div>
              </li>
              <li class="wh-tip">
                <div v-if="wheel.liveModeOn && ghosts.ghostSvs?.length > 0" class="card card_wh-tip" id="ghost_sv_info">
                  <div class="card-header card-header_wh-tip">
                    <a href="#ghost_svs_panel" data-toggle="collapse" class="collapsed" title="goose_svs_tooltip">
                      {{ $t('Ghost SV messages') }}
                      <span class="badge badge-danger badge_wh-tip">{{ ghosts.ghostSvs?.length }}</span>
                      <i style="margin-top: 5px" class="fa fa-caret-down float-right"></i>
                      <i style="margin-top: 5px" class="fa fa-caret-up float-right"></i>
                    </a>
                  </div>
                  <div class="collapse" id="ghost_svs_panel">
                    <div class="card-body">
                      <ghost-sv 
                        v-for="(item, idx) in ghosts.ghostSvs" 
                        v-bind:gs="item" 
                        v-bind:idx="idx + 1"
                        v-bind:lvbList="ghosts.lvbs"
                        ></ghost-sv>
                    </div>
                  </div>
                </div>
              </li>
              <li class="wh-tip">
                <div
                  v-if="wheel.liveModeOn && ghosts.ghostIeds?.length > 0"
                  class="card card_wh-tip"
                  id="ghost_ied_info"
                >
                  <div class="card-header card-header_wh-tip">
                    <a href="#ghost_ieds_panel" data-toggle="collapse" class="collapsed">
                      {{ $t('Ghost IED') }}
                      <span class="badge badge-danger badge_wh-tip">{{ ghosts.ghostIeds?.length }}</span>
                      <i style="margin-top: 5px" class="fa fa-caret-down float-right"></i>
                      <i style="margin-top: 5px" class="fa fa-caret-up float-right"></i>
                    </a>
                  </div>
                  <div class="collapse" id="ghost_ieds_panel">
                    <div class="card-body ghost-ied-panel-card-body">
                      <table class="table ghost-ied-panel-table" id="ghost-ied-panel-table">
                        <thead>
                          <tr class="table-row">
                            <th onclick="ghosts.sortRowsBy('mac')" class="table-cell table-cell-col-1 table-header">
                              {{ $t(`AccessPoint {element}`, { element: 'MAC' }) }}
                              <span id="sort-mac" class="fal fa-caret-up"></span>
                            </th>
                            <th onclick="ghosts.sortRowsBy('ip')" class="table-cell table-cell-col-2 table-header">
                              {{ $t('AccessPoint {element}', { element: 'IP' }) }}
                              <span id="sort-ip" class="fal fa-sort"></span>
                            </th>
                            <th class="table-cell table-cell-col-3 table-header">{{ $t('Set offline') }}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <ghost-ied
                            v-for="(item, idx) in ghosts.ghostIeds"
                            v-bind:gi="item"
                            v-bind:idx="idx + 1"
                          ></ghost-ied>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </li>
              <li class="wh-tip">
                <div v-if="wheel.liveModeOn && ghosts.lvbs?.length > 0" class="card card_wh-tip" id="live_lvb_info">
                  <div class="card-header card-header_wh-tip">
                    <a href="#live_lvb_info_panel" data-toggle="collapse" class="collapsed">
                      {{ $t('Tekvel Park Status') }}
                      <span class="badge badge-info badge_wh-tip">{{ ghosts.lvbs?.length }}</span>
                      <i style="margin-top: 5px" class="fa fa-caret-down float-right"></i>
                      <i style="margin-top: 5px" class="fa fa-caret-up float-right"></i>
                    </a>
                  </div>
                  <div class="collapse" id="live_lvb_info_panel">
                    <div class="card-body">
                      <ul class="list-group list-group-flush">
                        <live-lvb v-for="(item, idx) in ghosts.lvbs" v-bind:lvb="item" v-bind:idx="idx + 1"></live-lvb>
                        <li class="list-group-item d-flex justify-content-between align-items-center">
                          {{ $t('TPLS') }}
                          <span class="text-success" v-if="ghosts.system_state.tpls.alive"
                            ><i class="fal fa-check"></i></span
                          ><span class="text-danger" v-else><i class="fal fa-exclamation-triangle"></i></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </n-grid-item>
        </n-grid>
      </ul>
    </div>
  </div>
  <teleport to="#modals-target">
  <lvb-description-modal></lvb-description-modal>
  <last-events-modal :last-events="lastEventsQuery"></last-events-modal>
  </teleport>
</template>

<script>
import WhBulls from '@/components/wheel/WhBulls.vue';
import WhDesc from '@/components/wheel/WhDesc.vue';
import LastEventsModal from '@/components/modals/LastEventsModal.vue';
import LvbDescriptionModal from '@/components/modals/LvbDescriptionModal.vue';
import GhostGoose from '@/components/wheel/GhostGoose.vue';
import GhostSv from '@/components/wheel/GhostSv.vue';
import GhostIed from '@/components/wheel/GhostIed.vue';
import LiveLvb from '@/components/wheel/LiveLvb.vue';
import LiveDevObject from '@/components/wheel/LiveDevObject.vue';
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useWheel } from '@/stores/wheel';
import { useGhosts } from '@/stores/ghosts';
import { useVisual } from '@/stores/visual';
export default {
  name: 'wheel-desc',
  props: {
    bulls: {
      type: Array,
    },
    desc: {
      type: Array,
    },
  },
  components: {
    WhBulls,
    WhDesc,
    LastEventsModal,
    LvbDescriptionModal,
    GhostGoose,
    GhostSv,
    GhostIed,
    LiveLvb,
    LiveDevObject,
  },
  emits: ['showDesc'],
  data: () => {
    return {
      lastEventsQuery: {},
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useWheel),
    ...mapStores(useGhosts),
    leftBulls() {
      return this.bulls.filter((el) => el.prefix == 'left') || [];
    },
    rightBulls() {
      return this.bulls.filter((el) => el.prefix == 'right') || [];
    },
    leftDesc() {
      return this.desc.filter((el) => el.prefix == 'left') || [];
    },
    rightDesc() {
      return this.desc.filter((el) => el.prefix == 'right') || [];
    },
    goose_svs_tooltip() {
      return 'Ghost SV messages are messages that exist on the network while they are not configured.\nCheck if you use the latest version of the configuraton file and all IEDs are configured with it.';
    },
  },
  methods: {
    ...mapActions(useWheel, ['showLastEventsModal']),
    showDesc(i, prefix) {
      this.$emit('showDesc', i, prefix);
    },
    showLastEvents(q, w, e) {
      dev.log('q', q);
      this.lastEventsQuery = q;
      this.showLastEventsModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#right_sidebar {
  display: flex;
  flex-direction: column;
}
.wheel-desc-block {
  min-height: calc(100vh - 56px);
  &--vertical {
    min-height: calc(100vh - 112px);
  }
}
</style>
