<template>
  <div
    class="t-wheel-drawer"
    :class="{ 't-wheel-drawer--vertical': vertical }"
    style="height: 100%"
    :style="{ flexDirection: dirStyle }"
  >
    <div
      class="t-wheel-drawer__content"
      :class="{ 't-wheel-drawer--collapsed': collapsed, 't-wheel-drawer__content--reverse': reverse }"
    >
      <slot name="content"></slot>
    </div>

    <div
      class="t-wheel-drawer__side"
      v-resize:debounce="onResize"
      :class="{ 't-wheel-drawer__side--collapsed': collapsed, 't-wheel-drawer__side--reverse': reverse }"
    >
      <t-drawer-divider :reverse="reverse" @divider-click="$emit('divider-click')"></t-drawer-divider>
      <div class="t-wheel-drawer__side-content">
        <simplebar-vue-naive :disabled="!sideScrollable" :height="sizeHeight">
          <slot name="side"></slot>
        </simplebar-vue-naive>
      </div>
    </div>
  </div>
</template>

<script>
import TDrawerDivider from './TDrawerDivider.vue';
import SimplebarVueNaive from '@/components/common/SimplebarVueNaive.vue';
import getSize from 'get-size';
import { debounce } from '@/plugins/debounce';
export default {
  name: 't-wheel-drawer',
  props: {
    sideScrollable: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SimplebarVueNaive,
    TDrawerDivider,
  },
  data: () => {
    return {
      sideSize: undefined,
    };
  },
  computed: {
    sizeHeight() {
      return this.sideSize?.height || 0;
    },
    dirStyle() {
      if (this.vertical) {
        if (this.reverse) {
          return 'column-reverse';
        } else {
          return 'column';
        }
      } else {
        if (this.reverse) {
          return 'row-reverse';
        } else {
          return 'row';
        }
      }
    },
    contentStyle() {
      if (this.vertical) {
        if (this.collapsed) {
          return { flexBasis: 0, flexGrow: 0 };
        } else {
          return { flexBasis: 1, flexGrow: 1 };
        }
      } else {
        if (this.collapsed) {
          return { flexBasis: 0, flexGrow: 0 };
        } else {
          return { flexBasis: '60%', flexGrow: 1 };
        }
      }
    },
    sideStyle() {
      if (this.vertical) {
        if (this.collapsed) {
          return { flexGrow: 1, flexBasis: '40vh' };
        } else {
          return { flexGrow: 0, flexBasis: '40vh' };
        }
      } else {
        if (this.collapsed) {
          return { flexGrow: 1, flexBasis: '40%' };
        } else {
          return { flexGrow: 0, flexBasis: '40%' };
        }
      }
    },
  },
  mounted() {
    // this.checkSize();
  },
  updated() {
    // this.$nextTick(()=>{
    //   this.checkSize();
    // })
  },
  methods: {
    onResize() {
      // console.log('t-wheel-drawer resize');
      this.sideSize = getSize('.t-wheel-drawer__side');
    },
  },
};
</script>
<style lang="scss" scoped>
.t-wheel-drawer {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  position: relative;
  $drawer: &;
  &--full-height {
    min-height: calc(100vh - 56px);
  }
  &__content {
    flex: 1;
    padding-left: unset;
    padding-right: 40%;
    opacity: 1;
    transition: opacity 0.5s;
    &--reverse {
      padding-left: 40%;
      padding-right: unset;
    }
    &--collapsed {
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
  &__side {
    position: absolute;
    width: 40%;
    display: flex;
    flex-direction: row;
    top: 0;
    right: 0;
    bottom: 0;
    left: unset;
    transition: width 0.5s;
    &--reverse {
      top: 0;
      left: 0;
      bottom: 0;
      right: unset;
      flex-direction: row-reverse;
    }

    &--collapsed {
      width: 100%;
      transition: width 0.5s;
    }
    &-content {
      flex: 1;
    }
  }
  &--vertical {
    &--full-height {
      min-height: calc(100vh - 112px);
    }
    #{$drawer}__content {
      flex: 1;
      padding-left: unset;
      padding-right: unset;
      padding-top: unset;
      padding-bottom: calc((100vh - 112px) * 0.4);
      opacity: 1;
      transition: opacity 0.5s;
      &--reverse {
        padding-top: calc((100vh - 112px) * 0.4);
        padding-bottom: unset;
      }
      &--collapsed {
        opacity: 0;
        transition: opacity 0.5s;
      }
    }
    #{$drawer}__side {
      height: calc((100vh - 112px) * 0.4);
      width: 100%;
      display: flex;
      flex-direction: column;
      top: unset;
      right: 0;

      bottom: 0;
      left: 0;
      transition: height 0.5s;
      &--reverse {
        top: 0;
        right: 0;
        left: 0;
        bottom: unset;
        flex-direction: column-reverse;
      }
      &--collapsed {
        height: 100%;
        transition: height 0.5s;
      }
    }
    #{$drawer}__block {
    }
  }
}
</style>
