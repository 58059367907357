import api from '@/services/api';
import { defineStore } from 'pinia';
import set from 'set-value';

export const useSclProcessors = defineStore({
  id: 'sclProcessors',
  state: () => {
    return {
      sclProcessorsElements: {},
      sclProcessors: [],
    };
  },
  getters: {
    sclInterface: (state) => {
      return state.sclProcessorsElements;
    },
  },
  actions: {
    clearSclProcessorElements(value) {
      this.sclProcessorsElements = {};
    },
    addSclProcessor(value) {
      this.setSclProcessorElements(value);
      const found = this.sclProcessors.find((el) => el.label === value.label);
      if (!found) {
        this.sclProcessors.push(value);
      }
    },
    setSclProcessorElements(value) {
      const setObjectProperty = (object, path, value) => {
        const parts = path.split(':');
        const limit = parts.length - 1;
        for (let i = 0; i < limit; ++i) {
          const key = parts[i];
          object = object[key] ?? (object[key] = {});
        }
        const key = parts[limit];
        if (object[key] === undefined) {
          object[key] = [];
        }
        const found = object[key].find((el) => el.value.route === value.route);
        // console.log('found', JSON.stringify(found), JSON.stringify(value));
        if (!found) {
          object[key].push(value);
        }
      };
      const pat = value.manifest.reportView.interfacePlace;
      const val = {
        title: value.manifest.interfaceTitle,
        route: value.manifest.reportView.route,
        icon: value.manifest.interfaceIcon,
        condition: value.manifest.reportView.stateCondition,
      };
      // console.log('pat, val', pat, val);
      // setToValue(this.sclProcessorsElements, pat, val);
      if (this.sclProcessorsElements[pat] === undefined) {
        // this.sclProcessorsElements[pat] = [];
        this.sclProcessorsElements[pat] = [];
      }

      // this.sclProcessorsElements[pat].push(val);
      this.sclProcessorsElements[pat].push(val);
    },
  },
});
