<template>
  <div id="events-table">
    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <h2
        v-if="
          eventsArchive.eventsArchive &&
          !isNaN(eventsArchive.eventsArchiveTS?.tstart) &&
          !isNaN(eventsArchive.eventsArchiveTS?.tend)
        "
        class="events-archive__title"
      >
        {{ $t('Events log interval', { intervalStart: nanoTstart, intervalEnd: nanoTend }) }}
      </h2>
      <events-table
        v-if="visibleTable"
        :fields="fields"
        :query="{ search }"
        :url="url"
        :length-menu="lengthMenu"
        ref="eventsTable"
        @page-update="pageChange"
      >
        <template slot="filters"> </template>
      </events-table>
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';
import EventsTable from '@/components/events/EventsTable.vue';
import { ref, onMounted, getCurrentInstance } from 'vue';
import { RouterLink } from 'vue-router';
import { mapStores } from 'pinia';
import { mapActions } from 'pinia';

import { useTpws } from '@/stores/tpws';
import { useEventsArchive } from '@/stores/eventsArchive';
import { useUi } from "@/stores/ui";
export default {
  name: 'events-page',
  components: { EventsTable },
  props: {
    page: { type: Number, default: 1 },
    eventsSearchStr: String,
  },
  data: () => {
    return {
      search: '',
      quickSearch: '',
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      visibleTable: true,
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useUi),
    ...mapStores(useEventsArchive),
    currentProject() {
      return this.tpws.currentProject;
    },
    options(){
      return this.ui.options
    },
    url() {
      return this.eventsArchive.eventsArchive
        ? `/projects/${this.currentProject}/events3?tstart=${this.eventsArchive.eventsArchiveTS.start}&tend=${this.eventsArchive.eventsArchiveTS.end}`
        : `/projects/${this.currentProject}/events3`;
    },
    headerVisible() {
      return !isNaN(this.eventsArchive.eventsArchiveTS.start) && !isNaN(this.eventsArchive.eventsArchiveTS.end);
    },

    fields() {
      return [
        {
          label: this.$t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
          style: { width: 230 + 'px' },
          classes: ['ts-column'],
          renderTemplate: true,
          render: (d) => {
            if (d.params || d.query) {
              return this.$h(RouterLink, {
                class: 'btn-link',
                to: { name: 'signals-profile', params: d.params, query: d.query },
                innerHTML: d.name,
              });
            } else {
              return this.$h('span', { innerHTML: d.name });
            }
          },
          // render: (d) => {
          //   if (d.params) {
          //     return this.$h(`<router-link class="btn-link" :to="{name: 'signals-profile', params: ${d.params}}" title="${d.title}">${d.name}</router-link>`);
          //   } else {
          //     return this.$h(`<span title="${d.title}">${d.name}</span>`);
          //   }
          // },
        },
        {
          label: this.$t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
          style: { width: 70 + 'px' },
          render: (d) => {
            return `<span title="${d.name}" class="${d.class}" aria-hidden="true"></span>&nbsp;${d.name}`;
          },
        },
        {
          label: this.$t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
          style: { width: 25 + '%' },
          renderTemplate: true,
          render: (d, r) => {
            // dev.log('ied', d, r)
            let title = '';

            if (d.params || d.query) {
              return this.$h(RouterLink, {
                class: d.class,
                to: { name: 'wheel-live-page', params: d.params, query: d.query },
                title: d.title,
                innerHTML: d.name,
              });
            } else {
              return this.$h('span', { class: d.class, title: d.title, innerHTML: d.name });
            }
          },
        },
        {
          label: this.$t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
          style: { width: 25 + '%' },
          renderTemplate: true,
          render: (d) => {
            if (d.params || d.query) {
              return this.$h(RouterLink, {
                class: d.class,
                to: { name: 'wheel-live-page', params: d.params, query: d.query },
                title: d.title,
                innerHTML: d.name,
              });
            } else {
              return this.$h('span', { class: d.class, title: d.title, innerHTML: d.name });
            }
          },
        },
        {
          label: this.$t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionData',
          sortable: true,
          style: { width: 'auto' },
          render: (d) => {
            return this.$t(d.key+'^srv', d.data);
          },

        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.eventsArchive.eventsArchiveTS.start).customFormat();
    },
    nanoTend() {
      return new DateNano(this.eventsArchive.eventsArchiveTS.end).customFormat();
    },
  },

  mounted() {
    this.setEventsArchive(false);
    this.setEventsArchiveTS({ tstart: 0, tend: 0 });

    dev.log(this.$route.query.search);

  },
  methods: {
    ...mapActions(useEventsArchive, ['setEventsArchive', 'setEventsArchiveTS']),
    onTouchStart() {
      document.querySelector('body').style.overflow = 'hidden';
    },
    onTouchEnd() {
      document.querySelector('body').style.overflow = 'auto';
    },
    pageChange() {
      dev.log('this.$refs.eventsTable', this.$refs.eventsTable);
      let page = this.$refs?.eventsTable?.currentPage,
        order = this.$refs?.eventsTable?.order,
        search = this.$refs?.eventsTable?.search?.value;
      dev.log('values', page, order, search);
      // this.$router.push({name:'events', query: {
      //   page: page,
      //     search: search,
      //     column: order?.column,
      //     dir: order?.dir
      //   }})
      // history.pushState(
      //   {
      //     page: page,
      //     order: order,
      //     search: this.$qs.stringify(search),
      //   },
      //   `page ${page}`,
      //   `?p=${this.eventsArchive.eventsArchive ? 'archive' : 'events'}&page=${page}&search=${search}&column=${
      //     order && order['column']
      //   }&dir=${order && order['dir']}`
      // );
    },
  },
};
</script>

<style lang="scss">
#events-table {
  padding: 0 7%;
}
</style>
<style lang="scss"></style>
