<template>
  <div class="filter-group">
    <div class="filter-group__header">
      <div class="filter-group__header-icon">
        <t-icon v-if="open" :size="20" icon="collapse-open"></t-icon>
        <t-icon v-else :size="20" icon="collapse-close"></t-icon>
      </div>
      <div @click="open = !open" class="filter-group__header-title">{{ name }}</div>
    </div>
    <div v-if="open" class="filter-group__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import TIcon from '@/components/ui/TIcon.vue';
export default {
  name: 'filter-group',
  components: {
    TIcon,
  },
  props: {
    name: {
      type: String,
    },
  },
  data: () => {
    return {
      open: true,
    };
  },
};
</script>

<style scoped></style>
