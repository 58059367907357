<template>
  <div class="v-icon" ref="vIcon">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'v-icon',
  props: {
    size: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  mounted() {
    this.updateStyles();
  },
  methods: {
    updateStyles() {
      // console.log('upd styles', this.$el);
      let hasSize = false;
      let hasWidth = false;
      let hasHeight = false;
      // console.log('reset', this.src, this.width + 'x' + this.height);
      if (this.size) {
        hasSize = true;
      }
      if (this.width && this.width !== 'auto') {
        hasWidth = true;
      }
      if (this.height && this.height !== 'auto') {
        hasHeight = true;
      }
      // console.log('props', hasSize, hasWidth, hasHeight);
      if (!hasWidth && !hasHeight) {
        if (hasSize) {
          // console.log('set size');
          this.$refs.vIcon.style.width = this.size + 'px';
          this.$refs.vIcon.style.height = this.size + 'px';
        }
      } else {
        if (this.width !== 'auto') {
          this.$el.style.width = this.width;
        }
        if (this.height !== 'auto') {
          this.$el.style.height = this.height;
        }
      }
    },
  },
  watch: {
    size: {
      handler(val) {
        this.updateStyles();
      },
      imediate: true,
    },
    width: {
      handler(val) {
        this.updateStyles();
      },
      imediate: true,
    },
    height: {
      handler(val) {
        this.updateStyles();
      },
      imediate: true,
    },
  },
};
</script>

<style scoped>
.v-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
