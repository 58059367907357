<template>
  <div id="wheel-page1" class="">
    <div id="loading">
      <div class="el-loading-mask">
        <div class="el-loading-spinner">
          <svg class="circular" viewbox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none"></circle>
          </svg>
        </div>
      </div>
    </div>
    <div id="wheel" class="wheel" :class="{ 'wheel_open-wheel': wheel.isOpenWheel }" style="height: 100%">
      <div class="wheel__top-bar">
        <div class="wheel__crumbs">
          <slot name="wheel-bread-crumbs"></slot>
        </div>

        <div class="wheel__right-panel">
          <slot name="wheel-nav"></slot>
        </div>
      </div>
      <div class="wheel-disconnect-screen">
        <div class="disconnect-screen-content">
          <div class="preloader"></div>
          <span>{{ $t('No Server Connection') }}</span>
        </div>
      </div>
      <div class="wheel-tpls-disconnect-screen">
        <div class="disconnect-screen-content">
          <div class="preloader"></div>
          <span>{{ $t('No TPLS Connection') }}</span>
        </div>
      </div>
      <div class="wh-page" v-resize:debounce="onResize" :class="{ 'wh-page--vertical': visual.vertical }"></div>
      <!--      <div id="footer">-->
      <!--        <div id="controls">-->
      <!--          <div class="btn-group" role="group" aria-label="View controls">-->

      <!--            <div id="filters_popover" class="d-none">-->
      <!--              <form action="" id="filters_form" class="wh-has-popover"></form>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';
import { mapStores, mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useWheel } from '@/stores/wheel';
import { useTpws } from '@/stores/tpws';
import getSize from 'get-size';
// import VueResizeObserver from 'vue-resize-observer';

export default {
  name: 'wheel-graph',
  components: {},
  // directives: { resize: VueResizeObserver },
  props: {
    page: { type: Number, default: 1 },
    eventsSearchStr: String,
  },
  emits: ['resize'],
  data: () => {
    return {
      wheelSize: undefined,
      quickSearch: '',
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      visibleTable: true,
    };
  },
  computed: {
    ...mapStores(useVisual, useWheel),
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
    url() {
      return this.eventsArchive
        ? `/projects/${this.currentProject}/archive?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
        : `/projects/${this.currentProject}/events2`;
    },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },
    isLive() {
      return this.$route.name === 'wheel-live-page';
    },
  },
  created() {
    // this.$store.dispatch('showEventsCounterModal', false);
  },
  mounted() {
    // $('#eventsCounterVisibility_switcher').prop('checked', wheelEventsCounter.visiblity);
    this.getGraphSize();
  },
  methods: {
    ...mapActions(useWheel, ['setGraphSize']),
    onRedraw(r) {
      // console.log('wheelSize', r);
      this.$emit('redraw', r);
      // this.getGraphSize();
    },
    onResize(r) {
      // console.log('wheelSize', r);
      this.$emit('resize', r);
      // this.getGraphSize();
    },
    getGraphSize() {
      try {
        this.wheelSize = getSize('#wheel .wh-page');
        this.setGraphSize({ width: this.wheelSize.width, height: this.wheelSize.height });
      } catch (e) {
        dev.log('getGraphSize err', e);
      }
    },
    // showEventsCounterModal() {
    //   this.$store.dispatch('showEventsCounterModal', true);
    // },
    onTouchStart() {
      document.querySelector('body').style.overflow = 'hidden';
    },
    onTouchEnd() {
      document.querySelector('body').style.overflow = 'auto';
    },
    pageChange() {},
  },
};
</script>

<style lang="scss">
.wheel__crumbs {
  display: flex;
}
</style>
<style lang="scss"></style>
