<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.userProfileModalVisible"
    v-model:visible="visual.userProfileModalVisible"
    ref="modEx"
    @closeModal="closeModal"
    @onSubmit="onSubmit"
  >
    <template #title> </template>
    <template #content>
      <el-form label-width="140px">
        <template v-for="f in fields">
          <div class="form-group">
            <label :for="f.name" class="col-sm-3 control-label">{{ $t(f?.label) }}</label>
            <div class="col-sm-12">
              <input
                v-model="formValues[f.name]"
                :class="{ 'is-invalid': v$.formValues[f.name].$errors.length }"

                :id="f.name"
                class="form-control"
                :name="f.name"
              />
              <div v-for="error in v$.formValues[f.name].$errors" :key="error.$uid" class="invalid-feedback">
                {{ $t(error.$message.toString()) }}
              </div>
            </div>
          </div>

<!--          <el-form-item :label="$t(f?.label)">-->
<!--            <el-input-->
<!--              v-model="formValues[f.name]"-->
<!--              :class="{ 'is-invalid': v$.formValues[f.name].$errors.length }"-->
<!--              :name="f.name"-->
<!--            />-->

<!--          </el-form-item>-->
<!--          <div v-for="error in v$.formValues[f.name].$errors" :key="error.$uid" class="invalid-feedback">-->
<!--            {{ $t(error.$message.toString()) }}-->
<!--          </div>-->
        </template>
      </el-form>
    </template>
    <template #buttons> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useTpws } from '@/stores/tpws';
import { useUsers } from '@/stores/users';
import { mapStores } from 'pinia';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

export default {
  name: 'user-profile-modal',
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    },
    selectedSubnet: {
      type: String,
    },
  },
  components: { ModalDialog },
  data: () => {
    return {
      modal: {
        name: 'user-profile',
        title: 'User profile',
        closeBtn: 'Close',
        onSubmit: 'Save',
        noSubmit: false,
        size: '800',
        noTitle: false,
        noCross: false,
      },
      fields: [],
      formValues: {},
      newName: '',
      newLanguage: undefined,
    };
  },
  validations() {
    return {
      formValues: {
        email: { required, email },
        firstname: { required },
        lastname: { required },
        company: { required },
        position: { required },
        phone: { required },
      },
    };
  },

  computed: {
    ...mapStores(useVisual),
    ...mapStores(useTpws),
    ...mapStores(useUsers),
    currentProfileFields() {
      return this.users.userFields || [];
    },
  },
  mounted() {
    // this.newName = this.tpws.displayName;
    // this.newLanguage = this.tpws.currentLang;
  },
  methods: {
    updateForm() {
      this.fields = this.currentProfileFields.filter((f) => f.name !== 'id');

      this.formValues = { ...this.fields.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {}) };
    },
    closeModal() {
      dev.log('closeModal');
      this.visual.userProfileModalVisible = false;
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.formValues.$validate();
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (!isFormCorrect) {
        dev.log('form not valid', this.v$.formValues);
        return;
      } else {
        this.doSubmit();
      }
    },
    doSubmit() {
      dev.log('doSubmit', this.formValues);
      const id = Number(this.currentProfileFields.find((f) => f.name === 'id').value);
      this.users.setCurrentProfileFields({id: id, ...this.formValues}).then(response=>{
        dev.log('response', response);

      }).finally(()=>{
        this.visual.userProfileModalVisible = false;
      });
    },
  },
  watch: {
    currentProfileFields: {
      handler(val) {
        if (val.length) {
          this.updateForm();
        }
      },
      immediate: true,
    },
    'visual.userProfileModalVisible': {
      handler(val) {
        if (val) {
          this.users.getCurrentProfileFields();
        }
      },
      immediate: true,
    },
    'tpws.displayName': {
      handler(val) {
        this.newName = val;
      },
    },
    'tpws.currentLang': {
      handler(val) {
        this.newLanguage = val;
      },
    },
  },
};
</script>

<style lang="scss">
h5 .subnet-select-modal__title {
  font-size: 1.1em;
  line-height: 1.5em;
}
</style>
<style lang="scss"></style>
