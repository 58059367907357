<template>
  <!--  <div id="wheel-page" class="" style='height: 100%'>-->
  <!--      <button @click="layoutOrder = !layoutOrder">ord</button>-->
  <t-wheel-drawer
    id="wheel-page"
    class="t-wheel-drawer--full-height"
    :class="{ 't-wheel-drawer--vertical--full-height': visual.vertical }"
    :collapsed="visual.wheelCollapsed"
    :vertical="visual.vertical"
    :reverse="visual.wheelOrder"
    :side-scrollable="true"
    @divider-click="toggleWheelCollapsed"
  >
    <template #content>
      <!--      <div style='background-color: grey'>test-div</div>-->
      <wheel-graph @resize="onResize">
        <template #wheel-bread-crumbs>
          <wheel-breadcrumbs
            v-if="wheel.pathTree?.length > 0"
            :list="wheel.pathTree"
            @onSelect="bcAction"
          ></wheel-breadcrumbs>
        </template>
        <template #wheel-nav>
          <n-popover v-if="isLivePage" placement="bottom-start" trigger="click" raw :show-arrow="true">
            <template #trigger>
              <button class="wheel-nav-btn btn mr-1">
                <v-svg fill="#1757bd" src="settings-cog"></v-svg>
              </button>
            </template>
            <div class="wheel-tree-view">
              <div v-if="false" class="wheel-tree-view__header">{{ $t('Hierarchy') }}</div>
              <div class="wheel-tree-view__list">
                <span v-if="isLivePage" class="live-controls">
                  <span style="display: inline-block; margin-right: 10px">
                    {{ $t('Number of events') }}:&nbsp;
                    <input
                      type="checkbox"
                      @change="wheelEventsCounter.toggleBadges()"
                      style="margin-right: 4px"
                      class=""
                      id="eventsCounterVisibility_switcher"
                    />
                    <label for="eventsCounterVisibility_switcher" class="">{{ $t('Show') }}</label>
                  </span>

                  <a
                    href="#"
                    style="display: inline-block; margin-right: 10px"
                    @click="visual.eventsCounterModalVisible = true"
                    >{{ $t('Change interval') }}</a
                  >
                </span>
              </div>
            </div>
          </n-popover>

          <button
            id="back_btn"
            class="wheel-nav-btn btn fal fa-long-arrow-left mr-1"
            :disabled="history?.length < 1"
            :title="$t('Back')"
          ></button>

          <button
            id="parent_btn"
            class="wheel-nav-btn btn fal fa-long-arrow-up mr-1"
            :disabled="currentLvl < 1"
            :title="$t('Go to parent element')"
          ></button>

          <button id="reset_btn" class="wheel-nav-btn btn fal fa-asterisk mr-2" :title="$t('Reset')"></button>

          <button
            v-if="true"
            id="redraw_btn"
            class="wheel-nav-btn btn fal fa-sync mr-2"
            :title="$t('Redraw')"
            @click="resetTime"
          ></button>

          <button
            v-if="false"
            id="filter_btn"
            class="btn"
            type="button"
            data-placement="top"
            data-html="true"
            data-toggle="popover"
            role="button"
            data-template='<div class="popover" role="tooltip"><div class="arrow"></div><span class="popover-header popover-header-custom"></span><div class="popover-body popover-body-scroll"></div></div>'
            data-container=".wheel__top-bar"
            :title="$t('Device groups')"
            href="#reset"
          >
            <span class="filter-btn-text">
              <v-svg src="filter" fill="#1757BD"></v-svg>
            </span>
          </button>
          <n-popover v-if="true" placement="bottom-end" trigger="click" raw :show-arrow="true">
            <template #trigger>
              <button id="filter_btn_next" class="btn ml-2" type="button">
                <span class="filter-btn-text">
                  <v-svg src="filter" fill="#1757BD"></v-svg>
                </span>
              </button>
            </template>
            <div class="wheel-groups-filter">
              <div class="wheel-groups-filter__header">{{ $t('Device groups') }}</div>
              <ul class="wheel-groups-filter__list">
                <li v-for="group in groupsItems">
                  <div :title="group.desc" class="checkbox">
                    <div class="check-group" :for="group.id" @click.prevent="switchGroups(group, $event)">
                      <icon size="16">
                        <checkbox-checked v-if="group.enabled"></checkbox-checked>
                        <checkbox v-else></checkbox>
                      </icon>
                      &nbsp;
                      <div>{{ group.name }}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </n-popover>
        </template>
      </wheel-graph>
    </template>
    <template #side>
      <!--      <div style='background-color: grey'>test-div</div>-->
      <wheel-desc :bulls="bulls" :desc="desc" @showDesc="showDesc"></wheel-desc>
    </template>
  </t-wheel-drawer>
  <teleport to="#modals-target">
    <select-subnet-modal
      :selectedSubnet="selectedSubnet?.name"
      :list="subnetsList"
      @select="doSubnetSelect"
    ></select-subnet-modal>
  </teleport>
  <events-counter-modal></events-counter-modal>
  <!--  </div>-->
</template>

<script>
import TWheelDrawer from '@/components/ui/TWheelDrawer.vue';
import WheelGraph from '@/components/wheel/WheelGraph.vue';
import JsonTree from '@/components/common/JsonTree.vue';
import WheelDesc from '@/components/wheel/WheelDesc.vue';
import SelectSubnetModal from '@/components/modals/SelectSubnetModal.vue';
import EventsCounterModal from '@/components/wheel/EventsCounterModal.vue';
import WheelBreadcrumbs from '@/components/wheel/WheelBreadcrumbs.vue';

import DateNano from '@/utils/format.date.time';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useWheel } from '@/stores/wheel';
import { useTpws } from '@/stores/tpws';
import { usePrefs } from '@/stores/prefs';
import { usePage } from '@/stores/page';
import { mapActions } from 'pinia';
// import * as d3 from 'd3';
import getSize from 'get-size';
import api from '@/services/api';
import { useGhosts } from '@/stores/ghosts';
import { useWheelEventsCounter } from '@/stores/wheelEventsCounter';
import { useWheelSession } from '@/stores/wheelSession';

import Checkbox from '@/components/icons/Checkbox.vue';
import SettingsCog from '@/components/icons/SettingsCog.vue';
import CaretUp from '@/components/icons/CaretUp.vue';
import CheckboxChecked from '@/components/icons/CheckboxChecked.vue';
import { Icon } from '@vicons/utils';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

// import { markRaw } from 'vue/dist/vue.esm-bundler';
import { markRaw } from '@/utils/wraps';

// import states from '@/utils/states';
// import dev from '@/utils/dev';

import { consts, nodeTypes, nodeLevels, graph } from './wheelVars';
import {htmlEncode, htmlDecode} from "@/utils/codec";

const to = (time) => new Promise((resolve) => setTimeout(() => resolve(true), time));

function getCircularReplacer() {
  const ancestors = [];
  return function (key, value) {
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    // `this` is the object that value is contained in,
    // i.e., its direct parent.
    while (ancestors.length > 0 && ancestors.at(-1) !== this) {
      ancestors.pop();
    }
    if (ancestors.includes(value)) {
      return '[Circular]';
    }
    ancestors.push(value);
    return value;
  };
}

var replaceCircular = function (val, cache) {
  cache = cache || new WeakSet();

  if (val && typeof val == 'object') {
    if (cache.has(val)) return '[Circular]';

    cache.add(val);

    var obj = Array.isArray(val) ? [] : {};
    for (var idx in val) {
      obj[idx] = replaceCircular(val[idx], cache);
    }

    cache.delete(val);
    return obj;
  }

  return val;
};

// let chart;

export default {
  name: 'wheel-page',
  components: {
    Icon,
    CaretUp,
    SettingsCog,
    Checkbox,
    CheckboxChecked,
    TWheelDrawer,
    WheelBreadcrumbs,
    WheelGraph,
    WheelDesc,
    SelectSubnetModal,
    EventsCounterModal,
    JsonTree,
    VueJsonPretty,
  },
  props: {},
  data: () => {
    return {
      subnetSelectorVisible: false,
      // selectedSubnet: undefined,
      currentSubnet: undefined,
      layoutOrder: false,
      collapsed: false,
      visibleTable: true,
      rejectCounter: 0,
      rejectLimit: 1,

      // WHEEL_CENTRED: false,

      LIVE_VIEW_MODE: false,
      livePulse: undefined,
      pulseIntervalMs: 1000,
      isAwaitEvents: false,

      currentLvl: undefined,
      initState: false,
      _forcedCursor: undefined,

      curDiagramType: undefined,

      currentLeftListPos: 0,
      totalLeftListNodes: 0,
      leftListMoveUpEnabled: undefined,
      leftListMoveDownEnabled: undefined,
      leftListSelection: undefined,
      currentRightListPos: 0,
      totalRightListNodes: 0,
      rightListMoveUpEnabled: undefined,
      rightListMoveDownEnabled: undefined,
      rightListSelection: undefined,
      conditions: undefined,
      data: undefined,
      dataReady: false,
      eJson: undefined,
      eJsonParsed: undefined,
      eJsonUpdate: undefined,
      subnets: [],
      subnetsUpdate: undefined,
      selectedSubnet: undefined,
      selectedSubnetUpdate: undefined,
      // selectedSubnetData: [],
      // selectedSubnetDataUpdate: [],
      root: undefined,
      rootData: undefined,
      nodes: undefined,
      links: undefined,
      hi: undefined,
      wi: undefined,
      rotationSelect: undefined,
      angle: 0,
      angle0: 0,

      limitQuarterAngleDeg: 60,
      eventsSpeed: '...',
      eventsSpeedInterval: undefined,
      ied: undefined,
      list: [],
      bulls: [],
      desc: [],
      nodeToSwitch: null,
      subToSwitch: null,
      // color: undefined,
      // cluster: undefined,
      // bundle: undefined,
      // diagonal: undefined,
      // line: undefined,
      calculateGroupArc: undefined,
      // groupLine: undefined,
      // groupArc: undefined,
      // borderScale: undefined,
      // hostsScale: undefined,
      // clientsScale: undefined,
      svg: undefined,
      chart: undefined,
      // link: undefined,
      // linkEnter: undefined,
      // group: undefined,

      // nodeCount: undefined,
      // nodeTextCount: undefined,
      // node: undefined,
      // nodeDescs: undefined,
      // ghostMarker: undefined,
      marker: undefined,
      // rotatable: undefined,
      diameter: undefined,
      radius: undefined,
      innerRadius: undefined,
      mainFields: undefined,
      interval1: undefined,
      groupsItems: [],
      statesIdx: 0,
      cursor: undefined,
      pointer: undefined,
      history: [],
    };
  },
  computed: {
    ...mapStores(useVisual, useWheel),
    ...mapStores(useGhosts),
    subnetsList() {
      const list = this.subnets?.map((el) => ({
        name: el.name,
        type: el.type,
        ieds: el.summary.ieds,
        gooses: el.summary.gooses,
        svs: el.summary.svs,
      }));
      return list || [];
    },
    ...mapStores(useTpws),
    ...mapStores(usePrefs),
    ...mapStores(usePage),
    ...mapStores(useWheelEventsCounter),
    ...mapStores(useWheelSession),
    currentProject() {
      return this.tpws.currentProject;
    },
    // subnets() {
    //   return this.eJson?.subnets || [];
    // },
    // selectedSubnet() {
    //   let subnet = this.subnets.find((item) => item.name == this.currentSubnet) || this.subnets[0];
    //   return markRaw(subnet);
    // },
    selectedSubnetData() {
      return this.selectedSubnet?.data || [];
    },
    isLive() {
      return this.tpws?.isLive || false;
    },
    isLivePage() {
      return this.$route.name === 'wheel-live-page';
    },
    isWheelLivePage() {
      return this.$route.name === 'wheel-live-page';
    },
    // url() {
    //   return this.eventsArchive
    //     ? `/projects/${this.currentProject}/archive?tstart=${this.eventsArchiveTS.tstart}&tend=${this.eventsArchiveTS.tend}`
    //     : `/projects/${this.currentProject}/events2`;
    // },
    headerVisible() {
      return this.eventsArchiveTS && !isNaN(this.eventsArchiveTS.tstart) && !isNaN(this.eventsArchiveTS.tend);
    },
    nanoTstart() {
      return new DateNano(this.eventsArchiveTS.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.eventsArchiveTS.tend).customFormat();
    },
    // isOpenWheel() {
    //   return window.location.pathname.includes('open/wheel');
    // },
  },
  created() {
    this.visual.showEventsCounterModal(false);
    // this.$store.commit('setIsOpenWheel', false)
    // this.$store.commit('setLiveModeOn', false)
  },
  mounted() {
    this.$router.isReady().then(() => {
      dev.log('$router Ready', this.$route);
      this.init();
      this.wheel.hideLastEventsModal();
    });
  },
  beforeDestroy() {
    dev.log('beforeDestroy');
  },
  beforeUnmount() {
    dev.log('beforeUnmount');
    if (this.livePulse) {
      dev.log('clear livePulse');
      clearInterval(this.livePulse);
    }
    if (this.interval1) {
      dev.log('clear interval1');
      clearInterval(this.interval1);
    }
  },
  methods: {
    ...mapActions(useVisual, ['toggleWheelCollapsed', 'showEventsCounterModal']),
    ...mapActions(useWheel, ['showLastEventsModal', 'showSelectSubnetModal']),
    b64_to_utf8(str) {
      return decodeURIComponent(escape(window.atob(str)));
    },
    makePathTree() {
      dev.log('pathTree this.subnetsList', this.subnetsList?.length);
      const rootList = [];
      const loopParents = (sn, el) => {
        const nearby = [];
        // dev.log('el.parent', el.parent);
        if (el?.parent) {
          if (el.parent.children?.length > 0) {
            el.parent.children.forEach((ch) => {
              // if (ch.subnet !== sn) {
              //   return;
              // }
              if (ch?.name === el?.name) {
                return;
              }
              if (ch.type.indexOf('_LABEL') >= 0) {
              }
              // dev.log('el.subnet == sn', ch, ch.subnet, sn);
              const child = {
                a: '1',
                name: ch.name,
                text: ch.text,
                desc: ch.desc,
                type: ch.type,
                disabled: ch.type.indexOf('_LABEL') >= 0 || ch._virtual,
                // orig: { ...markRaw(ch), parent: undefined },
              };
              nearby.push(child);
            });
          }
          loopParents(sn, el.parent);
        }
        if (el?.depth) {
          rootList[el?.depth] = {
            current: {
              a: '2',
              name: el.name,
              text: el.text,
              desc: el.desc,
              type: el.type,
              orig: { ...markRaw(el), parent: undefined },
            },
            nearby: nearby,
          };
        }
      };
      const levelZero = {};
      this.subnetsList?.forEach((sn) => {
        // dev.log('sn', sn);
        if (this.selectedSubnet?.name === sn.name) {
          levelZero['current'] = {
            a: '3',
            name: sn.name,
            text: sn.name,
            type: 'subnet',
          };
          this.cursor && loopParents(sn.name, this.cursor);
        } else {
          if (!levelZero.nearby) {
            levelZero.nearby = [];
          }
          levelZero.nearby.push({
            a: '4',
            name: sn.name,
            text: sn.name,
            type: 'subnet',
          });
        }
      });
      rootList[0] = levelZero;
      dev.log('pathTree cur', this.cursor);
      this.wheel.pathTree = markRaw(rootList) || [];
    },
    setEJson(data) {
      this.eJson = data;
      this.eJsonParsed = false;
      this.eJsonUpdate = Math.random();
    },
    setSubnets() {
      this.subnets = this.eJson?.subnets || [];
      this.subnetsUpdate = Math.random();
    },
    selectSubnet() {
      let subnet = this.subnets.find((item) => item.name == this.currentSubnet) || this.subnets[0];
      this.selectedSubnet = markRaw(subnet);
      this.selectedSubnetUpdate = Math.random();
    },
    selectedSubnetReady() {
      return new Promise(async (resolve) => {
        while (!this.selectedSubnet?.name) {
          dev.log('wait');
          await to(10);
        }
        resolve(true);
      });
    },
    cursorChildrenReady() {
      let cnt = 0;
      return new Promise(async (resolve) => {
        while (!this.cursor?.children && cnt < 20) {
          dev.log('wait', cnt);
          cnt++;
          await to(50);
        }
        resolve(true);
      });
    },
    waitDataReady() {
      let cnt = 0;
      return new Promise(async (resolve) => {
        while (!this.dataReady && cnt < 20) {
          dev.log('wait data', cnt);
          cnt++;
          await to(50);
        }
        resolve(true);
      });
    },
    setSelectedSubnetData() {},
    init() {
      dev.log('wheel.page init');
      if (this.$route.name == 'open-wheel-page') {
        this.wheel.isOpenWheel = true;
      } else {
        this.wheel.isOpenWheel = false;
      }
      if (this.$route.name == 'open-wheel-page' || this.$route.name == 'wheel-live-page') {
        this.wheel.liveModeOn = true;
      } else {
        this.wheel.liveModeOn = false;
      }
      $('#wheel').show();
      $('#right_sidebar').show();
      $('#footer').show();
      $('.wh-live').show();
      $('.wheel-navigation').css('display', 'flex');

      if (!this.$route.query?.node) {
        this.nodeToSwitch = null;
      }
      if (!this.nodeToSwitch) {
        this.nodeToSwitch = this.$route.query?.node || null;
      }
      if (!this.nodeToSwitch) {
        this.navReset();
      }
      if (!this.subToSwitch) {
        this.subToSwitch = this.$route.query?.sub || null;
      }
      dev.log(
        'init nodes',
        this.nodeToSwitch,
        this.subToSwitch,
        this.$route.query?.node,
        this.$route.query?.sub,
        this.cursor
      );
      this.$nextTick(() => {
        try {
          dev.log('wheel.page init end');

          const pg = this.Wheel();
          dev.log('wheel pg', this.nodeToSwitch, this.subToSwitch, pg);
        } catch (e) {
          console.error(e);
        }
      });
    },
    bcAction(e) {
      if (e.type === 'subnet') {
        this.doSubnetSelect(e.value);
      } else {
        this.doNodeSelect(e.value);
      }
      dev.log('bcAction', e);
    },
    switchGroups(group, e) {
      const subnet = this.selectedSubnet;
      const filter = subnet?.filters[0];
      group.enabled = !group.enabled;
      e.target.setAttribute('checked', group.enabled);
      dev.log('switch', group, e);
      filter.groups.forEach((g, i) => {
        dev.log('g', i, g);
        if (i === group.idx) {
          dev.log('togg');
          g.enabled = group.enabled;
          this.$nextTick(() => {
            this.buildChart(this.selectedSubnet);
          });
        }
      });
    },
    doSubnetSelect(selectedValue) {
      dev.log('doSubnetSelect', selectedValue);
      if (selectedValue) {
        this.currentSubnet = selectedValue;
        this.wheelSession.saveProp('subnet', this.selectedSubnet.name);
        this.cursor = undefined;
        // this.$nextTick(() => {
        dev.log('this.selectedSubnet', this.selectedSubnet);
        this.loadSubnet();
        // });
      }
    },
    doNodeSelect(selectedValue, back = false) {
      this.nodeToSwitch = selectedValue;
      this.subToSwitch = selectedValue?.subnet ? selectedValue.subnet : this.selectedSubnet.name;
      // const pg = this.Wheel(this.nodeToSwitch, this.subToSwitch);
      dev.log('doNodeSelect', this.subToSwitch, this.nodeToSwitch);
      this.cursorByNode(back);
    },
    openSubnetSelectorDialog() {
      this.showSelectSubnetModal();
    },
    loadSubnet() {
      dev.log('loadSubnet', this.selectedSubnet);
      // this.$nextTick(()=>{
      this.currentLvl = nodeLevels.IED_LEVEL;
      this.$nextTick(() => {
        this.initGroupsMenu();
        this.initBreadcrumbs();
        setTimeout(() => {
          this.buildChart(this.selectedSubnet);
          this.setStatus();
        }, 200);
        this.cursorByNode();
      });
      // })
    },
    onResize(s) {
      const size = getSize('#wheel .wh-page #chart');
      dev.log('wp onResize', s, size);
      this.hi = size?.height;
      // this.hi = this.hi > 600 ? this.hi : 600;
      this.wi = size?.width;
      // $('#chart').remove();
      // this.redrawSvg();
      // this.mouseovered(null);
      // this.loadWheelData();
      dev.log('resize subnet', this.selectedSubnet);
      if (this.selectedSubnet) {
        this.$nextTick(() => {
          this.goDefault();
          this.updateInfo();
          this.buildChart(this.selectedSubnet);
        });
      }
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        return '';
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    replaceLocation() {
      // const location =
      //   window.location.protocol +
      //   '//' +
      //   window.location.host +
      //   window.location.pathname +
      //   '?' +
      //   'sub=' +
      //   encodeURIComponent(this.subToSwitch) +
      //   '&' +
      //   'node=' +
      //   encodeURIComponent(this.nodeToSwitch);
      // if (window.location.href !== location) {
      //   // dev.log('location', location);
      //   window.history.replaceState('', document.title, location);
      // }
      // this.$router.replace({
      //   path: this.$router.currentRoute.value.path,
      //   query: { sub: this.cursor.subnet, node: this.cursor.name },
      // });
      const locQuery = this.cursor ? '?' + this.$qs.stringify({ sub: this.cursor.subnet, node: this.cursor.name }) : '';
      window.history.replaceState('', document.title, this.$router.currentRoute.value.path + locQuery);
      dev.log('this.$router.currentRoute', this.$router.currentRoute.value, this.$router);
      // window.history.pushState('', document.title, this.$router.currentRoute.value.href)
    },
    goDefault() {
      // const p = this.getParameterByName('p');
      // const location =
      //   window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + 'p=' + p;
      // if (window.location.href !== location) {
      //   // dev.log('location', location);
      //   // window.history.replaceState('', document.title, location);
      //   // if ($('#subnet_selector') === undefined) {
      //   //   $('<span/>', {
      //   //     id: 'subnet_selector',
      //   //     href: '#subnet_selector',
      //   //     class: 'btn fal fa-sitemap',
      //   //     title: this.$t('Subnets'),
      //   //   }).appendTo('#wheel #footer #controls .btn-group');
      //   //
      //   //   $('#subnet_selector').off();
      //   //   $('#subnet_selector').click(openSubnetSelectorDialog);
      //   // }
      //
      //   // dev.log('this.mainFields', this.mainFields);
      //   // $.ajax(this.mainFields);
      //   api[this.mainFields.type](this.mainFields.url, this.mainFields.data).then(this.mainFields.success);
      // }
    },
    i18(e) {
      if (e === 'Inputs') {
        return this.$t(e);
      }
      return e;
    },
    mouseovered(d, i, touched) {
      // dev.log('mouseovered', d, i, touched);
      graph.node.each((n) => {
        n.target = n.source = false;
      });
      if (touched) {
        graph.node.each((n) => {
          n.touched = n.name == d.name;
        });
      }
      const targetLinks = graph.link
        .filter((l) => {
          return (
            (l.target === d && !l.target.imports) ||
            (l.target === d && l.target.imports && l.target.imports.indexOf(l.source.name) < 0)
          );
        })
        .each((l) => {
          l.source.source = true;
        })
        .attr('stroke-dasharray', (_d) => {
          return _d.pathTotalLength + ' ' + _d.pathTotalLength;
        })
        .attr('stroke-dashoffset', (l) => {
          return l.pathTotalLength;
        })
        .classed('link--target', true);
      targetLinks
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attr('stroke-dashoffset', (l) => {
          return l.pathTotalLength * 2;
        });
      const sourceLinks = graph.link
        .filter((l) => {
          return (
            (l.source === d && !l.target.imports) ||
            (l.source === d && l.target.imports && l.target.imports.indexOf(l.source.name) < 0)
          );
        })
        .each((l) => {
          l.target.target = true;
        })
        .attr('stroke-dasharray', (_d) => {
          return _d.pathTotalLength + ' ' + _d.pathTotalLength;
        })
        .attr('stroke-dashoffset', (l) => {
          return l.pathTotalLength;
        })
        .classed('link--source', true);
      sourceLinks
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attr('stroke-dashoffset', (l) => {
          return l.pathTotalLength * 2;
        });
      const bothLinks = graph.link
        .filter((l) => {
          return (d === l.target || d === l.source) && l.target.imports && l.target.imports.indexOf(l.source.name) >= 0;
        })
        .each((l) => {
          (l.target.target = true), (l.source.source = true);
        })
        .classed('link--both', true)
        .attr('stroke-dasharray', (_d) => {
          return '0 ' + _d.pathTotalLength / 2 + ' 0 ' + _d.pathTotalLength / 2 + ' 0';
        })
        .attr('stroke-dashoffset', 0);
      bothLinks
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attr('stroke-dasharray', (_d) => {
          return _d.pathTotalLength / 2 + ' 0 0 0 ' + _d.pathTotalLength / 2;
        });
      graph.node
        .classed('node--target', (n) => {
          return n.target;
        })
        .classed('node--source', (n) => {
          return n.source;
        })
        .classed('node--both', (n) => {
          return n.source && n.target;
        })
        .classed('node--touched', (n) => {
          return n.touched;
        });
    },
    /*
  Build package hierarchy for D3 visualization from array of element, provided by TPS.
*/
    packageHierarchy(arr) {
      const _root = {
        name: '',
        allchildren: [],
      };
      const map = {
          '': _root,
        },
        pmap = {};
      arr.forEach((d) => {
        pmap[d.name] = d;
        d.allchildren = [];
      });

      /**
       * Recursively build pa_rent-child model of elements.
       * The function can be called with name parameter only, in this case it would select node from pmap as pmap[node].
       * Otherwise it shall attach node data to map[name]
       * It also constantly updates map object recursively
       * @param {string} name The name of the node in map/pmap object
       * @param {Object} data The data that shall be added to map for particular node name.
       * @returns {Object} The node object of selected name with defined data
       * @example node.parent = find(name.substring(0, i = name.lastIndexOf(OBJECT_DELIMITER)));
       * @example find(d.name, d);
       */

      const find = (name, _data) => {
        let _node = map[name];
        let i;
        // We shall add to map only those elements not yet present in the map
        if (!_node) {
          _node = map[name] = _data || pmap[name];
          // TODO: Looks like name.length is always TRUE. Have to figure out what is it used for.
          if (name.length) {
            let parentName = name.substring(0, (i = name.lastIndexOf(consts.OBJECT_DELIMITER)));
            _node.parent = find(parentName);
            _node.parent.allchildren.push(_node);
            const pos = i < 0 ? 0 : i + consts.OBJECT_DELIMITER.length;
            if (_node.type === 'GOOSE_FCDA' || _node.type === 'SV_FCDA') {
              _node.text = _node.text || _node.path + ' ' + _node.fc;
            } else {
              _node.text = _node.text || name.substring(pos);
            }
          } else {
            console.info('WARNING: Empty name');
          }
        }
        return _node;
      };
      arr.forEach((d) => {
        find(d.name, d);
      });

      map[''].map = map;
      return map[''];
    },
    wheelMouseUp() {
      this.rotationSelect = null;
      graph.node.classed('wh-rot-ts-disabled', false);
    },
    wheelMouseMove(d, i, n, k) {
      // dev.log('d, i, n, k', d, i, n, k);
      // dev.log('graph.chart', graph.chart[0][0]);
      const positiveAngle = (a) => {
        return (a + 360) % 360;
      };
      let coordinates = [0, 0];
      coordinates = d3.mouse(graph.chart[0][0]);
      const x = coordinates[0] - this.wi / 2;
      const y = coordinates[1] - this.hi / 2;
      const a = (Math.atan2(y, x) * 180) / Math.PI;

      if (this.rotationSelect) {
        // dev.log('coordinates', coordinates);

        this.angle = positiveAngle(a + 90 - this.rotationSelect.x);
        this.angle0 = positiveAngle(a + 90 - this.rotationSelect.x);
        this.transform();
      }
    },
    mouseouted(d, i, touched) {
      // dev.log('mouseouted', d, i, touched);
      /* В редких случаях событие mouseovered совпадает
       * с первой отрисовкой колеса. В таком случае прозрачность
       * не успевает анимироваться. Строка ниже - обход данного бага*/
      graph.linkEnter.style('stroke-opacity', 0.4);

      graph.link.classed('link--target', false).classed('link--source', false).classed('link--both', false);

      graph.node.classed('node--target', false).classed('node--source', false).classed('node--both', false);
    },
    mouseoutedL() {
      graph.link.classed('link--target', false).classed('link--source', false).classed('link--both', false);

      graph.node.classed('node--target', false).classed('node--source', false).classed('node--both', false);
    },
    wheelMouseDown(d) {
      // dev.log('wheelMouseDown', d);
      this.rotationSelect = d;
      graph.node.classed('wh-rot-ts-disabled', true);
    },
    transform() {
      const currentAngle = (d) => {
        return (d.x + this.angle) % 360;
      };

      const isInHideZone = (d) => {
        return (
          currentAngle(d) <= this.limitQuarterAngleDeg ||
          Math.abs(currentAngle(d) - 180) <= this.limitQuarterAngleDeg ||
          Math.abs(currentAngle(d) - 360) <= this.limitQuarterAngleDeg
        );
      };

      graph.svg.attr('transform', `translate(${this.wi / 2}, ${this.hi / 2}) rotate(${this.angle},0,0)`);
      graph.svg.attr('data-angle', `${this.angle}`);

      graph.node
        .filter((d) => d.type.indexOf('_LABEL') < 0 && d.type !== 'IED')
        .text((d) => {
          let text = d.text;
          if (
            (this.currentLvl === nodeLevels.IED_LEVEL || this.currentLvl === nodeLevels.MESSAGES_LEVEL) &&
            isInHideZone(d)
          ) {
            text =
              d.text.length > consts.NODE_TEXT_LENGTH_LIMIT
                ? d.text.substr(0, consts.NODE_TEXT_LENGTH_LIMIT) + '...'
                : d.text;
          }
          text =
            this.prefs.descriptionType == 'nameAndDesc'
              ? text + ' ' + (d.desc || '')
              : this.prefs.descriptionType == 'onlyDesc' && d.desc
              ? d.desc
              : text;
          return this.i18(text.trim());
        });
      graph.nodeDescs.text('');
      const descText = (d) => {
        let desc =
          this.prefs.descriptionType == 'onlyName'
            ? ''
            : d.desc
            ? d.desc
            : d.parent && d.parent.desc
            ? d.parent.desc
            : d.parent.parent && d.parent.parent.desc
            ? d.parent.parent.desc
            : '';
        if (d.isGhost) desc = this.prefs.descriptionType == 'onlyDesc' ? d.text + ' ' + d.desc : d.desc;
        if (
          desc &&
          (this.currentLvl === nodeLevels.IED_LEVEL || this.currentLvl === nodeLevels.MESSAGES_LEVEL) &&
          isInHideZone(d)
        ) {
          desc =
            this.prefs.descriptionType == 'onlyDesc' && desc.length > consts.NODE_TEXT_LENGTH_LIMIT
              ? desc
              : this.prefs.descriptionType == 'onlyDesc'
              ? desc
              : d.text.length >= consts.NODE_TEXT_LENGTH_LIMIT
              ? d.text
              : d.text.length + desc.length > consts.NODE_TEXT_LENGTH_LIMIT
              ? desc
              : desc;
        }

        return desc
          ? ' ' + desc.replace('Ghosts', this.$t('Ghosts'))
          : this.prefs.descriptionType == 'onlyDesc'
          ? d.text.replace('Ghosts', this.$t('Ghosts'))
          : '';
      };

      let width = this.radius - this.innerRadius - 65;
      // dev.log('width', width);
      const wrap = (cnt) => {
        var self = d3.select(cnt),
          textWidth = self.node().parentNode.parentNode.getComputedTextLength(), // Width of text in pixel.
          initialText = self.text(), // Initial text.
          textLength = initialText.length, // Length of text in characters.
          text = initialText,
          precision = 10, //textWidth / width,                // Adjustable precision.
          maxIterations = 40; // width;                      // Set iterations limit.

        let ang = parseFloat(d3.select(self.node().parentNode.parentNode).attr('data-angle'));
        let ang2 = parseFloat(graph.svg.attr('data-angle'));
        let angF = (ang + ang2) % 360;
        let rad = (angF * Math.PI) / 180;
        let sn = Math.sin(rad);
        let cs = Math.cos(rad);
        let xc, yc;
        if (this.wi * Math.abs(sn) < this.hi * Math.abs(cs)) {
          xc = Math.sign(cs) * (this.wi / 2);
          yc = Math.tan(rad) * xc;
        } else {
          yc = Math.sign(sn) * (this.hi / 2);
          xc = (1 / Math.tan(rad)) * yc;
        }
        let wid = Math.sqrt(xc * xc + yc * yc);
        let widm =
          wid -
          this.innerRadius -
          ((this.wheel.isOpenWheel || this.isWheelLivePage) && this.currentLvl === 0 ? 105 : 65);

        if (textWidth > widm) {
          while (maxIterations > 0 && text.length > 0 && Math.abs(widm - textWidth) > precision) {
            text =
              /*text.slice(0,-1); =*/ textWidth >= widm
                ? text.slice(0, -textLength * 0.15)
                : initialText.slice(0, textLength * 1.15);
            self.text(text + '...');
            textWidth = self.node().parentNode.parentNode.getComputedTextLength();
            textLength = text.length;
            maxIterations--;
          }
        } else {
          self.text(text);
        }
        // dev.log(width - textWidth);
      };

      // let desw = nodeDescs.append('tspan').classed('wheel-desc-wrapper', true);
      // let des_block = d3.select(nodeDescs)
      // dev.log('svg', svg);
      // dev.log('des_block', des_block);
      let des1 = graph.nodeDescs
        .append('tspan')
        .classed('wheel-desc-start', true)
        .text((d) => {
          const des = descText(d);
          let len = des.length - consts.NODE_TEXT_END_LENGTH;
          if (len < 0) {
            len = 0;
          }
          return des.substr(0, len);
        })
        .each(function () {
          wrap(this);
        });
      des1.append('title').text(descText);
      let des2 = graph.nodeDescs
        .append('tspan')
        .classed('wheel-desc-end', true)
        .text((d) => {
          const des = descText(d);
          let len = des.length - consts.NODE_TEXT_END_LENGTH;
          if (len < 0) {
            len = 0;
          }
          return des.substr(len);
        });
      des2.append('title').text(descText);

      graph.node
        .attr(
          'transform',
          (d) =>
            `rotate(${d.x - 90})translate(${
              (d.type.indexOf('_LABEL') >= 0 &&
                this.wheelEventsCounter.visiblity &&
                this.LIVE_VIEW_MODE &&
                this.currentLvl === nodeLevels.IED_LEVEL) ||
              (d.type === 'IED' &&
                this.wheelEventsCounter.visiblity &&
                this.LIVE_VIEW_MODE &&
                this.currentLvl === nodeLevels.IED_LEVEL)
                ? d.y + 45
                : d.y + 10
            },0)${(d.x + this.angle + 360) % 360 < 180 ? '' : 'rotate(180)'}`
        )
        .style('text-anchor', (d) => ((d.x + this.angle + 360) % 360 < 180 ? 'start' : 'end'));

      graph.nodeTextCount
        .style('text-anchor', (d) => ((d.x + this.angle + 360) % 360 < 180 ? 'start' : 'end'))
        .attr(
          'transform',
          (d) =>
            `rotate(${d.x - 90})translate(${d.y + 12},0)${(d.x + this.angle + 360) % 360 < 180 ? '' : 'rotate(180)'}`
        )
        .attr('data-angle', (d) => `${d.x - 90}`);
    },
    toggleChildren(d, cond) {
      // dev.log('toggleChildren')
      if (d.allchildren) {
        d.children = [];
        d.allchildren.forEach((c) => {
          if (cond(c)) {
            d.children.push(c);
          }
        });
      }
    },
    packageImports(_nodes, cond, field) {
      field = field || 'imports';
      const map = {},
        imports = [];
      _nodes.forEach((d) => {
        map[d.name] = d;
      });
      _nodes.forEach((d) => {
        if (d[field]) {
          d[field].forEach((i) => {
            if (cond(d, i)) {
              const target = map[i];
              imports.push({
                source: map[d.name],
                target,
              });
            }
          });
        }
      });
      return imports;
    },
    mouseoveredL(d) {
      graph.node.each((n) => {
        n.target = n.source = false;
      });
      const targetLinks = graph.link
        .filter(
          (l) =>
            (l.target === d && !l.target.imports) ||
            (l.target === d && l.target.imports && l.target.imports.indexOf(l.source.name) < 0)
        )
        .each((l) => (l.source.source = true))
        .attr('stroke-dasharray', (_d) => _d.pathTotalLength + ' ' + _d.pathTotalLength)
        .attr('stroke-dashoffset', (l) => l.pathTotalLength)
        .classed('link--target', true);
      targetLinks
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attr('stroke-dashoffset', (l) => l.pathTotalLength * 2);
      const sourceLinks = graph.link
        .filter(
          (l) =>
            (l.source === d && !l.target.imports) ||
            (l.source === d && l.target.imports && l.target.imports.indexOf(l.source.name) < 0)
        )
        .each((l) => (l.target.target = true))
        .attr('stroke-dasharray', (_d) => _d.pathTotalLength + ' ' + _d.pathTotalLength)
        .attr('stroke-dashoffset', (l) => l.pathTotalLength)
        .classed('link--source', true);
      sourceLinks
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attr('stroke-dashoffset', (l) => l.pathTotalLength * 2);
      graph.node.classed('node--target', (n) => n.target).classed('node--source', (n) => n.source);
    },
    removeVirtual(arr) {
      let i = arr.length;
      while (i--) {
        if (arr[i]._virtual) {
          arr.splice(i, 1);
        } else if (arr[i]._imports) {
          arr[i].imports = arr[i]._imports;
        } else if (arr[i]._lImports) {
          arr[i].lImports = arr[i]._lImports;
        }
      }
    },
    filter(subnet) {
      let _filter = subnet.filters.filter((f) => {
        return f.active;
      });
      _filter = _filter.length > 0 ? _filter[0] : null;
      if (_filter) {
        subnet.data.forEach((d) => {
          d.filtered = false;
        });
        //Disable all but ghost
        subnet.data.map((d) => {
          if (d.isGhost) {
            return;
          }
          d.filtered = true;
        });
        _filter.groups.forEach((g) => {
          if (g.enabled) {
            subnet.data.forEach((d) => {
              const nameParts = d.name.split(consts.OBJECT_DELIMITER);
              nameParts.forEach((p) => {
                if (g.lns.indexOf(p) >= 0) {
                  d.filtered = false;
                }
              });
            });
          }
        });
      }
    },
    updateListsFlags() {
      this.leftListMoveUpEnabled = this.currentLeftListPos > 0;
      this.leftListMoveDownEnabled = this.currentLeftListPos + consts.LIST_NODES_LIMIT < this.totalLeftListNodes;
      this.rightListMoveUpEnabled = this.currentRightListPos > 0;
      this.rightListMoveDownEnabled = this.currentRightListPos + consts.LIST_NODES_LIMIT < this.totalRightListNodes;
    },
    copyImports(d) {
      if (d.imports) {
        d._imports = d.imports.slice();
      }
      if (d.lImports) {
        d._lImports = d.lImports.slice();
      }
    },
    updateGhosts(gg, gs, gi, data, redrawCallback) {
      // TODO: Better compare
      gg = gg.filter((d) => {
        return d.current[0].status.alive;
      });
      gs = gs.filter((d) => {
        return d.current[0].status.alive;
      });
      const ghosts = data.filter((d) => {
        return d.isGhost;
      });
      let ghostCbs;
      ghosts.map((d) => {
        d.disabled = true;
      });
      if (gg.length > 0) {
        ghosts
          .filter((d) => {
            return (
              (d.type === 'IED' && d.name === 'GOOSE Ghosts') ||
              (d.type === 'IED_LABEL' && d.parent.name === 'GOOSE Ghosts')
            );
          })
          .map((d) => {
            d.disabled = false;
          });
        ghostCbs = ghosts.filter((d) => {
          return d.type === 'GOOSE_CTRL' && d.parent.name === 'GOOSE Ghosts';
        });
        gg.forEach((d, i) => {
          if (!ghostCbs[i]) {
            return;
          }
          ghostCbs[i].gse.mac = d.dstMac;
          ghostCbs[i].gse.appid = d.appId;
          ghostCbs[i].gse.vlanId = d.vlan ? d.vlan.id : '      ';
          ghostCbs[i].gse.vlanPriority = d.vlan ? d.vlan.priority : '      ';
          ghostCbs[i].goid = d.goId;
          ghostCbs[i].gse.minTime = ghostCbs[i].gse.maxTime = d.current[0].timeAllowedToLive;
          ghostCbs[i].disabled = false;
        });
      }
      if (gs.length > 0) {
        ghosts
          .filter((d) => {
            return (
              (d.type === 'IED' && d.name === 'SV Ghosts') || (d.type === 'IED_LABEL' && d.parent.name === 'SV Ghosts')
            );
          })
          .map((d) => {
            d.disabled = false;
          });
        ghostCbs = ghosts.filter((d) => {
          return d.type === 'SV_CTRL' && d.parent.name === 'SV Ghosts';
        });
        gs.forEach((d, i) => {
          if (!ghostCbs[i]) {
            return;
          }
          ghostCbs[i].smv.mac = d.dstMac;
          ghostCbs[i].smv.appid = d.appId;
          ghostCbs[i].smv.vlanId = d.vlan ? d.vlan.id : '      ';
          ghostCbs[i].smv.vlanPriority = d.vlan ? d.vlan.priority : '      ';
          ghostCbs[i].svid = d.svId;
          // TODO: Other parameters
          ghostCbs[i].disabled = false;
        });
      }
      if (redrawCallback) {
        redrawCallback();
      }
    },
    navHistoryGoBack() {
      // this.nodeToSwitch = null;
      // this.goDefault();
      if (this.history.length) {
        const prev_cursor = this.history[this.history.length - 1].cursor;
        dev.log('prev cur', prev_cursor);
        // this.cursor = markRaw(prev_cursor);
        let localCursor = markRaw(prev_cursor);
        this.currentLeftListPos = 0;
        this.currentRightListPos = 0;
        this.pointer = null;
        this.currentLvl = this.history[this.history.length - 1].level;
        // this.updateInfo(this.cursor);
        this.history.pop();
        // this.cursor = localCursor;
        this.gotoNode(localCursor, this.currentLvl);
      }
    },
    navLevelUp() {
      // this.goDefault();
      dev.log('this.cursor && this.cursor.parent', this.cursor, this.cursor.parent);
      if (this.cursor && this.cursor.parent) {
        dev.log('cur', this.cursor, this.cursor.parent);
        // this.history.push({
        //   cursor: this.cursor,
        //   level: this.currentLvl,
        // });
        if (this.cursor) this.recNode(this.cursor, this.currentLvl, this.cursor.subnet);
        if (this.cursor.parent.name == '' && this.cursor.parent.depth == 0) {
          dev.log('go 0');
          this.subToSwitch = this.selectedSubnet?.name;
          this.nodeToSwitch = undefined;
          this.cursor = null;
          this.currentLvl = 0;
          dev.log('sub', this.subToSwitch);
          this.doSubnetSelect(this.subToSwitch);
        } else {
          this.cursor = markRaw(this.cursor.parent);
          this.currentLvl--;
          this.gotoNode(this.cursor, this.currentLvl);
          // this.doNodeSelect(this.nodeToSwitch);
        }
      }
    },
    navReset() {
      dev.log('navReset', this.cursor, this.history);
      if (this.cursor) this.recNode(this.cursor, this.currentLvl, this.cursor.subnet);
      this.nodeToSwitch = null;
      this.goDefault();
      this.currentLeftListPos = 0;
      this.currentRightListPos = 0;
      this.currentLvl = nodeLevels.IED_LEVEL;
      // this.history = [];
      this.angle = 0;
      this.angle0 = 0;
      this.cursor = this.pointer = null;

      // this.$nextTick(() => {
      //   if (this.selectedSubnet) {
      //     this.updateInfo();
      //     this.buildChart(this.selectedSubnet);
      //   }
      // });
    },
    backToNode(d, lvl, subnet) {
      dev.log('do backToNode', d, lvl, subnet);
      this.currentLeftListPos = 0;
      this.currentRightListPos = 0;
      this.currentLvl = lvl;
      this.cursor = d.type === 'GOOSE_DATA' ? markRaw(d.parent) : markRaw(d);
      // this.$nextTick(() => {
      //   this.updateInfo(this.cursor);
      //   dev.log('backToNode', this.cursor, this.selectedSubnet, subnet);
      //   // this.selectedSubnet = subnet;
      //
      //   this.buildChart(this.selectedSubnet);
      // });
    },
    recNode(d, lvl, subnet) {
      if (this.initState) {
        dev.log('recNode d', d);
        this.history.push({
          cursor: markRaw(d),
          subnet: subnet ? subnet : this.selectedSubnet.name,
          level: lvl ? lvl : this.currentLvl,
        });
      }
    },
    gotoNode(d, lvl) {
      dev.log('do gotoNode', d, lvl);
      this.initState = true;
      const sd = d?.type === 'GOOSE_DATA' ? d.parent : d;
      this.currentLeftListPos = 0;
      this.currentRightListPos = 0;
      this.currentLvl = lvl;
      this.cursor = markRaw(sd);
      dev.log('this.cursor gotoNode', this.cursor);
      dev.log('gotoNode this.selectedSubnet', this.selectedSubnet);
    },
    onChangeCursor() {
      dev.log('this.cursor11', this.cursor, this.cursor?.children);

      dev.log('gotoNode', this.cursor, this.cursor?.children, this.selectedSubnet);
      // this.selectedSubnet = subnet;

      this.buildChart(this.selectedSubnet);
      this.updateInfo(this.cursor);
    },
    linkLists() {
      this.rightListSelection.forEach(this.copyImports);
      this.leftListSelection.forEach((l) => {
        this.rightListSelection.forEach((r) => {
          if (!r._virtual && !l._virtual) {
            return;
          } else if (!r._virtual && l._virtual) {
            if (r.type.indexOf('_LABEL') >= 0 || l.type.indexOf('_LABEL') >= 0) {
              if (l.lns.indexOf(r.name) >= 0) {
                r.lImports.push(l.name);
              }
            } else {
              if (l.lns.indexOf(r.name) >= 0) {
                r.imports.push(l.name);
              }
            }
          } else if (r._virtual) {
            if (
              l.lns.some((ln) => {
                return r._virtual.names.indexOf(ln) >= 0;
              })
            ) {
              if (r.type.indexOf('_LABEL') >= 0 || l.type.indexOf('_LABEL') >= 0) {
                r.lImports = r.lImports || [];
                r.lImports.push(l.name);
              } else {
                r.imports = r.imports || [];
                r.imports.push(l.name);
              }
            }
          }
        });
      });
    },
    updateRightListSelection(ttlCondition) {
      this.totalRightListNodes = 0;
      this.addVirtualToRightList(ttlCondition);
      const ttl = this.data.filter(ttlCondition);
      let ix = 1;
      ttl.map((_h) => {
        if (!_h._virtual) {
          _h._ix = ix++;
          this.totalRightListNodes++;
        }
      });
      this.rightListSelection = ttl.filter((d) => {
        return (
          (d._virtual && d._virtual.side === 'right') ||
          (d._ix - 1 >= this.currentRightListPos && d._ix - 1 < consts.LIST_NODES_LIMIT + this.currentRightListPos)
        );
      });
    },
    updateLeftListSelection(ttlCondition) {
      this.totalLeftListNodes = 0;
      this.addVirtualToLeftList(ttlCondition);
      const ttl = this.data.filter(ttlCondition);
      let ix = 1;
      ttl.map((_h) => {
        if (!_h._virtual) {
          _h._ix = ix++;
          this.totalLeftListNodes++;
        }
      });
      this.leftListSelection = ttl.filter((d) => {
        return (
          (d._virtual && d._virtual.side === 'left') ||
          (d._ix - 1 >= this.currentLeftListPos && d._ix - 1 < consts.LIST_NODES_LIMIT + this.currentLeftListPos)
        );
      });
    },
    drawLists() {
      if (!this._forcedCursor?.type) {
        return;
      }
      $('.events-speed-container').addClass('d-none');
      $('#chart').on('mousewheel', (event, deltaY = event.deltaY, pageX) => {
        pageX = pageX ? $('#chart').width() * pageX : event.pageX;

        const moveUp = deltaY > 0;
        const cursorOnRight = ($('#chart').width() * 2) / 3 - pageX < 0;

        if (moveUp) {
          if (cursorOnRight) {
            this.currentRightListPos = this.rightListMoveUpEnabled ? this.currentRightListPos - 1 : 0;
          } else {
            this.currentLeftListPos = this.leftListMoveUpEnabled ? this.currentLeftListPos - 1 : 0;
          }
        } else {
          if (cursorOnRight) {
            this.currentRightListPos = this.rightListMoveDownEnabled
              ? this.currentRightListPos + 1
              : this.totalRightListNodes - consts.LIST_NODES_LIMIT;
          } else {
            this.currentLeftListPos = this.leftListMoveDownEnabled
              ? this.currentLeftListPos + 1
              : this.totalLeftListNodes - consts.LIST_NODES_LIMIT;
          }
        }
        this.buildChart(this.selectedSubnet);
      });

      const hosts = this.nodes.filter((d) => this.conditions.display(d) && this.isCursorsChild(d));

      const clients = this.nodes.filter((d) => this.conditions.display(d) && !this.isCursorsChild(d));

      const isInputs = this._forcedCursor?.type === 'INPUTS';

      graph.clientsScale
        .range([
          -graph.borderScale(clients.length > consts.LIST_NODES_LIMIT ? consts.LIST_NODES_LIMIT : clients.length),
          graph.borderScale(clients.length > consts.LIST_NODES_LIMIT ? consts.LIST_NODES_LIMIT : clients.length),
        ])
        .domain([0, clients.length - 1]);

      graph.hostsScale
        .range([
          -graph.borderScale(hosts.length > consts.LIST_NODES_LIMIT ? consts.LIST_NODES_LIMIT : hosts.length),
          graph.borderScale(hosts.length > consts.LIST_NODES_LIMIT ? consts.LIST_NODES_LIMIT : hosts.length),
        ])
        .domain([0, hosts.length - 1]);

      clients.map((d, i) => {
        d.x = isInputs ? -this.innerRadius : this.innerRadius;
        d.y = graph.clientsScale(i);
        d.left = isInputs ? false : true;
      });

      hosts.map((d, i) => {
        d.x = isInputs ? this.innerRadius : -this.innerRadius;
        d.y = graph.hostsScale(i);
        d.left = isInputs ? true : false;
      });

      graph.link = graph.link.data(graph.bundle(this.links.filter((l) => l.source && l.target)), this.linkName);

      graph.link.enter().append('path').attr('class', 'link');

      graph.link
        .each((d) => {
          (d.source = d[0]), (d.target = d[d.length - 1]);
        })
        .style('stroke-opacity', (d) => {
          if (d.source._virtual || d.target._virtual) {
            return 0.2;
          } else {
            return 0.6;
          }
        })
        .attr('d', (d) => {
          const s = {
            x: d.source.y,
            y: d.source.x,
          };
          const t = {
            x: d.target.y,
            y: d.target.x,
          };
          return graph.diagonal({
            source: s,
            target: t,
          });
        })
        .each(function (d) {
          d.pathTotalLength = this.getTotalLength();
        });
      graph.link.classed('link--target', false).classed('link--source', false).classed('link--both', false);
      graph.link
        .classed(
          'link-sv',
          (d) =>
            (d.target.type === 'IED' && d.target._linkType.indexOf('sv') >= 0) || d.target.type.indexOf('SV_') === 0
        )

        .classed(
          'link-goose',
          (d) =>
            (d.target.type === 'IED' && d.target._linkType.indexOf('goose') >= 0) ||
            d.target.type.indexOf('GOOSE_') === 0
        );

      graph.link.exit().remove();
      graph.node = graph.node.data(this.nodes.filter(this.conditions.display), this.name);
      graph.nodeCount = graph.nodeCount.data(this.nodes.filter(this.conditions.display), this.name);
      graph.nodeTextCount = graph.nodeTextCount.data(this.nodes.filter(this.conditions.display), this.name);
      const cutName = (name) => {
        //обрезка имени устройства, чтобы оно не выходило за пределы видимости
        if ($(window).width() < 1410) {
          const lengthLimit =
            $(window).width() > 1290 ? consts.NODE_TEXT_LENGTH_LIMIT + 5 : consts.NODE_TEXT_LENGTH_LIMIT;
          name =
            name.length < lengthLimit
              ? name
              : this.prefs.descriptionType == 'onlyName'
              ? name.substr(0, lengthLimit) + '...'
              : name.substr(0, lengthLimit);
        }
        return name;
      };
      const cutDesc = (desc, d) => {
        //обрезка описания устройства, чтобы оно не выходило за пределы видимости
        const lengthLimit =
          $(window).width() >= 1850
            ? consts.NODE_TEXT_LENGTH_LIMIT + 50
            : $(window).width() >= 1690
            ? consts.NODE_TEXT_LENGTH_LIMIT + 30
            : $(window).width() >= 1590
            ? consts.NODE_TEXT_LENGTH_LIMIT + 20
            : $(window).width() >= 1490
            ? consts.NODE_TEXT_LENGTH_LIMIT + 10
            : consts.NODE_TEXT_LENGTH_LIMIT;
        if (desc && this.prefs.descriptionType !== 'onlyDesc') {
          desc = d.text.length + desc.length > lengthLimit ? desc.substr(0, lengthLimit - d.text.length) + '...' : desc;
        } else if (desc && this.prefs.descriptionType === 'onlyDesc') {
          desc = desc.length > lengthLimit + 10 ? desc.substr(0, lengthLimit + 10) + '...' : desc;
        }
        return desc;
      };
      const wraph = (cnt) => {
        var self = d3.select(cnt);
        // dev.log(self.node().parentNode.parentNode);
        var textWidth = self.node().parentNode.parentNode.getComputedTextLength(), // Width of text in pixel.
          initialText = self.text(), // Initial text.
          textLength = initialText.length, // Length of text in characters.
          text = initialText,
          precision = 10, //textWidth / width,                // Adjustable precision.
          maxIterations = 100; // width;                      // Set iterations limit.

        let x = parseFloat(d3.select(self.node().parentNode.parentNode).attr('data-x'));
        let s = 'true' == d3.select(self.node().parentNode.parentNode).attr('data-s');
        let ww = $('.wh-page').eq(1).width();
        let wwh = ww / 2;
        let www;
        if (!s) {
          // dev.log(wwh + x, ww, wwh, x);
          www = wwh + x - consts.EDGE_PADDING;
        } else {
          www = ww - (wwh + x) - consts.EDGE_PADDING;
        }

        // dev.log('calcs', s, www, Math.ceil(x), Math.ceil(s));
        // dev.log('textWidth0', textWidth, initialText, d3.select(self.node().parentNode.parentNode).text());

        if (textWidth > www) {
          while (maxIterations > 0 && text.length > 0 && Math.abs(www - textWidth) > precision) {
            text =
              /*text.slice(0,-1); =*/ textWidth >= www
                ? text.slice(0, -textLength * 0.15)
                : initialText.slice(0, textLength * 1.15);
            self.text(text + '...');
            textWidth = self.node().parentNode.parentNode.getComputedTextLength();
            // dev.log('textWidth', textWidth);
            textLength = text.length;
            maxIterations--;
          }
        } else {
          self.text(text);
        }
        // dev.log(width - textWidth);
      };

      let nodeEnter = graph.node
        .enter()
        .append('text')
        .attr('class', 'node')
        .attr('typ1', (d) => {
          return d.type;
        })
        .classed(
          'ied-label',
          (d) =>
            this.prefs.descriptionType != 'onlyDesc' ||
            d.type === 'IED' ||
            (d.type.indexOf('_LABEL') >= 0 && this.prefs.descriptionType != 'onlyDesc')
        )
        .classed('no-linked', (d) => d.type.indexOf('_LABEL') < 0 && d.lns && d.lns.length < 1)
        .classed('wh-disabled', (d) => d.type.indexOf('_LABEL') >= 0 || d._virtual)
        .classed('wh-virtual', (d) => d._virtual)
        .classed('node--pointer', false)
        .style('opacity', '0')
        .attr('dy', '.31em');

      graph.node.style('opacity', '1').text((d) => {
        const _name =
          (d._ix ? d._ix + '. ' : '') +
          (this.prefs.descriptionType == 'onlyDesc'
            ? ''
            : d.text.replace(/Inputs/, this.$t('Inputs')).replace('not bound', this.$t('not bound')));
        return cutName(_name);
      });
      let nDesc = graph.node.append('tspan').classed('wheel-desc-limited', true);
      // .text((d) => {
      //   const desc = this.getDescNode(d);
      //   return desc ? ' ' + cutDesc(desc, d) : '';
      // });

      graph.node
        .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')')
        .attr('data-x', (d) => d.x)
        .attr('data-s', (d) => d.left)
        .style('text-anchor', (d) => (!d.left ? 'end' : 'start'))
        .attr('dx', (d) => (!d.left ? '-1em' : '1em'))
        .on('mouseover', (d, i) => {
          this.mouseoveredL(d, i);
          this.updateInfo(d);
        })
        .on('mouseout', (d, i) => {
          this.mouseoutedL(d, i);
          this.updateInfo(this.cursor);
        })
        .on('click', (d) => {
          this.nodeClick(d);
        });

      graph.node.classed('node--target', false).classed('node--source', false).classed('node--both', false);
      graph.node.exit().remove();

      let des1 = nDesc
        .append('tspan')
        .classed('wheel-desc-start', true)
        .text((d) => {
          const des = this.getDescNode(d);
          let len = des.length - consts.NODE_TEXT_END_LENGTH;
          if (len < 0) {
            len = 0;
          }
          return des.substr(0, len);
        })
        .each(function () {
          wraph(this);
        });
      des1.append('title').text(this.getDescNode);
      let des2 = nDesc
        .append('tspan')
        .classed('wheel-desc-end', true)
        .text((d) => {
          const des = this.getDescNode(d);
          let len = des.length - consts.NODE_TEXT_END_LENGTH;
          if (len < 0) {
            len = 0;
          }
          return des.substr(len);
        });
      des2.append('title').text(this.getDescNode);

      d3.selectAll('.eventsCount').remove();

      // dev.log('arrow', this.pointer, this.pointer.name, this.cursor.name, this.pointer.name !== this.cursor.name)

      graph.arrowPointer = graph.arrowPointer.data(
        this.nodes.filter((d) => d.name === this.pointer.name),
        this.name
      );
      // dev.log('draw arrow', graph.arrowPointer)
      const arrowPointerEnter = graph.arrowPointer.enter().append('path');
      if (this.pointer && this.pointer.name !== this.cursor.name) {
        arrowPointerEnter.attr('fill', 'red');
      } else {
        arrowPointerEnter.style('opacity', '0');
      }
      arrowPointerEnter.classed('arrow-pointer', true);
      graph.arrowPointer.attr('d', (d) =>
        d !== this.cursor
          ? 'M ' + d.x + ' ' + (d.y - 9) + ' l -4 -8 l 8 0 z'
          : 'M ' + d.x + ' ' + (d.y + 7) + ' l 4 8 l -8 0 z'
      );

      graph.arrowPointer.exit().remove();

      graph.marker = graph.marker.data(this.nodes.filter((d) => this.conditions.display(d) && !d._virtual));
      const markerEnter = graph.marker
        .enter()
        .append('circle')
        .attr('y', (e) => e.y - 6);

      graph.marker.attr('r', (d) => (d.type.indexOf('_LABEL') >= 0 || d.type === 'IED' ? 6 : 4));
      graph.marker
        .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')')
        .style('fill', (d) => (d.parent.parent.parent.color ? d.parent.parent.parent.color : d.parent.parent.color));
      graph.marker.on('mousedown', null);
      graph.marker.exit().remove();

      graph.arrowUp = graph.arrowUp.data(
        this.nodes.filter((d) => d._virtual && d._virtual.pos === 'upper'),
        this.name
      );
      const arrowUpEnter = graph.arrowUp
        .enter()
        .append('path')
        .attr('d', (d) => 'M ' + d.x + ' ' + (d.y - 6) + ' l 6 12 l -12 0 z')
        .on('click', (e) => $('#chart').triggerHandler('mousewheel', [1, e.x]));
      graph.arrowUp.style('fill', (d) =>
        d.parent.parent.parent.color ? d.parent.parent.parent.color : d.parent.parent.color
      );
      graph.arrowUp.exit().remove();
      graph.arrowDown = graph.arrowDown.data(
        this.nodes.filter((d) => d._virtual && d._virtual.pos === 'lower'),
        this.name
      );
      const arrowDownEnter = graph.arrowDown
        .enter()
        .append('path')
        .attr('d', (d) => 'M ' + d.x + ' ' + (d.y + 6) + ' l -6 -12 l 12 0 z')
        .on('click', (e) => $('#chart').triggerHandler('mousewheel', [-1, e.x]));
      graph.arrowDown.style('fill', (d) =>
        d.parent.parent.parent.color ? d.parent.parent.parent.color : d.parent.parent.color
      );
      graph.arrowDown.exit().remove();

      graph.group = graph.group.data(
        this.nodes.filter(
          (d) =>
            (((d.type === 'GOOSE_CTRL' || d.type === 'SV_CTRL') && this.cursor.type !== 'INPUTS_DATA') ||
              ((d.type === 'GOOSE_FCDA' || d.type === 'SV_FCDA') &&
                (this.cursor.type === 'INPUTS_DATA' ||
                  this.cursor.type === 'GOOSE_FCDA' ||
                  this.cursor.type === 'SV_FCDA')) ||
              d.type === 'INPUTS') &&
            d.children
        ),
        this.name
      );
      const transformA = () => {
        graph.svg.attr('transform', `translate(${this.wi / 2}, ${this.hi / 2}) rotate(${this.angle}, 0, 0)`);
      };
      const groupEnter = graph.group
        .enter()
        .append('path')
        .style('stroke', (d) => (d.parent.parent.color ? d.parent.parent.color : d.parent.color))
        .attr('stroke-width', 0.3);
      graph.group.attr('d', (d) => graph.groupLine(d.children));

      graph.group.exit().remove();

      this.angle = 0;
      transformA();
    },
    name(d) {
      return this.selectedSubnet.timestamp + '_' + this.curDiagramType + '_' + d.name;
    },
    linkName(d) {
      return this.selectedSubnet.timestamp + '_' + this.curDiagramType + '_' + d[0].name + d[d.length - 1].name;
    },
    drawWheel() {
      if (this.currentLvl === nodeLevels.IED_LEVEL || this.currentLvl === nodeLevels.MESSAGES_LEVEL) {
        graph.rotatable.classed('wh-rotateable', true);
        d3.select('#chart').on('mouseup', this.wheelMouseUp);
        d3.select('#chart').on('touchend', this.wheelMouseUp);
        d3.select('#chart').on('mousemove', this.wheelMouseMove);
        d3.select('#chart').on('touchmove', this.wheelMouseMove);
      }
      if (this.currentLvl === nodeLevels.MESSAGES_LEVEL && this.LIVE_VIEW_MODE) {
        $('.events-speed-container').removeClass('d-none');
      } else {
        $('.events-speed-container').addClass('d-none');
      }
      graph.link = graph.link.data(graph.bundle(this.links.filter((l) => l.source && l.target)), this.linkName);

      graph.link.style('stroke-opacity', 0.4);
      graph.linkEnter = graph.link.enter().append('path').attr('class', 'link').style('stroke-opacity', 0);
      graph.link
        .attr('d', graph.line)
        .each(function (d) {
          (d.pathTotalLength = this.getTotalLength()), (d.source = d[0]), (d.target = d[d.length - 1]);
        })
        .attr('stroke-dasharray', (d) => d.pathTotalLength + ' ' + d.pathTotalLength);
      graph.link
        .classed(
          'link-sv',
          (d) =>
            (d.target.type === 'IED' && d.target._linkType.indexOf('sv') >= 0) || d.target.type.indexOf('SV_') === 0
        )
        .classed(
          'link-goose',
          (d) =>
            (d.target.type === 'IED' && d.target._linkType.indexOf('goose') >= 0) ||
            d.target.type.indexOf('GOOSE_') === 0
        );
      graph.linkEnter.transition().duration(consts.ANIMATION_DURATION).style('stroke-opacity', 0.4);
      graph.link.exit().remove();

      // SelectAll
      graph.node = graph.node.data(this.nodes.filter(this.conditions.display), this.name);
      graph.nodeCount = graph.nodeCount.data(this.nodes.filter(this.conditions.display), this.name);
      graph.nodeTextCount = graph.nodeTextCount.data(this.nodes.filter(this.conditions.display), this.name);
      graph.node.style('opacity', '1');
      const nodeEnter = graph.node
        .enter()
        .append('text')
        .attr('class', 'node')
        .attr('typ2', (d) => {
          return d.type;
        })
        .classed('ied-label', (d) => d.type.indexOf('_LABEL') >= 0 || d.type === 'IED')
        .classed('no-linked', (d) => !d.linked && d.type.indexOf('_LABEL') < 0 && d.type !== 'IED')
        .classed('wh-disabled', (d) => d.type.indexOf('_LABEL') >= 0)
        .style('opacity', '0')
        .attr('dy', '.31em')
        .style('text-anchor', (d) => (d.x < 180 ? 'start' : 'end'));
      nodeEnter.transition().duration(consts.ANIMATION_DURATION).style('opacity', '1');
      graph.node.text((d) => {
        return this.prefs.descriptionType == 'onlyDesc' ? '' : this.$t(d.text);
      });
      graph.nodeDescs = graph.node
        .filter((d) => d.type === 'IED' || d.type.indexOf('_LABEL') >= 0)
        .append('tspan')
        .classed('wheel-desc-limited', true);
      if (this.visual.isTouch) {
        dev.log('touch assign');
        graph.node
          .classed('node--pointer', false)
          .on('touchstart', (d, i) => {
            if (d.touched) {
              this.nodeClickW(d);
            } else {
              this.mouseouted(d, i, true);
              this.mouseovered(d, i, true);
              this.updateInfo(d);
            }
          })
          .on('mouseover', (d, i) => {
            this.mouseovered(d, i);
            this.updateInfo(d);
          })
          .on('mouseout', (d, i) => {
            this.mouseouted(d, i);
            this.updateInfo(this.cursor);
          })
          .on('click', (d) => {
            this.nodeClickW(d);
          });
      } else {
        // dev.log('mouse assign');
        graph.node
          .classed('node--pointer', false)
          .on('mouseover', (d, i) => {
            this.mouseovered(d, i);
            this.updateInfo(d);
          })
          .on('mouseout', (d, i) => {
            this.mouseouted(d, i);
            this.updateInfo(this.cursor);
          })
          .on('click', (d) => {
            this.nodeClickW(d);
          });
      }
      graph.node
        .attr('transform', (d) => `rotate(${d.x - 90})translate(${d.y + 10},0)${d.x < 180 ? '' : 'rotate(180)'}`)
        .attr('data-angle', (d) => `${d.x - 90}`);
      graph.node.exit().remove();

      if (this.isWheelLivePage) {
        let nodeCountEnter = graph.nodeCount.enter().append('rect').style('opacity', '0');
        graph.nodeCount
          .filter((d) => d.type === 'IED' || d.type.indexOf('_LABEL') >= 0)
          .attr({ width: '30', height: '15', rx: '3', ry: '3' })
          .attr('dy', '.31em')
          .style('stroke', '#475579')
          .style('fill', 'white')
          .style('visibility', this.wheelEventsCounter.visiblity ? 'visible' : 'hidden')
          .classed('eventsCount', true)
          .classed('eventsCountBadge', true)
          .attr('transform', (d) => `rotate(${d.x - 89})translate(${d.y + 10},-13)`);
        nodeCountEnter
          .append('svg:title')
          .classed('eventsCountTitle', true)
          .text(() => this.$t('Number of events for the period') + ': ' + this.wheelEventsCounter?.timeString);

        graph.nodeCount.transition().duration(consts.ANIMATION_DURATION).style('opacity', '1');
        graph.nodeCount.exit().remove();

        let nodeCountTextEnter = graph.nodeTextCount
          .enter()
          .append('text')
          .style('opacity', '0')
          .style('fill', '#475579');
        graph.nodeTextCount
          .filter((d) => d.type === 'IED' || d.type.indexOf('_LABEL') >= 0)
          .style('fill', '#475579')
          .style('font-size', '11')
          .attr('dy', '.31em')
          .style('text-anchor', (d) => (d.x < 180 ? 'start' : 'end'))
          .classed('eventsCount', true)
          .classed('eventsCountText', true)
          .style('visibility', this.wheelEventsCounter.visiblity ? 'visible' : 'hidden')

          .attr('transform', (d) => `rotate(${d.x - 90})translate(${d.y + 12},0)${d.x < 180 ? '' : 'rotate(180)'}`);
        graph.nodeTextCount.transition().duration(consts.ANIMATION_DURATION).style('opacity', '1');
        graph.nodeTextCount.exit().remove();
      }

      if (this.currentLvl !== nodeLevels.IED_LEVEL) {
        d3.selectAll('.eventsCount').remove();
      }
      graph.arrowPointer = graph.arrowPointer.data(
        this.nodes.filter((d) => d.name === this.pointer?.name),
        this.name
      );
      const arrowPointerEnter = graph.arrowPointer.enter().append('path');
      if (this.pointer && this.pointer.name !== this.cursor.name) {
        arrowPointerEnter.attr('fill', 'red');
      } else {
        arrowPointerEnter.style('opacity', '0');
      }
      arrowPointerEnter.classed('arrow-pointer', true);

      arrowPointerEnter.attr('d', () => 'M ' + 0 + ' ' + 0 + ' l -4 -8 l 8 0 z');

      graph.arrowPointer.attr('transform', (d) => `rotate(${d.x - 180})translate(0, ${d.y - 8})`);
      graph.arrowPointer.exit().remove();

      graph.arrowUp = graph.arrowUp.data([]);
      graph.arrowUp.exit().remove();
      graph.arrowDown = graph.arrowDown.data([]);
      graph.arrowDown.exit().remove();

      graph.ghostMarker = graph.ghostMarker.data(
        this.nodes.filter((d, i) => this.conditions.display(d, i) && d.isGhost)
      );
      graph.ghostMarker.attr('r', (d) => (d.type.indexOf('_LABEL') >= 0 || d.type === 'IED' ? 5 : 3));
      const ghostMarkerEnter = graph.ghostMarker
        .enter()
        .append('circle')
        .attr('r', 1e-6)
        .attr('y', (d) => d.y - 6)
        .style('fill', 'white')
        .style('pointer-events', 'none');
      ghostMarkerEnter
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attr('r', (d) => (d.type.indexOf('_LABEL') >= 0 || d.type === 'IED' ? 5 : 3));
      graph.ghostMarker.attr(
        'transform',
        (d) => `rotate(${d.x - 90})translate(${d.y + 1},0)${d.x < 180 ? '' : 'rotate(180)'}`
      );
      graph.ghostMarker.exit().remove();

      graph.marker = graph.marker.data(this.nodes.filter(this.conditions.display), this.name);
      graph.marker.attr('r', (d) => (d.type.indexOf('_LABEL') >= 0 || d.type === 'IED' ? 6 : 4));
      const markerEnter = graph.marker
        .enter()
        .append('circle')
        .attr('r', 1e-6)
        .attr('y', (d) => d.y - 6)
        .style('fill', (d) => d.color || d.parent.color);
      markerEnter
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attr('r', (d) => (d.type.indexOf('_LABEL') >= 0 || d.type === 'IED' ? 6 : 4));
      graph.marker.attr(
        'transform',
        (d) => `rotate(${d.x - 90})translate(${d.y + 1},0)${d.x < 180 ? '' : 'rotate(180)'}`
      );
      if (this.currentLvl === nodeLevels.IED_LEVEL || this.currentLvl === nodeLevels.MESSAGES_LEVEL) {
        graph.marker.on('mousedown', (slf) => {
          this.wheelMouseDown(slf);
        });
        graph.marker.on('touchstart', (slf) => {
          this.wheelMouseDown(slf);
        });
      } else {
        graph.marker.on('mousedown', null);
        graph.marker.on('touchstart', null);
      }

      graph.marker.exit().remove();

      graph.group = graph.group.data(
        this.nodes.filter((n) => n.type === 'IED'),
        this.name
      );
      graph.group
        .each(this.calculateGroupArc)
        .style('fill', (d) => d.color)
        .attr('d', (d) => {
          d.arc.curX = d.arc.endX;
          return graph.groupArc(d);
        });
      const groupEnter = graph.group
        .enter()
        .append('path')
        .each(this.calculateGroupArc)
        .style('fill', (d) => d.color)
        .attr('d', graph.groupArc)
        .each((d) => (d.childrenCount = d.children ? d.children.length : 0));
      groupEnter
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attrTween('d', (d) => {
          const interpolate = d3.interpolate(d.arc.startX, d.arc.endX);
          return (t) => {
            d.arc.curX = interpolate(t);
            return graph.groupArc(d);
          };
        });
      graph.group
        .filter((d) => {
          const cc = d.children ? d.children.length : 0;
          const oldCc = d.childrenCount;
          d.childrenCount = d.children ? d.children.length : 0;
          return cc !== oldCc;
        })
        .transition()
        .duration(consts.ANIMATION_DURATION)
        .attrTween('d', (d) => {
          const interpolate = d3.interpolate(d.arc.startX, d.arc.endX);
          return (t) => {
            d.arc.curX = interpolate(t);
            return graph.groupArc(d);
          };
        });
      graph.group.exit().remove();

      if (this.currentLvl === nodeLevels.IED_LEVEL) {
        this.angle = this.angle0;
      } else if (this.pointer) {
        this.angle = -this.pointer.x + 90;
      }

      this.transform();
    },
    nodeClickW(d) {
      dev.log('nodeClickW', d);
      this.nodeSelect(d);
    },
    nodeSelect(d) {
      dev.log('nodeSelect', d);
      $('#eventsSpeedResult').html('...');
      const getChildrenIdentificators = (ied) => {
        const cbRefs = [];
        const svIds = [];
        for (const child of ied.allchildren) {
          if (child.cbRef) {
            cbRefs.push(child.cbRef);
          } else if (child.svid) {
            svIds.push(child.svid);
          }
        }
        return {
          cbRefs,
          svIds,
        };
      };
      const { cbRefs, svIds } = getChildrenIdentificators(d);
      const iedIdentificators = [d.name, ...cbRefs, ...svIds];

      clearInterval(this.eventsSpeedInterval);
      this.getEventsSpeed(iedIdentificators);
      this.eventsSpeedInterval = setInterval(() => this.getEventsSpeed(iedIdentificators), 30000);
      if ((d.type === 'GOOSE_CTRL' || d.type === 'SV_CTRL') && d.isGhost) {
        console.error('Ghost max depth!');
        return;
      }
      this.recNode(this.cursor, this.currentLvl, this.cursor?.subnet || this.selectedSubnet.name);

      this.currentLeftListPos = 0;
      this.currentRightListPos = 0;
      if (this.currentLvl < nodeLevels.MAX_DEPTH_LEVEL) {
        this.currentLvl++;
      }
      this.cursor = markRaw(d);
      // this.updateInfo(d);
      // dev.log('subnet', this.selectedSubnet);
      // this.buildChart(this.selectedSubnet);
    },
    nodeClick(d) {
      dev.log('nodeClick', d);
      if ((d.type === 'GOOSE_DATA' || d.type === 'SV_DATA') && d.parent === this.cursor) {
        console.error('Double *_DATA click disabled!');
        return;
      }
      this.history.push({
        cursor: markRaw(this.cursor),
        subnet: this.cursor?.subnet,
        level: this.currentLvl,
      });
      this.currentLeftListPos = 0;
      this.currentRightListPos = 0;
      if (this.currentLvl < nodeLevels.MAX_DEPTH_LEVEL) {
        this.currentLvl++;
      }
      this.cursor = d.type === 'GOOSE_DATA' || d.type === 'SV_DATA' ? markRaw(d.parent) : markRaw(d);
      // this.updateInfo(this.cursor);
      // this.buildChart(this.selectedSubnet);
    },
    setUrl() {
      dev.log('setUrl', this.cursor);
      // this.$router.replace({ ...this.$router.currentRoute, query: { sub: this.cursor.subnet, node: this.cursor.name } });
    },
    async redrawSvg() {
      return new Promise((resolve, reject) => {
        dev.log('d3', d3);
        graph.color = d3.scale.category20();

        (this.diameter = this.hi), (this.radius = this.diameter / 2), (this.innerRadius = this.radius / 3.5);

        graph.cluster = d3.layout
          .cluster()
          .size([360, this.innerRadius])
          .value((d) => d.size);

        graph.bundle = d3.layout.bundle();

        graph.diagonal = d3.svg.diagonal().projection((d) => [d.y, d.x]);

        graph.line = d3.svg.line
          .radial()
          .interpolate('bundle')
          .tension(0.85)
          .radius((d) => d.y)
          .angle((d) => (d.x / 180) * Math.PI);

        this.calculateGroupArc = (d) => {
          let startElement, endElement;
          if (d.children) {
            startElement = d.children[0];
            endElement = d.children[d.children.length - 1];
          } else {
            startElement = endElement = d;
          }
          d.arc = {
            startX: startElement.x,
            endX: endElement.x,
            curX: startElement.x,
          };
        };

        graph.groupLine = d3.svg
          .line()
          .x((d) => d.x)
          .y((d) => d.y);

        graph.groupArc = d3.svg
          .arc()
          .innerRadius(this.innerRadius)
          .outerRadius(this.innerRadius + 1)
          .startAngle((d) => ((d.arc.startX - consts.IED_ARC_SHIFT_DEG) / 180) * Math.PI)
          .endAngle((d) => ((d.arc.curX + consts.IED_ARC_SHIFT_DEG) / 180) * Math.PI);

        graph.borderScale = d3.scale
          .linear()
          .range([0, this.hi / 8])
          .domain([1, consts.LIST_NODES_LIMIT / 2 + 1]);

        graph.hostsScale = d3.scale.linear();

        graph.clientsScale = d3.scale.linear();

        let cle = d3.selectAll('#wheel .wh-page > *').remove();
        graph.chart = d3
          .select('#wheel .wh-page')
          // .selectAll("svg")
          // .remove()
          .append('svg')
          .attr('id', 'chart')
          .attr('width', '100%')
          .attr('height', '100%');
        graph.svg = graph.chart.append('g');

        (graph.link = graph.svg.append('g').selectAll('.link')),
          (graph.group = graph.svg.append('g').selectAll('.group')),
          (graph.arrowPointer = graph.svg.append('g').classed('arrow-holder', true).selectAll('.arrow-pointer')),
          (graph.arrowUp = graph.svg.append('g').selectAll('.arrow-up')),
          (graph.arrowDown = graph.svg.append('g').selectAll('.arrow-down')),
          (graph.nodeCount = graph.svg.append('g').selectAll('.eventsCountBadge')),
          (graph.nodeTextCount = graph.svg.append('g').selectAll('.eventsCountText')),
          (graph.node = graph.svg.append('g').selectAll('.node'));
        graph.rotatable = graph.svg.append('g');
        graph.ghostMarker = graph.svg.append('g').selectAll('.ghost'); // It's not good for rotable. WORKAROUND: pointer-events: none for ghost markers
        graph.marker = graph.rotatable.selectAll('.marker');
        dev.log('d3 out');

        resolve();
      });
    },
    /**
     * Update current status of pa_rent objects of the referred projectObject
     * @param {Object} projectElement Project JSON model element of the project, referring to a GOOSE or SV control block
     * @param {Object} liveElement Live JSON data element (from TPWS), referring to a GOOSE or SV message
     * @param {Object} data Project JSON data
     */
    livePushDataToParent(projectObject) {
      if (projectObject.parent && projectObject.parent._live) {
        projectObject.parent._live.children = projectObject.parent._live.children || {};
        projectObject.parent._live.children[projectObject.name] = projectObject._live.alive;
        projectObject.parent._live.alive = projectObject._live.alive;
        projectObject.parent._live.simulated = this.checkIedSimulatedState(projectObject.parent);
      }
    },
    /**
     * Updates current status of child elements of projectObject, referring to GOOSE or SV control block.
     * @param {Object} projectElement Project JSON model element of the project, referring to a GOOSE or SV control block
     * @param {Object} liveObject Live JSON data element (from TPWS), referring to a GOOSE or SV message
     * @param {Object} data Project JSON data
     */

    livePushDataToChildren(projectObject, liveObject, data) {
      // Select SV_FCDA and GOOSE_FCDA objects from data, where pa_rent object is projectObject

      const fcdaMap = data.filter((dataObject) => {
        return (
          (dataObject.type === 'SV_FCDA' || dataObject.type === 'GOOSE_FCDA') && dataObject.parent === projectObject
        );
      });

      // Select SV_DATA and GOOSE_DATA objects from data, where grandpa_rent object is projectObject

      const daMap = data.filter((dataObject) => {
        if (typeof dataObject.parent == 'undefined') {
          return false;
        }
        return (
          (dataObject.type === 'SV_DATA' || dataObject.type === 'GOOSE_DATA') &&
          dataObject.parent.parent === projectObject
        );
      });

      // For selected FCDAs set alive and type status equal to alive and type status of pa_rent Object

      fcdaMap.map((fcda) => {
        fcda._live.alive = projectObject._live.alive;
        fcda._live.simulated = projectObject._live.simulated;
        fcda._live.type = projectObject._live.type;
      });

      // For selected DAs set alive and type status equal to alive and type status of grandpa_rent Object

      daMap.map((da) => {
        da._live.alive = projectObject._live.alive;
        da._live.simulated = projectObject._live.simulated;
        da._live.type = projectObject._live.type;
      });
    },
    checkIedSimulatedState(ied) {
      const children = ied.allchildren;
      const controlBlocks = children.filter((d) => {
        return d.type === 'SV_CTRL' || d.type === 'GOOSE_CTRL';
      });
      const iedSimulated = Object.keys(controlBlocks).every((cb) => {
        return (
          typeof controlBlocks[cb]._live !== 'undefined' &&
          typeof controlBlocks[cb]._live.simulated !== 'undefined' &&
          controlBlocks[cb]._live.simulated === true
        );
      });

      const iedSemiSimulated = Object.keys(controlBlocks).some((cb) => {
        return (
          typeof controlBlocks[cb]._live !== 'undefined' &&
          typeof controlBlocks[cb]._live.simulated !== 'undefined' &&
          controlBlocks[cb]._live.simulated === true
        );
      });

      return iedSimulated || iedSemiSimulated;
    },
    updateInfo(d) {
      // dev.log('!!updateInfo', d);
      this.ied = undefined;
      this.list = [];

      // $('#desc_left_head').empty();
      // $('#desc_right_head').empty();
      // $('#desc_left_body').empty();
      // $('#desc_right_body').empty();
      this.desc = [];
      this.bulls = [];
      $('#header').empty();
      // $('.recent-events-btn').remove();

      if (!d || !d.type) {
        return;
      }

      this.pushList(this.cursor);
      this.formatDescs('left');
      this.showDesc(this.list.length - 1, 'left');
      if (d !== this.cursor) {
        this.list = [d];
        this.formatDescs('right');
        this.showDesc(0, 'right');
      }

      $('#header')
        .empty()
        .append(
          '<h1>' +
            this.ied.name.replace('Ghosts', this.$t('Ghosts')) +
            '</h1>' +
            '<h3>' +
            this.ied?.desc?.replace('Ghosts', this.$t('Ghosts')) +
            '</h3>'
        );
    },
    async getEventsSpeed(name) {
      const { data: result } = await api
        .get(
          this.wheel.isOpenWheel ? '/open/get-speed-events' : '/projects/' + this.currentProject + '/get-speed-events',
          {
            period: this.wheelEventsCounter.interval,
            ied: name.join(','),
          }
        )
        .catch((e) => {
          dev.log('get-speed-events error', e);
        });
      dev.log('get-speed-events result', result);
      this.eventsSpeed = result.desc.count ? result.desc.count : 0;
      $('#eventsSpeedResult').html(this.eventsSpeed);
      $('#eventsSpeedInterval').html(this.wheelEventsCounter?.timeString);
    },
    loadWheelData() {
      dev.log('before this.mainFields', this.currentProject);
      this.mainFields = {
        url: this.wheel.isOpenWheel ? '/open/data' : `/projects/${this.currentProject}/data.json`,
        type: this.wheel.isOpenWheel ? 'get' : 'post',
        dataType: 'json',
        success: ({ data }) => {
          dev.log('loadWheelData success');
          if (!['open-wheel-page', 'wheel-page', 'wheel-live-page'].includes(this.$route.name)) {
            return;
          }
          // console.trace('loadWheelData', this.$route, data);
          // this.eJson = markRaw(data);
          this.setEJson(markRaw(data));
          if (this.wheel.isOpenWheel) {
            this.liveViewSwitcherOnClick();
            dev.log('ss1');
            this.subnetSelector();
          } else {
            if (data.msg?.toLowerCase() === 'ok') {
              dev.log('ss2');
              return this.subnetSelector();
            } else {
              // запускаем лайв-режим, если нет файла проекта
              this.liveViewSwitcherOnClick();
              this.wheelSession.saveProp('liveMode', false);

              // this.eJson =
              this.setEJson({
                msg: 'Ok',
                status: 0,
                subnets: [
                  {
                    data: [],
                    desc: '',
                    filters: [{ name: 'linkage', groups: [], active: true }],
                    iedList: [],
                    isGOOSE: true,
                    name: 'Unknown',
                    status: {},
                    summary: { ieds: 0, gooses: 0, svs: 0 },
                    timestamp: 0,
                    type: '',
                  },
                ],
                substation: {
                  name: '',
                  desc: '',
                },
                validationErrors: '',
              });

              dev.log('ss3');
              this.subnetSelector();
            }
          }
        },
      };

      if (!this.wheel.isOpenWheel) {
        this.mainFields.data = { isActiveVersion: this.isWheelLivePage };
      }
      // if (!this.wheel.isOpenWheel) {
      //   this.mainFields.data = { isActiveVersion: this.isWheelLivePage };
      // }
      // $.ajax(this.mainFields);
      api[this.mainFields.type](this.mainFields.url, this.mainFields.data).then(this.mainFields.success);
    },
    async subnetSelector() {
      dev.log('do subnetSelector');
      let bLoadSubnet = false;
      // this.eJson = markRaw(eJson);
      /*
        This function is used to select the subnetwork to be visualised.

        INPUT: eJson (json model, received from TPS)
        RETURN: NO DIRECT return. Calls number of functions to build the diagram for selected subnetwork.

        */

      // If there are more than one subnet found in JSON - prompt user which subnet to visualize.
      let savedSubnet = this.wheelSession.subnet;
      if (savedSubnet) {
        this.currentSubnet = savedSubnet;
      }

      if (this.subToSwitch) {
        this.currentSubnet = decodeURIComponent(this.subToSwitch);
      }
      await this.selectedSubnetReady();
      if (this.subnets.length > 1) {
        // If model has more than 1 subnet, show the screen to select subnet
        if (this.subToSwitch) {
          this.wheelSession.saveProp('subnet', this.subToSwitch);
          savedSubnet = this.subToSwitch;
        }
        if (!savedSubnet && this.subnets.length > 1) {
          this.openSubnetSelectorDialog();
        } else {
          bLoadSubnet = true;
          // this.loadSubnet();
        }
        this.$slf.scrollToTopVexContent();

        this.subnetSelectorVisible = true;
      }
      if (!this.subToSwitch) {
        // If model has more than 1 subnet, show the screen to select subnet
        bLoadSubnet = true;
        // this.loadSubnet();
      } else {
        // Else just pick the first one and visualize it.
        dev.log('this.selectedSubnet.name', this.selectedSubnet?.name, this.selectedSubnet);
        this.wheelSession.saveProp('subnet', this.selectedSubnet.name);
        bLoadSubnet = true;
        // this.loadSubnet();
      }

      if (bLoadSubnet) {
        this.loadSubnet();
      }

      dev.log('this.selectedSubnet.data', !!this.selectedSubnet?.data);

      dev.log('subnetSelector end');
    },
    switchToNode() {
      dev.log('switchToNode', this.nodeToSwitch, this.selectedSubnetData);
      if (this.nodeToSwitch) {
        let sdata = this.selectedSubnetData[0];
        let lvlToSwitch = 2;
        sdata = this.selectedSubnetData.find((d) => {
          // dev.log('d.name', d.type/*, decodeURIComponent(this.nodeToSwitch)*/, d.name);
          if (d.type == 'IED' && d.name == this.nodeToSwitch) {
            dev.log('i1');
            lvlToSwitch = 1;
            return true;
          }
          if (
            d.type === 'GOOSE_CTRL' &&
            (d.cbRef === decodeURIComponent(this.nodeToSwitch) || d.name === decodeURIComponent(this.nodeToSwitch))
          ) {
            dev.log('i2');
            lvlToSwitch = 2;
            return true;
          }
          if (d.type === 'INPUTS' && d.name === decodeURIComponent(this.nodeToSwitch)) {
            lvlToSwitch = 2;
            return true;
          }
          if (
            (d.type == 'GOOSE_FCDA' ||
              d.type == 'GOOSE_DATA' ||
              d.type == 'SV_FCDA' ||
              d.type == 'SV_DATA' ||
              d.type == 'INPUTS_DATA') &&
            d.name == decodeURIComponent(this.nodeToSwitch)
          ) {
            dev.log('i3');
            lvlToSwitch = 3;
            return true;
          }
          return d.text == this.nodeToSwitch ? true : false;
        });
        dev.log('subnetSelector out', sdata, lvlToSwitch, this.selectedSubnet);
        if (sdata) {
          this.gotoNode(sdata, lvlToSwitch);
        }
      }
    },
    cursorByNode(back = false) {
      // log('cursorByNode in');
      dev.log('cursorByNode in');
      if (this.nodeToSwitch) {
        let sdata = this.selectedSubnet.data[0];
        let lvlToSwitch = 2;
        sdata = this.selectedSubnet.data.find((d) => {
          // dev.log('d', d.type, d.name, d);
          if (d.type == 'IED' && d.name == this.nodeToSwitch) {
            lvlToSwitch = 1;
            return true;
          }
          if (
            d.type === 'GOOSE_CTRL' &&
            (d.cbRef === decodeURIComponent(this.nodeToSwitch) || d.name === decodeURIComponent(this.nodeToSwitch))
          ) {
            lvlToSwitch = 2;
            return true;
          }
          if (d.type === 'INPUTS' && d.name === decodeURIComponent(this.nodeToSwitch)) {
            lvlToSwitch = 2;
            return true;
          }
          if (
            (d.type == 'GOOSE_FCDA' ||
              d.type == 'GOOSE_DATA' ||
              d.type == 'SV_FCDA' ||
              d.type == 'SV_DATA' ||
              d.type == 'INPUTS_DATA') &&
            d.name == decodeURIComponent(this.nodeToSwitch)
          ) {
            lvlToSwitch = 3;
            return true;
          }
          return d.text == this.nodeToSwitch ? true : false;
        });
        dev.log('cursorByNode out', sdata, lvlToSwitch, sdata?.subnet);
        if (sdata) {
          if (back) {
            this.gotoNode(sdata, lvlToSwitch);
          } else {
            this.recNode(sdata, lvlToSwitch, sdata?.subnet);
            this.gotoNode(sdata, lvlToSwitch);
          }
        } else {
          dev.log('no data', this.selectedSubnet?.data, decodeURIComponent(this.nodeToSwitch), this.nodeToSwitch);
        }
      }
    },
    setStatus() {
      const subnet = this.selectedSubnet;
      const MINOR = 1;
      const CRITICAL = 2;
      const hint = $('#wheel_status_hint');
      const icon = $('#wheel_status');
      icon.removeClass('fa-check-circle');
      icon.removeClass('fa-times-circle');
      const status = subnet.status;
      if (status.type === CRITICAL) {
        icon.addClass('fa-times-circle');
        icon.css('color', 'red');
      } else if (status.type === MINOR) {
        icon.addClass('fa-times-circle');
        icon.css('color', 'orange');
      } else {
        icon.addClass('fa-check-circle');
        icon.css('color', 'green');
      }
      hint.html(subnet.status.msg);
    },
    resetTime() {
      // dev.log(JSON.stringify(util.inspect(this.data) ));
      this.wheelEventsCounter.lastRecievedEventsTs = this.wheelEventsCounter.lastRecievedEventsTs - 1000000000000;
      dev.log('new date', new Date(this.wheelEventsCounter.lastRecievedEventsTs / 1000));
    },
    printData() {
      // dev.log(JSON.stringify(util.inspect(this.data) ));
      const stringifyCircularJSON = (obj) => {
        const seen = new WeakSet();
        return JSON.stringify(obj, (k, v) => {
          if (v !== null && typeof v === 'object') {
            if (seen.has(v)) return;
            seen.add(v);
          }
          return v;
        });
      };
      dev.log(JSON.stringify(stringifyCircularJSON(this.data)));
    },
    pulseFunction() {
      // dev.log('pulseFunction', this.LIVE_VIEW_MODE);
      // const e = new Error();
      // dev.log(e);
      const subnet = this.selectedSubnet;
      if (!this.LIVE_VIEW_MODE || !this.wheelEventsCounter.visiblity) {
        d3.selectAll('.eventsCount').style('visibility', 'hidden');
        if (this.currentLvl === nodeLevels.IED_LEVEL || this.currentLvl === nodeLevels.MESSAGES_LEVEL)
          d3.selectAll('.ied-label')
            .attr(
              'transform',
              (d) =>
                `rotate(${d.x - 90})translate(${d.y + 10},0)${
                  (d.x + this.angle + 360) % 360 < 180 ? '' : 'rotate(180)'
                }`
            )
            .attr('data-angle', (d) => `${d.x - 90}`);
        // d3.selectAll('.ied-label').attr('data-angle', (d) => `${d.x - 90}`);
      }
      if (!this.LIVE_VIEW_MODE) {
        this.rejectCounter = 0;
        $('.wheel-disconnect-screen').css('display', 'none');
        $('#eventsCounter').css('display', 'none');
        $('#subnet_selector').css('display', 'block');
        $('#right_sidebar_buttons').css({ display: 'flex', width: 'auto', marginLeft: '5px' });

        //ON_AIR = false;
        graph.node.classed('wh-status-good-sv', false);
        graph.node.classed('wh-status-good-goose', false);
        graph.node.classed('wh-status-intermediate', false);
        graph.node.classed('wh-status-bad', false);
        graph.node.classed('wh-status-unknown', false);
        return;
      }
      if (this.currentLvl === nodeLevels.IED_LEVEL) {
        $('#eventsCounter').css({ display: 'block' });
        if (this.wheelEventsCounter.visiblity)
          d3.selectAll('.ied-label')
            .attr(
              'transform',
              (d) =>
                `rotate(${d.x - 90})translate(${
                  d.type.indexOf('_LABEL') >= 0 || d.type === 'IED' ? d.y + 45 : d.y + 10
                },0)${(d.x + this.angle + 360) % 360 < 180 ? '' : 'rotate(180)'}`
            )
            .attr('data-angle', (d) => `${d.x - 90}`);
        // d3.selectAll('.ied-label').attr('data-angle', (d) => `${d.x - 90}`);
      } else {
        $('#eventsCounter').css({ display: 'none' });
      }
      if (this.wheelEventsCounter.visiblity) {
        d3.selectAll('.eventsCount').style('visibility', 'visible');
      }

      /** Request current state date and events list from TPS.
       The function calls requests url: "/projects/"+this.currentProject+"/state".
       On successfull call it recives current state json file and executes further processing.
       On failure it calls reject() function.

       @name getState
       @function getState
       */
      if (this.isAwaitEvents) {
        return;
      }
      this.isAwaitEvents = true;

      const getState = new Promise((resolve, reject) => {
        api
          .get(this.wheel.isOpenWheel ? '/open/state' : '/projects/' + this.currentProject + '/state', {
            params: {
              period: 60, //this.wheelEventsCounter.interval,
            },
          })
          .then(({ data: state }) => {
            // Developer
            //todo remove after #1192 resolve @/utils/states
            // state = JSON.parse(this.b64_to_utf8(states[this.statesIdx]))
            // this.statesIdx = this.statesIdx + 1;
            // if (this.statesIdx >= states.length) {
            //   this.statesIdx = 0;
            // }

            if (this.ghosts.devConsoleLiveUpdateOn) {
              console.info('Live console update');
              this.ghosts.updateDevConsole(state, consts.TPS_LIVE_OBJECTS);
            }

            const liveGooseMap = {},
              liveSvMap = {},
              gg = [],
              gs = [],
              gi = [];
            if (!state.tpls.alive) {
              $('.wheel-tpls-disconnect-screen').css('display', 'block');

            } else {
              $('.wheel-tpls-disconnect-screen').css('display', 'none');
            }
            /**
               @private For each object, received from state collect data and put it to defined objects: gmap, smap, gg, gs.
               */
            d3.selectAll('.eventsCountText').text((d) => {
              let count = 0;
              for (const stateObject of state.periods) {
                if ('iedName' in stateObject && stateObject.name == d.text) {
                  count += stateObject.count;
                }

                if (
                  'goCbRef' in stateObject &&
                  d.allchildren.find((c) => c.type == 'GOOSE_CTRL' && c.cbRef === stateObject.goCbRef) !== undefined
                ) {
                  count += stateObject.count;
                }

                if (
                  'svId' in stateObject &&
                  d.allchildren.find((c) => c.type == 'SV_CTRL' && c.svid === stateObject.svId) !== undefined
                ) {
                  count += stateObject.count;
                }
              }
              return count;
            });
            state.objects.forEach((stateObject) => {
              // If no current element for this object, simply skip it
              // TODO: Algorithm for current at TPWS?
              if (!stateObject.current[0]) {
                return;
              }
              if (stateObject.type === nodeTypes.typeGOOSE) {
                //TODO: Must add goCbRef here in order to make full unique path for goose.
                liveGooseMap[
                  stateObject.dstMac.split('-').join('') +
                    stateObject.goCbRef +
                    stateObject.appId +
                    stateObject.goId +
                    stateObject.simulation
                ] = stateObject;
              } else if (stateObject.type === nodeTypes.typeSV) {
                //TODO: Must add msvCbRef here in order to make full unique path for sv.
                liveSvMap[
                  stateObject.dstMac.split('-').join('') + stateObject.appId + stateObject.svId + stateObject.simulation
                ] = stateObject;
              } else {
                //TODO: Must add msvCbRef here in order to make full unique path for sv.
              }
              if (stateObject.current[0].status.isGhost) {
                if (stateObject.type === nodeTypes.typeGOOSE) {
                  gg.push(stateObject);
                } else if (stateObject.type === nodeTypes.typeSV) {
                  gs.push(stateObject);
                } else {
                  gi.push(stateObject);
                }
              }
            });

            // Filter out goose and svs from project subnet
            const projectGooseMap = subnet.data.filter((d) => {
              return d.type === 'GOOSE_CTRL';
            });
            const projectSvMap = subnet.data.filter((d) => {
              return d.type === 'SV_CTRL';
            });

            /*
              For each GOOSE in the project:
              1. Check if it is not ghost and not disabled, if
              */
            projectGooseMap.map((projectGoose) => {
              if (projectGoose.isGhost && projectGoose.disabled) {
                return;
              }
              // Extract live goose with project parameters as well as simulated GOOSE (if present)
              const liveGoose =
                liveGooseMap[
                  projectGoose.gse.mac.split('-').join('') +
                    projectGoose.cbRef +
                    projectGoose.gse.appid +
                    projectGoose.goid +
                    '0'
                ];
              const simulatedGoose =
                liveGooseMap[
                  projectGoose.gse.mac.split('-').join('') +
                    projectGoose.cbRef +
                    projectGoose.gse.appid +
                    projectGoose.goid +
                    '1'
                ];
              if (!liveGoose && !simulatedGoose) {
                projectGoose._live.neverseen = true;
                projectGoose._live.alive = false;
                return;
              } else {
                projectGoose._live.neverseen = false;
              }
              // TODO: Check GOOSE
              if (liveGoose && typeof liveGoose.current[0].status.alive !== 'undefined') {
                projectGoose._live.alive = liveGoose.current[0].status.alive && state.lvbs && state.tpls.alive;
              }

              if (simulatedGoose && typeof simulatedGoose.current[0].status.alive !== 'undefined') {
                projectGoose._live.simulated = simulatedGoose.current[0].status.alive && state.lvbs && state.tpls.alive;
              }

              if (liveGoose) {
                projectGoose._live.type = liveGoose.type;
              } else if (simulatedGoose) {
                projectGoose._live.type = simulatedGoose.type;
              }

              this.livePushDataToParent(projectGoose, liveGoose, subnet.data);
              this.livePushDataToChildren(projectGoose, liveGoose, subnet.data);
            });
            projectSvMap.map((projectSv) => {
              if (projectSv.isGhost && projectSv.disabled) {
                return;
              }
              const liveSv =
                liveSvMap[projectSv.smv.mac.split('-').join('') + projectSv.smv.appid + projectSv.svid + '0'];
              const simulatedSv =
                liveSvMap[projectSv.smv.mac.split('-').join('') + projectSv.smv.appid + projectSv.svid + '1'];
              if (!liveSv && !simulatedSv) {
                projectSv._live.alive = false;
                projectSv._live.neverseen = true;
                return;
              } else {
                projectSv._live.neverseen = false;
              }
              // TODO: Check SV
              if (liveSv && typeof liveSv.current[0].status.alive !== 'undefined') {
                projectSv._live.alive = liveSv.current[0].status.alive && state.lvbs && state.tpls.alive;
              }

              if (simulatedSv && typeof simulatedSv.current[0].status.alive !== 'undefined') {
                projectSv._live.simulated = simulatedSv.current[0].status.alive && state.lvbs && state.tpls.alive;
              }

              if (liveSv) {
                projectSv._live.type = liveSv.type;
              } else if (simulatedSv) {
                projectSv._live.type = simulatedSv.type;
              }

              this.livePushDataToParent(projectSv, liveSv, subnet.data);
              this.livePushDataToChildren(projectSv, liveSv, subnet.data);
            });

            // After getting all required information for all objects, update thier statuses in nodes of D3

            graph.node.classed('wh-status-good-sv', (e) => {
              let res = false;
              if (e._live.type === nodeTypes.typeSV && e._live.alive) {
                dev.log('wh-status-good-sv: ', e);
              }
              switch (e.type) {
                case 'SV_CTRL_LABEL':
                  res = e.parent._live.type === nodeTypes.typeSV && e.parent._live.alive;
                  break;
                case 'SV_FCDA_LABEL':
                  res = e.parent.parent._live.type === nodeTypes.typeSV && e.parent.parent._live.alive;
                  break;
                default:
                  res = e._live.type === nodeTypes.typeSV && e._live.alive;
              }
              return res;
            });

            graph.node.classed('wh-status-good-goose', (e) => {
              let res = false;

              switch (e.type) {
                case 'IED':
                  res = this.checkParentState(e) === 'good';
                  break;
                case 'IED_LABEL':
                  res = this.checkIedLabelState(e) === 'good';
                  break;
                case 'GOOSE_CTRL_LABEL':
                  res = e.parent._live.type === nodeTypes.typeGOOSE && e.parent._live.alive;
                  break;
                case 'GOOSE_FCDA_LABEL':
                  res = e.parent.parent._live.type === nodeTypes.typeGOOSE && e.parent.parent._live.alive;
                  break;
                default:
                  res = e._live.type === nodeTypes.typeGOOSE && e._live.alive;
              }
              return res;
            });
            graph.node.classed('wh-status-bad', (e) => {
              let res = false;

              if (e.isGhost) {
                return res;
              }

              switch (e.type) {
                case 'IED':
                  res = this.checkParentState(e) === 'bad';
                  break;
                case 'IED_LABEL':
                  res = this.checkIedLabelState(e) === 'bad';
                  break;
                case 'GOOSE_CTRL_LABEL':
                case 'SV_CTRL_LABEL':
                  res =
                    !e.parent._live.neverseen &&
                    e.parent._live.alive != null &&
                    !e.parent._live.alive &&
                    !e.parent._live.simulated;
                  break;
                case 'GOOSE_FCDA_LABEL':
                case 'SV_FCDA_LABEL':
                  res =
                    !e.parent.parent._live.neverseen &&
                    e.parent.parent._live.alive != null &&
                    !e.parent.parent._live.alive &&
                    !e.parent.parent._live.simulated;
                  break;
                default:
                  res = !e._live.neverseen && e._live.alive != null && !e._live.alive && !e._live.simulated;
              }

              return res;
            });

            graph.node.classed('wh-status-simulated', (e) => {
              switch (e.type) {
                case 'IED_LABEL':
                case 'GOOSE_CTRL_LABEL':
                case 'SV_CTRL_LABEL':
                  return e.parent._live.simulated;
                case 'GOOSE_FCDA_LABEL':
                case 'SV_FCDA_LABEL':
                  return e.parent.parent._live.simulated;
                default:
                  return e._live.simulated;
              }
            });

            graph.node.classed('wh-status-intermediate', (e) => {
              let res = false;
              switch (e.type) {
                case 'IED':
                  res = this.checkParentState(e) === 'intermediate';
                  break;
                case 'IED_LABEL':
                  res = this.checkIedLabelState(e) === 'intermediate';
                  break;
                default:
                  res = false;
              }
              return res;
            });

            graph.node.classed('wh-status-unknown', (e) => {
              let res = true;
              switch (e.type) {
                case 'IED':
                  res = this.checkParentState(e) === 'unknown';
                  break;
                case 'IED_LABEL':
                  res = this.checkIedLabelState(e) === 'unknown';
                  //TODO: Implement check function for IED label at Control-block view.
                  break;

                case 'INPUTS':
                  // TODO: Implement status check for inputs
                  break;

                case 'INPUTS_LABEL':
                  // TODO: Implement status check for inputs
                  break;

                case 'INPUTS_DATA':
                  // TODO: Implement status check for inputs
                  break;
                case 'GOOSE_CTRL_LABEL':
                case 'SV_CTRL_LABEL':
                case 'GOOSE_FCDA':
                case 'SV_FCDA':
                  return e.parent._live.neverseen === true;
                case 'GOOSE_FCDA_LABEL':
                case 'SV_FCDA_LABEL':
                case 'GOOSE_DATA':
                case 'SV_DATA':
                  return e.parent.parent._live.neverseen === true;
                default:
                  // GOOSE_CTRL, GOOSE_FCDA, GOOSE_DATA goes here by default
                  res = e._live.neverseen === true;
                  break;
              }
              return res;
            });
            graph.node.classed('wh-status-ghost', (e) => {
              return e.isGhost;
            });

            const ggn = [];
            const gsn = [];
            const gin = [];
            gg.forEach((g, idx) => {
              if (g?.current[0].status.alive) {
                ggn.push(g);
              }
            });
            gs.forEach((s, idx) => {
              if (s?.current[0].status.alive) {
                gsn.push(s);
              }
            });
            gi.forEach((i, idx) => {
              if (i?.current[0].status.alive) {
                //todo fix main
                if (!~this.$slf.offlineIed.indexOf(i.ied)) {
                  gin.push(i);
                }
              }
            });

            // dev.log('window.vm', window.vm._instance.data.ghosts, window.devvm);
            this.ghosts.ghostGooses = ggn;
            this.ghosts.ghostSvs = gsn;
            this.ghosts.ghostIeds = gin.sort(this.ghosts.sort);
            this.ghosts.lvbs = state.lvbs;
            this.ghosts.system_state = state;
            this.ghosts.objects = state.objects;

            this.updateGhosts(gg, gs, gi, subnet.data, () => {
              if (this.selectedSubnet?.name !== subnet?.name) {
                dev.log('updateGhosts', this.selectedSubnet?.name, subnet?.name);
              }

              this.$nextTick(() => {
                this.buildChart(subnet);
              });
            });
            this.updateIndicators(state);
            resolve();
          })
          .catch(() => {
            this.rejectCounter++;
            reject();
          });
      });

      const getEvents = new Promise((resolve, reject) => {
        api
          .get(this.wheel.isOpenWheel ? '/open/events' : `/projects/${this.currentProject}/events`, {
            params: { start: this.wheelEventsCounter.lastRecievedEventsTs },
          })
          .then(({ data: events }) => {
            if (events.length) {
              dev.log('getEvents res', events.length);
            }
            if (events.length) {
              this.wheelEventsCounter.lastRecievedEventsTs = events.reduce((a, e) => (a < e.ts ? e.ts : a), 0);
              this._eventsNotice(
                events,
                events.reduce((a, e) => (a.ts < e.ts ? e : a), { ts: 0 })
              );
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
      Promise.all([getState, getEvents])
        .then(() => {
          this.isAwaitEvents = false;
          $('.wheel-disconnect-screen').css('display', 'none');
          this.rejectCounter = 0;
        })
        .catch(() => {
          this.isAwaitEvents = false;
          $('.wheel-disconnect-screen').css('display', this.rejectCounter > this.rejectLimit ? 'block' : 'none');
        });
    },
    initBreadcrumbs() {
      // const subnet = this.selectedSubnet;
      // let data = subnet.data;
      // data.map((d) => (d._ix = 0));
      // this.removeVirtual(data);
      // this.rootData = markRaw(this.packageHierarchy(data));
      this.$nextTick(() => {
        this.makePathTree();
      });
      dev.log('bc upd', this.wheel.pathTree);
    },
    async buildChart(subnet) {
      // dev.log('!!!buildChart', this.subToSwitch, this.nodeToSwitch, this.selectedSubnet, this.cursor);
      // console.trace('!!buildChart', this.subToSwitch, this.nodeToSwitch, this.selectedSubnet, this.cursor);
      // Reset events (bebind buttons)
      // const subnet = this.selectedSubnet;

      // if (subnet?.name) {
      $('#currentSubnet').text(subnet?.name);
      // }
      // dev.log('graph.rotatable buildChart off', graph.rotatable);
      graph.rotatable.classed('wh-rotateable', false);

      d3.select('#chart').on('mousemove', null);
      d3.select('#chart').on('mouseup', null);

      $('#back_btn').off();
      $('#back_btn').click(this.navHistoryGoBack);
      $('#parent_btn').off();
      $('#parent_btn').click(this.navLevelUp);
      $('#reset_btn').off();
      $('#reset_btn').click(this.navReset);

      $('#chart').off();

      const switchToLevel = (lvl) => {
        let lvld;
        // dev.log('lvl', lvl);
        switch (lvl) {
          case nodeLevels.DATASET_LEVEL:
            lvld = {
              diagramType: consts.LIST_DIAGRAM,
              drawFunc: this.drawLists,
              importsField: 'lImports',
              conditions: {
                display: this.dsLvlDisplayCond,
                enable: this.dsLvlEnableCond,
                imports: (_) => true,
                listTtl: this.dsTtlListCond,
                rightListTtl: this.dsTtlRightListCond,
              },
            };
            break;
          case nodeLevels.SIGNAL_LEVEL:
            lvld = {
              diagramType: consts.LIST_DIAGRAM,
              forcedCursor: (_cursor) => (_cursor.type === 'INPUTS_DATA' ? _cursor?.parent : _cursor),
              forcedPointer: (_cursor) => {
                // dev.log('fp', _cursor?.type)
                if (_cursor?.type === 'INPUTS_DATA') {
                  return _cursor?.parent?.children?.length > 0 && _cursor.parent?.children[0];
                } else {
                  return _cursor?.children?.length > 0 && _cursor.parent?.children[0];
                }
              },
              drawFunc: this.drawLists,
              conditions: {
                display: this.sLvlDisplayCond,
                enable: this.sLvlEnableCond,
                imports: this.sLvlImportsCond,
                listTtl: this.sTtlListCond,
                rightListTtl: this.sTtlRightListCond,
              },
            };
            break;
          case nodeLevels.MESSAGES_LEVEL:
            lvld = {
              diagramType: consts.WHEEL_DIAGRAM,
              drawFunc: this.drawWheel,
              conditions: {
                display: this.msgsLvlDisplayCond,
                enable: this.msgsLvlEnableCond,
                imports: this.msgsLvlImportsCond,
              },
            };
            break;
          case nodeLevels.IED_LEVEL:
          default:
            lvld = {
              diagramType: consts.WHEEL_DIAGRAM,
              drawFunc: this.drawWheel,
              conditions: {
                display: this.iedLvlDisplayCond,
                enable: this.iedLvlEnableCond,
                imports: () => true,
              },
            };
            break;
        }
        return lvld;
      };

      const lvlData = switchToLevel(this.currentLvl);
      // dev.log('lvlData', lvlData, switchToLevel);
      this.curDiagramType = lvlData.diagramType;
      this.conditions = lvlData.conditions;
      this._forcedCursor = lvlData?.forcedCursor ? lvlData.forcedCursor(this.cursor) : this.cursor;
      // dev.log('subnet.data', subnet?.data.length, !subnet?.data);
      if (!subnet?.data) {
        return;
      }
      this.dataReady = false;
      this.data = subnet?.data;
      this.data.map((d) => (d._ix = 0));
      this.removeVirtual(this.data);
      if (this.conditions?.listTtl && this.conditions?.rightListTtl) {
        this.updateLeftListSelection(this.conditions.listTtl);
        this.updateRightListSelection(this.conditions.rightListTtl);
        this.linkLists();
        this.updateListsFlags();
      }
      this.filter(subnet);

      this.root = this.packageHierarchy(this.data);

      this.data.map((d) => {
        d._live = {};
        this.toggleChildren(d, (c) => {
          return this.conditions.enable(c) && !c.filtered && !c.disabled;
        });
      });
      this.toggleChildren(this.root, (c) => {
        return this.conditions.enable(c) && !c.filtered && !c.disabled;
      });

      this.nodes = graph.cluster.nodes(this.root);

      this.links = this.packageImports(this.nodes, this.conditions.imports, lvlData.importsField);

      this.nodes
        .filter((n) => {
          return n.type === 'IED';
        })
        .map((d, i) => {
          d.color = d.color || graph.color(i);
        });

      this.dataReady = true;
      // dev.log('state dataReady')

      // if (this.cursor) {
      // /*await */this.cursorChildrenReady();
      await this.waitDataReady();
      // dev.log('this.cursor', this.cursor, this.cursor?.children, this.cursor?.name, this.cursor?.allchildren);
      // dev.log('lvlData.forcedPointer', lvlData.forcedPointer);
      const point = lvlData?.forcedPointer ? lvlData.forcedPointer(this.cursor) : this.cursor?.children?.[0];
      this.pointer = markRaw(point);
      // dev.log('forcedPointer bc', this.cursor, this.pointer);
      // console.trace();
      this.displayDebugInfo(subnet);
      // }
      lvlData.drawFunc();
      // dev.log('state drawFunc')

      if (!this.livePulse) {
        dev.log('assignPulse');
        this.livePulse = setInterval(() => {
          this.pulseFunction();
        }, this.pulseIntervalMs);
      }
    },
    addVirtualToLeftList(ttlCondition) {
      const ttl = this.data.filter(ttlCondition);
      // dev.log('ttl', ttl);
      let upper, lower;
      if (this.currentLeftListPos > 0 /* && ttl?.length > 0*/) {
        const upperPos = this.data.indexOf(ttl[this.currentLeftListPos - 1]);
        upper = {
          name: ttl[this.currentLeftListPos - 1].name + '_upper',
          type: ttl[this.currentLeftListPos - 1].type,
          parent: ttl[this.currentLeftListPos - 1].parent,
          allchildren: [],
          lns: [],
          _virtual: {
            pos: 'upper',
            side: 'left',
            names: [],
          },
          text:
            ttl[0].text.substr(0, 8) +
            '..' +
            ttl[0].text.substr(-8) +
            ' - ' +
            ttl[this.currentLeftListPos - 1].text.substr(0, 8) +
            '..' +
            ttl[this.currentLeftListPos - 1].text.substr(-8),
        };
        ttl[this.currentLeftListPos - 1].parent.allchildren.push(upper);
        for (let i = 0; i < this.currentLeftListPos; i++) {
          ttl[i].lns.forEach((ln) => {
            if (upper.lns.indexOf(ln) < 0) {
              upper.lns.push(ln);
            }
          });
          upper._virtual.names.push(ttl[i].name);
        }
        this.data.splice(upperPos, 0, upper);
      }
      if (this.currentLeftListPos + consts.LIST_NODES_LIMIT < ttl.length) {
        const lowerPos = this.data.indexOf(ttl[this.currentLeftListPos + consts.LIST_NODES_LIMIT]);
        lower = {
          name: ttl[this.currentLeftListPos + consts.LIST_NODES_LIMIT].name + '_lower',
          type: ttl[this.currentLeftListPos + consts.LIST_NODES_LIMIT].type,
          parent: ttl[this.currentLeftListPos + consts.LIST_NODES_LIMIT].parent,
          allchildren: [],
          lns: [],
          _virtual: {
            pos: 'lower',
            side: 'left',
            names: [],
          },
          text:
            ttl[this.currentLeftListPos + consts.LIST_NODES_LIMIT].text.substr(0, 8) +
            '..' +
            ttl[this.currentLeftListPos + consts.LIST_NODES_LIMIT].text.substr(-8) +
            ' - ' +
            ttl[ttl.length - 1].text.substr(0, 8) +
            '..' +
            ttl[ttl.length - 1].text.substr(-8),
        };
        ttl[this.currentLeftListPos + consts.LIST_NODES_LIMIT].parent.allchildren.push(lower);
        for (let i = this.currentLeftListPos + consts.LIST_NODES_LIMIT; i < ttl.length; i++) {
          ttl[i].lns.forEach((ln) => {
            if (lower.lns.indexOf(ln) < 0) {
              lower.lns.push(ln);
            }
          });
          lower._virtual.names.push(ttl[i].name);
        }
        this.data.splice(lowerPos, 0, lower);
      }
    },
    addVirtualToRightList(ttlCondition) {
      const ttl = this.data.filter(ttlCondition);
      let upper, lower;
      if (this.currentRightListPos > 0) {
        const upperPos = this.data.indexOf(ttl[this.currentRightListPos - 1]);
        upper = {
          name: ttl[this.currentRightListPos - 1].name + '_upper',
          type: ttl[this.currentRightListPos - 1].type,
          parent: ttl[this.currentRightListPos - 1].parent,
          allchildren: [],
          lns: [],
          _virtual: {
            pos: 'upper',
            side: 'right',
            names: [],
          },
          text:
            ttl[0].text.substr(0, 8) +
            '..' +
            ttl[0].text.substr(-8) +
            ' - ' +
            ttl[this.currentRightListPos - 1].text.substr(0, 8) +
            '..' +
            ttl[this.currentRightListPos - 1].text.substr(-8),
        };
        ttl[this.currentRightListPos - 1].parent.allchildren.push(upper);
        for (let i = 0; i < this.currentRightListPos; i++) {
          ttl[i].lns.forEach((ln) => {
            if (upper.lns.indexOf(ln) < 0) {
              upper.lns.push(ln);
            }
          });
          upper._virtual.names.push(ttl[i].name);
        }
        this.data.splice(upperPos, 0, upper);
      }
      if (this.currentRightListPos + consts.LIST_NODES_LIMIT < ttl.length) {
        const lowerPos = this.data.indexOf(ttl[this.currentRightListPos + consts.LIST_NODES_LIMIT]);
        lower = {
          name: ttl[this.currentRightListPos + consts.LIST_NODES_LIMIT].name + '_lower',
          type: ttl[this.currentRightListPos + consts.LIST_NODES_LIMIT].type,
          parent: ttl[this.currentRightListPos + consts.LIST_NODES_LIMIT].parent,
          allchildren: [],
          lns: [],
          _virtual: {
            pos: 'lower',
            side: 'right',
            names: [],
          },
          text:
            ttl[this.currentRightListPos + consts.LIST_NODES_LIMIT].text.substr(0, 8) +
            '..' +
            ttl[this.currentRightListPos + consts.LIST_NODES_LIMIT].text.substr(-8) +
            ' - ' +
            ttl[ttl.length - 1].text.substr(0, 8) +
            '..' +
            ttl[ttl.length - 1].text.substr(-8),
        };
        ttl[this.currentRightListPos + consts.LIST_NODES_LIMIT].parent.allchildren.push(lower);
        for (let i = this.currentRightListPos + consts.LIST_NODES_LIMIT; i < ttl.length; i++) {
          if (!ttl[i].lns) {
            throw 'Element ' + ttl[i].name + ' has no lns';
          }
          ttl[i].lns.forEach((ln) => {
            if (lower.lns.indexOf(ln) < 0) {
              lower.lns.push(ln);
            }
          });
          lower._virtual.names.push(ttl[i].name);
        }
        this.data.splice(lowerPos, 0, lower);
      }
    },
    initGroupsMenu() {
      const subnet = this.selectedSubnet;
      const filter = subnet.filters[0];
      this.groupsItems = [];
      filter.active = true;
      $('#filters_form').empty();
      filter.groups.forEach((g, i) => {
        const group = {
          idx: i,
          id: 'group_' + i,
          desc: g.lns.join(',\r\n'),
          name: this.$t(g.name) + ' (' + g.lns.length + ')',
          enabled: true,
        };
        const desc = g.lns.join(',\r\n');
        const cb = $(`
                <div title="${desc}" class="checkbox">
                    <label>
                        <input title="${desc}" id="group_${i}" type="checkbox" checked="checked" />
                        ${this.$t(g.name)} (${g.lns.length})
                    </label>
                </div>
                `);
        g.enabled = true;
        $('#filters_form').append(cb);
        this.groupsItems.push(group);
      });
      $('html').on('click', (e) => {
        if (!$(e.target).is('#filter_btn[data-toggle=popover]') && $(e.target).closest('.popover').length == 0) {
          $('#filter_btn[data-toggle=popover]').popover('hide');
        }
      });
      $('#filter_btn[data-toggle=popover]')
        .popover({
          html: true,
          content: () => {
            return $('#filters_popover').html();
          },
        })
        .click('click', function () {
          /*
           * WORKAROUND:
           * Prevent multiple call click function for popover button.
           * Simple .off("click") for #filters_btn has no effect (fully disable popover)
           */
          filter.groups.forEach((g, i) => {
            $('input#group_' + i).off();
            if (g.enabled) {
              $('input#group_' + i).prop('checked', true);
            } else {
              $('input#group_' + i).prop('checked', false);
            }
            $('input#group_' + i).click(function () {
              const ena = $(this).is(':checked');
              if (!ena) {
                g.enabled = false;
              } else {
                g.enabled = true;
              }
              this.$nextTick(() => {
                this.buildChart(this.selectedSubnet);
              });
            });
          });
        });
    },
    liveViewSwitcherOnClick() {
      dev.log('liveViewSwitcherOnClick', this.LIVE_VIEW_MODE);
      this.LIVE_VIEW_MODE = this.wheel.isOpenWheel ? true : !this.LIVE_VIEW_MODE;
      if (!this.isWheelLivePage) {
        this.wheelSession.saveProp('liveMode', this.LIVE_VIEW_MODE);
      }

      $('#live_view_switcher').prop('checked', this.LIVE_VIEW_MODE);
      $('#view-submenu').toggle(this.LIVE_VIEW_MODE);

      $('#eventsCounterVisibility_switcher').prop('checked', this.wheelSession.eventsCounterVisibility);

      $('.wh-live').toggleClass('wh-live-on');
      $('.wh-live').toggleClass('wh-live-off');
      if (!this.LIVE_VIEW_MODE) {
        $('.events-speed-container').addClass('d-none');
      } else if (this.currentLvl === nodeLevels.MESSAGES_LEVEL) {
        $('.events-speed-container').removeClass('d-none');
      }

      this.updateIndicators();
      // dev.log('LIVE_VIEW_MODE is', this.LIVE_VIEW_MODE);
      if (this.interval1) {
        clearInterval(this.interval1);
      }
      this.interval1 = setInterval(() => {
        if (this.wheel.liveModeOn) {
          clearInterval(this.interval1);
          this.wheel.liveModeOn = this.LIVE_VIEW_MODE;
        }
      }, 1000);
    },
    transformA(svg) {
      svg.attr('transform', `translate(${this.wi / 2}, ${this.hi / 2}) rotate(${this.angle}, 0, 0)`);
    },
    updateIndicators(state) {
      const clear = ($dom) => {
        $dom.removeClass('fa-bolt fa-check-circle fa-times-circle');
      };

      const online = ($dom) => {
        clear($dom);
        $dom.addClass('fa-check-circle -ok');
        $dom.css('color', 'green');
        $dom.css('opacity', 1);
      };

      const offline = ($dom) => {
        clear($dom);
        $dom.addClass('fa-times-circle');
        $dom.css('color', 'red');
        $dom.css('opacity', 1);
      };
      if (state) {
        $('.wh-system-state').show();
        if (state.lvbs) {
          online($('#lvb_ind span'));
        } else {
          offline($('#lvb_ind span'));
        }
        if (state.tpls.alive) {
          online($('#tpls_ind span'));
        } else {
          offline($('#tpls_ind span'));
        }
      } else {
        $('.wh-system-state').hide();
      }
    },
    displayDebugInfo(subnet) {
      $('#debug_sn_name').html(subnet.name + ' (' + subnet.desc + ')');
    },
    getDescNode(e) {
      let str;
      if (e.name == 'USER_SETOFFLINE') {
        str = this.$t(e.name+'^clt', {str: e.data.str, ip: e.data.ip, iedName: e.data.iedName});
      } else {
        e.data = e.data || {};
        e.data[Symbol.iterator] = function* () {
          for (const d in this) {
            yield this[d];
          }
        };
        str = this.$t(e.name, ...e.data);
      }
      return str.replace('GHOSTIED', this.$t('GHOSTIED'));
    },
    getDescEv(obj) {
      dev.log('des', obj.type, obj);
      //функция, используемая для генерации описаний устройств
      if (this.prefs.descriptionType == 'onlyName') {
        return '';
      }
      let desc = '';
      switch (obj.name) {
        case 'GOOSE_CTRL_LABEL':
        case 'SV_CTRL_LABEL':
          desc = (obj.parent.desc || obj.parent.text) + ' ' + (obj.parent.parent.desc || obj.parent.parent.text);
          break;
        case 'GOOSE_FCDA_LABEL':
        case 'SV_FCDA_LABEL':
          desc =
            (obj.parent.parent.desc || obj.parent.parent.text) +
            ' ' +
            (obj.parent.parent.parent.desc || obj.parent.parent.parent.text);
          break;
        case 'GOOSE_FCDA':
        case 'SV_FCDA':
        case 'GOOSE_DATA':
        case 'SV_DATA':
          desc = obj.desc || obj.parent.desc || obj.text || obj.parent.text;
          break;
        case 'INPUTS_LABEL':
        case 'INPUTS_DATA':
          desc = obj.text.replace(/Inputs/, this.$t('Inputs')).replace('not bound', this.$t('not bound'));
          break;
        default:
          desc = obj.desc || obj.parent.desc || obj.text || obj.parent.text;
          break;
      }

      if (!desc.trim() && this.prefs.descriptionType == 'onlyDesc') {
        desc = obj._ix ? obj._ix + '. ' + obj.text : obj.text;
      }
      return desc ? ' ' + desc : '';
    },
    onTouchStart() {
      document.querySelector('body').style.overflow = 'hidden';
    },
    onTouchEnd() {
      document.querySelector('body').style.overflow = 'auto';
    },
    pushList(e) {
      if (e && e.type) {
        this.list.unshift(e);
        if (e.parent) {
          this.pushList(e.parent);
        }
      }
    },
    showDesc(i, prefix) {
      // dev.log('showDesc', i, prefix);
      $('#desc_' + prefix + ' .wh-desc').hide();
      $('#desc_' + prefix + '_head ul li').removeClass('active');
      $('#wh_desc_' + prefix + '_' + i).show();
      $('#wh_desc_li_' + prefix + '_' + i).addClass('active');
    },
    checkParentState(d) {
      if (!d._live.children) {
        return 'unknown';
      } else if (
        Object.keys(d._live.children).every((c) => {
          return !!d._live.children[c];
        }) &&
        (d.allchildren.filter((child) => ~child.type.indexOf('_CTRL')).length == Object.keys(d._live.children).length ||
          d.isGhost)
      ) {
        return 'good';
      } else if (
        Object.keys(d._live.children).every((c) => {
          return !d._live.children[c];
        })
      ) {
        return 'bad';
      } else {
        return 'intermediate';
      }
    },
    /**
     * Checks state for the IED dependent on states of its children
     * @param {any} d IED object
     */
    checkIedLabelState(labelObject) {
      const iedObject = labelObject.parent;
      return this.checkParentState(iedObject);
    },
    formatDescs(prefix) {
      this.list.forEach((e, i) => {
        // dev.log('formatDescs ', e, i)
        this.bulls.push({ i, prefix });
        const desc = {};

        switch (e.type) {
          case 'IED':
            this.ied = e;
            desc['i'] = i;
            desc['ied'] = e;
            desc['prefix'] = prefix;
            desc['headerColor'] = e.color;
            desc['header'] = this.$t('IED');
            desc['list'] = [];
            desc['list'].push({ label: this.$t('IED name'), val: e.name.replace('Ghosts', this.$t('Ghosts')) });
            desc['list'].push({
              label: this.$t('Description'),
              val: e.desc.replace('Ghosts', this.$t('Ghosts')),
            });
            desc['list'].push({ label: this.$t('Vendor'), val: e.manufacturer.replace('n/a', this.$t('n/a')) });

            desc['aps'] = [];
            e.aps.forEach((ap) => {
              desc['aps'].push(ap);
            });
            if (this.currentLvl === nodeLevels.MESSAGES_LEVEL) {
              if (window.location.pathname !== '/open/wheel') {
                desc['lastEventsBtn'] = true;
              }
            }

            desc['lastEventsName'] = e.name;
            desc['lastEventsDesc'] = e.desc;
            break;
          case 'GOOSE_CTRL':
            // if (!e.parent) break;
            this.ied = e.parent ? e.parent : e;
            // dev.log('this.ied', e, this.ied)
            desc['i'] = i;

            desc['ied'] = e.parent;
            desc['prefix'] = prefix;
            desc['headerColor'] = this.ied?.color;
            desc['header'] = this.$t('GOOSE message');
            desc['list'] = [];
            desc['list'].push({ label: this.$t('GOID'), val: e.goid });
            desc['list'].push({ label: this.$t('Dataset'), val: e.datSet.toString().replace('n/a', this.$t('n/a')) });
            desc['list'].push({
              label: this.$t('MAC address'),
              val: e.gse.mac.toString().replace('n/a', this.$t('n/a')),
            });
            desc['list'].push({ label: this.$t('APPID'), val: e.gse.appid.toString().padStart(6, '0x0000') });
            desc['list'].push({
              label: this.$t('VLAN-ID'),
              val: !isNaN(parseInt(e.gse.vlanId, 16))
                ? this.prefs.vlanDisplayType == 'HEX'
                  ? e.gse.vlanId.padStart(5, '0x000')
                  : parseInt(e.gse.vlanId, 16).toString().padStart(4, '0000')
                : e.gse.vlanId.toString().replace('n/a', this.$t('n/a')),
            });
            desc['list'].push({
              label: this.$t('VLAN-PRIORITY'),
              val: e.gse.vlanPriority.toString().replace('n/a', this.$t('n/a')),
            });
            desc['list'].push({
              label: this.$t('MAX TIME'),
              val: !isNaN(e.gse.maxTime)
                ? e.gse.maxTime + ' <span class="tip-label">' + this.$t('ms') + '</span>'
                : this.$t('n/a'),
              ...((!isNaN(e.gse.maxTime) && { val: e.gse.maxTime, units: this.$t('ms') }) || { val: this.$t('n/a') }),
            });
            desc['list'].push({
              label: this.$t('MIN TIME'),
              ...((!isNaN(e.gse.minTime) && { val: e.gse.minTime, units: this.$t('ms') }) || { val: this.$t('n/a') }),
            });

            if (this.currentLvl === nodeLevels.DATASET_LEVEL) {
              if (window.location.pathname !== '/open/wheel') {
                desc['lastEventsBtn'] = true;
              }
            }

            desc['lastEventsName'] = e.goid;
            desc['lastEventsDesc'] = false;

            break;
          case 'SV_CTRL':
            this.ied = e.parent;
            desc['i'] = i;

            desc['ied'] = e.parent;
            desc['prefix'] = prefix;
            desc['headerColor'] = this.ied.color;
            desc['header'] = this.$t('SV stream');
            desc['list'] = [];
            desc['list'].push({ label: this.$t('SVID'), val: e.svid.toString().replace('n/a', this.$t('n/a')) });
            desc['list'].push({ label: this.$t('Dataset'), val: e.datSet.toString().replace('n/a', this.$t('n/a')) });
            desc['list'].push({
              label: this.$t('MAC address'),
              val: e.smv.mac.toString().replace('n/a', this.$t('n/a')),
            });
            desc['list'].push({ label: this.$t('APPID'), val: e.smv.appid.toString().padStart(6, '0x0000') });
            desc['list'].push({
              label: this.$t('VLAN-ID'),
              val: !isNaN(parseInt(e.smv.vlanId, 16))
                ? this.prefs.vlanDisplayType == 'HEX'
                  ? e.smv.vlanId.padStart(5, '0x000')
                  : parseInt(e.smv.vlanId, 16).toString().padStart(4, '0000')
                : e.smv.vlanId.toString().replace('n/a', this.$t('n/a')),
            });
            desc['list'].push({
              label: this.$t('VLAN-PRIORITY'),
              val: e.smv.vlanPriority.toString().replace('n/a', this.$t('n/a')),
            });

            if (this.currentLvl === nodeLevels.DATASET_LEVEL) {
              if (window.location.pathname !== '/open/wheel') {
                desc['lastEventsBtn'] = true;
              }
            }

            desc['lastEventsName'] = e.svid;
            desc['lastEventsDesc'] = false;

            break;
          case 'INPUTS':
            // this.ied = e.parent;
            this.ied = e.parent ? e.parent : e;
            desc['i'] = i;

            desc['ied'] = e.parent;
            desc['prefix'] = prefix;
            desc['headerColor'] = this.ied.color;
            desc['header'] = this.$t('Inputs');

            break;
          case 'INPUTS_DATA':
            this.ied = e.parent.parent;
            desc['i'] = i;

            desc['ied'] = e.parent.parent;
            desc['prefix'] = prefix;
            desc['headerColor'] = this.ied.color;
            desc['header'] = this.$t('Input signal');

            break;
          case 'SV_FCDA':
          case 'GOOSE_FCDA':
            // dev.log('e', e);
            this.ied = e?.parent?.parent;
            desc['i'] = i;

            desc['ied'] = e?.parent?.parent;
            desc['prefix'] = prefix;
            desc['headerColor'] = this.ied.color;
            desc['header'] = this.$t('FCDA');
            desc['list'] = [];
            desc['list'].push({ label: this.$t('Name'), val: e.text });
            desc['list'].push({ label: this.$t('Description'), val: e.desc });
            desc['list'].push({ label: this.$t('Dataset position'), val: e.ix });
            desc['list'].push({ label: this.$t('FC'), val: e.fc });

            break;
          case 'SV_DATA':
          case 'GOOSE_DATA':
            this.ied = e.parent.parent.parent;
            desc['i'] = i;

            desc['ied'] = e.parent.parent.parent;
            desc['prefix'] = prefix;
            desc['headerColor'] = this.ied.color;
            desc['header'] = this.$t('Output signal');
            desc['list'] = [];
            desc['list'].push({ label: this.$t('Description'), val: e.desc });
            break;
          default:
            console.warn(`Unhandled node type (${e.type}). Can't display info`);
            break;
        }

        this.desc.push(desc);
      });
    },
    _eventsNotice(tpwsEvents, lastEvt) {
      // const desc = this.getDescEv(lastEvt);
      const desc = htmlDecode(this.$t('events:' + lastEvt.name + '^clt', lastEvt.data));

      //todo fix speaker
      //speakMessage(desc);

      const notificationsContainer = $('#eventsNotificationsContainer');
      const notificationWrapper = $('<div class="event-notifications-wrapper"/>');

      const mainMessage = $('<div/>', { text: desc, class: 'event-notification' });
      mainMessage.prepend('<span class="fal fa-exclamation mr-2" aria-hidden="true"></span>');

      if (tpwsEvents.length > 1) {
        const serviceMessgae = $('<div/>');
        serviceMessgae
          .text(this.$t('AndOneMoreEvents^count', { count: tpwsEvents.length - 1 }))
          .appendTo(notificationWrapper);
      }

      notificationWrapper
        .prepend(mainMessage)
        .prependTo(notificationsContainer)
        .slideDown(500)
        .click(function () {
          $(this).slideUp(500, function () {
            $(this).remove();
          });
        });
    },
    async Wheel() {
      this.wheelEventsCounter.lastRecievedEventsTs = Date.now() * 1e3; // - 12*24*60*60*1e3*1e3,
      (window.tpwsEvents = []), (window.prevTpwsEventsCount = 0);

      this.history = [];

      this.conditions = undefined;
      this.data = undefined;
      this.root = undefined;
      this.nodes = undefined;
      this.links = undefined;

      this.hi = $('#wheel').height();
      // this.hi = this.hi > 600 ? this.hi : 600;
      this.wi = $('#wheel').width();

      this.rotationSelect = undefined;
      this.angle = 0;
      this.angle0 = 0;

      this.eventsSpeed = '...';
      this.eventsSpeedInterval = undefined;
      this.updateIndicators();

      // this.isWheelLivePage = this.$route.name === 'wheel-live-page';
      if (this.isWheelLivePage && !this.isLive) {
        dev.log('!!! todo redirect to current project');
        // const redirectUrl = this.currentProject ? `/projects/${this.currentProject}` : '/';
        // return location.replace(redirectUrl);
        //todo: vue3 отключено
        // this.$router.push({
        //   name: 'current-project',
        //   params: {
        //     projectId: this.currentProject,
        //   },
        // });
      } else {
        this.LIVE_VIEW_MODE = false;
      }

      $('#wheel').height('100%');
      // $('#doc').addClass('wh-hidden');
      // $('#notices').addClass('wh-hidden');
      $('#loading').hide();

      const eventsSpeedContainer = `
        <div class="events-speed-container alert alert-secondary d-none" role="alert">
            ${this.$t('Number of events in the last')}
            <span id="eventsSpeedInterval">${this.wheelEventsCounter?.timeString}</span>:
            <span id="eventsSpeedResult">${this.eventsSpeed}</span>
        </div>`;

      $('#wheelRecentEvents').prepend(eventsSpeedContainer);

      dev.log('this.wheelSession.liveMode || this.isWheelLivePage', this.wheelSession.liveMode, this.isWheelLivePage);
      //if (wheelSession.liveMode || this.isWheelLivePage) {
      if (this.isWheelLivePage) {
        this.liveViewSwitcherOnClick();
      } else {
        $('#view-submenu').toggle(this.wheelSession.liveMode);
      }

      // $('#im_import_button').on('click', () => {
      //   $('#im_import_file').trigger('click');
      // });

      $('#wheel_status').mousemove((e) => {
        const hint = $('#wheel_status_hint');
        const x = e.clientX,
          y = e.clientY;
        hint.css('top', y + 20 + 'px');
        hint.css('left', x + 20 + 'px');
      });

      /**
       Request events from the projects.
       @param {function} cb -- callback function when all events read from tps.
       @returns {cb}  as call-back function cb with 2 parameters, where firtst Object is error (if no error null shall be passed), second object is evenets object.
       */

      await this.redrawSvg();
      this.mouseovered(null);

      this.loadWheelData();

      this.$slf.wheelNotice = this._eventsNotice;

      $('.no-collapsable').on('click', (e) => {
        e.stopPropagation();
      });
    },
    dsLvlDisplayCond(_node) {
      return (
        _node.type === 'GOOSE_CTRL_LABEL' ||
        _node.type === 'SV_CTRL_LABEL' ||
        _node.type === 'INPUTS_LABEL' ||
        _node.type === 'INPUTS_DATA' ||
        _node.type === 'GOOSE_FCDA' ||
        _node.type === 'SV_FCDA'
      );
    },
    dsLvlEnableCond(_node) {
      return (
        this.iedLvlDisplayCond(_node) ||
        this.msgsLvlDisplayCond(_node) ||
        this.leftListSelection.indexOf(_node) >= 0 ||
        this.rightListSelection.indexOf(_node) >= 0 ||
        (this.isCursorsChild(_node) &&
          (_node.type === 'INPUTS_LABEL' || _node.type === 'GOOSE_CTRL_LABEL' || _node.type === 'SV_CTRL_LABEL'))
      );
    },
    dsTtlListCond(_node) {
      if (_node._virtual && _node._virtual.side === 'left') {
        return true;
      }
      if (this._forcedCursor.type === 'INPUTS') {
        return (
          (_node.type === 'GOOSE_CTRL_LABEL' || _node.type === 'SV_CTRL_LABEL') && this.isInCursorsChildLImports(_node)
        );
      } else {
        return (_node.type === 'GOOSE_FCDA' || _node.type === 'SV_FCDA') && this.isCursorsChild(_node);
      }
    },
    msgsLvlDisplayCond(_node) {
      return (
        _node.type === 'IED_LABEL' || _node.type === 'INPUTS' || _node.type === 'GOOSE_CTRL' || _node.type === 'SV_CTRL'
      );
    },
    isInCursorsChildLImports(_node) {
      return this._forcedCursor?.allchildren?.some((cc) => {
        return cc.lImports && cc.lImports.indexOf(_node.name) >= 0;
      });
    },
    dsTtlRightListCond(_node) {
      if (_node._virtual && _node._virtual.side === 'right') {
        return true;
      }
      if (this._forcedCursor.type !== 'INPUTS') {
        return _node.type === 'INPUTS_LABEL' && this.hasLinkWithSomeCursorsChild(_node);
      } else {
        return _node.type === 'INPUTS_DATA' && this.isCursorsChild(_node);
      }
    },
    isCursorsChild(_node) {
      return this._forcedCursor?.allchildren?.indexOf(_node) >= 0;
    },
    iedLvlDisplayCond(_node) {
      return _node.type === 'IED';
    },
    iedLvlEnableCond(_node) {
      return !this.msgsLvlDisplayCond(_node);
    },
    hasLinkWithSomeCursorsChild(_node) {
      return this._forcedCursor?.allchildren?.some((cc) => cc.lns && cc.lns.includes(_node.name));
    },
    msgsLvlEnableCond(_node, ied = this._forcedCursor) {
      switch (_node.type) {
        case 'IED_LABEL':
          return true;
        case 'IED':
          if (_node === ied || ied.lns.indexOf(_node.name) >= 0) {
            return true;
          }
          break;
        case 'INPUTS':
        case 'GOOSE_CTRL':
        case 'SV_CTRL':
          const cnames = ied.allchildren.map((c) => c.name);

          if (ied.allchildren.includes(_node) || !!_node.lns.find((ln) => cnames.includes(ln))) {
            return true;
          }
      }
      return false;
    },
    sLvlDisplayCond(_node) {
      return (
        _node.type === 'GOOSE_FCDA_LABEL' ||
        _node.type === 'SV_FCDA_LABEL' ||
        _node.type === 'INPUTS_LABEL' ||
        _node.type === 'INPUTS_DATA' ||
        _node.type === 'GOOSE_DATA' ||
        _node.type === 'SV_DATA'
      );
    },
    sLvlEnableCond(_node) {
      const isAnySelectedSignalParent = (d, ss) => {
        return ss.some((s) => {
          return s.parent === d;
        });
      };
      const hasSameParent = (d, ss) => {
        return ss.some((s) => {
          return s.parent === d.parent;
        });
      };

      return (
        this.iedLvlDisplayCond(_node) ||
        this.msgsLvlDisplayCond(_node) ||
        (_node.type === 'INPUTS' && isAnySelectedSignalParent(_node, this.rightListSelection)) ||
        ((_node.type === 'GOOSE_FCDA' || _node.type === 'SV_FCDA') &&
          isAnySelectedSignalParent(_node, this.leftListSelection)) ||
        this.leftListSelection.indexOf(_node) >= 0 ||
        this.rightListSelection.indexOf(_node) >= 0 ||
        ((_node.type === 'GOOSE_FCDA_LABEL' || _node.type === 'SV_FCDA_LABEL') &&
          hasSameParent(_node, this.leftListSelection)) ||
        (_node.type === 'INPUTS_LABEL' && hasSameParent(_node, this.rightListSelection))
      );
    },
    sLvlImportsCond(_node) {
      return _node.type === 'INPUTS_DATA' || _node.type === 'GOOSE_DATA' || _node.type === 'SV_DATA';
    },
    sTtlListCond(_node) {
      if (_node._virtual && _node._virtual.side === 'left') {
        return true;
      }
      if (this._forcedCursor?.type === 'INPUTS') {
        return (
          (_node.type === 'GOOSE_DATA' || _node.type === 'SV_DATA') &&
          this.cursor.imports &&
          this.cursor.imports.indexOf(_node.name) >= 0
        );
      } else {
        return (_node.type === 'GOOSE_DATA' || _node.type === 'SV_DATA') && this.isCursorsChild(_node);
      }
    },
    sTtlRightListCond(_node) {
      if (_node._virtual && _node._virtual.side === 'right') {
        return true;
      }
      if (this._forcedCursor?.type !== 'INPUTS') {
        return _node.type === 'INPUTS_DATA' && this.hasLinkWithSomeCursorsChild(_node);
      } else {
        return _node.type === 'INPUTS_DATA' && _node === this.cursor;
      }
    },
    msgsLvlImportsCond(_node, importStr) {
      const ied = this._forcedCursor;

      return (
        (_node.type === 'INPUTS' || _node.type === 'GOOSE_CTRL' || _node.type === 'SV_CTRL') &&
        (this.isCursorsChild(_node) || (!this.isCursorsChild(_node) && importStr.indexOf(ied.name) === 0))
      );
    },
  },
  watch: {
    eJsonUpdate: {
      handler(val) {
        this.setSubnets();
      },
      // immediate: true,
    },
    subnetsUpdate: {
      handler(val) {
        this.selectSubnet();
      },
      // immediate: true,
    },
    currentSubnet: {
      handler(val) {
        this.selectSubnet();
      },
      // immediate: true,
    },
    selectedSubnetData: {
      handler(val) {
        this.switchToNode();
      },
      // immediate: true,
    },
    nodeToSwitch: {
      handler(val) {
        this.switchToNode();
      },
      // immediate: true,
    },
    '$route.name': {
      handler(val) {
        if (['open-wheel-page', 'wheel-page', 'wheel-live-page'].includes(val)) {
          dev.log('on exit route');
          this.init();
        }
      },
      // immediate: true,
    },
    cursor: {
      handler(newVal, oldVal) {
        if (newVal?.subnet !== oldVal?.subnet || newVal?.name !== oldVal?.name) {
          dev.log('watch wheel.cursor', newVal, this.wheel.pathTree, this.subToSwitch, this.nodeToSwitch);
          this.onChangeCursor();
          this.replaceLocation();
          this.initBreadcrumbs();
        }
        // setTimeout(() => {
        //   this.initBreadcrumbs();
        // }, 600);
      },
    },
  },
};
</script>

<style lang="scss">
.check-group {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}
.wheel-groups-filter {
  border-radius: 5px;
  &__header {
    padding: 10px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
  }
  &__list {
    padding: 10px;
    list-style-type: none;
    & li {
    }
  }
}
.wheel-tree-view {
  border-radius: 5px;
  &__header {
    padding: 10px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
  }
  &__list {
    background-color: white;
    padding: 10px;
    list-style-type: none;
    & li {
    }
  }
}
.t-layout {
  & .wheel-desc-block {
    min-height: 100%;
    flex: auto;
    display: flex;
    background-color: #e5e5e5;
    & .n-scrollbar > .n-scrollbar-rail {
      z-index: 999;
    }
  }

  &--full-height {
    flex: auto;
  }
}
</style>
<style scoped lang="scss">
.wheel-nav-btn {
  background: rgba(23, 87, 189, 0.1);
  color: #1757bd;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
