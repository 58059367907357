<template>
  <div class="data-table" ref="pageRoot">
    <teleport to="#modals-target">
    <export-modal v-model:visibleModal="visibleExportDialog" @doExport="doExport" object="analysis"></export-modal>
    </teleport>
    <analysis-header :data="header" class=""></analysis-header>

    <div class="data-table-buttons events-top-controllers">
      <div class="dt-buttons btn-group">
        <template v-for="b in buttons">
          <div class="btn-group" v-if="b.children">
            <button
              class="btn btn-secondary dropdown-toggle"
              :class="'data-table-' + b.name"
              :id="'submenu-' + b.name"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="b.name === 'events-export' && reports?.hasPendingReports"
            >
              {{ $t(b.label) }}
            </button>

            <div class="dropdown-menu" :aria-labelledby="'submenu-' + b.name">
              <div class="dt-button-collection-title"></div>
              <template v-for="cb in b.children">
                <a
                  class="dt-button dropdown-item buttons-pdf buttons-html5"
                  tabindex="0"
                  @click="execAction(cb, $event)"
                  ><span>{{ $t(cb.label) }}</span></a
                >
              </template>
            </div>
          </div>
          <button
            class="btn btn-secondary"
            :class="[
              'data-table-' + b.name,
              {
                disabled:
                  (b.name === 'events-export' && reports?.hasPendingReports) ||
                  (!enableView && b.name === 'view-selected'),
              },
            ]"
            @click="execAction(b, $event)"
            :disabled="
              (b.name === 'events-export' && reports?.hasPendingReports) || (!enableView && b.name === 'view-selected')
            "
            v-else
          >
            {{ $t(b.label) }}
          </button>
        </template>
      </div>
      <div v-if="!noSearch" id="vue_events_table_filter" class="dataTables_filter">
        <label
          >{{ $t("Search") }}<input
            v-model="searchString"
            type="search"
            class="form-control form-control-sm"
            placeholder=""
            aria-controls="wheel_events_table"
        /></label>
      </div>
    </div>
    <div style="display: none" class="print-block" ref="print-block" id="printBlock">
      <analysis-header :data="header"></analysis-header>
      <table class="data-table display" :class="className" ref="print-tab">
        <thead>
          <tr role="row">
            <th v-for="f in fields" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
              {{ $t(f.label) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in filteredPrintRowsData"
            :row-data="rowData"
            :fields="fields"
            :key="rowData._id"
            :print="true"
            :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1 }]"
            @select="selectRow(idx)"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ $t(f.label) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="data-table-loading-wrapper pb-5" v-loading="bundle.bundleLoading">
      <table class="data-table display" :class="className">
        <col v-for="f in fields" :key="f.name" :style="f.style" />

        <thead>
          <tr role="row">
            <th v-for="f in fields" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
              {{ $t(f.label) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in pageRowsData"
            :row-data="rowData"
            :fields="fields"
            :key="rowData._id"
            :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
            class="analysis-cell"
            @select="selectRow(idx)"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ $t(f.label) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      class="floating-pager"
      style="width: auto"
      :style="{ left: visual.contentLeft + Math.floor(visual.contentWidth / 2) + 'px' }"
    >
      <div class="floating-pager__inner">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="currentPage"
          :default-current-page="1"
          :page-sizes="[7, 17, 50, 100, 300]"
          :page-size="pageLength"
          layout="total, sizes, prev, pager, next, jumper"
          :total="recordsFiltered"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue';
// import loading from 'vue-loading';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/css/index.css';

import ExportModal from '@/components/modals/ExportModal.vue';
import AnalysisHeader from '@/components/analysis/AnalysisHeader.vue';
// import resize from 'vue-resize-directive';

import api from '@/services/api';
import { Zone, DateTime } from 'luxon';
import VueSticky from 'vue-sticky';
import { Printd } from 'printd'
import tinyPrint from 'tiny-print';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { useTpui } from '@/stores/tpui';
import { usePrefs } from '@/stores/prefs';
import { useVisual } from '@/stores/visual';
// import { useRoot } from '@/stores/root';
import { useReports } from '@/stores/reports';
import { useLicense } from '@/stores/license';
import { useBundle } from '@/stores/bundle';
import { markRaw } from 'vue';
import {firstBy} from "thenby";
const sortAlias = {
  elementDT: 'elementDTsearch',
  warnLevelDT: 'warnLevelText',
}
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    // buttons: {
    //   type: Array,
    // },
    fields: {
      type: Array,
    },
    lengthMenu: {
      type: Array,
    },
    noSearch: {
      defauld: false,
    },
    extButtons: {
      type: Array,
    },
    query: {
      type: Object,
    },
    atrs: Object,
  },
  // directives: {
  //   resize,
  // },

  components: { ExportModal, DataRow, Loading, AnalysisHeader },
  // directives: { loading },
  name: 'analysis-table',
  data: () => {
    return {
      sortables: [],
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      pageStart: 0,
      pageLength: 17,
      searchString: '',
      // searchTypes: [],
      jsn: undefined,
      rowsData: [],
      printRowsData: [],
      recordsTotal: 0,
      // recordsFiltered: 0,
      loading: false,
      firstLoad: true,
      draw: 0,
      ts: 0,
      errors: [],
      onTypes: [],
      criticalOnly: false,
      selectedRows: [],
      visibleArchiveDialog: false,
      visibleExportDialog: false,
      thisEvTable: undefined,
      nums: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
        30,
      ],
      header: {
        versionInfo: undefined,
        isActive: undefined,
        minorsCnt: undefined,
        criticalsCnt: undefined,
        withErrors: undefined,
        noticesStatHtml: undefined,
        noticesStatStr: undefined,
      },
    };
  },
  computed: {
    ...mapStores(useBundle),
    ...mapStores(useReports),
    // ...mapStores(useRoot),
    ...mapStores(useTpws),
    ...mapStores(useTpui),
    ...mapStores(usePrefs),
    ...mapStores(useVisual),
    ...mapStores(useLicense),
    currentProject() {
      return this.tpws.currentProject;
    },
    currentProjectName() {
      return this.tpws.displayName;
    },
    isLivePage() {
      return this.$route.name == 'analysis-live';
    },
    currentSortFieldA(){
      return sortAlias[this.currentSortField] || this.currentSortField;
    },
    recordsFiltered() {
      return this.filteredRowsData.length;
    },
    filteredPrintRowsData() {
      let rows = this.printRowsData.filter((el) => {
        let search = this.searchString.toLowerCase();
        if (el.elementDTsearch.toLowerCase().indexOf(search) > -1) {
          return el;
        }
        if (el.elementTypeDT.toLowerCase().indexOf(search) > -1) {
          return el;
        }
        if (el.warnLevelDT.toLowerCase().indexOf(search) > -1) {
          return el;
        }
        if (el.descriptionDT.toLowerCase().indexOf(search) > -1) {
          return el;
        }
      });
      if (this.criticalOnly) {
        rows = rows.filter((el) => el.warnLevel === 2);
      }
      return rows;
    },
    filteredRowsData() {
      let rows = this.rowsDataSorted.filter((el) => {
        let search = this.searchString.toLowerCase();
        if (el.elementDTsearch.toLowerCase().indexOf(search) > -1) {
          return el;
        }
        if (el.elementTypeDT.toLowerCase().indexOf(search) > -1) {
          return el;
        }
        if (el.warnLevelDT.toLowerCase().indexOf(search) > -1) {
          return el;
        }
        if (el.descriptionDT.toLowerCase().indexOf(search) > -1) {
          return el;
        }
      });
      if (this.criticalOnly) {
        rows = rows.filter((el) => el.warnLevel === 2);
      }
      return rows;
    },
    rowsDataSorted(){
      const sorted = [...this.rowsData.sort(firstBy(this.currentSortFieldA, this.currentSortDir === 1 ? 'asc': 'desc'))]
      return sorted;
    },
    pageRowsData() {
      let start = (this.currentPage - 1) * this.pageLength;
      let end = this.currentPage * this.pageLength;
      if (end > this.filteredRowsData.length) {
        end = this.filteredRowsData.length;
      }
      let rows = this.filteredRowsData.slice(start, end);
      return rows;
    },
    buttons() {
      let buttons = [
        {
          name: 'print',
          label: 'Print',
          action: 'printTable',
        },
        {
          name: 'events-export',
          label: 'Export',
          action: 'exportDialog',
        },
        {
          name: 'view-critical',
          label: !this.criticalOnly ? 'View critical' : 'Show all',
          action: 'viewCritical',
        },
      ];

      let found;

      return buttons;
    },
    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    enableView() {
      return this.rowsDataSelected.filter((e) => e.hasRef).length > 1;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    order() {
      return {
        column: this.fields.map((e) => e.name).indexOf(this.currentSortField),
        dir: this.currentSortDir === 1 ? 'asc' : 'desc',
      };
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$t('Goose') },
        { name: 'SV', label: this.$t('SV') },
        { name: 'SRV', label: this.$t('Service events') },
        { name: 'ARP', label: this.$t('ARP') },
        { name: 'MMS', label: this.$t('MMS') },
        { name: 'USER', label: this.$t('User events') },
      ];
    },

    search() {
      let searchArray = [];
      if (this.searchString !== '') {
        searchArray = [this.searchString];
      }
      return {
        value: searchArray.join(','),
        regex: false,
      };
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      let searchObj = {
        draw: this.draw,
        order,
        search,
        start: this.pageStart,
        length: this.pageLength,
      };

      if (this.query) {
        return this.$qs.stringify({ ...this.query, ...searchObj, removeUnfiltered: true });
      } else {
        return this.$qs.stringify(searchObj);
      }
    },
  },
  beforeMount() {
    try {
      let onTypes = this.eventTypes.map((e) => e.name);
      let ss = this.$route.query;
      let ssArr = this.$qs.parse(ss);
      let types = ssArr['search']
        .split(',')
        .filter((e) => e.indexOf('!') > -1)
        .map((e) => e.substring(1));
      dev.log('ss', ssArr, onTypes);
      onTypes.forEach((el) => {
        if (types.indexOf(el) < 0) {
          this.onTypes.push(el);
        }
      });
      this.$nextTick(() => {
        this.visibleTable = true;
      });
    } catch (e) {
      this.visibleTable = true;
    }
    if (this.extButtons) {
      this.buttons = this.extButtons;
    }
  },
  mounted() {
    dev.log('mnt', this.tpws);
    this.$router.isReady().then(() => {
      this.setDefaultSort();
      this.loadData();
    });

    if (this.$route.query.page) {
      this.setCurrentPage(Number(this.$route.query.page));
    }
  },
  methods: {
    printTable() {
      // const d = new Printd()
      // d.print(this.$refs['print-block'], '.color { color: blue; }')
      this.loadPrintData().then(() => {
        dev.log('pr th');
        this.$nextTick(() => {
          tinyPrint(this.$refs['print-block'], {
            scanStyles: true,
            scanHTML: true,
            cssStyle: `a { text-decoration: none!important; color: black; } .btn-link {word-break: break-all; display: inline-block; color: black;} .data-table .analysis-cell {
    word-break: break-all;
}`,
            hidePageRule: false,
          });
          this.$nextTick(() => {
            this.printRowsData = [];
          });
        });
      });
    },
    loadPrintData() {
      return new Promise((resolve, reject) => {
        this.printRowsData = this.rowsData;
        this.$nextTick(() => {
          resolve('Success!');
          this.loading = false;
        });
      });
    },

    handleSizeChange(e) {
      this.pageLength = e;
      dev.log('set size', e);
    },
    handleCurrentChange(e) {
      // this.currentPage = e;
      dev.log('set page', e);
    },
    doExport(e) {
      dev.log('doExport', e);
      if (!e?.format) {
        return;
      }

      let exportFormat = e.format;
      let fileDateTime = DateTime.local().toFormat('yyyyLLdd');
      let exportFilename =
        `${this.$t('Tekvel park')} - ${this.$t('project')} ${this.currentProjectName} - ${this.$t(
          'diagnostic'
        )} - ${fileDateTime}.` + exportFormat;

      this.$socket.send(
        JSON.stringify({
          type: 'diagnostic-file-export',
          content: {
            data: this.filteredRowsData,
            projectName: this.currentProject,
            displayName: this.tpws.displayName,
            header: {
              version: this.header.versionInfo?.text,
              summary: this.header.noticesStatHtml,
            },
            license: this.license.license.license_data.version,
            language: this.prefs.language,
            exportFilename: exportFilename,
            exportFormat: exportFormat,
          },
        })
      );
    },
    switchType(type) {
      dev.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.$nextTick(() => {
        // this.pageChange();
        this.$emit('pageUpdate');
      });
    },

    execAction(b, e) {
      if (b.extAction) {
        this.$emit('ext-action', b.extAction, e);
      }
      if (b.action) {
        this[b.action](e);
      }
    },
    selectAll() {
      for (let i = 0; i < this.pageLength - 2; i++) {
        const rowInx = this.pageStart + i;
        if (this.selectedRows.indexOf(rowInx) < 0) {
          this.selectedRows.push(rowInx);
        } else {
          // this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
        }
      }
    },
    exportDialog(e) {
      this.visibleExportDialog = true;
    },
    viewCritical(e) {
      this.criticalOnly = !this.criticalOnly;
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      dev.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    loadData() {
      this.draw++;
      dev.log('sq', this.queryString);
      let dataUrl = this.url;
      let options = {};
      this.selectedRows = [];
      this.loading = true;

      this.$emit('pageUpdate');

      if (this.isLivePage) {
        this.jsn = this.bundle.activeBundle;
      } else {
        this.jsn = this.bundle.viewedBundle;
      }
      // this.loading = true;
      // if (this.jsn.isFulfilled) {
      //   dev.log('isFulfilled');
      //   this.$nextTick(() => {
      //     this.convertData(this.jsn);
      //   });
      // } else {
      Promise.resolve(this.jsn).then((result) => {
        dev.log('jsn res', result);

        this.$nextTick(() => {
          this.convertData(result);
        });
      });
      // }

      // this.convertData(this.jsn);
    },
    convertData(reJson) {
      const eJson = markRaw(reJson);
      dev.log('convertData', eJson);
      let arrData = [];

      if (eJson.subnets) {
        arrData = eJson.subnets.reduce((arr, sub) => {
          sub.data.forEach((d) => (d.subnet = sub.name));
          return [...arr, ...sub.data];
        }, []);
      }

      // dev.log('arrData', arrData);
      this.generateNotices(arrData, eJson.validationErrors);
    },
    generateNotices(data, validationErrors) {
      let criticalOnly = false;

      const MINOR = 1,
        CRITICAL = 2;

      const sclValidationErrorsArr = validationErrors
        ? validationErrors
            .replace(/Warning: /g, 'Warning:')
            .split('__NEXT__')
            .map((errLine) => errLine.split(': '))
        : [];
      let minorsCnt = 0,
        criticalsCnt = sclValidationErrorsArr.length;

      data.forEach((d) =>
        'notices' in d
          ? d.notices.forEach((notice) =>
              notice.type === MINOR ? minorsCnt++ : notice.type === CRITICAL ? criticalsCnt++ : 0
            )
          : 0
      );

      const tr_SV_CTRL = this.$t('SV message');
      const tr_GOOSE_CTRL = this.$t('GOOSE message');
      const tr_GOOSE_FCDA = this.$t('FCDA');
      const tr_GOOSE_DATA = this.$t('Data attribure');
      const tr_INPUTS_DATA = this.$t('ExtRef');

      function wrapElementType(type) {
        const elementType = {
          SV_CTRL: tr_SV_CTRL,
          GOOSE_CTRL: tr_GOOSE_CTRL,
          GOOSE_FCDA: tr_GOOSE_FCDA,
          GOOSE_DATA: tr_GOOSE_DATA,
          INPUTS_DATA: tr_INPUTS_DATA,
        };
        return type in elementType ? elementType[type] : type;
      }

      const tr_Minor = this.$t('Minor');
      const tr_Critical = this.$t('Critical');

      function wrapWarnLevel(lvl) {
        const warnLevel = {};
        (warnLevel[MINOR] = `<span class="badge badge-warning">${tr_Minor}</span>`),
          (warnLevel[CRITICAL] = `<span class="badge badge-danger">${tr_Critical}</span>`);
        return lvl in warnLevel ? warnLevel[lvl] : lvl;
      }
      function wrapWarnLevelText(lvl) {
        const warnLevel = {};
        (warnLevel[MINOR] = `${tr_Minor}`), (warnLevel[CRITICAL] = `${tr_Critical}`);
        return lvl in warnLevel ? warnLevel[lvl] : lvl;
      }

      const withErrors = minorsCnt > 0 || criticalsCnt > 0;
      this.header.withErrors = withErrors;
      this.header.minorsCnt = minorsCnt;
      this.header.criticalsCnt = criticalsCnt;

      const noticesStatHtml =
        minorsCnt < 1 && criticalsCnt < 1
          ? this.$t('No minor, nor critical errors in SCL file')
          : this.$t('analysisHeader^minorsAndCriticals', {
              minors: minorsCnt,
              criticals: criticalsCnt,
              total: minorsCnt + criticalsCnt,
            }) +
            '<br/>' +
            this.$t('Check the table below for details');
      const noticesStatStr =
        minorsCnt < 1 && criticalsCnt < 1
          ? this.$t('No minor, nor critical errors in SCL file')
          : this.$t('analysisHeader^minorsAndCriticals', {
              minors: minorsCnt,
              criticals: criticalsCnt,
              total: minorsCnt + criticalsCnt,
            });

      this.header.noticesStatHtml = noticesStatHtml;
      this.header.noticesStatStr = noticesStatStr;

      const { inProcessing } = this.tpws?.inProcessing || false;

      this.header.isActive = this.isLivePage;

      let reportVersion;
      if (this.header.isActive) {
        reportVersion = this.tpws.activeVersion;
      } else {
        reportVersion = this.tpws.viewedVersion;
      }
      const versionInfo = {
        file: reportVersion?.origin,
        date: new Date(reportVersion?.date).customFormat('#DD#.#MM#.#YYYY#'),
        version: reportVersion?.version,
      };

      versionInfo['text'] = `${this.$t('Current version')}: ${versionInfo.version}. ${this.$t('Filename')}: ${
        versionInfo.file
      }, ${this.$t('uploaded')} ${versionInfo.date}\n\n${
        inProcessing
          ? this.$t('The new version of the project is in processing...') +
            '. ' +
            this.$t('Information may be incomplete') +
            '\n\n'
          : ''
      }`;

      this.header.versionInfo = versionInfo;

      this.rowsData = [];

      data.forEach((d) =>
        'notices' in d
          ? d.notices.forEach((notice) => {
              let _msg = notice.msg;
              const args = notice.arguments ? notice.arguments : [];
              const node =
                d.type === 'GOOSE_CTRL' || d.type === 'SV_CTRL'
                  ? encodeURIComponent(d.text)
                  : encodeURIComponent(d.name);
              if (_msg.match(/(')([^']+)(' is not a valid value of the local atomic type\.)/))
                _msg = _msg.replace(/(')([^']+)(' is not a valid value of the local atomic type\.)/, '$1$1$3');

              this.rowsData.push({
                routeData: {
                  title: d.text ? d.text : d.path,
                  name: this.isLivePage ? 'wheel-live-page' : 'wheel-page',
                  query: {
                    node: node,
                    sub: encodeURIComponent(d.subnet),
                  },
                },
                elementDTsearch: `${d.text ? d.text : d.path}`,
                elementTypeDT: `${wrapElementType(d.type)}`,
                warnLevelDT: `${wrapWarnLevel(notice.type)}`,
                warnLevelText: `${wrapWarnLevelText(notice.type)}`,
                warnLevel: notice.type,
                descriptionDT: `${this.$t(_msg, ...args)}`,
              });
            })
          : 0
      );

      // console.time('sclValidation');
      const tr_Element = this.$t('Element');
      const tr_attribute = this.$t('attribute');
      const tr_line = this.$t('line');
      const tr_more_then = this.$t('more then');
      sclValidationErrorsArr.forEach((valErr) => {
        this.rowsData.push({
          routeData: {
            title: `${this.$slf.diagnosis.parseValidationElementReference(valErr[1])}`,
          },
          elementDTsearch: `${this.$slf.diagnosis.parseValidationElementReference(valErr[1])}`,
          elementTypeDT: `SCL`,
          warnLevelDT: `${wrapWarnLevel(valErr[0] == 'Error' ? CRITICAL : MINOR)}`,
          warnLevelText: `${wrapWarnLevelText(valErr[0] == 'Error' ? CRITICAL : MINOR)}`,
          warnLevel: valErr[0] == 'Error' ? CRITICAL : MINOR,
          descriptionDT: `${this.$slf.diagnosis.parseValidationErrors(valErr.filter((e, i) => i >= 2).join(': '))}`,
        });
      });
      // console.timeEnd('sclValidation');

      if (withErrors) {
        $('#diagnosis .wh-page').append(
          `<div class="row"><h2 class="diagnosis__subtitle">${this.$t('Detailed information')}</h2></div>`
        );
      }
      // $('#diagnosis .wh-page').append(table); //todo what is table

      let printMessage = '';
      const addPrintMessage = async () => {
        try {
          const projectHistory = await api.get('/projects/' + this.currentProject + '/history');
          const lastVersion = projectHistory.length - 1;
          const messageInfo = {
            file: projectHistory[lastVersion]?.origin,
            date: new Date(projectHistory[lastVersion]?.date).customFormat('#DD#.#MM#.#YYYY#'),
            version: projectHistory[lastVersion]?.version,
          };
          printMessage = `
      ${this.$t('Current version')}: ${messageInfo.version}. ${this.$t('Filename')}: ${messageInfo.file}, ${this.$t(
            'uploaded'
          )} ${messageInfo.date}. \n\n
                ${
                  inProcessing
                    ? `${this.$t('The new version of the project is in processing...')}. ${this.$t(
                        'Information may be incomplete'
                      )}\n\n`
                    : ''
                }`;
          // this.header.versionInfo = '<p>' + printMessage + '</p>';
        } catch (e) {
          console.error('Fail to create a message for printing: could not get project history', e);
          printMessage = '';
        }

        function customizeExportPdf(doc) {
          if (!withErrors) {
            doc.content[2].table.body.push([
              [{ text: this.$t('No errors'), style: 'tableBodyOdd' }],
              [{ text: '', style: 'tableBodyOdd' }],
              [{ text: '', style: 'tableBodyOdd' }],
              [{ text: '', style: 'tableBodyOdd' }],
            ]);
          }

          const colCount = new Array();
          $('#project-analysis-table')
            .find('tbody tr:first-child td')
            .each(function () {
              if ($(this).attr('colspan')) {
                for (let i = 1; i <= $(this).attr('colspan'); i++) {
                  colCount.push('*');
                }
              } else {
                colCount.push('*');
              }
            });

          doc.styles.table = {
            alignment: 'center',
            width: '100%',
          };

          doc.styles.tableHeader.alignment = 'left';
          doc.content[2].table.widths = colCount;
        }
      };
      addPrintMessage();
      this.loading = false;
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      let classes = [];
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          classes.push('sorting_asc');
        }
        if (this.currentSortDir === -1) {
          classes.push('sorting_desc');
        }
      } else {
        classes.push(f.sortable ? 'sorting' : '');
      }
      if (f.classes) {
        classes = classes.concat(f.classes);
      }
      return classes;
    },
    columnClass(f) {
      let classes = [];
      if (f.classes) {
        classes = classes.concat(f.classes);
      }
      return classes;
    },
    changeSort(f) {
      dev.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.loadData();
      this.$emit('pageUpdate');
    },
  },
  watch: {
    searchString: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
    pageLength: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    pageStart: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    // jsn: {
    //   handler(data) {
    //     dev.log('jsn upd', data);
    //
    //     Promise.resolve(data).then((result) => {
    //       dev.log('jsn res', result);
    //       this.loading = false;
    //       this.$nextTick(()=>{
    //         this.convertData(result);
    //       })
    //
    //     });
    //     this.loading = false;
    //   },
    //   deep: true,
    // },
  },
};
</script>

<style scoped>
.floating-pager {
  position: fixed;
  bottom: 0;
  text-align: center;
}

.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .analysis-cell {
  word-break: break-all;
  hyphens: auto;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
  *cursor: hand;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
