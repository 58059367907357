import { createPinia, setMapStoreSuffix } from 'pinia';
import { markRaw } from 'vue/dist/vue.esm-bundler';
// import { PiniaSharedState } from 'pinia-shared-state';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { usePersist } from 'pinia-use-persist';
// import PiniaRouter from 'pinia-plugin-router';
// import { router } from '@/router';
// import router from '@/router';
// import { globalProperties } from '@/root.app';
// import { router } from '@/root.app';

setMapStoreSuffix('');
const store = createPinia();
// store.use(usePersist);
store.use(piniaPluginPersistedstate);
// store.use(
//   PiniaLogger({
//     expanded: true,
//     disabled: import.meta.env ? import.meta.env.mode === 'production' : process.env.mode === 'production',
//   })
// );
// store.use(
//   PiniaSharedState({
//     // Enables the plugin for all stores. Defaults to true.
//     enable: false,
//     // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
//     initialize: false,
//     // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
//     type: 'localstorage',
//   })
// );
// store.use(PiniaRouter(router));
// store.use(PiniaRouter(globalProperties.$rt));
// store.$router = markRaw(globalProperties.$rt);

// store.use(
//   storePlugin({
//     stores: ['auth', 'visual', 'unhandledEvents'],
//   })
// );

export default store;
