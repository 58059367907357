<script>
export default {
  name: 'data-row-field',
  props: {
    row: {
      type: Object,
    },
    f: {},
  },
  render() {
    // use an array to return multiple root nodes
    return this.f.render(this.row[this.f.data], this.row, this.row);
  },
};
</script>

<style scoped></style>
