<template>
  <div class="" v-resize="onResize" ref="thisEl">
    <t-icon v-if="type === 'iconed'" class="items-list__icon" :size="20" :icon="item.icon"></t-icon>
    <div v-if="type === 'tagged'">
      <div class="items-list__tag">
        <div
          class="items-list__tag-title"
          :class="{
            'not-fit': !item.inView,
          }"
        >
          <n-popover v-if="true" class="tp-popover" trigger="hover">
            <template #trigger>
              <div>
                <ell :text="name"></ell>
              </div>
            </template>

            <div>{{ name }}</div>
          </n-popover>
        </div>
      </div>
    </div>
    <div v-if="type === 'iconed'">
      <div class="items-list__title" :title="item.description">{{ name }}</div>
    </div>
  </div>
</template>

<script>
// import resize from 'vue-resize-directive';
import TIcon from '@/components/ui/TIcon.vue';
import Ell from '@/components/clusters/Ell.vue';

import Popper from 'vue3-popper';
// import 'vue-popperjs/dist/vue-popper.css';
import '../../../assets/vue-popper-custom.css';

import { mapStores } from 'pinia';
import { usePrefs } from '@/stores/prefs';
import { NPopover } from 'naive-ui';

export default {
  name: 'limited-item',
  props: {
    item: {
      type: Object,
    },
    parentSize: {
      type: DOMRect,
    },
    type: {
      type: String,
    },
  },
  components: {
    TIcon,
    Ell,
    Popper,
    NPopover,
  },
  directives: {
    // resize,
  },
  data: () => {
    return {
      tooBig: false,
    };
  },
  computed: {
    ...mapStores(usePrefs),
    name() {
      if (this.item?.raw && (this.item?.raw?.name || this.item?.raw?.desc)) {
        if (this.prefs.descriptionType === 'onlyName') {
          return this.item.raw.name;
        }
        if (this.prefs.descriptionType === 'onlyDesc') {
          return this.item.raw.desc;
        }
        if (this.prefs.descriptionType === 'nameAndDesc') {
          return this.item.raw.name + ' (' + this.item.raw.desc + ')';
        }
      }
      return this.item.title;
    },
  },
  methods: {
    onResize(e) {
      const child = this.$refs.thisEl.getBoundingClientRect();
      const parent = this.parentSize;
      const inView =
        parent.left <= child.left &&
        parent.top <= child.top &&
        parent.right >= child.right &&
        parent.bottom >= child.bottom;
      const right = child.right - parent.left;

      // console.log('w', parent.width, child.width, parent.width <= child.width);
      // this.tooBig = parent.width - 32 < child.width;

      // console.log('child', child);
      // console.log('parent', parent);
      if (this.item.inView !== inView || this.item.right !== right) {
        this.$emit('inViewChange', { inView: inView, right: right });
      }
    },
  },
  watch: {
    parentSize: function (old, val) {
      if (JSON.stringify(old) !== JSON.stringify(val)) this.onResize();
    },
  },
};
</script>

<style scoped>
.popper {
  background: #1757bd;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  border-color: #1757bd;
}
</style>

<style>
.popper[x-placement^='top'] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: #13499e transparent transparent transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
</style>
