const consts = {
    ANIMATION_DURATION: 200,
    EDGE_PADDING: 65,
    HW_OFFSET_PX: 50,
    IED_ARC_SHIFT_DEG: 0.1,
    WHEEL_DIAGRAM: 'wh',
    LIST_DIAGRAM: 'lst',
    LIST_NODES_LIMIT: 12,
    NODE_TEXT_END_LENGTH: 7,
    NODE_TEXT_LENGTH_LIMIT: 20,
    OBJECT_DELIMITER: '||',
    TPS_LIVE_OBJECTS: 4,
};

const nodeTypes = {
    typeGOOSE: 1,
    typeSV: 2,
    typeSERVICE: 3,
    typeUNKNOWN: 0xf,
};

const nodeLevels = {
    IED_LEVEL: 0,
    MESSAGES_LEVEL: 1,
    DATASET_LEVEL: 2,
    SIGNAL_LEVEL: 3,
    MAX_DEPTH_LEVEL: 3,
};

const graph = {
    arrowPointer: undefined,
    arrowUp: undefined,
    arrowDown: undefined,
    borderScale: undefined,
    hostsScale: undefined,
    clientsScale: undefined,
    color: undefined,
    cluster: undefined,
    bundle: undefined,
    diagonal: undefined,
    line: undefined,
    node: undefined,
    nodeCount: undefined,
    nodeTextCount: undefined,
    nodeDescs: undefined,
    // nodes: undefined,
    link: undefined,
    linkEnter: undefined,
    group: undefined,
    groupArc: undefined,
    groupLine: undefined,
    rotatable: undefined,
    marker: undefined,
    ghostMarker: undefined,
    svg: undefined,
    chart: undefined,
};

exports.consts = consts;
exports.nodeTypes = nodeTypes;
exports.nodeLevels = nodeLevels;
exports.graph = graph;
