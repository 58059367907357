<template>
  <div class="list-checkbox" @click="changeChecked">
    <div class="list-checkbox__icon">
      <img v-show="isChecked" src="/images/box-checked.svg" loading="lazy" alt="" />
      <img v-show="!isChecked" src="/images/box.svg" loading="lazy" alt="" />
    </div>
    <div class="list-checkbox__title" :title="item.title !== item.description ? item.description : ''">
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'list-checkbox',
  props: {
    item: {
      type: Object,
    },
    activeItem: {
      type: Array,
    },
    ch: {
      type: Boolean,
    },
  },
  computed: {
    isChecked() {
      return this.activeItem.indexOf(this.item.value) > -1;
    },
  },
  methods: {
    changeChecked() {
      if (this.isChecked) {
        //remove
        this.$emit('checked', { action: 'remove', value: this.item.value });
      } else {
        //add
        this.$emit('checked', { action: 'add', value: this.item.value });
      }
    },
  },
};
</script>

<style scoped></style>
