<template>
  <div v-html="tosContent"></div>
</template>

<script>
import { mapStores } from 'pinia';
import { usePage } from '@/stores/page';

export default {
  name: 'tos-page',
  data: () => {
    return {};
  },
  computed: {
    ...mapStores(usePage),
    tosContent() {
      return this.page.pageData['tos-page'];
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss"></style>
