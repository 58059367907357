import CSSOM from 'cssom';
import { h } from 'vue';
/*
Original: https://github.com/princeahugah/vue3-simple-svg
 */

let loaded = false;

const SimpleSVG = {
  render() {
    return h('svg');
    // return h('svg', { id: 'foo', class: 'not-loaded' });
  },
  name: 'v-svg',
  props: {
    src: {
      type: String,
      required: true,
    },
    fillClassName: {
      type: String,
      default: '',
    },
    fill: {
      type: String,
      default: '',
    },
    strokeClassName: {
      type: String,
      default: '',
    },
    stroke: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    customId: {
      type: String,
      default: '',
    },
    cc: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loaded: false,
  }),
  mounted() {
    this.generateInlineSVG();
  },
  watch: {
    src(val) {
      // Re-generate inline SVG if src is updated
      this.generateInlineSVG();
    },
    fill(val) {
      this.updateFillColor(val);
    },
    stroke(val) {
      this.updateStrokeColor(val);
    },
    width(val) {
      this.$el.style.width = val;
    },
    height(val) {
      this.$el.style.width = val;
    },
  },
  methods: {
    /* Load an SVG file with XHR and generate an inline SVG code */
    generateInlineSVG() {
      const context = this;

      // Reset first. Remove all the code of the existing inline SVG
      this.resetInlineSVG();

      // Get the content of the SVG file
      const request = new XMLHttpRequest();
      request.open('GET', '/v-icons/' + this.src + '.svg', true);
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          // Setup a DOM parser to convert the response to text/xml
          const domParser = new DOMParser();
          const result = domParser.parseFromString(request.responseText, 'text/xml');
          const loadedSVG = result.getElementsByTagName('svg')[0];

          if (!loadedSVG) {
            console.error('No SVG element found in the given file: ' + context.filepath);
            return;
          }

          context.$el.classList.remove('not-loaded');

          // add attributes to the inline SVG
          const attributeNames = loadedSVG.getAttributeNames();
          for (const name of attributeNames) {
            const value = loadedSVG.getAttribute(name);
            context.$el.setAttribute(name, value);
          }
          if (context.customId) context.$el.id = context.customId;
          // if (context.cc) context.$el.setAttribute('class', context.cc);
          if (context.width !== 'auto') {
            context.$el.style.width = context.width;
          }
          if (context.height !== 'auto') {
            context.$el.style.height = context.height;
          }

          // add child nodes to the inline SVG
          const domN = loadedSVG.children.length;
          for (let i = domN - 1; i >= 0; i--) {
            const node = loadedSVG.children.item(i);
            context.$el.appendChild(node);
          }

          // set colors
          context.updateFillColor(context.fill);
          context.updateStrokeColor(context.stroke);

          // now the inline SVG is generated
          context.$emit('load');
        } else {
          console.error('There was an error retrieving the source of the SVG.');
        }
      };

      request.onerror = function () {
        console.error('There was on XML Http Request');
        dev.log('this.src', this.src);
      };

      request.send();
    },
    resetInlineSVG() {
      while (this.$el.firstChild) {
        this.$el.removeChild(this.$el.firstChild);
      }
      const attributeNames = this.$el.getAttributeNames();
      for (const name of attributeNames) {
        // console.log('check attr', name);
        this.$el.removeAttribute(name);
      }
      let hasWidth = false;
      let hasHeight = false;
      // console.log('reset', this.src, this.width + 'x' + this.height);
      if (this.width && this.width !== 'auto') {
        hasWidth = true;
      }
      if (this.height && this.height !== 'auto') {
        hasHeight = true;
      }
      if (!hasWidth && !hasHeight) {
        this.$el.classList.add('not-loaded');
      }
      // console.log('has', this.src, hasWidth, hasHeight);
      if (hasWidth && hasHeight) {
        this.$el.setAttribute('width', this.width);
        this.$el.setAttribute('height', this.height);
      }
      if (this.cc) this.$el.setAttribute('class', this.cc);
    },
    updateFillColor(fill) {
      if (this.fillClassName) {
        const matches = this.$el.getElementsByClassName(this.fillClassName);
        for (const element of matches) {
          element.style.fill = fill;
        }
      } else {
        if (fill) {
          const matches = this.$el.getElementsByClassName('icon-main-color');
          for (const element of matches) {
            element.style.fill = fill;
          }
        }
      }
    },
    updateStrokeColor(stroke) {
      if (this.strokeClassName) {
        const matches = this.$el.getElementsByClassName(this.strokeClassName);
        for (const element of matches) {
          element.style.stroke = stroke;
        }
      } else {
        if (stroke) {
          const matches = this.$el.getElementsByClassName('icon-main-color');
          for (const element of matches) {
            element.style.stroke = stroke;
          }
        }
      }
    },
  },
};

const plugin = {
  install(app, options) {
    app.component('v-svg', SimpleSVG);
  },
};

export { plugin as default, SimpleSVG as vSvg };
