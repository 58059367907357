<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="20" height="20" rx="4" fill="#1757BD" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0001 14.5858L7.41429 12C7.02376 11.6095 6.3906 11.6095 6.00008 12C5.60955 12.3905 5.60955 13.0237 6.00008 13.4142L9.29297 16.7071C9.68349 17.0976 10.3167 17.0976 10.7072 16.7071L18.0001 9.41423C18.3906 9.0237 18.3906 8.39054 18.0001 8.00001C17.6096 7.60949 16.9764 7.60949 16.5859 8.00001L10.0001 14.5858Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'checkbox-checked',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
