<template>
  <div
    class="main-container"
    v-resize:debounce="onResize"
    @resize="onResize"
    :class="{ 'main-container--full-height': visual.vertical }"
  >
    <nav-menu></nav-menu>
    <div class="main-section" :class="{ 'main-section--full-height': visual.vertical }">
      <top-nav></top-nav>

      <div class="content-view" :class="{ 'content-view--vertical': visual.vertical }">
        <div class="content-view-height">
          <div class="content-block content-view-height">
            <slot />
          </div>
        </div>
      </div>
    </div>

    <user-settings-modal></user-settings-modal>
    <about-company-modal></about-company-modal>
    <div style="display: none">
      <!--    <teleport v-if="hasEventsApp" to="#events-block">-->
      <!--      <events-app></events-app>-->
      <!--    </teleport>-->
      <!--    <teleport v-if="hasHistoryTimeline" to="#tpws_history_timeline-test">-->
      <!--      <tpws-history-timeline />-->
      <!--    </teleport>-->
      <!--    <teleport v-if="hasUsersControl" to="#control-users">-->
      <!--      <users-control :dataset="controlUsersDataset"></users-control>-->
      <!--    </teleport>-->
      <teleport v-if="hasMenuReportsApp" to="#reports-block">
        <menu-reports-app></menu-reports-app>
      </teleport>
      <teleport v-if="hasLastEventsBlock" to="#recentEventsVue">
        <last-events-block></last-events-block>
      </teleport>
    </div>
  </div>
</template>
<script>
import TopNav from '@/components/layout/TopNav.vue';
import NavMenu from '@/components/layout/NavMenu.vue';
import SimplebarVue from '@/components/common/SimplebarVue.vue';
import DevStatusModal from '@/components/modals/DevStatusModal.vue';
import UserSettingsModal from '@/components/modals/UserSettingsModal.vue';
import AboutCompanyModal from '@/components/modals/AboutCompanyModal.vue';
import MenuReportsApp from '@/components/layout/MenuReportsApp.vue';
import LastEventsBlock from '@/components/events/LastEventsBlock.vue';
import { isMobile } from 'detect-touch-device';
import { mapActions } from 'pinia';
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';

export default {
  name: 'app-layout-error',
  components: {
    TopNav,
    NavMenu,

    SimplebarVue,
    DevStatusModal,
    UserSettingsModal,
    AboutCompanyModal,

    MenuReportsApp,
    LastEventsBlock,
  },
  inject: ['mq'],
  computed: {
    ...mapStores(useVisual),
    hasMenuReportsApp() {
      return !!document.querySelector('#reports-block');
    },
    hasLastEventsBlock() {
      return !!document.querySelector('#recentEventsVue');
    },

    noGlobalScroll() {
      return (
        this.$route.name == 'report' ||
        this.$route.name == 'wheel-page' ||
        this.$route.name == 'wheel-live-page' ||
        this.$route.name == 'open-wheel-page'
      );
    },
  },
  mounted() {
    if (isMobile) {
      this.setTouch(true);
    } else {
      this.setTouch(false);
    }
  },
  methods: {
    ...mapActions(useVisual, ['setVertical', 'setTouch']),
    onResize() {
      setTimeout(() => {
        this.checkTouch();
      }, 50);
      this.$nextTick(() => {});
    },
    checkTouch() {
      if (navigator.maxTouchPoints) {
        this.setTouch(true);
      } else {
        this.setTouch(false);
      }
    },
  },
  watch: {
    'mq.vertical': {
      handler(vertical) {
        this.setVertical(vertical);
      },
      // force eager callback execution
      immediate: true,
      // deep: true,
    },
  },
};
</script>
