import axios from 'axios';
import axiosInstance from './api';

import { memoizedRefreshToken } from './refreshToken';
import TokenService from './tokenService';
import eventBus from '@/utils/eventBus';

const setup = () => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      // const session = JSON.parse(localStorage.getItem("session"));
      const accessToken = TokenService.getLocalAccessToken();
      // console.log('accessToken', accessToken, config.baseURL + config.url, config);

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      // console.log('r status', response.status)
      return response;
    },
    async (error) => {
      const config = error?.config;

      // console.log('resp error', error?.response?.status, error, config?.sent, error?.response.responseText);

      if (error.config.url === '/login' && error.config.method === 'post') {
        return Promise.reject(error);
      }

      if (error?.response?.status === 401 && error?.request?.responseText == 'No refresh token') {
        eventBus.dispatch('noRefreshToken');
        eventBus.dispatch('noAuth');
      }
      if (error?.response?.status === 401 && error?.request?.responseText == 'Unauthorized. Refresh fail') {
        eventBus.dispatch('noAuth');
      }
      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;

        const { accessToken, refreshToken } = await memoizedRefreshToken();

        if (accessToken) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${accessToken}`,
          };
        }

        return axios(config);
      }

      return Promise.reject(error);
    }
  );
};

// export const axiosPrivate = axios;
export default setup;
