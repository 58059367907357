<template>
  <tr role="row" :class="className" @click="$emit('select')" :data-gap="gap">
    <template v-for="f in fields" :key="f.name">
      <template v-if="f.renderTemplate">
        <td
          v-if="f.visible !== false"
          :data-gap="f.name === 'timeDT' && gap"
          :class="[...(f.classes ? f.classes : []),{

            pointer: !!f.action,
            'show-gap': f.name === 'timeDT' && gap,
            'long-break': f.name === 'descriptionsDT',
          }]"
        >
          <data-row-field :row="rowData" :f="f"></data-row-field>
        </td>
      </template>
      <template v-else>
        <td
          v-if="f.visible !== false"
          :data-gap="f.name === 'timeDT' && gap"
          :class="{
            pointer: !!f.action,
            'show-gap': f.name === 'timeDT' && gap,
            'long-break': f.name === 'descriptionsDT',
          }"
          v-html="renderData(rowData, f)"
          @click="execAction(f.action, $event, rowData)"
        ></td>
      </template>
    </template>
  </tr>
</template>

<script>
import DataRowField from '@/components/common/DataRowField.vue';
import { parse as htmlParse, serialize as htmlSerialize } from 'parse5';

export default {
  props: {
    rowData: Object,
    fields: Array,
    className: Array,
    gap: String,
    print: Boolean,
  },
  name: 'data-row',
  components: {
    DataRowField,
  },
  methods: {
    execAction(a, e, d) {
      dev.log('execAction', a, e, d);
      if (a) {
        e.stopImmediatePropagation();
        this.$emit('colAction', a, e, d);
      }

      // if (a) {
      //   e.stopImmediatePropagation();
      //   this[a](e);
      // }
    },
    // showProfile(e) {
    //   e.stopImmediatePropagation();
    //   this.$emit('colAction', e);
    // },
    renderData(row, f) {
      if (this.print && f.print && typeof f.print === 'function') {
        return f.print(row[f.data], row, row);
      } else if (f.render && typeof f.render === 'function') {
        return f.render(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
    renderTemplate(row, f) {
      if (f.render && typeof f.render === 'function') {
        return f.render(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
    walkRecursive(obj, parseF) {
      if (obj?.childNodes) {
        // console.log('with children', obj);
        obj?.childNodes.forEach((ch) => {
          // console.log('ch', ch);
          this.walkRecursive(ch, parseF);
        });
      } else {
        // console.log('obj', obj);
        if (obj.nodeName === '#text') {
          obj.value = parseF(obj.value);
        }
      }
      return obj;
    },
    unescapeHtml(unsafe) {
      return unsafe
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
    },
    parseNode(el) {
      dev.log('node', el);
      let text = el;
      text = text.replace(new RegExp(';', 'g'), ';###BREAK###');
      text = text.replace(new RegExp('_', 'g'), '_###BREAK###');
      text = text.replace(new RegExp('/', 'g'), '/###BREAK###');
      text = text.replaceAll('\$', '$###BREAK###');
      return this.unescapeHtml(text);
    },
    replaceHtmlInners(inp) {
      const ht = htmlParse(inp);
      dev.log('in', ht);
      const rep = this.walkRecursive(ht, this.parseNode);
      dev.log('rep', rep);
      const ser = htmlSerialize(rep);
      dev.log('ser', ser);
      return ser
        .replace('<html><head></head><body>', '')
        .replace('</body></html>', '')
        .replace(new RegExp('###BREAK###', 'g'), '&shy;');
    },
    addBreaks(data) {
      dev.log('addBreaks', data);
      // data = this.replaceHtmlInners(data);
      // data = this.replaceHtmlInners(data);
      data = data.replace(new RegExp('&zwj;', 'g'), '&zwj;');
      dev.log('ht', data);

      // text = text.replaceAll(new RegExp('$', 'g'), '$&zwnj;')
      return data;
    },
  },
};
</script>

<style scoped>
.data-table.stripe tbody tr.odd,
.data-table.display tbody tr.odd {
  background-color: #fbfbfb;
}

.data-table.display tbody td {
  border-top: 1px solid #ddd;
}

.data-table.display tbody td.word-break {
  word-break: break-all;
}

.data-table.display tbody td.long-break {
  word-break: break-word;
}

.data-table.display tbody tr:hover:not(.child) {
  background-color: #f6f6f6;
}

.data-table.display tbody tr.selected:hover:not(.child) {
  background-color: #aab7d1;
}

.data-table tbody td {
  padding: 8px 10px;
}

.data-table tbody .high-row td {
  padding: 22px 10px;
}

.data-table tbody td.show-gap {
  position: relative;
}

.data-table tbody td.show-gap::before {
  content: attr(data-gap);
  width: 130px;
  height: 3px;
  line-height: 3px;
  color: #007bff;
  text-align: center;
  font-size: 16px;
  background-color: white;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  margin-top: -2px;
  top: 0%;
}

.data-table tbody td.pointer {
  cursor: pointer;
}
</style>

<style>
.data-table tbody td .table-in-table td {
  border: none !important;
  padding: 2px 2px 2px 4px !important;
}
.data-table tbody td .table-in-table td:first-child {
  word-break: keep-all !important;
}
</style>
