import { defineStore } from 'pinia';

const GOOSE = 1;
const SV = 2;
const SERVICE = 3;
const UNKNOWN = 0xf;

const TPS_OBJECTS = 1;
const TPS_EVENTS = 2;
const TPS_MESSAGE = 3;
const TPS_LIVE_OBJECTS = 4;

// Отдельный код для сортировки списка ИЭУ-призраков в Прямом эфире

/* Глобальный объект с полями
 * methodToSort: текущая сортировка,
 * propToSort: текущая поле для сортировки,
 * sort: функция сортировки, которую можно использовать извне,
 * sortRowsBy: функция для изменения направления или поля сортировки
 */
export const useGhosts = defineStore({
  id: 'ghosts',
  state: () => {
    return {
      ghostGooses: undefined,
      ghostSvs: undefined,
      ghostIeds: undefined,
      lvbs: undefined,
      system_state: undefined,
      objects: undefined,
      devObjectsJson: {},
      devLiveObjectsJson: {},
      devConsoleLiveUpdateOn: false,
      methodToSort: -1,
      propToSort: 'mac',
    };
  },
  actions: {
    updateDevConsole(object, type) {
      switch (type) {
        case TPS_OBJECTS:
          dev.log('Set TPS_OBJECTS', object);
          this.devObjectsJson = object;
          break;
        case TPS_LIVE_OBJECTS:
          dev.log('Live objects updated', object);
          this.devLiveObjectsJson = object;
          break;
        case TPS_EVENTS:
          break;
        case TPS_MESSAGE:
          break;
        default:
      }
    },
    sort(a, b) {
      return a[this.propToSort] > b[this.propToSort] ? -this.methodToSort : this.methodToSort;
    },
    sortRowsBy(prop) {
      $('#ghost-ied-panel-table span').removeClass('fa-caret-up').removeClass('fa-caret-down').addClass('fa-sort');
      this.methodToSort = this.propToSort != prop ? -1 : -this.methodToSort;
      this.propToSort = prop;
      $('#sort-' + prop)
        .removeClass('fa-sort')
        .addClass(`fa-caret-${this.methodToSort < 0 ? 'up' : 'down'}`);

      this.ghostIeds = this.ghostIeds.sort(this.sort);
    },
    setGhostGooses(state, items) {
      state.ghostGooses = items;
    },
    setGhostSvs(state, items) {
      state.ghostSvs = items;
    },
    setGhostIeds(state, items) {
      state.ghostIeds = items;
    },
    setLvbs(state, items) {
      state.lvbs = items;
    },
    setSystemState(state, items) {
      state.system_state = items;
    },
    setObjects(state, items) {
      state.objects = items;
    },
  },
});
