// import MyButton from "@/components/UI/MyButton.vue";
// import MyInput from "@/components/UI/MyInput.vue";
import TDialog from '@/components/ui/TDialog.vue';
import TPopover from '@/components/ui/TPopover.vue';

export default [
  // MyButton,
  // MyInput,
  TDialog,
  TPopover,
];
