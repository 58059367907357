import SecureLS from 'secure-ls';
var ls = new SecureLS({ isCompression: false });

class TokenService {
  getLocalAccessToken() {
    const token = localStorage.getItem('token');
    return token;
  }
  updateLocalAccessToken(token) {
    localStorage.setItem('token', token);
  }
  clearLocalAccessToken() {
    localStorage.removeItem('token');
  }
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem('user');
  }
}
export default new TokenService();
