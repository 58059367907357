<template>
  <template v-for="desc in descs">
    <div :id="`wh_desc_${desc.prefix}_${desc.i}`" class="wh-desc">
      <h2 :style="{ backgroundColor: desc.headerColor }">{{ desc.header }}</h2>
      <ul class="list-unstyled">
        <li v-for="item in desc.list">
          <span class="tip-label">{{ item.label }}</span> {{ item.val }}
          <template v-if="item.units"
            >&nbsp;<span class="tip-label">{{ item.units }}</span></template
          >
        </li>
      </ul>
      <wh-access-point-desc v-for="ap in desc.aps" :ap="ap"></wh-access-point-desc>
    </div>

    <button
      v-if="desc.lastEventsBtn"
      class="btn btn-sm btn-link"
      @click="$emit('showLastEvents', { name: desc.lastEventsName, desc: desc.lastEventsDesc })"
    >
      {{ $t('View recent events') }}
    </button>
  </template>
</template>

<script>
import WhAccessPointDesc from '@/components/wheel/WhAccessPointDesc.vue';
export default {
  name: 'wh-desc',
  props: {
    descs: {
      type: Array,
    },
  },
  emits: ['showLastEvents'],
  components: {
    WhAccessPointDesc,
  },
};
</script>

<style scoped></style>
