<template>
  <div
    class="timepicker"
    :class="{
      timepicker_invalid: !validity && !focused,
      timepicker_focused: focused && validity,
      timepicker_focused_invalid: focused && !validity,
    }"
    :title="$t('hh:mm:ss')"
  >
    <input
      class="timepicker__input"
      type="number"
      max="23"
      min="0"
      :placeholder="$t('hh')"
      @focus="setFocus"
      @blur="removeFocus"
      v-model="hours"
    />
    <span class="timepicker__separator">:</span>
    <input
      class="timepicker__input"
      type="number"
      max="59"
      min="0"
      :placeholder="$t('mm')"
      @focus="setFocus"
      @blur="removeFocus"
      v-model="minutes"
    />
    <span class="timepicker__separator">:</span>
    <input
      class="timepicker__input"
      type="number"
      max="59"
      min="0"
      :placeholder="$t('ss')"
      @focus="setFocus"
      @blur="removeFocus"
      v-model="seconds"
    />
  </div>
</template>

<script>
export default {
  name: 'timepicker',
  props: ['initTime', 'onFocus', 'outputResult'],
  data: function () {
    return {
      time: ['hours', 'minutes', 'seconds'],
      hours: this.initTime.hours,
      minutes: this.initTime.minutes,
      seconds: this.initTime.seconds,
      validity: true,
      focused: false,
    };
  },
  beforeUpdate: function () {
    this.formatValue();
  },
  watch: {
    hours: function () {
      this.getResult();
    },
    minutes: function () {
      this.getResult();
    },
    seconds: function () {
      this.getResult();
    },
  },
  methods: {
    formatValue: function () {
      for (let value of this.time) {
        if (this[value].length === 1) this[value] = '0' + this[value];
        else if (this[value].length > 2) this[value] = this[value].slice(1);
        else if (this[value] === '') this[value] = '00';
      }
    },
    checkValidity: function () {
      let validity = true;
      if (this.hours === '' || this.minutes === '' || this.seconds === '') validity = false;
      if (+this.hours >= 24 || +this.minutes >= 60 || +this.seconds >= 60) validity = false;
      if (+this.hours < 0 || +this.minutes < 0 || +this.seconds < 0) validity = false;
      if (+this.hours === 0 && +this.minutes === 0 && +this.seconds === 0) validity = false;
      this.validity = validity;
    },
    getResult: function () {
      let result = {
        hours: this.hours,
        min: this.minutes,
        sec: this.seconds,
      };
      this.checkValidity();
      if (this.validity) {
        this.outputResult(result);
      } else {
        this.outputResult('invalid');
      }
    },
    // clearFields: function() {
    //   for (let value of this.time) {
    //     this[value] = "";
    //   }
    // },
    setFocus: function (e) {
      this.focused = true;
      this.onFocus();
    },
    removeFocus: function (e) {
      this.focused = false;
      this.checkValidity();
    },
  },
};
</script>

<style scoped></style>
