<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.userInviteModalVisible"
    v-model:visible="visual.userInviteModalVisible"
    ref="modEx"
    @closeModal="closeModal"
    @onSubmit="onSubmit"
  >
    <template #title> </template>
    <template #content>
      <n-form ref="formRef" :model="formValue" :rules="rules" :label-width="80">
        <n-form-item :label="$t('Enter email')" path="user.email">
          <n-input v-model:value="formValue.user.email" :placeholder="$t('Enter email')" />
        </n-form-item>
      </n-form>
    </template>
    <template #buttons> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { useVisual } from '@/stores/visual';
import { useTpws } from '@/stores/tpws';
import { useUsers } from '@/stores/users';
import { mapStores } from 'pinia';
import { ref } from 'vue';

export default {
  name: 'user-invite-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    },
    selectedSubnet: {
      type: String,
    },
  },
  components: { ModalDialog },
  setup() {
    const formRef = ref(null);
    // const message = useMessage()
    return {
      formRef,
      formValue: ref({
        user: { email: '' },
      }),
    };
  },
  data: () => {
    return {
      modal: {
        name: 'invite-user',
        title: 'Invite user to Tekvel Park',
        closeBtn: 'Close',
        onSubmit: 'Invite',
        noSubmit: false,
        size: '800',
        noTitle: false,
        noCross: false,
      },
      // user: { email: '' },
      userEmail: '',
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useTpws),
    ...mapStores(useUsers),
    rules() {
      return {
        user: {
          email: {
            required: true,
            type: 'email',
            message: this.$t('Value is required'),
            trigger: 'blur',
          },
        },
      };
    },
  },
  mounted() {},
  methods: {
    closeModal() {
      dev.log('closeModal');
      this.visual.userInviteModalVisible = false;
    },
    onSubmit() {
      dev.log('onSubmit');
      // this.$emit('select', this.selected);
      this.formRef
        ?.validate((errors) => {
          if (!errors) {
            dev.log('success');
            this.users.invite(this.formValue.user.email).then((res) => {
              dev.log('invite res', res);
              this.closeModal();
            });
          } else {
            // console.log(errors);
          }
        })
        .catch((err) => {});
    },
  },
  watch: {
    'visual.userInviteModalVisible': {
      handler(val) {
        dev.log('visibleModal', val);
        if (val) {
          // this.onShow();
        }
      },
      // deep: true
    },
  },
};
</script>

<style lang="scss">
h5 .subnet-select-modal__title {
  font-size: 1.1em;
  line-height: 1.5em;
}
</style>
<style lang="scss"></style>
