<template>
  <span class="skeleton-span" style="display: inline-block" :style="styleObject"></span>
</template>

<script>
export default {
  name: 'skeleton-span',
  props: {
    height: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
  },
  computed: {
    styleObject() {
      return {
        minHeight: this.height,
        minWidth: this.width,
      };
    },
  },
};
</script>

<style scoped>
.skeleton-span {
  background-color: #eeeeee;
}
</style>
