<template>
  <scd-upload-page
    v-model:visibleScdUpload="visibleScdUpload"
    @update:visibleScdUpload="visibleScdUpload = $event"
    @versionUploaded="versionUploaded"
  ></scd-upload-page>
  <template v-if="current">
    <p>
      {{ $t('Last updated by') }} {{ $t(current?.upload?.by) }} on
      {{ $t(current.upload.date) }}
    </p>
    <p>{{ $t('Current version:') }} {{ $t(current?.upload?.version) }}</p>
    <p>
      <a @click="showUpload" class="btn btn-default"> {{ $t('Upload new version') }}</a
      >&nbsp;<router-link :to="{ name: 'wheel-page', params: { projectId: currentProject } }" class="btn btn-default">
        {{ $t('View Wheel') }} </router-link
      >&nbsp;
    </p>
  </template>
  <template v-else>
    <div class="jumbotron text-center">
      <template v-if="permission?.indexOf('scdmanagement') > -1">
        <h2>
          {{ $t('No project uploaded. You can') }}&nbsp;<a @click="showUpload" class="btn btn-primary">{{
            $t('Upload')
          }}</a
          >&nbsp;{{ $t('new SCD file to start') }}
        </h2>
      </template>
      <template v-else>
        <h2>{{ $t('No project uploaded') }}</h2>
      </template>
    </div>
  </template>
</template>

<script>
import ScdUploadPage from '@/components/timeline/ScdUploadPage.vue';
import { mapStores, mapActions } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePage } from '@/stores/page';
import { useVisual } from '@/stores/visual';
export default {
  name: 'version-upload',
  components: {
    ScdUploadPage,
  },
  data: () => {
    return {
      visibleScdUpload: false,
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(usePage),
    ...mapStores(useVisual),
    currentProject() {
      return this.tpws.currentProject;
    },
    current() {
      return this.page.pageData['project-timeline']?.current;
    },
    permission() {
      return this.page.pageData['project-timeline']?.permission;
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions(usePage, ['getPageData']),

    showUpload() {
      this.visual.showSclUploadModal(true);
      // this.visibleScdUpload = true;
    },
    versionUploaded() {
      this.getPageData({
        name: 'current-project',
        path: '/projects/' + this.currentProject,
      });
      location.reload();
    },
  },
};
</script>

<style scoped></style>
