<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.sclUploadModalVisible"
    v-model:visible="visual.sclUploadModalVisible"
    ref="modEx"
    @closeModal="closeModal"
    @onSubmit="handleUpload"
  >
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #content>
      <slot></slot>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { useVisual } from '@/stores/visual';
import { mapStores } from 'pinia';

export default {
  name: 'scd-upload',
  model: {
    event: 'update',
  },
  props: {
    submitActive: {
      type: Boolean,
      default: false,
    },
    cancelActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['upload', 'cancel', 'versionUploaded', 'versionUploaded'],
  components: {
    ModalDialog,
  },

  data: () => {
    return {};
  },
  computed: {
    ...mapStores(useVisual),
    modal() {
      return {
        name: 'scl-upload',
        title: this.$t('Tekvel Park') + ' - ' + this.$t('Upload SCD'),
        onSubmit: 'Upload',
        submitBtn: 'OK',
        size: 'md',
        noActions: false,
        submitDisabled: !this.submitActive,
        cancelDisabled: !this.cancelActive,
      };
    },
  },

  methods: {
    closeModal(e) {
      this.visual.sclUploadModalVisible = false;
      this.$emit('cancel', e)
    },
    handleUpload(e) {
      e?.preventDefault();
      this.$emit('upload', e);
      // this.visual.sclUploadModalVisible = false;
    },
  },
};
</script>

<style scoped></style>
