<template>
  <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.31127 3.9331L10.5591 3.9331L9.42092 4.7331C9.26998 4.83919 9.1702 4.99712 9.14352 5.17215C9.11684 5.34719 9.16544 5.52499 9.27864 5.66644C9.39185 5.80788 9.56037 5.9014 9.74714 5.9264C9.93391 5.95141 10.1236 5.90586 10.2746 5.79977L13.1201 3.79977C13.2071 3.73755 13.2776 3.65736 13.3261 3.56544C13.3747 3.47351 13.3999 3.37233 13.3999 3.26977C13.3999 3.16721 13.3747 3.06603 13.3261 2.9741C13.2776 2.88218 13.2071 2.80198 13.1201 2.73977L10.3742 0.739768C10.25 0.649457 10.0974 0.600228 9.94022 0.599769C9.83158 0.600013 9.72445 0.623574 9.62703 0.668643C9.52961 0.713712 9.44451 0.779092 9.37824 0.859769C9.26272 0.998748 9.21059 1.17493 9.23325 1.34982C9.25591 1.5247 9.35151 1.68407 9.49917 1.7931L10.616 2.59977L1.31127 2.59977C1.12261 2.59977 0.941666 2.67001 0.808258 2.79503C0.67485 2.92005 0.599901 3.08962 0.599901 3.26643C0.599901 3.44325 0.67485 3.61282 0.808258 3.73784C0.941666 3.86286 1.12261 3.9331 1.31127 3.9331Z"
      fill="#2A2E32"
    />
  </svg>
</template>

<script>
export default {
  name: 'arrow-right',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
