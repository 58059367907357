<template>
  <div class="error-page">
    <div class="error-page__image">
      <img :src="errorImg" alt="" loading="lazy" style="max-height: calc(100vh - 56px)" />
    </div>
    <div class="error-page__content">
      <div class="error-page-content">
        <div class="error-page-content__status">
          <div class="error-status-label">
            <div>{{ $t('Error code') }}</div>
          </div>

          <div class="error-status-code">
            <slot name="errorCode">
              <div>error.code</div>
            </slot>
          </div>
        </div>
        <div class="error-page-content__desc">
          <div class="error-title">
            <slot name="errorTitle">
              <div>error.title</div>
            </slot>
          </div>

          <div class="error-desc">
            <slot name="errorDescription">
              <div>error.description</div>
            </slot>
          </div>
        </div>
        <div class="error-page-content__action">
          <div class="ui-button ui-button--active">
            <div class="ui-button__icon">
              <img src="/images/caret_left-white.svg" alt="" loading="lazy" />
            </div>

            <div class="ui-button__lable ui-button__lable--active">
              <div class="cursor-pointer" @click="$router.push({ name: 'client-root' })">
                {{$t('Return home')}}
              </div>
            </div>
          </div>
        </div>
        <div class="error-page-content__support">
          <div class="error-support">
            <div class="error-support__icon cursor-pointer" @click="saveSupportData">
              <img src="/images/export-white.svg" alt="" loading="lazy" />
            </div>

            <div class="error-support__desc">
              <div>
                {{$t('Information for customer support')}} <span v-if="errorDetails">{{ errorDetailsJson }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useAuth } from '@/stores/auth';
import { usePage } from '@/stores/page';
import { mapActions } from 'pinia';
import eventBus from '@/utils/eventBus';

export default {
  name: 'error-page',
  props: ['errorDetails', 'image'],
  data: () => {
    return {
      licenseStatus: {},
      password: '',
      password_confirm: '',
    };
  },
  computed: {
    ...mapStores(usePage),
    errorDetailsJson() {
      return JSON.stringify(this.errorDetails);
    },
    errorImg() {
      return '/images/' + (this.image ? this.image : 'goose-500.svg');
    },
  },
  mounted() {},
  unmounted() {},
  methods: {
    saveSupportData(){
      window.dispatchEvent(new CustomEvent('save-text', { detail: { filename: 'supportData.json', text: this.errorDetailsJson } }));
    }
  },
};
</script>

<style lang="scss"></style>
