<template>
  <modal-dialog
    :modal="modal"
    v-show="wheel.visibleLvbDescriptionModal"
    v-model:visible="wheel.visibleLvbDescriptionModal"
    ref="modEx"
    @closeModal="closeModal"
    @doExport="doExport"
  >
    <template #title>
      <span class="lvb-description-modal__title"></span>
    </template>
    <template #content>
      <div style="margin-top: 20px">
        <table class="table lvb-description-modal__table" style="width: 100%">
          <thead>
            <tr>
              <th>{{ $t('Description') }}</th>
              <th>{{ $t('Type') }}</th>
              <th>{{ $t('Rack') }}</th>
              <th>{{ $t('Location') }}</th>
              <th>{{ $t('IP') }}</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </template>
    <template #buttons> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { mapActions } from 'pinia';
import { useWheel } from '@/stores/wheel';
import { mapStores } from 'pinia';

export default {
  name: 'lvb-description-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog },
  data: () => {
    return {
      modal: {
        name: 'lvb-description',
        title: 'Export',
        onSubmit: 'doExport',
        submitBtn: 'OK',
        size: 'lg',
        noActions: true,
      },
      exportFormats: [
        { value: 'pdf', label: 'PDF', active: true },
        { value: 'xlsx', label: 'Excel' },
        { value: 'csv', label: 'CSV' },
      ],
      exportSort: [
        { value: 'asc', label: 'from new to old', active: true },
        { value: 'desc', label: 'from old to new' },
      ],
      selectedFormat: 'pdf',
      selectedSort: 'asc',
      dateTime: {},
    };
  },
  computed: {
    ...mapStores(useWheel),
  },
  mounted() {},
  methods: {
    ...mapActions(useWheel, ['hideLvbDescriptionModal']),
    doExport() {
      dev.log('doExport');
      this.$emit('doExport', { format: this.selectedFormat, sort: this.selectedSort });
      this.$emit('update:visibleModal', false);
    },
    closeModal() {
      dev.log('closeModal');
      this.$emit('update:visibleModal', false);
    },
    selectPeriod(p) {
      dev.log(p);
      this.timePresets
        .filter((e) => e.active)
        .forEach((el) => {
          let ell = el;
          let idx = this.timePresets.indexOf(el);
          delete ell['active'];
          // this.timePresets.$set(idx, ell);
          this.timePresets[idx] = ell;
        });
      dev.log(this.timePresets.filter((e) => e.value === p.value));
      let el = this.timePresets.find((e) => e.value === p.value);
      let idx = this.timePresets.indexOf(el);

      el.active = true;
      // this.timePresets.$set(idx, el);
      this.timePresets[idx] = el;
      this.setTimePeriod(p.value);
    },
    setTimePeriod(value) {
      let now = new Date();
      let start = new Date();
      let end = new Date();
      switch (value) {
        case 'five-min':
          start.setMinutes(now.getMinutes() - 5);
          break;
        case 'today':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          break;
        case 'this-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'last-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 6);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'this-month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case 'last-month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        default:
          break;
      }
      this.dateTime = {
        start: {
          date: start.customFormat('#YYYY#-#MM#-#DD#'),
          time: start.customFormat('#hhhh#:#mm#:#ss#'),
        },
        end: {
          date: end.customFormat('#YYYY#-#MM#-#DD#'),
          time: end.customFormat('#hhhh#:#mm#:#ss#'),
        },
      };
    },
  },
  watch: {
    visibleModal: function (val) {
      dev.log('visibleModal', val);
      if (!val) {
        this.$emit('update:visibleModal', val);
      }
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
