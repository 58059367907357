import api from '@/services/api';
import { defineStore } from 'pinia';
import DateNano from '../utils/format.date.time';

export const useServerTime = defineStore({
  id: 'serverTime',
  state: () => {
    return {
      serverTimeOffset: 0,
    };
  },

  actions: {
    adjustingTime(value) {
      const now = new DateNano().getTime();
      const serverOffset = now - value.serverTime;
      this.serverTimeOffset = serverOffset;
    },
  },
});
