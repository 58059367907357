import api from '@/services/api';
import { defineStore } from 'pinia';
import set from 'set-value';
import { i18next } from '@/root.app';
import eventBus from '@/utils/eventBus';

export const useTpws = defineStore({
  id: 'tpws',
  state: () => {
    return {
      routeProject: undefined,
      softProject: undefined,
      projectName: '',
      project: {},
      currentVersionIndex: 0,
      context: undefined,
      projectStatusLoading: false,
    };
  },
  getters: {
    currentProject: (state) => {
      if (state.routeProject) {
        return state.routeProject;
      } else if (state.softProject) {
        return state.softProject;
      } else if (state.projectName) {
        return state.projectName;
      } else {
      }
    },
    contextResolver(state) {
      return state.context || this.getContext();
    },
    name(state) {
      return this.contextResolver?.name;
    },
    displayName(state) {
      return this.contextResolver?.displayName;
    },
    currentLang(state) {
      return this.contextResolver?.languageProject;
    },
    lastVersion(state) {
      return this.contextResolver?.historyList[this.versionNumber - 1];
    },
    activeVersion(state) {
      return this.contextResolver?.activeVersion;
    },
    activeVersionId(state) {
      return this.activeVersion?._id;
    },
    viewedVersion(state) {
      return this.contextResolver?.viewedVersion;
    },
    viewedVersionId(state) {
      return this.viewedVersion?._id;
    },
    inProcessing(state) {
      return this.contextResolver?.inProcessing;
    },
    commissioning(state) {
      return this.contextResolver?.commissioning;
    },
    isLive(state) {
      return this.contextResolver?.isLive;
    },
    enableSoftwareLVB(state) {
      return this.contextResolver?.enableSoftwareLVB;
    },
    languageProject(state) {
      return this.contextResolver?.languageProject;
    },
    current(state) {
      return this.contextResolver?.current;
    },
    historyList(state) {
      return this.contextResolver?.historyList;
    },
    permissionList(state) {
      return this.permission;
    },
    permission(state) {
      return this.contextResolver?.permission;
    },
    sclProcessorList(state) {
      return this.contextResolver?.sclProcessorList;
    },
    projectHasActiveVersion: (state) => {
      return !!state?.activeVersion;
    },
    versionNumber: (state) => {
      //console.log('this.historyList', this?.historyList);
      return state?.historyList?.length;
    },
    activeVersionHasElly: (state) => {
      const found =
        state.activeVersion &&
        state.activeVersion.sclProcessors &&
        state.activeVersion.sclProcessors.find(
          (el) => el.processing_result === 'success' && el.processor_id === 'ELLY'
        );
      return !!found;
    },
    viewedVersionHasElly: (state) => {
      const found = state?.viewedVersion?.sclProcessors.find(
        (el) => el.processing_result === 'success' && el.processor_id === 'ELLY'
      );
      return !!found;
    },
    viewedVersionHasSignalTable: (state) => {
      const found = state?.viewedVersion?.sclProcessors.find(
        (el) => el.processing_result === 'success' && el.processor_id === 'SIGNAL_TABLE'
      );
      return !!found;
    },
    viewedVersionHasSsdExplorer: (state) => {
      const found = state?.viewedVersion?.sclProcessors.find(
        (el) => el.processing_result === 'success' && el.processor_id === 'SSD_EXPLORER'
      );
      return !!found;
    },
    viewedVersionHasIpTable: (state) => {
      const found = state?.viewedVersion?.sclProcessors.find(
        (el) => el.processing_result === 'success' && el.processor_id === 'IP_TABLE'
      );
      return !!found;
    },
    viewedVersionHasSclStats: (state) => {
      const found = state?.viewedVersion?.sclProcessors.find(
        (el) => el.processing_result === 'success' && el.processor_id === 'SCL_STATS'
      );
      return !!found;
    },
    currentSclProcessors: (state) => {
      return state?.viewedVersion?.sclProcessors;
    },
  },
  actions: {
    initTpws() {},
    setCurrentVersionIndex(val) {
      this.currentVersionIndex = val;
    },
    errorHandler(err) {
      console.error(err);
    },
    parseErrors(validationErrors, bgClass) {
      bgClass = bgClass || 'bg-warning';
      var str;
      var errorsList = validationErrors.split('__NEXT__');
      var ttlErrors = `${i18next.t('Total errors')}: ` + errorsList.length;
      str = `<p>${i18next.t(
        'We have successfully visualized GOOSE connections between IEDs of the project, but your SCD file is not valid. Click OK to view communications. Please, consider SCD validation errors - world requires better digital substations.'
      )}</p>`;
      str += '<div class="wh-scroll-block">';
      errorsList.forEach((d) => (str += `<p class="${bgClass}">${d}</p>`));
      // function (d) {
      //     //      var msg = d.toString();
      //     //      var col = d.column;
      //     //      var line = d.line;
      //     str += "<p class=\"" + bgClass + "\">";
      //     //      str += "<strong>Line: " + line + ", char: " + col  + "</strong><br />";
      //     str += d; //msg;
      //     str += "</p>";
      // });
      str += '<p>' + ttlErrors + '</p>';
      str += '</div>';
      return str;
    },
    srcMacNull(projectName) {
      return new Promise((resolve) => {
        // const tpws = useTpws();
        api
            .get(`/projects/${projectName}/srcmacnull`)
            .then((response) => {
              console.log('srcMacNull', response);
              if (response.data.res == 'success') {
                resolve(response.data.desk);
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              console.error(error);
              resolve(false);
            });
      });
    },
    getUserList(projectName, cb) {
      $.ajax({
        url: '/api/projects/' + projectName + '/users',
        dataType: 'json',
        error: (q, s, err) => {
          this.errorHandler(err);
        },
        success: (list) => {
          cb(list);
        },
      });
    },
    getHistory(projectName, cb) {
      $.ajax({
        url: '/api/projects/' + projectName + '/history',
        error: (q, s, err) => {
          this.errorHandler(err);
        },
        success: (list) => {
          cb(list);
        },
      });
    },
    fillProjectUserList(list) {
      $('#tpws_users_container').empty();
      //console.log(typeof list);
      list.forEach(function (userRole) {
        var role = $('<div class="row tpws-container"></div>');
        var ul = $('<div class="row"></div>'),
          users = [];
        role.append('<p><strong class="text-capitalize">' + userRole.role + '</strong></p>');
        if (userRole.users.length > 0) {
          userRole.users.forEach(function (userInRole) {
            users.push(
              '<a href="/users/' + userInRole.id + '">' + userInRole.firstname + ' ' + userInRole.lastname + '</a>'
            );
            //ul.append('<a href="/users/' + ll.username + '">' + ll.fullname + '</a><br />');
          });
        } else {
          users.push(i18next.t('No users assigned'));
        }
        ul.html(users.join(', '));
        role.append(ul);
        $('#tpws_users_container').append(role);
      });
    },
    /**
     * Searches user by his email and adds data fills in the form of the modal window for further adding user to the project
     */
    searchUserByEmail() {
      this.reinitUserSearchWindow();
      // this.addedUserEmail = $('#user-search-email').val();
      $('#user-search-progress').toggleClass('hidden');
      $('#user-search-progress-bar').addClass('progress-bar-animated');
      $.get(this.currentProject + '/getuserbyemail?email=' + $('#user-search-email').val(), function (data) {
        //console.log(data);
        if (data.email != undefined) {
          $('#user-search-progress').toggleClass('hidden');
          $('#found-user-full-name').val(data.firstname + ' ' + data.lastname);
          $('.found-user-full-name-span').each(function () {
            //console.log(this);
            $(this).text(data.firstname + ' ' + data.lastname);
          });
          $('#found-user-name-continue-button').text(data.firstname);
          $('#found-user-email').val(data.email);
          $('#found-user-telephone').val(data.phone);
          $('#found-user-company').val(data.company);
          $('#found-user-position').val(data.position);
          $('#search-user-modal').modal('toggle');
          $('#foundUserModal').modal('toggle');
        } else {
          $('#user-not-found-email').text(this.addedUserEmail);
          $('#user-search-progress').toggleClass('hidden');
          $('#user-not-found-alert').toggleClass('hidden');
        }
      });
    },
    reinitUserSearchWindow() {
      $('#user-not-found-alert').addClass('hidden');
    },
    addProjectUserByEmail(projectId, userEmail, cb) {
      $.get(projectId + '/adduser?email=' + userEmail, function (res) {
        if (res['res'] == 'success') {
          cb(true);
        } else if (res == 'complete') {
          // FIXME: This is the older syntax, it shall be removed when server is updated
          cb(true);
        } else {
          cb(false, res);
        }
      });
    },
    /**
     * Calls the adduserrole script at tpws server and returns the result of a call to the callback,
     * @param {any} projectId The ID of the project
     * @param {any} projectRole The assigned role for the user in the project
     * @param {any} userEmail User email
     * @param {any} cb Callback functions, where the results of the operation will be passed: [true] if successfull, [false, err] of unsuccessfull.
     */

    addUserRole(projectId, projectRole, userEmail, cb) {
      $.get(projectId + '/adduserrole?email=' + userEmail, '&role=' + projectRole, function (addUserRoleResult) {
        if (addUserRoleResult['res'] == 'success') {
          cb(true);
        } else if (addUserRoleResult == 'complete') {
          // FIXME: This is the older syntax, it shall be removed when server is updated
          cb(true);
        } else {
          cb(false, addUserRoleResult);
        }
      });
    },
    addedUserEmail() {
      return $('#user-search-email').val();
    },
    assignRoleToUser(projectId, projectRole, userEmail, cb) {
      // Try adding user to the project. Continue if added.
      this.addProjectUserByEmail(projectId, userEmail, function (addUserResult) {
        if (addUserResult === true) {
          // User was successfully added to the project we may continue trying to add user role
          this.addUserRole(projectId, projectRole, userEmail, function (addUserRoleResult) {
            if (addUserRoleResult === true) {
              cb(true);
            } else {
              cb(false, `${i18next.t('Could not add user role')}: ` + addUserRoleResult);
            }
          });
        } else {
          // User could not be added to the project due to some reason, throw error.
          cb(false, `${i18next.t('User could not be added to the project')}: ` + addUserResult);
        }
      });
    },
    readAddUserRoleCheckboxValues() {
      return $('.add-user-role-checkbox:checked')
        .map(function () {
          return this.value;
        })
        .get();
    },
    /**
     * The function shall be called by UI button press only and collects only currnet parameters of the UI, while calling other functions from this JS
     */
    assignMultipleRolesToUser() {
      let userRoles = this.readAddUserRoleCheckboxValues();
      $('.add-user-roles-results').html('');
      if (userRoles.length > 0) {
        this.addProjectUserByEmail(this.currentProject, this.addedUserEmail, function (addToProjectResult) {
          text = '';
          if (addToProjectResult === true) {
            text =
              `<div class="alert alert-success" role="alert">${i18next.t('Successfully added user')} <strong>` +
              this.addedUserEmail +
              '</strong>' +
              i18next.t('to the project') +
              '</div >';
          } else {
            text =
              `<div class="alert alert-danger" role="alert">${i18next.t('Failed to add user')} <strong>` +
              this.addedUserEmail +
              `</strong> ${i18next.t('to the project')}</div>`;
          }
          $('.add-user-roles-results').append(text);
        });
        userRoles.forEach(function (userRole) {
          this.addUserRole(this.currentProject, userRole, this.addedUserEmail, function (result) {
            text = '';
            if (result === true) {
              text =
                `<div class="alert alert-success" role="alert">${i18next.t('Successfully added role')} <strong>` +
                userRole +
                `</strong> ${i18next.t('to user')} <strong>` +
                this.addedUserEmail +
                '</strong></div >';
            } else {
              text =
                `<div class="alert alert-danger" role="alert">${i18next.t('Failed to add role')} <strong>` +
                userRole +
                `</strong> ${i18next.t('to user')} <strong>` +
                this.addedUserEmail +
                '</strong></div >';
            }
            $('.add-user-roles-results').append(text);
          });
        });
      } else {
        $('.add-user-roles-results').append(
          `<div class="alert alert-warning" role="alert">${i18next.t('No new roles were selected for user')} <strong>` +
            this.addedUserEmail +
            '</strong></div >'
        );
      }
      $('#assign-roles-to-user').modal('toggle');
    },
    tpws_generalPostRequest(settings) {
      $.post({
        url: settings.url,
        data: settings.data,
        dataType: 'json',
        success: settings.success,
      });
    },
    tpws_ProjectChangeSettings({ newProjectName, newProjectLanguage }, cb) {
      this.tpws_generalPostRequest({
        url: '/api/projects/' + this.currentProject + '/rename',
        data: {
          newName: newProjectName,
          newLanguage: newProjectLanguage,
        },
        success: function (result) {
          if (result['res'] == 'success') {
            cb(true);
          } else if (result['ok'] != 'undefined') {
            // FIXME: This is the older syntax, it shall be removed when server is updated
            //console.log('Ok');
            cb(true);
          } else {
            cb(false, result['desc']);
          }
        },
      });
    },
    renameProjectController() {
      var result = '';
      $('#project-name-edit').prop('readonly', true);
      $('#save-new-project-name').prop('disabled', true);

      const newProjectName = $('#project-name-edit').val();
      const newProjectLanguage = $('#project-language-edit').val();

      const languageLabel = $.trim($('#project-language-edit').children('option:selected').text());

      if (newProjectName != '') {
        this.tpws_ProjectChangeSettings({ newProjectName, newProjectLanguage }, function (changeResult, errDesc = '') {
          if (changeResult == true) {
            result = `
                    <div class="d-flex flex-column alert alert-success" role="alert">
                        <div>${i18next.t('Project parameters have been changed')}:</div>
                        <div class="d-flex flex-column">
                            <div class="row">
                                <span class="col-md-4">${i18next.t('New name')}:</span>
                                <strong class="col-md-8">${newProjectName}</strong>
                            </div>
                            <div class="row">
                                <span class="col-md-4">${i18next.t('New language')}:</span>
                                <strong class="col-md-8">${languageLabel}</strong>
                            </div>
                        </div>
                    </div>`;
            setTimeout(function () {
              location.reload();
            }, 2000);
          } else {
            result =
              `< div class= "alert alert-danger" role = "alert" > ${i18next.t(
                'Could not rename project, the following error occured'
              )}: ` +
              errDesc +
              '</div >';
            $('#project-name-edit').prop('readonly', false);
            $('#save-new-project-name').prop('disabled', false);
          }
          $('#project-rename-results').html(result);
        });
      } else {
        result = `<div class="alert alert-danger" role="alert">${i18next.t('Project name can not be empty')}.</div >`;
        $('#project-name-edit').prop('readonly', false);
        $('#save-new-project-name').prop('disabled', false);
      }

      $('#project-rename-results').html(result);
    },
    setProjectProperties(properties) {
      this.tpws_generalPostRequest({
        url: '/api/projects/' + this.currentProject + '/set_properties',
        data: {
          properties: JSON.stringify(properties),
        },
        success: function (result) {
          if (result['res'] == 'success') {
            cb(true);
          } else if (result['ok'] != 'undefined') {
            // FIXME: This is the older syntax, it shall be removed when server is updated
            //console.log('Ok');
            cb(true);
          } else {
            cb(false, result['desc']);
          }
        },
      });
    },
    tpws_inviteUserByEmailXHR(email, cb) {
      $.get(
        this.projectName ? '/projects/' + this.projectName + '/invite2?email=' + email : '/invite?email=' + email,
        function (res) {
          if (res['res'] == 'success') {
            cb(true);
          } else {
            cb(false, res);
          }
        }
      );
    },
    inviteUserByEmailController() {
      var result = '';
      $('#invite-user-email').prop('readonly', true);
      $('#invite-user-btn').button('loading');
      var email = $('#invite-user-email').val();
      if (email != '') {
        this.tpws_inviteUserByEmailXHR(email, function (sendInvitationResult, errDesc = '') {
          if (sendInvitationResult == true) {
            result =
              `<div class="alert alert-success" role="alert">${i18next.t('Successfully sent invitation to')} <strong>` +
              email +
              '</strong>.</div >';
            setTimeout(function () {
              location.reload();
            }, 2000);
          } else {
            result =
              `<div class="alert alert-danger" role="alert">${i18next.t('Could not send invitation to')} <strong>` +
              email +
              `</strong>, ${i18next.t('the following error occured')}: ` +
              errDesc +
              '</div >';
            $('#invite-user-email').prop('readonly', false);
            $('#invite-user-btn').button('loading');
          }
          $('#invite-user-modal__results').html(result);
        });
      } else {
        result = `<div class="alert alert-danger" role="alert">${i18next.t('Email cannot be empty')}.</div>`;
        $('#invite-user-email').prop('readonly', false);
        $('#invite-user-btn').button('reset');
      }

      $('#invite-user-modal__results').html(result);
    },
    clearProjectStatus() {
      this.context = undefined;
    },
    // setProjectName(name) {
    //   this.softProject = name;
    // },
    setRouteProject(val) {
      dev.log('setRouteProject', val);
      this.routeProject = val;
      const cr = this.contextResolver;
    },
    unloadProject() {
      dev.log('unloadProject');
      this.projectName = undefined;
      this.softProject = undefined;
      this.setRouteProject(undefined);
      this.clearProjectStatus();
    },
    getContext() {
      return new Promise((resolve, reject) => {
        if (!this.routeProject) {
          this.context = undefined;
          resolve(this.context);
          return;
        }
        //console.log('loadProject', this.currentProject);
        this.projectStatusLoading = true;
        api
          .get(`/projects/${this.currentProject}/project-status`)
          .then(({ data }) => {
            //console.log('currentProject resolve', data);
            if (data.performance) {
              //console.log('data.performance', data.performance);

              for (let i = Object.values(data.performance).length - 2; i >= 0; i--) {
                const p2 = Object.values(data.performance)[i + 1];
                const p1 = Object.values(data.performance)[i];
                //console.log('per[' + (i + 2) + ']', p2 - p1);
              }
            }
            data['historyList'] = [
              ...data['historyList'].sort((a, b) => {
                return a.version - b.version;
              }),
            ];
            this.context = data;
            resolve(this.context);
          })
          .catch((error) => {
            if (error.code === "ERR_BAD_REQUEST") {
              eventBus.dispatch('message', {
                message: i18next.t('You do not have access to this project. Please contact the administrator.'),
                close: true,
                type: 'error',
              });
              eventBus.dispatch('project-forbidden');
            }

            console.error('currentProject error', error);
            resolve(error);
          })
          .finally(() => {
            this.projectStatusLoading = false;
          });
      });
    },
    disableMaintenance(cb) {
      api
        .get(`/projects/${this.currentProject}/disable_maintenance`)
        .then(({ data }) => {
          //console.log('disableMaintenance resolve', data);
          cb(data);
        })
        .catch((error) => {
          console.error('currentProject error', error);
        });
    },
    renameProject(payload, cb) {
      api
        .post(`/projects/${this.currentProject}/rename`, payload)
        .then(({ data }) => {
          //console.log('renameProject resolve', data);
          this.reloadProjectStatus();
          cb(data);
        })
        .catch((error) => {
          console.error('currentProject error', error);
        });
    },
    setProject(state, item) {
      set(state, item.path, item.payload);
    },
    async reloadProjectStatus(versions) {
      this.getContext();
    },
    async loadProjectStatus(project) {
      const tmp = this.contextResolver;
    },
  },
});
