//*** This code is copyright 2002-2016 by Gavin Kistner, !@phrogz.net
//*** It is covered under the license viewable at http://phrogz.net/JS/_ReuseLicense.txt
//*** Reuse or modification is free provided you abide by the terms of that license.
//*** (Including the first two lines above in your source code satisfies the conditions.)

// Include this code (with notice above ;) in your library; read below for how to use it.
// Код расширен возможностью использовать милисекунды, микросекунды и наносекунды

Date.prototype.customFormat = function (formatString = '#hhhh#:#mm#:#ss# #DD#.#MM#.#YYYY#') {
  var YYYY, YY, MMMM, MMM, MM, M, DDDD, DDD, DD, D, hhhh, hhh, hh, h, mm, m, ss, s, ms, us, ns, ampm, AMPM, dMod, th;
  var dateObject = this;
  YY = ((YYYY = dateObject.getFullYear()) + '').slice(-2);
  MM = (M = dateObject.getMonth() + 1) < 10 ? '0' + M : M;
  MMM = (MMMM = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][M - 1])?.substring(0, 3);
  DD = (D = dateObject.getDate()) < 10 ? '0' + D : D;
  DDD = (DDDD = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][
    dateObject.getDay()
  ])?.substring(0, 3);
  th = D >= 10 && D <= 20 ? 'th' : (dMod = D % 10) == 1 ? 'st' : dMod == 2 ? 'nd' : dMod == 3 ? 'rd' : 'th';
  formatString = formatString
    .replace('#YYYY#', YYYY)
    .replace('#YY#', YY)
    .replace('#MMMM#', MMMM)
    .replace('#MMM#', MMM)
    .replace('#MM#', MM)
    .replace('#M#', M)
    .replace('#DDDD#', DDDD)
    .replace('#DDD#', DDD)
    .replace('#DD#', DD)
    .replace('#D#', D)
    .replace('#th#', th);

  h = hhh = dateObject.getHours();
  if (h == 0) h = 24;
  if (h > 12) h -= 12;
  hh = h < 10 ? '0' + h : h;
  hhhh = hhh < 10 ? '0' + hhh : hhh;
  AMPM = (ampm = hhh < 12 ? 'am' : 'pm').toUpperCase();
  mm = (m = dateObject.getMinutes()) < 10 ? '0' + m : m;
  ss = (s = dateObject.getSeconds()) < 10 ? '0' + s : s;
  ms = `${dateObject.getMilliseconds()}`.padStart(3, '0');
  us = `${dateObject.getMicroseconds ? dateObject.getMicroseconds() : 0}`.padStart(3, '0');
  ns = `${dateObject.getNanoseconds ? dateObject.getNanoseconds() : 0}`.padStart(3, '0');
  return formatString
    .replace('#hhhh#', hhhh)
    .replace('#hhh#', hhh)
    .replace('#hh#', hh)
    .replace('#h#', h)
    .replace('#mm#', mm)
    .replace('#m#', m)
    .replace('#ss#', ss)
    .replace('#s#', s)
    .replace('#ms#', ms)
    .replace('#us#', us)
    .replace('#ns#', ns)
    .replace('#ampm#', ampm)
    .replace('#AMPM#', AMPM);
};

Date.prototype.setTimezoneOffset =
  Date.prototype.setTimezoneOffset ||
  function (offset) {
    this.setMinutes(this.getMinutes() - offset + this.getTimezoneOffset());
    return this;
  };

class DateNano extends Date {
  constructor(ts = Date.now()) {
    /*
            Так как в системе уже используются таймстемпы с жестко заданным кол-вом разрадов,
            в которых передаются не только стандартные милисекунды, но и три разряда микросекунд,
            мы принимаем за данность, что даты лежат в диапазоне между 2001 и 2286 годами,
            что соответствует 10-ти разрадам секунд (ts от 1 000 000 000 до 9 999 999 999)
        */
    let sec, nano;

    // Если число передано в виде строки, пытаемся преобразовать его к числу
    if (!isNaN(+ts)) ts = +ts;

    // Если дата в виде, подходящем для парсинга, парсим
    if (!isNaN(Date.parse(ts))) ts = Date.parse(ts);
    // Если число, то жестко берем первые 10 разрядов как секунды, следующие 6 - как наносекунды и дополняем нулями
    // Брать вместо 6 разрядов 9 не имеет смысла, так как Number.MAX_SAFE_INTEGER имеет как раз 16 разрядов
    if (typeof ts == 'number') {
      sec = `${ts}`.substring(0, 10);
      nano = `${ts}`.substring(10, 16).padEnd(9, '0');
    }

    // Передан массив, проверяем значения и первое считаем секундами, второе наносекундами
    if (Array.isArray(ts)) {
      // Если одно из значений в виде строки, пытаемся преобразовать его к числу
      if (!isNaN(+ts[0])) ts[0] = +ts[0];
      if (!isNaN(+ts[1])) ts[1] = +ts[1];
      sec = `${ts[0]}`.substring(0, 10);
      nano = `${ts[1]}`.substring(0, 9);
    }

    // Передан объект, проверям наличие соответствующих полей и значения в них
    if (!Array.isArray(ts) && typeof ts == 'object') {
      // Если одно из полей в виде строки, пытаемся преобразовать его к числу
      if (!isNaN(+ts.sec)) ts.sec = +ts.sec;
      if (!isNaN(+ts.nano)) ts.nano = +ts.nano;
      sec = `${ts.sec}`.substring(0, 10);
      nano = `${ts.nano}`.substring(0, 9);
    }

    // Приводим к числу
    sec = +sec;
    nano = +nano;
    // console.log(sec, nano);

    // Вызываем конструктор Date и передаем ему микросекунды
    super(sec * 1e3 + Math.floor(nano / 1e6));

    // Задаем скрытое поле, которое будет хранить кол-во наносекунд
    Object.defineProperty(this, 'nano', { value: nano, enumerable: false, writable: true });

    return this;
  }

  getMicroseconds() {
    return Math.floor((this.nano / 1e3) % 1e3);
  }

  getNanoseconds() {
    return this.nano % 1e3;
  }

  setMicroseconds(us) {
    if (typeof us != 'number' || us < 0 || us >= 1e3) return;
    this.nano = this.getMilliseconds() * 1e6 + us * 1e3 + (this.nano % 1e3);
  }

  setNanoseconds(ns) {
    if (typeof ns != 'number' || ns < 0 || ns >= 1e3) return;
    this.nano = this.getMilliseconds() * 1e6 + this.getMicroseconds() * 1e3 + ns;
  }
}

export default DateNano;
