<template>
  <div class="t-btn" @click="$emit('click', $event)">
    <div v-if="icon" class="t-btn__icon">
      <i :class="icon"></i>
    </div>
    <div class="t-btn__label">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 't-btn',
  props: {
    icon: {
      type: String,
    },
  },
};
</script>

<style scoped>
.t-btn {
  padding: 8px 16px;
  background: rgba(23, 87, 189, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}
.t-btn__icon {
  margin-right: 4px;
  height: 20px;
  width: 20px;
  color: #1757bd;
}
.t-btn__label {
  color: #1757bd;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
</style>
