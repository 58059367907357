<template>
  <div class="container login-form-container">
    <div class="tpws-centered-form">
      <div class="tps-error-message">
        <p v-if="message" class="alert alert-danger tpws-roundborder-block">message</p>
      </div>
      <n-form ref="formRef" :model="formValue" :rules="rules" label-placement="top"  id="signin_form"
              require-mark-placement="right-hanging"
              label-width="auto">
        <input v-model="token" name="token" type="token" hidden :readonly="true" id="token" class="form-control" />

        <n-form-item :label="$t('Email')" path="username">
          <n-input name="email"
                   type="text" v-model:value="formValue.username" :placeholder="$t('Email')" />
        </n-form-item>
        <n-form-item :label="$t('Password')" path="password">
          <n-input name="password" type="password" v-model:value="formValue.password" show-password-on="click" :placeholder="$t('Password')" />
        </n-form-item>
      </n-form>
      <div>

        <button @click="onSubmit" class="btn btn-pri">{{ $t('Login') }}</button>
        <template v-if="!onPremis">
          <a href="/signup" @click.prevent="$router.push({ name: 'auth-signup' })" class="btn btn-link ml-3">{{
            $t('Sign up')
          }}</a>
          <a href="/forgot" @click.prevent="$router.push({ name: 'auth-forgot' })" class="btn btn-link ml-3">{{
            $t('Forgot Password')
          }}</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useAuth } from '@/stores/auth';
import { usePage } from '@/stores/page';
import { mapActions } from 'pinia';
import {ref} from "vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: 'signin-page',
  setup() {
    const formRef = ref(null);
    return { v$: useVuelidate(),
      formRef,
      formValue: ref({
        username: '',
        password: '',
      }),};
  },

  data: () => {
    return {
      message: '',
      username: '',
      password: '',
    };
  },
  computed: {
    ...mapStores(usePage),
    onPremis() {
      return this.page.pageData['auth-login']?.onPremis;
    },
    rules() {
      return {
        username: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
        password: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
      };
    },

  },
  mounted() {
    $('div#copyright p').text(function () {
      return $(this).text().replace('currentyear', new Date().getFullYear());
    });
  },
  unmounted() {
    // this.$unloadScript('/js/wheel-session.js');
  },
  methods: {
    // ...mapActions(['getPageData']),
    ...mapActions(useAuth, ['login']),

    onSubmit() {
      this.formRef
          ?.validate((errors) => {
            if (!errors) {
              this.login(this.formValue.username, this.formValue.password, { name: 'client-root' }).then((url) => {
                dev.log('ret', url);
                this.$router.push(url);
              });
            } else {
              dev.log('form not valid', errors);
            }
          })
          .catch((err) => {});


      // api
      //   .post('/login', { email: this.username, password: this.password })
      //   .then((res) => {
      //     if (res.status == 200) {
      //       this.$router.push({ name: 'client-root' });
      //     }
      //   })
      //   .catch(() => {});
    },
  },
};
</script>

<style lang="scss"></style>
