import api from '@/services/api';
import { defineStore } from 'pinia';
import {responseCatch} from '@/utils/responseCatchHandler';

export const useLicense = defineStore({
  id: 'license',
  state: () => {
    return {
      licenseError: undefined,
      licenseLoaded: false,
      context: undefined,
    };
  },
  getters: {
    contextResolver(state) {
      return state.context || this.getContext();
    },
    license(state) {
      return this.contextResolver?.license;
    },
    licenseStatus(state) {
      return this.licenseError?.licenseStatus;
    },
    sclProcessors(state) {
      return this.contextResolver?.license?.license_data?.sclProcessors;
    },
    licenseUsedProjects(state) {
      return this.contextResolver?.licenseUsedProjects;
    },
  },
  actions: {
    async getLicense() {
      const me = await this.getContext();
      dev.log('aw license', me);
      return me;
    },
    getContext() {
      return new Promise((resolve, reject) => {
        api
          .get('/current-license')
          .then((response) => {
            const { data } = response;
            if (data.status) {
              this.context = data;
              this.licenseError = undefined;
            } else {
              responseCatch(response);
            }
            resolve(this.context);
          })
          .catch(responseCatch)
          .finally(() => {
            this.licenseLoaded = true;
          });
      });
    },
    storeLicenseError(data) {
      this.licenseError = data;
      this.context = data;
    },
  },
});
