<template>
  <div class="period-picker">
    <div class="locker" v-scroll-lock="focus">
      <el-date-picker
        key="date-1"
        v-model="value1"
        type="datetime"
        placeholder="Начало"
        :clearable="true"
        style="margin-bottom: 8px"
        format="DD.MM.YYYY HH:mm:ss"
        value-format="x"
        :shortcuts="shortcutsMin"
        :disabled-date="disabledDate"
        @change="changeLeft"
        @focus="onFocus"
        @blur="onBlur"
      >
      </el-date-picker>
      <el-date-picker
        key="date-2"
        v-model="value2"
        type="datetime"
        placeholder="Конец"
        :clearable="true"
        format="DD.MM.YYYY HH:mm:ss"
        value-format="x"
        :shortcuts="shortcutsMax"
        :disabled-date="disabledDate"
        @change="changeRight"
        @focus="onFocus"
        @blur="onBlur"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';

import { mapStores } from 'pinia';
import { useFilters } from '@/stores/filters';
import { usePrefs } from '@/stores/prefs';

export default {
  name: 'period-picker',
  props: {
    value: {
      type: [Object, Array, undefined],
    },
  },
  emits: ['change'],
  data: () => {
    return {
      value1: '',
      value2: '',
      focus: false,
    };
  },
  computed: {
    ...mapStores(useFilters),
    ...mapStores(usePrefs),
    lang() {
      return this.prefs.language;
    },
    offset() {
      return this.prefs.timezoneOffset;
    },

    bounds() {
      return this.filters.filters.clusterDateBounds;
    },
    value1default() {
      return this.clusterDateBoundsMin;
    },
    value2default() {
      return this.clusterDateBoundsMax;
    },
    clusterDateBoundsMin() {
      return this.filters.filters.clusterDateBounds.min;
    },
    shLabelFirst() {
      return this.$t('First cluster');
    },
    shLabelMid() {
      return this.$t('Middle point');
    },
    shLabelLast() {
      return this.$t('Last cluster');
    },
    clusterDateBoundsMax() {
      return this.filters.filters.clusterDateBounds.max;
    },
    shortcutsMin() {
      return [
        {
          text: this.shLabelFirst,
          value: () => {
            return new Date(this.clusterDateBoundsMin);
          },
        },
        {
          text: this.shLabelMid,
          value: () => {
            return new Date((this.clusterDateBoundsMin + this.clusterDateBoundsMax) / 2);
          },
        },
      ];
    },
    shortcutsMax() {
      return [
        {
          text: this.shLabelLast,
          value: () => {
            return new Date(this.clusterDateBoundsMax);
          },
        },
        {
          text: this.shLabelMid,
          value: () => {
            return new Date((this.clusterDateBoundsMin + this.clusterDateBoundsMax) / 2);
          },
        },
      ];
    },
  },
  created() {
  },
  mounted() {
    // console.log('datepicker start', JSON.stringify(this.value), JSON.stringify(this.bounds));
    if (this.value.min) {
      this.value1 = this.value.min;
    } else {
      // this.value1 = this.filters.filters.clusterDateBounds.min;
    }
    if (this.value.max) {
      this.value2 = this.value.max;
    } else {
      // this.value2 = this.filters.filters.clusterDateBounds.max;
    }
  },
  methods: {
    disabledDate(time) {
      return time.getTime() < this.clusterDateBoundsMin || time.getTime() > this.clusterDateBoundsMax;
    },
    changeLeft(v) {
      dev.log('left', v, this.value1default, this.value1);
      if (this.value1 && (this.value1 < this.value1default || this.value1 > this.value2default)) {
        this.value1 = this.value1default;
      }
      this.$emit('change', { min: this.value1, max: this.value2 });
    },
    changeRight(v) {
      dev.log('right1', v, this.value2default, this.value2);
      if (this.value2 && (this.value2 < this.value1default || this.value2 > this.value2default)) {
        this.value2 = this.value2default;
      } else {
      }
      dev.log('right2', v, this.value2default, this.value2);
      this.$emit('change', { min: this.value1, max: this.value2 });
    },

    onFocus() {
      // console.log('onFocus');
      this.focus = true;
    },
    onBlur() {
      // console.log('onBlur');
      this.focus = false;
    },
  },
  watch: {
    dateLeft: function (val) {
      this.value1 = val;
    },
    dateRight: function (val) {
      this.value2 = val;
    },
  },
};
</script>

<style scoped></style>
