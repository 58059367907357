<template>
  <div class="modal-overlay">
    <div
      :aria-labelledby="modal.name + '-modal__title'"
      :class="[modal.name + '-modal', { show: visible }]"
      :id="modal.name + '-modal'"
      class="modal fade"
      role="dialog"
      tabindex="-1"
      :style="[{ 'display:block;': visible }]"
    >
      <n-scrollbar style="min-height: 100vh">
        <div
          class="tp-modal-dialog modal-dialog modal-dialog-centered"
          role="document"
          :class="[modal.size ? 'modal-' + modal.size : '', { show: visible }]"
        >
          <div class="modal-content">
            <div class="modal-header" :class="[modal.name + '-modal__header']">
              <h5 v-if="!modal.noTitle" class="tp-modal-title" :id="modal.name + '-modal__title'">
                <slot name="title">
                  <span v-html="$t(`${modal.title}`)"></span>
                </slot>
              </h5>
              <button
                v-if="!modal.noCross"
                class="close"
                type="button"
                aria-label="Close"
                @click="$emit('update:visible', false)"
              >
                <span aria-hidden="true"> &times; </span>
              </button>
            </div>
            <div class="modal-dialog__header-line"></div>
            <div class="modal-body" :class="modal.name + '-modal__body'">
              <slot name="content"></slot>
            </div>
            <div
              v-if="!modal.noActions"
              class="modal-footer"
              :class="[modal.name + '-modal__footer', { 'modal-footer--center': modal.centerActions }]"
            >
              <slot name="buttons">
                <button
                  class="btn btn-sec"
                  type="button"
                  :disabled="modal.cancelDisabled"
                  data-dismiss="modal"
                  :aria-label="$t(`${modal.closeBtn ? modal.closeBtn : 'Cancel'}`)"
                  :id="$t(`${modal.closeBtn ? modal.closeBtn : 'Cancel'}`)"
                  @click="$emit('closeModal')"
                >
                  {{ $t(`${modal.closeBtn ? modal.closeBtn : 'Cancel'}`) }}
                </button>
                <button
                  v-if="modal.onSubmit"
                  class="btn btn-pri"
                  type="button"
                  :disabled="modal.submitDisabled"
                  data-dismiss="modal"
                  :aria-label="$t(`${modal.closeBtn ? modal.closeBtn : 'Cancel'}`)"
                  :id="$t(`${modal.closeBtn ? modal.closeBtn : 'Cancel'}`)"
                  @click="$emit('onSubmit')"
                >
                  {{ $t(`${modal.onSubmit}`) }}
                </button>
              </slot>
            </div>
            <div v-else class="modal-footer" :class="[modal.name + '-modal__footer']"></div>
          </div>
        </div>
      </n-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useVisual } from '@/stores/visual';

export default {
  name: 'modal-dialog',
  props: {
    modal: Object,
    visible: Boolean,
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    cancelDisabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:visible', 'close'],
  components: {},
  data: () => {
    return {
      // modalName: 'fff',
    };
  },
  computed: {
    ...mapStores(useVisual),
  },
  mounted() {},
  methods: {
    showModal() {
      this.visual.modalVisible = true;
      var locModal = document.getElementById(this.modal.name + '-modal');
      if (locModal) {
        locModal.style.display = 'block';
        locModal.style.paddingRight = '17px';
        locModal.className = 'modal fade show';
      }
    },
    hideModal() {
      this.visual.modalVisible = false;
      var locModal = document.getElementById(this.modal.name + '-modal');
      if (locModal) {
        locModal.style.display = 'none';
        locModal.className = 'modal fade';
        dev.log('hide');
        this.$emit('close', false);
        this.$emit('update:visible', false);
      }
    },
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.showModal();
        } else {
          this.hideModal();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.modal-footer {
  &--center {
    justify-content: center !important;
  }
}
.modal.show {
  display: block;
}
</style>
<style lang="scss" scoped>
.modal[role='dialog'] {
  background-color: rgb(0 0 0 / 50%);
}
</style>
