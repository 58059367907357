import api from '@/services/api';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue/dist/vue.esm-bundler';

import { useTpws } from '@/stores/tpws';

export const useWheelSession = defineStore({
  id: 'wheelSession',
  persist: {
    // enabled: true,
    // encryptionKey: 'my-store',
    paths: ['subnet', 'settings', 'session', 'cookies'],
    storage: sessionStorage,
  },
  state: () => {
    return {
      eventsCounterVisibility: false,
      eventsCounterPeriod: 40,
      liveMode: false,
      subnet: undefined,
      cookies: undefined,
      session: undefined,
      project: undefined,
      settings: {},
    };
  },
  getters: {
    // todo setterp rojectSettings(newVal) {return settings;}
    projectSettings: (state) => {
      const settings = state.session && state.project in state.session ? state.session[state.project] : {};
      // console.info('Settings from cookies: ', settings)
      return settings;
    },
  },
  actions: {
    // todo очищать сессию при входе this.cleanSession()
    initSession() {
      const tpws = useTpws();
      this.project = tpws.currentProject;
      this.applyProjectSettings();
    },
    saveProp(name, value) {
      this[name] = value;
      this.saveSession();
    },
    applyProjectSettings() {
      //если уже есть сохраненные настройки, то они добавляются в this.settings
      const settings = this.projectSettings;
      for (const prop in settings) {
        this.settings[prop] = settings[prop];
      }
    },
    saveSession() {
      const record = {
        [this.project]: this.settings,
      };
      //запись о настройках для проекта либо просто сохраняется,
      if (!this.session) {
        this.cookies = { wheelSession: record };
      } else {
        //либо уже существующий набор настроек перезаписывается с добавлением новых настроек для данного проекта
        this.session = { ...this.session, ...record };
        this.cookies = {
          wheelSession: this.session,
        };
      }
    },

    cleanSession() {
      this.cookies = {
        wheelSession: '',
      };
    },
  },
});
