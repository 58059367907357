<template>
  <h3 class="tip-header">{{ $t('Access Point name', { name: ap.name }) }}</h3>
  <ul class="list-unstyled">
    <li>
      <span class="tip-label">{{ $t('IP address') }}</span> {{ ap.ip }}
    </li>
    <li>
      <span class="tip-label">{{ $t('Subnet mask') }}</span> {{ ap.mask }}
    </li>
    <li>
      <span class="tip-label">{{ $t('Gateway') }}</span> {{ ap.gw }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'wh-access-point-desc',
  props: {
    ap: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
