import api from '@/services/api';
import { defineStore } from 'pinia';

import { useTpws } from '@/stores/tpws';

export const useBundle = defineStore({
  id: 'bundle',
  state: () => {
    return {
      bundleLoading: false,
      contextA: undefined,
      contextV: undefined,
    };
  },
  getters: {
    activeBundle(state) {
      return this.contextResolverA || {};
    },
    viewedBundle(state) {
      return this.contextResolverV || {};
    },
    contextResolverA(state) {
      return state.contextA || this.getContextA();
    },
    contextResolverV(state) {
      return state.contextV || this.getContextV();
    },
  },
  actions: {
    getContextA() {
      const tpws = useTpws();
      return new Promise((resolve, reject) => {
        this.bundleLoading = true;
        api
          .post(`/projects/${tpws.currentProject}/bundle`, { isActiveVersion: true })
          .then(({ data }) => {
            this.bundleLoading = false;
            dev.log('bundle A resolve', data);
            this.contextA = data;
            resolve(this.contextA);
          })
          .catch((error) => {
            this.bundleLoading = false;
            console.error('bundle A error', error);
            resolve(error);
          });
      });
    },
    getContextV() {
      const tpws = useTpws();
      return new Promise((resolve, reject) => {
        this.bundleLoading = true;
        api
          .post(`/projects/${tpws.currentProject}/bundle`, { isActiveVersion: false })
          .then(({ data }) => {
            this.bundleLoading = false;
            dev.log('bundle V resolve', data);
            this.contextV = data;
            resolve(this.contextV);
          })
          .catch((error) => {
            this.bundleLoading = false;
            console.error('bundle V error', error);
            resolve(error);
          });
      });
    },
  },
});
