<template>
  <div class="user-control">
    <div v-if="permission" class="card-project">
      <div id="card-header" class="card-title project-page__card-title">
        <h5 class="">
          <div class="card-project__title-text">{{ $t('Users') }}</div>
        </h5>
        <div
          v-if="permission.indexOf('usermanagement') > -1 && !updateStatus"
          class="ui-button pointer-cursor"
          @click="openUpdate()"
        >
          <div class="ui-button__icon">
            <v-svg src="edit" fill="#1757BD"></v-svg>
          </div>
          <div class="ui-button__lable">{{ $t('Edit') }}</div>
        </div>
      </div>
      <div class="card-body">
        <div v-if="false" class="page-actions__search">
          <div class="ui-search" style="margin: 10px 20px">
            <div class="ui-search__pre-icon">
              <img src="/images/search-blue.svg" loading="lazy" alt="" />
            </div>
            <div class="ui-search__placeholder">{{ $t('Search') }}</div>
          </div>
        </div>
        <div class="card-group-users">
          <div v-for="userGroup in users" :key="userGroup.roleVal" class="card-container">
            <div class="card-default">
              <div class="card-header-group-users">
                <h6 class="card-project__btn-link">{{ userGroup.role }} ({{ userGroup.users.length }})</h6>
              </div>
              <div class="card-body-group-users">
                <div class="users-container">
                  <div
                    v-for="user in userGroup.users"
                    :key="user.email"
                    class="badge-user badge badge-pill badge-primary"
                    :style="{
                      backgroundColor: user.bgCollor,
                      textDecoration: user.statusUser === 'del' ? 'line-through' : 'none',
                    }"
                    @click="selectUser(user)"
                  >
                    <div class="users-container-current">{{ user.firstname }} {{ user.lastname }}</div>
                    <div
                      v-if="updateStatus"
                      style="width: 27px; height: 27px; display: flex; justify-content: center; align-items: center"
                      @click.stop="delUser(userGroup.roleVal, user.email)"
                    >
                      <v-svg src="cross-8" fill="white"></v-svg>
                    </div>
                  </div>
                  <el-popover
                    v-if="updateStatus"
                    :ref="'popoverRef' + userGroup.roleVal"
                    placement="top-start"
                    popper-class="el-popover--no-v-paddind"
                    width="545"
                    trigger="click"
                    v1-model:visible="userGroup.viseblePopoverAddUser"
                  >
                    <div v-click-outside="onClickOutside">
                      <div class="header-popover">
                        <h6
                          style="
                            font-family: Noto Sans;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 32px;
                            text-transform: uppercase;
                            color: #1757bd;
                          "
                        >
                          {{ $t('Add user') }}
                        </h6>
                        <span class="btn-close pointer" @click="closePoposer($event, 'popRef' + userGroup.roleVal)">
                          <v-svg src="cross-stroke" stroke="#121314"></v-svg>
                        </span>
                      </div>
                      <div class="body-popover">
                        <el-autocomplete
                          style="width: 100%; padding: 0 14px; margin: 40px 0"
                          v-model="userGroup.newName"
                          :fetch-suggestions="querySearchAsync"
                          :placeholder="$t('email')"
                          @select="handleSelect($event, userGroup.roleVal)"
                        >
                          <template #prefix>
                            <v-svg src="search" fill="#1757BD" cc="search-icon"></v-svg>
                          </template>

                          <template #default="{ item }">
                            <div class="value">{{ item.email }}</div>
                            <div v-if="item.newEmail">
                              <p class="autocomplete__text">{{ $t('No such user was found') }}</p>
                              <div class="ui-button pointer-cursor" style="max-width: 300px">
                                <div class="ui-button__icon--no-label">
                                  <v-svg src="add" fill="#1757BD"></v-svg>
                                </div>
                                <div class="ui-button__lable">{{ $t('Create new user') }}</div>
                              </div>
                            </div>
                            <div v-if="item.isAlreadyUser">
                              <p class="autocomplete__text">{{ $t('User already exists') }}</p>
                            </div>
                          </template>
                        </el-autocomplete>
                      </div>
                    </div>
                    <template #reference>
                      <div
                        class="ui-button pointer-cursor"
                        @click="addUser(userGroup)"
                        :ref="'popRef' + userGroup.roleVal"
                        :virtual-ref="'popoverRef' + userGroup.roleVal"
                        v-click-outside="onClickOutside"
                      >
                        <div class="ui-button__icon--no-label">
                          <v-svg src="add" fill="#1757BD"></v-svg>
                        </div>
                      </div>
                    </template>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end" v-if="updateStatus">
          <div style="margin-right: 16px" @click="closeUpdate" class="ui-button pointer-cursor">
            <div class="ui-button__lable">
              <div>{{ $t('Cancel') }}</div>
            </div>
          </div>
          <div class="ui-button ui-button--active pointer-cursor" @click="updateUsers">
            <div class="ui-button__lable ui-button__lable--active">
              <div>{{ $t('Save') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="visibleDialog" width="50%" :show-close="false" class="noHeaderBlock">
      <template #header>
        <slot name="title"></slot>
      </template>
      <template #default>
        <div class="modal-window__layout">
          <div class="modal-window__main">
            <div class="modal-window__header modal-window__header--top-align">
              <div class="modal-header__panel">
                <div class="header-title">
                  <div class="header-title__text">
                    <div>{{ $t('User details') }}</div>
                  </div>
                  <div class="header-title__buttons"></div>
                </div>
              </div>
              <div @click="closeModal" class="modal-header__close-btn pointer-cursor">
                <img src="/images/modal-close-crossline-black.svg" loading="lazy" alt="" />
              </div>
            </div>
            <hr class="modal-window__hr" />
            <div class="modal-window__body">
              <div class="ui-text-block">
                <div class="lvb-table__modal-label">
                  <div>{{ $t('Name') }}</div>
                </div>
                <div class="ui-text-block__body_info">{{ selectedUser.firstname }} {{ selectedUser.lastname }}</div>
              </div>
              <div class="ui-text-block">
                <div class="lvb-table__modal-label"><div>Email</div></div>
                <div class="ui-text-block__body_info">{{ selectedUser.email }}</div>
              </div>
              <div class="ui-text-block">
                <div class="lvb-table__modal-label">
                  <div>{{ $t('Phone') }}</div>
                </div>
                <div class="ui-text-block__body_info">
                  {{ selectedUser.phone }}
                </div>
              </div>
              <div class="ui-text-block">
                <div class="lvb-table__modal-label">
                  <div>{{ $t('Company') }}</div>
                </div>
                <div class="ui-text-block__body_info">
                  {{ selectedUser.company }}
                </div>
              </div>
              <div class="ui-text-block">
                <div class="lvb-table__modal-label">
                  <div>{{ $t('Position') }}</div>
                </div>
                <div class="ui-text-block__body_info">
                  {{ selectedUser.position }}
                </div>
              </div>
            </div>
            <div class="modal-window__actions">
              <div class="modal-actions">
                <div class="modal-actions__col"></div>
                <div class="modal-actions__col">
                  <div class="d-flex">
                    <div style="margin-right: 16px" @click="closeModal" class="ui-button pointer-cursor">
                      <div class="ui-button__lable">
                        <div>{{ $t('Close') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import chroma from 'chroma-js';
import api from '@/services/api';
import { ref, unref } from 'vue';
import { ClickOutside } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePage } from '@/stores/page';
import { usePrefs } from '@/stores/prefs';
// import ClickOutside from '@/utils/v-click-outside';

export default {
  name: 'users-control',
  props: ['dataset'],
  directives: {
    ClickOutside,
  },
  components: {
    Search,
  },
  data() {
    return {
      users: [],
      updateStatus: false,
      visible: false,
      selectRoleval: '',
      visibleDialog: false,
      selectedUser: {},
      han: undefined,
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(usePage),
    ...mapStores(usePrefs),
    currentProject() {
      return this.tpws.currentProject;
    },
    permission() {
      return this.page.pageData['project-users']?.permission;
    },
    visibleUserPopover() {
      return this.users.filter((el) => el.viseblePopoverAddUser).length > 0;
    },
  },
  methods: {
    onClickOutside() {},
    closePoposer(ev, e1) {
      this.$refs[e1][0].click();
    },
    async querySearchAsync(email, cb) {
      const result = await this.getuserbyemail(email);
      const allUsers = this.users[this.findUsersGroupIdx(this.selectRoleval)].users;
      let isAlreadyUser = false;
      let alreadyEmail = null;
      if (result.length) {
        const allEmailResult = result.map((user) => user.email);
        allUsers.forEach((user) => {
          if (allEmailResult.includes(user.email)) {
            isAlreadyUser = true;
            alreadyEmail = user.email;
          }
        });
        if (isAlreadyUser) {
          const delIdx = result.findIndex((item) => item.email === alreadyEmail);
          result.splice(delIdx, 1);
        }
        if (alreadyEmail === email) {
          const alreadyUser = [
            {
              isAlreadyUser: true,
              email,
            },
          ];
          cb(alreadyUser);
        } else {
          cb(result);
        }
      } else {
        const isValidEmail = this.validateEmail(email);
        allUsers.forEach((user) => {
          if (user.email === email) {
            isAlreadyUser = true;
            alreadyEmail = email;
          }
        });
        if (alreadyEmail === email) {
          const alreadyUser = [
            {
              isAlreadyUser: true,
              email,
            },
          ];
          cb(alreadyUser);
          return;
        }
        if (isValidEmail) {
          const newUserByEmail = [
            {
              email,
              newEmail: true,
              firstname: email,
            },
          ];
          cb(newUserByEmail);
        } else cb(result);
      }

      // cb(result);
    },
    handleSelect(item, role) {
      this.users.forEach((user) => {
        if (item.isAlreadyUser) {
          return;
        }
        if (user.roleVal === role) {
          item.bgCollor = this.fillUsersAlpha(item.email);
          item.statusUser = item.newEmail ? 'newUserEmail' : 'newUser';
          user.users.push(item);
        }
      });
    },
    newUser(group) {
      group.viseblePopoverAddUser = false;
      group.newName = '';
      group.users.forEach((user) => {
        user.bgCollor = this.fillUsersDarken(user.email);
      });
    },
    newUsers(groups) {
      groups.forEach((group) => this.newUser(group));
      return groups;
    },
    async fetchAllUsers() {
      const res = await api.get('/projects/' + this.$route.params['projectId'] + '/users/');
      if (res.statusText === 'OK') {
        this.users = this.newUsers(res.data.projectusers);
      }
    },
    openUpdate() {
      this.updateStatus = true;
    },
    async closeUpdate() {
      this.updateStatus = false;
      await this.fetchAllUsers();
    },
    delUser(group, email) {
      const idx = this.findUsersGroupIdx(group);
      const idxUsers = this.users[idx].users.findIndex((user) => user.email === email);
      const user = this.users[idx].users[idxUsers];
      if (user.statusUser) {
        if (user.statusUser === 'del') {
          user.statusUser = null;
          this.$forceUpdate(idxUsers, 1);
          return;
        }
        if (user.statusUser === 'newUser' || user.statusUser === 'newUserEmail') {
          this.users[idx].users.splice(idxUsers, 1);
          return;
        }
      }
      user.statusUser = 'del';
      this.$forceUpdate();
    },
    addUser(userGroup) {
      userGroup.viseblePopoverAddUser = true;
      this.selectRoleval = userGroup.roleVal;
    },
    async updateUsers() {
      const newUserRole = [];
      const newUserToEmail = [];
      const delUsers = [];
      this.users.forEach((role) => {
        const roleVal = role.roleVal;
        role.users.forEach((user) => {
          if (user.statusUser === 'newUser') {
            newUserRole.push({
              email: user.email,
              roleVal,
            });
          }
          if (user.statusUser === 'newUserEmail') {
            newUserToEmail.push({
              email: user.email,
              roleVal,
            });
          }
          if (user.statusUser === 'del') {
            delUsers.push({
              email: user.email,
              roleVal,
            });
          }
        });
      });
      for (const user of newUserRole) {
        await this.adduserrole(user.email, user.roleVal);
        dev.log('newUserRole', user);
      }
      for (const user of newUserToEmail) {
        await this.inviteuserbyemail(user.email, user.roleVal);
        dev.log('newUserToEmail', user);
      }
      for (const user of delUsers) {
        await this.removeuserrole(user.email, user.roleVal);
        dev.log('delUsers', user);
      }
      await this.fetchAllUsers();
      this.updateStatus = false;
    },
    getUserColor(name) {
      let nameSum = 0;
      for (var i = 0; i < name.length; i++) {
        nameSum += name.charCodeAt(i) + i;
      }
      return this.$colors[nameSum % 192];
    },
    fillUsersDarken(user) {
      return chroma(this.getUserColor(user)).darken();
    },
    fillUsersAlpha(user) {
      const color = chroma(this.getUserColor(user)).darken();
      return chroma(color).alpha(0.3);
    },
    findUsersGroupIdx(role) {
      return this.users.findIndex((item) => item.roleVal === role);
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async getuserbyemail(email) {
      const res = await api.get(`/projects/${this.currentProject}/getuserbyemail?email=${email}`);
      dev.log('res', res.data, typeof res.data);
      if (res.data.desc) {
        return [];
      }
      const ar = [];
      ar.push(res.data);
      dev.log('resu', ar);
      return ar;
    },
    async adduserrole(email, role) {
      await api.get(`/projects/${this.currentProject}/adduserrole?email=${email}&role=${role}`);
      await api.get(`/projects/${this.currentProject}/adduser?email=${email}`);
    },
    async inviteuserbyemail(email, role) {
      await api.get(`/projects/${this.currentProject}/invite2?email=${email}&role=${role}`);
    },
    async removeuserrole(email, role) {
      await api.get(`/projects/${this.currentProject}/removeuserrole?email=${email}&role=${role}`);
      const newUsers = await api.get(`/projects/${this.currentProject}/users`);
      let stayUser = false;
      newUsers.data.projectusers.forEach((uss) => {
        const found = uss.users.find((us) => us.email === email);
        if (found) {
          stayUser = true;
        }
      });
      if (!stayUser) {
        await api.get(`/projects/${this.currentProject}/removeuser?email=${email}`);
      }
    },

    selectUser(user) {
      this.selectedUser = user;
      this.visibleDialog = true;
    },

    closeModal() {
      this.visibleDialog = false;
    },
  },
  created() {
    this.han = (ev) => {
      dev.log('cl', ev);
    };
  },
  mounted() {
    this.$router.isReady().then(() => {
      this.fetchAllUsers();
    });

    // const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
    // this.$el.addEventListener(clickHandler, this.han);
  },
  unmounted() {
    // const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
    // this.$el.removeEventListener(clickHandler, this.han);
  },
  watch: {
    'prefs.language': {
      handler(val, old) {
        if (val !== old) {
          this.fetchAllUsers();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.header-popover {
  display: flex;
  justify-content: space-between;
  width: 497px;
  align-items: center;
  padding: 20px 0 12px;
  margin: 0 auto;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  color: #1757bd;
  border-bottom: 1px solid #eaeaea;
  h6 {
    margin: 0;
  }
}
.body-popover .el-input__prefix {
  left: 6px;
}
.search-icon {
  margin: auto;
}
// .el-input__inner {
//   border: none !important;
//   border-bottom: 1px solid red !important;
// }

.el-popover--no-v-paddind {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.el-autocomplete {
  .el-input {
    input.el-input__inner {
      border-radius: 0 !important;
      border: none !important;
      border-bottom: 1px solid #eaeaea !important;
      font-family: Noto Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1757bd !important;
    }
  }
}
.autocomplete__text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1757bd;
  opacity: 0.5;
}
.ui-text-block__body_info {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
</style>
