<template>
  <input
    @input="handleCheck"
    type="checkbox"
    :checked="checked"
    :disabled="disabled"
    :id="fieldId"
    class="mr-2 hidden"
  />
  <label
    :for="fieldId"
    :class="{
      'flex flex-row items-center font-bold text-gray-800 cursor-pointer select-none': !disabled,
      'flex flex-row items-center font-bold text-gray-500 cursor-pointer select-none': disabled,
    }"
  >
    <i
      class="mr-2"
      :class="{
        'fa fa-check-square text-tekvel-blue': checked && !disabled,
        'fa fa-check-square text-gray-500': checked && disabled,
        'far fa-square text-gray-800': !checked && !disabled,
        'fa fa-square text-gray-500': !checked && disabled,
      }"
    ></i>
    {{ $t(label) }}
  </label>
</template>

<script>
export default {
  name: 't-checkbox',
  props: {
    label: String,
    fieldId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCheck(e) {
      console.log(e)
      if (!this.disabled) {
        this.$emit('update:checked', e.target.checked)
      }
    }
  },
};
</script>

<style scoped>
.hidden{display:none;}
.flex{display:flex;}
.flex-row{flex-direction:row;}
.items-center{align-items:center;}
.text-gray-500{--text-opacity:1;color:#a0aec0;color:rgba(160,174,192,var(--text-opacity));}
.text-gray-800{--text-opacity:1;color:#2d3748;color:rgba(45,55,72,var(--text-opacity));}
.text-tekvel-blue{--text-opacity:1;color:#1757BD;}
.cursor-pointer{cursor:pointer;}
.select-none{-webkit-user-select:none;-ms-user-select:none;user-select:none;}

</style>
