<template>
  <div class="top-bar">
    <div class="top-bar__title">
      <slot name="title"></slot>
    </div>
    <div class="top-bar__body">
      <div class="top-bar__body-left">
        <slot name="left"></slot>
      </div>
      <div class="top-bar__body-right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'top-bar',
};
</script>

<style>
.top-bar {
  padding: 24px 32px;
  background: #f7f7f9;
}
.top-bar__title {
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.04em;
  text-align: left;
  text-transform: uppercase;
}
.top-bar__body {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.top-bar__body-left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.top-bar__body-right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-end;
  justify-content: flex-end;
}
.top-bar__body-left .top-bar__item:not(:last-child) {
  margin-right: 8px;
}
.top-bar__body-right .top-bar__item:not(:first-child) {
  margin-left: 8px;
}
</style>
