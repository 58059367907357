<template>
  <div :id="'notification-content-' + type">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'notification-list',
  props: {
    type: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
