<template>
  <div id="projects-list-app" class="min-heigth-content">
    <Head>
      <title>{{ $t('Tekvel Park') + ' — ' + $t("User's projects") }}</title>
    </Head>
    <div class="content-block">
      <div class="page-block page-projects">
        <teleport to="#modals-target">
          <project-add @create="createProject" @close="closeCreateProject">
            <n-form ref="formRef" :model="formValue" :rules="rules" :label-width="80">
              <n-form-item :label="$t('Project name')" path="projectName">
                <n-input v-model:value="formValue.projectName" :placeholder="$t('Enter project name')" />
              </n-form-item>
            </n-form>
          </project-add>
        </teleport>

        <div class="page-block__header">
          <div class="page-actions">
            <div class="page-actions__tabs">
              <div class="page-tabs">
                <div
                  v-for="tab in tabs"
                  class="page-tabs__elm pointer-cursor"
                  :class="{ 'page-tabs__elm--active': tab.name === activeTab }"
                  :key="tab.name"
                  @click="setActiveTab(tab.name)"
                >
                  {{ $t(tab.title) }}
                </div>
              </div>
            </div>
            <div v-if="false" class="page-actions__search">
              <div class="ui-search">
                <div class="ui-search__pre-icon">
                  <img src="/images/search.svg" alt="" />
                </div>
                <div class="ui-search__placeholder">
                  <div>{{ $t('Search') }}</div>
                </div>
              </div>
            </div>
            <div class="spacer-grow"></div>
            <div v-if="projectsList.userCanAddProjects" class="page-actions__buttons m-l-8">
              <div @click="showAdd" class="ui-button pointer-cursor">
                <div class="ui-button__icon">
                  <img src="/images/plus.svg" alt="" />
                </div>
                <div class="ui-button__lable">
                  <div>{{ $t('Create project') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'active'" class="page-block__body">
          <div v-if="favoriteProjects.length > 0" class="page-section">
            <div class="page-section__title">{{ $t('Favorite') }}</div>
            <div class="page-section__projects-list1">
              <n-grid x-gap="16" y-gap="16" :cols="colsRule" item-responsive>
                <n-grid-item
                  :span="spanRule"
                  v-for="fProject in favoriteProjects"
                  :key="fProject._id"
                  class="project-card"
                >
                  <div class="project-card__title">
                    <div
                      class="project-card__title-text pointer-cursor"
                      @click="goToProject(fProject)"
                      style="max-width: calc(100% - 42px)"
                    >
                      <n-ellipsis :line-clamp="3" style="max-width: 100%; word-break: break-word">
                        {{ fProject.displayName }}
                      </n-ellipsis>
                    </div>
                    <div class="project-card__title-actions">
                      <div class="project-actions">
                        <div class="project-action pointer-cursor" @click="setProjectArchive(fProject.name, true)">
                          <img src="/images/in-archive.svg" alt="" />
                        </div>
                        <div class="project-action pointer-cursor" @click="setProjectFavorite(fProject.name, false)">
                          <img src="/images/favorite-star.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="project-card__users">
                    <div
                      v-for="user in distinct(fProject.users)"
                      :key="user.login"
                      :title="getUser(user).fullName"
                      class="project-user"
                      :style="{ backgroundColor: getUser(user).color }"
                    >
                      <div class="project-user__letters">{{ getUser(user).letters }}</div>
                    </div>
                  </div>
                  <div class="project-card__status">
                    <div class="project-status">
                      <div class="project-status__label">
                        <div>{{ $t('Last event') }}</div>
                      </div>
                      <div class="project-status__value">
                        <div>{{ projectsList.projectLastEvent(fProject._id) }}</div>
                      </div>
                    </div>
                    <div class="project-status">
                      <div class="project-status__label">
                        <div>{{ $t('Last SCL upload') }}</div>
                      </div>
                      <div class="project-status__value">
                        <div>{{ projectsList.projectLastVersion(fProject._id) }}</div>
                      </div>
                    </div>
                  </div>
                </n-grid-item>
              </n-grid>
            </div>
          </div>
          <div v-if="allProjects.length > 0" class="page-section">
            <div class="page-section__title">{{ $t('All projects') }}</div>
            <div class="page-section__projects-list1">
              <n-grid x-gap="16" y-gap="16" :cols="colsRule" item-responsive>
                <n-grid-item :span="spanRule" v-for="aProject in allProjects" :key="aProject._id" class="project-card">
                  <div class="project-card__title">
                    <div
                      class="project-card__title-text pointer-cursor"
                      @click="goToProject(aProject)"
                      style="max-width: calc(100% - 42px)"
                    >
                      <n-ellipsis :line-clamp="3" style="max-width: 100%; word-break: break-word">
                        {{ aProject.displayName }}
                      </n-ellipsis>
                    </div>
                    <div class="project-card__title-actions">
                      <div class="project-actions">
                        <div class="project-action pointer-cursor" @click="setProjectArchive(aProject.name, true)">
                          <img src="/images/in-archive.svg" alt="" />
                        </div>
                        <div class="project-action pointer-cursor" @click="setProjectFavorite(aProject.name, true)">
                          <img src="/images/favorite-star-empty.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="project-card__users">
                    <div
                      v-for="user in distinct(aProject.users)"
                      :key="user.login"
                      :title="getUser(user).fullName"
                      class="project-user"
                      :style="{ backgroundColor: getUser(user).color }"
                    >
                      <div class="project-user__letters">{{ getUser(user).letters }}</div>
                    </div>
                  </div>
                  <div class="project-card__status">
                    <div class="project-status">
                      <div class="project-status__label">
                        <div>{{ $t('Last event') }}</div>
                      </div>
                      <div class="project-status__value">
                        <div>{{ projectsList.projectLastEvent(aProject._id) }}</div>
                      </div>
                    </div>
                    <div class="project-status">
                      <div class="project-status__label">
                        <div>{{ $t('Last SCL upload') }}</div>
                      </div>
                      <div class="project-status__value">
                        <div>{{ projectsList.projectLastVersion(aProject._id) }}</div>
                      </div>
                    </div>
                  </div>
                </n-grid-item>
              </n-grid>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'archive'" class="page-block__body">
          <div class="page-section">
            <div class="page-section__title">{{ $t('Archived projects') }}</div>
            <div class="page-section__projects-list1">
              <n-grid x-gap="16" y-gap="16" :cols="colsRule" item-responsive>
                <n-grid-item
                  :span="spanRule"
                  v-for="aProject in archiveProjects"
                  :key="aProject._id"
                  class="project-card"
                >
                  <div class="project-card__title">
                    <div
                      class="project-card__title-text pointer-cursor"
                      @click="goToProject(aProject)"
                      style="max-width: calc(100% - 20px)"
                    >
                      <n-ellipsis :line-clamp="3" style="max-width: 100%; word-break: break-word">
                        {{ aProject.displayName }}
                      </n-ellipsis>
                    </div>
                    <div class="project-card__title-actions">
                      <div class="project-actions">
                        <div class="project-action pointer-cursor" @click="setProjectArchive(aProject.name, false)">
                          <img src="/images/in-archive.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="project-card__users">
                    <div
                      v-for="user in distinct(aProject.users)"
                      :key="user.login"
                      :title="getUser(user).fullName"
                      class="project-user"
                      :style="{ backgroundColor: getUser(user).color }"
                    >
                      <div class="project-user__letters">{{ getUser(user).letters }}</div>
                    </div>
                  </div>
                  <div class="project-card__status">
                    <div class="project-status">
                      <div class="project-status__label">
                        <div>{{ $t('Last event') }}</div>
                      </div>
                      <div class="project-status__value">
                        <div>{{ projectsList.projectLastEvent(aProject._id) }}</div>
                      </div>
                    </div>
                    <div class="project-status">
                      <div class="project-status__label">
                        <div>{{ $t('Last SCL upload') }}</div>
                      </div>
                      <div class="project-status__value">
                        <div>{{ projectsList.projectLastVersion(aProject._id) }}</div>
                      </div>
                    </div>
                  </div>
                </n-grid-item>
              </n-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import ProjectAdd from '@/components/projects/ProjectAdd.vue';
import DateNano from '../../utils/format.date.time';
import { DateTime } from 'luxon';
import { debounce } from '@/plugins/debounce';

import { mapStores } from 'pinia';
import { mapActions } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { useTpui } from '@/stores/tpui';
import { usePrefs } from '@/stores/prefs';
// import { useRoot } from '@/stores/root';
import { useAuth } from '@/stores/auth';
import { useVisual } from '@/stores/visual';
import { useProjectsList } from '@/stores/projectsList';
import { ref } from 'vue';

const equals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

export default {
  name: 'projects-list-app',
  components: { ProjectAdd },
  directives: {
    // resize,
  },
  setup() {
    const formRef = ref(null);
    // const message = useMessage()
    return {
      formRef,
      formValue: ref({
        projectName: '',
      }),
    };
  },

  data() {
    return {
      tabs: [
        {
          name: 'active',
          title: 'Active projects',
        },
        {
          name: 'archive',
          title: 'Archive',
        },
      ],
      visibleProjectAdd: false,
      activeTab: 'active',
      projectsData: {},
      // users: [],
      colsRule: '1 800:2 1100:3 1500:4',
      spanRule: '1',
      // formRef: ref(),
      // formValue: ref({
      //   projectName: '',
      // })
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useAuth),
    ...mapStores(usePrefs),
    ...mapStores(useVisual),
    ...mapStores(useProjectsList),
    // ...mapStores(useRoot),
    ...mapStores(useTpui),
    rules() {
      return {
        projectName: {
          required: true,
          message: this.$t('Value is required'),
          trigger: ['input', 'blur'],
        },
      };
    },
    language() {
      return this.prefs.language;
    },
    users() {
      return this.projectsList.allUsers || [];
    },
    getUser() {
      return (id) => {
        let user = this.users.find((el) => el.id == id) || { firstname: '', lastname: '' };
        return user;
      };
    },
    baseUrl() {
      //console.log('this.$route.path', this.$route);
      //console.log('window.location', window.location);
      return window.location.pathname.split('/').slice(0, -1).join('/');
    },
    favoriteProjects() {
      //console.log(this.projects);
      return this.projects.filter((el) => el.favorite && !el.archived) || [];
    },
    allProjects() {
      return this.projects.filter((el) => !el.favorite && !el.archived) || [];
    },
    archiveProjects() {
      return this.projects.filter((el) => el.archived) || [];
    },
    projects() {
      return this.projectsList.userProjects || [];
    },
    projectsNames() {
      return this.projects.map((el) => el.name) || [];
    },
    allUsers() {
      return this.projectsList.allUsers || [];
    },
  },
  created() {},
  mounted() {
    this.$router.isReady().then(() => {});
    this.getData();
  },
  methods: {
    ...mapActions(useTpws, ['clearProjectStatus', 'setRouteProject', 'reloadProjectStatus']),
    ...mapActions(useProjectsList, ['getProjects', 'requestProjectDetails', 'requestProjectsUsers']),
    distinct(arr) {
      return [...new Set(arr)];
    },
    showAdd() {
      this.visual.showProjectAddModal(true);
      this.formValue.projectName = '';
    },
    createProject() {
      let dataUrl = '/projects/add';
      this.formRef
        ?.validate((errors) => {
          if (!errors) {
            this.visual.showProjectAddModal(false);
            api
              .post(dataUrl, { name: this.formValue.projectName })
              .then((result) => {
                //console.log('result.data', result.data);
                // this.setProjectName(result.data.name);
                // this.reloadProjectStatus();
                this.$router.push({ name: 'current-project', params: { projectId: result.data.name } });
                // window.location.assign('/projects/' + result.data.name);
                this.formValue.projectName = '';
              })
              .catch((e) => {
                console.error(e);
                // this.errors.push(e);
                // this.loading = false;
              });
          } else {
            this.visual.showProjectAddModal(true);
            // console.log(errors);
          }
        })
        .catch((err) => {});
    },
    closeCreateProject() {
    },
    setActiveTab(name) {
      this.activeTab = name;
    },
    goToProject(project) {
      this.$router.push({
        name: 'current-project',
        params: {
          projectId: project.name,
        },
      });
    },
    getUserLetters(user) {
      return (user.firstname ? user.firstname[0] : '_') + (user.lastname ? user.lastname[0] : '_');
    },
    getUserName(user) {
      return (user.firstname ? user.firstname : '_') + ' ' + (user.lastname ? user.lastname : '_');
    },
    setProjectFavorite(name, status) {
      let dataUrl = this.baseUrl + '/project-set-favorite';
      api
        .post(dataUrl, { name, status })
        .then((result) => {
          const ind = this.projects.findIndex((pr) => pr.name === name);
          this.projects[ind] = result.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    setProjectArchive(name, status) {
      let dataUrl = this.baseUrl + '/project-set-archive';
      api
        .post(dataUrl, { name, status })
        .then((result) => {
          const ind = this.projects.findIndex((pr) => pr.name === name);
          this.projects[ind] = result.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getUserColor(name) {
      let nameSum = 0;
      for (let i = 0; i < name.length; i++) {
        nameSum += name.charCodeAt(i) + i;
      }
      return this.$colors[nameSum % 192];
    },
    getDateFormat(ts) {
      //console.log('getDateFormat', ts);
      return ts
        ? DateTime.fromMillis(ts / 1e3)
            .setLocale(this.language ? this.language : 'en')
            .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
        : '---';
    },
    bgColor(color) {
      return `'${color}'`;
    },

    getUsers(projects) {
      this.requestProjectsUsers(projects);
    },
    getData() {
      //console.log('route', this.$route.path.split('/').slice(0, -1).join('/'));
      this.getProjects();
      this.requestProjectDetails();
    },
  },
  watch: {
    projectsNames: {
      handler(list) {
        if (list) {
          this.getUsers(list);
        }
      },
    },
  },
};
</script>

<style>
#events-app {
  width: 100%;
}
</style>

<style lang="scss">
#top-app {
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
}
.report-progress0 {
  position: absolute;
  background-color: darkgray;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.report-progress {
  position: absolute;
  background-color: lawngreen;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
<style lang="scss"></style>
