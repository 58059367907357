<template>
  <div class="data-table">
    <div class="data-table-loading-wrapper" v-loading="loading">
      <!--      <loading :active.sync="loading" :can-cancel="false" :is-full-page="false"> </loading>-->

      <table class="data-table display" :class="className">
        <thead>
          <tr role="row">
            <th v-for="f in fields" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
              {{ f.label }}
            </th>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in rowsData"
            :row-data="rowData"
            :fields="fields"
            :key="rowData._id"
            :class-name="{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }"
            @select="selectRow(idx)"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ f.label }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div v-if="rowsData.length >= pageLength" class="data-pages">
      <div class="dataTables_paginate paging_simple_numbers" id="wheel_events_table_paginate">
        <ul class="pagination">
          <li
            v-if="currentPage > minPage"
            @click="currentPage = currentPage - 1"
            class="paginate_button page-item previous"
            id="wheel_events_table_previous"
          >
            <a aria-controls="wheel_events_table" data-dt-idx="0" tabindex="0" class="page-link">Предыдущая</a>
          </li>
          <li v-else class="paginate_button page-item previous disabled" id="wheel_events_table_previous">
            <a aria-controls="wheel_events_table" data-dt-idx="0" tabindex="0" class="page-link">Предыдущая</a>
          </li>
          <li
            v-for="(p, i) in pagesList"
            class="paginate_button page-item"
            :class="{ disabled: p === null, active: p === currentPage }"
            :key="i + '-' + p"
            @click="setCurrentPage(p)"
          >
            <a aria-controls="wheel_events_table" :data-dt-idx="i + 1" tabindex="0" class="page-link">{{
              p !== null ? p : '...'
            }}</a>
          </li>
          <li
            v-if="currentPage < maxPage"
            @click="currentPage = currentPage + 1"
            class="paginate_button page-item next"
            id="wheel_events_table_next"
          >
            <a aria-controls="wheel_events_table" :data-dt-idx="pagesList.length + 2" tabindex="0" class="page-link"
              >Следующая</a
            >
          </li>
          <li v-else class="paginate_button page-item next disabled" id="wheel_events_table_next">
            <a aria-controls="wheel_events_table" :data-dt-idx="pagesList.length + 2" tabindex="0" class="page-link"
              >Следующая</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DataRow from '@/components/common/DataRow.vue';
// import loading from 'vue-loading';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/css/index.css';

import ArchiveEventsModal from '@/components/modals/ArchiveEventsModal.vue';

import api from '@/services/api';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
export default {
  name: 'cluster-events-table',
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    // buttons: {
    //   type: Array,
    // },
    fields: {
      type: Array,
    },
    rowsData: {
      type: Array,
      default: [],
    },
    lengthMenu: {
      type: Array,
    },
    offTypes: {
      type: Array,
    },
    noSelect: {
      type: Boolean,
      default: false,
    },
    archive: Boolean,
  },
  components: { ArchiveEventsModal, DataRow, Loading },
  // directives: { loading },
  data: () => {
    return {
      sortables: [],
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      pageStart: 0,
      pageLength: 17,
      searchString: '',
      // searchTypes: [],
      // rowsData: [],
      recordsTotal: 0,
      loading: false,
      draw: 0,
      errors: [],
      selectedRows: [],
      visibleArchiveDialog: false,
      buttons: [
        {
          name: 'print',
          label: 'Print',
        },
        {
          name: 'export-sub',
          label: 'Export',
          children: [
            {
              name: 'pdf',
              label: 'PDF',
              action: 'exportPdf',
            },
            {
              name: 'csv',
              label: 'CSV',
              action: 'exportCsv',
            },
            {
              name: 'xls',
              label: 'Excel',
              action: 'exportXls',
            },
          ],
        },
        {
          name: 'archive',
          label: 'Archive',
          action: 'switchArchive',
        },
        {
          name: 'view-selected',
          label: 'View selected',
          action: 'openSelected',
        },
      ],
    };
  },
  computed: {
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    enableView() {
      return this.rowsDataSelected.filter((e) => e.hasRef).length > 1;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    order() {
      return {
        column: this.fields.map((e) => e.name).indexOf(this.currentSortField),
        dir: this.currentSortDir === 1 ? 'asc' : 'desc',
      };
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    offTypesArray() {
      return this.offTypes && this.offTypes.map((e) => '!' + e);
    },
    search() {
      let searchArray = this.offTypesArray;
      if (this.searchString !== '') {
        searchArray = [...this.offTypesArray, this.searchString];
      }
      return {
        value: searchArray.join(','),
        regex: false,
      };
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      return this.$qs.stringify({
        draw: this.draw,
        order,
        search,
        start: this.pageStart,
        length: this.pageLength,
      });
    },
  },
  mounted() {
    this.setDefaultSort();
    // this.loadData();
    dev.log('r', this.$route);
  },
  methods: {
    execAction(a, e) {
      if (a) {
        this[a](e);
      }
    },
    exportXls(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.currentProject}/archive-xls?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.currentProject}/events2-xls`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProject} ` + this.$t('analysis report') + `.xlsx`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportPdf(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.currentProject}/archive-pdf?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.currentProject}/events2-pdf`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProject} ` + this.$t('analysis report') + `.pdf`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportCsv(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.currentProject}/archive-csv?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.currentProject}/events2-csv`;
      api
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.currentProject} ` + this.$t('analysis report') + `.csv`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    switchArchive(e) {
      this.visibleArchiveDialog = true;
    },
    openSelected(e) {
      dev.log('openSelected', e);
      const ts = this.rowsDataSelectedTime;
      if (e.ctrlKey || e.shiftKey || e.altKey)
        window.open(`?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`, '_blank');
      else location.href = `?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`;
    },
    selectRow(i) {
      if (!this.noSelect) {
        const rowInx = this.pageStart + i;
        dev.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
        if (this.selectedRows.indexOf(rowInx) < 0) {
          this.selectedRows.push(rowInx);
        } else {
          this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
        }
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    // loadData() {
    //   this.draw++;
    //   console.log('sq', this.queryString);
    //   let dataUrl = this.url + '?' + this.queryString;
    //   this.selectedRows = [];
    //   this.loading = true;
    //
    //   this.$emit('pageUpdate');
    //
    //   api
    //     .get(dataUrl)
    //     .then((response) => {
    //       // JSON responses are automatically parsed.
    //       this.rowsData = response.data.data;
    //       this.recordsTotal = response.data.recordsTotal;
    //       this.loading = false;
    //     })
    //     .catch((e) => {
    //       this.errors.push(e);
    //       this.loading = false;
    //     });
    // },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          return 'sorting_asc';
        }
        if (this.currentSortDir === -1) {
          return 'sorting_desc';
        }
      } else {
        return f.sortable ? 'sorting' : '';
      }
    },
    changeSort(f) {
      dev.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.loadData();
      this.$emit('pageUpdate');
    },
  },
  watch: {
    offTypes: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    searchString: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
    pageLength: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    pageStart: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
  },
};
</script>

<style scoped>
.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
