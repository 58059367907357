<template>
  <div class="top-nav" :style="zStyle">
    <div class="top-nav-elm" style="display: flex">
      <div class="top-nav-elm__icon pointer-cursor" @click="toggleSidebar">
        <v-svg src="menu" stroke="black"></v-svg>
      </div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 32px">{{ displayName }}</div>
      <span v-if="visual.isTouch">&nbsp;Touch</span>
    </div>
    <div class="d-flex" style="margin-right: 35px">
      <top-app></top-app>
      <unhandled-events-popover v-if="currentProject"></unhandled-events-popover>
      <div v-if="currentProject" class="top-nav-elm">
        <popup-top-nav :name="currentProject"> </popup-top-nav>
      </div>
    </div>
  </div>
</template>

<script>
import PopupTopNav from '@/components/layout/PopupTopNav.vue';
import TopApp from '@/components/layout/TopApp.vue';
import UnhandledEventsPopover from '@/components/notifications/UnhandledEventsPopover.vue';
import { menu } from '@/mixins/menu';
import { mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useTpws } from '@/stores/tpws';
import { mapStores } from 'pinia';

export default {
  components: { PopupTopNav, TopApp, UnhandledEventsPopover },
  name: 'top-nav-white',
  mixins: [menu],
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useTpws),
    currentProject() {
      return this.tpws.currentProject;
    },
    displayName() {
      return this.tpws.displayName;
    },
    zStyle(){
      return !this.visual.modalVisible ? {
        zIndex: 999
      }: {zIndex: 99}
    }
  },
  methods: {
    ...mapActions(useVisual, ['toggleSidebar']),
    clickPopup() {
      this.showPopup = true;
    },
  },
};
</script>

<style scoped>
.top-nav {
  position: relative;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.top-nav-elm__popup {
  right: auto;
}
</style>
