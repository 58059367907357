<template>
  <div id="signals-table" class="container" sticky-container v-resize:debounce="onTimerResize">
    <template v-if="byCluster">
      <div class="page-actions mt-2">
        <div class="page-actions__buttons pointer-cursor" @click="goBackToClusters">
          <div class="ui-button">
            <div class="ui-button__icon">
              <img src="/images/caret_left.svg" loading="lazy" alt="" />
            </div>
            <div class="ui-button__lable"><div>Назад</div></div>
          </div>
        </div>
      </div>

      <h1 class="event-profile__title mt-1">
        {{ $t('Event Profile for cluster', { cluster: '' }) }}
      </h1>
    </template>
    <template v-else>
      <h1 class="event-profile__title mt-5">{{ $t('Event Profile for', { date: dateNow }) }}</h1>
    </template>
    <small v-if="dateBefore && dateAfter">{{
      $t(`Showing event summary for time interval`, { intervalStart: dateBeforeShifted, intervalEnd: dateAfterShifted })
    }}</small>
    <small v-else>{{ $t(`Showing event summary for time`, { date: dateNow }) }}</small>

    <a v-if="buttonsVisible && dygraphVisible" class="event-profile__show-tabs-btn ml-2" href="#" @click="showTabs"
      >Показать вкладки</a
    >
    <a
      v-if="buttonsVisible && !dygraphVisible"
      class="event-profile__show-graphs-btn ml-2"
      href="#"
      @click="showDygraph"
      >Показать графики</a
    >
    <div class="event-profile__content container" id="event-profile__">
      <div v-show="!dygraphVisible" class="show collapse">
        <ul class="nav nav-tabs card-header-tabs mt-4">
          <li class="nav-item" @click="switchTab('signals')">
            <div
              class="signal-btn btn nav-link"
              id="signals-btn2"
              :class="{ active: currentTab === 'signals', disabled: signalsCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab^Signals') }} &nbsp;<small class="badge badge-primary">{{ signalsCount }}</small>
              </h2>
            </div>
          </li>
          <li class="nav-item" @click="switchTab('svr')">
            <div
              class="signal-btn btn nav-link"
              id="svr-btn2"
              :class="{ active: currentTab === 'svr', disabled: svrCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab^Sampled values records') }} &nbsp;<small class="badge badge-primary">{{
                  svrCount
                }}</small>
              </h2>
            </div>
          </li>
          <li class="nav-item" @click="switchTab('pcaps')">
            <div
              class="signal-btn btn nav-link"
              id="pcaps-btn2"
              :class="{ active: currentTab === 'pcaps', disabled: pcapsCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab^PCAPs') }} &nbsp;<small class="badge badge-primary">{{ pcapsCount }}</small>
              </h2>
            </div>
          </li>
        </ul>
        <br />
        <signals-meta
          v-if="prefs.preferences && signalsMetaQuery.t && signalsMetaQuery.prev && signalsMetaQuery.next"
          ref="signalsMeta"
          v-show="currentTab === 'signals'"
          :no-search="true"
          :fields="fields"
          :off-types="offTypes"
          :url="signalsUrl"
          :query="signalsMetaQuery"
          :length-menu="lengthMenu"
          @loadedRows="loadedSignals"
          @showDygraph="swDygraph"
        >
        </signals-meta>
        <svr-meta
          v-if="prefs.preferences && oscsMetaQuery.t && oscsMetaQuery.prev && oscsMetaQuery.next"
          ref="svrMeta"
          v-show="currentTab === 'svr'"
          :no-search="true"
          :fields="svrFields"
          :off-types="offTypes"
          :url="oscsUrl"
          :query="oscsMetaQuery"
          :length-menu="lengthMenu"
          :hide-bottom="true"
          @loadedRows="loadedSvr"
          @showDygraph="swDygraph"
          @open-selected="swSvr"
        >
        </svr-meta>
        <pcaps-meta
          v-if="prefs.preferences && pcapsMetaQuery.t && pcapsMetaQuery.prev && pcapsMetaQuery.next"
          ref="pcapsMeta"
          v-show="currentTab === 'pcaps'"
          :no-search="true"
          :fields="pcapsFields"
          :off-types="offTypes"
          :url="pcapsUrl"
          :query="pcapsMetaQuery"
          :hide-bottom="true"
          :length-menu="lengthMenu"
          @loadedRows="loadedPcaps"
          @showDygraph="swDygraph"
        >
        </pcaps-meta>
      </div>
      <a class="up-btn fal fa-angle-up" href="#signals" style="display: none"></a>
      <div v-show="dygraphVisible" class="row mt-4" id="signals-dygraph">
        <div
          id="timer-form"
          ref="timerForm"
          v-sticky
          sticky-offset="offset"
          sticky-side="top"
          style="padding: 5px 0 5px 50px"
        >
          <!--          <div id="timer-back"></div>-->
          <div
            id="timer-bck"
            :style="{ '--contentWidth': visual.contentWidth + 'px', '--contentLeft': '-' + contentLeft + 'px' }"
          ></div>
          <form class="form-inline">
            <div class="input-group col-md-3" id="timer-input-t1-group">
              <div class="input-group-prepend">
                <span class="input-group-text">T1</span>
              </div>
              <input class="form-control" id="timer-input-t1" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-3" id="timer-input-t2-group">
              <div class="input-group-prepend">
                <span class="input-group-text">T2</span>
              </div>
              <input class="form-control" id="timer-input-t2" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-3" id="timer-input-dt-group">
              <div class="input-group-prepend">
                <span class="input-group-text">ΔT</span>
              </div>
              <input class="form-control" id="timer-input-dt" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-1">
              <button class="btn btn-default" id="timer-reset-button" type="button" @click="resetTimer">Сброс</button>
            </div>
          </form>
        </div>
        <div v-loading="loading" id="signals-dygraph-canvas" style="position: relative; width: 100%">
          <div class="position-relative" id="graphs"></div>
          <div class="d-none mb-2" id="timestamp-type-toggle" ref="togggleTimestamp">
            <div class="timestamp-type-toggle__background"></div>
            <div class="timestamp-type-toggle__title mr-2">Тип метки времени:</div>
            <div class="btn-group btn-group-toggle">
              <span
                class="btn btn-sm btn-secondary"
                :class="{ active: eventProfile.timestampType === 'process' }"
                @click="toggleTimestampType('process')"
                >Процесс</span
              ><span
                class="btn btn-sm btn-secondary"
                :class="{ active: eventProfile.timestampType === 'network' }"
                @click="toggleTimestampType('network')"
                >Сетевая</span
              >
            </div>
          </div>
          <div v-loading="svrDrawLoading" class="position-relative" id="svr-dygraph"></div>
        </div>
      </div>
      <div class="loading" style="display: none">
        <div class="wrapper"><div class="cssload-loader"></div></div>
      </div>
    </div>

    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <h2 v-if="archive && !isNaN(archive.tstart) && !isNaN(archive.tend)" class="events-archive__title">
        {{ $t('Events log interval', { intervalStart: nanoTstart, intervalEnd: nanoTend }) }}
      </h2>
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';
import SignalsMeta from '@/components/signals/SignalsMeta.vue';
import SvrMeta from '@/components/signals/SvrMeta.vue';
import PcapsMeta from '@/components/signals/PcapsMeta.vue';

import Dygraph from '../../../../vendor/dygraph';

import api from '@/services/api';
import $ from 'jquery';

import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { useEvents } from '@/stores/events';
import { useVisual } from '@/stores/visual';
import { usePrefs } from '@/stores/prefs';
import { useFaultProfile } from '@/stores/faultProfile';
import { useEventProfile } from '@/stores/eventProfile';
import { useDygraphTpui } from '@/stores/dygraphTpui';
import { mapActions } from 'pinia/dist/pinia';
import { markRaw, shallowRef, ref } from 'vue/dist/vue.esm-bundler';
import { ElMessage } from 'element-plus';
import eventBus from '@/utils/eventBus';

let sync = undefined;

export default {
  name: 'signals-page',
  components: { SignalsMeta, SvrMeta, PcapsMeta },
  props: {
    page: { type: Number, default: 1 },
    archive: Boolean,
    eventsSearchStr: String,
  },
  data: () => {
    return {
      quickSearch: '',
      onTypes: [],
      offTypes: [],
      timelinesArray: [],
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      // tpui: undefined,
      signalsCount: 0,
      svrDrawLoading: false,
      contentLeft: 0,
      svrCount: 0,
      svrCache: {},
      pcapsCount: 0,
      currentTab: 'signals',
      dygraphVisible: false,
      buttonsVisible: false,
      errors: [],
      prevRoute: null,
    };
  },
  computed: {
    ...mapStores(useTpws),
    ...mapStores(useEvents),
    ...mapStores(useVisual),
    ...mapStores(usePrefs),
    ...mapStores(useFaultProfile),
    ...mapStores(useEventProfile),
    ...mapStores(useDygraphTpui),
    currentProject() {
      return this.tpws.currentProject;
    },
    offset() {
      return this.prefs.preferences?.timezoneOffset;
    },
    dateNow() {
      if (this.byCluster) {
        if (this?.currentCluster?.time) {
          return new DateNano(this.currentCluster.t)
            .setTimezoneOffset(this.offset)
            .customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#');
        } else {
          return '';
        }
      } else {
        try {
          return new DateNano(this.$route.query.time)
            .setTimezoneOffset(this.offset)
            .customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#');
        } catch (e) {
          return '';
        }
      }
    },
    byCluster() {
      return !!this.clusterId;
    },
    clusterId() {
      return this.$route.query?.cluster;
    },
    currentCluster() {
      if (this.clusterId) {
        return this.events?.clusters[this.clusterId];
      }
    },
    dateBeforeRaw() {
      if (this.byCluster) {
        return this.currentCluster && Number(this.currentCluster.time) - Number(this.currentCluster.prev || 0);
      } else {
        try {
          return Number(this.$route.query.time) - Number(this.$route.query.timeBefore || 0);
        } catch (e) {}
      }
    },
    dateAfterRaw() {
      if (this.byCluster) {
        return this.currentCluster && Number(this.currentCluster.time) + Number(this.currentCluster.next || 0);
      } else {
        try {
          return Number(this.$route.query.time) + Number(this.$route.query.timeAfter || 0);
        } catch (e) {}
      }
    },
    dateBefore() {
      return this.formatDate(this.dateBeforeRaw);
    },
    dateAfter() {
      return this.formatDate(this.dateAfterRaw);
    },
    dateBeforeRawShifted() {
      return this.dateBeforeRaw - this.prefs.timeBefore;
    },
    dateAfterRawShifted() {
      return this.dateAfterRaw + this.prefs.timeAfter;
    },
    dateBeforeShifted() {
      return this.formatDate(this.dateBeforeRaw - this.prefs.timeBefore);
    },
    dateAfterShifted() {
      return this.formatDate(this.dateAfterRaw + this.prefs.timeAfter);
    },
    graphDataWindowStart() {
      return this.syncDataWindow?.length > 1 ? this.syncDataWindow[0] || this.fineTimestamp - this.fineTimeBefore : 0;
    },
    graphDataWindowEnd() {
      return this.syncDataWindow?.length > 1 ? this.syncDataWindow[1] || this.fineTimestamp + this.fineTimeAfter : 0;
    },
    syncDataWindow() {
      if (this.timelinesFirst) {
        return this.timelinesFirst;
      } else {
        return [undefined, undefined];
      }
    },
    timelinesFirst() {
      return this.timelinesArray.length > 0
        ? this.timelinesArray?.find((e) => e?.dateWindow_ !== null && e?.dateWindow_[0] && e?.dateWindow_[1])
            ?.dateWindow_
        : [undefined, undefined];
    },

    fineTimestamp() {
      if (this.byCluster) {
        return Number(this.currentCluster?.time);
      } else {
        return Number(parseInt(this.$route?.query?.time));
      }
      return 0;
    },
    fineTimeBefore() {
      return Number(parseInt(this.signalsTimeBefore));
    },
    fineTimeAfter() {
      return Number(parseInt(this.signalsTimeAfter));
    },
    signalsTimeBefore() {
      let routeTimeBefore = Number(this.$route.query.timeBefore || 0);
      const projectTimeBefore = this.prefs.preferences?.timeBefore;
      const generalTimeBefore = this.prefs.generalPreferences?.timeBefore;
      if (!routeTimeBefore || isNaN(routeTimeBefore)) {
        routeTimeBefore = 0;
      }
      dev.log(
        'signalsTimeBefore',
        routeTimeBefore,
        projectTimeBefore,
        Number(generalTimeBefore),
        Number(routeTimeBefore),
        Number(projectTimeBefore),
        this.faultProfile.tbefore
      );
      if (Number(projectTimeBefore) > 0) {
        return Number(routeTimeBefore) + Number(projectTimeBefore);
      } else if (Number(generalTimeBefore) > 0) {
        return Number(routeTimeBefore) + Number(generalTimeBefore);
      }
      return this.faultProfile.tbefore * 1e6;
    },
    signalsTimeAfter() {
      let routeTimeAfter = Number(this.$route.query.timeAfter || 0);
      const projectTimeAfter = this.prefs.preferences?.timeAfter;
      const generalTimeAfter = this.prefs.generalPreferences?.timeAfter;
      if (!routeTimeAfter || isNaN(routeTimeAfter)) {
        routeTimeAfter = 0;
      }
      dev.log(
        'signalsTimeAfter',
        routeTimeAfter,
        projectTimeAfter,
        Number(generalTimeAfter),
        Number(routeTimeAfter),
        Number(projectTimeAfter),
        this.faultProfile?.tafter
      );
      if (Number(projectTimeAfter) > 0) {
        return Number(routeTimeAfter) + Number(projectTimeAfter);
      } else if (Number(generalTimeAfter) > 0) {
        return Number(routeTimeAfter) + Number(generalTimeAfter);
      }
      return this.faultProfile?.tafter * 1e6;
    },
    commonQuery() {
      const { timeStartNano, timeEndNano } = this.timeBeforeAfter();
      const timeBefore = this.fineTimestamp - timeStartNano;
      const timeAfter = timeEndNano - this.fineTimestamp;
      return {
        t: this.fineTimestamp,
        prev: timeBefore,
        next: timeAfter,
      };
    },
    signalsMetaQuery() {
      return {
        ...this.commonQuery,
      };
    },
    oscsMetaQuery() {
      return {
        ...this.commonQuery,
      };
    },
    pcapsMetaQuery() {
      return {
        ...this.commonQuery,
      };
    },
    signalsUrl() {
      return `/projects/${this.currentProject}/getsignalsmeta`;
    },
    pcapsUrl() {
      return `/projects/${this.currentProject}/getpcapsmeta`;
    },
    oscsUrl() {
      return `/projects/${this.currentProject}/oscsmeta`;
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$t('Goose') },
        { name: 'SV', label: this.$t('SV') },
        { name: 'SRV', label: this.$t('Service events') },
        { name: 'ARP', label: this.$t('ARP') },
        { name: 'MMS', label: this.$t('MMS') },
        { name: 'USER', label: this.$t('User events') },
      ];
    },
    _eq() {
      return {
        GO: this.$t('Goose'),
        SV: this.$t('SV'),
        SRV: this.$t('Service events'),
        ARP: this.$t('ARP'),
        MMS: this.$t('MMS'),
        USER: this.$t('User events'),
      };
    },

    __eq() {
      let eq = {};
      for (let t in this._eq) {
        eq[this._eq[t]] = t;
      }
      return eq;
    },
    headerVisible() {
      return this.archive && !isNaN(this.archive.tstart) && !isNaN(this.archive.tend);
    },

    descriptionType() {
      return this.prefs.preferences?.descriptionType;
    },

    fields() {
      return [
        {
          label: this.$t('signals-columns^IED Name'),
          name: 'iedName',
          data: 'iedName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: true,
        },
        {
          label: this.$t('signals-columns^IED description'),
          name: 'iedDesc',
          data: 'iedDesc',
          visible: this.descriptionType !== 'onlyName',
          sortable: true,
        },
        {
          label: this.$t('signals-columns^Signal'),
          name: 'signalName',
          data: 'signalName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: true,
        },
        {
          label: this.$t('signals-columns^Signal description'),
          name: 'desc',
          data: 'desc',
          visible: this.descriptionType !== 'onlyName',
          render: (d, t, row) => `<span data-name="${row.signalName}">${d.replace(/\|+(.*)/g, '$1')}</span>`,

          sortable: true,
        },
        {
          label: this.$t('signals-columns^Control Block Name'),
          name: 'gooseCB',
          data: 'gooseCB',
          sortable: true,
        },
        {
          label: this.$t('signals-columns^Simulation'),
          name: 'simulation',
          data: 'simulation',
          //render: (_) => `<b class="fa-${_ ? 'exclamation-' : ''}circle fal text-${_ ? 'warning' : 'success'}"></b>`,
          render: (_) =>
            `<b class="fa-${!!_ ? 'exclamation-' : ''}circle fal text-${!!_ ? 'warning' : 'success'}"></b>`,

          sortable: true,
        },
      ];
    },
    svrFields() {
      return [
        {
          label: this.$t('svr-columns^LVB'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: true,
        },
        {
          label: this.$t('svr-columns^SVID'),
          name: 'svId',
          data: 'svId',
          sortable: true,
        },
        {
          label: this.$t('svr-columns^Destination MAC'),
          name: 'dstMac',
          data: 'dstMac',
          sortable: true,
        },
        {
          label: this.$t('svr-columns^APPID'),
          name: 'appId',
          data: 'appId',
          sortable: true,
        },
        {
          label: this.$t('svr-columns^Simulation'),
          name: 'simulation',
          data: 'simulation',
          //render: (_) => `<b class="fa-${_ ? 'exclamation-' : ''}circle fal text-${_ ? 'warning' : 'success'}"></b>`,
          render: (_) =>
            `<b class="fa-${!!_ ? 'exclamation-' : ''}circle fal text-${!!_ ? 'warning' : 'success'}"></b>`,
          sortable: true,
        },
        {
          label: this.$t('svr-columns^Record Start'),
          name: 'start',
          data: 'start',
          render: (d) => {
            // console.log('d', d);
            let ts = new DateNano(d.sec * 1e6 + Math.round(d.nano / 1e3));

            return ts.customFormat('#hhhh#:#mm#:#ss# #DD#.#MM#.#YYYY#');
          },
          sortable: true,
        },
        {
          label: this.$t('svr-columns^Record Duration'),
          name: 'duration',
          render: (d, t, row) => {
            let recordDuration = 0;
            if (row.stop.nano > row.start.nano)
              recordDuration =
                `${row.stop.sec - row.start.sec}.` + `${row.stop.nano - row.start.nano}`.padStart(9, '0');
            else
              recordDuration =
                `${row.stop.sec - row.start.sec - 1}.` + `${row.stop.nano - row.start.nano + 1e9}`.padStart(9, '0');
            return recordDuration;
          },
          sortable: true,
        },
      ];
    },
    pcapsFields() {
      return [
        {
          label: this.$t('pcaps-columns^LVB name'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: true,
        },
        {
          label: this.$t('pcaps-columns^pcap frames'),
          name: 'count',
          data: 'count',
          sortable: true,
        },
        {
          label: this.$t('pcaps-columns^download'),
          name: 'link',
          data: '_id',
          action: 'downloadPcap',
          render: (d, t, row) => {
            if (parseInt(row.count) > 5e5) {
              return `<a
                                    title="${this.$t('No more than 500000 frames')}"
                                    class="btn btn-outline-dark btn-sm fa-ban fal"

                                </a>`;
            } else {
              return `<a class="btn btn-outline-dark btn-sm fa-download fal"  >
                                </a>`;
            }
          },

          sortable: false,
        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.archive.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.archive.tend).customFormat();
    },
  },

  mounted() {
    this.$nextTick(() => {});
    this.onTypes = this.eventTypes.map((e) => e.name);
    this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
    this.initDygraphTpui();
    Dygraph.synchronize = this.dygraphTpui.synchronize;

    this.$router.isReady().then(() => {
      if (this.$socket.readyState) {
        this.getClusterBounds();
      } else {
        eventBus.on('socket.ready', (options) => {
          this.getClusterBounds();
        });
      }
      this.timelinesArray = [];
      // dev.log('this.$socket', this.$socket);
    });
  },
  beforeRouteEnter(to, from, next) {
    dev.log('beforeRouteEnter');
    next((vm) => {
      vm.prevRoute = from;
    });
  },

  methods: {
    ...mapActions(useDygraphTpui, ['initDygraphTpui']),
    formatDate(value) {
      return (
        (value &&
          new DateNano(value)
            .setTimezoneOffset(this.offset)
            .customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#')) ||
        ''
      );
    },
    timeBeforeAfter(start, end) {
      const timeStartSec = Math.floor(this.dateBeforeRawShifted / 1e6);
      const timeEndSec = Math.ceil(this.dateAfterRawShifted / 1e6);
      return {
        timeStartSec,
        timeEndSec,
        timeStartNano: this.dateBeforeRawShifted,
        timeEndNano: this.dateAfterRawShifted,
      };
    },
    resetTimer() {
      this.faultProfile.reset();
    },
    onTimerResize(e) {
      const box = this.$refs?.timerForm.getBoundingClientRect();
      dev.log('resize', box);
      this.contentLeft = box.left - this.visual.contentLeft;
      // this.root.style.setProperty("--contentLeft", box.left);
    },
    toggleTimestampType(val) {
      this.eventProfile.timestampType = val;
      // $('.svr-meta-button').removeClass('disabled').click()
    },

    getClusterBounds() {
      this.$socket.send(
        JSON.stringify({
          type: 'get-cluster-times',
          content: {
            cluster: this.clusterId,
          },
        })
      );
    },
    goBackToClusters() {
      const lastRoute = localStorage.getItem('lastRoute');
      dev.log('this.$router', this.$router);
      if (lastRoute === 'clusters-index') {
        dev.log('lastRoute', lastRoute);
        this.$router.push({ name: 'clusters-index', query: { cluster: this.$route.query.cluster } });
      } else {
        this.$router.push({ name: 'clusters-index' });
      }
    },
    swDygraph(e) {
      this.showDygraph(e);
    },
    showDygraph() {
      this.buttonsVisible = true;
      this.dygraphVisible = true;
      this.getSignals();
    },
    swSvr(e) {
      dev.log('swSvr');
      this.showSvr(e);
    },
    showSvr() {
      this.buttonsVisible = true;
      this.dygraphVisible = true;
      dev.log('showSvr');
      this.getSvr();
    },
    showTabs() {
      this.buttonsVisible = true;
      this.dygraphVisible = false;
    },
    dygraphData() {
      return this.$refs.signalsMeta.rowsDataSelected.map((e) => e.id);
    },
    getSignals() {
      let signals = this.dygraphData();
      if (!signals.length) {
        return;
      }
      dev.log('signals', signals);
      let commonPart = ((signals) => {
        let common = '';
        let flag = true;
        let i = 0;
        while (flag) {
          common = common + signals[0][i];
          signals.forEach((s) => (s.indexOf(common) != 0 ? (flag = false) : 0));
          i++;
          if (i >= signals[0].length) break;
        }
        return common.substring(0, common.length - 1);
      })(signals);
      let dataUrl = `/projects/${this.currentProject}/getsignals`;
      let dataParams = {
        ...this.signalsMetaQuery,
        common: commonPart,
        signals: signals.map((s) => s.substring(commonPart.length)),
      };
      api
        .post(dataUrl, dataParams)
        .then((result) => {
          // JSON responses are automatically parsed.
          let res = result.data.desc;
          dev.log(res); //result.data.desc;
          const { timeStartNano, timeEndNano } = this.timeBeforeAfter();
          res.tstart = timeStartNano;
          res.tend = timeEndNano;
          this._displayDygraph1(res);
          // this.$emit('loadedRows', this.rowsData.length);
          // this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally(()=>{
        this.loading = false;
      });
    },
    svrRedraw() {
      dev.log('redraw');
      let graphDiv = document.getElementById('svr-dygraph');
      graphDiv.innerHTML = '';
      for (const [index, dat] of Object.entries(this.svrCache)) {
        dev.log(`${index}: `, dat);
        const meta = dat?.desc?.meta;
        let key = `${meta?.appId}-${meta?.dstMac}-${meta?.iedName}-${meta?.lvbId}-${meta?.simulation}-${meta?.svId}-${index}`;
        this.svrDraw(
          { ...dat, idx: key },
          'signals-dygraph-canvas',
          this.fineTimestamp,
          this.fineTimeBefore,
          this.fineTimeAfter
        );
      }
    },
    svrDraw(recordDataReact, blockId, timestamp, timeBefore, timeAfter) {
      // if (!this.timelinesArray) {
      //   this.timelinesArray = [];
      // }
      this.svrDrawLoading = true;
      const recordData = markRaw(recordDataReact);
      dev.log('recordData', recordData);
      // var graphDivClean = document.getElementById('svr-dygraph');
      // graphDivClean.innerHTML = "";

      let meta = { ...recordData.desc.meta, ...recordData.desc.meta.metadata },
        // data = JSON.parse(recordData.desc.data)
        data = recordData.desc.data,
        DATASET = meta.DATASET,
        labels = ['t'],
        labelsDesc = ['t'],
        quality = [];
      for (let d in DATASET)
        if (DATASET[d].p) {
          labels.push(d);
          labelsDesc.push(
            this.prefs.descriptionType == 'onlyDesc' && typeof DATASET[d].desc == 'string'
              ? DATASET[d].desc.replace(/\|+(.*)/g, '$1')
              : d
          );
        }

      if (labels.length != 9) {
        eventBus.dispatch('notification', {
          message: this.$t(
            "Tekvel Park now supports  9-2LE compatible dataset only with 8 analog values with\n quality flags included. SV configuration is incompatible with 9-2LE and can't be displayed."
          ),
          type: 'error',
        });
        // return ;
      }

      let base64ToHex = (str) =>
        atob(str.replace(/[ \r\n]+$/, ''))
          .split('')
          .reduce(
            (a, b) => (a += (b.charCodeAt(0).toString(16).length === 1 ? '0' : '') + b.charCodeAt(0).toString(16)),
            ''
          );

      let _dy = [],
        // FIXME временный костыль для посчета smpRate. По хорошему его стоит брать из scd
        smpRate = data.reduce((a, p) => (a = p.smpCnt > a ? p.smpCnt : a), 0) + 1,
        nowSecond = data.find((p) => p.smpCnt == smpRate - 1).lvb_ts.sec - 1,
        prevSmpCount = data[0].smpCnt - 1,
        prevSec = data[0].lvb_ts.sec - 1;
      dev.log(smpRate);

      for (let p of data) {
        let _p = [], //это координаты по оси У для графиков сигналов
          _pq = [], //это координаты по оси У для графиков качества сигналов
          _ts = +(`${p.lvb_ts.sec}.` + `${p.lvb_ts.nano}`.padStart(9, '0'));
        if (this.eventProfile.timestampType === 'process') {
          if (p.smpCnt === 0) {
            nowSecond = p.lvb_ts.sec; // - 1
            _ts = nowSecond;
          } else {
            if (Math.abs(nowSecond - p.lvb_ts.sec) > 1) nowSecond = p.lvb_ts.sec - 1;
            _ts = nowSecond + p.smpCnt / smpRate;
          }
        }

        prevSmpCount = p.smpCnt == smpRate - 1 ? -1 : p.smpCnt;
        prevSec = _ts;

        _pq.push(_ts * 1e6);
        p.datasetH = base64ToHex(p.dataset);
        let _dataset = p.datasetH.match(/.{8}/g);

        let i = 0;
        for (let d in DATASET) {
          if (DATASET[d].p) {
            if (_dataset[i][0] == 'f') {
              _p.push(
                /*Math.round*/ -+(
                  '0x' +
                  _dataset[i]
                    .split('')
                    .map((n) => (0xf - `0x${n}`).toString(16))
                    .join('')
                ) * DATASET[d].k
              );
            }
            // _p.push(parseInt('0x'+_dataset[i]))
            else _p.push(/*Math.round*/ +('0x' + _dataset[i]) * DATASET[d].k);
          } else {
            // console.log('_q');
            let _q;
            if (_dataset[i][0] == 'f')
              _q = /*Math.round*/ -+(
                '0x' +
                _dataset[i]
                  .split('')
                  .map((n) => (0xf - `0x${n}`).toString(16))
                  .join('')
              );
            else _q = /*Math.round*/ +('0x' + _dataset[i]);
            if (_q !== 0) quality.push({ p: _p[0], t: 1, s: (0.5 / smpRate) * 1e6 + 10 });
            if (_dataset[i][_dataset[i].length - 1] == '1') {
              _pq.push(_p[_p.length - 1]);
            } else {
              _pq.push(null);
            }
          }
          i++;
        }
        _p = [..._pq, ..._p]; //графики качества должны рисоваться под графиками сигналов, чтобы не перекрывать их. Поэтому в массив кладем сначала координаты для графиков качества, а потом координаты для графиков сигналов.
        // dev.log('_p', _p)
        _dy.push(_p);
      }

      //let graphDiv = $('<div/>').appendTo($('#${blockId} .svr-dygraph')).get(0); // document.getElementById("svr-dygraph")
      // let svrDygraph = document.getElementById('svr-dygraph');
      let graphDiv = document.getElementById('svr-dygraph');
      // let graphDiv = document.createElement('div');
      // svrDygraph.append(graphDiv)
      // let graphDiv = $('<div/>').appendTo($(`#${blockId} .svr-dygraph`)).get(0);
      let graphWrap = document.createElement('div');
      let graphContainer = document.createElement('div');
      graphDiv.appendChild(graphWrap);

      graphWrap.style.height = '250px';
      graphWrap.style.display = 'flex';
      graphWrap.style.width = '100%';
      let graphId =
        recordData.desc.meta.lvbId +
        recordData.desc.meta.svId +
        recordData.desc.meta.dstMac +
        recordData.desc.meta.appId +
        '-' +
        recordData.idx +
        '-' +
        recordData.desc.meta.simulation;
      graphWrap.dataset.rowId = graphId;
      graphWrap.className = 'osc-graph';
      graphContainer.className = 'wh-graph-signal-graph';

      let signalColors = ['#e6e614', 'green', 'red', '#68ade4', '#e6e614', 'green', 'red', '#68ade4'];
      let qualityColors = new Array(8).fill('#ffbcad');
      let allColors = [...qualityColors, ...signalColors];

      let signalLabels = labels;
      let qualityLabels = new Array(8).fill('quality');
      let allLabels = [signalLabels[0], ...qualityLabels, ...signalLabels.slice(1)];
      // _dy.shift()

      // создаем пустые точки начала и конца периода
      const emptyStartPoint = new Array(_dy[0].length - 1).fill(null);
      emptyStartPoint.unshift(this.dateBeforeRawShifted);
      const emptyEndPoint = new Array(_dy[0].length - 1).fill(null);
      emptyEndPoint.unshift(this.dateAfterRawShifted);
      const raw_dy = markRaw(_dy);
      const foundStartPoint = raw_dy.find((el) => el[0] === this.dateBeforeRawShifted);
      // если в осцилограмме нет точки соответствующей началу периода, добавляем пустую точку
      if (!foundStartPoint) {
        raw_dy.unshift(emptyStartPoint);
      }
      const foundEndPoint = raw_dy.find((el) => el[0] === this.dateAfterRawShifted);
      // если в осцилограмме нет точки соответствующей концу периода, добавляем пустую точку
      if (!foundEndPoint) {
        raw_dy.push(emptyEndPoint);
      }
      let valueRange = (raw_dy.map((_) => [..._].sort((a, b) => b - a)[1]).sort((a, b) => b - a)[0] + 1e3) * 1.5;
      // valueRange += valueRange*.5 +  1e3

      graphContainer.style.width = 'calc(100% - 170px)';
      graphWrap.appendChild(graphContainer);
      dev.log('draw window', timestamp - timeBefore, `(${this.graphDataWindowStart})`, timestamp + timeAfter),
        `(${this.graphDataWindowEnd})`;
      dev.log('draw range', valueRange);
      let srvGraph = new Dygraph(graphContainer, raw_dy, {
        idx: recordData.idx,
        labels: allLabels,
        colors: allColors,
        dateWindow: [this.graphDataWindowStart, this.graphDataWindowEnd], //[_dy[0][0], _dy[_dy.length-1][0]],
        valueRange: [-valueRange, valueRange],
        series: {
          quality: {
            strokeWidth: 8,
          },
        },
        plotter: this.dygraphTpui.smoothPlotter,
        // labelsSeparateLines: true,
        legend: 'never',
        hideOverlayOnMouseOut: true,
        highlightCircleSize: 5,
        strokeWidth: 1.1,
        strokeBorderWidth: 0.2,
        pointClickCallback: this.faultProfile.pointClick,
        highlightCallback: (event, x, points) => {
          //функция создает подписи со значениями для точек вместо встроенной легенды
          //let graph = $(event.currentTarget).parents('.osc-graph');
          // let graph = $(event.currentTarget).parents('.osc-graph');
          let graph = $(event.currentTarget).parents('.osc-graph');
          let time = new DateNano(x)
            .setTimezoneOffset(this.prefs.timezoneOffset)
            .customFormat('#hhhh#:#mm#:#ss#.#ms##us#');
          graph.find('.legend-sv-time-label').html(time);
          function valueFormatter(_val, seriesName) {
            //функция переводит значения по оси У в человекочитаемый вид и добавляет единицы измерения
            let num = Math.abs(Math.round(_val * 1000)),
              _d = ['мк', '', ' к', ' М', ' Г', ' Т', ' П'],
              r =
                (_val < 0 ? '-' : '') +
                +`${num}`
                  .padStart(18, '0')
                  .match(/.{3}/g)
                  .reduce((a, n) => (a = n != '000' && a == '' ? n : a.length == 3 ? a + '.' + n : a), '') +
                (num !== 0 ? _d[Math.floor((`${num}`.length - 1) / 3)] : '') +
                (~seriesName.indexOf('Amp') ? 'А' : 'В');
            return r;
          }
          for (let point of points) {
            let label = point.name;
            let value = valueFormatter(point.yval, label);
            graph.find(`span[data-target="${label}"]`).html(!isNaN(parseFloat(value)) ? value : '');
          }
        },
        unhighlightCallback: () => {
          $('.osc-value').html('');
          $('.legend-sv-time-label').html('');
        },
        axes: {
          x: {
            drawAxis: true,
            ticker: this.dygraphTpui.dateTicker,
            axisLabelFormatter: this.dygraphTpui.dateAxisLabelFormatter,
          },
          y: {
            axisLabelFormatter: (y) => '',
          },
        },
        showLabelsOnHighlight: true,
        animatedZooms: true,
      });

      this.timelinesArray = this.timelinesArray.filter((el) => {
        dev.log('comp', el.user_attrs_.idx, srvGraph.user_attrs_.idx);
        return el.user_attrs_.idx !== srvGraph.user_attrs_.idx;
      });
      dev.log('this.timelinesArray', this.timelinesArray.length);
      this.timelinesArray.push(markRaw(srvGraph));

      if (this.timelinesArray.length > 1) {
        try {
          if (sync) {
            sync.detach();
          }
          sync = Dygraph.synchronize(this.timelinesArray, {
            selection: false,
            zoom: true,
            range: false,
          });
        } catch (e) {
          console.error('sync err', e);
        }
      }

      dev.log('tpui.timelinesArray svr', this.timelinesArray);
      dev.log('sync', sync);

      let _iedHtml = `
                    <li class="legend-ied-name-li">
                        <div class="legend-ied-name" title="${meta.iedName}">
                            ${meta.iedName}
                        </div>
                    </li>`,
        _signalHtml = `
                    <li class="legend-data-object-name-li">
                        <div class="legend-data-object-name">
                        <span ></span>
                            <div id="label-container__${graphId}" class="legend-sv-signal-labels">
                                ${labelsDesc
                                  .map((l, i) =>
                                    i
                                      ? '<div class="osc-label-container"><span class="osc-label" style="color:' +
                                        signalColors[i - 1] +
                                        ';cursor:pointer;" svDatasetSignalIdx="' +
                                        i +
                                        '" title="' +
                                        l +
                                        '">' +
                                        l +
                                        '</span><span class="osc-value" data-target="' +
                                        labels[i] +
                                        '"></span ></div>'
                                      : ''
                                  )
                                  .join('')}
                                <div class="legend-sv-time-label"></div>
                            </div>
                        </div>
                    </li>`;
      if (this.prefs.descriptionType == 'onlyDesc') {
        _iedHtml = '';
      }
      if (this.prefs.descriptionType != 'onlyName') {
        _iedHtml += `
                    <li class="legend-ied-name-li">
                        <span>
                            ${meta.iedDesc ? meta.iedDesc : ''}
                        </span>
                    </li>`;
      }
      let yLabel = $(`
                <div class="wh-graph-signal-name">
                    <ul>
                        ${_iedHtml}
                        <li class="legend-control-block-name-li">
                            <span class="legend-control-block-name ${meta.simulation ? 'simulated' : ''}" title="${
        meta.svId
      }">
                                ${meta.svId}
                            </span>
                        </li>
                        ${_signalHtml}
                    </ul>
                </div>`);

      graphWrap.insertBefore(yLabel.get(0), graphWrap.firstChild);

      // console.log('$(yLabel)', $(yLabel))
      const jQyLabel = $(yLabel);
      jQyLabel.prepend(
        `<button class="remove-btn close" data-event="event" @click="eventProfile.removeGraph(event)" title="${this.$t(
          'Hide the graph'
        )}"><span aria-hidden="true">&times;</span></button>`
      );
      jQyLabel.find('.remove-btn').on('click', (label) => {
        dev.log('ev removeGraph', label);
        this.eventProfile.removeGraph(event);
      });
      $(`#label-container__${graphId} span`).on('click', (label) => {
        $(label.currentTarget).toggleClass('osc-label');
        srvGraph.setVisibility(
          +$(label.currentTarget).attr('svDatasetSignalIdx') + 7,
          $(label.currentTarget).hasClass('osc-label')
        );
        srvGraph.setVisibility(
          $(label.currentTarget).attr('svDatasetSignalIdx') - 1,
          $(label.currentTarget).hasClass('osc-label')
        );
      });

      this.svrDrawLoading = false;

      return 0;
    },
    _displayDygraph1(signalsData) {
      dev.log('_displayDygraph1', signalsData);

      // this.timelinesArray = [];

      // $(".jumbotron").hide();

      /**
       * This function parses signal name against predifined regex and
       * @param {any} s matching the following regex: /(?:.*):(\w*).(\w*)\$(\w*)\/(\w*)\$(.*)/
       * @returns {{"goId": parsed[1], "iedName": parsed[2], "ld": parsed[3], "ln": parsed[4], "do": parsed[5]}}
       */

      function parseSignalName(s) {
        const regex = /(?:.*):(\w*).(\w*)\$(\w*)\/(\w*)\$(.*)/;
        const regex0 = /(?:.*):(\w*).(\w*)\$(\w*)\/(.*)/;
        var parsed = s.match(regex);
        var parsed0 = s.match(regex0);
        if (parsed) {
          return {
            goId: parsed[1],
            iedName: parsed[2],
            ld: parsed[3],
            ln: parsed[4],
            do: parsed[5],
          };
        } else if (parsed0) {
          return {
            goId: parsed0[1],
            iedName: parsed0[2],
            ld: parsed0[3],
            ln: parsed0[4],
            do: undefined,
          };
        } else {
          console.error("Can't parse signal name", s);
          return {};
        }
      }

      /**
       * Formats boolean value. If arr[x] is not empty< returns arr[x], else returns ""
       * @param {any} x
       * @param {any} arr
       */

      function boleanYFormatter(x, arr) {
        if (!arr) return null;
        return arr[x] ? arr[x] : '';
      }

      var legend = {
        DPC: {
          valueRange: [-1, 4], //[0,2],
          y: null, //, 'Failure']
          yticker: null,
          name: 'Double Point Controllable',
          stepPlot: true,
        },
        DPS: {
          valueRange: [-1, 4], //[0,2],
          y: null, //, 'Failure']
          yticker: null,
          name: 'Double Point Status',
          stepPlot: true,
        },
        SPC: {
          valueRange: [-1, 2], //[0,1],
          y: null,
          yticker: null,
          name: 'Single Point Controllable',
          stepPlot: true,
        },
        SPS: {
          valueRange: [-1, 2], //[0,1],
          y: null,
          yticker: null,
          name: 'Single Point Status',
          stepPlot: true,
        },
        ACD: {
          valueRange: [5, 9], //[0,1],
          yticker: function (min, max, pixels, opts, dygraph, vals) {
            return [
              {
                v: 1,
                label: 'neut',
              },
              {
                v: 2,
                label: 'phsC',
              },
              {
                v: 3,
                label: 'phsB',
              },
              {
                v: 4,
                label: 'phsA',
              },
              {
                v: 7,
                label: 'general',
              },
            ];
          },
          height: '150px',
          name: 'ACD',
          stepPlot: true,
        },
        ACT: {
          valueRange: [-1, 2], //[0,1],
          y: null,
          yticker: null,
          name: 'ACT',
          stepPlot: true,
        },
        MV: {
          valueRange: null,
          y: null,
          yticker: null,
          name: 'Measured Value',
          height: '150px',
          stepPlot: false,
        },
      };
      var sync;

      $('#graphs').empty();
      dev.log("$('#graphs')", $('#graphs'));
      var graphsDiv = document.getElementById('graphs');

      var signals = signalsData.signals,
        tstart = signalsData.tstart,
        tend = signalsData.tend;

      this.faultProfile.tstart = tstart;
      this.faultProfile.tend = tend;
      // window.dispatchEvent(new Event('tpui'));

      // Добавляем фейковый сигнал в массив, чтобы превратить его в шкалу времени
      signals.push({ ...signals[0], signal_id: signals[0].signal_id + '-fake' });
      let lastSignal = signals[0];
      // For each signal from JSON with it's signalNumber

      dev.log('signals', signals);
      signals.forEach((signal, signalNumber) => {
        // Если элемент последний, то есть фейковый, уводим его в другую ветку
        dev.log('signals.forEach', signal, signalNumber);
        let key = `${signal.signal_id}-${signal.name}`;

        if (signalNumber == signals.length - 1) {
          dev.log('signalNumber', signalNumber);
          let g,
            data,
            cdc = 'SPS', //lastSignal.cdc,
            diagramType,
            graphDiv = document.createElement('div'),
            graphContainer = document.createElement('div'),
            yLabel = document.createElement('div'),
            // signalName = parseSignalName(lastSignal.name),
            simulated = ''; // (lastSignal.simulation === 1) ? " simulated" : ""
          data = this.dygraphTpui.splitData(lastSignal.points, cdc);

          yLabel.className = 'wh-graph-signal-name';

          graphDiv.id = 'div_' + signalNumber;
          graphDiv.className = 'timeline';
          graphDiv.style.width = '100%';
          graphDiv.style.height = legend[cdc].height ? legend[cdc].height : '15px';
          graphDiv.style.position = 'sticky';
          graphDiv.style.bottom = '10px';
          graphDiv.style.top = '10px';
          graphDiv.style.display = 'flex';
          graphDiv.style.paddingBottom = '20px';
          graphDiv.style.zIndex = '1';

          graphsDiv.appendChild(graphDiv);

          graphContainer.style.width = 'calc(100% - 170px)';
          graphContainer.style.height = '100%';
          graphContainer.style.marginTop = '20px';

          graphDiv.appendChild(graphContainer);

          g = new Dygraph(graphContainer, data.arr, {
            idx: key,
            drawPoints: false,
            legend: 'never',
            labels: data.labels,
            _states: data.states,
            dateWindow: [this.graphDataWindowStart, this.graphDataWindowEnd],
            series: data.series != null ? data.series : {},
            quality: data.quality,
            stepPlot: legend[cdc].stepPlot,
            pointClickCallback: false,
            axes: {
              y: {
                drawAxis: true,
              },
              x: {
                drawAxis: true, //signals.length < 2,
                valueRange: [tstart, tend],
                ticker: this.dygraphTpui.dateTicker,
                axisLabelFormatter: this.dygraphTpui.dateAxisLabelFormatter,
              },
            },
            showLabelsOnHighlight: true,
            animatedZooms: true,
          });

          graphDiv.insertBefore(yLabel, graphDiv.firstChild);

          dev.log('g', g);

          this.timelinesArray = this.timelinesArray.filter((el) => {
            dev.log('comp', el.user_attrs_.idx, g.user_attrs_.idx);
            return el.user_attrs_.idx !== g.user_attrs_.idx;
          });

          this.timelinesArray.push(markRaw(g));
          dev.log('this.timelinesArray', this.timelinesArray);
          return 0;
        }

        // TODO: Looks like this never used
        signal.points[0].type = 'boolean';

        dev.log('signal.points[0].type', signal.points[0].type);

        var g,
          data,
          cdc,
          diagramType,
          graphDiv = document.createElement('div'),
          graphContainer = document.createElement('div'),
          yLabel = document.createElement('div'),
          signalName = parseSignalName(signal.name),
          simulated = signal.simulation === 1 ? ' simulated' : '';

        // Determine signal CDC
        cdc = signal.cdc;
        // if (['ENS'].includes(cdc)) {
        //   return;
        // }

        dev.log('cdc', cdc);

        let cdcIsSupported = this.dygraphTpui._supportedCdcs.includes(cdc);

        //console.log(Dygraph._supportedCdcs, cdcIsSupported, cdc)

        dev.log('cdcIsSupported', cdcIsSupported);

        if (cdcIsSupported) {
          data = this.dygraphTpui.splitData(signal.points, cdc);
          dev.log('data', data);
          yLabel.className = 'wh-graph-signal-name';
          let signalObjectName = `${signalName.ld}/${signalName.ln}/${signalName.do}(${cdc})`;
          let _iedHtml = `
                    <li class="legend-ied-name-li">
                        <div class="legend-ied-name" title="${signalName.iedName}">
                            ${signalName.iedName}
                        </div>
                    </li>`,
            _signalHtml = `
                    <li class="legend-data-object-name-li">
                        <div class="legend-data-object-name" title="${signalObjectName}">
                            ${signalObjectName}
                        </div>
                    </li>`;
          if (this.prefs.descriptionType == 'onlyDesc') {
            _iedHtml = '';
            _signalHtml = '';
          }
          if (this.prefs.descriptionType != 'onlyName') {
            _iedHtml += `
                    <li class="legend-ied-name-li" title="${signal.iedDesc}">
                        <div>
                            ${signal.iedDesc}
                        </div>
                    </li>`;
            _signalHtml += `
                    <li class="legend-data-object-name-li" title="${signal.desc}">
                        <div>
                            ${signal.desc}
                        </div>
                    </li>`;
          }
          yLabel.innerHTML = `
                <ul>
                    ${_iedHtml}
                    <li class="legend-control-block-name-li">
                        <div class="legend-control-block-name${simulated}" title="${signalName.goId}">
                            ${signalName.goId}
                        </div>
                    </li>
                    ${_signalHtml}
                </ul>`;
          // graphDiv = $(`
          //     <div
          //         id="div_${signalNumber}"
          //         class="timeline ${cdc + simulated}"
          //         style="
          //             height:${legend[cdc].height ? legend[cdc].height : "75px"};
          //             width:900px;
          //             position:relative;
          //             padding:0 0 0 150px">
          //     </div>`)
          graphDiv.id = 'div_' + signalNumber;
          graphDiv.className = 'timeline ' + cdc + simulated;
          graphDiv.dataset.rowId = signal.signal_id;
          graphDiv.style.width = '100%';
          graphDiv.style.height = legend[cdc].height
            ? legend[cdc].height
            : this.prefs.descriptionType == 'nameAndDesc'
            ? '125px'
            : '105px';
          graphDiv.style.position = signalNumber == signals.length - 1 ? 'fixed' : 'relative';
          graphDiv.style.display = 'flex';
          graphDiv.style.bottom = '0px';
          // graphDiv.style.padding = "0 0 0 13vw"//"0px 0px 0px 150px"
          //graphDiv.style.borderBottom = "1px solid gray";
          //console.log('Data: ', data);
          graphsDiv.appendChild(graphDiv);

          graphContainer.style.width = 'calc(100% - 170px)';

          graphDiv.appendChild(graphContainer);

          dev.log('graphContainer', graphContainer, data.arr);

          g = new Dygraph(graphContainer, data.arr, {
            idx: key,
            drawPoints: false,
            legend: 'follow',
            labels: data.labels,
            _states: data.states,
            _do: signalName.do,
            dateWindow: [this.graphDataWindowStart, this.graphDataWindowEnd],
            series: data.series != null ? data.series : {},
            quality: data.quality,
            stepPlot: legend[cdc].stepPlot,
            highlightCallback: () => {
              //убирает двоеточие после времени в легенде
              $('.dygraph-legend').each((i, elem) => {
                $(elem).html($(elem).html().replace(':<span>', '<span>'));
                // console.log($(elem).html())
              });
            },
            pointClickCallback: this.faultProfile.pointClick,
            valueFormatter: (_val, opts, seriesName, dygraph, row, col) => {
              if (seriesName == 'Time')
                return new DateNano(_val)
                  .setTimezoneOffset(this.prefs.timezoneOffset)
                  .customFormat('#hhhh#:#mm#:#ss#.#ms##us#');
              return _val;
            },
            axes: {
              y: {
                drawAxis: true,
                valueRange: data.valueRange,
                ticker: data.yticker,
              },
              x: {
                drawAxis: false, //signals.length < 2,
                valueRange: [tstart, tend],
                ticker: this.dygraphTpui.dateTicker,
                axisLabelFormatter: this.dygraphTpui.dateAxisLabelFormatter,
              },
            },
            highlightCircleSize: 5,
            showLabelsOnHighlight: true,
            animatedZooms: true,
          });

          dev.log('g');

          graphDiv.insertBefore(yLabel, graphDiv.firstChild);
          dev.log('ap');
          this.timelinesArray = this.timelinesArray.filter((el) => {
            dev.log('comp', el.user_attrs_.idx, g.user_attrs_.idx);
            return el.user_attrs_.idx !== g.user_attrs_.idx;
          });
          this.timelinesArray.push(markRaw(g));
          dev.log('ap2');
          // window.dispatchEvent(new Event('tpui'));
          // $(yLabel).prepend(
          //     //todo remove action
          //   `<button href="#" class="remove-btn close" title="${$t(
          //     'Hide the graph'
          //   )}"><span aria-hidden="true">&times;</span></button>`
          // );
          dev.log('ap3');
          // Сохраняем последний успешно обработанный сигнал, чтобы на основе него сделать шкалу времени
          lastSignal = signal;
          dev.log('ap4');
          //console.log(g);
        } else {
          dev.log('cdcIsSupported false');

          eventBus.dispatch('notification', {
            message: this.$t(
              'CDC type {{unsupported}} is not supported in the this version of Tekvel Park. Supported CDCs are {{supported}}. This signal shall not be displayed.',
              { unsupported: cdc, supported: this.dygraphTpui._supportedCdcs.join(', ') }
            ),
            type: 'info',
          });

          // $.notify(
          //     this.$t(
          //         `CDC type $1 is not supported in the this version of Tekvel Park. Supported CDCs are: $2. This signal shall not be displayed.`,
          //         cdc,
          //         this.Dygraph._supportedCdcs.join(', ')
          //     ),
          //     {
          //       style: 'info',
          //       position: 'top-right',
          //     }
          // );
          //   notifyKas.show({ text: this.$t(
          //         `CDC type $1 is not supported in the this version of Tekvel Park. Supported CDCs are: $2. This signal shall not be displayed.`,
          //         cdc,
          //         this.Dygraph._supportedCdcs.join(', ')
          //     ), close: true, type: 'info' });
          dev.log('not supported');
        }
        dev.log('ape');
      });
      if (signals.length >= 1 && this.timelinesArray.length >= 1) {
        dev.log('check count', this.timelinesArray.length, signals.length);
        if (this.timelinesArray.length != signals.length)
          console.info('Signals and graphics count mismatch', this.timelinesArray.length, signals.length);
        sync = Dygraph.synchronize(this.timelinesArray, {
          selection: false,
          zoom: true,
          range: false,
        });
        dev.log('tpui.timelinesArray sig', this.timelinesArray);
        dev.log('sync', sync);
      }

      $('#graphs').sortable();
    },
    async getSvr() {
      let lines = this.$refs.svrMeta.rowsDataSelected;
      dev.log('setSvr', parseInt(this.signalsTimeBefore), parseInt(this.signalsTimeAfter));
      // $(`#svr-dygraph`).empty();
      this.svrCache = {};
      var graphDivClean = document.getElementById('svr-dygraph');
      graphDivClean.innerHTML = '';
      this.svrCache = [];
      for (const [idx, l] of lines.entries()) {
        dev.log('eachLine', l);
        let dataUrl =
          `/projects/${this.currentProject}/oscfiles` +
          '?' +
          this.$qs.stringify({
            ...this.oscsMetaQuery,

            lvb: l.lvb_id,
            svId: l.svId,
            dstMac: l.dstMac,
            appId: l.appId,
            simulation: l.simulation,
          });
        this.svrDrawLoading = true;
        const result = await api.get(dataUrl);

        // this.errors.push(e);
        this.loading = false;
        const meta = result?.data?.desc?.meta;
        dev.log('result meta', meta);
        let key = `${meta?.appId}-${meta?.dstMac}-${meta?.iedName}-${meta?.lvbId}-${meta?.simulation}-${meta?.svId}-${idx}`;
        this.svrCache.push({ ...result.data, key: key });
        dev.log('ans', result.data);
      }

      for (const [index, dat] of Object.entries(this.svrCache)) {
        dev.log(`${index}: `, dat);
        this.svrDraw(
          { ...dat, idx: dat.key },
          'signals-dygraph-canvas',
          this.fineTimestamp,
          this.fineTimeBefore,
          this.fineTimeAfter
        );
      }
      // lines.forEach((l, idx) => {
      // });

      this.$refs.togggleTimestamp.classList.remove('d-none');
    },

    switchTab(tab) {
      switch (tab) {
        case 'signals':
          if (this.signalsCount > 0) {
            this.currentTab = 'signals';
          }
          break;
        case 'svr':
          if (this.svrCount > 0) {
            this.currentTab = 'svr';
          }
          break;
        case 'pcaps':
          if (this.pcapsCount > 0) {
            this.currentTab = 'pcaps';
          }
          break;
      }
    },
    loadedSignals(rows) {
      this.signalsCount = rows;
    },
    loadedSvr(rows) {
      this.svrCount = rows;
    },
    loadedPcaps(rows) {
      this.pcapsCount = rows;
    },
    switchType(type) {
      dev.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
    },
  },
  watch: {
    'visual.contentLeft': {
      // deep: true,
      handler: function (v) {
        this.onTimerResize();
      },
    },
    signalsMetaQuery: {
      deep: true,
      handler: function (v) {
        dev.log('signalsMetaQuery', JSON.stringify(v));
      },
    },
    'eventProfile.timestampType': {
      deep: false,
      handler: function (v) {
        dev.log('timestampType', v);
        this.svrRedraw();
        // this.getSvr()
      },
    },
  },
};
</script>

<style lang="scss" scoped>
//:root {
//  --contentWidth: visual.contentWidth;
//}
#timer-bck {
  position: absolute;
  //left: var(--contentLeft);
  //width: var(--contentWidth);
  left: 0;
  width: 100%;
  top: 0;
  height: 49px;
  background: white;
  z-index: 2;
}
#timestamp-type-toggle {
  background-color: #f8f9fb;
}
#timestamp-type-toggle {
  justify-content: center;
  margin-left: unset;
  padding-top: 15px;
}
.timestamp-type-toggle__background {
  position: absolute;
  width: auto;
  height: 45px;
  //background-color: #f8f9fb;
  left: 0;
  right: 0;
  top: -2px;
  z-index: -1;
}
</style>
<style lang="scss"></style>
