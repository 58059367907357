<template>
  <tr v-if="obj.type == 1 && obj.current[0].status.alive">
    <th>
      {{ obj.goId }}
      <span class="badge badge-pill badge-danger" v-if="obj.current[0].status.isGhost">{{ $t('Ghost') }}</span
      ><span class="badge badge - pill badge - warning" v-if="obj.simulation == 1">{{ $t('Simulated') }}</span>
    </th>
    <td>
      <small
        >{{ $t('dstMac') }}: {{ obj.dstMac }}<br />
        {{ $t('srcMac') }}: <a :href="'http://api.macvendors.com/' + obj.srcMac">{{ obj.srcMac }}</a
        ><br />
        {{ $t('APPID') }}: {{ padAppId(obj.appId) }}
        <button
          class="btn btn-link btn-sm dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ $t('detailed') }}
        </button>
        <div class="dropdown-menu">
          <span class="dropdown-header"
            ><strong>{{ $t('GoCbRef') }}</strong
            >: {{ obj.goCbRef }}</span
          >
          <span class="dropdown-header" v-for="(value, key, index) in obj.current[0]"
            ><strong>{{ key }}</strong
            >: {{ value }}</span
          >
        </div>
      </small>
    </td>
    <td>
      <span v-for="(lvb, idx) in obj.current[0].status.aliveLVB">{{ idx + 1 }}.&nbsp;{{ lvb }}</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'live-dev-object',
  props: ['obj', 'idx'],
  methods: {
    padAppId(appId) {
      return '0x' + appId.padStart(4, '0');
    },
  },
};
</script>

<style scoped></style>
