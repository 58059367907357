import { defineStore } from 'pinia';

export const useWheel = defineStore({
  id: 'wheel',
  state: () => {
    return {
      visibleLvbDescriptionModal: false,
      visibleLastEventsModal: false,
      visibleSelectSubnetModal: false,
      pathTree: undefined,
      selectedSubnet: undefined,
      graphWidth: 0,
      graphHeight: 0,
      isOpenWheel: false,
      liveModeOn: false,
    };
  },

  actions: {
    showLastEventsModal() {
      this.visibleLastEventsModal = true;
    },
    hideLastEventsModal() {
      this.visibleLastEventsModal = false;
    },
    showSelectSubnetModal() {
      this.visibleSelectSubnetModal = true;
    },
    hideSelectSubnetModal() {
      this.visibleSelectSubnetModal = false;
    },
    showLvbDescriptionModal() {
      this.visibleLvbDescriptionModal = true;
    },
    hideLvbDescriptionModal() {
      this.visibleLvbDescriptionModal = false;
    },
    setGraphSize(size) {
      this.graphWidth = size.width;
      this.graphHeight = size.height;
    },
    setIsOpenWheel(state, mode) {
      state.isOpenWheel = mode;
    },
    setLiveModeOn(mode) {
      dev.log('setLiveModeOn', mode);
      state.liveModeOn = mode;
    },
  },
});
