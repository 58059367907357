<script setup>
import { onBeforeMount, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useTranslation } from "i18next-vue";

import { useVisual } from '@/stores/visual';
import { useWheel } from '@/stores/wheel';
import TWheelDrawer from '@/components/ui/TWheelDrawer.vue';
import WheelGraph from '@/components/wheel/WheelGraph.vue';
import WheelBreadcrumbs from '@/components/wheel/WheelBreadcrumbs.vue';
import WheelDesc from '@/components/wheel/WheelDesc.vue';
import SelectSubnetModal from '@/components/modals/SelectSubnetModal.vue';
import EventsCounterModal from '@/components/wheel/EventsCounterModal.vue';
import { Icon } from '@vicons/utils';
import Checkbox from '@/components/icons/Checkbox.vue';
import CheckboxChecked from '@/components/icons/CheckboxChecked.vue';

//stores
const visual = useVisual();
const wheel = useWheel();

//
const route = useRoute();
const router = useRouter();
const { t, i18next } = useTranslation();

//vars
const bulls = [];
const desc = [];

//life-cycle methods
onBeforeMount(() => {
  dev.log('onBeforeMount');
  visual.showEventsCounterModal(false);
});
onMounted(() => {
  router.isReady().then(() => {
    dev.log('$router Ready', route.value, router.currentRoute.value);
    init();
    wheel.hideLastEventsModal();
  });
});

//methods
const toggleWheelCollapsed = visual.toggleWheelCollapsed;
const init = () => {
  dev.log('wheel.page init');
  if (router.currentRoute.name == 'open-wheel-page') {
    wheel.isOpenWheel = true;
  } else {
    wheel.isOpenWheel = false;
  }
  if (router.currentRoute.name == 'open-wheel-page' || router.currentRoute.name == 'wheel-live-page') {
    wheel.liveModeOn = true;
  } else {
    wheel.liveModeOn = false;
  }

  $('#wheel').show();
  $('#right_sidebar').show();
  $('#footer').show();
  $('.wh-live').show();
  $('.wheel-navigation').css('display', 'flex');

  $('#loading').hide();

};
</script>

<template>
  <t-wheel-drawer
    id="wheel-page"
    class="t-wheel-drawer--full-height"
    :class="{ 't-wheel-drawer--vertical--full-height': visual.vertical }"
    :collapsed="visual.wheelCollapsed"
    :vertical="visual.vertical"
    :reverse="visual.wheelOrder"
    :side-scrollable="true"
    @divider-click="toggleWheelCollapsed"
  >
    <template #content>
      <!--      <div style='background-color: grey'>test-div</div>-->
      <wheel-graph @resize="onResize">
        <template #wheel-bread-crumbs>
          <wheel-breadcrumbs
            v-if="wheel.pathTree?.length > 0"
            :list="wheel.pathTree"
            @onSelect="bcAction"
          ></wheel-breadcrumbs>
        </template>
        <template #wheel-nav>
          <n-popover v-if="isLivePage" placement="bottom-start" trigger="click" raw :show-arrow="true">
            <template #trigger>
              <button class="wheel-nav-btn btn mr-1">
                <v-svg fill="#1757bd" src="settings-cog"></v-svg>
              </button>
            </template>
            <div class="wheel-tree-view">
              <div v-if="false" class="wheel-tree-view__header">{{ $t('Hierarchy') }}</div>
              <div class="wheel-tree-view__list">
                <span v-if="isLivePage" class="live-controls">
                  <span style="display: inline-block; margin-right: 10px">
                    {{ $t('Number of events') }}:&nbsp;
                    <input
                      type="checkbox"
                      @change="wheelEventsCounter.toggleBadges()"
                      style="margin-right: 4px"
                      class=""
                      id="eventsCounterVisibility_switcher"
                    />
                    <label for="eventsCounterVisibility_switcher" class="">{{ $t('Show') }}</label>
                  </span>

                  <a
                    href="#"
                    style="display: inline-block; margin-right: 10px"
                    @click="visual.eventsCounterModalVisible = true"
                    >{{ $t('Change interval') }}</a
                  >
                </span>
              </div>
            </div>
          </n-popover>

          <button
            id="back_btn"
            class="wheel-nav-btn btn fal fa-long-arrow-left mr-1"
            :disabled="history?.length < 1"
            :title="$t('Back')"
          ></button>

          <button
            id="parent_btn"
            class="wheel-nav-btn btn fal fa-long-arrow-up mr-1"
            :disabled="currentLvl < 1"
            :title="$t('Go to parent element')"
          ></button>

          <button id="reset_btn" class="wheel-nav-btn btn fal fa-asterisk mr-2" :title="$t('Reset')"></button>

          <button
            v-if="true"
            id="redraw_btn"
            class="wheel-nav-btn btn fal fa-sync mr-2"
            :title="$t('Redraw')"
            @click="resetTime"
          ></button>

          <button
            v-if="false"
            id="filter_btn"
            class="btn"
            type="button"
            data-placement="top"
            data-html="true"
            data-toggle="popover"
            role="button"
            data-template='<div class="popover" role="tooltip"><div class="arrow"></div><span class="popover-header popover-header-custom"></span><div class="popover-body popover-body-scroll"></div></div>'
            data-container=".wheel__top-bar"
            :title="$t('Device groups')"
            href="#reset"
          >
            <span class="filter-btn-text">
              <v-svg src="filter" fill="#1757BD"></v-svg>
            </span>
          </button>
          <n-popover v-if="true" placement="bottom-end" trigger="click" raw :show-arrow="true">
            <template #trigger>
              <button id="filter_btn_next" class="btn ml-2" type="button">
                <span class="filter-btn-text">
                  <v-svg src="filter" fill="#1757BD"></v-svg>
                </span>
              </button>
            </template>
            <div class="wheel-groups-filter">
              <div class="wheel-groups-filter__header">{{ $t('Device groups') }}</div>
              <ul class="wheel-groups-filter__list">
                <li v-for="group in groupsItems">
                  <div :title="group.desc" class="checkbox">
                    <div class="check-group" :for="group.id" @click.prevent="switchGroups(group, $event)">
                      <icon size="16">
                        <checkbox-checked v-if="group.enabled"></checkbox-checked>
                        <checkbox v-else></checkbox>
                      </icon>
                      &nbsp;
                      <div>{{ group.name }}</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </n-popover>
        </template>
      </wheel-graph>
    </template>
    <template #side>
      <!--      <div style='background-color: grey'>test-div</div>-->
      <wheel-desc :bulls="bulls" :desc="desc" @showDesc="showDesc"></wheel-desc>
    </template>
  </t-wheel-drawer>
  <select-subnet-modal
    :selectedSubnet="selectedSubnet?.name"
    :list="subnetsList"
    @select="doSubnetSelect"
  ></select-subnet-modal>
  <events-counter-modal></events-counter-modal>
  <!--  </div>-->
</template>

<style lang="scss">
.check-group {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}
.wheel-groups-filter {
  border-radius: 5px;
  &__header {
    padding: 10px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
  }
  &__list {
    padding: 10px;
    list-style-type: none;
    & li {
    }
  }
}
.wheel-tree-view {
  border-radius: 5px;
  &__header {
    padding: 10px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
  }
  &__list {
    background-color: white;
    padding: 10px;
    list-style-type: none;
    & li {
    }
  }
}
.t-layout {
  & .wheel-desc-block {
    min-height: 100%;
    flex: auto;
    display: flex;
    background-color: #e5e5e5;
    & .n-scrollbar > .n-scrollbar-rail {
      z-index: 999;
    }
  }

  &--full-height {
    flex: auto;
  }
}
</style>
<style scoped lang="scss">
.wheel-nav-btn {
  background: rgba(23, 87, 189, 0.1);
  color: #1757bd;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
