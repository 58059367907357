<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 7.91669L5.83334 12.0834H14.1667L10 7.91669Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'caret-up',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
