import api from '@/services/api';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue/dist/vue.esm-bundler';

export const useNotify = defineStore({
  id: 'notify',
  state: () => {
    return {
      listInternal: [],
      list: [],
      bodyEl: undefined,
      wrapperEl: null,
      firstOffset: 85,
    };
  },
  getters: {},
  actions: {
    setList(list) {
      this.listRaw = list;
    },
    initWrapper() {
      this.bodyEl = document.body;
      const oldEl = document.getElementsByClassName('notify-kas')[0];
      if (oldEl) {
        oldEl.parentNode.removeChild(oldEl);
      }

      const wrapperEl = document.createElement('div');
      wrapperEl.className = 'notify-kas';
      this.bodyEl.appendChild(wrapperEl);

      this.wrapperEl = wrapperEl;
    },
    reRenderOffset() {
      if (this.list.length) {
        setTimeout(() => {
          this.list.forEach(({ contentEl }, index) => {
            const tmpList = Object.assign([], this.list);
            tmpList.length = index;
            const offset = tmpList.reduce((a, b) => a + b.contentEl.clientHeight, 0);
            contentEl.style.top = `${this.firstOffset + offset + 10 * index}px`;
          });
        }, 0);
      }
    },
    init() {
      //console.log('init notify');
      this.initWrapper();
    },
    show({
      text,
      type = 'info',
      buttonList = null,
      close = false,
      clickToHide = false,
      autoHide = true,
      autoHideDelay = 5000,
    }) {
      //console.log('show n');
      const newList = this.list;
      const itemId = this.list.length;

      const contentEl = document.createElement('div');
      contentEl.className = 'notify-kas__content';
      contentEl.classList.add(`notify-kas__content_${type}`);

      if ((!autoHide && !close) || clickToHide) {
        contentEl.setAttribute('role', 'button');
        contentEl.onclick = () => this.hide(itemId);
      }

      const textEl = document.createElement('div');
      textEl.className = 'notify-kas__text';
      textEl.innerText = text;
      const actionEl = document.createElement('div');
      actionEl.className = 'notify-kas__action';

      if (buttonList) {
        buttonList.forEach(({ title, onclick, hideOnClick = true }) => {
          const buttonEl = document.createElement('button');
          buttonEl.className = 'notify-kas__button';
          buttonEl.innerText = title;
          buttonEl.onclick = (ev) => {
            if (!hideOnClick) {
              ev.stopPropagation();
            }
            onclick();
          };

          actionEl.appendChild(buttonEl);
        });
      }

      if (close) {
        const closeEl = document.createElement('i');
        closeEl.className = 'notify-kas__close fas fa-times';
        closeEl.setAttribute('role', 'button');
        closeEl.onclick = (ev) => {
          ev.stopPropagation();
          this.hide(itemId);
        };

        actionEl.appendChild(closeEl);
      }

      contentEl.appendChild(textEl);
      contentEl.appendChild(actionEl);

      this.wrapperEl.appendChild(contentEl);

      newList.push({ contentEl, id: itemId });

      if (autoHide) {
        setTimeout(() => {
          this.hide(itemId);
        }, autoHideDelay);
      }

      this.list = newList;
      this.reRenderOffset();
    },
    hide(id) {
      const findIndex = this.list.findIndex((i) => i.id === id);
      const findItem = this.list.find((i) => i.id === id);
      if (findIndex !== -1 && findItem) {
        findItem.contentEl.style.top = '-100px';
        setTimeout(() => {
          findItem.contentEl.parentNode.removeChild(findItem.contentEl);
          const list = this.list;
          list.splice(findIndex, 1);
          this.list = list;
          this.reRenderOffset();
        }, 300);
      }
    },
  },
});
