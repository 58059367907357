<template>
  <ul>
    <li
      v-for="bull in bulls"
      @click="$emit('showDesc', bull.i, bull.prefix)"
      :id="'wh_desc_li_' + bull.prefix + '_' + bull.i"
    >
      <span class="fal fa-square" aria-hidden="true"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'wh-bulls',
  props: {
    bulls: {
      type: Array,
    },
  },
  emits: ['showDesc'],
};
</script>

<style scoped></style>
