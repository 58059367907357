import { v4 as uuid } from 'uuid';

const saveId = (e) => {
  window.sessionStorage.tabId = window.tabId;

  return null;
};

const restoreId = (e) => {
  if (window.sessionStorage.tabId) {
    window.tabId = window.sessionStorage.tabId;
    window.sessionStorage.removeItem('tabId');
  } else {
    window.tabId = uuid();
  }

  return null;
};

const init = () => {
  window.addEventListener('beforeunload', saveId);

  window.addEventListener('load', restoreId);
};

export default init;
