<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.1">
      <rect x="3" y="3" width="18" height="18" rx="3" fill="white" />
      <rect x="3" y="3" width="18" height="18" rx="3" stroke="#1757BD" stroke-width="2" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'checkbox',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
