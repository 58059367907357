export class UserPreferences {
  constructor(userId = 0, userPreferencesInit) {
    // сохраняем id пользователя
    this.userId = +`${userId}`;

    // проверяем объект, переданный в конструктор
    userPreferencesInit = UserPreferences.check(userPreferencesInit);

    // задаем свойства объекта класс из конфига и переданных
    this.preferences = {
      ...UserPreferences.userPreferencesDefault,
      ...userPreferencesInit,
    };
    // создаем объект общих настроек
    this.generalPreferences = {};

    UserPreferences.bindProperties(this);

    return this;
  }

  applyPreferences() {
    // передаем preferences в форму
    for (const prop in this.preferences) {
      const propProject = $(`.user-preferences__${prop}-project`);
      if ($(`.user-preferences__${prop}-default`).prop('checked') || !$(`.user-preferences__${prop}-default`).length) {
        this[prop] = null;
        propProject.prop('disabled', true);
        continue;
      }

      if (propProject.prop('tagName') + '-' + propProject.prop('type') == 'INPUT-checkbox')
        propProject.bootstrapToggle(this.preferences[prop] ? 'on' : 'off');
      else propProject.val(this.preferences[prop]);
    }
    return this;
  }

  // Привязка обработчиков изменений полей в форме
  bindEvents() {
    for (const prop in this.preferences) {
      const propProject = $(`.user-preferences__${prop}-project`),
        propGeneral = $(`.user-preferences__${prop}-general`),
        propDefault = $(`.user-preferences__${prop}-default`);

      propDefault.change(() => {
        propProject.change();

        if (propDefault.prop('checked')) {
          propProject.prop('disabled', true);
          if (propProject.prop('tagName') + '-' + propProject.prop('type') == 'INPUT-checkbox')
            propProject.bootstrapToggle(propGeneral.prop('checked') ? 'on' : 'off');
          else propProject.val(propGeneral.val());
        } else {
          propProject.prop('disabled', false);
        }
      });

      switch (propGeneral.prop('tagName') + '-' + propGeneral.prop('type')) {
        // В случае массива
        case 'SELECT-select-one':
          propProject.change(() => {
            dev.log(this[prop]);
            this[prop] = propDefault.prop('checked')
              ? null
              : typeof UserPreferences.userPreferencesDefault[prop] == 'number'
              ? +propProject.val()
              : propProject.val();
          });
          propGeneral.change(() => {
            dev.log(this[prop]);
            this.generalPreferences[prop] =
              typeof UserPreferences.userPreferencesDefault[prop] == 'number' ? +propGeneral.val() : propGeneral.val();
          });

          break;
        // В случае boolean
        case 'INPUT-checkbox':
          propProject.change(() => (this[prop] = propDefault.prop('checked') ? null : propProject.prop('checked')));
          propGeneral.change(() => (this.generalPreferences[prop] = propGeneral.prop('checked')));
          break;
        // В случае string
        case 'INPUT-text':
          propProject.change(() => (this[prop] = propDefault.prop('checked') ? null : propProject.val()));
          propGeneral.change(() => (this.generalPreferences[prop] = propGeneral.val()));
          break;
        // В случае number
        case 'INPUT-number':
          propProject.change(() => {
            dev.log(this[prop]);
            this[prop] = propDefault.prop('checked') ? null : +propProject.val();
          });
          propGeneral.change(() => (this.generalPreferences[prop] = +propGeneral.val()));
          break;
      }
      // вызываем событие, чтобы заполнить объект общих настроек
      propGeneral.change();
    }
    return this;
  }

  // Отправка сформированого в виде JSON объекта
  submitForm() {
    const pref = {
      projectName: tpws.projectName,
      ...this.preferences,
    };

    ajax.setPropertiesSchema({ userPrefernces: JSON.stringify(pref) }, () =>
      ajax.setPropertiesSchema({ userPrefernces: JSON.stringify(this.generalPreferences) }, () => location.reload(1))
    );
  }

  // Проверка объекта на валидность свойств
  static check(checkedObject) {
    const normalObject = {};
    let condition;

    if (typeof checkedObject != 'object') return normalObject;

    for (const prop in checkedObject) {
      if (!this.userPreferencesSchema[prop] || checkedObject[prop] === undefined) continue;

      normalObject[prop] = this.userPreferencesDefault[prop];

      condition =
        (typeof this.userPreferencesSchema[prop] == 'string' &&
          this.userPreferencesSchema[prop] == typeof checkedObject[prop]) ||
        ~this.userPreferencesSchema[prop].indexOf(checkedObject[prop]);

      if (condition) normalObject[prop] = checkedObject[prop];
    }

    return normalObject;
  }

  // Привязка всех свойств с их геттерами и сеттерами к объекту
  static bindProperties(object) {
    for (const prop in this.userPreferencesSchema)
      Object.defineProperty(object, prop, {
        get: this.userPreferencesGetters[prop],
        set: this.userPreferencesSetters[prop],
      });
  }

  // Метод добавления новых свойств
  static addProperty(newProperty) {
    if (!this.userPreferencesSchema) this.userPreferencesSchema = {};
    this.userPreferencesSchema[newProperty.name] = newProperty.schema;

    if (!this.userPreferencesDefault) this.userPreferencesDefault = {};
    this.userPreferencesDefault[newProperty.name] = newProperty.default;

    if (!this.userPreferencesDescription) this.userPreferencesDescription = {};
    this.userPreferencesDescription[newProperty.name] = newProperty.description;

    if (!this.userPreferencesPreventToChange) this.userPreferencesPreventToChange = {};
    this.userPreferencesPreventToChange[newProperty.name] = newProperty.preventToChange;

    if (!this.userPreferencesGetters) this.userPreferencesGetters = {};
    this.userPreferencesGetters[newProperty.name] = newProperty.getter;

    if (!this.userPreferencesSetters) this.userPreferencesSetters = {};
    this.userPreferencesSetters[newProperty.name] = newProperty.setter;
  }

  static uploadSchema() {
    return new Promise((resolve) =>
      ajax.getPropertiesSchema((data) => {
        const initObject = {};
        for (const prop in data.desc) {
          if (prop == 'userId') continue;
          this.addProperty(
            new PropertyUserPreference({
              name: prop,
              schema: data.desc[prop].userPreferencesSchema,
              default: data.desc[prop].userPreferencesDefault,
              description: data.desc[prop].userPreferencesDescription,
              preventToChange: data.desc[prop].userPreferencesPreventToChange,
            })
          );
          initObject[prop] = data.desc[prop].value;
        }
        this.user = new this(data.desc.userId, initObject);

        window.localStorage.setItem('vue.user', JSON.stringify({ value: this.user, expire: null }));
        window.dispatchEvent(new Event('user'));

        resolve();
      })
    );
  }
}

export class PropertyUserPreference {
  constructor(property = {}) {
    // Проверяем имя нового свойства, и ставим заглушку, если оно не указано
    property.name = property.name !== undefined && typeof property.name == 'string' ? property.name : 'nonameprop';
    this.name = property.name;

    // Проверяем схему и ставим заглушку, если не указана
    property.schema =
      (property.schema !== undefined && Array.isArray(property.schema)) ||
      property.schema == 'string' ||
      property.schema == 'number' ||
      property.schema == 'boolean' ||
      property.schema == 'object'
        ? property.schema
        : ['noschemaprop'];
    this.schema = property.schema;

    // Проверяем дефолтное значение и ставим заглушку в виде первого элемента массива схемы
    property.default = property.default !== undefined ? property.default : property.schema[0];
    this.default = property.default;

    // Проверяем описание и ставим заглушку
    property.description = property.description !== undefined ? property.description : property.name;
    this.description = property.description;

    // Проверяем запрет на изменение. По умолчанию отсутствует
    property.preventToChange = property.preventToChange !== undefined ? property.preventToChange : false;
    this.preventToChange = property.preventToChange;

    this.getter =
      property.getter ||
      function () {
        return this.preferences[property.name] !== null
          ? this.preferences[property.name]
          : this.generalPreferences[property.name];
      };
    this.setter =
      property.setter ||
      function (newVal) {
        if (~property.schema.indexOf(newVal) || property.schema === typeof newVal || newVal === null)
          this.preferences[property.name] = newVal;
        return this.preferences[property.name];
      };
  }
}
