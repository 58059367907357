import api from '@/services/api';
import { defineStore } from 'pinia';

export const useConfig = defineStore({
  id: 'config',
  state: () => {
    return {
      context: undefined,
    };
  },
  getters: {
    nonCloudStart(state) {
      return this.contextResolver?.nonCloudStart;
    },
    userPreferences(state) {
      return this.contextResolver?.userPreferences;
    },
    contextResolver(state) {
      return state.context || this.getContext();
    },
  },
  actions: {
    getContext() {
      return new Promise((resolve, reject) => {
        api
          .get(`/config`)
          .then(({ data }) => {
            dev.log('config resolve', data);
            this.context = data;
            resolve(this.context);
          })
          .catch((error) => {
            console.error('config error', error);
            resolve(error);
          });
      });
    },
    async reloadConfig(versions) {
      this.getContext();
    },
    async loadConfig(project) {
      const tmp = this.contextResolver;
    },
  },
});
