<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import AppLayoutDefault from './AppLayoutDefault.vue';
import AppLayoutError from './AppLayoutError.vue';
import { shallowRef } from 'vue/dist/vue.esm-bundler';
export default {
  name: 'app-layout',
  data: () => ({
    layout: shallowRef(AppLayoutDefault),
  }),
  computed: {
    metaLayout() {
      return this.$route?.meta?.layout;
    },
  },
  watch: {
    metaLayout: {
      immediate: true,
      async handler(layout, layoutOld) {
        if (layout === 'app-layout-error') {
          this.layout = AppLayoutError;
        } else {
          this.layout = AppLayoutDefault;
        }
      },
    },
  },
};
</script>
