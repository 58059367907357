import api from '@/services/api';
import { useTpws } from '@/stores/tpws';
import { defineStore } from 'pinia';
import SecureLS from 'secure-ls';
var ls = new SecureLS({ isCompression: false });

export const useFilters = defineStore({
  id: 'filters',
  persist: {
    paths: ['clusterFilter', 'clusterFilterValues', 'filterVisible'],
    storage: sessionStorage,
  },
  state: () => {
    return {
      clusterFilterLoaded: false,
      clusterFilter: {
        loading: false,
        bounds: {
          clusterSize: {
            min: 0,
            max: 0,
          },
          clusterDate: {
            min: 0,
            max: 0,
          },
        },
        items: {
          types: [],
          protocols: [],
          devices: [],
        },
      },
      clusterFilterValues: {
        textSearch: '',
        clusterSize: { min: 1, max: 99999 },
        clusterDate: { min: null, max: null },
        types: [],
        protocols: [],
        devices: [],
      },
      clusterFilterValuesLast: {},
      clusterFilterValuesRestored: false,
      filterVisible: true,
    };
  },
  getters: {
    filtersLoaded: (state) => {
      return state.clusterFilterLoaded;
    },
    filters: (state) => {
      return {
        types: state.clusterFilter.items.types,
        protocols: state.clusterFilter.items.protocols,
        devices: state.clusterFilter.items.devices,
        clusterSizeBounds: state.clusterFilter.bounds.clusterSize,
        clusterDateBounds: state.clusterFilter.bounds.clusterDate,
      };
    },
    filterValues: (state) => {
      return state.clusterFilterValues;
    },
  },
  actions: {
    async loadFilters() {
      const tpws = useTpws();
      // make an API call to login the user with an email address and password
      const {
        data: {
          data: { types, protocols, devices, clusterSizeBounds, clusterDateBounds },
        },
      } = await api.get(`/projects/${tpws.currentProject}/get-filters`);

      // commit the user and tokens to the state
      this.clusterFilter.items.types = types;
      this.clusterFilter.items.protocols = protocols;
      this.clusterFilter.items.devices = devices;
      this.clusterFilter.bounds.clusterSize = clusterSizeBounds;
      this.clusterFilter.bounds.clusterDate = clusterDateBounds;
      this.clusterFilterLoaded = Date.now();
      this.restoreValues();
    },

    setTextSearch(value) {
      // console.log('setTextSearch', value);
      this.clusterFilterValues['textSearch'] = value;
    },
    setClusterSize(values) {
      let { min, max } = values;
      if (
        min < this.clusterFilter.bounds.clusterSize.min ||
        min > this.clusterFilter.bounds.clusterSize.max ||
        isNaN(min)
      ) {
        min = this.clusterFilter.bounds.clusterSize.min;
      }
      if (
        max < this.clusterFilter.bounds.clusterSize.min ||
        max > this.clusterFilter.bounds.clusterSize.max ||
        isNaN(max)
      ) {
        max = this.clusterFilter.bounds.clusterSize.max;
      }
      this.clusterFilterValues['clusterSize'] = { min, max };
    },
    setClusterDate(values) {
      let { min, max } = values;
      dev.log('setClusterDate1', values, min, max);
      if (min && (min < this.clusterFilter.bounds.clusterDate.min || min > this.clusterFilter.bounds.clusterDate.max)) {
        min = this.clusterFilter.bounds.clusterDate.min;
      }
      if (max && (max < this.clusterFilter.bounds.clusterDate.min || max > this.clusterFilter.bounds.clusterDate.max)) {
        max = this.clusterFilter.bounds.clusterDate.max;
      }
      dev.log('setClusterDate2', min, max);

      this.clusterFilterValues['clusterDate'] = { min, max };
    },
    pushItemValue({ type, value }) {
      this.clusterFilterValues[type].push(value);
    },
    shiftItemValue({ type, value }) {
      const elIdx = this.clusterFilterValues[type].findIndex((el) => el === value);
      this.clusterFilterValues[type].splice(elIdx, 1);
    },
    storeValues() {
      // commit(FILTER_MUTATIONS.SET_VALUES, { values });
      ls.set('filters', this.clusterFilterValues);
    },
    restoreValues() {
      let oldValues = { ...this.clusterFilterValues };
      dev.log('filters restoreValues');
      let values = ls.get('filters');
      if (values) {
      } else {
        values = {};
      }
      if (Object.keys(values).indexOf('textSearch') > -1) {
        oldValues['textSearch'] = values['textSearch'];
      }
      if (Object.keys(values).indexOf('clusterSize') > -1) {
        oldValues['clusterSize'] = values['clusterSize'];
      } else {
        oldValues['clusterSize'] = this.clusterFilter.bounds.clusterSize;
      }
      if (Object.keys(values).indexOf('clusterDate') > -1) {
        oldValues['clusterDate'] = values['clusterDate'];
      } else {
        oldValues['clusterDate'] = this.clusterFilter.bounds.clusterDate;
      }
      if (Object.keys(values).indexOf('types') > -1) {
        oldValues['types'] = values['types'];
      }
      if (Object.keys(values).indexOf('protocols') > -1) {
        oldValues['protocols'] = values['protocols'];
      }
      if (Object.keys(values).indexOf('devices') > -1) {
        oldValues['devices'] = values['devices'];
      }

      this.clusterFilterValues = oldValues;
    },
    changeFilterVisible() {
      this.filterVisible = !this.filterVisible;
    },
    closeFilterVisible() {
      this.filterVisible = false;
    },
    openFilterVisible() {
      this.filterVisible = true;
    },
  },
});
