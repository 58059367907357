<template>
  <div class="wheel-breadcrumbs__item">
    <div v-if="idx > 0" class="arrow-wrapper">
      <icon size="13"> <arrow-right></arrow-right> </icon>
    </div>
    <portal :disabled="options.length == 0" :to="'popselect-' + uid">
      <n-tooltip trigger="hover">
        <template #trigger>
          <div class="select-wrapper" :class="{ 'has-link': item?.nearby?.length > 0 }">
            <wheel-breadcrumbs-icon :node-type="item?.current?.type" />
            <div class="item-label-block">
              {{ item?.current?.text }}
            </div>
            <div v-if="item?.nearby?.length > 0" class="drop-wrapper drop-has-list">
              <icon size="24">
                <caret-down color="#121314"></caret-down>
              </icon>
            </div>
          </div>
        </template>
        <span class="tooltip-name">{{ item?.current?.text }}</span
        >&nbsp;<span class="tooltip-desc">{{ item?.current?.desc }}</span>
      </n-tooltip>
    </portal>
    <n-popselect
      v-model:value="value"
      :on-update:value="onSelect"
      :options="options"
      size="medium"
      trigger="click"
      :render-label="renderLabel"
      scrollable
    >
      <div class="tooltip-wrapper">
        <portal-target :name="'popselect-' + uid" />
      </div>
    </n-popselect>
  </div>
</template>

<script>
import CaretDown from '@/components/icons/CaretDown.vue';
import ArrowRight from '@/components/icons/ArrowRight.vue';
import { Icon } from '@vicons/utils';
import WheelBreadcrumbsIcon from '@/components/wheel/WheelBreadcrumbsIcon.vue';
import { defineComponent, h } from 'vue/dist/vue.esm-bundler';

export default {
  name: 'wheel-breadcrumbs-item',
  props: {
    idx: {
      type: Number,
    },
    item: {
      type: Object,
    },
  },
  emits: ['onSelect'],
  components: {
    WheelBreadcrumbsIcon,
    Icon,
    CaretDown,
    ArrowRight,
  },
  setup() {
    const uid = Vue.getCurrentInstance().uid;
    return {
      uid,
    };
  },

  data: () => {
    return {
      value: undefined,
    };
  },
  computed: {
    options() {
      return (
        (this.item?.nearby?.length > 0 && [
          {
            type: this.item?.current?.type,
            value: this.item?.current?.name,
            label: this.item?.current?.desc
              ? this.item?.current?.text + ' ' + this.item?.current?.desc
              : this.item?.current?.text,
          },
          ...this.item?.nearby.map((el) => ({
            type: el.type,
            disabled: el?.disabled,
            value: el.name,
            label: el.desc ? el.text + ' ' + el.desc : el.text,
          })),
        ]) ||
        []
      );
    },
  },
  mounted() {
    this.value = this.item?.current?.name;
  },
  methods: {
    renderLabel(option) {
      if (option.type === 'group') return option.label + '(Cool!)';
      return [
        h(
          WheelBreadcrumbsIcon,
          {
            nodeType: option?.type,
          },
          {
            default: () => h('div'),
          }
        ),
        option.label,
      ];
    },
    onSelect(newVal) {
      // this.$nextTick(() => {
      dev.log('selected', this.value, newVal);
      const foundType = this.options.find((el) => el.value === newVal);
      this.$emit('onSelect', { type: foundType?.type, value: newVal });
      // });
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val?.current?.name) {
          this.value = val?.current?.name;
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.n-base-select-option__content {
  display: flex;
  align-items: center;
}
</style>

<style scoped>
.has-link {
  text-decoration-line: underline;
  color: #2a2e32;

  opacity: 0.5;
  cursor: pointer;
}
.drop-has-list {
  cursor: pointer;
}
.drop-wrapper {
  display: flex;
  width: 24px;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.5;
  flex-shrink: 0;
}
.tooltip-name {
  font-weight: bold;
}
.arrow-wrapper {
  display: flex;
  width: 30px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  opacity: 0.5;
}
.wheel-breadcrumbs__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.select-wrapper {
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.item-label-block {
  /*width: 180px;*/
  font-family: 'Noto Sans';
  font-weight: 400;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
