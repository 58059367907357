<template>
  <el-dialog :visible.sync="visibleDialog" max-width="550px" :persistent1="true" class="noBodyTopPadding">
    <template #header>
      <slot name="title"></slot>
    </template>
    <template class="el-dialog__body--no-top-padding" #default> <slot></slot> </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'signal-info',
  props: {
    visibleDialog: {
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('update:visibleDialog', false);
    },
  },
};
</script>

<style scoped></style>
