export const htmlEncode = (s) => {
  var el = document.createElement("div");
  el.innerText = el.textContent = s;
  s = el.innerHTML;
  return s;
};

export const htmlDecode = (s) => {
  var doc = new DOMParser().parseFromString(s, "text/html");
  return doc.documentElement.textContent;
}
