<template>
  <modal-dialog
    :modal="modal"
    v-show="visual.visibleAboutCompanyModal"
    v-model:visible="visual.visibleAboutCompanyModal"
    ref="modEx"
    @closeModal="closeModal"
  >
    <!--    <template #title>-->
    <!--      <span class="lvb-description-modal__title"></span>-->
    <!--    </template>-->
    <template #content>
      <div class="about__header-wrapper">
        <ul id="myTab" class="nav about__header" role="tablist">
          <li class="header__item">
            <a
              href="#main"
              id="main-tab"
              class="header-item__label active"
              data-toggle="tab"
              role="tab"
              aria-controls="main"
              aria-selected="true"
              >{{ $t('About Tekvel Park') }}</a
            >
          </li>

          <li class="header__item">
            <a
              href="#license"
              id="license-tab"
              class="header-item__label"
              data-toggle="tab"
              role="tab"
              aria-controls="license"
              aria-selected="false"
              >{{ $t('License') }}</a
            >
          </li>
        </ul>
      </div>

      <div id="myTabContent" class="tab-content">
        <div id="main" class="tab-pane fade show active" role="tabpanel" aria-labelledby="main-tab">
          <div v-if="license.license?.current_version" class="about__block">
            <div class="about-block__title">
              <div class="about-block__label">{{ $t('Common info') }}</div>
            </div>

            <div class="about-block__body">
              <div class="about-block__item">
                <div class="about-block__item-label">
                  <strong>{{ $t('Build version') }}</strong>
                </div>
                <div class="about-block__item-value">{{ license.license.current_version.tpwsVersionString }}</div>
              </div>

              <div class="about-block__item">
                <div class="about-block__item-label">
                  <strong>{{ $t('Build date') }}</strong>
                </div>
                <div class="about-block__item-value">
                  {{ license.license.current_version.tpwsVersionDateString }}
                </div>
              </div>
            </div>
          </div>

          <div class="about__block">
            <div class="about-block__title">
              <div class="about-block__label">
                <strong>{{ $t('Free space') }}</strong>
              </div>
            </div>

            <div class="about-block__body1 mt-2 ml-2">
              <about-app v-if="visual.visibleAboutCompanyModal"></about-app>
            </div>
          </div>
        </div>

        <div v-if="license.license" id="license" class="tab-pane fade" role="tabpanel" aria-labelledby="license-tab">
          <template v-if="license.license?.license_data.license">
            <div class="about__block">
              <div class="about-block__title">
                <div class="about-block__label">{{ $t('Common info') }}</div>
              </div>

              <div class="about-block__body">
                <div class="about-block__item">
                  <div class="about-block__item-label">
                    <strong>{{ $t('License number') }}</strong>
                  </div>
                  <div class="about-block__item-value">{{ license.license.license_data.license.number }}</div>
                </div>

                <div class="about-block__item">
                  <div class="about-block__item-label">
                    <strong>{{ $t('License date') }}</strong>
                  </div>
                  <div class="about-block__item-value">{{ license.license.license_data.license.dateString }}</div>
                </div>

                <div class="about-block__item">
                  <div class="about-block__item-label">
                    <strong>{{ $t('License owner') }}</strong>
                  </div>
                  <div class="about-block__item-value">{{ license.license.license_data.license.owner }}</div>
                </div>

                <div class="about-block__item">
                  <div class="about-block__item-label">
                    <strong>{{ $t('License version') }}</strong>
                  </div>
                  <div class="about-block__item-value" :class="{error: isExpired}">
                    {{ license.license.license_data.version.versionString }} {{ $t('date^until' || '') }}
                    {{ license.license.license_data.version.versionDateString }}
                  </div>
                </div>

                <div class="about-block__item">
                  <div class="about-block__item-label">
                    <strong>{{ $t('Live mode') }}</strong>
                  </div>
                  <div class="about-block__item-value">
                    {{ $t(license.license.license_data.live ? 'Available' : 'Not available') }}
                  </div>
                </div>

                <div class="about-block__item">
                  <div class="about-block__item-label">
                    <strong>{{ $t('Project areas') }}</strong>
                  </div>
                  <div class="about-block__item-value">
                    {{ license.license.license_data.maxProjects }}
                    <span v-if="license.licenseUsedProjects"
                      >({{ license.licenseUsedProjects }} {{ $t('created') }})</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="about__block">
              <div class="about-block__title">
                <div class="about-block__label">
                  <strong>{{ $t('SCL-processors') }}</strong>
                </div>
              </div>
              <div class="about-block__body">
                <n-scrollbar :style="{ height: 40 + 'vh' }">
                  <div v-for="proc in license.license.license_data.sclProcessors" class="about-block__table-item">
                    <div class="about-block__scl-name">
                      {{ $t(proc?.typeName || '') }}
                      <br />
                      <small>{{ $t('scl-name^' + proc.typeName) }}</small>
                    </div>

                    <div class="about-block__scl-available">
                      {{ $t(proc.available ? 'Available' : 'Not available') }}
                    </div>
                    <div class="about-block__scl-desc">{{ $t(proc?.typeDesc || '') }}</div>
                  </div>
                </n-scrollbar>
              </div>
            </div>
          </template>
          <div v-else class="row">
            <span class="col-12">{{ $t('No license information') }}</span>
          </div>
        </div>
      </div>
    </template>
    <template #buttons> </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import AboutApp from '@/components/modals/AboutApp.vue';
import { mapActions } from 'pinia';
import { useVisual } from '@/stores/visual';
import { useLicense } from '@/stores/license';
import { mapStores } from 'pinia';

export default {
  name: 'about-company-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog, AboutApp },
  data: () => {
    return {
      modal: {
        name: 'about-company',
        title: 'About Tekvel Park',
        closeBtn: 'Close',
        noSubmit: true,
        size: 'w800',
        noTitle: true,
      },
      dateTime: {},
    };
  },
  computed: {
    ...mapStores(useVisual),
    ...mapStores(useLicense),
    isExpired() {
      return this.license?.licenseStatus?.code == 101002
    }
  },
  mounted() {},
  methods: {
    ...mapActions(useVisual, ['hideAboutCompanyModal']),
    ...mapActions(useLicense, ['loadLicense']),
    tr(m) {
      dev.log('msg', m);
      const tt = this.$t(m);
      dev.log('tr', tt);
      return tt;
    },
    doExport() {
      dev.log('doExport');
      this.$emit('doExport', { format: this.selectedFormat, sort: this.selectedSort });
      this.$emit('update:visibleModal', false);
    },
    closeModal() {
      dev.log('closeModal');
      this.hideAboutCompanyModal();
    },
    selectPeriod(p) {
      dev.log(p);
      this.timePresets
        .filter((e) => e.active)
        .forEach((el) => {
          let ell = el;
          let idx = this.timePresets.indexOf(el);
          delete ell['active'];
          // this.timePresets.$set(idx, ell);
          this.timePresets[idx] = ell;
        });
      dev.log(this.timePresets.filter((e) => e.value === p.value));
      let el = this.timePresets.find((e) => e.value === p.value);
      let idx = this.timePresets.indexOf(el);

      el.active = true;
      // this.timePresets.$set(idx, el);
      this.timePresets[idx] = el;
      this.setTimePeriod(p.value);
    },
    setTimePeriod(value) {
      let now = new Date();
      let start = new Date();
      let end = new Date();
      switch (value) {
        case 'five-min':
          start.setMinutes(now.getMinutes() - 5);
          break;
        case 'today':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          break;
        case 'this-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'last-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 6);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'this-month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case 'last-month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        default:
          break;
      }
      this.dateTime = {
        start: {
          date: start.customFormat('#YYYY#-#MM#-#DD#'),
          time: start.customFormat('#hhhh#:#mm#:#ss#'),
        },
        end: {
          date: end.customFormat('#YYYY#-#MM#-#DD#'),
          time: end.customFormat('#hhhh#:#mm#:#ss#'),
        },
      };
    },
  },
  watch: {
    'visual.visibleAboutCompanyModal': {
      handler(val) {
        dev.log('visibleModal', val);
        // if (val) {
        //   this.loadLicense();
        // } else {
        //   this.$emit('update:visibleModal', val);
        // }
      },
      // deep: true
    },
  },
};
</script>

<style lang="scss">
.error {
  color: red!important;
}
</style>
<style lang="scss"></style>
