import api from '@/services/api';
import { defineStore } from 'pinia';
import { i18next } from '@/root.app';

export const useFaultProfile = defineStore({
  id: 'faultProfile',
  state: () => {
    return {
      eventData: {},
      signalReaderQueue: 0,
      separeteUI: false,
      recordsContainerId: '#graphs',
      faultProfileDivId: '#fault-profile',
      systemFrequency: 50, //Hz
      timelinesArray: [],
      sampleMetadataLength: 3, // 2 stands for two timestamp fields
      downSampleRate: 8, // Put =1 to keep all samples.
      tbefore: 2, // seconds
      tafter: 10, // seconds
      tstart: 0,
      tend: 0,
      timer: {
        point1: null,
        point2: null,
        deltaT: null,
      },
      devFaultProfileTable: undefined,
      faultProfileDataTable: undefined,
      pointClick: undefined,
    };
  },
  getters: {
    timelinesFirst: (state) => {
      return state.timelinesArray.find((e) => e.dateWindow_[0]);
    },
    timelinesFirstDataWindow: (state) => {
      return state?.timelinesFirst?.dateWindow_;
    },
  },
  actions: {
    //todo rest
    initFaultProfile() {
      this.pointClick = this.tpuiFaultProfilePointClick;
    },
    /**
     * Initializes fault records view to further add data to it
     */
    initRecordsView(containerId) {
      //$(containerId).empty()
      this.eventData = {};
      this.signalReaderQueue = 0;
    },
    /**
     * Pseudo class to parse Analog Data header got from the server
     * @param {any} data the data part of get "/oscs" response
     */
    recordHeaderData(data) {
      this.svId = data.metadata.svId;
      this.dstMac = data.metadata.dstMac;
      this.appId = `${data.metadata.appId}`.padStart(6, '0x0000'); //converted to hex
      this.simulation = data.simulation == 1 ? true : false;
      let recordStart = new DateNano(data.metadata.start),
        recordEnd = new DateNano(data.metadata.stop);
      this.recordStart = recordStart;
      this.tstart = recordStart.getTime(); // Microseconds
      this.tend = recordEnd.getTime(); // Microseconds
      this.recordDuration = Math.round(this.tend / 1e3 - this.tstart / 1e3);

      this.fileName = data.filename;
      this.fileSize = data.length / (8 * 1024 * 1024);
    },
    xhrDataRequestBinary(requestUrl, cb) {
      var oReq = new XMLHttpRequest();
      oReq.open('GET', requestUrl, true);
      oReq.responseType = 'arraybuffer';

      oReq.onload = function (oEvent) {
        var arrayBuffer = oReq.response; // Note: not oReq.responseText
        if (arrayBuffer) {
          var byteArray = new Uint8Array(arrayBuffer);
          cb(false, byteArray);
        }
      };

      oReq.onerror = function (err) {
        cb(err);
      };

      oReq.send();
    },
    xhrDataRequestGeneral(requestUrl, cb) {
      $.ajax({
        url: requestUrl,
        success: cb,
      });
    },
    addPoint(point) {
      dev.log('addPoint', point);
      if (this.point1 == null) {
        this.point1 = point;
        this.onfirstpointadded();
      } else if (this.point2 == null) {
        this.point2 = point;
        this.calculateDeltaT();
        this.ondeltacalculated(this.point1, this.point2, this.deltaT);
      } else {
        this.reset();
        this.addPoint(point);
      }
    },
    calculateDeltaT() {
      this.deltaT = this.point2 - this.point1;
    },
    reset() {
      this.point1 = null;
      this.point2 = null;
      this.deltaT = null;
      this.onreset();
    },
    onfirstpointadded() {
      let time = new DateNano(this.point1);
      $('#timer-input-t1').val(time.customFormat('#hhhh#:#mm#:#ss#.#ms##us#'));
      $('#timer-input-t1-group .input-group-text').addClass('border-success text-light bg-success');
      $('#timer-input-t1').addClass('border-success');
    },
    ondeltacalculated(t1, t2, dt) {
      dev.log(t1, t2, dt);
      let time1 = new DateNano(t1),
        time2 = new DateNano(t2),
        dtlabel =
          dt < 1e3 && dt > -1e3
            ? `${dt}us`
            : dt < 1e6 && dt > -1e6
            ? `${Math.trunc(dt / 1e3)} ms, ${Math.abs(dt % 1e3)} us`
            : `${Math.trunc(dt / 1e6)}.${`${Math.abs(dt % 1e6)}`.padStart(6, '0')} s`;

      if (time1.customFormat('#DD#.#MM#.#YYYY#') != time2.customFormat('#DD#.#MM#.#YYYY#')) {
        $('#timer-input-t1').val(time1.customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#'));
        $('#timer-input-t2').val(time2.customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#'));
      } else {
        $('#timer-input-t2').val(time2.customFormat('#hhhh#:#mm#:#ss#.#ms##us#'));
      }
      $('#timer-input-t2-group .input-group-text').addClass('border-success text-light bg-success');
      $('#timer-input-t2').addClass('border-success');
      $('#timer-input-dt').val(dtlabel);
      $('#timer-input-dt-group .input-group-text').addClass('border-success text-light bg-success');
      $('#timer-input-dt').addClass('border-success');
    },
    onreset() {
      $('#timer-input-t1').val(0);
      $('#timer-input-t2').val(0);
      $('#timer-input-dt').val(0);
      let selector = ['t1', 't2', 'dt'].reduce(
        (s, i) => (s += `#timer-input-${i}-group span,#timer-input-${i}-group input,`),
        ''
      );
      $(selector.substring(0, selector.length - 1)).removeClass('border-success text-light bg-success');
    },
    /**
     * Generates XHR request to get analog fault records from the server for the ts {Timestamp} of the specifiend event e.
     * @param {TPWS_EVENT_TYPE} e Event
     * @returns {[]} List of analog signals
     */
    getRecordsHeaders(e, cb) {
      var recordsHeaders = [];
      var eventTimeStamp = Math.floor(e.ts / 1000);
      var reqUrl = '/api/projects/' + tpws.projectName + '/oscs?t=' + eventTimeStamp;

      xhrDataRequestGeneral(reqUrl, function (recordsArr) {
        recordsHeaders = recordsArr.map(function (record) {
          return new recordHeaderData(record);
        });

        cb(recordsHeaders);
      });
    },
    tpui_pad(num, size) {
      var s = num + '';
      while (s.length < size) s = '0' + s;
      return s;
    },
    tpui_faltprofile_partseDtLabel(dt) {
      let label = '';
      let sign = dt < 0 ? -1 : 1;
      let signStr = sign == -1 ? '-' : '';
      let absDt = sign * dt;
      if (absDt < 1e3) {
        label = signStr + absDt + ' us';
      } else if (absDt < 1e6) {
        let ms = Math.floor(absDt / 1000);
        let us = absDt % 1000;
        label = signStr + ms + ' ms, ' + us + ' us';
      } else {
        let ss = Math.floor(absDt / 1e6);
        let us = this.tpui_pad(absDt % 1e6, 6);
        label = signStr + ss + '.' + us + ' s';
      }
      return label;
    },
    sortDataByTime(a, b) {
      if (a[0] === b[0]) {
        return 0;
      } else {
        return a[0] < b[0] ? -1 : 1;
      }
    },
    displayRecordsGraphs() {
      for (streamId in this.eventData) {
        var g;

        var svData = this.eventData[streamId];
        var svPointsValues = svData.points.values;
        var svQualityValues = svData.points.quality;
        svPointsValues.sort(sortDataByTime);
        svQualityValues.sort(sortDataByTime);

        if (!this.separeteUI) {
          // Draw U and I on one timeline

          var svDataMask = svData.dataMask;
          var signalPathsArr = svDataMask.map(function (signal, idx) {
            return signal.dataName;
          });
          var signalNamesArr = signalPathsArr.map(function (signal) {
            let signalArr = signal.split('$');
            signalArr.splice(0, 1);

            return signalArr.join('/');
          });

          var labelsArr = signalNamesArr.slice(0); // Simply cloning the array, to keep orignal arr for further use.
          labelsArr.unshift('t');

          var recordContainerId = prepareRecordContainer(this.recordsContainerId, streamId);

          //function legendFormatter(data) {
          //    console.log("Legent formatter data: ",data);
          //    if (data.x == null) return '';  // no selection
          //    return data.xHTML + data.series.map(v => v.labelHTML + ': ' + v.yHTML).join(' ');
          //}

          g = new Dygraph(document.getElementById(recordContainerId), svPointsValues, {
            //visibility: [true, false, true, false, true, false, false, false, false, false, false, false, false, false, false, false],
            dateWindow: [this.tstart, this.tend],
            labels: labelsArr,
            legend: 'follow',
            //legendFormatter: legendFormatter,
            hideOverlayOnMouseOut: true,
            pointClickCallback: this.pointClick,
            //stepPlot: true,
            highlightCircleSize: 2,
            strokeWidth: 1,
            strokeBorderWidth: 1,
            highlightSeriesOpts: {
              strokeWidth: 2,
              strokeBorderWidth: 1,
              highlightCircleSize: 3,
            },
            axes: {
              x: {
                drawAxis: true,
                ticker: this.dateTicker,
                axisLabelFormatter: this.dateAxisLabelFormatter,
              },
              y: {
                axisLabelFormatter: function (y) {
                  return '';
                },
              },
            },
            animatedZooms: false,
          });

          this.timelinesArray.push(g);

          let signalNameLabelsDom = $('<div class="legend-sv-signal-labels" style="display:inline-block;">');

          signalNamesArr.forEach(function (signalName, idx) {
            let signalNameStr = '<span svDatasetSignalIdx="' + idx + '">' + signalName + '</span></br>';

            let signalNameLabelDom = $(signalNameStr).addClass('text-info');

            let local_g = g;
            signalNameLabelDom.on('click', function (label) {
              $(this).toggleClass('text-info');
              local_g.setVisibility(idx, $(this).hasClass('text-info'));
            });

            signalNameLabelsDom.append(signalNameLabelDom);
          });

          var yLabel = $('<span/>', {
            class: 'wh-graph-signal-name',
          });

          yLabel_innerHTML =
            '<ul>' +
            '<li class="legend-ied-name-li">' +
            '<span class="legend-ied-name">' +
            svData.meta.name.split('||')[0] +
            '</span>' +
            '</li>' +
            '<li class="legend-control-block-name-li">' +
            '<span class="legend-control-block-name ' +
            svData.meta.simulation +
            '">' +
            svData.meta.smvId +
            '</span>' +
            '</li>' +
            '<li class="legend-data-object-name-li">' +
            '<span class="legend-data-object-name" id="' +
            svData.meta.name.split('||')[0] +
            svData.meta.smvId +
            '"></span>' +
            '</li>' +
            '</ul>';

          $(yLabel_innerHTML).appendTo(yLabel);

          $('#' + recordContainerId).append(yLabel);

          $('#' + svData.meta.name.split('||')[0] + svData.meta.smvId).append(signalNameLabelsDom);
        } else {
          // Draw U and I separately
        }
      }

      Dygraph.synchronize(this.timelinesArray, {
        selection: false,
        zoom: true,
        range: false,
      });

      $('#graphs').sortable();

      $('#loading').fadeOut();
    },
    /**
     * Prepares div containers for fault records
     * @param {any} parentContainerId ID of parent DIV element that shall contain records
     * @param {any} streamId IF of the record
     */
    prepareRecordContainer(parentContainerId, elementId) {
      $('<div/>', {
        id: elementId,
        class: 'timeline sav',
        style: 'height: 250px;width: 900px; position: relative; padding: 0px 0px 0px 150px',
      }).prependTo(parentContainerId);

      return elementId;
    },
    dev_reshapeSvArray(
      array,
      numOfSingals,
      valQualMask,
      deltaTime = 250,
      timeShift = 1,
      useDerivedTimestamps = false,
      svScaleFactor = [0.001, 0.001, 0.001, 0.001, 0.01, 0.01, 0.01, 0.01]
    ) {
      //console.log('Dev Reshape, timestamps derived: ', useDerivedTimestamps ? "Yes":"No");
      var intermSamplesArr = [],
        intermQualityArr = [];
      var finalSamplesArr = [],
        finalQualityArr = [];

      if (valQualMask.length != svScaleFactor.length) {
        console.warn("svScaleFactor array length doesn't match valQualMask array. Scaling won't be applied");
        svScaleFactor = new Array(valQualMask.length).fill(1);
      }

      var colsNUm = numOfSingals + this.sampleMetadataLength; // 2 stands for two timestamp fields

      while (intermSamplesArr.length < array.length / colsNUm) {
        let line = Array.from(
          array.slice(intermSamplesArr.length * colsNUm, intermSamplesArr.length * colsNUm + colsNUm)
        );
        let samplesLine = [],
          qualityLine = [];

        if (useDerivedTimestamps) {
          line.splice(0, 2);
          line.unshift(intermSamplesArr.length * deltaTime + timeShift); // This introduces time stamp based on smpNum
        } else {
          //console.log(line);
          let timeStamp = line[0] * 1000000 + Math.floor(line[1] / 1000);
          //console.log(timeStamp);
          if (timeStamp == 0) {
            // For lost samples timeStamp is set to 0
            timeStamp = intermSamplesArr.length * deltaTime + timeShift;
            //console.log(timeStamp);
          }

          let sampleQuality = line[2];

          // Remove TPS timestamps (sec and nanosec fields)
          line.splice(0, this.sampleMetadataLength);
          valQualMask.forEach(function (maskElement, idx) {
            samplesLine.push(line[maskElement.dataIdx] * svScaleFactor[idx]);
            if (line[maskElement.qualityIdx] != null) {
              qualityLine.push(line[maskElement.qualityIdx]);
            } else {
              qualityLine.push(null);
            }
          });

          // And add recalculated timestamp
          samplesLine.unshift(timeStamp);
          qualityLine.unshift(timeStamp);
        }

        intermSamplesArr.push(samplesLine);
        intermQualityArr.push(qualityLine);
      }

      // Adds empty fields to the start and the end of the array not to have connections between separate records on the drawing.
      var startLine = [],
        endLine = [];
      startLine.push(timeShift - 1);
      endLine.push(timeShift + (array.length / colsNUm) * deltaTime + 1);
      for (var i = 0; i < valQualMask.length; i++) {
        startLine.push(null);
        endLine.push(null);
      }

      intermSamplesArr.push(endLine);
      intermQualityArr.push(endLine);
      intermSamplesArr.unshift(startLine);
      intermQualityArr.unshift(startLine);

      // Used to make smaller ammounts of data.
      for (var i = 0; i < intermSamplesArr.length; i = i + this.downSampleRate) {
        finalSamplesArr.push(intermSamplesArr[i]);
        finalQualityArr.push(intermQualityArr[i]);
      }

      let reshapedSv = {
        values: finalSamplesArr,
        quality: finalQualityArr,
      };

      //console.log('reshapedSv', reshapedSv);

      return reshapedSv;
    },
    getValQualMask(header) {
      let points = header.svcbInfo.signals.map(function (signal) {
        return signal.path;
      });
      // first get signals (not qualities)
      let signals = points.map(function (point, idx) {
        var res = {
          type: null,
        };
        let pointArr = point.split('$');
        //console.log(point.split('$'));
        pointDaStr = pointArr.splice(0, pointArr.length - 1).join('$');
        if (pointArr[pointArr.length - 1] != 'q') {
          res = {
            type: 'data',
            data: pointDaStr,
          };
        } else {
          points.forEach(function (originalPoint, origIdx) {
            if (origIdx != idx) {
              let subPointArr = originalPoint.split('$');
              let subPointDaStr = subPointArr.splice(0, subPointArr.length - 1).join('$');
              //console.log(subPointDaStr, pointDaStr);
              if (subPointDaStr == pointDaStr)
                res = {
                  type: 'quality',
                  dataIdx: origIdx,
                };
            }
          });
        }
        return res;
      });

      let res = [];

      signals.forEach(function (signal, sIdx) {
        let qualIdx;
        if (signal.type == 'data') {
          signals.forEach(function (subSignal, ssIdx) {
            if (subSignal.type == 'quality' && subSignal.dataIdx == sIdx) {
              qualIdx = ssIdx;
            }
          });
          res.push({
            dataIdx: sIdx,
            dataName: signal.data,
            qualityIdx: qualIdx,
          });
        }
      });

      return res;
    },
    prepareRecordsData(recordData) {
      var headerArr = [];
      var samplesArr = [];
      var isFirstPart = true;
      var splitIdx = recordData.indexOf(0, 1);
      //console.log('splitIdx', splitIdx);
      var svDataStart = (Math.floor(splitIdx / 4) + 1) * 4;
      //console.log('svDataStart', svDataStart);

      var headerU8intArr = new Uint8Array(recordData.buffer.slice(0, splitIdx));
      var utfDecoder = new TextDecoder('utf-8');
      var svHeader = JSON.parse(utfDecoder.decode(headerU8intArr));
      //console.log(svHeader);

      var streamId =
        svHeader.svcbInfo.smvId +
        svHeader.svcbInfo.communication.destmac +
        svHeader.svcbInfo.communication.appid +
        svHeader.svcbInfo.simulation;
      var recordStartTimeUs = svHeader.start.sec * 1e6 + Math.floor(svHeader.start.nano / 1000);

      var iedName = svHeader.svcbInfo.name.split('||')[0];
      var svId = svHeader.svcbInfo.smvId;
      var simulation = svHeader.svcbInfo.simulation;

      var smpRate = svHeader.svcbInfo.smpRate;

      var signalsArr = svHeader.svcbInfo.signals;

      var samples32Arr = new Int32Array(recordData.buffer.slice(svDataStart));

      //console.log(samples32Arr);

      var samplePeriod = 1e6 / (smpRate * this.systemFrequency); // microseconds

      var valQualMask = getValQualMask(svHeader);

      var svArr = dev_reshapeSvArray(samples32Arr, signalsArr.length, valQualMask, samplePeriod, recordStartTimeUs);

      if (this.eventData[streamId] == null) {
        // This data has never been added to the array
        let data = {
          meta: svHeader.svcbInfo,
          points: svArr,
          dataMask: valQualMask,
        };

        this.eventData[streamId] = data;
      } else {
        // This data has already been added, just update the existing array
        var svExistingDataValues = this.eventData[streamId].points.values;
        var svExistingDataQuality = this.eventData[streamId].points.quality;
        svArr.values.forEach(function (sample, idx) {
          svExistingDataValues.push(sample);
          svExistingDataQuality.push(svArr.quality[idx]);
        });

        this.eventData[streamId].points = {
          values: svExistingDataValues,
          quality: svExistingDataQuality,
        };
      }
    },
    tpui_faultprofile_parseTimeLabel(time) {
      let label = '';
      let accessor = this.DateAccessorsUTC;
      let p1 = new this.Date(time);
      // let yy = accessor.getFullYear(p1);
      // let MM = accessor.getMonth(p1);
      // let dd = accessor.getDate(p1);
      // let hh = accessor.getHours(p1);
      // let mm = accessor.getMinutes(p1);
      // let ss = accessor.getSeconds(p1);
      // let ms = accessor.getMilliseconds(p1);
      // let us = accessor.getMicroseconds(p1);
      // label = (yy + '/' + MM + '/' + dd + ' ' + hh + ':' + mm + ':' + ss + '.' + (ms * 1e3 + us))
      // return label
      return p1.customFormat('#hhhh#:#mm#:#ss# #DD#.#MM#.#YYYY#');
    },
    tpuiFaultProfilePointClick(e, x) {
      this.addPoint(x.xval);
    },
    getBinarySignals(e) {
      var binarySignals = [];
      // This is a placeholder for further fault-profile

      return binarySignals;
    },
    devBuildRecordsDataTable(recordsHeaders) {
      $('.jumbotron').hide();
      let faultPrfileDataTableHtml = `\
        <table id="fault-profile-datatable">\
           <thead>\
            <tr><th>${i18next.t('SVID')}</th>\
            <th>${i18next.t('Destination MAC')}</th>\
            <th>${i18next.t('APPID')}</th><th>${i18next.t('Simulation')}</th>\
                <th>${i18next.t('Record Start')}</th>\
            <th>${i18next.t('Record Duration')}</th>\
           </thead>\
        </table>`;

      faultProfileDataTable = $(faultPrfileDataTableHtml);
      $(this.faultProfileDivId).append(faultProfileDataTable);

      //if ($.fn.DataTable.isDataTable('#fault-profile-datatable')) {
      //    devFaultProfileTable = $("#fault-profile-datatable").DataTable();
      //    devFaultProfileTable.destroy();
      //    $('#dev-fault-profile').empty();
      //}
      try {
        devFaultProfileTable.destroy();
      } catch (e) {}
      devFaultProfileTable = $('#fault-profile-datatable').DataTable({
        data: recordsHeaders,
        columns: [
          {
            data: 'svId',
          },
          {
            data: 'dstMac',
          },
          {
            data: 'appId',
          },
          {
            data: 'simulation',
          },
          {
            data: 'recordStart',
          },
          {
            data: 'recordDuration',
          },
          //{ data: 'fileName' },
          //{ data: 'fileSize' },
        ],
        select: true,
      });

      $('#fault-profile-datatable tbody').on('click', 'tr', function () {
        $(this).toggleClass('selected');
        let selectedRowsLength = devFaultProfileTable.rows('.selected').data().length;
        if (selectedRowsLength == 0) {
          $('#view-records-btn').text(i18next.t('View All'));
        } else {
          $('#view-records-btn').text(i18next.t('View Selected'));
        }
      });

      $('#view-records-btn').show();
      $('#view-records-btn').click(function () {
        dev.log('Click');
        $('#loading').show();

        let graphElem = $('#graphs').length ? $('#graphs') : $("<div id='graphs'></div>").appendTo($('#signals-page'));
        $('.error-message-container').hide();

        //$('#dev-console-nav-tabs a[href="#dev-fault-records-tab"]').tab('show')

        let iteratorObject = null;

        initRecordsView(this.recordsContainerId);

        //console.log(devFaultProfileTable)

        let selectedRowsLength = devFaultProfileTable.rows('.selected').data().length;
        if (selectedRowsLength === 0) {
          // Get all data
          iteratorObject = devFaultProfileTable.rows().data();
        } else {
          // Get only selected data
          iteratorObject = devFaultProfileTable.rows('.selected').data();
          devFaultProfileTable.rows('.selected').deselect();
          //console.log(iteratorObject)
        }

        let oscPathsArr = iteratorObject.map(function (header) {
          return '/api/projects/' + tpws.projectName + '/oscfile_tst?filename=' + header.fileName;
        });

        async.map(oscPathsArr, xhrDataRequestBinary, function (err, results) {
          // results is now an array of stats for each file
          if (!err) {
            //console.log(results);
            results.forEach(function (result) {
              try {
                prepareRecordsData(result);
              } catch (e) {
                notifyKas.show({ text: `${i18next.t('Error occured processing fault record')}: ` + e, close: true });
              }
            });
            try {
              displayRecordsGraphs();
            } catch (e) {
              notifyKas.show({ text: `${i18next.t('Could not display fault records')}: ` + e, close: true });
              $('#loading').fadeOut();
            }
          } else {
            $('#loading').hide();
            notifyKas.show({
              text: `${i18next.t('Could not load fault records data, error occured')}: ` + err,
              close: true,
            });
          }
        });

        devFaultProfileTable.destroy();
        faultProfileDataTable.remove();
        devFaultProfileTable = null;
        faultProfileDataTable = null;
        $('#view-records-btn').off('click');
        $('#view-records-btn').hide();

        $(this.faultProfileDivId).empty();

        //console.log(devFaultProfileTable, faultProfileDataTable);
      });
    },
    generateFaultProfile(e) {
      this.getRecordsHeaders(e, function (recordsHeaders) {
        var matchingHeaders = [];

        // Append only records crossing the selected fault profile window (for cases when server responses too much headers from different time intervals)
        recordsHeaders.forEach(function (header, idx) {
          //console.log(header, header.tstart , this.tend, header.tend , this.tstart);
          if (header.tstart < this.tend && header.tend > this.tstart) matchingHeaders.push(header);
        });
        $(this.faultProfileDivId).empty();
        $('#view-records-btn').hide();
        if (matchingHeaders.length > 0) {
          this.devBuildRecordsDataTable(matchingHeaders);
          let recordsAvailableMessage = $(`<div class="alert alert-info alert-dismissible" role="alert">\
            <span class="fal fa-bolt" aria-hidden="true"></span> ${i18next.t(
              'There are records of Sampled Analog Values available for this timelines. Would you like to view them as well?'
            )}\
            <button class="btn btn-primary" data-dismiss="alert" onclick="$(\'#fault-profile-modal\').modal(\'show\')">${i18next.t(
              'Yes'
            )}</button>&nbsp<button class="btn btn-default" class="close" data-dismiss="alert" aria-label="Close">${i18next.t(
            'No'
          )}</button>\
            </div>`);

          //console.log(matchingHeaders)

          //$('#signals-page').prepend(recordsAvailableMessage);
        }
      });

      //binarySignals = getBinarySignals(e);
    },
  },
});
