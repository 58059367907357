import api from '@/services/api';
import { acceptHMRUpdate, defineStore } from 'pinia';

import { i18next } from '@/root.app';
import { useTpws } from '@/stores/tpws';
import { useVisual } from '@/stores/visual';
import eventBus from '@/utils/eventBus';

export const useReports = defineStore({
  id: 'reports',
  state: () => {
    return {
      waitPendingReports: [],
      pendingReports: [],
      preparedReports: [],
      preparedReportsVisible: false,
    };
  },
  getters: {
    hasPendingReports: (state) => {
      return state.pendingReports.length > 0;
    },
    // pendingReports: (state) => {
    //   return state.pendingReports;
    // },
    hasWaitingReports: (state) => {
      return state.waitPendingReports.length > 0;
    },
    // pendingReports: (state) => {
    //   return state.pendingReports;
    // },
    preparedReportsCount: (state) => {
      return (state.preparedReports && state.preparedReports.length) || 0;
    },
  },
  actions: {
    startWaitingReport(fname) {
      this.waitPendingReports.push(fname);
    },
    stopWaitingReport(fname) {
      this.waitPendingReports = this.waitPendingReports.filter(el=>(el !== fname));
    },
    async loadPreparedReports() {
      const tpws = useTpws();
      const visual = useVisual();
      if (tpws.currentProject) {
        const {
          data: { files },
        } = await api.get(`/projects/${tpws.currentProject}/get-project-reports`);

        visual.preparedReportsModalVisible = false;
        this.preparedReports = files;
        this.pendingReports = [];
      }
    },
    async removePreparedReport({ filename }) {
      const tpws = useTpws();
      const {
        data: { files },
      } = await api.post(`/projects/${tpws.currentProject}/remove-report`, { filename });

      this.preparedReports = files;
    },

    startPending(value) {
      this.stopWaitingReport(value.filename);
      this.pendingReports.push(value);
    },
    progressPending(value) {
      const foundPendingReport = this.pendingReports.find((el) => el.id === value.id);
      if (foundPendingReport) {
        foundPendingReport.progress = value.progress;
      }
    },
    errorPending(value) {
      this.pendingReports = this.pendingReports.filter((el) => el.id !== value.id);
      if (value.popup || value.notifyBoth) {
        eventBus.dispatch('message', {
          message: i18next.t('New report failed'),
          close: true,
          type: 'danger',
        });
      }
    },
    finishPending(value) {
      this.pendingReports = this.pendingReports.filter((el) => el.id !== value.id);
      this.preparedReports.push(value);
      if (value.popup || value.notifyBoth) {
        eventBus.dispatch('message', {
          message: i18next.t('New report prepared'),
          close: true,
          type: 'info',
        });
      }
      if (!value.popup || value.notifyBoth) {
        window.dispatchEvent(new Event('reloadNotify'));
      }
    },
    showPreparedReports(value) {
      dev.log('showPreparedReports');
      const visual = useVisual();
      visual.preparedReportsVisible = value;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReports, import.meta.hot));
}
