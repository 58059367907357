<template>
  <div class="lvb-table">
    <div class="card-project">
      <div id="lvb-header">
        <h5 class="card-title project-page__card-title">
          <div class="card-project__title-text">{{ $t('LVBs list') }}</div>
          <div class="d-flex align-items-center">
            <div v-if="adm" class="ui-button pointer-cursor" @click="addNewLvb">
              <div class="ui-button__icon--no-label">
                <v-svg fill="#1757BD" src="add"></v-svg>
              </div>
            </div>
            <a
              v-if="commissioning && enableSoftwareLVB"
              class="ui-button pointer-cursor"
              :href="'/projects/' + currentProject + '/lvb'"
              role="button"
              style="margin-left: 16px"
            >
              <div class="ui-button__icon--no-label">
                <v-svg scr="download" fill="#1757BD" width="24" height="24"></v-svg>
              </div>
            </a>
          </div>
        </h5>
      </div>
      <div class="card-body">
        <!--        <n-data-table :columns="columns" :data="tableData"></n-data-table>-->
        <el-table id="lvbTable" :data="tableData" stripe style="width: 100%">
          <el-table-column prop="lvb_id_str" :label="$t('Name')" sortable> </el-table-column>
          <el-table-column prop="description" :label="$t('Description')"> </el-table-column>
          <el-table-column prop="type" :label="$t('Type')">
            <template #default="scope">
              {{ scope.row.type === '1' ? 'GOOSE/SV' : 'MMS' }}
            </template>
          </el-table-column>
          <el-table-column prop="rack" :label="$t('Rack')"> </el-table-column>
          <el-table-column prop="location" :label="$t('Location')"> </el-table-column>
          <el-table-column prop="ip" :label="$t('IP')"> </el-table-column>
          <el-table-column label="" width="80">
            <template #default="scope">
              <span class="pointer" style="margin-right: 10px" @click="openInfoLvb(scope.row)">
                <v-svg src="info" fill="#1757BD"></v-svg>
              </span>
              <span class="pointer" @click="openUpdateLvb(scope.row)">
                <v-svg src="edit" fill="#1757BD"></v-svg>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog v-model="visibleDialog" width="50%" :show-close="false" class="noHeaderBlock">
          <template #header>
            <slot name="title"></slot>
          </template>
          <template #default>
            <div class="modal-window__layout">
              <div class="modal-window__main">
                <div class="modal-window__header modal-window__header--top-align">
                  <div class="modal-header__panel">
                    <div class="header-title">
                      <div class="header-title__text">
                        <div v-if="statusLvb === 'update'">{{ $t('Editing') }} {{ selectLvb.lvb_id_str }}</div>
                        <div v-else-if="statusLvb === 'info'">{{ selectLvb.lvb_id_str }}</div>
                        <div v-else>{{ $t('Add LVB') }}</div>
                      </div>
                      <div class="header-title__buttons"></div>
                    </div>
                  </div>
                  <div @click="closeModal" class="modal-header__close-btn pointer-cursor">
                    <img src="/images/modal-close-crossline-black.svg" loading="lazy" alt="" />
                  </div>
                </div>
                <hr class="modal-window__hr" />
                <div class="modal-window__body">
                  <div class="ui-text-block">
                    <div class="lvb-table__modal-label">{{ $t('Description') }}</div>
                    <div class="ui-text-block__body">
                      <div class="ui-input">
                        <input
                          :disabled="statusLvb === 'info'"
                          v-model="selectLvb.description"
                          type="text"
                          class="ui-input-input"
                          :placeholder="$t('Description')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="ui-text-block">
                    <div class="lvb-table__modal-label">{{ $t('Type') }}</div>
                    <el-select
                      :disabled="statusLvb === 'info'"
                      v-model="selectLvb.type"
                      :placeholder="$t('Type')"
                      style="width: 100%"
                    >
                      <el-option v-for="type in types" :key="type.value" :label="type.label" :value="type.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="ui-text-block">
                    <div class="lvb-table__modal-label">{{ $t('Rack') }}</div>
                    <div class="ui-text-block__body">
                      <div class="ui-input">
                        <input
                          :disabled="statusLvb === 'info'"
                          v-model="selectLvb.rack"
                          type="text"
                          class="ui-input-input"
                          :placeholder="$t('Rack')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="ui-text-block">
                    <div class="lvb-table__modal-label">{{ $t('Location') }}</div>
                    <div class="ui-text-block__body">
                      <div class="ui-input">
                        <input
                          v-model="selectLvb.location"
                          :disabled="statusLvb === 'info'"
                          type="text"
                          class="ui-input-input"
                          :placeholder="$t('Location')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="ui-text-block">
                    <div class="lvb-table__modal-label">{{ $t('IP') }}</div>
                    <div class="ui-text-block__body">
                      <div class="ui-input">
                        <input
                          v-model="selectLvb.ip"
                          :disabled="statusLvb === 'info'"
                          type="text"
                          class="ui-input-input"
                          :placeholder="$t('IP')"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-window__actions">
                  <div class="modal-actions">
                    <div class="modal-actions__col"></div>
                    <div class="modal-actions__col">
                      <div class="d-flex" v-if="statusLvb === 'update'">
                        <div style="margin-right: 16px" @click="closeModal" class="ui-button pointer-cursor">
                          <div class="ui-button__lable">{{ $t('Cancel') }}</div>
                        </div>
                        <div class="ui-button ui-button--active pointer-cursor" @click="updateLvb">
                          <div class="ui-button__lable ui-button__lable--active">
                            {{ $t('Save') }}
                          </div>
                        </div>
                      </div>
                      <div @click="switchUpdateLvb" class="ui-button pointer-cursor" v-if="statusLvb === 'info'">
                        <div class="ui-button__icon">
                          <v-svg src="edit" fill="#1757BD"></v-svg>
                        </div>
                        <div class="ui-button__lable">{{ $t('Edit') }}</div>
                      </div>
                      <div class="d-flex" v-if="statusLvb === 'create'">
                        <div style="margin-right: 16px" @click="closeModal" class="ui-button pointer-cursor">
                          <div class="ui-button__lable">{{ $t('Cancel') }}</div>
                        </div>
                        <div class="ui-button ui-button--active pointer-cursor" @click="createLvb">
                          <div class="ui-button__lable ui-button__lable--active">
                            {{ $t('Add') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { NDataTable, useMessage } from 'naive-ui';
import { mapStores } from 'pinia';
import { useTpws } from '@/stores/tpws';
import { usePage } from '@/stores/page';
export default {
  data() {
    return {
      // adm: JSON.parse(this.$parent.adm),
      // commissioning: JSON.parse(this.$parent.commissioning),
      // enableSoftwareLVB: JSON.parse(this.$parent.enable),
      columns: ['lvb_id_str', 'description'],
      visibleDialog: false,
      test: false,
      types: [
        {
          label: 'GOOSE/SV',
          value: '1',
        },
        {
          label: 'MMS',
          value: '2',
        },
      ],
      statusLvb: '',
      selectLvb: {
        lvb_id_str: '',
        description: '',
        type: '',
        rack: '',
        location: '',
        ip: '',
      },
      tableData: [],
    };
  },
  components: {
    NDataTable,
  },
  props: ['dataset'],
  computed: {
    ...mapStores(useTpws),
    ...mapStores(usePage),
    currentProject() {
      return this.tpws.currentProject;
    },
    adm() {
      return this.page?.currentPage?.adm;
    },
    commissioning() {
      return this.page?.currentPage?.commissioning;
    },
    enableSoftwareLVB() {
      return this.page?.currentPage?.enableSoftwareLVB;
    },
    heightTable() {
      if (this.test) {
        const heightScreen = document.documentElement.clientHeight;
        const widthScreen = document.documentElement.clientWidth;

        if (widthScreen > 1259) {
          return `${heightScreen - 483}`;
        }

        return `${heightScreen - 548}`;
      }
      return '555';
    },
  },
  methods: {
    closeModal() {
      this.visibleDialog = false;
    },
    openModal(row) {
      this.visibleDialog = true;
      if (row) {
        this.selectLvb = { ...row };
      } else
        this.selectLvb = {
          lvb_id_str: '',
          description: '',
          type: '',
          rack: '',
          location: '',
          ip: '',
        };
    },
    openInfoLvb(row) {
      this.openModal(row);
      this.statusLvb = 'info';
      // console.log(row);
    },
    openUpdateLvb(row) {
      this.openModal(row);
      this.statusLvb = 'update';
    },
    addNewLvb() {
      this.openModal();
      this.statusLvb = 'create';
    },
    switchUpdateLvb() {
      this.statusLvb = 'update';
      // console.log('update');
    },
    async createLvb() {
      const res = await api.get(
        `/projects/${this.currentProject}/assign-new-lvb?description=${this.selectLvb.description}&type=${this.selectLvb.type}&rack=${this.selectLvb.rack}&location=${this.selectLvb.location}&ip=${this.selectLvb.ip}`
      );
      if (res.statusText === 'OK') {
        this.fetchDataTable();
        this.closeModal();
      }
    },
    async updateLvb() {
      const res = await api.post(`/projects/${this.currentProject}/update-lvb-list`, this.selectLvb);
      if (res.statusText === 'OK') {
        this.fetchDataTable();
        this.closeModal();
      }
    },
    handleDelete(index, row) {
      // console.log(index, row);
    },
    async fetchDataTable() {
      const res = await api.get(`/projects/${this.currentProject}/get-lvb-list`);
      if (res.statusText === 'OK') {
        this.tableData = res.data.desc;
      } else this.tableData = [];
      // console.log('req', res);
    },
  },
  async mounted() {
    await this.fetchDataTable();
    this.test = true;
  },
};
</script>

<style lang="scss">
.card-body {
  padding: 40px 20px;
}
.lvb-table {
  &__modal-label {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #121314;
  }
  &__modal-input {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #121314;
  }
}
.ui-button__lable {
  margin: 0;
}
.header-title__text {
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  color: #1757bd;
}
.modal-window__hr {
  margin: 0 0 32px 0;
}
.modal-window__header--top-align {
  margin-bottom: 12px;
}
.ui-text-block {
  margin-bottom: 32px;
}
input:disabled {
  background-color: transparent;
  cursor: not-allowed;
}
input::-webkit-input-placeholder {
  color: #c0c4cc;
}
</style>

<style>
.el-input__inner {
  color: black !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: rgba(23, 87, 189, 0.1) !important;
  border-radius: 4px !important;
}
.el-select .el-input.is-disabled .el-input__inner {
  background-color: transparent;
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: rgba(23, 87, 189, 0.1) !important;
}
</style>
