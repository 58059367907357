<template>
  <div class="editable-text">
    <label class="editable-text__label">{{ label }}:</label>&nbsp;
    <template v-if="!edit">
      <span :class="textClass">{{ textVal }}</span
      >&nbsp;<i class="el-icon-edit" @click="startEdit"></i
    ></template>
    <template v-else>
      <template v-if="textArea">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          ref="edImpAr"
          size="small"
          :placeholder="label"
          v-model="textVal"
          @blur="emitChange"
        ></el-input>
      </template>
      <template v-else>
        <el-input ref="edImp" size="small" :placeholder="label" v-model="textVal" @blur="emitChange"> </el-input>
      </template>
      <el-button type="success" size="small" icon="el-icon-check" @click="emitChange"></el-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'editable-text',
  props: {
    label: {
      type: String,
    },
    text: {
      type: String,
    },
    textArea: {
      type: Boolean,
    },
    textClass: {
      type: String,
    },
  },
  data: () => {
    return {
      edit: false,
      textVal: '',
    };
  },
  mounted() {
    this.textVal = this.text;
  },
  methods: {
    startEdit() {
      this.edit = true;
      this.$nextTick(() => {
        if (this.textArea) {
          this.$refs.edImpAr.focus();
        } else {
          this.$refs.edImp.focus();
        }
      });
    },
    emitChange(e) {
      this.$emit('change', this.textVal);
      this.edit = false;
    },
  },
  watch: {
    text: function (val) {
      this.textVal = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-text {
  display: flex;
  &__label {
    //font-size: 16px;
    margin-right: 10px;
  }
}
.el-icon-edit {
  margin-left: 10px;
}
.el-button {
  margin-left: 10px;
}
.el-input {
  //width: 80%;
  flex: 1;
}
.el-textarea {
  width: 80%;
}
</style>
