<template>
  <modal-dialog
    :modal="modal"
    v-show="wheel.visibleSelectSubnetModal"
    v-model:visible="wheel.visibleSelectSubnetModal"
    ref="modEx"
    @closeModal="closeModal"
  >
    <template #title>
      <span class="subnet-select-modal__title">{{ $t('chooseSubnetwork.message', { subnetsNum: list?.length }) }}</span>
    </template>
    <template #content>
      <div class="subnet-selector-container">
        <ul class="subnet-selector-ul">
          <li v-for="subnet in list" :key="subnet.name">
            <input
              type="radio"
              class="subnet-selector-radio"
              name="subnet-selector-radio"
              v-model="selected"
              :value="subnet.name"
              :id="subnet.name"
            />
            <label class="subnet-selector-label" :for="subnet.name">
              <span class="subnet-selector-name">{{ subnet.name }}</span>
              <span class="subnet-selector-desc">
                {{ $t('Type') }}: <strong>{{ subnet.type }}</strong
                >, {{ $t('connected IEDs') }}: <strong>{{ subnet.ieds }}</strong
                >, {{ $t('configured GOOSE') }}: <strong>{{ subnet.gooses }}</strong
                >, {{ $t('configured SV') }}: <strong>{{ subnet.svs }}</strong>
              </span>
            </label>
            <div class="subnet-selector-check"></div>
          </li>
        </ul>
      </div>
    </template>
    <template #buttons>
      <button class="btn btn-secondary" type="button" @click="confirmSelect">
        {{ $t('Select') }}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modals/ModalDialog.vue';
import { mapActions } from 'pinia';
import { useWheel } from '@/stores/wheel';
import { mapStores } from 'pinia';

export default {
  name: 'select-subnet-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
    },
    selectedSubnet: {
      type: String,
    },
  },
  components: { ModalDialog },
  data: () => {
    return {
      modal: {
        name: 'select-subnet',
        title: 'Select subnet',
        closeBtn: 'Close',
        noSubmit: true,
        size: '800',
        noTitle: false,
        noCross: true,
      },
      selected: undefined,
    };
  },
  computed: {
    ...mapStores(useWheel),
  },
  mounted() {},
  methods: {
    ...mapActions(useWheel, ['hideSelectSubnetModal']),
    closeModal() {
      dev.log('closeModal');
      this.hideSelectSubnetModal();
    },
    confirmSelect() {
      dev.log('closeModal');
      this.$emit('select', this.selected);
      this.hideSelectSubnetModal();
    },
  },
  watch: {
    'visual.visibleSelectSubnetModal': {
      handler(val) {
        if (val) {
          // this.loadLicense();
          this.selected = this.selectedSubnet;
        } else {
          this.$emit('update:visibleModal', val);
        }
      },
      // deep: true
    },
    selectedSubnet: {
      handler(val) {
        if (val) {
          // this.loadLicense();
          this.selected = val;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
h5 .subnet-select-modal__title {
  font-size: 1.1em;
  line-height: 1.5em;
}
</style>
<style lang="scss"></style>
