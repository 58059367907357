<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8691 6.10327L15.1222 6.26255C15.6218 6.32291 16 6.74836 16 7.25309V8.74691C16 9.25091 15.6218 9.67709 15.12 9.73745L13.8691 9.89673C13.7658 10.2145 13.6385 10.5215 13.4895 10.8131L14.2633 11.8095C14.5767 12.2138 14.5404 12.7825 14.1789 13.1331L13.1295 14.1825C12.7818 14.5396 12.2131 14.5767 11.8095 14.2633L10.8131 13.4895C10.5215 13.6385 10.2145 13.7658 9.89673 13.8691L9.73745 15.1222C9.67782 15.6218 9.25164 16 8.74691 16H7.25309C6.74836 16 6.32291 15.6218 6.26255 15.1207L6.10327 13.8698C5.78546 13.7665 5.47855 13.6393 5.18691 13.4902L4.19055 14.264C3.78691 14.5767 3.21818 14.5404 2.86764 14.1796L1.81745 13.1295C1.46036 12.7818 1.42327 12.2131 1.73673 11.8095L2.51055 10.8131C2.36145 10.5215 2.23418 10.2145 2.13091 9.89673L0.877818 9.73745C0.378182 9.67782 0 9.25164 0 8.74691V7.25309C0 6.74836 0.378182 6.32291 0.88 6.26255L2.13091 6.104C2.23418 5.78618 2.36145 5.47927 2.51055 5.18764L1.73673 4.19127C1.42327 3.78691 1.45964 3.21818 1.82109 2.86764L2.87055 1.81673C3.21818 1.45964 3.78764 1.42327 4.19055 1.736L5.18691 2.50982C5.47855 2.36145 5.78473 2.23418 6.10327 2.13091L6.26255 0.877818C6.32218 0.378182 6.74836 0 7.25309 0H8.74691C9.25164 0 9.67709 0.378182 9.73818 0.880727L9.89745 2.13164C10.216 2.23491 10.5222 2.36145 10.8138 2.51055L11.8095 1.73673C12.2138 1.42327 12.7825 1.45964 13.1331 1.82109L14.1825 2.87055C14.5396 3.21818 14.5767 3.78691 14.2633 4.19055L13.4895 5.18691C13.6385 5.47855 13.7658 5.78546 13.8691 6.10327ZM11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'settings-cog',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
