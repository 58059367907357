<template>
  <div class="top-nav">
    <div class="top-nav-elm">
      <div class="top-nav-elm__icon">
        <img src="/v-icons/menu.svg" loading="lazy" alt="" />
      </div>
    </div>
    <div class="top-nav-elm">
      <div class="top-nav-elm__icon">
        <img src="/images/warning.svg" loading="lazy" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'main-top-nav',
};
</script>

<style scoped></style>
