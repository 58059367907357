<template>
  <div>
    <div class="t-icon" :class="['t-icon__' + size + 'px', 't-icon__' + icon]"></div>
  </div>
</template>

<script>
export default {
  name: 't-icon',
  props: {
    icon: {
      type: String,
    },
    size: {
      type: Number,
    },
  },
};
</script>

<style scoped></style>
