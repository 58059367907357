import api from '@/services/api';
import { useWheelSession } from '@/stores/wheelSession';
import { defineStore } from 'pinia';

export const useWheelEventsCounter = defineStore({
  id: 'wheelEventsCounter',
  state: () => {
    return {
      visiblity: false,
      interval: 60,
      lastRecievedEventsTs: undefined,
      timeString: '',
      context: undefined,
    };
  },

  actions: {
    initWheelEventsCounter() {
      const wheelSession = useWheelSession();
      this.visiblity = wheelSession.eventsCounterVisibility === false ? wheelSession.eventsCounterVisibility : true;
      this.interval = wheelSession.eventsCounterPeriod ? wheelSession.eventsCounterPeriod : 60;
    },
    toggleBadges() {
      const wheelSession = useWheelSession();
      this.visiblity = !this.visiblity;
      wheelSession.saveProp('eventsCounterVisibility', this.visiblity);
    },
  },
});
