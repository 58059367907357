<template>
  <div class="card" style="width: 18rem">
    <div class="card-body">
      <h5 class="card-title">Фильтр</h5>
      <p class="card-text"><slot></slot></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sidebar-filter',
};
</script>

<style scoped></style>
