import { markRaw as mr } from 'vue/dist/vue.esm-bundler';

export const isObject = (val) => {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
};

export const markRaw = (val) => {
  if (isObject(val)) {
    return mr(val);
  } else {
    return val;
  }
};
// export default eventBus;
