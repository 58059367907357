<template>
  <error-page :error-details="license?.licenseStatus" image="goose-555.svg">
    <template #errorCode>{{ errorCode }}</template>
    <template #errorTitle>{{ errorTitle }}</template>
    <template #errorDescription>{{ $t(errorDescription) }}</template>
  </error-page>
</template>

<script>
import ErrorPage from '@/components/pages/ErrorPage.vue';
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useAuth } from '@/stores/auth';
import { usePage } from '@/stores/page';
import { useLicense } from '@/stores/license';
import { mapActions } from 'pinia';
import eventBus from '@/utils/eventBus';

export default {
  name: 'license-problem',
  components: {
    ErrorPage,
  },
  data: () => {
    return {
      licenseStatus: {},
      password: '',
      password_confirm: '',
    };
  },
  computed: {
    ...mapStores(usePage),
    ...mapStores(useLicense),
    errorCode() {
      return this.license?.licenseStatus?.code
    },
    errorTitle() {
      return this.license?.licenseStatus?.status === 'ERROR' ? this.$t('License problem') : this.license?.licenseStatus?.status
    },
    errorDescription() {
      return this.license?.licenseStatus?.desc
    },

  },
  mounted() {},
  unmounted() {},
  methods: {
  },
  watch:{
    errorCode: {
      handler(newValue) {
        if (!newValue) {
          this.$router.push({name: 'client-root'});
        }
      },
      immediate: true,
    },

  }
};
</script>

<style lang="scss"></style>
