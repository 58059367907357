<template>
  <div class="signal-conponent">
    <div class="signals-align-sidebar">
      <div class="signal-ident">
        <div class="signal-ident__values">
          <div class="signal-ident__prop"><div>IED</div></div>
          <div class="signal-ident__val link-color" :title="signalObjectName" @click="showInfo">
            {{ signalObjectName }}
          </div>
          <!--          <button @click="showInfo">click</button>-->
          <signal-info :visibleDialog.sync="visibleSignalInfo">
            <template #title
              ><div class="modal-window__header modal-window__header--no-margin-bottom">
                <div class="modal-header__title">
                  <div class="signal-ident-title">
                    <div class="signal-ident__values">
                      <div class="signal-ident__prop"><div>IED</div></div>
                      <div class="signal-ident__val link"><div>PROT/PTOC6$Str</div></div>
                    </div>
                  </div>
                </div>
                <div class="modal-header__close-btn">
                  <div class="empty"></div>
                </div></div
            ></template>
            <div class="modal-window__body">
              <div class="page-section">
                <div class="page-section__body">
                  <div class="ident-tags-block__list m-b-16">
                    <div class="tags-list">
                      <div class="tags-list__body">
                        <div class="ident-tag-element">
                          <div class="tag-element__body">
                            <div class="tag-element__label"><div>7SJ801</div></div>
                          </div>
                        </div>
                        <div class="ident-tag-element">
                          <div class="tag-element__body">
                            <div class="tag-element__icon">
                              <img src="/images/loud-speaker-1.svg" loading="lazy" alt="" />
                            </div>
                            <div class="tag-element__label"><div>Control</div></div>
                          </div>
                        </div>
                        <div class="ident-tag-element">
                          <div class="tag-element__body">
                            <div class="tag-element__icon">
                              <img src="/images/database-2.svg" loading="lazy" alt="" />
                            </div>
                            <div class="tag-element__label"><div>PROT</div></div>
                          </div>
                        </div>
                        <div class="ident-tag-element">
                          <div class="tag-element__body">
                            <div class="tag-element__label"><div>Protection, DMT</div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ui-cols">
                    <div class="ui-col">
                      <div class="ui-text-block">
                        <div class="ui-text-block__label"><div>время</div></div>
                        <div class="ui-text-block__body"><div>14 ч 34 мин 12.120 сек</div></div>
                      </div>
                    </div>
                    <div class="ui-col">
                      <div class="ui-text-block">
                        <div class="ui-text-block__label"><div>качество сигнала</div></div>
                        <div class="ui-text-block__body"><div>Среднее</div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-section">
                <div class="page-section__title"><div>уведомления</div></div>
                <div class="page-section__body">
                  <div class="signals-notifications">
                    <div class="signals-notifications__item m-b-4">
                      <div class="signals-notifications-item">
                        <div class="signals-notifications-item__icon">
                          <img src="/images/warning-cont.svg" loading="lazy" alt="" />
                        </div>
                        <div class="signals-notifications-item__text">
                          <div>
                            Сообщения с <span class="message-link">Siemens1</span> и
                            <span class="message-link">Siemens2</span> были потеряны
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="signals-notifications__item">
                      <div class="signals-notifications-item">
                        <div class="signals-notifications-item__icon">
                          <img src="/images/warning-cont.svg" loading="lazy" alt="" />
                        </div>
                        <div class="signals-notifications-item__text">
                          <div><span class="message-link">Siemens3</span> отправил 3 сообщения</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-section">
                <div class="page-section__title"><div>атрибуты</div></div>
                <div class="page-section__body m-b-16">
                  <div class="signal-props-table">
                    <div class="signal-props-table__row signal-props-table__row--header">
                      <div class="signal-props-table__cell"><div>Название</div></div>
                      <div class="signal-props-table__cell"><div>значение</div></div>
                    </div>
                    <div class="signal-props-table__row gray-bg">
                      <div class="signal-props-table__cell"><div>general</div></div>
                      <div class="signal-props-table__cell"><div>785</div></div>
                    </div>
                    <div class="signal-props-table__row">
                      <div class="signal-props-table__cell"><div>dirGeneral</div></div>
                      <div class="signal-props-table__cell"><div>знач</div></div>
                    </div>
                    <div class="signal-props-table__row gray-bg">
                      <div class="signal-props-table__cell"><div>phsA</div></div>
                      <div class="signal-props-table__cell"><div>785</div></div>
                    </div>
                    <div class="signal-props-table__row">
                      <div class="signal-props-table__cell"><div>dirPhsA</div></div>
                      <div class="signal-props-table__cell"><div>знач</div></div>
                    </div>
                    <div class="signal-props-table__row gray-bg">
                      <div class="signal-props-table__cell"><div>phsB</div></div>
                      <div class="signal-props-table__cell"><div>785</div></div>
                    </div>
                    <div class="signal-props-table__row">
                      <div class="signal-props-table__cell"><div>dirPhsB</div></div>
                      <div class="signal-props-table__cell"><div>знач</div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-window__actions"><div class="empty"></div></div>
          </signal-info>

          <div class="signal-ident__vis">
            <img src="/images/eye.svg" loading="lazy" alt="" />
          </div>
        </div>
        <div class="signal-ident__close-btn">
          <img src="/images/remove.svg" loading="lazy" alt="" />
        </div>
      </div>
      <div class="signal-props" :id="'labels-' + _uid"><slot name="props"></slot></div>
    </div>
    <div class="signals-align-main">
      <div class="ui-canvas" :id="'ui-canvas-' + _uid" v-resize="onResize">
        <div :id="'canvas-' + _uid" :dataLabelsBlock="'labels-' + _uid" class="dy-canvas">canvas</div>
        <!--        <vue-dygraphs></vue-dygraphs>-->
        <!--        <div class="ui-canvas__overlay">
          <slot name="canvasOverlay"></slot>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import SignalInfo from '@/components/signals/SignalInfo.vue';
// import Dygraph from 'dygraphs';
import Dygraph from '../../../../vendor/dygraph';
import DygraphTpui from '../../../../vendor/dygraph/dygraph-tpui';

// import Dygraph from '../../../public/js/dygraph.min';
import DateNano from '../../utils/format.date.time';
import { mapStores } from 'pinia';
import { usePrefs } from '@/stores/prefs';

// import resize from 'vue-resize-directive';

export default {
  name: 'signal-component',
  components: {
    SignalInfo,
  },
  directives: {
    // resize,
  },
  props: {
    signal: {
      type: Object,
    },
    tstart: Number,
    tend: Number,
    timeline: Object,
  },

  data: () => {
    return {
      legend: {
        DPC: {
          valueRange: [-1, 4], //[0,2],
          y: null, //, 'Failure']
          yticker: null,
          name: 'Double Point Controllable',
          stepPlot: true,
        },
        DPS: {
          valueRange: [-1, 4], //[0,2],
          y: null, //, 'Failure']
          yticker: null,
          name: 'Double Point Status',
          stepPlot: true,
        },
        SPC: {
          valueRange: [-1, 2], //[0,1],
          y: null,
          yticker: null,
          name: 'Single Point Controllable',
          stepPlot: true,
        },
        SPS: {
          valueRange: [-1, 2], //[0,1],
          y: null,
          yticker: null,
          name: 'Single Point Status',
          stepPlot: true,
        },
        ACD: {
          valueRange: [5, 9], //[0,1],
          yticker: function (min, max, pixels, opts, dygraph, vals) {
            return [
              {
                v: 1,
                label: 'neut',
              },
              {
                v: 2,
                label: 'phsC',
              },
              {
                v: 3,
                label: 'phsB',
              },
              {
                v: 4,
                label: 'phsA',
              },
              {
                v: 7,
                label: 'general',
              },
            ];
          },
          height: '150px',
          name: 'ACD',
          stepPlot: true,
        },
        ACT: {
          valueRange: [-1, 2], //[0,1],
          y: null,
          yticker: null,
          name: 'ACT',
          stepPlot: true,
        },
        MV: {
          valueRange: null,
          y: null,
          yticker: null,
          name: 'Measured Value',
          height: '150px',
          stepPlot: false,
        },
      },
      visibleSignalInfo: false,
      g: undefined,
      height: 54,
    };
  },
  computed: {
    ...mapStores(usePrefs),
    cdc() {
      return (this.signal && this.signal.cdc) || undefined;
    },
    cdcIsSupported() {
      return Dygraph._supportedCdcs.includes(this.cdc);
    },
    calcHeight() {
      return this.pointsData.size || this.height;
    },
    pointsData() {
      return this.cdcIsSupported && DygraphTpui.splitDataV2(this.signal.points, this.cdc);
    },
    signalName() {
      dev.log('signal name', this.signal);
      return this.signal.data_ref;
    },
    simulated() {
      return this.signal.simulation === 1 ? ' simulated' : '';
    },
    signalObjectName() {
      return this.signalName + `(${this.cdc})`;
    },
  },
  mounted() {
    // this.show();
    dev.log('Dygraph._supportedCdcs', Dygraph._supportedCdcs, this.cdc);
    if (this.cdc === 'DPC' || this.cdc === 'DPS') {
      this.height = 180;
    }
  },
  methods: {
    onCanvasClick(e) {
      dev.log('click', e);
      if (e.shiftKey) {
        dev.log(this.g.dateWindow_);
        const bounds = this.g.xAxisExtremes();
        const range = this.g.xAxisRange();
        const delta = range[1] - range[0];
        let [minX, maxX] = range;
        minX -= delta;
        if (minX < bounds[0]) {
          minX = bounds[0];
        }
        maxX += delta;
        if (maxX > bounds[1]) {
          maxX = bounds[1];
        }
        dev.log('bounds', bounds, 'range', range);
        this.g.updateOptions({ dateWindow: [minX, maxX] });
      }
    },
    onCanvasDblClick(e) {
      dev.log('click', e);
      this.g.updateOptions({ dateWindow: null });
    },
    onResize(w) {
      // console.log('resize w', w);
      if (!this.g) {
        this.show();
      } else {
        this.g.resize();
      }
    },
    parseSignalName(s) {
      const regex = /(?:.*):(\w*).(\w*)\$(\w*)\/(\w*)\$(.*)/;
      var parsed = s.match(regex);
      dev.log('parse name', s, parsed);
      if (!parsed) {
        console.error("Can't parse signal name", s);
        return {};
      }
      return {
        goId: parsed[1],
        iedName: parsed[2],
        ld: parsed[3],
        ln: parsed[4],
        do: parsed[5],
      };
    },
    boleanYFormatter(x, arr) {
      if (!arr) return null;
      return arr[x] ? arr[x] : '';
    },
    show() {
      var data = [];

      const uiCan = document.getElementById('ui-canvas-' + this._uid);
      const el = document.getElementById('canvas-' + this._uid);
      const wd = uiCan.clientWidth;
      dev.log('wd', wd);
      dev.log('this.pointsData.valueRange', this.pointsData.valueRange, this.pointsData.arr);
      dev.log('this.calcHeight', this.calcHeight, JSON.stringify(this.calcHeight));
      el.style.setProperty('height', this.calcHeight + 'px');

      this.g = new Dygraph(el, this.pointsData.arr, {
        // width: -1,
        noGap: true,
        labelsInside: true, //this.cdc !== 'DPC',
        drawPoints: true,
        legend: 'follow',
        labels: this.pointsData.labels,
        _size: this.pointsData.size,
        _states: this.pointsData.states,
        _do: this.signalName.do,
        quality: this.pointsData.quality,
        meta: this.pointsData.meta,
        series: this.pointsData.series != null ? this.pointsData.series : {},
        stepPlot: this.legend[this.cdc].stepPlot,
        interactionModel: {
          ...Dygraph.tekvelInteractionModel,
          // mousedown: this.downV3,
          // mousemove: this.moveV3,
          // mouseup: this.upV3,
          click: function (event, g, context) {
            if (event.shiftKey) {
              dev.log(g.dateWindow_);
              const bounds = g.xAxisExtremes();
              const range = g.xAxisRange();
              const delta = range[1] - range[0];
              let [minX, maxX] = range;
              minX -= delta / 3;
              if (minX < bounds[0]) {
                minX = bounds[0];
              }
              maxX += delta / 3;
              if (maxX > bounds[1]) {
                maxX = bounds[1];
              }
              dev.log('bounds', bounds, 'range', range);
              g.updateOptions({ dateWindow: [minX, maxX] });
            }
            event.preventDefault();
          },
          // dblclick: this.dblClickMy,
          mousewheel: function (event, g, context) {
            if (event.shiftKey) {
              var normal = event.detail ? event.detail * -1 : event.wheelDelta / 80;
              const bounds = g.xAxisExtremes();
              const range = g.xAxisRange();
              const delta = range[1] - range[0];
              const deltaScroll = ((range[1] - range[0]) / 30) * normal;

              dev.log('scroll', normal);

              let [minX, maxX] = range;
              minX -= deltaScroll;
              maxX -= deltaScroll;
              if (minX < bounds[0]) {
                minX = bounds[0];
                maxX = bounds[0] + delta;
              }
              if (maxX > bounds[1]) {
                maxX = bounds[1];
                minX = bounds[1] - delta;
              }
              dev.log('bounds', bounds, 'range', range);
              g.updateOptions({ dateWindow: [minX, maxX] });
            }
          },
          // dblclick: this.dblClickMy,
        },
        highlightCallback: function () {
          //убирает двоеточие после времени в легенде
          // $('.dygraph-legend').each((i, elem) => {
          //   $(elem).html($(elem).html().replace(':<span>', '<span>'));
          //   // console.log($(elem).html())
          // });
        },
        // pointClickCallback: this.$tpui.faultprofile.pointClick,
        valueFormatter: (_val, opts, seriesName, dygraph, row, col) => {
          if (seriesName == 'Time')
            return new DateNano(_val)
              .setTimezoneOffset(this.prefs.timezoneOffset)
              .customFormat('#hhhh#:#mm#:#ss#.#ms##us#');
          return _val;
        },
        axes: {
          y: {
            axisLineColor: 'red',
            drawAxis: true,
            axisLabelWidth: 70,
            valueRange: this.pointsData.valueRange,
            ticker: this.pointsData.yticker,
            axisLabelFormatter: function (y, granularity, opts, dg) {
              dev.log('ylabel', y);
              return 'y' + y;
            },
          },
          x: {
            axisLineColor: 'green',

            drawAxis: false, //signals.length < 2,
            valueRange: [this.tstart, this.tend],
            ticker: DygraphTpui.dateTicker,
            axisLabelFormatter: DygraphTpui.dateAxisLabelFormatter,
          },
        },

        highlightCircleSize: 5,
        showLabelsOnHighlight: true,
        animatedZooms: true,
      });

      setTimeout(() => {
        if (this.g) {
          this.$emit('update:timeline', this.g);
          this.$nextTick(() => {
            this.$emit('timelineUpdate', true);
          });
          // if (this.signal.cdc == 'DPC') {
          //   // this.g.ready(function () {
          //   this.g.setAnnotations([
          //     {
          //       series: 'stVal',
          //       x: 1626767050021300,
          //       icon: '/icons/dpc.png',
          //       width: 16,
          //       height: 16,
          //       shortText: 'M',
          //       text: 'Marker',
          //       clickHandler: function (event, g, context) {
          //         this.g.updateOptions({ dateWindow: [1621948375137111, 1621948436957580.8] });
          //       },
          //     },
          //   ]);
          //   // });
          // }
        }
      }, 200);

      // It sucks that these things aren't objects, and we need to store state in window.
      // window.intervalId = setInterval(function () {
      //   var x = new Date(); // current time
      //   var y = Math.random() * 500;
      //   data.push([x, y]);
      //   g.updateOptions({ file: data });
      // }, 1000);
    },

    showInfo() {
      this.visibleSignalInfo = !this.visibleSignalInfo;
    },
  },
  watch: {
    // cdc: function (newVal, old) {
    //   console.log('watch newVal, old', newVal, old);
    //   if (newVal !== undefined) {
    //     this.show();
    //   }
    // },
  },
};
</script>

<style scoped></style>
