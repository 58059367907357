<template>
  <div class="container login-form-container">
    <div class="tpws-centered-form">
      <div class="tps-error-message">
        <p v-if="message" class="alert alert-danger tpws-roundborder-block">message</p>
      </div>
      <n-form ref="formRef" :model="formValue" :rules="rules" label-placement="top"  id="signin_form"
              require-mark-placement="right-hanging"
              label-width="auto">
        <input v-model="token" name="token" type="token" hidden :readonly="true" id="token" class="form-control" />

        <n-form-item :label="$t('Password')" path="password">
          <n-input name="password"
                   type="password" v-model:value="formValue.password" show-password-on="click" :placeholder="$t('Password')" />
        </n-form-item>
        <n-form-item :label="$t('Confirm password')" path="reenteredPassword">
          <n-input name="reenteredPassword" type="password" v-model:value="formValue.reenteredPassword" :disabled="!formValue.password" show-password-on="click" :placeholder="$t('Confirm password')" />
        </n-form-item>
      </n-form>
      <form autocomplete="new-password">
        <div v-if="false" class="form-group">
          <label for="password">{{ $t('New password') }}</label>
          <input
            v-model="password"
            name="password"
            type="password"
            autocomplete="new-password"
            id="password"
            class="form-control"
            @keyup.enter="onSubmit"
          />
        </div>
        <div v-if="false" class="form-group">
          <label for="password_confirm">{{ $t('Confirm password') }}</label>
          <input
            v-model="password_confirm"
            name="password_confirm"
            type="password"
            autocomplete="new-password"
            id="password_confirm"
            class="form-control"
            @keyup.enter="onSubmit"
          />
        </div>
        <button @click="onSubmit" class="btn btn-primary">{{ $t('Set new password') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import { mapStores } from 'pinia';
import { useAuth } from '@/stores/auth';
import { usePage } from '@/stores/page';
import { mapActions } from 'pinia';
import eventBus from '@/utils/eventBus';
import {ref} from "vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: 'reset-page',
  setup() {
    const formRef = ref(null);
    return { v$: useVuelidate(),
      formRef,
      formValue: ref({
        password: '',
        reenteredPassword: '',
      }),};
  },

  data: () => {
    return {
      message: '',
    };
  },
  computed: {
    ...mapStores(usePage),
    ...mapStores(useAuth),
    onPremis() {
      return this.page.pageData['auth-login']?.onPremis;
    },
    rules() {
      return {
        password: [
          {
            required: true,
            message: "Password is required"
          }
        ],
        reenteredPassword: [
          {
            required: true,
            message: "Re-entered password is required",
            trigger: ["input", "blur"]
          },
          {
            validator: this.validatePasswordStartWith,
            message: "Password is not same as re-entered password!",
            trigger: "input"
          },
          {
            validator: this.validatePasswordSame,
            message: "Password is not same as re-entered password!",
            trigger: ["blur", "password-input"]
          }
        ]
      };
    },

  },
  mounted() {},
  unmounted() {},
  methods: {
    ...mapActions(useAuth, ['reset']),
    validatePasswordStartWith(rule, value) {
      return !!this.formValue.password && this.formValue.password.startsWith(value) && this.formValue.password.length >= value.length;
    },
    validatePasswordSame(rule, value) {
      return value === this.formValue.password;
    },
    onSubmit() {
      const token = this.$route.query.t;
      if (!token) {
        eventBus.dispatch('message', {
          message: this.$t('Incorrect token. Check the link in your mailbox.'),
          close: true,
          type: 'error',
        });
        return;
      }
      this.formRef
          ?.validate((errors) => {
            if (!errors) {
              this.reset(token, this.formValue.password, this.formValue.reenteredPassword, { name: 'auth-login' })
                  .then((url) => {
                    dev.log('ret', url);
                    this.auth.logout({name: 'auth-login'});
                    setTimeout(()=>{
                      this.$router.push({name: 'auth-login'});
                    }, 1000);
                  })
                  .catch((error) => {});
            } else {
              dev.log('form not valid', errors);
            }
          })
          .catch((err) => {});

    },
  },
};
</script>

<style lang="scss"></style>
