import api from '@/services/api';
import { defineStore } from 'pinia';

export const useEventProfile = defineStore({
  id: 'eventProfile',
  state: () => {
    return {
      timestampType: 'network',
    };
  },

  actions: {
    //todo setTimestampType init
    removeGraph(event) {
      let graph = $(event.target).parent().parent().parent()
      let id = graph.attr('data-row-id');
      graph.remove()
      this.unselectRow(id)

      this.hideTimestampTypeToggle()
    },
    unselectRow(id) {
      $(`tr[data-row-id="${id}"]`).removeClass('selected')
      if ($('#svr-meta-table').find('tr.selected').length < 2) $('.svr-meta-button').addClass('disabled')
      if ($('#signals-meta-table').find('tr.selected').length < 2) $('.signals-meta-button').addClass('disabled')
    },
    toggleTimestampType() {
      this.timestampType = $('#timestamp-type-toggle__process').prop('checked') ? 'process' : 'network';
      $('.svr-meta-button').removeClass('disabled').click()
    },
    hideTimestampTypeToggle() {
      if (!$('#svr-dygraph').children().length) $('#timestamp-type-toggle').addClass('d-none')
    }
  },
});
