import { useAuth } from '@/stores/auth';
import { defineStore } from 'pinia';
// import {router} from '@/router';
// import { useRoute } from 'vue-router';
// import main from '@/root.app';

export const useUi = defineStore({
  id: 'ui',
  persist: {
    // enabled: true,
    // encryptionKey: 'pageSettings',
    key: ['options'],
    storage: sessionStorage,
  },
  state: () => {
    return {
      options: {},
    };
  },
  getters: {
    currentUser(state) {
      const auth = useAuth();
      return auth?.user?._id;
    },
    curRoute(state) {
      // main.config.globalProperties.$socket
      // return router?.currentRoute?.name;

      return 'route'; //main.config.globalProperties?.$rt?.currentRoute?.name;
    },
    currentWindow(state) {
      return window.tabId;
    },
    currentOptions(state) {
      // return false;
      // const route = useRoute();
      // console.log('route', route);
      return state.options?.[this.currentUser]?.[this.curRoute]?.[this.currentWindow];
    },
  },
  actions: {
    setProp({ page, data }) {
      // console.log('dis setPageData', page, data);
      this.pageData[page] = data;
    },
    // setPageData({ page, data }) {
    //   this.pageData[page] = data;
    // },
  },
});
