import api from '@/services/api';
import { defineStore } from 'pinia';

export const useEventsArchive = defineStore({
  id: 'eventsArchive',
  state: () => {
    return {
      eventsArchive: false,
      eventsArchiveTSrart: 0,
      eventsArchiveTEnd: 0,
      visibleArchiveDialog: false,
      currentTimePreset: undefined,
    };
  },
  getters: {
    eventsArchiveTS: (state) => {
      return { start: state.eventsArchiveTSrart, end: state.eventsArchiveTEnd };
    },
  },
  actions: {
    setEventsArchive(value) {
      dev.log('setEventsArchive', value);
      this.eventsArchive = value;
    },
    setEventsArchiveTS(value) {
      dev.log('setEventsArchiveTS', value);
      this.eventsArchiveTSrart = value.tstart;
      this.eventsArchiveTEnd = value.tend;
    },
  },
});
