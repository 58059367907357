<template>
  <div class="items-list-pre" style="width: 32px">
    <div v-if="itemsHidden.length > 0" class="items-list__tag-counter" :style="{ left: maxRight + 4 + 'px' }">
      <!--append-to-body="true"-->
      <popper
        trigger="hover"
        :append-to-body="true"
        :options="{
          placement: 'top',
          modifiers: { offset: { offset: '0,10px' } },
        }"
      >
        <div class="items-list__tag-title">+{{ itemsHidden.length }}</div>
        <template #content>
          <div class="popper">
            <div v-for="item in itemsHidden">{{ item.title }}</div>
          </div>
        </template>
      </popper>
    </div>
  </div>
</template>

<script>
import Popper from 'vue3-popper';
// import 'vue-popperjs/dist/vue-popper.css';
import '../../../assets/vue-popper-custom.css';
export default {
  name: 'hidden-counter',
  props: {
    itemsHidden: {
      type: Array,
    },
    maxRight: {
      type: Number,
    },
  },
  components: {
    popper: Popper,
  },
};
</script>

<style scoped>
.items-list__tag-counter {
  .inline-block {
    display: block!important;
  }
}
.popper {
  background: #1757bd;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  border-color: #1757bd;
}
</style>
<style>
.popper[x-placement^='top'] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: #13499e transparent transparent transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
</style>
