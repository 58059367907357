import api from '@/services/api';
import { defineStore } from 'pinia';
import { PropertyUserPreference } from '../utils/user-preferences';

import { useAuth } from '@/stores/auth';
import { useConfig } from '@/stores/config';
import { useTpws } from '@/stores/tpws';
import { markRaw } from 'vue';

const deRef = (r) => {
  return JSON.parse(JSON.stringify(r));
};
export const usePrefs = defineStore({
  id: 'prefs',
  name: 'prefs',
  state: () => {
    return {
      userId: undefined,
      preferences: undefined,
      formPreferences: undefined,
      generalPreferences: undefined,
      formGeneralPreferences: undefined,
      userPreferencesDefault: undefined,
      userPreferencesByProject: undefined,
      userPreferencesGetters: undefined,
      userPreferencesSetters: undefined,
      userPreferencesDescription: undefined,
      userPreferencesSchema: undefined,
      prefByProjectInit: undefined,
      prefByProject: undefined,
    };
  },
  getters: {
    pref: (state) => {
      return state.isAuthorized ? state.preferences : state.configPref;
    },
    configPref: (state) => {
      const config = useConfig();
      // console.log('config', config.userPreferences);
      return config.userPreferences ? config.userPreferences : state.preferences;
    },
    isAuthorized(state) {
      const auth = useAuth();
      return !!auth.userId;
    },
    descriptionType: (state) => {
      return state.pref?.descriptionType;
    },
    timezoneOffset: (state) => {
      return state.pref?.timezoneOffset || 0;
    },
    voiceNotifications: (state) => {
      return state.pref?.voiceNotifications;
    },
    vlanDisplayType: (state) => {
      return state.pref?.vlanDisplayType;
    },
    ghostGooseCardTitleDisplay: (state) => {
      return state.pref?.ghostGooseCardTitleDisplay;
    },
    ghostSvCardTitleDisplay: (state) => {
      return state.pref?.ghostSvCardTitleDisplay;
    },
    language: (state) => {
      return state?.pref?.language;
    },
    timeBefore: (state) => {
      return state?.pref?.timeBefore;
    },
    timelineExperimental: (state) => {
      return state?.pref?.timelineExperimental;
    },
    timeAfter: (state) => {
      return state?.pref?.timeAfter;
    },
    toggleOptions: (state) => {
      if (state.pref && state.pref.language === 'en') {
        return { on: 'On', off: 'Off' };
      }
      if (state.pref && state.pref.language === 'ru') {
        return { on: 'Вкл', off: 'Откл' };
      }
      return { on: 'On', off: 'Off' };
    },
  },

  actions: {
    resetForms() {
      dev.log('form reset');
      const tpws = useTpws();
      this.formGeneralPreferences = deRef(this.generalPreferences);
      this.prefByProject = deRef(this.prefByProjectInit);
      this.formPreferences = deRef({ ...this.preferences, ...this.prefByProject[tpws.currentProject] });
    },
    uploadSchema(value) {
      return new Promise(async (resolve) => {
        const tpws = useTpws();
        const { data } = await api.get(`/users/get-properties-schema?projectName=${tpws.currentProject}`);
        const initObject = {};
        for (const prop in data.desc) {
          if (prop == 'userId') continue;

          this.addProperty(
            new PropertyUserPreference({
              name: prop,
              schema: data.desc[prop].userPreferencesSchema,
              default: data.desc[prop].userPreferencesDefault,
              description: data.desc[prop].userPreferencesDescription,
              preventToChange: data.desc[prop].userPreferencesPreventToChange,
            })
          );

          initObject[prop] = data.desc[prop].value;
        }
        //console.log('get-set', data);

        this.initSettings({ userId: data.desc.userId, userPreferencesInit: initObject });
        this.initSettingsGeneral({ userId: data.desc.userId, userPreferencesInit: data.settings.preferences });
        this.initByProject(data.settings.prefByProject);

        // window.localStorage.setItem('vue.user', JSON.stringify({ value: this.user, expire: null }));
        // window.dispatchEvent(new Event('user'));

        resolve();
      });
    },
    applyPreferences() {
      // передаем preferences в форму
      for (const prop in this.preferences) {
        const propProject = $(`.user-preferences__${prop}-project`);
        if (
          $(`.user-preferences__${prop}-default`).prop('checked') ||
          !$(`.user-preferences__${prop}-default`).length
        ) {
          this[prop] = null;
          propProject.prop('disabled', true);
          continue;
        }

        if (propProject.prop('tagName') + '-' + propProject.prop('type') == 'INPUT-checkbox')
          propProject.bootstrapToggle(this.preferences[prop] ? 'on' : 'off');
        else propProject.val(this.preferences[prop]);
      }
      return this;
    },
    addProperty(newProperty) {
      if (!this.userPreferencesSchema) {
        this['userPreferencesSchema'] = {};
      }
      this.userPreferencesSchema[newProperty.name] = newProperty.schema;

      if (!this.userPreferencesDefault) {
        this['userPreferencesDefault'] = {};
      }
      this.userPreferencesDefault[newProperty.name] = newProperty.default;

      if (!this.userPreferencesDescription) {
        this['userPreferencesDescription'] = {};
      }
      this.userPreferencesDescription[newProperty.name] = newProperty.description;

      if (!this.userPreferencesPreventToChange) {
        this['userPreferencesPreventToChange'] = {};
      }
      this.userPreferencesPreventToChange[newProperty.name] = newProperty.preventToChange;

      if (!this.userPreferencesByProject) {
        this['userPreferencesByProject'] = {};
      }
      this.userPreferencesByProject[newProperty.name] = newProperty.preventToChange;
    },
    setUserId(userId) {
      this['userId'] = Number(userId);
    },
    setByProject(val) {
      // if (!this.prefByProject) {
      this['prefByProjectInit'] = deRef(val);
      this['prefByProject'] = val;
      // }
      // this.prefByProject[newProperty.name] = newProperty.setter;
    },
    setPrefs(userPreferencesInit1) {
      const tpws = useTpws();
      const preferences = {
        ...this.userPreferencesDefault,
        ...userPreferencesInit1,
      };
      this.preferences = preferences;
      dev.log('setFormProf', preferences, this.prefByProject);
      this.formPreferences = deRef({ ...preferences, ...this.prefByProject[tpws.currentProject] });
      // this.formPreferences['timeBefore'] = 3242234;
      // this.generalPreferences = {};
    },
    setPrefsGeneral(userPreferencesInit1) {
      const preferences = {
        ...this.userPreferencesDefault,
        ...userPreferencesInit1,
      };
      this.generalPreferences = preferences;
      this.formGeneralPreferences = deRef(preferences);
      // this.formPreferences['timeBefore'] = 3242234;
      // this.generalPreferences = {};
    },
    savePrefs() {
      this.preferences = this.formPreferences;
    },
    async saveFormPrefs() {
      return new Promise(async (resolve) => {
        const tpws = useTpws();
        //console.log('saveFormPrefs', this.formPreferences);
        // this.savePrefs();

        const pref = {
          projectName: tpws.currentProject,
          ...this.formPreferences,
        };
        const prefGeneral = {
          ...this.formGeneralPreferences,
        };

        const { data } = await api.post(`/users/set-properties`, { userPrefernces: pref });
        const { data: dataGeneral } = await api.post(`/users/set-properties`, {
          userPrefernces: prefGeneral,
        });

        if (data.res === 'success' && dataGeneral.res === 'success') {
          //console.log('setPrefs', data.desc);
          // this.setPrefs( data.desc);
          this.uploadSchema();
          resolve();
        }
      });
    },
    initSettings({ userId, userPreferencesInit }) {
      this.setUserId(userId);
      // userPreferencesInit = checkSettings(userPreferencesInit);
      //console.log('userPreferencesInit', userPreferencesInit);
      this.checkSettings(userPreferencesInit).then((userPreferencesInit1) => {
        this.setPrefs(userPreferencesInit1);
      });
    },
    initSettingsGeneral({ userId, userPreferencesInit }) {
      this.setUserId(userId);
      // userPreferencesInit = checkSettings(userPreferencesInit);
      //console.log('userPreferencesInit', userPreferencesInit);
      this.checkSettings(userPreferencesInit).then((userPreferencesInit1) => {
        //console.log('userPreferencesInit1', userPreferencesInit1);
        this.setPrefsGeneral(userPreferencesInit1);
      });
    },
    initByProject(val) {
      this.setByProject(val);
    },
    checkSettings(checkedObject) {
      return new Promise((resolve, reject) => {
        const normalObject = {};
        let condition;
        //console.log('checkedObject', checkedObject);

        if (typeof checkedObject != 'object') resolve(normalObject);

        for (const prop in checkedObject) {
          if (!this.userPreferencesSchema[prop] || checkedObject[prop] === undefined) continue;

          normalObject[prop] = this.userPreferencesDefault[prop];

          condition =
            (typeof this.userPreferencesSchema[prop] == 'string' &&
              this.userPreferencesSchema[prop] == typeof checkedObject[prop]) ||
            ~this.userPreferencesSchema[prop].indexOf(checkedObject[prop]);

          if (condition) {
            normalObject[prop] = checkedObject[prop];
          }
        }

        resolve(normalObject);
      });
    },
    checkOrig(checkedObject) {
      // Проверка объекта на валидность свойств
      const normalObject = {};
      let condition;

      if (typeof checkedObject != 'object') return normalObject;

      for (const prop in checkedObject) {
        if (!this.userPreferencesSchema[prop] || checkedObject[prop] === undefined) continue;

        normalObject[prop] = this.userPreferencesDefault[prop];

        condition =
          (typeof this.userPreferencesSchema[prop] == 'string' &&
            this.userPreferencesSchema[prop] == typeof checkedObject[prop]) ||
          ~this.userPreferencesSchema[prop].indexOf(checkedObject[prop]);

        if (condition) normalObject[prop] = checkedObject[prop];
      }

      return normalObject;
    },
  },
});
