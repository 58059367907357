<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0833 10L7.91666 5.83335L7.91666 14.1667L12.0833 10Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'caret-right',
  props: {
    color: {
      type: String,
      default: '#121314',
    },
  },
};
</script>

<style scoped></style>
