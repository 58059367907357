<template>
  <div class="signals-widget">
    <div class="signals-widget__header">
      <div class="widget-header">
        <div class="widget-header__name"><div>Интервальная линейка</div></div>
        <div class="widget-header__close-btn">
          <img src="/images/remove.svg" loading="lazy" alt="" />
        </div>
      </div>
    </div>
    <div class="signals-widget__body">
      <div class="widget-body__form">
        <div class="time-ruller">
          <div class="time-ruller__name"><div>T1</div></div>
          <div class="time-ruller__value">
            <div class="ui-input opacity-50"><div>20:16:56.346000</div></div>
          </div>
        </div>
        <div class="time-ruller">
          <div class="time-ruller__name"><div>T2</div></div>
          <div class="time-ruller__value">
            <div class="ui-input opacity-50"><div>20:16:57.645000</div></div>
          </div>
        </div>
        <div class="time-ruller">
          <div class="time-ruller__name">
            <div><strong>Δ</strong>T</div>
          </div>
          <div class="time-ruller__value">
            <div class="ui-input opacity-50"><div>1.546000</div></div>
          </div>
        </div>
      </div>
      <div class="widget-body__action">
        <div class="ui-button">
          <div class="ui-button__lable"><div>Сбросить</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'widget-ruler',
};
</script>

<style scoped></style>
